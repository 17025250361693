import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={6} height={6} viewBox="0 0 6 6" fill="none" {...props}>
            <path
                d="M3.403 1.57a.332.332 0 00-.47 0L1.737 2.767c-.13.13-.13.34 0 .47l1.196 1.196a.332.332 0 10.47-.47l-.626-.63h2.89C5.85 3.333 6 3.183 6 3a.334.334 0 00-.333-.333h-2.89l.626-.627a.335.335 0 000-.47zM.667 6h4.666C5.703 6 6 5.7 6 5.333v-1A.334.334 0 005.667 4a.334.334 0 00-.334.333V5c0 .183-.15.333-.333.333H1A.334.334 0 01.667 5V1c0-.183.15-.333.333-.333h4c.183 0 .333.15.333.333v.667a.334.334 0 00.667 0v-1C6 .3 5.7 0 5.333 0H.667C.3 0 0 .3 0 .667v4.666C0 5.7.3 6 .667 6z"
                fill={props.fill}
            />
        </svg>
    )
}

export default SvgComponent

import html2canvas from "html2canvas"

export default function getElementImage(args) {
    return new Promise((resolve, reject) => {
        // type: string (ENUM: "SVG" or "ELEMENT")
        // selector: string (css string selector)
        const { id = "builder", title = "", type, selector, selectors } = args

        let element = null
        let _width = 0
        let _height = 0

        console.log(selector)
        // html element
        if (selector) {
            element = document.querySelector(selector)
            _width = element?.width?.baseVal?.value
            _height = element?.height?.baseVal?.value
        } else if (selectors) {
            const elements = document.querySelectorAll(selectors)
            element = (elements && elements.length > 0 && elements[0].cloneNode(true)) || null

            for (let i = 1; i < elements.length; i++) {
                element.appendChild(elements[i].cloneNode(true))
            }

            // let bbox = elements[0].getBBox();
            // _width = bbox.width;
            // _height = bbox.height;
            _width = elements[0]?.width?.baseVal?.value
            _height = elements[0]?.height?.baseVal?.value
        }

        // if element is not null and type is SVG
        if (type === "SVG" && element) {
            let ratio = _height / _width
            let width = 1920
            let height = width * ratio

            let clonedSvg = element.cloneNode(true)

            console.log(clonedSvg)

            // parse it to blob
            let outerHTML = clonedSvg.outerHTML
            let blob = new Blob([outerHTML], {
                type: "image/svg+xml",
            })

            const reader = new FileReader()
            reader.readAsDataURL(blob)

            const image = new Image()

            reader.onload = function (e) {
                image.src = reader.result
            }

            image.onload = () => {
                // save to canvas for base64 encoding
                let canvas = document.createElement("canvas")
                canvas.setAttribute("crossorigin", "*")
                // set canvas proportions
                canvas.width = width
                canvas.height = height
                // draw image in canvas
                let context = canvas.getContext("2d")
                context.drawImage(image, 0, 0, width, height)

                if (title) {
                    context.font = "32px Helvetica"
                    context.fillStyle = "#666D74"
                    context.fillText(title, 20, 32)
                }

                // context.fillRect(0, 0, 20, 20)
                const png = canvas.toDataURL("image/png")
                resolve({ data: png })
            }
        } else if (type === "ELEMENT" && element) {
            html2canvas(element).then((canvas) => {
                // add title to downloable image
                // if (id === "image") {
                //     context.font = "32px Helvetica"
                //     context.fillStyle = "#666D74"
                //     context.fillText(title, 20, 32)
                // }

                let context = canvas.getContext("2d")

                if (title) {
                    console.log(title)
                    context.font = "32px Helvetica"
                    context.fillStyle = "#FFFFFF"
                    context.fillText(title, 20, 32)
                }

                // get base64 img
                let png = canvas.toDataURL()

                resolve({ data: png })
                // set base64 img to parent
                // return { id, data: png }
                // window.parent.postMessage({ id, data: png }, "*");
            })
        }
    })
}

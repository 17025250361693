import React from "react"

import ArrowRight from "../../../../ui/Icons/ArrowRight"

import { titleCase } from "../../../../Util/General"

// TODO: Any difference in performance for children importing styles?
// import "./AlertSettings.css"

const CropTab = (props) => {
    const { name } = props
    return (
        <div id={`${name}-category-tab__risk-profiles`} className="risk-profiles__alerts__category-menu">
            <div className="risk-profiles__alerts__category-menu-title">{titleCase(name)}</div>

            <ArrowRight className="risk-profiles__alert__category-menu-icon" />
        </div>
    )
}

export default CropTab

import { Link as RouterLink } from "react-router-dom"

export default function AddLocationsButton(props){
    const { skin, email, togglePaymentFlow } = props

    const buttonClassNameProperties = [
        "montserrat text-white text-[14px] font-bold",
        "transition-all duration-200 ease-out",
        "bg-accent hover:bg-accent-dark",
        "rounded-sm border-none",
        "px-[20px] py-[6px]",
        "cursor-pointer",
        ]
        
    return (
        <>
        {(skin === "skip" && email !== "pacificseeds@climate.ai" && (
            <button
                id="add-field-button__table"
                className={buttonClassNameProperties.join(" ")}
                onClick={togglePaymentFlow}
            >
                Add Locations
            </button>
        )) ||
        (
            <RouterLink to="/weather/add-locations">      
                <button
                    id="add-field-button__table"
                    className={buttonClassNameProperties.join(" ")}
                >
                    Add Locations
                </button>
            </RouterLink>
        )}
        </>
    )
  }
import * as React from "react"

const ClimateRiskIcon = (props) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12 4.99 19.53 18H4.47L12 4.99Zm1.73-1c-.77-1.33-2.69-1.33-3.46 0L2.737 16.998C1.966 18.33 2.928 20 4.468 20h15.063c1.54 0 2.503-1.669 1.731-3.002L13.731 3.99ZM13 15h-2v2h2v-2Zm0-6h-2v4h2V9Z"
            fill={props.fill}
        />
    </svg>
)

export default ClimateRiskIcon

import React, { useState, useContext, useEffect } from "react"

import FunnelIcon from "../Icons/FunnelIcon"

import "./TableHeader.css"
import TableContext from "./TableContext"
import TableArrowIcon from "../Icons/TableArrowIcon"
import TableFilter from "./TableFilter"
import useOutsideComponentClickHandler from "../../hooks/ClickOutsideHook"
import FixedLayer from "../FixedLayer/FixedLayer"

function TableHeaders(props) {
    const {
        active = false,
        sortFunctionName = "",
        onClick = () => null,
        column,
        selectedFilters,
        filterOptions = new Set(),
    } = props

    const {
        propName,
        displayName,
        headerClassName,
        style,
        headerStyle,
        headerRender = () => null,
        headerComponent: HeaderComponent,
        draggable = false,
        sticky,
        sortable,
        filter,
    } = column

    const { checked, handleCheckAllRows, checkedStatus, onAddFilter, onRemoveFilter } = useContext(TableContext)
    const [open, toggle] = useState(false)

    const ref = useOutsideComponentClickHandler(() => toggle(false))

    useEffect(() => {
        function handleTableShadowOn() {
            toggle(false)
        }

        if (filter && !sticky) window.addEventListener("table-shadow-on", handleTableShadowOn)
        return () => {
            if (filter && !sticky) window.removeEventListener("table-shadow-off", handleTableShadowOn)
        }
    }, [filter, sticky])

    return (
        <>
            <th
                className={`table-header ${active ? "active" : ""} ${headerClassName || ""} ${
                    (sticky && "stick") || ""
                }`}
                style={{ ...(headerStyle || style), cursor: draggable ? "grab" : "initial" }}
                draggable={draggable}
                ref={ref}
            >
                <div className="w-full h-full flex items-center justify-center">
                    {(headerRender && headerRender(displayName, { checked, handleCheckAllRows, checkedStatus })) ||
                        (HeaderComponent && (
                            <HeaderComponent
                                displayName={displayName}
                                headerOptions={{ checked, handleCheckAllRows, checkedStatus }}
                            />
                        )) ||
                        displayName}
                    {filter && (
                        <button
                            className="relative btn fill-accent w-[0px] h-[20px] overflow-visible"
                            // onClick={toggleFilterSelection}
                            onClick={() => toggle(!open)}
                        >
                            <div className="absolute top-0 w-[20px] h-[20px] ml-[2px]">
                                <FunnelIcon
                                    fill={
                                        ((open || selectedFilters.size > 0) && "var(--color-primary)") ||
                                        "var(--color-gray-30)"
                                    }
                                />
                            </div>
                        </button>
                    )}
                </div>
                {!open && sortable && (
                    <button
                        className={
                            "btn table-header__icon cursor-pointer hover:shadow-md" +
                            ((sortFunctionName && " shadow-md") || "")
                        }
                        onClick={() => sortable && onClick(propName)}
                    >
                        <TableArrowIcon sort={(active && sortFunctionName) || null} />
                    </button>
                )}
                {open && (
                    <FixedLayer yPosition="bottom" inheritWidth>
                        <TableFilter
                            columnPropName={propName}
                            options={filterOptions}
                            selected={selectedFilters}
                            onSelectChange={(checked, value) => {
                                if (checked) onAddFilter({ value, propName })
                                else onRemoveFilter({ value, propName })
                            }}
                        />
                    </FixedLayer>
                )}
            </th>
        </>
    )
}

export default TableHeaders

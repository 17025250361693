import React from "react"

function DropdownItem(props) {
    const {
        title = "",
        id = "",
        disabled,
        onClick = () => null,
        actions: { close = () => null },
    } = props

    function handleClick() {
        onClick()
        close()
    }

    return (
        <>
            <button
                id={id}
                className="control-item__content__default-options__option"
                onClick={handleClick}
                disabled={disabled}
            >
                {title}
            </button>
        </>
    )
}

export default DropdownItem

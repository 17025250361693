import * as d3 from "d3"
import {
    convertTemperatureValueOnly,
    convertWaterLengthValueOnly,
    convertSpeedWindValueOnly,
    convertSpeedValueOnly,
    convertWindSpeedKmhToMph,
} from "../Util//UnitConversion"
import { endOfMonth } from "date-fns"
import ReactDOMServer from "react-dom/server"
import getRiskIcon from "../Util/getRiskIcon"
import { isDate } from "../views/AddLocations/AddLocationsUtil"
import WeatherArrowIcon from "../ui/Icons/WeatherArrowIcon"
import WeatherEqualsIcon from "../ui/Icons/WeatherEqualsIcon"

/**
 * Merge historical and forecast data mostly for hourly data exports
 * @param {Object} data - historical and forecast data and value accessor
 * @param {{time:[]}} data.historical -
 * @param {'t2m'|'tp'|'stl1'|'e'|'rh'} data.prop -
 * @param {{time:[]}} data.forecast -
 */
export function mergeHistoricalAndForecastData({ historical, forecast, prop = "t2m" }) {
    // Assemble Historical
    const historicalMerged = historical.time.map((d, i) => [d, historical[prop][i], [""]])

    // Assemble Forecast
    const forecastMerged = forecast.time.map((d, i) => [d, [""], forecast[prop][i]])

    // Merge and return result
    const result = historicalMerged.concat(forecastMerged)
    return result
}

// Reusable function to export forecast data in area chart supported format
export function getForecastConfidenceData(
    forecast,
    lastHistoricalPoint,
    forecastDataObj,
    confidenceProportion,
    historicalData
) {
    if (!forecastDataObj[confidenceProportion]) return []
    const maxConfidenceProportion = confidenceProportion
    let minConfidenceProportion = "0.25"
    if (maxConfidenceProportion === "0.95") {
        minConfidenceProportion = "0.05"
    }
    if (!lastHistoricalPoint) return []
    const result = forecast.time.map((item, index) => {
        if (!index) {
            return {
                x: new Date(item).getTime(),
                y: lastHistoricalPoint.y - 0.000005,
                y0: lastHistoricalPoint.y + 0.00005,
            }
        } else {
            return {
                x: new Date(item).getTime(),
                y: forecastDataObj[maxConfidenceProportion][index],
                y0: forecastDataObj[minConfidenceProportion][index],
            }
        }
    })

    // If historical data was passed, add last 3 item in order to area to be smoothly transitioned
    if (historicalData) {
        const filtered = historicalData
            .slice()
            .reverse()
            .filter((d, i) => i < 4 && i > 0)
            .reverse()
        return filtered.map((d) => ({ x: d.x, y: d.y - 0.000005, y0: d.y + 0.00005 })).concat(result)
    }
    return result
}

// Reusable function to add and substract months to the passed date
export function addMonths(date, months) {
    date.setMonth(date.getMonth() + months)
    return date
}

// Get last day of months
export function getLastDayOfMonth(date) {
    return endOfMonth(date)
}

export function getFirstDayOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1).getTime()
}

export function getFirstDayOfWeek(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()).getTime()
}

// Get last day of week
export function getLastDayOfWeek(date) {
    return new Date(date.getTime() + (7 - date.getDay()) * 24 * 60 * 60 * 1000)
}

// Calculate months diff
export function getMonthsBetween(date1, date2, roundUpFractionalMonths) {
    //Months will be calculated between start and end dates.
    //Make sure start date is less than end date.
    //But remember if the difference should be negative.
    var startDate = date1
    var endDate = date2
    var inverse = false
    if (date1 > date2) {
        startDate = date2
        endDate = date1
        inverse = true
    }

    //Calculate the differences between the start and end dates
    var yearsDifference = endDate.getFullYear() - startDate.getFullYear()
    var monthsDifference = endDate.getMonth() - startDate.getMonth()
    var daysDifference = endDate.getDate() - startDate.getDate()

    var monthCorrection = 0
    //If roundUpFractionalMonths is true, check if an extra month needs to be added from rounding up.
    //The difference is done by ceiling (round up), e.g. 3 months and 1 day will be 4 months.
    if (roundUpFractionalMonths === true && daysDifference > 0) {
        monthCorrection = 1
    }
    //If the day difference between the 2 months is negative, the last month is not a whole month.
    else if (roundUpFractionalMonths !== true && daysDifference < 0) {
        monthCorrection = -1
    }

    return (inverse ? -1 : 1) * (yearsDifference * 12 + monthsDifference + monthCorrection)
}

// Reusable function to add and substract years to the passed date
export function addYears(date, years) {
    const dt = new Date(date)
    dt.setFullYear(dt.getFullYear() + years)
    return dt
}

export function addDays(date, days) {
    const dt = new Date(date)
    dt.setDate(dt.getDate() + days)
    return dt
}

export function addWeeks(date, weeks) {
    const dt = new Date(date)
    dt.setDate(dt.getDate() + weeks * 7)
    return dt
}

// Reusable function to duplicate historical yearly data for future 6 month date values
export function duplicateMonthlyHistoricalDataForFutureSevenMonths(oneYearAreasData) {
    // Concatenate passed yearly data
    const concatenatedYears = oneYearAreasData.concat(
        oneYearAreasData.map((d) => Object.assign({}, d, { x: addYears(d.x, 1) }))
    )

    // Get next 6 month data
    const sevenMonthsAhead = addMonths(new Date(), 7)

    // Calculate last day
    var lastDay = new Date(2008, sevenMonthsAhead.getMonth() + 1, 0).getDate()

    // Set last day
    sevenMonthsAhead.setDate(lastDay)

    // Filter out of range records
    const yearsFiltered = concatenatedYears.filter((d) => d.x < sevenMonthsAhead)

    // Return result
    return yearsFiltered
}

// Reusable function to duplicate historical yearly data for future 6 month date values
export function getSevenMonthsMarginClimData(oneYearAreasData) {
    // Concatenate passed yearly data
    const concatenatedYears = oneYearAreasData
        .map((d) =>
            Object.assign({}, d, {
                x: addYears(d.x, -1),
            })
        )
        .concat(oneYearAreasData)
        .concat(oneYearAreasData.map((d) => Object.assign({}, d, { x: addYears(d.x, 1) })))

    // Get next 7 month data
    const sevenMonthsAhead = addMonths(new Date(), 7)
    const sevenMonthsBefore = addMonths(new Date(), -7)

    // Calculate last day
    const lastDay = new Date(2008, sevenMonthsAhead.getMonth() + 1, 1).getDate()

    // Set last and first day
    sevenMonthsAhead.setDate(lastDay)
    sevenMonthsBefore.setDate(1)

    // Filter out of range records
    const yearsFiltered = concatenatedYears.filter((d) => d.x < sevenMonthsAhead && d.x > sevenMonthsBefore)

    // Return result
    return yearsFiltered
}

// Reusable function to hide climatology data starting two weeks from now
export function trimSeasonalDateClimate(oneYearAreasData) {
    const twoWeeksLater = addDays(new Date(), 14)
    const filteredClimData = oneYearAreasData.filter((d) => d.x < twoWeeksLater)
    return filteredClimData
}

/**
 *  Reusable function for data validation
 * @param historic - Historical data
 * @param forecast - Forecast data
 * @param message -  Message which will be displayed in case validity fails
 * @param accessorKey - key, to access historic data value
 */
export function validateData({ historic, forecast, message, accessorKey, diffToAlert }) {
    // Declare minimum viable diff unit value
    const maxDiffForAlert = diffToAlert || 10

    // If passed data is empty, return
    if (!historic || historic.length === 0) return

    // Get forecast dates
    const forecastKeys = forecast.map((d) => d.x)

    // Get matching historical max values
    const filtereHistoric = historic.filter((d) => forecastKeys.includes(d.x))

    // Calculate unit differences between forecast and historical data, and filter out invalids
    const diffs = forecast
        .map((f, i) => {
            const maxHistoric = filtereHistoric[i]?.y
            const minHistoric = filtereHistoric[i]?.y0
            let diff = 0
            if (f.y < minHistoric) diff = minHistoric - f.y
            if (f.y > maxHistoric) diff = f.y - maxHistoric
            return diff
        })
        .filter((diff) => diff > maxDiffForAlert)

    // Calculate proportions
    const proportion = diffs.length / forecast.length

    // If invalid points proportions is more than 80%, throw error
    if (proportion >= 0.8) throw new Error(message)
}

// Trim data based 12 and future 14 days
export function trimmData(data, pastLimit = 12) {
    const result = data.filter((item) => {
        const minX = new Date().getTime() - pastLimit * 24 * 60 * 60 * 1000
        const maxX = new Date().getTime() + 14 * 24 * 60 * 60 * 1000
        return minX <= item.x && item.x <= maxX
    })
    return result
}

// Trim data based on prev 12 and future 12 hour
export function hourlyDataTrim(data) {
    const result =
        data &&
        Array.isArray(data) &&
        data.filter((item) => {
            const minX = new Date().getTime() - 0 * 60 * 60 * 1000
            // display 30 hours into the future
            const maxX = new Date().getTime() + 28 * 60 * 60 * 1000
            return minX <= item.x && item.x <= maxX
        })
    return result || []
}

// Interpolates number between two passed number
function interpolateNumber(start, end, current) {
    var i = d3.interpolateNumber(start, end)
    return i(current)
}

/**
 * Assembles sevaral area datas into one
 * @param  {bool} isMonthly  Whether we should take monthly view into account
 * @param  {} areaData Original area data
 * @param  {} seasonal  Seasonal data
 * @param  {} climatology  Climatology data for rounding
 * @param  {boolean} isCumulative  Flag which checks if we should acumulate data or not
 * @param  {string} cumulativeType  sum_per_month (sum up values on monthly basis), cumulative_sum_per_month - (accumulate monthly basis sums)
 * @return {{x,y,y0}[]} Assembled area data
 */
export function assembleAreaData({
    lineDataBaseValue,
    isMonthly,
    areaData,
    seasonal,
    climatology,
    isCumulative,
    cumulativeType,
}) {
    if (isMonthly) {
        if (areaData && areaData.length && seasonal && seasonal.length) {
            // Get next 6 month data
            const sixMonthsAhead = addMonths(new Date(), 6)

            // Filter seasonal data to stay within next six months range
            const filteredSeasonalData = seasonal
                .filter((d) => +d.x > +areaData[areaData.length - 1].x)
                .filter((d) => d.x <= sixMonthsAhead)
            if (filteredSeasonalData.length > 7) {
                if (climatology) {
                    const climatologyObj = {}
                    climatology.forEach((c) => (climatologyObj[c.x] = c))
                    filteredSeasonalData.forEach((f) => {
                        if (climatologyObj[f.x]) {
                            f.y1 = Math.max(climatologyObj[f.x].y, f.y)
                            f.y0 = Math.min(climatologyObj[f.x].y0, f.y0)
                            f.min = f.y0
                            f.max = f.y1
                        }
                    })
                }

                // Interpolate intemediate values
                filteredSeasonalData
                    .filter((d, i) => i < 7)
                    .forEach((d, i) => {
                        d.y0 = interpolateNumber(areaData[areaData.length - 1].y0, filteredSeasonalData[7].y0, i / 7)
                        d.y1 = interpolateNumber(areaData[areaData.length - 1].y, filteredSeasonalData[7].y1, i / 7)
                        d.min = d.y0
                        d.max = d.y1
                    })
            }
            // Acumulate data if there is such option passed
            return isCumulative
                ? accumulateArea({
                      areaData: areaData.concat(filteredSeasonalData),
                      cumulativeType: cumulativeType,
                      lineDataBaseValue,
                  })
                : areaData.concat(filteredSeasonalData)
        }
        // Acumulate data if there is such option passed
        return isCumulative
            ? accumulateArea({ areaData: areaData, cumulativeType: cumulativeType, lineDataBaseValue })
            : areaData
    } else {
        // Trim to daily view data
        const trimmed = trimmData(areaData)
        return trimmed
    }
}

/**
 * Accumulation of area data
 * @param  {{x,y,y0}[]} areaData
 * @param  {string} cumulativeType  sum_per_month (sum up values on monthly basis)
 * @param  {Array} lineDataBaseValue  Argument to normalize forecast area data
 * @return {{x,y,y0}[]} Acumulated area data
 */
function accumulateArea({ areaData, cumulativeType, lineDataBaseValue = [] }) {
    let result = areaData

    // If acumulation type was sum per month, sum all values or monthly basis
    if (cumulativeType === "sum_per_month") {
        result = [
            // Group all data based on the year and month
            ...d3.group(areaData, (d) => {
                let dt = new Date(d.x)
                return dt.getFullYear() + "-" + dt.getMonth()
            }),
        ]
            // Map grouped data to area data format
            .map(([key, entries]) => {
                const [year, month] = key.split("-")
                return {
                    x: new Date(year, month, 15).getTime(), // Use middle of month as main value
                    y: +d3.sum(entries, (d) => d.y ?? d.y1).toFixed(2), // Sum up maximum y values
                    y0: +d3.sum(entries, (d) => d.y0).toFixed(2), // sum Up minimum y values
                }
            })
    }

    if (cumulativeType === "cumulative_sum_per_month") {
        // Filter out first element
        const fitleredAreaData = areaData.filter((d, i) => i)

        // Retrieve only necessary climd ata
        const lineDataClim = lineDataBaseValue.filter((d) => d.x <= fitleredAreaData[0].x)
        //.filter((d, i, arr) => i !== arr.length - 1)
        let sumPerMonth = [
            // Group all data based on the year and month
            ...d3.group(fitleredAreaData, (d) => {
                let dt = new Date(d.x)
                return dt.getFullYear() + "-" + dt.getMonth()
            }),
        ]
            // Map grouped data to area data format
            .map(([key, entries]) => {
                const [year, month] = key.split("-")
                return {
                    x: new Date(year, month, 15).getTime(), // Use middle of month as main value
                    y: +d3.sum(entries, (d) => d.y ?? d.y1).toFixed(4), // Sum up maximum y values
                    y0: +d3.sum(entries, (d) => d.y0).toFixed(4), // sum Up minimum y values
                }
            })

        // Take missing line data (historical) into account if passed
        let baseValueAccumulated = lineDataClim
            .map((d) => ({
                x: d.x,
                y: d.y,
                y0: d.y,
            }))
            .concat(sumPerMonth)

        result = []

        // Cumulative sum  values
        baseValueAccumulated.forEach((d) => {
            if (!result.length) {
                result.push(d)
            } else {
                const last = result[result.length - 1]
                result.push({ x: d.x, y: last.y + d.y, y0: last.y0 + d.y0 })
            }
        })

        result = result.filter((d) => d.x > lineDataBaseValue[lineDataBaseValue.length - 1].x)
    }
    return result
}

// // assemble line data for hourly and daily (include wind direction)
// export function assembleWindSpeedLineData({
//     propVar,
//     selectedGranularity,
//     hourlyData,
//     historical,
//     forecast,
// }) {

// }

// Assemble lines data
export function assembleLineData({
    isMonthly,
    propVar,
    selectedGranularity,
    hourlyData,
    historical,
    forecast = [],
    seasonal,
    isCumulative,
    cumulativeType,
    color,
    radius,
    strokeWidth,
    stroke,
    fill,
}) {
    if (selectedGranularity === "hourly") {
        const hourlyForecast = hourlyData.ds_fc.time.map((d, i) => {
            return { x: new Date(d), y: hourlyData.ds_fc[propVar][0.5]?.[i] }
        })

        const trimF = hourlyDataTrim(hourlyForecast)

        const currDate = new Date()
        trimF.filter((d) => d.x >= currDate).forEach((d) => Object.assign(d, { dashed: true }))

        // Assemble daily view lines (and filter out similar date records)
        const assembledLines = trimF
            .filter((d, i, arr) => {
                if (i && +d.x === +arr[i - 1].x) return false
                return true
            })
            .map((d) => Object.assign(d, { visible: true, color, radius, strokeWidth, stroke, fill }))

        return assembledLines
    }

    // If we have monthly view
    if (isMonthly) {
        // Assemple monthly view data
        const monthlyData = historical.concat(forecast.map((d) => Object.assign(d, { dashed: true })))
        let result = monthlyData

        // If seasonal data was passed, include it into foracasting
        if (monthlyData && monthlyData.length && seasonal && seasonal.length) {
            // Get next 6 month data
            const sixMonthsAhead = addMonths(new Date(), 6)

            // Filter seasonal data to stay within next six months range
            const filteredSeasonalData = seasonal
                .filter((d) => d.x > monthlyData[monthlyData.length - 1].x)
                .filter((d) => d.x < sixMonthsAhead)

            const dashedSeasonal = filteredSeasonalData.map((d) => Object.assign(d, { dashed: true }))

            if (dashedSeasonal.length > 7) {
                // Interpolate intemediate values
                dashedSeasonal
                    .filter((d, i) => i < 7)
                    .forEach((d, i) => {
                        d.y = interpolateNumber(monthlyData[monthlyData.length - 1].y, dashedSeasonal[7].y, i / 7)
                    })
            }

            // Concat results (Accumulate if such options are passed)
            return isCumulative
                ? accumulateLine({ lineData: monthlyData.concat(dashedSeasonal), cumulativeType: cumulativeType }).map(
                      (d) => Object.assign(d, { visible: false })
                  )
                : monthlyData.concat(dashedSeasonal).map((d) => Object.assign(d, { visible: false }))
        }
        // Concat results (Accumulate if such options are passed)
        return isCumulative ? accumulateLine({ lineData: result, cumulativeType: cumulativeType }) : result
    } else {
        // Trim to daily view data
        const trimmed = trimmData(historical)

        // Trim and map forecasted daily view data
        const forecastedLines = trimmData(forecast).map((d) => Object.assign(d, { dashed: true }))

        // Assemble daily view lines (and filter out similar date records)
        const assembledLines = trimmed
            .concat(forecastedLines.filter((d) => d.x > trimmed[trimmed.length - 1].x))
            .filter((d, i, arr) => {
                if (i && +d.x === +arr[i - 1].x) return false
                return true
            })
            .map((d) => Object.assign(d, { visible: true }))
        return assembledLines
    }
}

/**
 * Accumulation of line data
 * @param  {{x,y}[]} lineData
 * @param  {string} cumulativeType  sum_per_month (sum up values on monthly basis)
 * @return {{x,y}[]} Acumulated area data
 */
function accumulateLine({ lineData, cumulativeType }) {
    // Get first day of month
    let firstDayOfMonth = new Date().setDate(31)
    // Save result
    let result = lineData
    if (cumulativeType === "sum_per_month") {
        // If cumulative type was sum_per_month (group data and map to line format)
        result = [
            ...d3.group(lineData, (d) => {
                let dt = new Date(d.x)
                return dt.getFullYear() + "-" + dt.getMonth()
            }),
        ].map(([key, entries]) => {
            const [year, month] = key.split("-")
            let x = new Date(year, month, 15).getTime()
            return {
                x,
                y: +d3.sum(entries, (d) => d.y ?? d.y).toFixed(2),
                dashed: firstDayOfMonth <= x,
            }
        })
    }

    if (cumulativeType === "cumulative_sum_per_month") {
        const sumPerMonth = [
            // Group all data based on the year and month
            ...d3.group(lineData, (d) => {
                let dt = new Date(d.x)
                return dt.getFullYear() + "-" + dt.getMonth()
            }),
        ]
            // Map grouped data to area data format
            .map(([key, entries]) => {
                const [year, month] = key.split("-")
                let x = new Date(year, month, 15).getTime()
                return {
                    x,
                    y: +d3.sum(entries, (d) => d.y ?? d.y).toFixed(2),
                    dashed: firstDayOfMonth <= x,
                }
            })

        result = []

        // Cummulative sum  values
        sumPerMonth.forEach((d) => {
            if (!result.length) {
                result.push(d)
            } else {
                const last = result[result.length - 1]
                result.push({ x: d.x, y: last.y + d.y, dashed: d.dashed })
            }
        })
    }

    return result
}

// Function to transform shaded ranges data
export function convertToShadedRangesFormat(sampleAlertsData, keys = ["t2m_max", "t2m_min"]) {
    let alertKey = ""
    return keys
        .map((key) => {
            alertKey = key
            return sampleAlertsData[key] // Retrieve alert objects
        })
        .map((alerts) => {
            return Object.keys(alerts).map((key) => alerts[key]) // Get alert objecy values
        })
        .flat() // flat out array
        .map((alert) => {
            // Create new alert for each values object
            return alert.values
                .map((value) => {
                    const IconComponent = getRiskIcon(alertKey, alert.title)
                    return {
                        start: new Date(value.start_time),
                        end: new Date(value.end_time),
                        label: ReactDOMServer.renderToStaticMarkup(
                            <div className="flex flex-row content-center items-center w-full space-x-2">
                                <span className="shrink-0 w-[16px] h-[16px] fill-gray-60">
                                    {IconComponent && <IconComponent />}
                                </span>
                                <span className="truncate pb-px">{alert.title}</span>
                            </div>
                        ),
                        tooltipLabel: ReactDOMServer.renderToStaticMarkup(
                            <div className="flex flex-row items-center w-full space-x-2">
                                <span className="shrink-0 w-[16px] h-[16px] fill-gray-60">
                                    {IconComponent && <IconComponent />}
                                </span>
                                <div>{alert.title}</div>
                            </div>
                        ),
                        metadata: alert.metadata || "",
                    }
                })
                .map((d) => {
                    // Intercept one day alers and make them compatible to shaded ranges view
                    if (+d.start === +d.end) {
                        d.end.setTime(d.end.getTime() + 12 * 60 * 60 * 1000)
                        d.start.setTime(d.start.getTime() - 12 * 60 * 60 * 1000)
                    }
                    return d
                })
        })
        .flat() // Flat out again
}

// Function to process polygon data and selected fields data
export function processPolygonData({ selectedFieldIds, polygons, fields = [] }, units) {
    const selectedFields = fields.filter((field) => selectedFieldIds.includes(field.uuid))
    const data = selectedFields
        .filter((sf) => polygons[sf.uuid]) // Filter out fields, which do not have corresponding polygons
        .filter((sf) => polygons[sf.uuid].center) // Filter out fields with no associated polygon data
        .map((sf) => {
            return {
                id: sf.uuid,
                lat: +sf.polygon.center.lat,
                lon: +sf.polygon.center.lon,
                name: sf.name,
                crop: sf.crop,
                variety: sf.variety,
                region: sf.region,
                polygon: polygons[sf.uuid]?.field_polygon,
                temp: +convertTemperatureValueOnly("metric", units, sf.weather_variables.t2m.data.t2m_max),
                maxTemp: +convertTemperatureValueOnly("metric", units, sf.weather_variables.t2m.data.t2m_max),
                minTemp: +convertTemperatureValueOnly("metric", units, sf.weather_variables.t2m.data.t2m_min),
                precipitation: +convertWaterLengthValueOnly("metric", units, sf.weather_variables.tp.data.tp_sum),
                alerts: sf.triggered_alerts,
                alert_types_count: sf.alert_types_count,
            }
        })

    // Prepare and return result
    const result = {
        data: data,
        units: units,
        variables: [
            {
                iconType: "temperature",
                name: "Tmrws. Temperature",
                colors: ["#FAC3A8", "#FC2024"],
                value: "temp",
                unit: units === "metric" ? "°C" : "°F",
            },
            {
                iconType: "precipitation",
                name: "Tmrws. Precipitation",
                colors: ["#D1E8F0", "#3B77B5"],
                value: "precipitation",
                unit: units === "metric" ? "mm" : "in",
            },
        ],
    }
    return result
}

// Define custom varying gradient color scale
export function scaleColor(d3) {
    let colors,
        values,
        gradient = false
    function scale(value) {
        if (value >= values[values.length - 1]) {
            return colors[colors.length - 1]
        }
        if (value < values[0]) {
            return colors[0]
        }
        let out
        for (let i = 0, l = values.length - 1; i < l; i++) {
            const nextIndex = i === values.length - 1 ? i : i + 1
            const curr = values[i]
            const next = values[nextIndex]
            if (value >= curr && value < next) {
                const range = next - curr
                if (gradient) {
                    const distance = value - curr
                    out = d3.interpolate(colors[i], colors[nextIndex])(distance / range)
                } else {
                    out = colors[i]
                }
                break
            }
        }
        return out
    }
    scale.colors = function (_) {
        return arguments.length ? ((colors = _), scale) : colors
    }

    scale.values = function (_) {
        return arguments.length ? ((values = _), scale) : values
    }

    scale.gradient = function (_) {
        return arguments.length ? ((gradient = _), scale) : gradient
    }
    return scale
}

/**
 * Creates cumulative forecast data in area or line chart format - depending on confidence level passed
 */
export function assembleCumulativeData({ forecastData, confidenceLevel, variable, climCumulativeData }) {
    try {
        let result = null

        // Transform 0.95 confidence data to area graph format
        if (+confidenceLevel === 0.05 || confidenceLevel === 0.95) {
            result = forecastData[variable]["0.05"].map((d, i) => {
                return {
                    x: addDays(new Date(forecastData.time[i]), 15),
                    y: +forecastData[variable]["0.95"][i],
                    y0: +forecastData[variable]["0.05"][i],
                }
            })
        } else if (+confidenceLevel === 0.75 || confidenceLevel === 0.25) {
            // Transform 0.95 confidence data to area graph format
            result = forecastData[variable]["0.25"].map((d, i) => {
                return {
                    x: addDays(new Date(forecastData.time[i]), 15),
                    y: +forecastData[variable]["0.75"][i],
                    y0: +forecastData[variable]["0.25"][i],
                }
            })
        } else {
            // Concat cumulative clim data to cumulative forecast
            const climCumulativeLineData = climCumulativeData
                .filter((d) => !d.dashed)
                .filter((d, i, arr) => i !== arr.length - 1)
            const forecastLineData = forecastData.time.map((strDate, i) => {
                return {
                    x: addDays(new Date(strDate), 15),
                    y: forecastData[variable]["0.5"][i],
                    dashed: true,
                }
            })

            // Add intermediate point to divide forecast & climatology points, so it's easier for user to understand
            const result = climCumulativeLineData
                .concat([
                    {
                        x: endOfMonth(climCumulativeLineData[climCumulativeLineData.length - 1].x),
                        y: (climCumulativeLineData[climCumulativeLineData.length - 1].y + forecastLineData[0].y) / 2,
                        invisible: true,
                    },
                ])
                .concat(forecastLineData)
            return result
        }
        return result
    } catch (err) {
        console.log(err)
        return []
    }
}

// Function to load current unit symbol and value if necessary
export function getUnit({ system = "metric", value = 0 }) {
    // Define initial metric values
    let tempUnit = "°C"
    let temp = value
    let precipUnit = "mm"
    let precip = value
    let windSpeedUnit = "km/h"
    let windSpeed = value
    let evapUnit = "mm"
    let evap = value

    // If imperial system was passed, convert values accordingly
    if (system === "imperial") {
        tempUnit = "°F"
        temp = +convertTemperatureValueOnly("metric", "imperial", temp)
        precipUnit = "in"
        precip = +convertWaterLengthValueOnly("metric", "imperial", precip)
        windSpeedUnit = "MPH"
        windSpeed = value = convertSpeedWindValueOnly("metric", "imperial", windSpeed)
        evapUnit = "in"
        evap = +convertWaterLengthValueOnly("metric", "imperial", evap)
    }

    // Return corresponding values
    return {
        tempUnit,
        temp,
        precipUnit,
        precip,
        windSpeedUnit,
        windSpeed,
        evapUnit,
        evap,
    }
}

// Function to map area or line values according to current variable type and metric vs imperial system
export function processUnitSystem(d, { system = "metric", type = "temp" }) {
    const item = Object.assign({}, d)

    // if (type === "ws") {
    //     ['y1', 'y0', 'y'].forEach(key => {
    //         if (item[key] !== undefined) {
    //             item[key] = meterPerSecondToKilometerPerHour(item[key])
    //         }
    //     })
    // }

    if (system === "imperial") {
        if (item.max) {
            item.y = item.max
        }
        if (item.min) {
            item.y0 = item.min
            item.y = item.min
        }
        if (item.y1 !== undefined) {
            if (type === "temp") {
                item.y1 = +convertTemperatureValueOnly("metric", system, item.y1)
            }
            if (type === "precip") {
                item.y1 = +convertWaterLengthValueOnly("metric", system, item.y1)
            }
            if (type === "ws") {
                item.y1 = +convertSpeedValueOnly("metric", system, item.y1)
            }
            if (type === "ws_custom") {
                if (system == "imperial") {
                    item.y1 = convertWindSpeedKmhToMph(item.y1)
                }
            }
            if (type == "evapotranspiration") {
                item.y1 = +convertWaterLengthValueOnly("metric", system, item.y1)
            }
        }

        if (item.y !== undefined) {
            if (type === "temp") {
                item.y = +convertTemperatureValueOnly("metric", system, item.y)
            }
            if (type === "precip") {
                item.y = +convertWaterLengthValueOnly("metric", system, item.y)
            }
            if (type === "ws") {
                item.y = +convertSpeedValueOnly("metric", system, item.y)
            }
            if (type === "ws_custom") {
                if (system == "imperial") {
                    item.y = convertWindSpeedKmhToMph(item.y)
                }
            }
            if (type == "evapotranspiration") {
                item.y = +convertWaterLengthValueOnly("metric", "imperial", item.y)
            }
        }
        if (item.y0 !== undefined) {
            if (type === "temp") {
                item.y0 = +convertTemperatureValueOnly("metric", system, item.y0)
            }
            if (type === "precip") {
                item.y0 = +convertWaterLengthValueOnly("metric", system, item.y0)
            }
            if (type === "ws") {
                item.y0 = +convertSpeedValueOnly("metric", system, item.y0)
            }
            if (type === "ws_custom") {
                if (system == "imperial") {
                    item.y0 = convertWindSpeedKmhToMph(item.y0)
                }
            }
            if (type == "evapotranspiration") {
                item.y0 = +convertWaterLengthValueOnly("metric", "imperial", item.y0)
            }
        }
        if (item.values !== undefined) {
            let props = ["y1", "y0", "yMid", "yMax", "yMin"]
            item.values.forEach((v) => {
                props.forEach((p) => {
                    if (v[p] !== undefined) {
                        if (type === "temp") {
                            v[p] = v[p].map((d) => +convertTemperatureValueOnly("metric", "imperial", d))
                        }
                        if (type === "precip") {
                            v[p] = v[p].map((d) => +convertWaterLengthValueOnly("metric", "imperial", d))
                        }
                        if (type === "ws") {
                            v[p] = v[p].map((d) => +convertSpeedWindValueOnly("metric", "imperial", d))
                        }
                        if (type == "evapotranspiration") {
                            v[p] = v[p].map((d) => +convertWaterLengthValueOnly("metric", "imperial", d))
                        }
                        if (type === "ws_custom") {
                            v[p] = v[p].map((d) => {
                                if (system == "imperial") {
                                    return convertWindSpeedKmhToMph(d)
                                }
                                return d
                            })
                        }
                    }
                })
            })
        }
    }
    return item
}

// Function to get value based on the unit type
export function getUnitValue(d, { system = "metric", type = "temp" }) {
    let item = d
    if (system === "imperial") {
        if (type === "temp") {
            item = +convertTemperatureValueOnly("metric", "imperial", item)
        }
        if (type === "precip") {
            item = +convertWaterLengthValueOnly("metric", "imperial", item)
        }
        if (type === "ws") {
            item = +convertSpeedWindValueOnly("metric", "imperial", item)
        }
    }
    return item
}

// Function to transform response data to relevant units
export function transformWithUnit(responseData, system, props) {
    if (system === "imperial") {
        const resultUnit = [responseData.ds_clim, responseData.ds_fc, responseData.ds_hist]
            .filter((d) => d)
            .map((dt) => props.map((prop) => dt[prop]))
            .flat()
            .filter((d) => d)
        resultUnit.forEach((result) => {
            let items = null
            if (Array.isArray(result)) {
                items = [result]
            } else {
                items = Object.keys(result).map((k) => result[k])
            }
            for (let i = 0; i < items.length; i++) {
                for (let j = 0; j < items[i].length; j++) {
                    items[i][j] = +convertTemperatureValueOnly("metric", "imperial", items[i][j])
                }
            }
        })
    }
    return responseData
}
/**
 * Filter out invalid data records
 */
export function filterValidLineRecords(d, i, arr) {
    if (i === arr.length - 1) {
        return d
    }
    return arr[i + 1].x > d.x
}

export function convertToBarObj({ maxObj, minObj, props }) {
    const { barType, median, color, width } = props

    if (barType == "candl") {
        const result = {
            type: "bar",
            ...props,
            points: Object.keys(maxObj).map((month) => {
                return {
                    ...props,
                    width,
                    x: new Date(`${month} 15 `),
                    y: maxObj[month],
                    y0: minObj[month],
                }
            }),
        }
        return result
    }
}

export function convertToDoubleCandlestick({ obj, colors, unit, unitType, convert = true }) {
    const strDates = Object.keys(obj[0].values[0]["0.05"])
    let points = strDates.map((strDate, i) => {
        return {
            x: new Date("16 " + strDate),
            values: obj.map((d) => {
                return {
                    isMax: d.isMax,
                    y1: d.values.map((v) => v[0.95][strDate]).filter((d) => d != null),
                    y0: d.values.map((v) => v[0.05][strDate]).filter((d) => d != null),
                    yMid: d.values.map((v) => v[0.5][strDate]).filter((d) => d != null),
                    yMax: d.values.map((v) => v[0.75][strDate]).filter((d) => d != null),
                    yMin: d.values.map((v) => v[0.25][strDate]).filter((d) => d != null),
                }
            }),
        }
    })

    if (convert) {
        points = points.map((d) => {
            return processUnitSystem(d, { system: unit, type: unitType })
        })
    }

    return {
        type: "candlestick",
        color: (pointIndex, groupIndex, candleIndex) => {
            if (candleIndex) return "gray"
            if (groupIndex) return colors[1]
            return colors[0]
        },
        points,
    }
}

export function convertToDoubleCandlestickWeekly({ obj, colors, unit, unitType, convert = true }) {
    const strDates = Object.keys(obj[0].values[0]["0.05"])
    let points = strDates.map((strDate, i) => {
        return {
            x: new Date(strDate),
            values: obj.map((d) => {
                return {
                    isMax: d.isMax,
                    y1: d.values.map((v) => v[0.95][strDate]).filter((d) => d != null),
                    y0: d.values.map((v) => v[0.05][strDate]).filter((d) => d != null),
                    yMid: d.values.map((v) => v[0.5][strDate]).filter((d) => d != null),
                    yMax: d.values.map((v) => v[0.75][strDate]).filter((d) => d != null),
                    yMin: d.values.map((v) => v[0.25][strDate]).filter((d) => d != null),
                }
            }),
        }
    })

    if (convert) {
        points = points.map((d) => processUnitSystem(d, { system: unit, type: unitType }))
    }
    return {
        type: "candlestick",
        color: (pointIndex, groupIndex, candleIndex) => {
            if (candleIndex) return "gray"
            if (groupIndex) return colors[1]
            return colors[0]
        },
        points,
    }
}

export function convertToLineFromBar({ obj, props }) {
    const { barType, unitType, unit, visible, getPointColor, date, convert = true } = props
    if (!obj) return null

    const result = {
        type: "line",
        ...props,
        points: Object.keys(obj).map((month) => {
            const monthSplit = month.split(" ")
            const dateStr = [monthSplit[0], date ?? 15, monthSplit[1]].filter((d) => d).join(" ")
            const newDate = new Date(`${dateStr} `)
            return {
                x: isDate(newDate) ? newDate : addDays(month, -3),
                y: obj[month],
                visible,
                color: "lightgrey",
                fill: "lightgrey",
                strokeWidth: 0.5,
                stroke: "grey",
                radius: 20,
                getPointColor,
            }
        }),
    }

    if (convert) {
        result.points = result.points.map((d) => {
            return processUnitSystem(d, { system: unit, type: unitType })
        })
    }
    return result
}

export function convertToLineFromBarWeekly({ obj, props }) {
    const { barType, unitType, unit, visible, getPointColor, date, hours } = props
    if (!obj) return null
    return {
        type: "line",
        ...props,
        points: Object.keys(obj)
            .map((month) => {
                const dt = new Date(month)
                dt.setHours(hours || 12)
                dt.setDate(date || dt.getDate())
                return {
                    x: dt,
                    y: obj[month],
                    visible,
                    color: "lightgrey",
                    radius: 20,
                    getPointColor,
                }
            })
            .map((d) => processUnitSystem(d, { system: unit, type: unitType })),
    }
}

export function convertDataToBarStructureWeekly({ data, property = "t2m_mean" }) {
    const result = {}
    const source = data[property]

    const keys = ["0.05", "0.25", "0.5", "0.75", "0.95"]
    const weekNames = data.time.map((t) => {
        const d = new Date(t)
        d.setDate(d.getDate() + 3)
        d.setHours(12)
        return d.toISOString()
    })

    keys.forEach((key) => {
        const weeks = {}
        weekNames.forEach((week, i) => {
            const valuesObj = source[key]
            weeks[week] = valuesObj[i]
        })
        result[key] = weeks
    })
    return result
}

export function convertDataToBarStructure({ data, timeSyncData, property = "t2m_mean" }) {
    const result = {}
    const dateMapping = {}
    const source = data[property]
    const keys = ["0.05", "0.25", "0.5", "0.75", "0.95"]
    const getReadableMonthName = (d) => new Date(d).toLocaleString("eng", { month: "short", year: "numeric" })
    const monthNames = data.time.map((d) => getReadableMonthName(d))
    if (timeSyncData) {
        const climTimes = timeSyncData.clim.time
        const forTimes = timeSyncData.for.time
        const readableClimTimes = climTimes.map((d) => getReadableMonthName(d))
        const readableForTimes = forTimes.map((d) => getReadableMonthName(d))
        const readableClimTimesMap = new Map(
            readableClimTimes.map((d, i) => [
                d,
                {
                    readable: d,
                    initial: climTimes[i],
                },
            ])
        )
        const readableForTimesMap = new Map(
            readableForTimes.map((d, i) => [
                d,
                {
                    readable: d,
                    initial: forTimes[i],
                },
            ])
        )
        readableForTimes.forEach((forecastTime) => {
            if (!readableClimTimesMap.has(forecastTime)) {
                const dt = readableForTimesMap.get(forecastTime).initial
                // Try 1 year before
                let year = addYears(new Date(dt), -1)
                let readableYear = getReadableMonthName(year)
                dateMapping[readableYear] = forecastTime
                // Try 1 year later
                if (!readableClimTimesMap.has(readableYear)) {
                    year = addYears(new Date(dt), 1)
                    readableYear = getReadableMonthName(year)
                }
                dateMapping[readableYear] = forecastTime
            }
        })
    }
    keys.forEach((key) => {
        const months = {}
        monthNames.forEach((month, i) => {
            const monthFinal = dateMapping[month] || month
            const valuesObj = source[key]
            months[monthFinal] = valuesObj[i]
        })
        result[key] = months
    })
    return result
}

export function convertAPIDataToSeasonalBarStructure({ climatology, forecast, property }) {
    const result = {}
    const dateMapping = {}
    if (!climatology.time.length || !forecast.time.length) {
        return null
    }
    const getReadableMonthName = (d) => new Date(d).toLocaleString("eng", { month: "short", year: "numeric" })
    const monthNames = forecast.time.map((d) => getReadableMonthName(d))

    const climMonthNames = climatology.time.map((d) => getReadableMonthName(d))

    {
        const climTimes = climatology.time
        const forTimes = forecast.time
        const readableClimTimes = climTimes.map((d) => getReadableMonthName(d))
        const readableForTimes = forTimes.map((d) => getReadableMonthName(d))
        const readableClimTimesMap = new Map(
            readableClimTimes.map((d, i) => [
                d,
                {
                    readable: d,
                    initial: climTimes[i],
                },
            ])
        )
        const readableForTimesMap = new Map(
            readableForTimes.map((d, i) => [
                d,
                {
                    readable: d,
                    initial: forTimes[i],
                },
            ])
        )
        readableForTimes.forEach((forecastTime) => {
            if (!readableClimTimesMap.has(forecastTime)) {
                const dt = readableForTimesMap.get(forecastTime).initial
                // Try 1 year before
                let year = addYears(new Date(dt), -1)
                let readableYear = getReadableMonthName(year)
                dateMapping[readableYear] = forecastTime
                // Try 1 year later
                if (!readableClimTimesMap.has(readableYear)) {
                    year = addYears(new Date(dt), 1)
                    readableYear = getReadableMonthName(year)
                }
                dateMapping[readableYear] = forecastTime
                dateMapping[forecastTime] = readableYear
            }
        })
    }

    const keys = {
        top: (source, i) => (source["0.33-0.67"][i] + source["0.67-1"][i]) * 100, //warmer_top
        middle_top: (source, i) => source["0.33-0.67"][i] * 100,
        middle_bottom: (source, i) => (source["0.33-0.67"][i] / 2) * 100,
        bottom: (source, i) => (-source["0.33-0.67"][i] / 2 - source["0-0.33"][i]) * 100, //colder_bottom

        normalMax: (source, i) => source["0.67-1"][i] * 100, // Warmer
        normal: (source, i) => source["0.33-0.67"][i] * 100, // Normal
        normalMin: (source, i) => source["0-0.33"][i] * 100, // Colder
        clim67: (_1, _2, clim, monthIndex) => clim["0.67"][monthIndex],
        clim33: (_1, _2, clim, monthIndex) => clim["0.33"][monthIndex],
    }

    Object.keys(keys).forEach((key) => {
        result[key] = {}
        monthNames.forEach((month, i) => {
            const monthFinal = dateMapping[month] || month
            const climMonthIndex = climMonthNames.indexOf(monthFinal)
            result[key][month] = keys[key](forecast[property], i, climatology[property], climMonthIndex)
        })
    })

    return result
}

export function convertAPIDataToSeasonalBarStructureWeekly({ climatology, forecast, property }) {
    const result = {}
    if (!climatology.time.length || !forecast.time.length) {
        return null
    }

    const weekNames = forecast.time.map((t) => {
        const d = new Date(t)
        d.setDate(d.getDate() + 3)
        d.setHours(12)
        return d.toISOString()
    })

    const climWeekNames = climatology.time.map((t) => {
        const d = new Date(t)
        d.setDate(d.getDate() + 3)
        d.setHours(12)
        return d.toISOString()
    })

    const keys = {
        top: (source, i) => (source["0.33-0.67"][i] + source["0.67-1"][i]) * 100, //warmer_top
        middle_top: (source, i) => source["0.33-0.67"][i] * 100,
        middle_bottom: (source, i) => (source["0.33-0.67"][i] / 2) * 100,
        bottom: (source, i) => (-source["0.33-0.67"][i] / 2 - source["0-0.33"][i]) * 100, //colder_bottom

        normalMax: (source, i) => source["0.67-1"][i] * 100, // Warmer
        normal: (source, i) => source["0.33-0.67"][i] * 100, // Normal
        normalMin: (source, i) => source["0-0.33"][i] * 100, // Colder
        clim67: (_1, _2, clim, monthIndex) => {
            return clim["0.67"][monthIndex]
        },
        clim33: (_1, _2, clim, monthIndex) => clim["0.33"][monthIndex],
    }

    Object.keys(keys).forEach((key) => {
        result[key] = {}
        weekNames.forEach((week, i) => {
            const climWeekIndex = climWeekNames.indexOf(week)
            result[key][week] = keys[key](forecast[property], i, climatology[property], climWeekIndex)
        })
    })

    return result
}

export function renderArrows(maxValue, normalMin, normal, normalMax) {
    return ReactDOMServer.renderToStaticMarkup(
        <div
            style={{
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    width: 15,
                    height: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // transform: (maxValue === normalMin && "rotate(180deg)") || "",
                }}
            >
                {((maxValue === normalMin || maxValue === normalMax) && (
                    <WeatherArrowIcon
                        transform={(maxValue === normalMin && "rotate(180, 7, 7)") || undefined}
                        fill={(maxValue === normalMin && "#155ECC") || "#E00028"}
                    />
                )) ||
                    (maxValue === normal && <WeatherEqualsIcon fill="#666D74" />) ||
                    null}
            </div>
        </div>
    )
}

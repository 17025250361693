import React, { useState, useEffect, useReducer, useContext } from "react"
import LongTermContext from "./LongTermContext"
import ReportReducer from "./components/ReportReducer"
import LTNavbar from "./components/LTNavbar"
import LongTermMap from "./LongTermMap"
import "./LongTerm.css"
import http from "../../Util/http"

const REPORTS = [
    // { region: "Kanpur", crops: [""], lastUpdate: "Sept/12/2021", coordinates: [80.3319, 26.4499], alerts: 0 },
    // { region: "India", crops: ["Tomato"], lastUpdate: "Sept/12/2021", coordinates: [78.0081, 27.1767], alerts: 0 },
]

const initialState = {
    inputSearch: "",
    labelSearch: [],
}

const INPUT_SEARCH = "INPUT_SEARCH"
const ADD_LABEL_SEARCH = "ADD_LABEL_SEARCH"
const DELETE_LABEL_SEARCH = "DELETE_LABEL_SEARCH"

function LongTerm() {
    const [reports, setReports] = useState([])
    const [search, dispatch] = useReducer(ReportReducer, initialState)

    function handleInputSearch(e) {
        let inputValue = e.target.value
        dispatch({
            type: INPUT_SEARCH,
            payload: { inputSearch: inputValue },
        })
    }

    function handleLabelSearch(e) {
        const { value, status } = e
        // false is equals to selected
        if (status) {
            dispatch({
                type: DELETE_LABEL_SEARCH,
                payload: { labelSearch: value },
            })
        } else {
            dispatch({
                type: ADD_LABEL_SEARCH,
                payload: { labelSearch: value },
            })
        }
    }

    useEffect(() => {
        http.get("/api/v1/adapt/reports/")
            .then((data) => {
                const new_reports = []
                data.reports.forEach((report) => {
                    const firstLoc = report.locations[Object.keys(report.locations)[0]]
                    const { lon: lng, lat } = firstLoc.coords

                    new_reports.push({
                        crops: [firstLoc.region],
                        region: JSON.parse(firstLoc.crops.replaceAll("'", '"'))[0],
                        lastUpdate: "Sept/12/2021",
                        coordinates: [lng > 180 ? lng - 360 : lng, lat > 90 ? lat - 180 : lat],
                        alerts: 0,
                        reportId: report.id,
                    })
                })
                setReports(new_reports)
            })
            .catch(console.log)
    }, [])

    return (
        <>
            <LongTermContext.Provider value={{ layout: "navigation" }}>
                <div className="long-term">
                    <div className="long-term__navbar">
                        <LTNavbar labelSearch={handleLabelSearch} inputSearch={handleInputSearch} reports={reports} />
                    </div>
                    <div className="long-term__map">
                        <LongTermMap reports={reports} />
                    </div>
                </div>
            </LongTermContext.Provider>
        </>
    )
}

export default LongTerm

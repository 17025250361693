import { useContext } from "react"
import { DataContext } from "../../Util/Data"
import ReportsView from "./ReportsView"

const CustomReports = ({}) => {
    const {
        state: { customReports },
    } = useContext(DataContext)

    return <ReportsView reports={customReports} />
}

export default CustomReports

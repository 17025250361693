import "react-datepicker/dist/react-datepicker.css"
import Section from "../../../ui/Section"
import AnalogsMapLegend from "./AnalogsMapLegend"
import MapboxGenericMap from "../../../components/Charts/MapboxMaps/MapboxGenericMap"
import { getAnalogMarkerPopup } from "../../../components/Charts/MapboxMaps/mapboxUtils"
import Modal from "../../../components/Modal/Modal"

function AnalogsChart(props) {
    const { loading, mapHeatmap, mapPins } = props

    return (
        <div className="min-w-xl min-h-[600px] grow">
            <Section
                title="Climate Analogs"
                description="This page displays the regions that will have analogous climate conditions compared to the reference location during a target decade in the future (e.g. 2040-2050)."
                header={() => (
                    <div className="flex flex-row grow text-gray-60 roboto text-[14px] items-center lg:pl-2 lg:-mr-[2rem] space-x-[10px]">
                        <label>Least Similar - 0</label>
                        <div
                            className="grow h-[9px] min-w-[100px]"
                            style={{ background: "linear-gradient(90deg, #FFFFFF, #1A968F)" }}
                        ></div>
                        <label>Most Similar - 10</label>
                    </div>
                )}
            >
                <div className="flex-1 w-full h-full">
                    <MapboxGenericMap
                        data={mapPins}
                        heatmap={mapHeatmap}
                        markerPopup={getAnalogMarkerPopup}
                        mapDefaultLayer="mapbox://styles/mapbox/light-v10"
                        canToggleMapStyle={true}
                        renderWorldCopies={false}
                        customFitTo={[
                            [-80, -10],
                            [100, 40],
                        ]}
                        minZoom={0.0}
                    >
                        {loading && (
                            <div className="absolute inset-0 z-30 bg-gray-30/[.7]">
                                <Modal isAbsolute title="Calculating your climate analogs" type="loading" />
                            </div>
                        )}
                        <AnalogsMapLegend />
                    </MapboxGenericMap>
                </div>
            </Section>
        </div>
    )
}

export default AnalogsChart

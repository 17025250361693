import { useMemo } from "react"

export default function usePortfolioData(data) {
    return useMemo(() => {
        let chartData = {
            xLabels: [],
            yLabels: [],
            values: [],
            colorValues: [],
        }

        chartData.values = []
        Object.keys(data).forEach((yKey, yIndex) => {
            // push both keys, they are the same
            chartData.xLabels.push(yKey)
            chartData.yLabels.push(yKey)
            chartData.values.push([])
            Object.keys(data[yKey]).forEach((xKey, xIndex) => {
                chartData.values[yIndex].push(data[yKey][xKey])
            })
        })

        chartData.colorValues = [...chartData.values]

        return { chartData }
    }, [data])
}

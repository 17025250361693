import "react-datepicker/dist/react-datepicker.css"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"
import Section from "../../../ui/Section"
import useContainerSize from "../../../hooks/useContainerSize"
import LoadingBar from "../../../components/LoadingBar"
import ScenarioDropdownSelection from "../../../ui/Controls/ScenarioDropdownSelection"
import LocationDropdownSelection from "../../../ui/Controls/LocationDropdownSelection"
import VariableDropdownSelection from "../../../ui/Controls/VariableDropdownSelection"
import { getMaxYValue } from "../../../Util/candleGraphUtils"
import { useContext } from "react"
import { SettingsContext } from "../../../Util/SettingsContext"
import ExportButton from "../../../components/ExportButton"
import useCSVData from "../../../hooks/useCSVData"
import { formatSeasonalCSVData, SEASONAL_CSV_HEADERS } from "../../../Util/CSVParser"

const chartArgs = {
    labelY: "Days with Risk",
    xTicksCount: 12,
    yTicksCount: 5,
    xDateMin: new Date(2022, 0, 1),
    xDateMax: new Date(2023, 0, 1),
    centerTicks: true,
    candlePadding: 0,
    labelYOffsetX: -20,
    candleTopBottomLines: true,
    maxCandlestickWidth: 100,
    xLeftOffset: 0.2,
    xRightOffset: 0.2,
    marginLeft: 100,
}

function SeasonalTrendChart(props) {
    const {
        maxValue,
        minValue,
        loading,
        children,
        data,
        //
        locations,
        selectedLocation,
        setSelectedLocation,
        //
        scenarios,
        selectedScenario,
        setSelectedScenario,
        //
        variables,
        selectedVariable,
        setSelectedVariable,
    } = props

    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const [containerRef, { height }] = useContainerSize(1000, [selectedLocation, selectedScenario, selectedVariable])

    let labelY,
        tickFormat,
        yTickFormat = null
    if (variables && selectedVariable) {
        const selectedVariableObj = variables[selectedVariable]

        labelY =
            selectedVariableObj.label +
            " " +
            ((selectedVariableObj.units && `(${selectedVariableObj.getUnits(units)})`) || "")

        tickFormat = selectedVariableObj.units ? selectedVariableObj.getUnits(units) : ""

        yTickFormat = (d) => (["gdd", "rh", "ch"].includes(selectedVariable) && d < 0 ? "" : d)
    }

    const getVariableYTick = (labY) => {
        if (labY && labY.includes("Relative")) return 3
        return 5
        // || labY.includes("Growing") || labY.includes("Chill")
    }

    const { csvHeaders, csvData, csvString } = useCSVData(
        formatSeasonalCSVData(data),
        SEASONAL_CSV_HEADERS,
        `Seasonal Trend Data
        Location:,${!loading && locations[selectedLocation].location}
        Scenario:,${!loading && scenarios[selectedScenario].label}
        Variable:,${!loading && variables[selectedVariable].label}
        \n`,
        !loading
    )

    return (
        <>
            <Section
                id="seasonal-trend-section"
                title="Monthly Trend"
                description="This pages displays the monthly trend of the selected climate variable at a given location."
                header={() => {
                    return (
                        <>
                            <LocationDropdownSelection
                                selectedLocation={selectedLocation}
                                locations={locations}
                                setSelectedLocation={setSelectedLocation}
                                keyPrefix={"decadal_location"}
                            />
                            <VariableDropdownSelection
                                selectedVariable={selectedVariable}
                                variables={variables}
                                setSelectedVariable={setSelectedVariable}
                                keyPrefix={"decadal_variable"}
                            />
                            <ScenarioDropdownSelection
                                selectedScenario={selectedScenario}
                                scenarios={scenarios}
                                setSelectedScenario={setSelectedScenario}
                                keyPrefix={"decadal_scenario"}
                            />
                            <ExportButton
                                cols={{ csv: csvHeaders }}
                                data={{ csv: csvData }}
                                csvString={csvString}
                                wrapperClasses="absolute top-3 right-2"
                                fileName={"Monthly_trend-" + locations[selectedLocation]?.location}
                                idToExport="seasonal-trend-section"
                            />
                        </>
                    )
                }}
            >
                {loading && <LoadingBar loading />}
                {children}
                <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
                    <LineAreaChartComponent
                        labelY={labelY}
                        xTicksCount={chartArgs.xTicksCount}
                        yTicksCount={chartArgs.yTicksCount}
                        yValueMax={getMaxYValue(maxValue, minValue, getVariableYTick(labelY))}
                        // yValueMin becomes zero instead of using zeroBasis
                        yValueMin={minValue < 1 && minValue > -1 ? 0 : minValue}
                        marginLeft={chartArgs.marginLeft}
                        xDateMax={chartArgs.xDateMax}
                        xDateMin={chartArgs.xDateMin}
                        xLeftOffset={chartArgs.xLeftOffset}
                        xRightOffset={chartArgs.xRightOffset}
                        centerTicks={chartArgs.centerTicks}
                        labelYOffsetX={chartArgs.labelYOffsetX}
                        candlePadding={chartArgs.candlePadding}
                        candleTopBottomLines={chartArgs.candleTopBottomLines}
                        yTopOffset={0.2}
                        svgHeight={(height && height - 10) || 0}
                        svgWidth="100%"
                        xTickFormat={(d) => d.toLocaleDateString(undefined, { month: "long" })}
                        yTickFormat={yTickFormat}
                        shapeTip={(e, d, arr) => {
                            return `
                            <table>
                            <tr><th style="text-align:right">95th</th><td>${
                                d.y1 !== undefined && d.y1.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">75th</th><td>${
                                d.yMax !== undefined && d.yMax.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">50th</th><td>${
                                d.yMid !== undefined && d.yMid.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">25th</th><td>${
                                d.yMin !== undefined && d.yMin.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">5th</th><td>${
                                d.y0 !== undefined && d.y0.toFixed(2) + " " + tickFormat
                            }</td></tr>
                          </table>
                    `
                        }}
                        data={!loading && data}
                    ></LineAreaChartComponent>
                </div>
            </Section>
        </>
    )
}

export default SeasonalTrendChart

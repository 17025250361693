import {
    TemperatureIcon,
    PrecipitationIcon,
    SoilTemperatureIcon,
    WaterBudgetIcon,
    EvapotranspirationIcon,
    SolarRadiationIcon,
    GrowingDegreeDaysIcon,
    SoilMoistureIcon,
    WindSpeedIcon,
    RelativeHumidityIcon,
    ChillHoursIcon,
} from "../../../../ui/Icons/newIcons/weatherVariableIcons"

export const GRANULARITY_META = {
    hourly: {
        key: "hourly",
        displayName: "Hour",
        enableValidatorKey: "short_term",
    },
    daily: {
        key: "daily",
        displayName: "Day",
        enableValidatorKey: "short_term",
    },
    weekly: {
        key: "weekly",
        displayName: "Week",
        enableValidatorKey: "subseasonal",
    },
    monthly: {
        key: "monthly",
        displayName: "Month",
        enableValidatorKey: "seasonal",
    },
}

const defaultTooltip = (period, what, variable, extra) => {
    return `This graph shows the ${period} ${what} ${variable} ${extra}`
}

export const VARIABLES_META = {
    temperature_t2m: {
        hourly: false,
        daily: true,
        monthly: true,
        weekly: true,
        title: "Temperature",
        icon: TemperatureIcon,
        key: "temperature_t2m",
        tooltip: (period) =>
            defaultTooltip(
                period,
                "observed and forecasted maximum and minimum",
                "temperature",
                "that would be measured 2 meters above the surface (land or water surface)."
            ),
    },
    precipitation: {
        hourly: false,
        daily: true,
        monthly: true,
        weekly: true,
        title: "Precipitation",
        icon: PrecipitationIcon,
        key: "precipitation",
        tooltip: (period) =>
            defaultTooltip(
                period,
                "observed and forecasted",
                "precipitation",
                "accumulation which is the volume of water accumulated over an area within a day."
            ),
    },
    soil_temperature: {
        hourly: false,
        daily: true,
        monthly: true,
        weekly: false,
        title: "Soil Temperature",
        icon: SoilTemperatureIcon,
        key: "soil_temperature",
        tooltip: (period) =>
            defaultTooltip(
                period,
                "average observed and forecasted",
                "soil temperature",
                "in the first 7 centimeters of depth."
            ),
    },
    water_budget: {
        hourly: false,
        daily: true,
        monthly: false,
        weekly: false,
        title: "Water Budget",
        icon: WaterBudgetIcon,
        key: "water_budget",
        tooltip: (period) => defaultTooltip(period, "", "water budget", ""),
    },
    relative_humidity: {
        hourly: false,
        daily: true,
        monthly: true,
        weekly: false,
        title: "Relative Humidity",
        icon: RelativeHumidityIcon,
        key: "relative_humidity",
        tooltip: (period) =>
            defaultTooltip(
                period,
                "observed and forecasted",
                "relative humidity in %.",
                "Relative humidity is an indicator of the water vapor present in the environment normalized by the equilibrium water vapor at the same temperature."
            ),
    },
    evapotranspiration: {
        hourly: false,
        daily: true,
        monthly: true,
        weekly: false,
        title: "Evapotranspiration",
        icon: EvapotranspirationIcon,
        key: "evapotranspiration",
        tooltip: (period) =>
            defaultTooltip(
                period,
                "observed and forecasted",
                "evaporation rate",
                ", which is the loss of moisture from a surface by direct evaporation together with the loss of water by transpiration of vegetation."
            ),
    },
    solar_radiation: {
        hourly: false,
        daily: true,
        monthly: true,
        weekly: true,
        title: "Solar Radiation",
        icon: SolarRadiationIcon,
        key: "solar_radiation",
        tooltip: (period) =>
            defaultTooltip(period, "average observed and forecasted", "direct downward irradiance.", ""),
    },
    gdd: {
        hourly: false,
        daily: false,
        monthly: true,
        weekly: false,
        title: "Growing Degree Days",
        icon: GrowingDegreeDaysIcon,
        key: "gdd",
        tooltip: (period) =>
            defaultTooltip(
                period,
                "amount of",
                "growing degree days",
                "for your crop to estimate the growth and development during the growing season."
            ),
    },
    soil_moisture: {
        hourly: false,
        daily: true,
        monthly: true,
        weekly: false,
        title: "Soil Moisture",
        icon: SoilMoistureIcon,
        key: "soil_moisture",
        tooltip: (period) =>
            defaultTooltip(period, "average of", "water", "stored in the first 7 centimeters of depth in the soil."),
    },
    wind_speed: {
        hourly: true,
        daily: true,
        monthly: true,
        weekly: false,
        title: "Wind",
        icon: WindSpeedIcon,
        key: "wind_speed",
        tooltip: (period) => {
            return defaultTooltip(
                period,
                "average observed and forecasted",
                "horizontal wind speed" + ((period !== "hourly" && ".") || " and visualization of wind direction."),
                ""
            )
        },
    },
    chill_hours: {
        hourly: false,
        daily: false,
        monthly: true,
        weekly: false,
        title: "Chill Hours",
        icon: ChillHoursIcon,
        key: "chill_hours",
        tooltip: (period) => defaultTooltip(period, "average observed and forecasted", "Chill Hours.", ""),
    },
}

import "./HorizontalSeparator.css"

function HorizontalSeparator(props) {
    const { label } = props
    return (
        <div className="horizontal_separator">
            { label && (label) }
        </div>
    )
}

export default HorizontalSeparator

import config from "../Util/config"
import app from "../Util/Fire"
import http from "../Util/http"

const hostDomain = config.backend_server

export async function getMyReports() {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const { data } = await http.get(hostDomain + "/api/v1/custom_analytics/", {
            headers: { "User-Token": userToken },
        })

        if (!data || data.length === 0) return []

        return data.map((item) => {
            return {
                name: item.report_name,
                type: item.report_type,
                tab: item.report_tab,
                id: item.id,
                url: item.url,
                modifiedDate: item.last_modified_date,
            }
        })
    } catch (e) {
        console.error(e)
        throw new Error(e.response?.data?.message ?? "There was a problem, try again later.")
    }
}

export async function getReportData(id) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const { data } = await http.get(hostDomain + "/api/v1/custom_analytics/" + id + "?query=content", {
            headers: { "User-Token": userToken },
        })

        return data
    } catch (e) {
        console.error(e)
        throw new Error(e.response?.data?.message ?? "There was a problem, try again later.")
    }
}

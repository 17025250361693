import * as React from "react"

const ComputerIcon = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 75 75"
        className="fill-inherit"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M62.5 56.25c3.438 0 6.219-2.813 6.219-6.25l.031-31.25c0-3.438-2.813-6.25-6.25-6.25h-50c-3.438 0-6.25 2.813-6.25 6.25V50c0 3.438 2.813 6.25 6.25 6.25H0v6.25h75v-6.25H62.5Zm-50-37.5h50V50h-50V18.75Z"
            fill={props.fill}
        />
    </svg>
)

export default ComputerIcon

const MinusIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path d="M19 13H5V11H19V13Z" fill={props.fill} />
        </svg>
    )
}

export default MinusIcon

import { useMemo, useRef, useState, useEffect, useContext } from "react"
import { CircularProgress } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"

import { getHistoricalTemp, getForecastArr, getForecastTemp, getClim, trimmData } from "./helper"

import {
    convertToShadedRangesFormat,
    getForecastConfidenceData,
    addMonths,
    validateData,
    addDays,
    assembleAreaData,
    assembleLineData,
    getSevenMonthsMarginClimData,
    convertAPIDataToSeasonalBarStructure,
    convertDataToBarStructure,
    convertToLineFromBar,
    convertToDoubleCandlestick,
    renderArrows,
} from "../../../../helpers/chartHelpers"

import ChartSpecs from "../ChartSpecs"
import networking from "../../../../Util/Networking"
import { AuthContext } from "../../../../Auth/Auth"
import useLastUpdated from "../../../../hooks/useLastUpdated"
import { SettingsContext } from "../../../../Util/SettingsContext"
import { ToastContext } from "../../../../components/Toast/ToastContextProvider"

const SoilMoistureChart = ({ actionsState }) => {
    const chartRef = useRef(null)
    const { currentUser } = useContext(AuthContext)
    const { currentSettings } = useContext(SettingsContext)
    const { enqueueError, enqueueNetworkError } = useContext(ToastContext)
    const { id } = useParams()
    const [fieldId, setFieldId] = useState()

    const [confidenceVisible, setConfidenceBarsVisibility] = useState(false)
    const [climatologyVisible, setClimatologyVisible] = useState(true)

    const [historicalPending, setHistoricalPending] = useState(false)

    // Prepare initial data
    const weatherVariable = "soil_moisture"

    // ******************************* BAR DATA PART ****************************

    //---------------------------  MONTHLY SEASONAL - MONTHLY  ------------------------
    // BarData - Forecast
    const [monthlyBarForecastData, setMonthlyBarForecastData] = useState({
        time: [],
    })

    // BarData - Climatology
    const [monthlyBarClimatologyData, setMonthlyBarClimatologyData] = useState({
        time: [],
    })

    // BarData - Forecast - Vigintiles
    const [monthlyBarForecastDataVigintiles, setMonthlyBarForecastDataVigintiles] = useState({ time: [] })

    // BarData - Climatology - Vigintiles
    const [monthlyBarClimatologyDataVigintiles, setMonthlyBarClimatologyDataVigintiles] = useState({ time: [] })

    // Bar Data - Insights
    const barDataInsights = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "sm_mean",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                property: "sm_mean",
            }),
        [monthlyBarForecastDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarClimatologyDataVigintiles,
                property: "sm_mean",
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
            }),
        [monthlyBarClimatologyDataVigintiles, monthlyBarForecastDataVigintiles]
    )

    const [barType, setBarType] = useState("candl")

    // ******************************* END OF BAR DATA PART ****************************

    const [data, setData] = useState({
        ds_hist: {
            time: [],
            sm_mean: [],
        },
        ds_fc: {
            time: [],
            sm_mean: [],
        },
        ds_clim: {
            time: [],
            sm_mean: [],
        },
        pending: true,
    })

    const [monthlyData, setMonthlyData] = useState({
        ds_hist: {
            time: [],
            sm_mean: [],
        },
        ds_fc: {
            time: [],
            sm_mean: [],
        },
        ds_clim: {
            time: [],
            sm_mean: [],
        },
        pending: true,
    })

    // Alerts Data
    const [alertsData, setAlertsData] = useState({
        sm_mean: {},
    })

    // Load data
    useEffect(() => {
        if (fieldId !== id || (fieldId === id && climatologyVisible && data.ds_clim.time.length === 0)) {
            setData((prevData) => ({
                ...prevData,
                pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
            }))

            let localData = data

            if (climatologyVisible) {
                setHistoricalPending(true)
            }
            if (!climatologyVisible) {
                if (fieldId !== id) {
                    localData.pending = true
                }
                localData = Object.assign({}, localData, {
                    ds_clim: {
                        time: [],
                        sm_mean: [],
                    },
                })
                setData(localData)
            }

            setMonthlyData({
                ds_hist: {
                    time: [],
                    sm_mean: [],
                },
                ds_fc: {
                    time: [],
                    sm_mean: [],
                },
                ds_clim: {
                    time: [],
                    sm_mean: [],
                },
                pending: true,
            })

            // let localData = data

            // if (climatologyVisible) {
            //     setHistoricalPending(true)
            // }
            // let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
            // if (climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
            // if (!climatologyVisible) {
            //     if(fieldId!==id){
            //         localData.pending = true;
            //     }
            //     localData = Object.assign({}, localData, {
            //         ds_clim: {
            //             time: [],
            //             sm_mean: [],
            //         },
            //     })
            //     setData(localData)
            // }

            currentUser.getIdToken().then((userToken) => {
                let localData = data

                if (climatologyVisible) {
                    setHistoricalPending(true)
                }
                let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
                if (climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
                if (!climatologyVisible) {
                    localData = Object.assign({}, localData, {
                        ds_clim: {
                            time: [],
                            sm_mean: [],
                        },
                        pending: true,
                    })
                    setData(localData)
                }

                networking
                    .get(`/api/v1/weather/${weatherVariable}/daily/${id}?datasets=${datasets}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (datasets.indexOf("climatology") !== -1) {
                            setHistoricalPending(false)
                        }
                        setData({
                            ...Object.assign({}, localData, res.data),
                            pending: false,
                        })
                    })
                    .catch(() => {
                        setData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        enqueueNetworkError()
                    })

                // Break network call if data already loaded
                if (fieldId === id && monthlyData.ds_fc.time.length) {
                    return
                }

                //  networking
                //     .get(
                //         `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                //         {
                //             extraHeaders: { "User-Token": userToken },
                //         }
                //     )
                //     .then((res) => {
                //         console.log(res.data)
                //         setMonthlyBarForecastData(res.data.ds_fc)
                //         setMonthlyBarClimatologyData(res.data.ds_clim)
                //     })
                //     .catch(() => {
                //         toast.warn("Could not load monthly terciles forecast data.")
                //     })

                //   networking
                //       .get(
                //           `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                //           {
                //               extraHeaders: { "User-Token": userToken },
                //           }
                //       )
                //       .then((res) => {
                //         console.log(res.data)
                //           setMonthlyBarForecastDataVigintiles(res.data.ds_fc)
                //           setMonthlyBarClimatologyDataVigintiles(res.data.ds_clim)
                //       })
                //       .catch(() => {
                //           toast.warn("Could not load monthly vigintiles forecast data.")
                //       })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        console.log("seasonal terciles", res)
                        setMonthlyBarForecastData(res.data.ds_fc)
                        setMonthlyBarClimatologyData(res.data.ds_clim)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setMonthlyBarForecastDataVigintiles(res.data.ds_fc)
                        setMonthlyBarClimatologyDataVigintiles(res.data.ds_clim)
                    })
                    .catch(() => {
                        //         toast.warn(
                        //             `Alerts not displayed on dashboard due to internet
                        // connectivity issues. All other functions working.`
                        //         )
                    })

                networking
                    .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        setAlertsData(res.data)
                    })
                    .catch(() => {
                        //             toast.warn(
                        //                 `Alerts not displayed on dashboard due to internet
                        //   connectivity issues. All other functions working.`
                        //             )
                    })
            })
            setFieldId(id)
        }
    }, [currentUser, id, climatologyVisible])

    const lastUpdated = useLastUpdated(data, monthlyData)

    // Prepare historical data
    const historicalTemp = useMemo(() => {
        try {
            return getHistoricalTemp(data["ds_hist"])
        } catch (e) {
            enqueueError("Problem ocurred processsing information")
            throw e
        }
    }, [data])

    // Prepare forecast data
    const forecastArr = useMemo(() => {
        try {
            return getForecastArr(data["ds_fc"])
        } catch (e) {
            return []
        }
    }, [data])
    const forecastTemp = useMemo(() => {
        try {
            return getForecastTemp(data["ds_fc"], forecastArr)
        } catch (e) {
            return []
        }
    }, [data, forecastArr])

    // Prepare Confidence Data
    const forecastConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalTemp[historicalTemp.length - 1],
            data["ds_fc"]["sm_mean"],
            "0.75"
        )
    }, [data, historicalTemp])
    const forecastConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalTemp[historicalTemp.length - 1],
            data["ds_fc"]["sm_mean"],
            "0.95"
        )
    }, [data, historicalTemp])

    // Prepare clim data
    const { climLighten, climDarken } = useMemo(() => {
        try {
            return getClim(data["ds_clim"])
        } catch (e) {
            return []
        }
    }, [data])

    // Look at the changes for historical and forecast data and display warning messages if invalid
    useMemo(() => {
        validateData({
            diffToAlert: 15,
            historic: climLighten,
            forecast: forecastTemp,
            accessorKey: "y",
            message: "Forecast Anomaly Detected",
        })
    }, [forecastTemp, climLighten])

    const lineData = assembleLineData({
        isMonthly: actionsState.isMonthly,
        historical: historicalTemp,
        forecast: forecastTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.sm_mean["0.5"][i],
            }
        }),
    })

    const csvData = lineData.map((d) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })],
        [d.y],
    ])

    return (
        <div className="weather-chart">
            <div className="weather-chart__chart-container">
                <div style={{ display: data.pending ? "flex" : "none" }} className="weather-chart__preload-container">
                    <CircularProgress />
                </div>
                <LineAreaChartComponent
                    // Pass height externally
                    svgHeight={650}
                    // Title text
                    title="Soil Moisture"
                    // Y label text
                    labelY="Soil Moisture m^3/m^3"
                    // Convert received data to shaded ranges format
                    shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData))}
                    // Pass unique resize event key
                    resizeEventListenerId="soil_moisture-chart"
                    // Add chart data id to filter out some update requests
                    chartDataId={
                        {
                            hourly: `hour`,
                            daily: `month`,
                            monthly: `day`,
                        }[actionsState.selectedGranularity] +
                        `${weatherVariable}-chart_` +
                        climLighten.length
                    }
                    // Center Ticks

                    centerTicks={
                        {
                            daily: false,
                            monthly: true,
                            hourly: false,
                        }[actionsState.selectedGranularity]
                    }
                    // Make chart to have dynamic y basis
                    zeroBasis={true}
                    // Bottom margin will be 0.2 times of data diff
                    yBottomOffset={0.2}
                    // Top margin will be 0.3 times of data diff
                    yTopOffset={0.3}
                    // Provide custom date max axis extent for monthly view charts
                    xDateMax={
                        {
                            daily: addDays(new Date(), 6),
                            // monthly: addMonths(new Date(), 6),
                            monthly: addMonths(
                                new Date(
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getFullYear(),
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getMonth() + 1,
                                    1
                                ),
                                0
                            ),
                            hourly: null,
                        }[actionsState.selectedGranularity]
                    }
                    // Provide custom date min axis extent for monthly view charts
                    xDateMin={
                        {
                            daily: addDays(new Date(), -6),
                            // monthly: addMonths(new Date(), -7),
                            monthly: (() => {
                                const today = new Date()
                                const year = today.getFullYear()
                                const month = today.getMonth()
                                const result = new Date(year, month, 1)
                                return result
                            })(),
                            hourly: null,
                        }[actionsState.selectedGranularity]
                    }
                    // How x ticks will be formatted in chart
                    xTickFormat={
                        {
                            daily: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getDate()
                                if (i === 0) return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                            },
                            // monthly: (d, i, arr) => {
                            //     // Remove last, overflowing tick item
                            //     if (i === arr.length - 1) return ""
                            //     return d.toLocaleString(undefined, { month: "short" })
                            // },
                            monthly: (d, i, arr) => {
                                // Remove last, overflowing tick item
                                if (i === arr.length - 1) return ""
                                return d.toLocaleString(undefined, { month: "short" })
                            },
                            hourly: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getHours() + "h"
                                if (i === 0) return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                                return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                            },
                        }[actionsState.selectedGranularity]
                    }
                    xTicksCount={
                        {
                            daily: 14,
                            //monthly: 12,
                            monthly: 5,
                            hourly: 16,
                        }[actionsState.selectedGranularity]
                    }
                    // Hide chart if data is pending
                    hide={data.pending}
                    // Tooltip content on line points mouse over
                    tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                        let hour = undefined
                        let day = "numeric"
                        let month = "short"
                        let titleWidth = 40
                        let titleFontSize = 20

                        if (actionsState.selectedGranularity === "hourly") {
                            hour = "numeric"
                            day = undefined
                            month = undefined
                        }

                        let dateStr = key.toLocaleString(undefined, { day, month, hour })

                        if (actionsState.selectedGranularity === "monthly") {
                            dateStr = key.toLocaleString(undefined, { month: "short" })
                        }

                        if (barType === "candl") {
                            day = undefined
                        }

                        return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                 <tr><td style="font-weight:bold;font-size:${titleFontSize}px" rowspan="${
                            values.length
                        }"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:${titleWidth}px;line-height:1.1">${dateStr}</div></td> 
                     <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                         points[0].dashed ? "dashed" : "solid"
                     } ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${
                            Math.round(values[0] * 100) / 100
                        } m^3/m^3</td>
                 </tr>
                 ${values
                     .filter((_, i) => i > 0)
                     .map((value, i) => {
                         return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                             points[i + 1].dashed ? "dashed" : "solid"
                         } ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${
                             Math.round(value * 100) / 100
                         } m^3/m^3</td></tr>`
                     })
                     .join("")}
             </table>`
                    }}
                    shapeTip={
                        {
                            hourly: null,
                            daily: null,
                            monthly: (EVENT, boundObj, state, point) => {
                                let resultObj = point ?? boundObj
                                let month = resultObj.key
                                    .toLocaleString("en-US", { month: "short", year: "numeric" }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                    .split("")
                                    .join("")
                                const prec = 100
                                const warmer = Math.round(prec * barDataInsights.normalMax[month]) / prec
                                let clim67 = Math.round(prec * barDataInsights.clim67[month]) / prec
                                const normal = Math.round(prec * barDataInsights.normal[month]) / prec
                                let clim33 = Math.round(prec * barDataInsights.clim33[month]) / prec
                                const colder = Math.round(prec * barDataInsights.normalMin[month]) / prec

                                let unit = "m^3/m^3"
                                // if (currentSettings.units === 'imperial') {
                                //     clim33 = +convertTemperatureValueOnly("metric", "imperial", clim33)
                                //     clim67 = +convertTemperatureValueOnly("metric", "imperial", clim67)
                                //     unit = 'F'
                                // }

                                return `<div style="max-width:250px;">There is a ${warmer}% chance that soil moisture will be higher than usual (above ${clim67} ${unit} ). </br>  </br> There is a ${normal}% chance that soil moisture will be within the normal range (${clim33} to ${clim67}  ${unit}). </br>  </br>There is a ${colder}% chance that soil moisture will be lower than normal (below ${clim33}  ${unit}).</div>`
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Chart data content
                    data={[
                        // //======================= PLOT MEDIAN AS LINE - MONTHLY===================
                        ["monthly"].includes(actionsState.selectedGranularity)
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBar({
                                      obj: forecastQuantilesBarData[0.5],
                                      props: {
                                          color: "#EE6900",
                                          barType: barType,
                                          visible: true,
                                          convert: false,
                                          unit: currentSettings.units,
                                          // unitType: 'temp',
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsights)
                                                  return {
                                                      color: "",
                                                      symbol: `
                                 <style>
                                 .point-loader {
                                   border: 3px solid #f3f3f3;
                                   border-radius: 50%;
                                   border-top: 3px solid #3498db;
                                   width: 10px;
                                   height: 10px;
                                   -webkit-animation: spin 2s linear infinite; /* Safari */
                                   animation: spin 2s linear infinite;
                                 }

                                 /* Safari */
                                 @-webkit-keyframes spin {
                                   0% { -webkit-transform: rotate(0deg); }
                                   100% { -webkit-transform: rotate(360deg); }
                                 }

                                 @keyframes spin {
                                   0% { transform: rotate(0deg); }
                                   100% { transform: rotate(360deg); }
                                 }
                                 </style>

                                 <div class="point-loader"></div>
                                 `,
                                                  }
                                              const month = d.key
                                                  .toLocaleString("en-US", {
                                                      month: "short",
                                                      year: "numeric",
                                                  }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                                  .split("")
                                                  .join("")
                                              const normalMax = barDataInsights.normalMax[month]
                                              const normalMin = barDataInsights.normalMin[month]
                                              const normal = barDataInsights.normal[month]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        //=================== DOUBLE  CANDLESTICK VERSION  =========================

                        [""].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToLineFromBar({
                                  obj: forecastQuantilesBarData[0.5],
                                  props: {
                                      color: "#EE6900",
                                      barType: barType,
                                      visible: false,
                                      convert: false,
                                      unit: currentSettings.units,
                                      //   unitType: "temp",
                                      date: 15,
                                  },
                              })
                            : null,

                        ["monthly"].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToDoubleCandlestick({
                                  obj: [
                                      {
                                          values: [forecastQuantilesBarData, climatologyQuantilesBarsData],
                                      },
                                  ],
                                  unit: currentSettings.units,
                                  //   unitType: "temp",
                                  convert: false,
                                  colors: ["#EE6900", "#039BE5"],
                              })
                            : null,

                        // ===================== End Of Horizontal and vertical bars versions  ====================

                        ["daily", ""].includes(actionsState.selectedGranularity) && climatologyVisible
                            ? {
                                  type: "area",
                                  points: !actionsState.isMonthly
                                      ? trimmData(getSevenMonthsMarginClimData(climLighten))
                                      : getSevenMonthsMarginClimData(climLighten),
                                  color: "#DACBB3",
                                  opacity: 0.6,
                              }
                            : null,
                        ["daily", ""].includes(actionsState.selectedGranularity) && climatologyVisible
                            ? {
                                  type: "area",
                                  points: !actionsState.isMonthly
                                      ? trimmData(getSevenMonthsMarginClimData(climDarken))
                                      : getSevenMonthsMarginClimData(climDarken),
                                  color: "#C8A06E",
                                  opacity: 0.6,
                              }
                            : null,
                        // Confidence Bands
                        ["daily", ""].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastConfidence95,
                                      isMonthly: actionsState.isMonthly,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              key: +new Date(d),
                                              y1: monthlyData.ds_fc.sm_mean["0.95"][i],
                                              y0: monthlyData.ds_fc.sm_mean["0.05"][i],
                                              max: monthlyData.ds_fc.sm_mean["0.95"][i],
                                              min: monthlyData.ds_fc.sm_mean["0.05"][i],
                                          }
                                      }),
                                  }),
                                  color: actionsState.isMonthly ? "#FFBB97" : "#EE6900",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,

                        ["daily", ""].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastConfidence75,
                                      isMonthly: actionsState.isMonthly,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              key: +new Date(d),
                                              y1: monthlyData.ds_fc.sm_mean["0.75"][i],
                                              y0: monthlyData.ds_fc.sm_mean["0.25"][i],
                                              max: monthlyData.ds_fc.sm_mean["0.75"][i],
                                              min: monthlyData.ds_fc.sm_mean["0.25"][i],
                                          }
                                      }),
                                  }),
                                  color: actionsState.isMonthly ? "#FB9769" : "#EE6900",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,

                        ["daily", ""].includes(actionsState.selectedGranularity)
                            ? {
                                  type: "line",
                                  id: "line-mid",
                                  alwaysOnTop: true,
                                  points: assembleLineData({
                                      isMonthly: actionsState.isMonthly,
                                      historical: historicalTemp,
                                      forecast: forecastTemp,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: new Date(d),
                                              y: monthlyData.ds_fc.sm_mean["0.5"][i],
                                          }
                                      }),
                                  }),
                                  color: "#FF7002",
                                  "stroke-width": 2,
                              }
                            : null,
                    ]}
                ></LineAreaChartComponent>
            </div>
            <div className="weather-chart__specs-container">
                <ChartSpecs
                    type="soil_moisture"
                    barType={barType}
                    historicalPending={historicalPending}
                    selectedGranularity={actionsState.selectedGranularity}
                    chartRef={chartRef}
                    climatologyVisible={climatologyVisible}
                    confidenceVisible={confidenceVisible}
                    handleBarTypeChange={(candlChecked) => {
                        if (candlChecked) setBarType("candl")
                        else setBarType("med")
                    }}
                    lastUpdated={lastUpdated}
                    handleAreasVisibilityChange={({ conf, clim }) => {
                        setConfidenceBarsVisibility(conf)
                        setClimatologyVisible(clim)
                    }}
                    colsArr={["Date", "Value (m^3/m^3)"]}
                    data={{
                        csv: csvData,
                        // hourlyCsv: mergeHistoricalAndForecastData({
                        //     forecast: hourlyData.ds_fc,
                        //     historical: hourlyData.ds_hist,
                        //     prop: "sm_mean",
                        // }),
                    }}
                    // onHourlyCsvDataTrigger={() => {
                    //     return new Promise((resolve, reject) => {
                    //         currentUser.getIdToken().then((userToken) => {
                    //             networking
                    //                 .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                    //                     extraHeaders: { "User-Token": userToken },
                    //                 })
                    //                 .then((res) => {
                    //                     setHourlyData({
                    //                         ...res.data,
                    //                     })
                    //                     resolve(res.data)
                    //                 })
                    //                 .catch(() => {
                    //                     reject()
                    //                 })
                    //         })
                    //     })
                    // }}
                    actionsState={actionsState}
                />
            </div>
        </div>
    )
}

export default SoilMoistureChart

import React from "react"

import "./ScenarioCard.css"

function ScenarioCard(props) {
    const { render = () => null, children } = props
    return (
        <>
            <div className="scenario-card">
                <div className="scenario-card__header">{render()}</div>
                <div className="scenario-card__body">{children}</div>
            </div>
        </>
    )
}

export default ScenarioCard

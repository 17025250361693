const InsufficientChillTempRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.83 11H22V13H17.83L21.07 16.24L19.66 17.66L15 13H13V15L17.66 19.66L16.24 21.07L13 17.83V22H11V17.83V15V13V11V9V6.17V2H13V6.17L16.24 2.93L17.66 4.34L13 9V11H15L19.66 6.34L21.07 7.76L17.83 11Z"
                fill={props.fill}
            />
            <rect x="5" y="7" width="2" height="6" fill={props.fill} />
            <rect x="5" y="15" width="2" height="2" fill={props.fill} />
        </svg>
    )
}

export default InsufficientChillTempRiskIcon

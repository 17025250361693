import LoadingCircleIcon from "../../ui/Icons/LoadingCircleIcon"
import { Button } from "../Button"
import { companyLogosObject } from "./AboutData"

const AboutSeasonal = () => {
    return (
        <>
            <div className="leading-tight montserrat">
                ClimateAi ingests all available forecasts (including proprietary models,{" "}
                <span className="whitespace-nowrap">public / private</span> vendors, satellite observations, and ground
                truth sources) to deliver the <b>world's most accurate forecast</b>.
            </div>
            <div className="flex flex-row flex-wrap items-center space-x-2 space-y-4 justify-evenly">
                {Object.keys(companyLogosObject).map((companyKey) => {
                    return (
                        <a href={companyLogosObject[companyKey].url} className="w-1/3" target="_blank" rel="noreferrer">
                            <img src={companyLogosObject[companyKey].image} alt={companyLogosObject[companyKey].url} />
                        </a>
                    )
                })}
            </div>
        </>
    )
}

const AboutClimate = () => {
    return (
        <div className="leading-normal montserrat">
            ClimateAi ingests all available forecasts (including proprietary models,{" "}
            <span className="whitespace-nowrap">public / private</span> vendors, satellite observations, and ground
            truth sources) to deliver the <b>world's most accurate forecast</b>.
        </div>
    )
}

const Modal = ({
    title = "",
    text = "",
    type = "",
    onCancel = () => {},
    onContinue = () => {},
    onContinueLabel = "Continue",
    onCancelLabel = "Cancel",
    closeModal = () => {},
    doGrayBG = false,
    isAbsolute = false,
}) => {
    const handleCancelModal = () => {
        onCancel()
        closeModal()
    }

    const handleContinueModal = () => {
        onContinue()
        closeModal()
    }

    return (
        <div
            className={[
                "z-full inset-0 flex flex-row justify-center items-center",
                doGrayBG ? "bg-gray-90/[.5]" : "",
                isAbsolute ? "absolute" : "fixed",
                type === "loading" ? "text-center px-4" : "",
            ].join(" ")}
        >
            <div className="bg-white rounded-lg w-full max-w-[380px] min-h-[200px] p-[24px] flex flex-col items-stretch justify-between elevation-2 space-y-[24px]">
                {type === "about-seasonal" ? (
                    <AboutSeasonal />
                ) : type === "about-climate" ? (
                    <AboutClimate />
                ) : (
                    <div>
                        <h6 className="text-[20px] montserrat text-gray-90 font-semibold leading-tight">{title}</h6>
                        <p className="roboto text-[16px] text-gray-400 mt-[10px] leading-relaxed">{text}</p>
                    </div>
                )}
                <div className="flex flex-row items-center space-x-[8px]">
                    {type === "loading" ? (
                        <div className="flex flex-row justify-center w-full pb-4">
                            <span className="w-20">
                                <LoadingCircleIcon />
                            </span>
                        </div>
                    ) : (
                        <>
                            {type === "confirmation" && (
                                <Button
                                    extraClasses="flex-1"
                                    label={onCancelLabel}
                                    type="secondary"
                                    onClick={handleCancelModal}
                                />
                            )}
                            <Button
                                extraClasses="flex-1"
                                label={onContinueLabel}
                                type="primary"
                                onClick={handleContinueModal}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Modal

import "./LoadingScreen.css"

function LoadingScreen(props) {
    const { message = "Welcome" } = props
    return (
        <>
            <main className="loading-screen">
                <div className="loading-screen__loader" />
                <h1 className="loading-screen__title">{message && message.toUpperCase()}</h1>
            </main>
        </>
    )
}

export default LoadingScreen

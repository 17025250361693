import { useMemo } from "react"
import { buildCSVString } from "../Util/CSVParser"

export default function useCSVData(formatedData, csvHeaders, csvHeaderSection, isReady) {
    return useMemo(() => {
        if (!formatedData || !isReady) {
            return {
                csvHeaders: [],
                csvData: [],
                csvString: "",
            }
        }

        const csvString = buildCSVString(csvHeaderSection, csvHeaders, formatedData)

        return {
            csvHeaders,
            csvData: formatedData,
            csvString,
        }
    }, [formatedData, csvHeaders, isReady])
}

import React, { Children, useState } from "react"

import "./SmallControls.css"

function SmallControls(props) {
    const { children, __onClose__, className = "", onlyChild } = props

    const childrenArray = Children.toArray(children)
    const options = childrenArray
        .filter((child) => !child.props.defaultList && child.props.listItem)
        .map((child) => ({ name: child.props.name, title: child.props.title, disabled: child.props.disabled }))

    if (childrenArray.length === 0) {
        throw new TypeError("You must provide children to this component.")
    }

    const [history, setHistory] = useState([childrenArray[0].props.name])
    const currentChild = childrenArray.find((child) => child.props.name === history[history.length - 1])

    function goTo(value) {
        if (!onlyChild) setHistory([...history, value])
    }

    function goBack() {
        if (!onlyChild) {
            const _history = [...history]
            _history.pop()
            setHistory(_history)
        }
    }

    return (
        <>
            <div className={`small-controls ${className}`}>
                {React.cloneElement(currentChild, {
                    actions: { goTo, goBack, close: __onClose__, onlyChild },
                    options,
                })}
            </div>
        </>
    )
}

export default SmallControls

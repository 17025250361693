import { useMemo, useRef } from "react"

import { PROPERTY_TITLES_EQUIVALENCE } from "../Util/CSVParser"
import {
    calculateProbabilities,
    alertBetweenTimePeriod,
    getAlertProbabilitiesBetweenDates,
} from "../helpers/plantingTool"

function usePlantingToolData(scenarios, startDate, endDate, tab = "") {
    const thresholdChanges = useRef({})

    const { _scenarios, _tableColumns, _tableData, _chartData, _availableAlerts } = useMemo(() => {
        //
        const _scenarios = {}
        const _chartData = {}
        const _tableColumns = [
            { propName: "scenario", display: "Scenario", available: true },
            {
                propName: "climate_risk",
                display: "Climate Risk",
                riskKey: "climate_risk",
                alertId: "default",
                available: true,
            },
        ]
        const _tableData = []
        const _availableAlerts = {
            climate_riskdefault: {
                riskKey: "climate_risk",
                alertId: "default",
                riskName: "Default",
                alertName: `Climate Risk when ${tab.split("_")[0]}`,
            },
        }

        if (!startDate || !endDate) return { _scenarios, _chartData, _tableColumns: [], _tableData }

        // iterate over each scenario
        for (let uid in scenarios) {
            // copy scenario information
            _scenarios[uid] = { ...scenarios[uid] }
            let chartData = scenarios[uid].chart_data

            // planting date time window
            const _startDate = new Date(_scenarios[uid].plantingDate.getTime())
            const _endDate = new Date(_scenarios[uid].plantingDate.getTime())
            _endDate.setDate(_scenarios[uid].plantingDate.getDate() + _scenarios[uid].length)

            // > > > > >
            // Generate _scenarios and _tableColumns
            // > > > > >

            // total weight for climate risk
            let toolWeightTotal = 0 // we need this for the main chart
            let plantingWindowWeightTotal = 0 // we need this for the table

            // iterate over each risk
            for (let riskKey in chartData) {
                // copy risk information
                _scenarios[uid].chart_data[riskKey] = { ...chartData[riskKey] }
                // iterate over each alert
                for (let alertId in chartData[riskKey]) {
                    // copy alert information
                    const alert = { ...chartData[riskKey][alertId], availableDuringPlanting: false }

                    // > > > > >
                    // Sum weights
                    // > > > > >

                    toolWeightTotal += alert.weight

                    // > > > > >
                    // Handle lag change
                    // > > > > >

                    if (alert.lag !== undefined || alert.lag !== 0) {
                        alert.time = [...scenarios.current.chart_data[riskKey][alertId].alert_data.time].map((t) => {
                            const date = new Date(t)
                            date.setDate(date.getDate() - alert.lag)
                            return date.toISOString()
                        })
                    }

                    // we need this for the table
                    // check if alert exists inside scenario planting time window
                    if (alertBetweenTimePeriod(alert, _startDate, _endDate)) {
                        plantingWindowWeightTotal += alert.weight
                        alert.availableDuringPlanting = true
                    }

                    // > > > > >
                    // Handle threshold change
                    // > > > > >

                    // calculate new alert values when threshold changes
                    if (
                        uid !== "current" &&
                        ((thresholdChanges.current[uid] &&
                            thresholdChanges.current[uid][alertId] &&
                            alert.alert_data.threshold !== thresholdChanges.current[uid][alertId]) ||
                            ((!thresholdChanges.current[uid] || !thresholdChanges.current[uid][alertId]) &&
                                alert.alert_data.threshold !==
                                    scenarios.current.chart_data[riskKey][alertId].alert_data.threshold))
                    ) {
                        // recalculate values only if threshold is different from
                        // current scenario or past value
                        alert.values = calculateProbabilities(
                            alert.alert_data.time,
                            alert.alert_data.forecast_values,
                            alert.alert_data.conditional,
                            alert.alert_data.threshold
                        )

                        if (
                            alert.alert_data.threshold !==
                            scenarios.current.chart_data[riskKey][alertId].alert_data.threshold
                        ) {
                            thresholdChanges.current[uid] = {
                                [alertId]: alert.alert_data.threshold,
                            }
                        } else {
                            Object.keys(thresholdChanges.current[uid][alertId]).length === 0 &&
                                delete thresholdChanges.current[uid][alertId]
                            Object.keys(thresholdChanges.current[uid]).length === 0 &&
                                delete thresholdChanges.current[uid]
                        }
                    }

                    _scenarios[uid].chart_data[riskKey][alertId] = { ...alert }

                    // > > > > >
                    // Handle table columns
                    // > > > > >
                    if (uid === "current") {
                        _tableColumns.push({
                            propName: riskKey + alertId,
                            riskKey: riskKey,
                            alertId: alertId,
                            alertName: alert.alert_data.alert_name,
                            available: alert.availableDuringPlanting && alert.weight > 0,
                        })
                    }
                }
            }

            // > > > > >
            // Generate _tableData and _chartData
            // > > > > >

            // reasign chartData to updated scenario
            chartData = _scenarios[uid].chart_data
            _chartData[uid] = {}

            // we need this for the table
            let climateTotalRisk = 0
            const climateRiskValues = {}
            const tableDataResult = { scenario: _scenarios[uid].name }

            // iterate over risks
            for (let riskKey in chartData) {
                _chartData[uid][riskKey] = {}
                // iterate over alerts
                for (let alertId in chartData[riskKey]) {
                    // reference to the last alert value
                    const alert = chartData[riskKey][alertId]

                    // > > > > >
                    // Handle table data
                    // > > > > >

                    // check if alert is available between scenario planting time window
                    if (alert.availableDuringPlanting) {
                        // get probabilities
                        const availableValues = getAlertProbabilitiesBetweenDates(alert, _startDate, _endDate)

                        if (availableValues) {
                            // calculate average acording to the weight total for this
                            // scenario's planting time window
                            const alertAverage =
                                availableValues.map((d) => d.value).reduce((prev, curr) => prev + curr, 0) /
                                availableValues.length

                            climateTotalRisk += alertAverage * (alert.weight / plantingWindowWeightTotal)
                            tableDataResult[riskKey + alertId] = alertAverage
                        }
                    }

                    // > > > > >
                    // Handle chart data
                    // > > > > >

                    // initialize alert object
                    _chartData[uid][riskKey][alertId] = {}
                    alert.weightAvailable = alert.weight > 0

                    // populate list of available alerts by time period
                    if (uid === "current" && alertBetweenTimePeriod(alert, startDate, endDate))
                        _availableAlerts[riskKey + alertId] = {
                            riskKey,
                            alertId,
                            riskName: PROPERTY_TITLES_EQUIVALENCE[riskKey],
                            alertName: alert.alert_data.alert_name,
                        }

                    // alert's first 'time' registry
                    const alertStartDate = new Date(alert.time[0])

                    // check how many days are between the alert's first registry and
                    // the beginin of the tool time window
                    let alertTimeOffset = Math.floor(
                        (startDate.getTime() - alertStartDate.getTime()) / (1000 * 3600 * 24)
                    )

                    // array to save the alert probabilities values
                    let availableValues = {
                        maxValue: 0,
                        points: [],
                    }
                    // iterate over the dates of the tool time window
                    const currentDate = new Date(startDate.getTime())
                    while (currentDate.getTime() <= endDate.getTime()) {
                        // take alert's date value if exists
                        let currentValue = 0
                        if (alertTimeOffset >= 0 && alert.values[alertTimeOffset] !== undefined)
                            currentValue = alert.values[alertTimeOffset]

                        // add to climate risk values and calculate average
                        let climateRiskCurrentValue =
                            (currentValue !== 0 && (alert.weight / toolWeightTotal) * currentValue) || 0
                        if (!climateRiskValues[currentDate.toISOString()]) {
                            climateRiskValues[currentDate.toISOString()] = {
                                time: currentDate.getTime(),
                                value: climateRiskCurrentValue,
                            }
                        } else {
                            climateRiskValues[currentDate.toISOString()].value += climateRiskCurrentValue
                        }

                        if (currentValue > availableValues.maxValue) availableValues.maxValue = currentValue

                        availableValues.points.push({
                            time: currentDate.getTime(),
                            value: currentValue,
                        })

                        // ++
                        alertTimeOffset++
                        currentDate.setDate(currentDate.getDate() + 1)
                    }

                    if (availableValues) {
                        _chartData[uid][riskKey][alertId] = availableValues
                    }
                    // }
                    // delete if data does not exist
                    Object.keys(_chartData[uid][riskKey][alertId]).length === 0 &&
                        delete _chartData[uid][riskKey][alertId]
                }
                // delete if data does not exist
                Object.keys(_chartData[uid][riskKey]).length === 0 && delete _chartData[uid][riskKey]
            }

            const climateRiskPoints = Object.values(climateRiskValues)

            _chartData[uid].climate_risk = {
                default: {
                    maxValue: climateRiskPoints.reduce((prev, curr) => (curr.value > prev && curr.value) || prev, 0),
                    points: climateRiskPoints,
                },
            }

            // Add climate total risk sum to the table
            tableDataResult.climate_risk = climateTotalRisk
            _tableData.push(tableDataResult)
        }
        // return { _scenarios, _tableColumns, _tableData, _chartData }
        return { _scenarios, _tableColumns, _tableData, _chartData, _availableAlerts }
        // }, [scenarios, startDate && startDate.getTime(), endDate && endDate.getTime()])
    }, [scenarios, startDate, endDate, tab])

    return {
        _scenarios,
        _tableColumns,
        _tableData,
        _chartData,
        _availableAlerts,
    }
}

export default usePlantingToolData

import { v4 as uuidv4 } from "uuid"
import { RISKS } from "../views/Settings/AlertSettings/components/RisksList"
import { MONTH_NAMES } from "./constants"
import { convertTemperature, convertWaterLength } from "./UnitConversion"

const TEXT_HEADERS_EQUIVALENCE = {
    "Risk Category (not editable)": "risk_category",
    "Alert Title / Subtitle (Column is editable)": "title",
    "Modifier (not editable)": "modifier",
    Threshold: "threshold",
    "Probability (%)": "probability",
    "Req Recurrence (Consecutive Days)": "recurrent_days",
    "Start Date (month)": "start_date_month",
    "Start Date (day)": "start_date_day",
    "End Date (month)": "end_date_month",
    "End Date (day)": "end_date_day",
    "Active Alert": "active",
    "GDD Floor Temp (C)": "gddBase",
    "Plant Life Cycle (in days)": "gddLifeCycle",
}

export const ALERT_TITLES_EQUIVALENCE = {
    "Heat Stress Risk": "risk_heat_stress",
    "Cold Risk": "risk_cold",
    "Insufficient High Temperature Risk": "risk_insufficient_high_temp",
    "Insufficient Chill Temperature Risk": "risk_insufficient_low_temp",
    "Precipitation Risk": "risk_flood",
    "Cumulative Precipitation Risk": "risk_cumulative_flood",
    "Drought Risk": "risk_drought",
    "Cumulative Drought Risk": "risk_cumulative_drought",
    "Dry Soil Risk": "risk_dry_soil",
    "Wet Soil Risk": "risk_wet_soil",
    "Low Soil Temperature Risk": "risk_low_soil_temperature",
    "High Soil Temperature Risk": "risk_high_soil_temperature",
    "Low Humidity Risk": "risk_low_humidity",
    "High Humidity Risk": "risk_high_humidity",
    // "Growing Degree Days": "risk_growing_degree_days",
    // "Pest Degree Days": "risk_pest_degree_days",
    "Low Wind Day Risk": "risk_low_wind_speed",
    "High Wind Day Risk": "risk_high_wind_speed",
    "Excessive Solar Radiation Risk": "risk_excessive_solar_radiation",
    "Insufficient Solar Radiation Risk": "risk_insufficient_solar_radiation",
}

const MUST_HAVE_ALERT_PROPS = [
    "threshold",
    "active",
    "end_date_day",
    "start_date_day",
    "end_date_month",
    "start_date_month",
    "probability",
    "recurrent_days",
    "title",
]

// invert key values
export const PROPERTY_HEADERS_EQUIVALENCE = Object.keys(TEXT_HEADERS_EQUIVALENCE).reduce((prev, curr) => {
    prev[TEXT_HEADERS_EQUIVALENCE[curr]] = curr
    return prev
}, {})

// invert key values
export const PROPERTY_TITLES_EQUIVALENCE = Object.keys(ALERT_TITLES_EQUIVALENCE).reduce((prev, curr) => {
    prev[ALERT_TITLES_EQUIVALENCE[curr]] = curr
    return prev
}, {})

const HIDDEN_VALUES = ["risk_category", "modifier"]

// Function to know the day of each month => Stack Overflow
function daysInMonth(month) {
    // month is 0 indexed: 0-11
    switch (month) {
        case 1:
            return 28 // ignore leap-year
        case 8:
        case 3:
        case 5:
        case 10:
            return 30
        default:
            return 31
    }
}

// Function to know if a date is valid => Stack Overflow
function dateIsValid(d, m, y) {
    return m >= 0 && m < 12 && d > 0 && d <= daysInMonth(m)
}

function parseCell(propName, value, unit) {
    let result = null
    switch (propName) {
        case "threshold":
        case "gddBase":
            result = parseFloat(value)
            break
        case "probability":
            result = parseInt(value) / 100
            break
        case "recurrent_days":
        case "gddLifeCycle":
            result = parseInt(value)
            break
        case "active":
            result = value.toLowerCase() === "yes" ? true : false
            break
        default:
            result = value
            break
    }
    return result
}

function restoreCellValue(propName, value) {
    let result = null
    switch (propName) {
        case "probability":
            result = value * 100
            break
        case "active":
            result = value ? "Yes" : "No"
            break
        default:
            result = value
            break
    }
    return result
}

function splitCSVRow(row) {
    const values = []
    let openQuote = false
    let currValue = ""
    Array.from(row).forEach((char) => {
        if (char === "," && !openQuote) {
            if (currValue) values.push(currValue)
            currValue = ""
        } else if (char === '"') {
            openQuote = !openQuote
            if (!openQuote) {
                values.push(currValue)
                currValue = ""
            }
        } else {
            currValue += char
        }
    })
    return values
}

export function parseCSVFile(startValue, file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.onload = function () {
            // get csv lines
            const lines = reader.result.split("\n")

            // result obj
            const alertConfigs = {}

            // result's propNames to be mapped from the csv
            const propNames = []
            const tableHeaders = splitCSVRow(lines[startValue])

            // get table headers
            for (let tableHeader of tableHeaders) {
                const currentValue = TEXT_HEADERS_EQUIVALENCE[tableHeader]
                propNames.push(currentValue)
            }

            const titleIndex = propNames.indexOf("title")
            if (!titleIndex) reject('Invalid data, "title" was not found in the alert config table headers.')

            // fill alert configs
            for (let i = startValue + 1; i < lines.length; i++) {
                const cells = splitCSVRow(lines[i])
                if (!cells[0]) continue

                const alertTitle = ALERT_TITLES_EQUIVALENCE[cells[0]]

                if (!alertConfigs[alertTitle]) alertConfigs[alertTitle] = {}

                const alertConfig = {}
                for (let j = 1; j < cells.length; j++) {
                    if (!propNames[j] || HIDDEN_VALUES.includes(propNames[j])) {
                        continue
                    }
                    let cell = cells[j]
                    if (MUST_HAVE_ALERT_PROPS.includes(propNames[j]) && !cell) {
                        if (propNames[j] === "recurrent_days") {
                            if (alertTitle.includes("cumulative")) {
                                reject(`The CSV file provided is missing a value for "${propNames[j]}".`)
                            }
                            cell = 1 // hardcode the empty recurrent_days to 1
                        } else {
                            reject(`The CSV file provided is missing a value for "${propNames[j]}".`)
                        }
                    } else if (cell === "0" || cell === 0) {
                        if (propNames[j] === "probability" || propNames[j] === "recurrent_days") {
                            reject(`Error: "${propNames[j]}" can't have values of zero.`)
                        }
                        cell = 0
                    } else if (!cell || (cell && cell === "NA")) continue

                    if (propNames[j].includes("start_date") || propNames[j].includes("end_date")) {
                        let prop = propNames[j].split("_")
                        let dayOrMonth = prop.pop() // Delete "month" or "day" of the prop name array
                        prop = prop.join("_") // Join the array to "start_date" or "end_date"
                        if (!alertConfig[prop]) alertConfig[prop] = {}
                        alertConfig[prop][dayOrMonth] = parseInt(cell)
                        if (
                            "day" in alertConfig[prop] &&
                            "month" in alertConfig[prop] &&
                            !dateIsValid(
                                alertConfig[prop]["day"],
                                alertConfig[prop]["month"] - 1 // Minus 1 because dateIsValid month uses 0-11
                            )
                        ) {
                            reject("Error: One of the input dates in the csv is invalid!")
                        }
                    } else {
                        alertConfig[propNames[j]] = parseCell(propNames[j], cell)
                    }
                }

                alertConfigs[alertTitle][uuidv4()] = alertConfig
            }

            if (Object.keys(alertConfigs).length === 0) {
                reject("The CSV file provided has no alerts.")
            }
            resolve(alertConfigs)
        }
        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsText(file, "UTF-8")
    })
}

export function toCSVFile(crop = "", variety = "", alerts) {
    const tableHeaders = Object.values(PROPERTY_HEADERS_EQUIVALENCE)
    const tableData = []

    for (let type in alerts) {
        const currentAlertType = alerts[type]
        for (let alertKey in currentAlertType) {
            const currentAlert = currentAlertType[alertKey]
            const rowData = []

            if (!alerts[type][alertKey]["stacked"]) {
                // rowData[0] = currentAlert
                for (let key in PROPERTY_HEADERS_EQUIVALENCE) {
                    switch (key) {
                        case "risk_category":
                            rowData.push(PROPERTY_TITLES_EQUIVALENCE[type])
                            break
                        case "modifier":
                            rowData.push(RISKS[type].modifier)
                            break
                        case "start_date_month":
                        case "end_date_month":
                        case "start_date_day":
                        case "end_date_day":
                            let prop = key.split("_")
                            const dayOrMonth = prop.pop() // Delete "month" or "day" of the prop name array
                            prop = prop.join("_") // Join the array to "start_date" or "end_date"
                            rowData.push(currentAlert[prop][dayOrMonth])
                            break
                        default:
                            if (currentAlert[key] !== undefined) {
                                rowData.push(restoreCellValue(key, currentAlert[key]))
                            }
                            break
                    }
                }
            }

            tableData.push(rowData.map((data) => `"${data}"`).join(","))
        }
    }
    let totalColumns = 0
    tableData.forEach((row) => {
        const colCount = splitCSVRow(row).length
        if (colCount > totalColumns) totalColumns = colCount
    })
    return `${crop} Alerts ,,,,,,,,,,,,,,
Days to maturity,NA,not editable,,,,,,,,,,,,
Variety:,${variety},editable by customer,,,,,,,,,,,,
Units: ,Metric (Imperial/Metric),Yield  column: to forget for now,,,,,,,,,,,,
,,,,,,,,,,,,,,
${tableHeaders.slice(0, totalColumns).join(",")}
${tableData.join("\n")}`
}

export function parseRiskFactorsCSVFile(startValue, file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.onload = function () {
            // get csv lines
            const lines = reader.result.split("\n")

            let resultObj = {}
            // fill alert configs
            for (let i = startValue + 1; i < lines.length; i++) {
                const cells = splitCSVRow(lines[i])

                let riskCategory = cells[0]
                let alertId = cells[1].replace("'", "")
                let weight = cells[2]

                resultObj = {
                    ...resultObj,
                    [riskCategory]: {
                        ...resultObj[riskCategory],
                        [alertId]: {
                            weight: parseFloat(weight),
                        },
                    },
                }
            }
            resolve(resultObj)
        }
        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsBinaryString(file)
    })
}

export function riskFactorsToCSV(crop, variety, tool, alerts) {
    // const tableHeaders = []
    const tableHeaders = ["Risk Category", "Alert ID", "Weight"]
    const tableData = []

    alerts.forEach((alert) => {
        const rowData = []
        rowData.push(alert.type)
        rowData.push("'" + alert.subType)
        rowData.push(alert.weight)
        tableData.push(rowData.join(","))
    })
    tool = tool.replace(" ", "")

    return `Crop:,${crop} ,,,,,,,,,,,,,,
Variety:,${variety},,,,,,,,,,,,,,
Tool:,${tool},,,,,,,,,,,,,,
,,,,,,,,,,,,,,
${tableHeaders.join(",")}
${tableData.join("\n")}`
}

export const CLIMATE_CSV_HEADERS = [
    "Decade",
    "5th quantile",
    "25th quantile",
    "50th quantile",
    "75th quantile",
    "95th quantile",
]

export const SEASONAL_CSV_HEADERS = ["Month", ...CLIMATE_CSV_HEADERS]

export const GET_DEPARTURE_CSV_HEADERS = (variable) => ["Year", variable]

export const ANALOGS_CSV_HEADERS = [
    "Longitude",
    "Latitude",
    "Similarity",
    "Analog months",
    "Max temp similarity",
    "Min temp similarity",
    "Precipitation similarity",
]

export const CROP_TABLE_CSV_HEADERS = ["Location", "Region", "Category", "Subcategory", "Labels"]

export const OVERVIEW_CSV_HEADERS = [
    "Location",
    "Region",
    "Category",
    "Subcategory",
    "2010-2019",
    "2020-2029",
    "2030-2039",
    "2040-2049",
    "2050-2059",
    "2060-2069",
]

export const buildCSVString = (csvHeaderSection, csvHeaders, csvData) => {
    return csvHeaderSection + csvHeaders.join(",") + "\n" + csvData.map((line) => line.join(",")).join("\n")
}

export const formatSeasonalCSVData = (data) => {
    let csvData = data?.points?.sort((a, b) => a.x - b.x)
    let result = []
    if (!csvData || !csvData.length) return []
    for (let i = 0; i < csvData[0].values.length; i++) {
        csvData.forEach((point) => {
            const monthYearData = point.values[i]
            const date = new Date(monthYearData.x)
            const year = date.getFullYear()
            const monthDate = new Date(point.x)
            result.push([
                MONTH_NAMES[monthDate.getMonth()],
                year + "-" + (+year + 9),
                monthYearData.y0,
                monthYearData.yMin,
                monthYearData.yMid,
                monthYearData.yMax,
                monthYearData.y1,
            ])
        })
    }
    return result
}

export const formatClimateCSVData = (data) => {
    return data?.points?.map((point) => {
        const dateYear = new Date(point.x).getFullYear()
        return [dateYear + "-" + (+dateYear + 9), point.y0, point.yMin, point.yMid, point.yMax, point.y1]
    })
}

export const formatDepartureCSVData = (data) => {
    return data?.points?.map((point) => {
        const dateYear = new Date(point.x).getFullYear()
        return [dateYear, point.y]
    })
}

export const formatAnalogsCSVData = (data) => {
    return data
        ?.filter((feature) => feature.properties.opacity)
        .map((feature) => {
            const [lat, lon] = feature?.geometry?.coordinates
            const value = feature?.properties?.value.toFixed(1)
            const months = feature?.properties?.months
            const t2m_max = feature?.properties?.variables?.t2m_max?.value.toFixed(1) || "Not used"
            const t2m_min = feature?.properties?.variables?.t2m_min?.value.toFixed(1) || "Not used"
            const tp = feature?.properties?.variables?.tp?.value.toFixed(1) || "Not used"
            return [lon, lat, value, months, t2m_max, t2m_min, tp]
        })
}

export const formatOverviewCSVData = ({ data }) => {
    const result = []
    data.forEach((info) => {
        let decades = info.decades
        decades = Object.keys(decades).map((decadeKey) => decades[decadeKey].yield_impact)
        result.push([info.location.replaceAll("_", " "), info.region, info.crop, info.variety, ...decades])
    })
    return result
}

export const formatSeasonalOverviewCSVData = ({ data, context: { units } }) => {
    // Return if undefined or if there's no data
    // NOTE: This conditional is constructed in a way that
    // if it's undefined/null it doesn't evaluate the length.
    if (!(data && data.length > 0)) return []

    // Parse each field into an array of values
    return data.map((field) => {
        return [
            `"${field.name}"`, // Location
            `"${field.region}"`, // Region
            `"${field.crop}"`, // Category
            `"${field.variety}"`, // Subcategory
            `"${field.labels.map((label) => label.name).join("-")}"`, // Labels
        ]
    })
}

import { useContext, useEffect, useState } from "react"
import { withRouter } from "react-router"
import { Link as RouterLink, useLocation } from "react-router-dom"
import BackgroundAndCardLayout from "./BackgroundAndCardLayout"
import { Button } from "./Button"
import { GenericFromInputAndLabel } from "./SignUp"
import { ToastContext } from "./Toast/ToastContextProvider"
import app from "../Util/Fire"

const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")

const RestorePasswordNewPassword = ({ history }) => {
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const { enqueueError } = useContext(ToastContext)

    const [urlCode, setUrlCode] = useState("")
    const location = useLocation()

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const oobCode = queryParams.get("oobCode")
        if (!oobCode) {
            enqueueError("Restore password code not provided.")
            setTimeout(() => {
                history.push("/")
            }, 4000)
        }
        setUrlCode(oobCode)
    }, [])

    const handlePassword = async (event) => {
        event.preventDefault()
        setLoading(true)
        try {
            await app.auth().confirmPasswordReset(urlCode, newPassword)
            setLoading(false)
            history.push("/restore-password/confirmation")
        } catch (error) {
            setLoading(false)
            enqueueError(error)
        }
    }

    const isValidPassword = (pwd1) => {
        const errors = []
        if (!new RegExp("^(?=.*[a-z])").test(pwd1)) errors.push("one lowercase character")
        if (!new RegExp("^(?=.*[A-Z])").test(pwd1)) errors.push("one uppercase character")
        if (!new RegExp("^(?=.*[0-9])").test(pwd1)) errors.push("one numeric character")
        if (!new RegExp("^(?=.*[!@#$%^&*])").test(pwd1)) errors.push("one special character")
        if (pwd1.length < 8) errors.push("8 characters long")
        if (errors.length > 0) {
            setError("The password must contain at least " + errors.join(", ") + ".")
            return false
        }
        setError("")
        return true
    }

    const returnPath = history.location.state ? history.location.state.prevPath : "/"

    return (
        <BackgroundAndCardLayout>
            <div className="montserrat text-[16px] text-gray-90 font-semibold text-center leading-tight pb-2">
                Create a new password
            </div>
            <GenericFromInputAndLabel
                label="New password"
                handleChange={(e) => {
                    setNewPassword(e.target.value)
                    isValidPassword(e.target.value)
                }}
                value={newPassword}
                placeholder="********"
                type="password"
                error={error}
            />
            <GenericFromInputAndLabel
                label="Confirm new password"
                handleChange={(e) => {
                    setConfirmNewPassword(e.target.value)
                }}
                value={confirmNewPassword}
                placeholder="********"
                type="password"
                error={
                    confirmNewPassword !== "" && newPassword !== "" && newPassword !== confirmNewPassword
                        ? "The passwords do not match"
                        : ""
                }
            />
            <div className="flex flex-row self-stretch pt-4 pb-2 space-x-2">
                <RouterLink className="w-1/2" to={returnPath}>
                    <Button label="Cancel" type="secondary" extend={true} onClick={() => {}} />
                </RouterLink>
                <Button
                    extraClasses={"w-1/2" + (loading ? " cursor-wait" : "")}
                    label="Continue"
                    type="primary"
                    extend={true}
                    onClick={handlePassword}
                    disabled={newPassword !== confirmNewPassword || !strongRegex.test(newPassword) || loading}
                />
            </div>
        </BackgroundAndCardLayout>
    )
}

export default withRouter(RestorePasswordNewPassword)

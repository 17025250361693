export default function GradientLegendSpec({
    colors = ["#e31a02", "#ffa89e", "#c9c9c9", "#dedede", "#89b8e0", "#0e4775"],
    labels = ["Warmer", "Normal", "Colder"],
}) {
    const id = colors.join("").replaceAll("#", "")
    return (
        <svg width="110" height="125" style={{ fontFamily: "sans-serif", fontSize: 12 + "px", overflow: "visible" }}>
            <defs>
                <linearGradient id={id + "1"} x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style={{ stopColor: colors[3], stopOpacity: 1 }}></stop>
                    <stop offset="100%" style={{ stopColor: colors[3], stopOpacity: 1 }}></stop>
                </linearGradient>
                <linearGradient id={id + 2} x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style={{ stopColor: colors[3], stopOpacity: 1 }}></stop>
                    <stop offset="100%" style={{ stopColor: colors[3], stopOpacity: 1 }}></stop>
                </linearGradient>
                <linearGradient id={id + 3} x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style={{ stopColor: colors[3], stopOpacity: 1 }}></stop>
                    <stop offset="100%" style={{ stopColor: colors[3], stopOpacity: 1 }}></stop>
                </linearGradient>
            </defs>
            <g transform="translate(2,5)">
                <rect width="30" height="40" y="0" fill={`url(#${id + 1})`}></rect>
                <rect width="30" height="40" y="40" fill={`url(#${id + 2})`}></rect>
                <rect width="30" height="40" y="80" fill={`url(#${id + 3})`}></rect>
                <text y="20" x="50" fill="#5E5C5E" alignmentBaseline="middle">
                    {labels[0]}
                </text>
                <text y="60" x="50" fill="#5E5C5E" alignmentBaseline="middle">
                    {labels[1]}
                </text>
                <text y="100" x="50" fill="#5E5C5E" alignmentBaseline="middle">
                    {labels[2]}
                </text>
                symbol: '↓',
                <text y="20" x="7.5" fontSize="20px" fill={"#E31A02"} alignmentBaseline="middle">
                    ↑
                </text>
                <text y="60" x="9.5" fontSize="20px" fill="gray" alignmentBaseline="middle">
                    =
                </text>
                <text y="100" x="7.5" fontSize="20px" fill={"#0E4775"} alignmentBaseline="middle">
                    ↓
                </text>
            </g>
        </svg>
    )
}

import { createContext, useEffect, useReducer } from "react"
import { getMyReports } from "../services/customAnalytics.service"

export const DataContext = createContext({})

const initialState = {
    fields: [],
    customReports: [],
    regulatoryReports: [],
    polygons: {},
}

function DataReducer(state = {}, action) {
    const { type, payload } = action

    switch (type) {
        case "GET_FIELDS": {
            const { fields } = payload
            fields.forEach((field) => {
                const risksSeen = {}
                field.triggered_alerts?.variables?.forEach((risk) => {
                    risksSeen[risk.risk_alert] = true
                })
                field.alert_types_count = Object.keys(risksSeen).length
            })
            return { ...state, fields }
        }
        case "GET_POLYGONS": {
            const { polygons } = payload
            return { ...state, polygons }
        }
        case "SET_CUSTOM_REPORTS": {
            const { customReports, regulatoryReports } = payload
            return { ...state, customReports, regulatoryReports }
        }
        default: {
            return state
        }
    }
}

function DataProvider(props) {
    const { children } = props
    const [state, dispatch] = useReducer(DataReducer, initialState)

    const setCustomReports = async () => {
        const reports = await getMyReports()
        dispatch({
            type: "SET_CUSTOM_REPORTS",
            payload: {
                customReports: reports.filter((report) => report.tab === "custom"),
                regulatoryReports: reports.filter((report) => report.tab === "regulatory"),
            },
        })
    }

    useEffect(() => {
        setCustomReports()
    }, [])

    return <DataContext.Provider value={{ state, dispatch }}>{children}</DataContext.Provider>
}

export default DataProvider

const LowWindDayRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M13.5 16C13.5 17.65 12.15 19 10.5 19C8.85 19 7.5 17.65 7.5 16H9.5C9.5 16.55 9.95 17 10.5 17C11.05 17 11.5 16.55 11.5 16C11.5 15.45 11.05 15 10.5 15H2V13H10.5C12.15 13 13.5 14.35 13.5 16ZM19 5.5C19 3.57 17.43 2 15.5 2C13.57 2 12 3.57 12 5.5H14C14 4.67 14.67 4 15.5 4C16.33 4 17 4.67 17 5.5C17 6.33 16.33 7 15.5 7H2V9H15.5C17.43 9 19 7.43 19 5.5Z"
                fill={props.fill}
            />
            <path d="M22 12H16V14H22V12Z" fill={props.fill} />
        </svg>
    )
}

export default LowWindDayRiskIcon

import { useState, useEffect, useContext, useMemo } from "react"
import useDecadalData from "../../hooks/useDecadalData"
import DecadalRiskChart from "./components/DecadalRiskChart"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import { ToastContext } from "../../components/Toast/ToastContextProvider"
import { SettingsContext } from "../../Util/SettingsContext"

function Decadal(props) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const { enqueueNetworkError } = useContext(ToastContext)

    const {
        ready,
        locations,
        scenarios,
        crops,
        risks,
        selectedLocation,
        selectedPlot,
        selectedCrop,
        selectedVariety,
        selectedRisk,
        selectedScenario,
        setSelectedLocation,
        setSelectedCropVariety,
        setSelectedRisk,
        setSelectedScenario,
        variables,
    } = useAdaptationValues()

    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?plot=${selectedPlot}&scenario=${selectedScenario}`

        const request = http.get(`/api/v1/adapt/v2/decadal/risk${query}`, { timeout: 180000 })

        request
            .then((response) => {
                setData(response.data)
            })
            .catch((err) => {
                console.log(err)
                enqueueNetworkError()
            })
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedPlot, selectedScenario])

    const riskVariable = useMemo(() => {
        return (selectedRisk && Object.values(variables).find((v) => risks[selectedRisk].unit === v.units)) || {}
    }, [selectedRisk])

    const { chartData, maxValue, minValue } = useDecadalData(data[selectedRisk])

    return (
        <div className="overview">
            <div className="overview__main">
                <DecadalRiskChart
                    units={units}
                    maxValue={maxValue}
                    minValue={minValue}
                    data={chartData}
                    loading={loading}
                    // location dropdown
                    locations={locations}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    // crop dropdown
                    crops={crops}
                    selectedCrop={selectedCrop}
                    selectedVariety={selectedVariety}
                    setSelectedCropVariety={setSelectedCropVariety}
                    // risk dropdown
                    risks={risks}
                    selectedRisk={selectedRisk}
                    setSelectedRisk={setSelectedRisk}
                    riskVariable={riskVariable}
                    // scenario dropdown
                    scenarios={scenarios}
                    selectedScenario={selectedScenario}
                    setSelectedScenario={setSelectedScenario}
                    // variablesByDefault={variablesByDefault}
                />
            </div>
        </div>
    )
}

export default Decadal

import { useState, useEffect, useContext, useMemo } from "react"
import SeasonalRiskChart from "./components/SeasonalRiskChart"
import ChartLabels from "../../components/LongTerm/ChartLabels/ChartLabels"
import ChartLabelItem from "../../components/LongTerm/ChartLabels/ChartLabelItem"
import Checkbox from "../../ui/Checkbox/Checkbox"
import useSeasonalData from "../../hooks/useSeasonalData"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import { ToastContext } from "../../components/Toast/ToastContextProvider"
import { SettingsContext } from "../../Util/SettingsContext"

export const chartColors = [
    "#1C9690",
    "#9F24AA",
    "#039BE5",
    "#E8A500",
    "#155ECC",
    "#EE6900",
    "#3d3d3d",
    "#8E7CC3",
    "#B3B6BA",
    "#A220B7",
    "gray",
]
export const innerChartColors = [
    "#E8F5F4",
    "#F5E9F7",
    "#E5F6FC",
    "#FDF6E5",
    "#E8EFFA",
    "#FDF0E6",
    "#3d3d3d",
    "#8E7CC3",
    "#F2F3F3",
    "#F6E9F8",
    "#F2F3F3",
]
const initialSelected = {
    2010: true,
    2020: false,
    2030: false,
    2040: false,
    2050: false,
    2060: true,
    2070: false,
    2080: false,
}

function SeasonalRisk(props) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [selected, setSelected] = useState(initialSelected)

    const { enqueueNetworkError } = useContext(ToastContext)

    const {
        ready,
        locations,
        scenarios,
        crops,
        risks,
        selectedLocation,
        selectedPlot,
        selectedCrop,
        selectedVariety,
        selectedRisk,
        selectedScenario,
        setSelectedLocation,
        setSelectedCropVariety,
        setSelectedRisk,
        setSelectedScenario,
        variables,
    } = useAdaptationValues()

    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    function handleCheckboxChange(key) {
        if (selected[key]) setSelected({ ...selected, [key]: false })
        else setSelected({ ...selected, [key]: true })
    }

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?plot=${selectedPlot}&scenario=${selectedScenario}`

        const request = http.get(`/api/v1/adapt/v2/seasonal/risk${query}`, { timeout: 180000 })

        request
            .then((response) => {
                console.log(response.data)
                setData(response.data)
                let newSelected = { ...selected }
                setSelected(newSelected)
            })
            .catch((err) => {
                console.log(err)
                enqueueNetworkError()
            })
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedPlot, selectedScenario])

    const riskVariable = useMemo(() => {
        return (selectedRisk && Object.values(variables).find((v) => risks[selectedRisk].unit === v.units)) || {}
    }, [selectedRisk])

    const { chartData, maxValue, minValue } = useSeasonalData(
        data[selectedRisk],
        selected,
        chartColors,
        (selectedRisk && risks[selectedRisk].case === 1 && riskVariable?.convertToUnits) || undefined
    )

    return (
        <>
            <div className="overview">
                <div className="overview__main">
                    <SeasonalRiskChart
                        units={units}
                        maxValue={maxValue}
                        minValue={minValue}
                        data={chartData}
                        loading={loading}
                        // location dropdown
                        locations={locations}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        // crop dropdown
                        crops={crops}
                        selectedCrop={selectedCrop}
                        selectedVariety={selectedVariety}
                        setSelectedCropVariety={setSelectedCropVariety}
                        // risk dropdown
                        risks={risks}
                        selectedRisk={selectedRisk}
                        setSelectedRisk={setSelectedRisk}
                        riskVariable={riskVariable}
                        // scenario dropdown
                        scenarios={scenarios}
                        selectedScenario={selectedScenario}
                        setSelectedScenario={setSelectedScenario}
                        // variablesByDefault={variablesByDefault}
                    >
                        <ChartLabels>
                            {new Array(6)
                                .fill(null)
                                .map((_, i) => 2010 + i * 10)
                                .map((d, i) => {
                                    const disabled = !(
                                        data &&
                                        selectedRisk &&
                                        data[selectedRisk] &&
                                        data[selectedRisk][d]
                                    )
                                    return (
                                        <ChartLabelItem
                                            key={`seasonal_select_${d.toString()}`}
                                            disabled={disabled}
                                            renderIcon={() => (
                                                <Checkbox
                                                    stroke={chartColors[i]}
                                                    fill={innerChartColors[i]}
                                                    checkmarkIcon={false}
                                                    checked={selected[d.toString()]}
                                                    status={selected[d.toString()] ? "full" : "empty"}
                                                    onChange={() => !disabled && handleCheckboxChange(d.toString())}
                                                />
                                            )}
                                        >
                                            <button
                                                className="btn"
                                                style={{
                                                    opacity: selected[d.toString()] ? 1 : 0.4,
                                                    fontFamily: "Montserrat",
                                                }}
                                                onClick={() => !disabled && handleCheckboxChange(d.toString())}
                                                onMouseEnter={(e) => {
                                                    e.target.style.opacity = 1
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.opacity = selected[d.toString()] ? 1 : 0.4
                                                }}
                                            >
                                                {d} - {d + 9}
                                            </button>
                                        </ChartLabelItem>
                                    )
                                })}
                        </ChartLabels>
                    </SeasonalRiskChart>
                </div>
            </div>
        </>
    )
}

export default SeasonalRisk

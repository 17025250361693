const ChillHoursStressRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.17 13L2 13L2 11L6.17 11L2.93 7.76L4.34 6.34L9 11L11 11L11 9L6.34 4.34L7.76 2.93L11 6.17L11 2L13 2L13 6.17L13 9L13 11L13 13L13 15L13 17.83L13 22L11 22L11 17.83L7.76 21.07L6.34 19.66L11 15L11 13L9 13L4.34 17.66L2.93 16.24L6.17 13Z"
                fill={props.fill}
            />
            <path
                d="M15 21.5422C19.0571 20.2679 22 16.4776 22 12C22 7.52234 19.0571 3.73204 15 2.45778L15 4.58149C15.6937 4.8623 16.3391 5.23758 16.9201 5.69138L15.4915 7.11996C16.0368 7.51078 16.5139 7.99073 16.9014 8.53849L18.3311 7.10882C19.185 8.21253 19.7568 9.54565 19.9381 11L17.917 11C17.9716 11.3252 18 11.6593 18 12C18 12.3407 17.9716 12.6748 17.917 13L19.9381 13C19.756 14.4607 19.18 15.7991 18.3198 16.9056L16.8907 15.4765C16.5016 16.0231 16.0231 16.5016 15.4765 16.8907L16.9056 18.3198C16.3283 18.7686 15.688 19.14 15 19.4185L15 21.5422Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default ChillHoursStressRiskIcon

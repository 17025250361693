import Firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/storage"
import "firebase/compat/database"
import "firebase/compat/analytics"
import config from "./config"
const firebaseConfig = config.firebase
// Initialize Firebase
const fire = Firebase.initializeApp(firebaseConfig)
Firebase.analytics()
export default fire

const DroughtRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path d="M5 8C7.76142 8 10 5.76142 10 3H9C6.23858 3 4 5.23858 4 8H5Z" fill={props.fill} />
            <path d="M21 18H3V20H21V18Z" fill={props.fill} />
            <path d="M19 13C16.2386 13 14 10.7614 14 8H15C17.7614 8 20 10.2386 20 13H19Z" fill={props.fill} />
            <rect x="11" y="3" width="2" height="15" fill={props.fill} />
        </svg>
    )
}

export default DroughtRiskIcon

import { normalizeString } from "../../helpers/wordHelper";
import { getAllCrops } from "../../services/cropVariety.service";
import { getAllLabels } from "../../services/label.service";

export const LABEL_COLORS = ["var(--color-primary)", "#1C9690", "#2187C1", "#8E3FF3", "#464B86",
"#55DDE0", "#E43F6F", "#FF99C8", "#C9E4CA", "#FFCF00"]

export const TEMPLATE_CSV_DATA = [
    ["Location Name", "Latitude", "Longitude", "Region", "Category", "Subcategory", "Starting Date (mm/dd/yy)", "Labels"],
    ["Mexico City", "19.2719", "-99.0954", "Mexico", "Tomato", "Cherry", "04/11/22", "Harvest-Other Label-Yet Another Label"]
]

export const generateLabelStructure = async (labels) => {
    let labelStructure = {}
    let currentColor = 0

    // Retrieve all labels from the backend
    try {
        const origLabels = await getAllLabels()
        labels.forEach((rawLabel) => {
            const currLabel = normalizeString(rawLabel)
            if (isEmptyString(currLabel)) return
            labelStructure[currLabel] = { color: LABEL_COLORS[currentColor], name: rawLabel }
            currentColor = (currentColor + 1) % LABEL_COLORS.length

            // Update the user label with the original label name if there's a match
            origLabels.forEach((origLabel) => {
                if (normalizeString(origLabel.name) !== currLabel) return
                labelStructure[currLabel] = { ...origLabel }
            })
        })
    } catch (err) {
        console.error(err ? err : "Something went wrong.");
    } finally {
        return labelStructure
    }
}

export const isEmptyString = (string) => {
    return !string || /^\s*$/.test(string)
}

export const isDate = function(date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export const MIN_LAT = -90
export const MAX_LAT = 90
export const MIN_LON = -180
export const MAX_LON = 180

export const TABLE_HEADER_COLUMN_DATA = [
    {label: "#", width: "w-[30px] text-gray-30", extraStyle: "", type: "number", required: true},
    {label: "Location Name", width: "w-[124px]", extraStyle: " text-left pl-[5px]", type: "string", required: true},
    {label: "Latitude", width: "w-[90px]", extraStyle: " text-left pl-[5px]", type: "number", required: true},
    {label: "Longitude", width: "w-[80px]", extraStyle: " text-left pl-[5px]", type: "number", required: true},
    {label: "Region", width: "w-[90px]", extraStyle: " text-left pl-[5px]", type: "string", required: true},
    {label: "Category", width: "w-[89px]", extraStyle: " text-left pl-[5px]", type: "string", required: true},
    {label: "Subcategory", width: "w-[110px]", extraStyle: " text-left pl-[5px]", type: "string", required: true},
    {label: "Starting Date", width: "w-[94px]", extraStyle: " text-left pl-[5px]", type: "date", required: false},
    {label: "Labels", width: "w-[117px]", extraStyle: " text-left pl-[5px]", type: "string", required: false},
]

export async function getUserCrops(setCrops){
    await getAllCrops().then(async (response) => {
        setCrops(response)
    })
}
import { useState, useEffect, useContext } from "react"
import DecadalTrendChart from "./components/DecadalTrendChart"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import useDecadalData from "../../hooks/useDecadalData"
import { ToastContext } from "../../components/Toast/ToastContextProvider"

function DecadalTrend() {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const { enqueueNetworkError } = useContext(ToastContext)

    const {
        ready,
        allLocations: locations,
        selectedLocation,
        setSelectedLocation,
        scenarios,
        selectedScenario,
        setSelectedScenario,
        variables,
        selectedVariable,
        setSelectedVariable,
    } = useAdaptationValues()

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?scenario=${selectedScenario}&location=${selectedLocation}&var=${selectedVariable}`

        const request = http.get(`/api/v1/adapt/v2/decadal/var${query}`, { timeout: 180000 })
        request
            .then((response) => {
                setData(response.data)
            })
            .catch((err) => {
                console.log(err)
                enqueueNetworkError()
            })
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedLocation, selectedVariable, selectedScenario])

    const { chartData, maxValue, minValue } = useDecadalData(
        data,
        ready ? variables[selectedVariable].convertToUnits : null
    )

    return (
        <>
            {/* <ReportDownloadButton
                text={`Decadal Risk / ${selectedLocation} / ${
                    selectedCrop && capitalizeFirstCharacter(selectedCrop)
                } / ${
                    variablesByDefault
                        ? selectedVariableObj.label
                        : `${selectedRiskObj.title} (${selectedRiskObj.subtitle}, ${selectedRiskObj.stage})`
                } / ${selectedScenarioObj.label}`}
            /> */}
            <div className="overview">
                <div className="overview__main">
                    <DecadalTrendChart
                        maxValue={maxValue}
                        minValue={minValue}
                        data={chartData}
                        loading={loading}
                        // locations dropdown
                        locations={locations}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        // scenarios dropdown
                        scenarios={scenarios}
                        selectedScenario={selectedScenario}
                        setSelectedScenario={setSelectedScenario}
                        // variables dropdown
                        variables={variables}
                        selectedVariable={selectedVariable}
                        setSelectedVariable={setSelectedVariable}
                    />
                </div>
            </div>
        </>
    )
}

export default DecadalTrend

import { useEffect, useRef, useState } from "react"

export function useLayout() {
    const ref = useRef(null)

    const getDimensions = () => {
        return {
            width: ref?.current?.offsetWidth || 0,
            height: ref?.current?.offsetHeight || 0,
        }
    }

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions())
        }

        if (ref.current) {
            setDimensions(getDimensions())
        }

        window.addEventListener("resize", handleResize)

        return () => window.removeEventListener("resize", handleResize)
    }, [ref])

    return { ref, dimensions }
}

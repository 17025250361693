import RedObservedIcon from "../../../../assets/Chart/red-observed.svg"
import BlueObservedIcon from "../../../../assets/Chart/blue-observed.svg"
import PurpleObservedIcon from "../../../../assets/Chart/purple-observed.svg"
import RedDotsIcon from "../../../../assets/Chart/red-dots.svg"
import PurpleDotsIcon from "../../../../assets/Chart/purple-dots.svg"
import BlueDotsIcon from "../../../../assets/Chart/blue-dots.svg"
import RedLightenHistoricalIcon from "../../../../assets/Chart/red-lighten-historical.svg"
import RedDarkenHistoricalIcon from "../../../../assets/Chart/red-darken-historical.svg"
import PurpleLightenHistoricalIcon from "../../../../assets/Chart/purple-lighten-historical.svg"
import PurpleDarkenHistoricalIcon from "../../../../assets/Chart/purple-darken-historical.svg"
import PurpleLightenConfidenceIcon from "../../../../assets/Chart/purple-lighten-confidence.svg"
import PurpleDarkenConfidenceIcon from "../../../../assets/Chart/purple-darken-confidence.svg"
import BlueLightenHistoricalIcon from "../../../../assets/Chart/blue-lighten-historical.svg"
import BlueDarkenHistoricalIcon from "../../../../assets/Chart/blue-darken-historical.svg"
import RedLightenSeasonalIcon from "../../../../assets/Chart/red-lighten-seasonal.svg"
import RedDarkenSeasonalIcon from "../../../../assets/Chart/red-darken-seasonal.svg"
import BlueLightenSeasonalIcon from "../../../../assets/Chart/blue-lighten-seasonal.svg"
import BlueDarkenSeasonalIcon from "../../../../assets/Chart/blue-darken-seasonal.svg"
import GreenLightenHistoricalIcon from "../../../../assets/Chart/green-lighten-historical.svg"
import GreenDarkenHistoricalIcon from "../../../../assets/Chart/green-darken-historical.svg"
import BrownLightenHistoricalIcon from "../../../../assets/Chart/brown-lighten-historical.svg"
import BrownDarkenHistoricalIcon from "../../../../assets/Chart/brown-darken-historical.svg"
import WindDirectionArrowIcon from "../../../../assets/Chart/wind-direction-arrow.svg"
import moment from "moment"
import MonthRangePicker from "../../../../components/MonthRangePicker"
import { MONTH_NAMES } from "../../../../Util/constants"

function parseDate(timestamp) {
    var d = new Date(timestamp * 1000)
    var da = moment(d).format("MMM DD, HH:mm")
    return da
}

export const SpecSection = ({ title, subtitle, children, toggler }) => {
    if (!title) return null
    return (
        <div className="flex flex-col space-y-[14px]">
            <div className="flex flex-row space-x-[10px] items-center">
                {toggler && <div className={subtitle && "-mt-[19px]"}>{toggler}</div>}
                <div className="flex flex-col space-y-[6px]">
                    <div className="roboto text-[16px] text-gray-90 font-normal">{title}</div>
                    {subtitle && <div className="roboto text-[12px] text-gray-30 font-medium">{subtitle}</div>}
                </div>
            </div>
            <div className="flex flex-col space-y-[8px]">{children}</div>
        </div>
    )
}

const SpecDetail = ({ srcImg, label }) => {
    return (
        <div className="flex flex-row items-center space-x-[12px]">
            <img className="w-[20px]" alt="" src={srcImg} />
            <div className="roboto text-[14px] text-gray-60 font-normal">{label}</div>
        </div>
    )
}

export const LastUpdatedSubtitle = ({ isLoading, date }) => {
    // TODO: Delete next line whenever all variables have ds_fc date
    if (isLoading || !date) return null

    return (
        <div className="flex items-center space-x-1">
            <div>Updated:</div>
            {!isLoading ? (
                date ? (
                    <div>{parseDate(date)}</div>
                ) : (
                    <div className="h-[12px] w-[100px]">No data</div>
                )
            ) : (
                <div className="h-[12px] w-[100px] bg-gray-5 animate-pulse-fast">Loading...</div>
            )}
        </div>
    )
}

export const MonthsRangeSelector = ({ onDateChange, startDate, endDate }) => {
    let ranges = {
        startDate,
        endDate,
    }
    return (
        <div className="chart-specs__range-date-selector__wrapper">
            <div>
                <div className="chart-specs__range-date-selector__label">Chill Season Start - End </div>
                <MonthRangePicker
                    startMonthIndex={ranges.startDate - 1}
                    endMonthIndex={ranges.endDate - 1}
                    startMonthPlaceholder={
                        ranges.startDate !== null ? MONTH_NAMES[ranges.startDate - 1] : "Start month"
                    }
                    endMonthPlaceholder={ranges.endDate !== null ? MONTH_NAMES[ranges.endDate - 1] : "End month"}
                    popupPositionClass="inset-x-0"
                    onChange={(indexes) => {
                        ranges.startDate = indexes[0] + 1
                        ranges.endDate = indexes[1] + 1
                        onDateChange(ranges.startDate, ranges.endDate)
                    }}
                />
            </div>
        </div>
    )
}

export const NumberRangeSelector = ({
    onNumberChange,
    lowerLimit,
    upperLimit,
    flag,
    error,
    onBlur = () => null,
    tempUnit = "°C",
}) => {
    let ranges = {
        lowerLimit,
        upperLimit,
    }
    return (
        <div className="chart-specs__range-date-selector__wrapper number-selector">
            <div>
                <div className="chart-specs__range-date-selector__label">Temperature Range {tempUnit}</div>
                <div className="relative min-w-0 select-none">
                    <div className="h-[36px] min-w-[60px] w-full flex flex-row items-center gap-2">
                        <input
                            className={[
                                "border-[1px] border-gray-10 rounded-lg",
                                "px-2 flex-1 h-full focus:outline-accent",
                                "flex flex-row items-center justify-between",
                                "hover:elevation-1 cursor-pointer w-1/2",
                            ].join(" ")}
                            type="number"
                            placeholder="0"
                            defaultValue={ranges.lowerLimit}
                            step="1"
                            onChange={(e) => {
                                onNumberChange(parseFloat(e.target.value), ranges.upperLimit)
                            }}
                            onBlur={onBlur}
                        ></input>
                        <input
                            className={[
                                "border-[1px] border-gray-10 rounded-lg",
                                "px-2 flex-1 h-full focus:outline-accent",
                                "flex flex-row items-center justify-between",
                                "hover:elevation-1 cursor-pointer w-1/2",
                            ].join(" ")}
                            type="number"
                            defaultValue={ranges.upperLimit}
                            placeholder="7"
                            step="1"
                            onChange={(e) => {
                                onNumberChange(ranges.lowerLimit, parseFloat(e.target.value))
                            }}
                            onBlur={onBlur}
                        ></input>
                    </div>
                </div>
            </div>
            {flag && <div className="number-selector__input-error">{error}</div>}
        </div>
    )
}

export const ObservedDetails = ({ type, units, tempValue }) => {
    switch (type) {
        case "growing-degree-days":
            return <SpecDetail srcImg={RedObservedIcon} label="GDDs" />
        case "temp-soil":
            return <SpecDetail srcImg={RedObservedIcon} label="Temp" />
        case "temp":
            switch (tempValue) {
                case "maxandmin":
                    return (
                        <>
                            <SpecDetail srcImg={RedObservedIcon} label="Maximum" />
                            <SpecDetail srcImg={BlueObservedIcon} label="Minimum" />
                        </>
                    )

                case "mean":
                    return <SpecDetail srcImg={PurpleObservedIcon} label="Mean" />
            }
        case "wind_speed":
            return <SpecDetail srcImg={RedObservedIcon} label={units === "metric" ? "km / h" : "MPH"} />
        case "relative-humidity":
            return <SpecDetail srcImg={BlueObservedIcon} label="Relative Humidity" />
        case "precipitation":
        case "water-budget":
            return <SpecDetail srcImg={BlueObservedIcon} label={units === "metric" ? "mm" : "in"} />
        case "soil_moisture":
            return <SpecDetail srcImg={RedObservedIcon} label="Water per m^3 of soil" />
        case "evapotranspiration":
            return <SpecDetail srcImg={RedObservedIcon} label={units === "metric" ? "mm" : "in"} />
        case "solar-radiation":
            return <SpecDetail srcImg={RedObservedIcon} label="Watt per m^2" />
        case "chill_hours":
            return <SpecDetail srcImg={BlueObservedIcon} label="Chill hours" />
        default:
            return null
    }
}

export const ForecastedDetails = ({ type, units, tempValue }) => {
    switch (type) {
        case "growing-degree-days":
            return <SpecDetail srcImg={RedDotsIcon} label="GDDs" />
        case "temp-soil":
            return <SpecDetail srcImg={RedDotsIcon} label="Temp" />
        case "temp":
            switch (tempValue) {
                case "maxandmin":
                    return (
                        <>
                            <SpecDetail srcImg={RedDotsIcon} label="Maximum" />
                            <SpecDetail srcImg={BlueDotsIcon} label="Minimum" />
                        </>
                    )
                case "mean":
                    return <SpecDetail srcImg={PurpleDotsIcon} label="Mean" />
            }

        case "wind_speed":
            // Figma only says "Forecast", keeping the old SpecDetail in case is needed
            return <SpecDetail srcImg={RedDotsIcon} label="Forecast" />
        case "wind_direction":
            return <SpecDetail srcImg={WindDirectionArrowIcon} label="Direction" />
        // return <SpecDetail srcImg={RedDotsIcon} label={units === "metric" ? "km / h" : "MPH"} />
        case "relative-humidity":
            return <SpecDetail srcImg={BlueDotsIcon} label="Relative Humidity" />
        case "precipitation":
        case "water-budget":
            return <SpecDetail srcImg={BlueDotsIcon} label={units === "metric" ? "mm" : "in"} />
        case "soil_moisture":
            return <SpecDetail srcImg={RedDotsIcon} label="Water per m^3 of soil" />
        case "evapotranspiration":
            return <SpecDetail srcImg={RedDotsIcon} label={units === "metric" ? "mm" : "in"} />
        case "solar-radiation":
            return <SpecDetail srcImg={RedDotsIcon} label="Watt per m^2" />
        case "chill_hours":
            return <SpecDetail srcImg={BlueDotsIcon} label="Chill hours" />
        default:
            return null
    }
}

export const ConfidenceBandsDetail = ({ type, tempValue }) => {
    switch (type) {
        case "growing-degree-days":
        case "temp-soil":
        case "wind_speed":
        case "soil_moisture":
        case "evapotranspiration":
        case "solar-radiation":
            return (
                <>
                    <SpecDetail srcImg={RedLightenSeasonalIcon} label="90%" />
                    <SpecDetail srcImg={RedDarkenSeasonalIcon} label="50%" />
                </>
            )
        case "temp":
            switch (tempValue) {
                case "maxandmin":
                    return (
                        <>
                            <SpecDetail srcImg={RedLightenSeasonalIcon} label="90%" />
                            <SpecDetail srcImg={RedDarkenSeasonalIcon} label="50%" />
                            <SpecDetail srcImg={BlueLightenSeasonalIcon} label="90%" />
                            <SpecDetail srcImg={BlueDarkenSeasonalIcon} label="50%" />
                        </>
                    )

                case "mean":
                    return (
                        <>
                            <SpecDetail srcImg={PurpleLightenConfidenceIcon} label="90%" />
                            <SpecDetail srcImg={PurpleDarkenConfidenceIcon} label="50%" />
                        </>
                    )
            }

        case "relative-humidity":
        case "precipitation":
        case "chill_hours":
        case "water-budget":
            return (
                <>
                    <SpecDetail srcImg={BlueLightenSeasonalIcon} label="90%" />
                    <SpecDetail srcImg={BlueDarkenSeasonalIcon} label="50%" />
                </>
            )
        default:
            return null
    }
}

export const HistoricalDetail = ({ type, cumulative, tempValue }) => {
    switch (type) {
        case "temp-soil":
        case "solar-radiation":
            return (
                <>
                    <SpecDetail srcImg={RedLightenHistoricalIcon} label="90%" />
                    <SpecDetail srcImg={RedDarkenHistoricalIcon} label="50%" />
                </>
            )
        case "temp":
            switch (tempValue) {
                case "maxandmin":
                    return (
                        <>
                            <SpecDetail srcImg={RedLightenHistoricalIcon} label="90%" />
                            <SpecDetail srcImg={RedDarkenHistoricalIcon} label="50%" />
                            <SpecDetail srcImg={BlueLightenHistoricalIcon} label="90%" />
                            <SpecDetail srcImg={BlueDarkenHistoricalIcon} label="50%" />
                        </>
                    )

                case "mean":
                    return (
                        <>
                            <SpecDetail srcImg={PurpleDarkenHistoricalIcon} label="90%" />
                            <SpecDetail srcImg={PurpleLightenHistoricalIcon} label="50%" />
                        </>
                    )
            }

        case "evapotranspiration":
        case "wind_speed":
            return (
                <>
                    <SpecDetail srcImg={GreenLightenHistoricalIcon} label="90%" />
                    <SpecDetail srcImg={GreenDarkenHistoricalIcon} label="50%" />
                </>
            )
        case "soil_moisture":
            return (
                <>
                    <SpecDetail srcImg={BrownLightenHistoricalIcon} label="90%" />
                    <SpecDetail srcImg={BrownDarkenHistoricalIcon} label="50%" />
                </>
            )
        case "water-budget":
        case "relative-humidity":
        case "precipitation":
            if (cumulative) return null
            return (
                <>
                    <SpecDetail srcImg={BlueLightenHistoricalIcon} label="90%" />
                    <SpecDetail srcImg={BlueDarkenHistoricalIcon} label="50%" />
                </>
            )
        case "growing-degree-days":
        default:
            return null
    }
}

export const WeeklyMonthlyVariableForecast = ({ type, units, tempValue }) => {
    switch (type) {
        case "temp":
            switch (tempValue) {
                case "maxandmin":
                    return (
                        <>
                            <SpecDetail srcImg={RedObservedIcon} label="Maximum" />
                            <SpecDetail srcImg={BlueObservedIcon} label="Minimum" />
                        </>
                    )

                case "mean":
                    return <SpecDetail srcImg={PurpleObservedIcon} label="Mean" />
            }
        case "solar-radiation":
            return <SpecDetail srcImg={RedObservedIcon} label="W/m^2" />
        case "temp-soil":
            return <SpecDetail srcImg={RedObservedIcon} label="Temp" />
        case "evapotranspiration":
            return <SpecDetail srcImg={RedObservedIcon} label={units === "metric" ? "mm" : "in"} />
        case "relative-humidity":
            return <SpecDetail srcImg={RedObservedIcon} label="%" />
        case "soil_moisture":
            return <SpecDetail srcImg={RedObservedIcon} label="Water per m^3 of soil" />
        case "wind_speed":
            return <SpecDetail srcImg={RedObservedIcon} label={units === "metric" ? "km / h" : "MPH"} />
        case "water-budget":
        case "precipitation":
            return <SpecDetail srcImg={BlueObservedIcon} label={units === "metric" ? "mm" : "in"} />
        case "growing-degree-days":
        default:
            return null
    }
}

export const MONTHLY_WEEKLY_LEGEND_METADATA = {
    temp: {
        maxandmin: {
            max: { title: "Maximum", color: "#EE6900", fill: "rgb(253, 239, 234)" },
            min: { title: "Minimum", color: "#039BE5", fill: "rgb(233, 243, 250)" },
        },
        mean: { mean: { title: "Mean", color: "#A220B7", fill: "rgb(246, 233, 248)" } },
    },
    precipitation: {
        sum: { title: "Precipitation", color: "rgb(29, 116, 179)", fill: "rgb(233, 241, 248)" },
    },
    "solar-radiation": {
        max: { title: "Solar Radiation", color: "#ff7100", fill: "rgb(255, 240, 233)" },
    },
    "temp-soil": {
        max: { title: "Soil Temperature", color: "#ff7100", fill: "rgb(255, 240, 233)" },
    },
    soil_moisture: {
        max: { title: "Soil Moisture", color: "#ff7100", fill: "rgb(255, 240, 233)" },
    },
    wind_speed: {
        max: { title: "Wind Speed", color: "#ff7100", fill: "rgb(255, 240, 233)" },
    },
    evapotranspiration: {
        max: { title: "Evapotranspiration", color: "#ff7100", fill: "rgb(255, 240, 233)" },
    },
    "relative-humidity": {
        max: { title: "Relative Humidity", color: "#ff7100", fill: "rgb(255, 240, 233)" },
    },
}

export const GRADIENT_LEGEND_METADATA = {
    temp: {},
    precipitation: {
        colors: ["#0e4775", "#c9c9c9", "#c9c9c9", "#dedede", "#ffbb00", "#916b00"],
        labels: ["More precipitation", "Normal", "Less precipitation"],
    },
    "solar-radiation": {
        colors: ["#FF7100", "#c9c9c9", "#c9c9c9", "#dedede", "#ffbb00", "#916b00"],
        labels: ["More radiation", "Normal", "Less radiation"],
    },
    "temp-soil": {
        colors: ["#e31a02", "#ffa89e", "#c9c9c9", "#dedede", "#89b8e0", "#039BE5"],
        labels: ["Warmer", "Normal", "Colder"],
    },
    soil_moisture: {
        colors: ["blue", "#ffa89e", "#c9c9c9", "#dedede", "#89b8e0", "#039BE5"],
        labels: ["More Moisture", "Normal", "Less Moisture"],
    },
    wind_speed: {
        colors: ["#e31a02", "#ffa89e", "#c9c9c9", "#dedede", "#89b8e0", "#0e4775"],
        labels: ["Faster", "Normal", "Slower"],
    },
    evapotranspiration: {
        colors: ["#e31a02", "#ffa89e", "#c9c9c9", "#dedede", "#89b8e0", "#039BE5"],
        labels: ["More Evapotranspiration", "Normal", "Less Evapotranspiration"],
    },
    "relative-humidity": {
        colors: ["#e31a02", "#ffa89e", "#c9c9c9", "#dedede", "#89b8e0", "#039BE5"],
        labels: ["More RH", "Normal", "Less RH"],
    },
}

export const VARIABLE_SECTION_TITLES = {
    temp: "Forecasted Temperature",
    "solar-radiation": "Solar Radiation",
    "temp-soil": "Soil Temperature",
    evapotranspiration: "Evapotranspiration",
    "relative-humidity": "Relative Humidity",
    soil_moisture: "Soil Moisture",
    wind_speed: "Wind Speed",
    precipitation: "Precipitation Forecast",
    "water-budget": "Water Budget",
}

import { useContext, useState, useMemo } from "react"
import { Slider } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { SettingsContext } from "../../../../Util/SettingsContext"
import DoubleCandleStickLegendContent from "./double-candlestick-legend.chartspec"
import GradientLegendSpec from "./gradient-bar-legend.chartspec.js"
import { getUnit } from "../../../../helpers/chartHelpers"
import Switch from "../../../../components/Switch"
import "./ChartSpecs.css"
import {
    ConfidenceBandsDetail,
    ForecastedDetails,
    HistoricalDetail,
    ObservedDetails,
    SpecSection,
    WeeklyMonthlyVariableForecast,
    VARIABLE_SECTION_TITLES,
    GRADIENT_LEGEND_METADATA,
    MONTHLY_WEEKLY_LEGEND_METADATA,
    LastUpdatedSubtitle,
    MonthsRangeSelector,
    NumberRangeSelector,
} from "./charSpecsUtils"
import GranularityButton from "../SeasonalVariableTabs/components/GranularityButton"
import ExportButton, { formatFilenameFieldAndVariable } from "../../../../components/ExportButton"
import useField from "../../../../hooks/useField"
import _ from "lodash"

const ChartSpecs = ({
    barType,
    GDDPlantingDate,
    onGDDPlantingDateChange,
    type,
    chartRef,
    data,
    onSliderChange,
    sliderMinValue,
    sliderMaxValue,
    sliderDefaultValue,
    historicalPending,
    actionsState,
    disabled,
    cumulative,
    confidenceVisible,
    climatologyVisible = true,
    lastUpdated = {},
    chSettings,
    onDateRangesSet,
    onLimitChange,
    colsArr = ["Date", "Value"],
    handleTempValueChange,
    handleAreasVisibilityChange = (d) => d,
    handleBarTypeChange = (d) => d,
    windDirectionVisible,
    toggleWindDirection = () => null,
    onSubmitChillHoursSettings = () => null,
    chSettingsErrors,
}) => {
    const { currentSettings } = useContext(SettingsContext)
    const [selectedTempValue, setSelectedTempValue] = useState("maxandmin")
    const field = useField()
    const fieldName = field ? field.name : undefined
    const variableName = actionsState.selectedVariablePrettyName

    const formatedFilename = useMemo(() => {
        return formatFilenameFieldAndVariable(fieldName, variableName)
    }, [fieldName, variableName])

    return (
        <>
            {actionsState.selectedVariable === "gdd" ? (
                <div className="gdd-planting-date-wrapper">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Planting Date"
                            autoOk={true}
                            value={GDDPlantingDate}
                            onChange={onGDDPlantingDateChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            ) : null}

            {actionsState.selectedVariable === "gdd" ? (
                <div className="chart-specs__slider-block">
                    <Slider
                        key={`slider-${sliderDefaultValue}`}
                        defaultValue={sliderDefaultValue}
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        marks
                        min={sliderMinValue}
                        max={sliderMaxValue}
                        valueLabelDisplay="on"
                        onChangeCommitted={onSliderChange}
                        disabled={disabled}
                    />
                    <p>Temp. Threshold ({getUnit({ system: currentSettings.units }).tempUnit})</p>
                </div>
            ) : null}

            {actionsState.extraPrecipitationChart ? (
                <div className="chart-specs__slider-block">
                    <h5>Evaporation Rate</h5>
                    <Slider
                        defaultValue={sliderDefaultValue}
                        aria-labelledby="discrete-slider-small-steps"
                        step={0.1}
                        marks
                        min={sliderMinValue ?? 0.3}
                        max={sliderMaxValue ?? 2}
                        valueLabelDisplay="auto"
                        onChange={onSliderChange}
                        disabled={disabled}
                    />
                </div>
            ) : null}

            {[
                "temp",
                "precipitation",
                "solar-radiation",
                "temp-soil",
                "soil_moisture",
                "wind_speed",
                "evapotranspiration",
                "relative-humidity",
                "chill_hours",
            ].includes(type) &&
                ["monthly", "weekly"].includes(actionsState.selectedGranularity) &&
                !cumulative && (
                    <>
                        {type === "temp" && (
                            <div className="w-full mb-[-14px]">
                                {["maxandmin", "mean"].map((tempValueType, index) => {
                                    return (
                                        <GranularityButton
                                            key={`temp_value_type_${tempValueType}`}
                                            onClick={() => {
                                                setSelectedTempValue(tempValueType)
                                                handleTempValueChange(tempValueType)
                                            }}
                                            isSelected={selectedTempValue === tempValueType}
                                            label={{ maxandmin: "Max & Min", mean: "Mean" }[tempValueType]}
                                            isLeft={index === 0}
                                            isRight={index === 1}
                                            isPossible={true}
                                        />
                                    )
                                })}
                            </div>
                        )}
                        <SpecSection
                            title={VARIABLE_SECTION_TITLES[type]}
                            subtitle={
                                <LastUpdatedSubtitle
                                    isLoading={lastUpdated["daily"]?.ds_fc === undefined}
                                    date={lastUpdated[actionsState.selectedGranularity]?.ds_fc}
                                />
                            }
                        >
                            <WeeklyMonthlyVariableForecast
                                type={type}
                                units={currentSettings.units}
                                tempValue={selectedTempValue}
                            />
                        </SpecSection>
                    </>
                )}

            {{
                monthly: [
                    "temp",
                    "precipitation",
                    "solar-radiation",
                    "temp-soil",
                    "soil_moisture",
                    "wind_speed",
                    "evapotranspiration",
                    "relative-humidity",
                ],
                weekly: ["temp", "precipitation", "solar-radiation"],
            }[actionsState.selectedGranularity]?.includes(type) && (
                <SpecSection
                    title="Historical Boxplots"
                    subtitle={
                        historicalPending ? (
                            <div className="h-full w-[100px] bg-gray-5 animate-pulse-fast">Loading...</div>
                        ) : (
                            "Last 20 years"
                        )
                    }
                    toggler={
                        <Switch
                            checked={barType === "candl"}
                            onChange={() => handleBarTypeChange(!(barType === "candl"))}
                            name="areas"
                        />
                    }
                >
                    <div className={["w-full", barType !== "candl" ? "opacity-50" : ""].join(" ")}>
                        {DoubleCandleStickLegendContent(
                            type === "temp"
                                ? MONTHLY_WEEKLY_LEGEND_METADATA[type][selectedTempValue]
                                : MONTHLY_WEEKLY_LEGEND_METADATA[type]
                        )}
                    </div>
                </SpecSection>
            )}

            {[
                "temp",
                "precipitation",
                "solar-radiation",
                "temp-soil",
                "soil_moisture",
                "wind_speed",
                "evapotranspiration",
                "relative-humidity",
            ].includes(type) &&
                ["monthly", "weekly"].includes(actionsState.selectedGranularity) &&
                !cumulative && (
                    <SpecSection
                        title="Directional Forecast"
                        toggler={
                            <Switch
                                checked={barType !== "candl"}
                                onChange={() => handleBarTypeChange(!(barType === "candl"))}
                            />
                        }
                    >
                        <div className={["w-full", barType === "candl" ? "opacity-50" : ""].join(" ")}>
                            {GradientLegendSpec(GRADIENT_LEGEND_METADATA[type] || {})}
                        </div>
                    </SpecSection>
                )}

            {!(
                !cumulative &&
                {
                    monthly: [
                        "temp",
                        "precipitation",
                        "solar-radiation",
                        "temp-soil",
                        "soil_moisture",
                        "wind_speed",
                        "evapotranspiration",
                        "relative-humidity",
                    ],
                    weekly: ["temp", "precipitation", "solar-radiation"],
                    hourly: ["wind_speed"],
                }[actionsState.selectedGranularity]?.includes(type)
            ) && (
                <>
                    {type === "temp" && (
                        <div className="w-full mb-[-14px]">
                            {["maxandmin", "mean"].map((tempValueType, index) => {
                                return (
                                    <GranularityButton
                                        key={`temp_value_type_${tempValueType}`}
                                        onClick={() => {
                                            setSelectedTempValue(tempValueType)
                                            handleTempValueChange(tempValueType)
                                        }}
                                        isSelected={selectedTempValue === tempValueType}
                                        label={{ maxandmin: "Max & Min", mean: "Mean" }[tempValueType]}
                                        isLeft={index === 0}
                                        isRight={index === 1}
                                        isPossible={true}
                                    />
                                )
                            })}
                        </div>
                    )}

                    {type === "chill_hours" ? (
                        <>
                            <MonthsRangeSelector
                                onDateChange={onDateRangesSet}
                                startDate={chSettings.startDate}
                                endDate={chSettings.endDate}
                            ></MonthsRangeSelector>

                            <NumberRangeSelector
                                onNumberChange={onLimitChange}
                                lowerLimit={chSettings.lowTemp}
                                upperLimit={chSettings.highTemp}
                                onBlur={onSubmitChillHoursSettings}
                                flag={chSettingsErrors.flag}
                                error={chSettingsErrors.error}
                                tempUnit={getUnit({ system: currentSettings.units }).tempUnit}
                            ></NumberRangeSelector>
                        </>
                    ) : null}

                    <SpecSection title={"Observed" + (type === "temp" ? " Temperature" : "")}>
                        <ObservedDetails type={type} units={currentSettings.units} tempValue={selectedTempValue} />
                    </SpecSection>

                    <SpecSection
                        title={"Forecast" + (type === "temp" ? "ed Temperature" : "")}
                        subtitle={
                            <LastUpdatedSubtitle
                                isLoading={lastUpdated["daily"]?.ds_fc === undefined}
                                date={lastUpdated[actionsState.selectedGranularity]?.ds_fc}
                            />
                        }
                    >
                        <ForecastedDetails type={type} units={currentSettings.units} tempValue={selectedTempValue} />
                    </SpecSection>

                    {(cumulative && type === "precipitation") || type === "growing-degree-days" ? null : (
                        <SpecSection
                            title="Confidence Bands"
                            toggler={
                                <Switch
                                    checked={confidenceVisible}
                                    onChange={(_) => {
                                        handleAreasVisibilityChange({
                                            conf: !confidenceVisible,
                                            clim: climatologyVisible,
                                        })
                                    }}
                                    name="areas"
                                />
                            }
                        >
                            {confidenceVisible && <ConfidenceBandsDetail type={type} tempValue={selectedTempValue} />}
                        </SpecSection>
                    )}
                    {(cumulative && type === "precipitation") ||
                    type === "growing-degree-days" ||
                    type === "chill_hours" ? null : (
                        <SpecSection
                            title="Historical average"
                            subtitle={
                                historicalPending ? (
                                    <div className="h-full w-[100px] bg-gray-5 animate-pulse-fast">Loading...</div>
                                ) : (
                                    "Last 10 years"
                                )
                            }
                            toggler={
                                <Switch
                                    checked={climatologyVisible}
                                    onChange={(_) => {
                                        handleAreasVisibilityChange({
                                            conf: confidenceVisible,
                                            clim: !climatologyVisible,
                                        })
                                    }}
                                    name="areas"
                                />
                            }
                        >
                            {climatologyVisible && (
                                <HistoricalDetail type={type} cumulative={cumulative} tempValue={selectedTempValue} />
                            )}
                        </SpecSection>
                    )}
                </>
            )}

            {({ hourly: ["wind_speed"] }[actionsState.selectedGranularity]?.includes(type) && (
                <>
                    <SpecSection title={VARIABLE_SECTION_TITLES[type]}>
                        <ForecastedDetails type={type} units={currentSettings.units} />
                    </SpecSection>

                    <SpecSection
                        title="Wind Direction"
                        toggler={
                            <Switch
                                checked={windDirectionVisible}
                                onChange={(_) => {
                                    toggleWindDirection(!windDirectionVisible)
                                }}
                                name="areas"
                            />
                        }
                    >
                        <ForecastedDetails type="wind_direction" />
                    </SpecSection>
                </>
            )) ||
                null}

            <div className="pt-0">
                <ExportButton
                    cols={type === "temp" ? { csv: colsArr[selectedTempValue] } : { csv: colsArr }}
                    data={data}
                    isDisabled={disabled}
                    wrapperClasses="absolute top-3 right-2"
                    fileName={formatedFilename}
                    idToExport="seasonal-section"
                />
            </div>
        </>
    )
}

export default ChartSpecs

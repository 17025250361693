import { Switch, Redirect, useHistory } from "react-router-dom"

// Views
import Dashboard from "../views/Dashboard"
import { Seasonal, PlantingToolV2, ProfileSettings, TableSettings } from "../views"

// Components
import { RouteWithLayout, TopBar } from "../components"

// Layouts
import { ConsolidatedLayout } from "../layouts"
import PrivateRoute from "../Util/PrivateRoute"
import { AlertSettings } from "../views/Settings"
import CropVarietySettings from "../views/Settings/CropVarietySettings/CropVarietySettings"
import { AuthContext } from "../Auth/Auth"
import { useContext, useState, useEffect } from "react"
import AddLocations from "../views/AddLocations/AddLocations"

function SeasonalRouter(props) {
    const PROFILE_OPTIONS = [
        {
            id: "user-settings__user-profile",
            label: "User Settings",
            to: "/weather/user-settings",
        },
        {
            id: "risk-profiles__user-profile",
            label: "Risk Profiles",
            to: "/weather/risk-profiles",
        },
        {
            id: "risk-categories__user-profile",
            label: "Risk Categories",
            to: "/weather/risk-categories",
        },
    ]

    const history = useHistory()

    const [currentPageURL, setCurrentPageURL] = useState(window.location.href)

    useEffect(() => {
        history.listen((location) => {
            setCurrentPageURL(location.pathname)
        })
    }, [history])

    const { currentUser } = useContext(AuthContext)
    if (!currentUser) return <Redirect to="/" />

    return (
        <div className="flex flex-col h-full">
            <TopBar profileOptions={PROFILE_OPTIONS} url={currentPageURL} />
            <div className="grow overflow-auto">
                <Switch>
                    <PrivateRoute exact path="/weather/dashboard" component={Dashboard} />
                    <RouteWithLayout
                        requiresPermission={false}
                        component={ProfileSettings}
                        exact
                        layout={ConsolidatedLayout}
                        path="/weather/user-settings"
                        title="User Settings"
                        settings
                    />
                    <RouteWithLayout
                        requiresPermission={false}
                        component={AlertSettings}
                        exact
                        layout={ConsolidatedLayout}
                        path="/weather/risk-profiles"
                        title="Risk Profiles"
                        settings
                    />
                    <RouteWithLayout
                        requiresPermission={false}
                        component={AlertSettings}
                        exact
                        layout={ConsolidatedLayout}
                        path="/weather/alerts-settings-stacked"
                        stacked={true}
                        title="Stacked Alerts Settings"
                        settings
                    />
                    <RouteWithLayout
                        requiresPermission={false}
                        component={CropVarietySettings}
                        exact
                        layout={ConsolidatedLayout}
                        path="/weather/risk-categories"
                        title="Risk Categories"
                        settings
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={AddLocations}
                        permission="weather"
                        exact
                        layout={ConsolidatedLayout}
                        showSidebar={false}
                        applyStyling={false}
                        navItem="dashboards"
                        path="/weather/add-locations"
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={Seasonal}
                        permission="weather"
                        exact
                        layout={ConsolidatedLayout}
                        navItem="dashboards"
                        path="/weather/:id"
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={PlantingToolV2}
                        permission="planting_tool"
                        title="Planting Tool"
                        exact
                        layout={ConsolidatedLayout}
                        navItem="tools"
                        config={{ tabName: "planting_tool" }}
                        path="/weather/:id/planting_tool"
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={PlantingToolV2}
                        permission="harvesting_tool"
                        title="Harvesting Tool"
                        exact
                        layout={ConsolidatedLayout}
                        navItem="tools"
                        config={{ tabName: "harvesting_tool" }}
                        path="/weather/:id/harvesting_tool"
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={PlantingToolV2}
                        permission="spraying_tool"
                        title="Spraying Tool"
                        exact
                        layout={ConsolidatedLayout}
                        navItem="tools"
                        config={{ tabName: "spraying_tool" }}
                        path="/weather/:id/spraying_tool"
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={PlantingToolV2}
                        permission="nitrogen_application_tool"
                        title="Nitrogen Application Tool"
                        exact
                        layout={ConsolidatedLayout}
                        navItem="tools"
                        config={{ tabName: "nitrogen_application_tool" }}
                        path="/weather/:id/nitrogen_application_tool"
                    />
                    <Redirect to="/weather/dashboard" />
                </Switch>
            </div>
        </div>
    )
}

export default SeasonalRouter

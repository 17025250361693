import { useCallback, useContext, useEffect, useState } from "react"
import { AuthContext } from "../../Auth/Auth"
import { useLocation, useHistory } from "react-router-dom"
import SeasonalVariableTabs from "./components/SeasonalVariableTabs"
import TempChart from "./components/TempChart"
import PrecipitationChart from "./components/PrecipitationChart"
import SoilTempChart from "./components/SoilTempChart"
import RelativeHumidityChart from "./components/RelativeHumidityChart"
import EvapotranspirationChart from "./components/EvapotranspirationChart"
import WindSpeedChart from "./components/WindSpeedChart"
import SoilMoistureChart from "./components/SoilMoistureChart"
import SolarRadiationChart from "./components/SolarRadiationChart"
import GrowingDegreeDaysChart from "./components/GrowingDegreeDaysChart"

import "./Seasonal.css"
import ChillHoursChart from "./components/ChillHoursChart"

const Seasonal = ({}) => {
    const history = useHistory()
    const { featurePermissions } = useContext(AuthContext)

    const { search } = useLocation()
    // TODO: Add check to see if variable is enabled. You can now go to any
    // variable if passed through Query Params regardless of the permissions

    const params = new URLSearchParams(search)
    const [actionsState, setActionsState] = useState({
        selectedVariable: params.get("variable") || "",
        selectedGranularity: params.get("granularity") || "",
    })

    const handleChangeAction = useCallback((state) => {
        setActionsState(state)
    }, [])

    const renderComponent = useCallback(() => {
        switch (actionsState.selectedVariable) {
            case "temperature_t2m":
                return <TempChart actionsState={{ ...actionsState, selectedVariablePrettyName: "Temperature" }} />
            case "precipitation":
                return (
                    <PrecipitationChart
                        actionsState={{ ...actionsState, selectedVariablePrettyName: "Precipitation" }}
                    />
                )
            case "soil_temperature":
                return (
                    <SoilTempChart actionsState={{ ...actionsState, selectedVariablePrettyName: "Soil Temperature" }} />
                )
            case "relative_humidity":
                return (
                    <RelativeHumidityChart
                        actionsState={{ ...actionsState, selectedVariablePrettyName: "Relative Humidity" }}
                    />
                )
            case "water_budget":
                return (
                    <PrecipitationChart
                        actionsState={{
                            ...actionsState,
                            extraPrecipitationChart: true,
                            selectedVariablePrettyName: "Water Budget",
                        }}
                    />
                )
            case "evapotranspiration":
                return (
                    <EvapotranspirationChart
                        actionsState={{ ...actionsState, selectedVariablePrettyName: "Evapotranspiration" }}
                    />
                )
            case "solar_radiation":
                return (
                    <SolarRadiationChart
                        actionsState={{ ...actionsState, selectedVariablePrettyName: "Solar Radiation" }}
                    />
                )
            case "gdd":
                return (
                    <GrowingDegreeDaysChart
                        actionsState={{ ...actionsState, selectedVariablePrettyName: "Growing Degree Days" }}
                    />
                )
            case "wind_speed":
                return <WindSpeedChart actionsState={{ ...actionsState, selectedVariablePrettyName: "Wind Speed" }} />
            case "soil_moisture":
                return (
                    <SoilMoistureChart
                        actionsState={{ ...actionsState, selectedVariablePrettyName: "Soil Moisture" }}
                    />
                )
            case "chill_hours":
                return <ChillHoursChart actionsState={{ ...actionsState, selectedVariablePrettyName: "Chill Hours" }} />
            default:
                return null
        }
    }, [actionsState])

    useEffect(() => {
        params.set("variable", actionsState.selectedVariable)
        params.set("granularity", actionsState.selectedGranularity)

        history.replace({
            search: params.toString(),
        })
    }, [actionsState])

    return (
        <div className="pb-4 -mt-2">
            <div
                className="bg-white max-w-8xl mx-auto border-[1px] border-gray-10 rounded-lg relative"
                id="seasonal-section"
            >
                <SeasonalVariableTabs
                    selectedTab={actionsState.selectedVariable}
                    selectedGran={actionsState.selectedGranularity}
                    allowedSeasonalVariables={featurePermissions["weather_meta"]}
                    onStateChange={handleChangeAction}
                />
                {renderComponent()}
            </div>
        </div>
    )
}

export default Seasonal

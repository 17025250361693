import React, { useEffect, useState, useRef } from "react"
import debounce from "lodash/debounce"

function useContainerSize(delay = 300, dependencyList = []) {
    const containerRef = useRef(null)
    const [size, setSize] = useState({ width: 0, height: 0 })

    function handleSizeChange() {
        if (containerRef.current) {
            setSize({
                width: containerRef.current.clientWidth,
                height: containerRef.current.clientHeight,
            })
        }
    }

    useEffect(() => {
        setTimeout(handleSizeChange, delay)
    }, [...dependencyList])
    useEffect(() => {
        const handleWindowResize = debounce(handleSizeChange, delay)

        window.addEventListener("resize", handleWindowResize)
        window.addEventListener("force-resize", handleWindowResize)

        return () => {
            window.removeEventListener("resize", handleWindowResize)
            window.removeEventListener("force-resize", handleWindowResize)
        }
    }, [containerRef])

    return [containerRef, size]
}

export default useContainerSize

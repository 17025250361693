import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import { CustomReports, RegulatoryReports } from "../views"
import { TopBar } from "../components"
import { useMediaQuery } from "@material-ui/core"
import NotAllowedDevice from "../views/NotAllowedDevice"

function CustomRouter({ reportTab = "custom" }) {
    const PROFILE_OPTIONS = []
    const NAVBAR_OPTIONS = []

    const history = useHistory()
    const tabletMedia = useMediaQuery("(max-width: 768px)")

    const [currentPageURL, setCurrentPageURL] = useState(window.location.href)

    useEffect(() => {
        history.listen((location) => {
            setCurrentPageURL(location.pathname)
        })
    }, [history])

    if (tabletMedia) return <NotAllowedDevice />

    return (
        <div className="flex flex-col h-full">
            <TopBar profileOptions={PROFILE_OPTIONS} navOptions={NAVBAR_OPTIONS} url={currentPageURL} />
            <div className="overflow-auto grow">
                {reportTab === "custom" ? <CustomReports /> : <RegulatoryReports />}
            </div>
        </div>
    )
}

export default CustomRouter

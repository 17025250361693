import React, { useState, useEffect } from "react"

function SkinProvider(props) {
    const { children } = props
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        // Load skin
        const skinName = process.env.REACT_APP_SKIN || "template"
        import(`../skins/${skinName}`)
            .then(() => {
                console.log("success")
            })
            .catch((err) => {
                console.log("failure")
                console.log(err)
            })
            .finally(() => setLoading(false))
    }, [])

    if (loading) return "Loading..."
    else return <>{children}</>
}

export default SkinProvider

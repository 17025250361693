import * as React from "react"

const FunnelIcon = (props) => (
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M3.542 4.676c1.683 2.158 4.791 6.158 4.791 6.158v5c0 .458.375.833.834.833h1.666a.836.836 0 0 0 .834-.833v-5s3.1-4 4.783-6.158a.831.831 0 0 0-.658-1.342H4.2a.831.831 0 0 0-.658 1.342Z"
            fill={props.fill}
        />
    </svg>
)

export default FunnelIcon

import "react-datepicker/dist/react-datepicker.css"

import ScenarioDropdownSelection from "../../../ui/Controls/ScenarioDropdownSelection"
import LocationDropdownSelection from "../../../ui/Controls/LocationDropdownSelection"
import VariableDropdownSelection from "../../../ui/Controls/VariableDropdownSelection"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"
import Section from "../../../ui/Section"
import useContainerSize from "../../../hooks/useContainerSize"
import LoadingBar from "../../../components/LoadingBar"
import ChartLabels from "../../../components/LongTerm/ChartLabels/ChartLabels"
import ChartLabelItem from "../../../components/LongTerm/ChartLabels/ChartLabelItem"
import { useContext } from "react"
import { SettingsContext } from "../../../Util/SettingsContext"
import useCSVData from "../../../hooks/useCSVData"
import { formatDepartureCSVData, GET_DEPARTURE_CSV_HEADERS } from "../../../Util/CSVParser"
import ExportButton from "../../../components/ExportButton"

export function LineIcon({ fill }) {
    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{ width: "100%", height: 2, backgroundColor: fill || "#000" }} />
        </div>
    )
}

function DepartureChart(props) {
    const {
        loading,
        data,
        threshold,
        futureq,
        pastq,
        //
        locations,
        selectedLocation,
        setSelectedLocation,
        //
        scenarios,
        selectedScenario,
        setSelectedScenario,
        //
        variables,
        selectedVariable,
        setSelectedVariable,
    } = props

    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const [containerRef, { height }] = useContainerSize(1000, [selectedLocation, selectedScenario, selectedVariable])

    let labelY = null
    if (variables && selectedVariable) {
        const selectedVariableObj = variables[selectedVariable]

        labelY =
            selectedVariableObj.label +
            " " +
            ((selectedVariableObj.units && `(${selectedVariableObj.getUnits(units)})`) || "")
    }

    const { csvHeaders, csvData, csvString } = useCSVData(
        formatDepartureCSVData(data),
        GET_DEPARTURE_CSV_HEADERS(variables && selectedVariable ? variables[selectedVariable].label : ""),
        `Climate Tipping Point
        Location:,${!loading && locations[selectedLocation].location}
        Scenario:,${!loading && scenarios[selectedScenario].label}
        Variable:,${!loading && variables[selectedVariable].label}
        Question:,When will a ${(pastq * 10).toFixed(0)} in 10 years event become a ${(futureq * 10).toFixed(
            0
        )} in 10 years event?
        Answer:,${threshold || "No Year of Departure"}
        \n`,
        !loading
    )

    return (
        <>
            <Section
                title="Climate Tipping Point"
                description="This page displays the approximated year when variances in weather due to climate change indicates a major change in a location's climate - i.e. when the average temperature sits outside the range of the warmest and coolest year."
                header={() => (
                    <>
                        <LocationDropdownSelection
                            selectedLocation={selectedLocation}
                            locations={locations}
                            setSelectedLocation={setSelectedLocation}
                            keyPrefix={"decadal_location"}
                        />
                        <VariableDropdownSelection
                            selectedVariable={selectedVariable}
                            variables={variables}
                            setSelectedVariable={setSelectedVariable}
                            filter={(v) => v.value !== "tp"}
                            keyPrefix={"decadal_variable"}
                        />
                        <ScenarioDropdownSelection
                            selectedScenario={selectedScenario}
                            scenarios={scenarios}
                            setSelectedScenario={setSelectedScenario}
                            keyPrefix={"decadal_scenario"}
                        />
                        <ExportButton
                            cols={{ csv: csvHeaders }}
                            data={{ csv: csvData }}
                            csvString={csvString}
                            wrapperClasses="absolute top-3 right-2"
                            fileName={"Tipping_point-" + locations[selectedLocation]?.location}
                            idToExport="departure-section"
                        />
                    </>
                )}
            >
                {loading && <LoadingBar loading />}
                <ChartLabels>
                    <ChartLabelItem renderIcon={() => <LineIcon fill="#1C9690" />}>
                        <label>Projection</label>
                    </ChartLabelItem>
                    <ChartLabelItem renderIcon={() => <LineIcon fill="#F37920" />}>
                        <label>Year of departure</label>
                    </ChartLabelItem>
                </ChartLabels>
                <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
                    <LineAreaChartComponent
                        labelY={labelY}
                        yBottomOffset={0.2}
                        yTopOffset={0.2}
                        zeroBasis={false}
                        xRightOffset={0.1}
                        svgHeight={(height && height - 25) || 0}
                        data={data}
                        customStaticTipXPosition={new Date(`Jan 1, ${threshold}`)}
                        customStaticTipXText={threshold}
                        customStaticTipXColor={"#F37920"}
                        tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                            return new Date(key).getFullYear()
                        }}
                    ></LineAreaChartComponent>
                </div>
            </Section>
        </>
    )
}

export default DepartureChart

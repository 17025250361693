import Tooltip from "../../../../../ui/Tooltip"

const VariableButton = ({ icon: Icon, isSelected, title, onClick, hasAlert, hasActiveAlert }) => {
    if (!title) return null
    return (
        <Tooltip content={title}>
            <button
                className={[
                    "flex items-center justify-center relative",
                    "w-[34px] h-[54px] shrink-0",
                    "border-b-[2px]",
                    "roboto font-normal text-[16px]",
                    isSelected ? "border-accent" : "border-transparent",
                    isSelected ? "fill-accent" : "fill-gray-60 hover:fill-accent",
                ].join(" ")}
                onClick={onClick}
            >
                {hasAlert && (
                    <div
                        className={
                            "absolute top-[11px] right-[-1px] w-[6px] h-[6px] rounded-full " +
                            (hasActiveAlert ? "bg-red" : "bg-gray-30/50")
                        }
                    ></div>
                )}
                <div className="h-[24px] w-[24px] group-hover:scale-[108%]">{Icon && <Icon />}</div>
            </button>
        </Tooltip>
    )
}

export default VariableButton

import { useEffect, useState } from "react"
import CancelIcon from "../../ui/Icons/newIcons/CancelIcon"

const Toast = ({ content = "", duration = 4, onCloseAlert = () => {}, remaining = 0, showTimer = false }) => {
    const [countdown, setCountdown] = useState(duration + 2)

    const [timeout, setTO] = useState(null)

    useEffect(() => {
        if (countdown > 0) {
            setTO(setTimeout(() => {
                setCountdown(countdown - 0.5)
            }, 500))
        } else {
            clearTimeout(timeout)
            onCloseAlert()
        }

        return () => clearTimeout(timeout)
    }, [countdown])

    const pauseTimeout = () => {
        clearTimeout(timeout)
    }

    const resumeTimeout = () => {
        setCountdown(countdown - 0.0001)
    }

    const handleCloseAlert = () => {
        setCountdown(0.5)
    }

    return (
        <div
            className={
                "fixed z-full left-1/2 -translate-x-1/2 top-4 min-w-[280px] max-w-fit w-max transition-all duration-400 ease-out opacity-0 roboto" +
                (countdown > 0.5 && countdown <= duration + 1.5 ? " opacity-100" : " -top-[48px]")
            }
        >
            <div
                className="relative overflow-hidden min-h-[48px] bg-gray-90 text-white text-[16px] flex flex-row items-center justify-between py-3 pl-[12px] pr-[8px] rounded-lg space-x-[24px] cursor-default"
                onMouseEnter={pauseTimeout}
                onMouseLeave={resumeTimeout}
            >
                {showTimer && (
                    <div
                        className="h-[2px] bg-white absolute bottom-0 left-0 transition-all duration-500 ease-linear"
                        style={{ width: ((duration - countdown + 2) / (duration + 1)) * 100 + "%" }}
                    ></div>
                )}
                <div className="grow whitespace-normal md:whitespace-nowrap">{content}</div>
                <span
                    className="shrink-0 w-[24px] fill-gray-60 flex items-center hover:fill-gray-30 cursor-pointer elevation-2"
                    onClick={handleCloseAlert}
                >
                    <CancelIcon />
                </span>
            </div>
            {remaining > 1 && (
                <div className="absolute top-0 right-0 h-5 w-5 rounded-full bg-gray-10 border-[1px] border-gray-30 translate-x-1/3 -translate-y-1/3 grid place-content-center text-gray-60 text-xs elevation-2 font-bold">
                    {remaining - 1}
                </div>
            )}
        </div>
    )
}

export default Toast

import React, { useEffect, useState } from "react"

import CropTableLabelItem from "./CropTableLabelItem"

import "./CropTableLabels.css"
import FixedLayer from "../../../ui/FixedLayer/FixedLayer"
import useOutsideComponentClickHandler from "../../../hooks/ClickOutsideHook"
import PlusIconAlt from "../../../ui/Icons/PlusIconAlt"
import CropTableLabelsForm from "./CropTableLabelsForm"

function CropTableLabels(props) {
    const {
        value = [],
        rowData = {},
        rowOptions: { removeLabelFromField, labels, onDeleteLabel, onLabelFields },
    } = props

    const [openNewLabel, toggleNewLabel] = useState(false)
    const [openLastItems, toggleLastItems] = useState(false)

    const ref = useOutsideComponentClickHandler(() => {
        openNewLabel && toggleNewLabel(false)
        openLastItems && toggleLastItems(false)
    })

    const items = []
    const lastItems = []

    for (let i = 0; i < value.length; i++) (i < 1 ? items : lastItems).push(value[i])

    function handleLabelDelete(label) {
        removeLabelFromField(label, rowData)
    }

    return (
        <>
            <div className="crop-table-labels w-full h-full flex items-center justify-center something" ref={ref}>
                {items.map((label) => {
                    return (
                        <div className="mr-1 last-of-type:mr-0" key={`label_${rowData.uuid}_${label.name}`}>
                            <CropTableLabelItem label={label} onDelete={handleLabelDelete} />
                        </div>
                    )
                })}
                {lastItems.length > 0 && (
                    <div className="relative">
                        <button
                            className="btn p-1 border border-gray-60 bg-gray-10 rounded-sm"
                            onClick={() => {
                                toggleLastItems(!openLastItems)
                                openNewLabel && toggleNewLabel(false)
                            }}
                        >
                            +{lastItems.length}
                        </button>
                        {openLastItems && (
                            <FixedLayer yPosition="bottom" xPosition="right">
                                <div className="bg-white elevation border border-gray-10 shadow-md rounded-sm p-2">
                                    {lastItems.map((label) => {
                                        return (
                                            <>
                                                <div className="mb-1" key={`label_${rowData.uuid}_${label.name}`}>
                                                    <CropTableLabelItem
                                                        label={label}
                                                        onDelete={handleLabelDelete}
                                                        compact={false}
                                                    />
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </FixedLayer>
                        )}
                    </div>
                )}

                <div className="relative">
                    <button
                        className={
                            "crop-table-lables__add-button relative hidden btn h-[20px] overflow-visible" +
                            ((items.length > 0 && " w-[0px]") || " w-[20px]")
                        }
                        onClick={() => {
                            toggleNewLabel(!openNewLabel)
                            openLastItems && toggleLastItems(false)
                        }}
                    >
                        <div className="absolute top-0 w-[20px] h-[20px] ml-[2px] fill-gray-30">
                            <PlusIconAlt />
                        </div>
                    </button>

                    {openNewLabel && (
                        <FixedLayer xPosition="right" yPosition="bottom">
                            <CropTableLabelsForm
                                labels={labels}
                                onDeleteLabel={onDeleteLabel}
                                onLabelFields={onLabelFields}
                                rowData={rowData}
                            />
                        </FixedLayer>
                    )}
                </div>
            </div>
        </>
    )
}

export default CropTableLabels

import * as React from "react"

function ChevronDownIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.59.59L6 5.17 1.41.59 0 2l6 6 6-6L10.59.59z" fill={props.fill || "#666D74"} />
        </svg>
    )
}

export default ChevronDownIcon

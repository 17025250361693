import React, { useRef, useState } from "react"
import ChevronDownIcon from "../../../ui/Icons/ChevronDownIcon"

import "./CardItem.css"

function CardItem(props) {
    const { icon, render, children, className = "", available, scrollable } = props

    const [open, toggle] = useState(false)
    const cardRef = useRef(null)

    function handleToggle() {
        if (!open && scrollable) {
            const { top: cardTop } = cardRef.current.getBoundingClientRect()
            const { top: parentTop } = cardRef.current.parentNode.getBoundingClientRect()

            setTimeout(() => {
                cardRef.current.parentNode.scrollTo({
                    top: cardTop + cardRef.current.parentNode.scrollTop - parentTop,
                })
            }, 100)
        }

        toggle(!open)
    }

    return (
        <>
            <div
                className={"card-item " + ((available !== undefined && !available && !open && "disabled") || "")}
                ref={cardRef}
            >
                <div className="card-item__main">
                    <div className="card-item__actionable">{icon}</div>
                    <div className="card-item__content">{render && render()}</div>
                    {children && (
                        <button className={"card-item__toggle" + (open ? " open" : "")} onClick={handleToggle}>
                            <ChevronDownIcon />
                        </button>
                    )}
                </div>
                {open && children && <div className="card-item__children">{children}</div>}
            </div>
        </>
    )
}

export default CardItem

function StackedAlertsIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M9.5341 3.99827L17.0641 17.0083H2.0041L9.5341 3.99827ZM11.2649 2.99793C10.4946 1.66736 8.57358 1.66736 7.80325 2.99793L0.272139 16.0062C-0.49979 17.3395 0.462328 19.0083 2.00299 19.0083H17.0652C18.6059 19.0083 19.568 17.3395 18.7961 16.0062L11.2649 2.99793ZM10.5341 14.0083H8.5341V16.0083H10.5341V14.0083ZM10.5341 8.00827H8.5341V12.0083H10.5341V8.00827Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.687 17.504L15.3036 6.51041L17.0332 5.50614L23.4166 16.4997C24.965 19.1664 23.041 22.5083 19.9574 22.5083H6.66841V20.5083H19.9574C21.4992 20.5083 22.4612 18.8373 21.687 17.504Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default StackedAlertsIcon

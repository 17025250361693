import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import app from "../Util/Fire.js"
import networking from "../Util/Networking"

import LoadingScreen from "../components/LoadingScreen"
import http from "../Util/http.js"

export const AuthContext = React.createContext(null)

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [pending, setPending] = useState(true)
    const [permissions, setPermissions] = useState(null)
    const [featurePermissions, setFeaturePermissions] = useState(null)
    const [forecastTypesEnabled, setForecastTypesEnabled] = useState({
        short_term: true,
        subseasonal: true,
        seasonal: true,
    })
    const [userInfo, setUserInfo] = useState({
        company: "",
        name: "",
        source_account: "",
    })

    const history = useHistory()

    const initialNavigation = (viewPerms) => {
        if (history.location.pathname !== "/") {
            const requiredPerm = history.location.pathname.split("/")[1]
            if (viewPerms[requiredPerm]) return
        }

        if (viewPerms["default"] && viewPerms[viewPerms["default"]]) {
            history.push("/" + viewPerms["default"])
        } else if (viewPerms["weather"]) {
            history.push("/weather")
        } else if (viewPerms["climate"]) {
            history.push("/climate")
        } else if (viewPerms["exposure"]) {
            history.push("/exposure")
        } else if (viewPerms["custom"]) {
            history.push("/custom")
        } else {
            app.auth().signOut()
            history.push("/")
        }
    }

    function getUserInfo() {
        http.get("/api/v1/user/information").then((data) => setUserInfo(data))
    }

    // detect if this is first login in browser
    function setUserPermissions(user) {
        if (user) {
            app.auth()
                .currentUser.getIdToken()
                .then((token) => {
                    networking
                        .get("/api/v1/settings/permissions", { extraHeaders: { "User-Token": token } })
                        .then((res) => res.data)
                        .then((result) => {
                            setPermissions(result["views"])
                            setCurrentUser(user)
                            setFeaturePermissions(result["features"])
                            setPending(false)
                            initialNavigation(result["views"])
                        })
                        .catch((error) => {
                            console.log("There was a problem obtaining permissions")
                        })
                    return token
                })
                .then((token) => {
                    networking
                        .get("/api/v1/settings/forecasts_enabled", { extraHeaders: { "User-Token": token } })
                        .then((res) => res.data)
                        .then((result) => {
                            setForecastTypesEnabled(result)
                        })
                        .catch((error) => {
                            console.log("There was a problem obtaining forecasts enabled.")
                        })
                })
        }
    }

    useEffect(() => {
        app.auth().onAuthStateChanged((user) => {
            if (user !== null) {
                setPending(true)
                // We might want to add more variables or more information.
                let pendo_object = {
                    visitor_id: user.email,
                    account_id: user.email,
                }
                window["wrapper_pendo"](pendo_object)
                setUserPermissions(user)
                getUserInfo()
            } else {
                setCurrentUser(null)
                setPending(false)
            }
        })
    }, [])

    if (pending) {
        return <LoadingScreen />
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                userInfo,
                permissions,
                forecastTypesEnabled,
                featurePermissions,
                setUserPermissions,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

import * as React from "react"

function WeatherArrowIcon(props) {
    return (
        <svg
            width={"100%"}
            height={"100%"}
            viewBox="0 0 13.91 13.91"
            fill="none"
            className="fill-inherit"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.914 14.597V4.014l4.861 4.861 1.226-1.235L7.045.683.088 7.64l1.226 1.226 4.86-4.852v10.583h1.74Z"
                fill={props.fill}
                transform={props.transform}
            />
        </svg>
    )
}

export default WeatherArrowIcon

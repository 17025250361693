import "react-datepicker/dist/react-datepicker.css"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"
import Section from "../../../ui/Section"
import useContainerSize from "../../../hooks/useContainerSize"
import LoadingBar from "../../../components/LoadingBar"
import ScenarioDropdownSelection from "../../../ui/Controls/ScenarioDropdownSelection"
import LocationDropdownSelection from "../../../ui/Controls/LocationDropdownSelection"
import CropDropdownSelection from "../../../ui/Controls/CropDropdownSelection"
import RiskDropdownSelection from "../../../ui/Controls/RiskDropdownSelection"
import { getMaxYValue, getMinYValue } from "../../../Util/candleGraphUtils"
import ExportButton from "../../../components/ExportButton"
import useCSVData from "../../../hooks/useCSVData"
import { formatSeasonalCSVData, SEASONAL_CSV_HEADERS } from "../../../Util/CSVParser"
import { getYLabel } from "../../../Util/CADChartLabel"

const chartArgs = {
    labelY: "Days with Risk",
    xTicksCount: 12,
    yTicksCount: 5,
    xDateMin: new Date(2022, 0, 1),
    xDateMax: new Date(2023, 0, 1),
    centerTicks: true,
    candlePadding: 0,
    labelYOffsetX: -20,
    candleTopBottomLines: true,
    maxCandlestickWidth: 100,
    xLeftOffset: 0.2,
    xRightOffset: 0.2,
    marginLeft: 100,
}

function SeasonalRiskChart(props) {
    const {
        units,
        maxValue,
        minValue,
        loading,
        data,
        children,
        // locations dropdown
        locations,
        selectedLocation,
        setSelectedLocation = () => null,
        // crop dropdown
        crops,
        selectedCrop,
        selectedVariety,
        setSelectedCropVariety = () => null,
        // scenario dropdown
        scenarios,
        selectedScenario,
        setSelectedScenario = () => null,
        // risk dropdown
        risks,
        selectedRisk,
        setSelectedRisk = () => null,
        riskVariable,
    } = props

    const [containerRef, { height }] = useContainerSize(1000, [
        selectedLocation,
        selectedCrop,
        selectedScenario,
        selectedRisk,
    ])

    // TODO check with JP why labels don't show in the new backend
    // let labelY = "Number of Days"
    const _risk = risks[selectedRisk]
    let labelY =
        (_risk &&
            getYLabel(
                _risk.case,
                _risk.function,
                _risk.consecutive,
                _risk.variable,
                riskVariable && riskVariable.getUnits(units)
            )) ||
        ""

    // if case !== 1, there is no units (days or events)
    let tickFormat = (_risk && _risk.case === 1 && riskVariable.getUnits(units)) || ""

    const yTickFormat = (d) => (riskVariable && riskVariable.value === "t2m" && d) || (d < 0 ? "" : d)

    const { csvHeaders, csvData, csvString } = useCSVData(
        formatSeasonalCSVData(data),
        SEASONAL_CSV_HEADERS,
        `Seasonal Risk Data
        Category:,${!loading && selectedCrop + "/" + selectedVariety}
        Location:,${!loading && locations[selectedLocation]?.location}
        Scenario:,${!loading && scenarios[selectedScenario]?.label}
        Risk:,${!loading && risks[selectedRisk]?.title + " (" + risks[selectedRisk]?.stage + ")"}
        Description:,${!loading && risks[selectedRisk]?.description.replaceAll(",", "/")}
        \n`,
        !loading
    )

    return (
        <Section
            id="seasonal-risk-section"
            title="Monthly Risk"
            description="This page displays the monthly forecast of the selected climate risk (defined for a crop variety) at a given location."
            header={() => {
                return (
                    <>
                        <CropDropdownSelection
                            selectedCrop={selectedCrop}
                            selectedVariety={selectedVariety}
                            crops={crops}
                            selectCropVariety={setSelectedCropVariety}
                            keyPrefix={"decadal_crops"}
                        />
                        <LocationDropdownSelection
                            selectedLocation={selectedLocation}
                            locations={locations}
                            setSelectedLocation={setSelectedLocation}
                            keyPrefix={"decadal_location"}
                        />
                        <RiskDropdownSelection
                            selectedRisk={selectedRisk}
                            risks={risks}
                            setSelectedRisk={setSelectedRisk}
                            keyPrefix={"decadal_risks"}
                        />

                        <ScenarioDropdownSelection
                            selectedScenario={selectedScenario}
                            scenarios={scenarios}
                            setSelectedScenario={setSelectedScenario}
                            keyPrefix={"decadal_scenario"}
                        />

                        <ExportButton
                            cols={{ csv: csvHeaders }}
                            data={{ csv: csvData }}
                            csvString={csvString}
                            wrapperClasses="absolute top-3 right-2"
                            fileName={"Monthly_risk-" + locations[selectedLocation]?.location + "-" + selectedCrop}
                            idToExport="seasonal-risk-section"
                        />
                    </>
                )
            }}
        >
            {loading && <LoadingBar loading />}
            {children}
            <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
                <LineAreaChartComponent
                    labelY={labelY}
                    xTicksCount={chartArgs.xTicksCount}
                    yTicksCount={chartArgs.yTicksCount}
                    yValueMax={getMaxYValue(maxValue, minValue, chartArgs.yTicksCount)}
                    // yValueMin becomes zero instead of using zeroBasis
                    yValueMin={minValue < 1 && minValue > -1 ? 0 : minValue}
                    marginLeft={chartArgs.marginLeft}
                    xDateMax={chartArgs.xDateMax}
                    xDateMin={chartArgs.xDateMin}
                    xLeftOffset={chartArgs.xLeftOffset}
                    xRightOffset={chartArgs.xRightOffset}
                    centerTicks={chartArgs.centerTicks}
                    labelYOffsetX={chartArgs.labelYOffsetX}
                    candlePadding={chartArgs.candlePadding}
                    candleTopBottomLines={chartArgs.candleTopBottomLines}
                    yTopOffset={0.2}
                    svgHeight={(height && height - 25) || 0}
                    svgWidth="100%"
                    xTickFormat={(d) => d.toLocaleDateString(undefined, { month: "long" })}
                    yTickFormat={yTickFormat}
                    shapeTip={(e, d, arr) => {
                        return `
                            <table>
                            <tr><th style="text-align:right">95th</th><td>${
                                d.y1 != undefined && d.y1.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">75th</th><td>${
                                d.yMax != undefined && d.yMax.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">50th</th><td>${
                                d.yMid != undefined && d.yMid.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">25th</th><td>${
                                d.yMin != undefined && d.yMin.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">5th</th><td>${
                                d.y0 != undefined && d.y0.toFixed(2) + " " + tickFormat
                            }</td></tr>
                          </table>
                    `
                    }}
                    data={!loading && data}
                ></LineAreaChartComponent>
            </div>
        </Section>
    )
}

export default SeasonalRiskChart

import { useContext, useEffect, useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import CropTab from "./components/CropTab"
import CropAlert from "./components/CropAlert"
import { AuthContext } from "../../../Auth/Auth"
import { getUserAlerts, getCropVarietyConfigs, updateCropVarietyConfigs } from "../../../Util/AlertsMiddleware"
import { parseCSVFile, toCSVFile } from "../../../Util/CSVParser"
import "./AlertSettings.css"
import "./components/Alert.css"
import { ToastContext } from "../../../components/Toast/ToastContextProvider"
import { ModalContext } from "../../../components/Modal/ModalContextProvider"

const AlertSettings = (props) => {
    const { stacked = false } = props
    const [crops, setCrops] = useState({
        crops: {
            name: "Loading",
        },
    })

    const { currentUser, featurePermissions } = useContext(AuthContext)
    const { confirmationModal } = useContext(ModalContext)
    const { enqueueAlert, enqueueError } = useContext(ToastContext)

    async function handleVarietyFileDownload(crop, variety) {
        const userToken = await currentUser.getIdToken()
        const { data } = await getCropVarietyConfigs(userToken, crop, variety)
        // download json, directly from stack overflow lol

        let result = ""
        try {
            result = toCSVFile(crop, variety, data)
        } catch (err) {
            enqueueError("There was an error while downloading the alerts. Please try again.")
            return
        }

        const dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(result)
        const downloadAnchorNode = document.createElement("a")
        downloadAnchorNode.setAttribute("href", dataStr)
        downloadAnchorNode.setAttribute("download", crop + "-" + variety + ".csv")
        document.body.appendChild(downloadAnchorNode) // required for firefox
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
    }

    async function handleVarietyFileUpload(crop, variety, file) {
        // let uploadedFile = e.target.files[0]
        confirmationModal({
            title: "Are you sure?",
            text: "This action will replace all your alerts.",
            onContinue: async () => {
                let result = null
                try {
                    result = await parseCSVFile(5, file)
                } catch (err) {
                    enqueueError(err)
                    return
                }
                const userToken = await currentUser.getIdToken()
                await updateCropVarietyConfigs(userToken, crop, variety, result)
                setTimeout(async () => {
                    await fetchAlerts()
                    enqueueAlert("Alert configurations successfully updated!")
                }, 1000)
            },
        })
    }

    async function fetchAlerts() {
        try {
            let userToken = await currentUser.getIdToken()
            getUserAlerts(userToken).then((res) => {
                let { notify_via_table, notify_via_email, ...crops } = res.data
                // setNotifyVia({
                //     table: notify_via_table || false,
                //     email: notify_via_email || false,
                // })
                setCrops(crops)
            })
        } catch (err) {
            console.log("Error getting user alerts ", err)
        }
    }

    useEffect(() => {
        fetchAlerts()
    }, [])

    function handleAlertStatusChange(status, mPath) {
        setCrops({
            ...crops,
            [mPath.crop]: {
                ...crops[mPath.crop],
                [mPath.variety]: {
                    ...crops[mPath.crop][mPath.variety],
                    [mPath.risk]: {
                        ...crops[mPath.crop][mPath.variety][mPath.risk],
                        [mPath.alert_id]: {
                            ...crops[mPath.crop][mPath.variety][mPath.risk][mPath.alert_id],
                            active: status,
                        },
                    },
                },
            },
        })
    }

    function handleNewAlert() {
        fetchAlerts()
    }

    return (
        <div className="risk-profiles pb-6">
            <div className="risk-profiles__container">
                <div className="risk-profiles__menu">
                    <div className="risk-profiles__alerts-menu-container">
                        <Tabs>
                            <TabList className="risk-profiles__alerts__category-menu-container">
                                {Object.keys(crops).map((crop) => {
                                    let name = crops[crop].name || crop
                                    return (
                                        <Tab key={"tab-" + crop}>
                                            <CropTab name={name} />
                                        </Tab>
                                    )
                                })}
                            </TabList>
                            <div className="risk-profiles__alerts-container">
                                {Object.keys(crops).map((crop) => {
                                    let cropObj = crops[crop]
                                    return (
                                        <TabPanel key={"tabpanel-" + crop}>
                                            <CropAlert
                                                cropObj={{ ...cropObj }}
                                                path={{ crop: crop }}
                                                onVarietyFileDownload={handleVarietyFileDownload}
                                                onVarietyFileUpload={handleVarietyFileUpload}
                                                isDownloadEnabled={featurePermissions?.alerts?.view}
                                                isUploadEnabled={featurePermissions?.alerts?.add}
                                                stackedAlertsView={stacked}
                                                onAlertStatusChange={handleAlertStatusChange}
                                                reloadAlertSettings={handleNewAlert}
                                            />
                                        </TabPanel>
                                    )
                                })}
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertSettings

import { useState, useEffect, useMemo, useCallback, useRef } from "react"

const getPaddingRightFromPixels = (px, maxX) => {
    return maxX - px
}
const getPaddingLeftFromPixels = (px, minX) => {
    return px - minX
}
const getPaddingRightFromIndex = (index, step, count) => {
    return step * (count - index)
}
const getPaddingLeftFromIndex = (index, step) => {
    return step * index
}

const SliderSlider = ({ side = "left", dragStart, dragging, dragEnd, isDragging, extraClasses }) => {
    const selfRef = useRef()

    const [auxElement, setAuxElement] = useState(null)

    const dragOrTouchStart = (e) => {
        dragStart(side)
        const fakeLink = selfRef.current.cloneNode(true)
        fakeLink.style.opacity = 0
        setAuxElement(fakeLink)
        document.body.appendChild(fakeLink)
        e?.dataTransfer?.setDragImage(fakeLink, 0, 0)
    }
    const dragOrTouchEnd = (e) => {
        document.body.removeChild(auxElement)
        dragEnd(e)
    }

    return (
        <div
            ref={selfRef}
            className="w-[0px] flex flex-row justify-center"
            draggable={true}
            onDragStart={dragOrTouchStart}
            onDrag={dragging}
            onDragEnd={dragOrTouchEnd}
            onTouchStart={dragOrTouchStart}
            onTouchMove={dragging}
            onTouchEnd={dragOrTouchEnd}
        >
            <div
                className={[
                    "shrink-0 h-[20px] w-[20px] sm:h-[14px] sm:w-[14px] rounded-full z-10",
                    "transition-all duration-100",
                    "hover:z-20 hover:elevation-1",
                    side === "left" ? "bg-accent" : "bg-white border-[4px] sm:border-[3px] border-accent",
                    isDragging ? "cursor-grabbing" : "cursor-pointer",
                    extraClasses,
                ].join(" ")}
            ></div>
        </div>
    )
}

const DUMMY = { dummy: "dummy" }

const Slider = ({
    id = "",
    options = [],
    selectedBottomIndex = 0,
    selectedTopIndex = 0,
    isDouble = false,
    setSelectedIndexes = () => {},
}) => {
    const [paddingLeft, setPaddingLeft] = useState("0px")
    const [paddingRight, setPaddingRight] = useState("0px")
    const [isDragging, setIsDragging] = useState(false)
    const [endX, setEndX] = useState()
    const [draggedElement, setDraggedElement] = useState("right")
    const [resized, setResized] = useState({ ...DUMMY })

    useEffect(() => {
        const handleResize = () => {
            setResized({ ...DUMMY })
        }
        window.addEventListener("force-resize", handleResize)
        window.addEventListener("resize", handleResize)
        // Wait for the sliders to be created in the DOM
        const auxTimeout = setTimeout(() => handleResize(), 100)
        return () => {
            clearTimeout(auxTimeout)
            window.removeEventListener("force-resize", handleResize)
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const { minX, maxX, step, count } = useMemo(() => {
        const count = options.length - 1
        const minX = document.getElementById(id + "-slider-step-0")?.getBoundingClientRect()?.x
        const maxX = document.getElementById(id + "-slider-step-" + count)?.getBoundingClientRect()?.x
        return {
            count,
            minX,
            maxX,
            step: (maxX - minX) / count,
        }
    }, [options, id, resized])

    useEffect(() => {
        setPaddingLeft(step * selectedBottomIndex + "px")
        setPaddingRight(step * (count - selectedTopIndex) + "px")
    }, [minX, maxX, step, count, selectedTopIndex, selectedBottomIndex])

    const bottomX = useMemo(() => minX + step * selectedBottomIndex, [minX, step, selectedBottomIndex])
    const topX = useMemo(
        () => (isDouble ? minX + step * selectedTopIndex : maxX),
        [minX, step, selectedTopIndex, maxX, isDouble]
    )

    const dragStart = (de) => {
        setDraggedElement(de)
        setIsDragging(true)
    }
    const dragging = useCallback(
        (e) => {
            const auxEndX = e.pageX
            if (auxEndX === 0) return

            if (draggedElement === "right") {
                let r = auxEndX
                if (r > maxX) r = maxX
                else if (r < bottomX + step) r = bottomX + step
                setPaddingRight(getPaddingRightFromPixels(r, maxX) + "px")
            } else if (draggedElement === "left") {
                let l = auxEndX
                if (l < minX) l = minX
                else if (l > (isDouble ? topX - step : topX)) l = isDouble ? topX - step : topX
                setPaddingLeft(getPaddingLeftFromPixels(l, minX) + "px")
            }
        },
        [draggedElement, maxX, minX, bottomX, topX, step, isDouble]
    )
    const dragEnd = (e) => {
        setIsDragging(false)
        setEndX(e.pageX)
    }

    const tickClick = useCallback(
        (index) => {
            const leftDiff = Math.abs(selectedBottomIndex - index)
            const rightDiff = Math.abs(selectedTopIndex - index)

            if (isDouble && rightDiff < leftDiff) {
                setSelectedIndexes([selectedBottomIndex, index])
            } else {
                setSelectedIndexes(isDouble ? [index, selectedTopIndex] : index)
            }
        },
        [maxX, minX, bottomX, topX, step, isDouble]
    )

    const barClick = useCallback(
        (e) => {
            const auxEndX = e.pageX
            if (auxEndX === 0) return

            const leftDiff = Math.abs(bottomX - auxEndX)
            const rightDiff = Math.abs(topX - auxEndX)

            if (isDouble && rightDiff < leftDiff) {
                let r = auxEndX
                if (r > maxX) r = maxX
                else if (r < bottomX + step) r = bottomX + step
                setDraggedElement("right")
                setEndX(r)
            } else {
                let l = auxEndX
                if (l < minX) l = minX
                else if (l > (isDouble ? topX - step : topX)) l = isDouble ? topX - step : topX
                setDraggedElement("left")
                setEndX(l)
            }
        },
        [maxX, minX, bottomX, topX, step, isDouble]
    )

    const snapSliderIndex = useCallback(() => {
        for (let i = 0; i <= count; i++) {
            const leftTick = minX + i * step
            const rightTick = minX + (i + 1) * step

            if (endX >= leftTick && endX < rightTick) {
                const leftDiff = Math.abs(endX - leftTick)
                const rightDiff = Math.abs(endX - rightTick)
                return leftDiff > rightDiff ? i + 1 : i
            }
        }
        return
    }, [endX, count, minX, step])

    useEffect(() => {
        if (!endX) return
        const initValue = snapSliderIndex()
        if (draggedElement === "right") {
            let r = endX
            if (r > maxX) r = count
            else if (r < bottomX + step) r = selectedBottomIndex + 1
            else r = initValue
            setPaddingRight(getPaddingRightFromIndex(r, step, count) + "px")
            setSelectedIndexes([selectedBottomIndex, r])
        } else if (draggedElement === "left") {
            let l = endX
            if (l < minX) l = 0
            else if (l > (isDouble ? topX - step : topX)) l = isDouble ? selectedTopIndex - 1 : count
            else l = initValue
            setPaddingLeft(getPaddingLeftFromIndex(l, step) + "px")
            setSelectedIndexes(isDouble ? [l, selectedTopIndex] : l)
        }
    }, [endX])

    return (
        <div className="flex flex-row items-center w-full pt-3 pr-3 cursor-pointer pb-9 group" onClick={barClick}>
            <div className="w-[12px] h-[5px] bg-gray-10 group-hover:bg-gray-30/50 rounded-l-sm"></div>
            <div className="w-full relative h-[5px] flex flex-row items-center bg-gray-10 group-hover:bg-gray-30/50 justify-between">
                {options.map((option, index) => (
                    <button
                        className="h-[22px] border-l-[1px] border-gray-30 w-[0px] flex flex-row justify-center focus:outline-accent"
                        id={id + "-slider-step-" + index}
                        key={id + "-slider-step-" + index}
                        onClick={() => tickClick(index)}
                    >
                        <div className="mt-8 roboto text-[14px] text-gray-60 select-none pointer-events-none">
                            {option}
                        </div>
                    </button>
                ))}
                <div
                    className={
                        "absolute h-[5px] flex flex-row items-center w-full overflow-visible " +
                        (isDragging ? "" : "transition-all duration-75")
                    }
                    style={{ paddingLeft, paddingRight }}
                >
                    <SliderSlider
                        side="left"
                        dragStart={dragStart}
                        dragging={dragging}
                        dragEnd={dragEnd}
                        isDragging={isDragging}
                        extraClasses={
                            isDouble && selectedTopIndex === selectedBottomIndex
                                ? "-translate-x-1/3 hover:scale-[1.1]"
                                : ""
                        }
                    />
                    {isDouble && (
                        <>
                            <div className="grow h-[5px] bg-accent"></div>
                            <SliderSlider
                                side="right"
                                dragStart={dragStart}
                                dragging={dragging}
                                dragEnd={dragEnd}
                                isDragging={isDragging}
                                extraClasses={
                                    isDouble && selectedTopIndex === selectedBottomIndex
                                        ? "translate-x-1/3 hover:scale-[1.1]"
                                        : ""
                                }
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="w-[12px] h-[5px] bg-gray-10 group-hover:bg-gray-30/50 rounded-r-sm"></div>
        </div>
    )
}

export default Slider

import React, { useContext } from "react"

import { AuthContext } from "../../Auth/Auth"

import "./ChangePictureModal.css"

const ChangePictureModal = (props) => {
    const { onCancel, onConfirm } = props

    const { currentUser } = useContext(AuthContext)

    return (
        <div className="change-picture-modal">
            <div className="change-picture-modal__container">
                <div className="change-picture-modal__title">Profile Picture</div>
                <div className="change-picture-modal__image">
                    {currentUser.displayName ? currentUser.displayName[0] : currentUser.email[0]}
                </div>
                <button className="change-picture-modal__browse-button">Browse to change picture</button>
                <div className="change-picture-modal__button-container">
                    <button className="change-picture-modal__delete-button">Delete photo</button>
                    <button className="change-picture-modal__cancel-button" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="change-picture-modal__confirm-button" onClick={onConfirm}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChangePictureModal

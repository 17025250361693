import "./SimpleModal.css"

function ModalFormTable(props) {
    const { children, title, customStyle = undefined } = props
    return (
        <>
        {!customStyle && (
            <div className="simple-modal">
                <div className="simple-modal__content">
                    <h1 className="simple-modal__content__title">{title}</h1>
                    {children}
                </div>
            </div>
        )}
        {customStyle && (
            <div className={customStyle.topLevel}>
                <div className={customStyle.contentLevel}>
                    <h1 className={customStyle.title}>{title}</h1>
                    {children}
                </div>
            </div>
        )}
        </>
    )
}

export default ModalFormTable

const LowChillHoursRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.17 13L2 13L2 11L6.17 11L2.93 7.76L4.34 6.34L9 11L11 11L11 9L6.34 4.34L7.76 2.93L11 6.17L11 2L13 2L13 6.17L13 9L13 11L13 13L13 15L13 17.83L13 22L11 22L11 17.83L7.76 21.07L6.34 19.66L11 15L11 13L9 13L4.34 17.66L2.93 16.24L6.17 13Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 21.5422C19.0571 20.2679 22 16.4776 22 12C22 7.52234 19.0571 3.73204 15 2.45778L15 4.58149C17.9318 5.76827 20 8.6426 20 12C20 15.3574 17.9318 18.2317 15 19.4185L15 21.5422Z"
                fill={props.fill}
            />
            <path d="M21 22L15 22L15 16L21 22Z" fill={props.fill} />
        </svg>
    )
}

export default LowChillHoursRiskIcon

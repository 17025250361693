import HeatStressRiskIcon from "../ui/Icons/newIcons/riskIcons/HeatStressRiskIcon"
import PrecipitationRiskIcon from "../ui/Icons/newIcons/riskIcons/PrecipitationRiskIcon"
import HighSoilTempRiskIcon from "../ui/Icons/newIcons/riskIcons/HighSoilTempRiskIcon"
import ThermometerIcon from "../ui/Icons/newIcons/ThermometerIcon"
import InsufficientHighTempRiskIcon from "../ui/Icons/newIcons/riskIcons/InsufficientHighTempRiskIcon"
import LowWindDayRiskIcon from "../ui/Icons/newIcons/riskIcons/LowWindDayRiskIcon"
import ChillHoursStressRiskIcon from "../ui/Icons/newIcons/riskIcons/ChillHoursStressRiskIcon"
import LowChillHoursRiskIcon from "../ui/Icons/newIcons/riskIcons/LowChillHoursRiskIcon"
import HighChillHoursRiskIcon from "../ui/Icons/newIcons/riskIcons/HighChillHoursRiskIcon"
import ColdRiskIcon from "../ui/Icons/newIcons/riskIcons/ColdRiskIcon"
import InsufficientChillTempRiskIcon from "../ui/Icons/newIcons/riskIcons/InsufficientChillTempRiskIcon"
import CumulativePrecipitationRiskIcon from "../ui/Icons/newIcons/riskIcons/CumulativePrecipitationRiskIcon"
import DroughtRiskIcon from "../ui/Icons/newIcons/riskIcons/DroughtRiskIcon"
import CumulativeDroughtRiskIcon from "../ui/Icons/newIcons/riskIcons/CumulativeDroughtRiskIcon"
import DrySoilRiskIcon from "../ui/Icons/newIcons/riskIcons/DrySoilRiskIcon"
import ExcessiveSolarRadiationRiskIcon from "../ui/Icons/newIcons/riskIcons/ExcessiveSolarRadiationRiskIcon"
import LowSoilTempRiskIcon from "../ui/Icons/newIcons/riskIcons/LowSoilTempRiskIcon"
import LowHumidityRiskIcon from "../ui/Icons/newIcons/riskIcons/LowHumidityRiskIcon"
import HighHumidityRiskIcon from "../ui/Icons/newIcons/riskIcons/HighHumidityRiskIcon"
import HighWindDayRiskIcon from "../ui/Icons/newIcons/riskIcons/HighWindDayRiskIcon"
import InsufficientSolarRadiationRiskIcon from "../ui/Icons/newIcons/riskIcons/InsufficientSolarRadiationRiskIcon"
import { WindSpeedIcon } from "../ui/Icons/newIcons/weatherVariableIcons"
import SettingsIcon from "../ui/Icons/Navbar/SettingsIcon"
import StackedAlertsIcon from "../ui/Icons/newIcons/StackedAlertsIcon"

const RISK_TITLE_TO_KEY = {
    "Low Humidity": "risk_low_humidity",
    "High Humidity": "risk_high_humidity",
    "Chill Hours Stress": "risk_chill_hours_stress",
    "Low Chill Hours": "risk_low_chill_hours",
    "High Chill Hours": "risk_high_chill_hours",
    "Heat Stress": "risk_heat_stress",
    Cold: "risk_cold",
    "Insufficient High Temperature": "risk_insufficient_high_temp",
    "Insufficient Low Temperature": "risk_insufficient_low_temp",
    "Cumulative Precipitation": "risk_cumulative_flood",
    Precipitation: "risk_flood",
    "Cumulative Drought": "risk_cumulative_drought",
    "Dry Soil": "risk_dry_soil",
    "Wet Soil": "risk_wet_soil",
    "Low Soil Temperature": "risk_low_soil_temperature",
    "High Soil Temperature": "risk_high_soil_temperature",
    "Low Wind": "risk_low_wind_speed",
    "High Wind": "risk_high_wind_speed",
    "Insufficient Solar Radiation": "risk_insufficient_solar_radiation",
    "Excessive Solar Radiation": "risk_excessive_solar_radiation",
    Drought: "risk_drought",
}

const getRiskFromTitle = (riskTitle) => {
    for (const riskPseudoKey of Object.keys(RISK_TITLE_TO_KEY)) {
        if (riskTitle.includes(riskPseudoKey)) return RISK_TITLE_TO_KEY[riskPseudoKey]
    }
    return ""
}

export default function getRiskIcon(riskName, riskTitle) {
    let riskKey = riskName
    if (riskTitle !== undefined) riskKey = getRiskFromTitle(riskTitle)
    if (riskKey === "") riskKey = riskName

    switch (riskKey) {
        case "risk_chill_hours_stress":
            return ChillHoursStressRiskIcon
        case "risk_low_chill_hours":
            return LowChillHoursRiskIcon
        case "risk_high_chill_hours":
            return HighChillHoursRiskIcon
        case "risk_heat_stress":
            return HeatStressRiskIcon
        case "risk_cold":
            return ColdRiskIcon
        case "risk_insufficient_high_temp":
            return InsufficientHighTempRiskIcon
        case "risk_insufficient_low_temp":
            return InsufficientChillTempRiskIcon
        case "risk_flood":
            return PrecipitationRiskIcon
        case "risk_cumulative_flood":
            return CumulativePrecipitationRiskIcon
        case "risk_drought":
            return DroughtRiskIcon
        case "risk_cumulative_drought":
            return CumulativeDroughtRiskIcon
        case "risk_dry_soil":
            return DrySoilRiskIcon
        case "risk_wet_soil":
            return ExcessiveSolarRadiationRiskIcon
        case "risk_low_soil_temperature":
            return LowSoilTempRiskIcon
        case "risk_high_soil_temperature":
            return HighSoilTempRiskIcon
        case "risk_low_humidity":
            return LowHumidityRiskIcon
        case "risk_high_humidity":
            return HighHumidityRiskIcon
        case "risk_low_wind_speed":
            return LowWindDayRiskIcon
        case "risk_high_wind_speed":
            return HighWindDayRiskIcon
        case "risk_insufficient_solar_radiation":
            return InsufficientSolarRadiationRiskIcon
        case "risk_excessive_solar_radiation":
            return ExcessiveSolarRadiationRiskIcon
        // case "risk_growing_degree_days":
        //     return < />
        // case "risk_pest_degree_days":
        //     return < />
        case "ws":
        case "risk_wind_speed":
            return WindSpeedIcon
        case "risk_stacked":
            return StackedAlertsIcon
        case "risk_custom":
            return SettingsIcon
        default:
            return ThermometerIcon
    }
}

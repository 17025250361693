import * as React from "react"

function SettingsIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            className="fill-inherit"
        >
            <path
                d="M19.41 12.077c.048-.344.072-.699.072-1.077 0-.367-.024-.733-.083-1.077l2.391-1.81a.552.552 0 00.142-.7L19.67 3.61c-.141-.252-.436-.332-.695-.252l-2.815 1.1A8.381 8.381 0 0014.25 3.38L13.827.47a.566.566 0 00-.565-.47H8.738a.553.553 0 00-.553.47L7.76 3.38a8.565 8.565 0 00-1.909 1.077l-2.815-1.1a.57.57 0 00-.695.252L.092 7.414c-.141.24-.094.538.141.699l2.392 1.81A6.547 6.547 0 002.519 11c0 .355.023.733.082 1.077L.21 13.887a.552.552 0 00-.142.7L2.33 18.39c.141.252.436.332.695.252l2.815-1.1A8.381 8.381 0 007.75 18.62l.424 2.91c.059.275.283.47.565.47h4.524a.545.545 0 00.553-.47l.425-2.91a8.137 8.137 0 001.908-1.077l2.815 1.1a.57.57 0 00.695-.252l2.262-3.804c.141-.253.082-.54-.142-.7l-2.367-1.81zM11 15.125c-2.332 0-4.24-1.856-4.24-4.125S8.667 6.875 11 6.875c2.332 0 4.24 1.856 4.24 4.125s-1.908 4.125-4.24 4.125z"
                fill={props.fill}
            />
        </svg>
    )
}

export default SettingsIcon

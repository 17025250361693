import React from "react"

import { titleCase } from "../../../../Util/General"

const VarietyTab = (props) => {
    const { name } = props

    return (
        <button id={`${name}-variety-tab__risk-profiles`} className="risk-profiles__alerts__variety-button">
            <div className="risk-profiles__alerts__subcategory-title">{titleCase(name)}</div>
        </button>
    )
}

export default VarietyTab

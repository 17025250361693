import React from "react"

import "./ChangePasswordModal.css"

const ChangePasswordModal = (props) => {
    const { onCancel, onConfirm } = props

    return (
        <div className="change-password-modal">
            <form className="change-password-modal__container">
                <div className="change-password-modal__content-white">
                    <div className="change-password-modal__title">
                        Submit your email address so we can send you a link to change your password
                    </div>
                    <input className="change-password-modal__input" placeholder="Your email address" />
                </div>

                <div className="change-password-modal__content-gray">
                    <div className="change-password-modal__button-container">
                        <button onClick={onCancel} className="change-password-modal__cancel-button">
                            Cancel
                        </button>
                        <button onClick={onConfirm} className="change-password-modal__accept-button">
                            Confirm
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ChangePasswordModal

import { useState, useCallback, useEffect, useContext } from "react"
import http from "../../../Util/http"
import LocationPinIcon from "../../../ui/Icons/newIcons/LocationPinIcon"
import ScenarioIcon from "../../../ui/Icons/newIcons/ScenarioIcon"
import CalendarIcon from "../../../ui/Icons/newIcons/CalendarIcon"
import Slider from "../../../components/Slider"
import WorldIcon from "../../../ui/Icons/newIcons/WorldIcon"
import FilterIcon from "../../../ui/Icons/newIcons/FilterIcon"
import AnalogIcon from "../../../ui/Icons/newIcons/AnalogIcon"
import NotLocationPinIcon from "../../../ui/Icons/newIcons/NotLocationPinIcon"
import Select from "../../../components/Select"
import { Button } from "../../../components/Button"
import MonthRangePicker, { shadedMonthIndexes } from "../../../components/MonthRangePicker"
import { COUNTRIES_AND_CONTINENTS_FOR_ANALOGS, MONTH_INDEXES, MONTH_NAMES } from "../../../Util/constants"
import PlacesInput from "./PlacesInput"
import { ToastContext } from "../../../components/Toast/ToastContextProvider"
import useCSVData from "../../../hooks/useCSVData"
import { ANALOGS_CSV_HEADERS, formatAnalogsCSVData } from "../../../Util/CSVParser"
import ExportButton from "../../../components/ExportButton"
import { SettingsContext } from "../../../Util/SettingsContext"
import TweakIcon from "../../../ui/Icons/TweakIcon"

const NUMBER_OF_ANALOGS_OPTIONS = { 1: 1, 3: 3, 5: 5, 10: 10 }
const VARIABLES_OPTIONS = ["Maximum temperature", "Minumum temperature", "Precipitation"]
const VARIABLES_KEYS = { "Maximum temperature": "t2m_max", "Minumum temperature": "t2m_min", Precipitation: "tp" }
export const EXCLUSION_RADIUS_OPTIONS = (units) =>
    units === "metric" ? [0, 250, 500, 750, 1000] : [0, 150, 310, 470, 620]

const AnalogFormGroup = ({ icon, name, right, bottom }) => {
    return (
        <div className="flex flex-col items-stretch space-y-[12px]">
            <div className="flex flex-row items-center space-x-[8px] min-h-[36px]">
                <span className="w-[24px] h-[24px] shrink-0 grow-0 fill-gray-60">{icon}</span>
                <div className="grow roboto text-[16px] text-gray-90 whitespace-nowrap">{name}</div>
                {right}
            </div>
            {bottom}
        </div>
    )
}
const InfoGrayPill = ({ content }) => {
    return <div className="grow-0 bg-gray-10 rounded-sm roboto text-[14px] py-[5px] px-[10px]">{content}</div>
}

const getQueryMonths = (start, end) => {
    if (start === null && end === null) {
        return MONTH_INDEXES.map((monthIndex) => monthIndex + 1).join(",")
    }
    if (start === null) return end++ + ""
    if (end === null) return start++ + ""

    return shadedMonthIndexes(start, end)
        .map((monthIndex) => monthIndex + 1)
        .join(",")
}

const AnalogsForm = ({
    ready,
    locations,
    scenarios,
    decadeOptions,
    formDecadeRange,
    setFormDecadeRange,
    formDecadesLabel,
    formMonthRanges,
    setFormMonthRanges,
    formMonthsLabel,
    selectedLocation,
    selectedScenario,
    formNumberOfAnalogs,
    setFormNumberOfAnalogs,
    formExclusionRadius,
    setFormExclusionRadius,
    mapHeatmap,
    mapPins,
    setLoading,
    callback = () => {},
}) => {
    const [formLocation, setFormLocation] = useState(selectedLocation)
    const [formScenario, setFormScenario] = useState(selectedScenario)
    const [formPlaces, setFormPlaces] = useState({})
    const [formVariables, setFormVariables] = useState({})
    const [initialAnalogsPerformed, setInitialAnalogsPerformed] = useState(false)

    const { enqueueError } = useContext(ToastContext)
    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const fetchAnalogs = useCallback(
        (selectedLocation, selectedScenario) => {
            setLoading(true)
            // TODO Validate state
            const queryDecades = decadeOptions
                .filter((_, index) => index >= formDecadeRange[0] && index < formDecadeRange[1])
                .join(",")
            const queryMonths = getQueryMonths(formMonthRanges[0], formMonthRanges[1])
            const queryPlaces = Object.keys(formPlaces)
                .map((item) => item.replaceAll(" ", "_"))
                .join(",")
            const queryVariables = Object.keys(formVariables)
                .map((name) => VARIABLES_KEYS[name])
                .join(",")
            const fullQueryPlaces = queryPlaces !== "" ? "&countries=" + queryPlaces : ""
            const fullQueryVariables = queryVariables !== "" ? "&variables=" + queryVariables : ""

            const location = formLocation || selectedLocation
            const scenario = formScenario || selectedScenario

            let query = `?location=${location}&decade=${queryDecades}&scenario=${scenario}&months=${queryMonths}${fullQueryPlaces}${fullQueryVariables}`
            const request = http.get(`/api/v1/adapt/v2/analogs${query}`, { timeout: 180000 })
            request
                .then((response) => {
                    setInitialAnalogsPerformed(true)
                    callback(location, scenario, decadeOptions[formDecadeRange[0]], response?.data)
                })
                .catch((err) => {
                    console.log(err)
                    enqueueError()
                })
                .finally(() => setLoading(false))
            return () => request.cancel()
        },
        [
            formLocation,
            formScenario,
            formDecadeRange,
            formMonthRanges,
            decadeOptions,
            callback,
            setLoading,
            formPlaces,
            formVariables,
            enqueueError,
        ]
    )

    useEffect(() => {
        setFormLocation(selectedLocation)
        setFormScenario(selectedScenario)
        if (!initialAnalogsPerformed && selectedLocation) fetchAnalogs(selectedLocation, selectedScenario)
    }, [selectedLocation, selectedScenario])

    const { csvHeaders, csvData, csvString } = useCSVData(
        formatAnalogsCSVData(mapHeatmap),
        ANALOGS_CSV_HEADERS,
        `Climate Analogs
Location:,${ready && locations[selectedLocation]?.location.replaceAll("_", " ")}
Scenario:,${ready && scenarios[selectedScenario]?.label}
Decade period:,${ready && formDecadesLabel}
Number of analogs:,${ready && formNumberOfAnalogs}
Search in:,${(ready && Object.keys(formPlaces).join(",")) || "Global"}
Month filter:,${ready && formMonthsLabel}
Variables used:,${(ready && Object.keys(formVariables).join(",")) || "All variables"}
Exclusion radius:,${
            ready && EXCLUSION_RADIUS_OPTIONS(units)[formExclusionRadius] + (units === "metric" ? " km" : " mi")
        }
\nAnalogs found
${ANALOGS_CSV_HEADERS.join(",")}
${
    mapPins &&
    mapPins
        .slice(1, mapPins.length)
        .map((pin) => [
            pin.lon,
            pin.lat,
            pin.value.toFixed(1),
            pin.months,
            pin.variables.t2m_max?.value.toFixed(1) || "Not used",
            pin.variables.t2m_min?.value.toFixed(1) || "Not used",
            pin.variables.tp?.value.toFixed(1) || "Not used",
        ])
        .join("\n")
}
\nSearch in data\n`, // RESPECT THE FORMAT DEFINED ABOVE.
        ready
    )

    return (
        <>
            {mapHeatmap && mapHeatmap.length > 0 && (
                <ExportButton
                    cols={{ csv: csvHeaders }}
                    data={{ csv: csvData }}
                    csvString={csvString}
                    wrapperClasses="absolute top-2 right-2"
                    fileName="Analogs"
                    isPNGEnabled={false}
                    idToExport="analogs-section"
                />
            )}
            <div className="pl-[18px] pr-[12px] py-[20px] flex flex-col space-y-[26px] overflow-y-auto overflow-x-hidden h-full">
                <AnalogFormGroup
                    icon={<LocationPinIcon />}
                    name="Location"
                    right={
                        <Select
                            loading={!ready}
                            selected={formLocation}
                            options={locations}
                            setSelected={setFormLocation}
                            valueKey="location"
                            placeholder="Select a location"
                        />
                    }
                />
                <AnalogFormGroup
                    icon={<ScenarioIcon />}
                    name="Scenario"
                    right={
                        <Select
                            loading={!ready}
                            selected={formScenario}
                            options={scenarios}
                            setSelected={setFormScenario}
                            valueKey="label"
                            placeholder="Select a scenario"
                        />
                    }
                />
                <AnalogFormGroup
                    icon={<CalendarIcon />}
                    name="Decade period"
                    right={<InfoGrayPill content={formDecadesLabel} />}
                    bottom={
                        <Slider
                            id="decade-slider"
                            options={decadeOptions}
                            isDouble
                            selectedBottomIndex={formDecadeRange[0]}
                            selectedTopIndex={formDecadeRange[1]}
                            setSelectedIndexes={setFormDecadeRange}
                        />
                    }
                />
                <AnalogFormGroup
                    icon={<AnalogIcon />}
                    name="Number of analogs"
                    right={
                        <Select
                            selected={formNumberOfAnalogs}
                            options={NUMBER_OF_ANALOGS_OPTIONS}
                            setSelected={setFormNumberOfAnalogs}
                            textAlignClass="text-center"
                        />
                    }
                />
                <div className="h-[1px] border-b-[1px] border-gray-10 w-[320px] relative -left-[18px]"></div>
                <div className="montserrat text-[16px] font-semibold text-gray-90 pt-4 pl-[4px]">Advanced Filters</div>
                <AnalogFormGroup
                    icon={<WorldIcon />}
                    name="Search in"
                    bottom={
                        <PlacesInput
                            options={COUNTRIES_AND_CONTINENTS_FOR_ANALOGS}
                            selected={formPlaces}
                            select={setFormPlaces}
                            placeholder="Enter a country to filter"
                        />
                    }
                />
                <AnalogFormGroup
                    icon={<FilterIcon />}
                    name="Month filter"
                    right={<InfoGrayPill content={formMonthsLabel} />}
                    bottom={
                        <MonthRangePicker
                            startMonthIndex={formMonthRanges[0] !== null ? formMonthRanges[0] : null}
                            endMonthIndex={formMonthRanges[1] !== null ? formMonthRanges[1] : null}
                            startMonthPlaceholder={
                                formMonthRanges[0] !== null ? MONTH_NAMES[formMonthRanges[0]] : "Start month"
                            }
                            endMonthPlaceholder={
                                formMonthRanges[1] !== null ? MONTH_NAMES[formMonthRanges[1]] : "End month"
                            }
                            popupPositionClass="inset-x-0"
                            onChange={(indexes) => {
                                setFormMonthRanges(indexes)
                            }}
                        />
                    }
                />
                <AnalogFormGroup
                    icon={<TweakIcon />}
                    name="Variable filter"
                    bottom={
                        <PlacesInput
                            placeholder="Enter a variable to filter"
                            options={VARIABLES_OPTIONS}
                            selected={formVariables}
                            select={setFormVariables}
                        />
                    }
                />
                <AnalogFormGroup
                    icon={<NotLocationPinIcon />}
                    name="Exclusion radius"
                    right={
                        <InfoGrayPill
                            content={
                                EXCLUSION_RADIUS_OPTIONS(units)[formExclusionRadius] +
                                (units === "metric" ? " kilometers" : " miles")
                            }
                        />
                    }
                    bottom={
                        <Slider
                            id="exclusion-slider"
                            options={EXCLUSION_RADIUS_OPTIONS(units)}
                            selectedBottomIndex={formExclusionRadius}
                            setSelectedIndexes={setFormExclusionRadius}
                        />
                    }
                />
                <div className="flex flex-row justify-center pt-8">
                    <Button label="Calculate Analogs" extend={false} disabled={false} onClick={fetchAnalogs} />
                </div>
            </div>
        </>
    )
}

export default AnalogsForm

import * as React from "react"

const CollapseIcon = (props) => (
    <svg
        width="100%"
        height="100%"
        className="fill-inherit"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m4.5 20.91 4.5-4.5V20h2v-7H4v2h3.59l-4.5 4.5 1.41 1.41ZM20 9v2h-7V4h2v3.59l4.5-4.5 1.41 1.41-4.5 4.5H20Z"
            fill={props.fill}
        />
    </svg>
)

export default CollapseIcon

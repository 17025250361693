import React from "react"
import {
    convertTemperatureValueOnly,
    convertWaterLengthValueOnly,
    convertVolumeValueOnly,
    convertSpeedWindValueOnly,
} from "./UnitConversion"

function getCorrectUnitValue(riskUnit, originalUnits, wantedUnits, alertThreshold) {
    switch (riskUnit) {
        case "C":
        case "°C":
            return convertTemperatureValueOnly(originalUnits, wantedUnits, alertThreshold)
        case "mm":
            return convertWaterLengthValueOnly(originalUnits, wantedUnits, alertThreshold)
        case "mm/m^3":
            return convertVolumeValueOnly(originalUnits, wantedUnits, alertThreshold)
        case "km/h":
            return convertSpeedWindValueOnly(originalUnits, wantedUnits, alertThreshold)
        case "%":
        case "W/m^2":
            return alertThreshold
        case "m/s":
            return parseFloat(alertThreshold)
        default:
            break
    }
}

function convertArrayToObject(array, key) {
    const initialValue = {}
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        }
    }, initialValue)
}

function cleanStackedAlertData(alertData, risksData, userUnits) {
    alertData.recurrent_days = parseInt(alertData.recurrent_days)

    if (Array.isArray(alertData.risks)) {
        alertData.risks = convertArrayToObject(alertData.risks, "risk")
    }

    Object.keys(alertData.risks).forEach((risk) => {
        let riskUnits = risksData[risk].units
        let isGDD = risksData[risk].isGDD
        let individualAlertData = alertData.risks[risk]
        if (isGDD) {
            individualAlertData.gddBase = parseFloat(
                getCorrectUnitValue(riskUnits, userUnits, "metric", individualAlertData.gddBase)
            )
            individualAlertData.threshold = parseFloat(
                getCorrectUnitValue(riskUnits, userUnits, "metric", individualAlertData.gddSum)
            )
            delete individualAlertData.gddSum
            individualAlertData.gddLifeCycle = parseInt(individualAlertData.gddLifeCycle)
        } else {
            individualAlertData.threshold = parseFloat(
                getCorrectUnitValue(riskUnits, userUnits, "metric", individualAlertData.threshold)
            )
        }

        individualAlertData.probability = parseFloat(individualAlertData.probability) / 100
    })

    alertData.risks = Object.entries(alertData.risks).map((e) => ({ ...e[1], risk: e[0] }))

    delete alertData.errors
    delete alertData.flags
    alertData.stacked = true
    return alertData
}

function cleanAlertData(alertData, riskUnits, userUnits, isGDD) {
    if (isGDD) {
        alertData.gddBase = parseFloat(getCorrectUnitValue(riskUnits, userUnits, "metric", alertData.gddBase))
        alertData.threshold = parseFloat(getCorrectUnitValue(riskUnits, userUnits, "metric", alertData.gddSum))
        delete alertData.gddSum
        alertData.gddLifeCycle = parseInt(alertData.gddLifeCycle)
    } else {
        alertData.threshold = parseFloat(getCorrectUnitValue(riskUnits, userUnits, "metric", alertData.threshold))
        alertData.recurrent_days = parseInt(alertData.recurrent_days)
    }
    alertData.probability = parseFloat(alertData.probability) / 100
    if (alertData.lag) alertData.lag = parseInt(alertData.lag)

    delete alertData.errors
    delete alertData.flags
    return alertData
}

function getUnitsNotation(riskUnit, userUnits) {
    if (userUnits === "metric") {
        switch (riskUnit) {
            case "C":
                return <>&deg;C</>
            case "mm":
                return <>mm</>
            case "mm/m^3":
                return <>mm/m&#xb3;</>
            case "%":
                return <>%</>
            case "m/s":
                return <>m/s</>
            case "W/m^2":
                return <>W/m^2</>
            case "km/h":
                return <>km/h</>
            default:
                return <>Units</>
        }
    }

    switch (riskUnit) {
        case "C":
            return <>&deg;F</>
        case "mm":
            return <>in</>
        case "mm/m^3":
            return <>mm/m&#xb3;</>
        case "%":
            return <>%</>
        case "m/s":
            return <>mph</>
        case "km/h":
            return <>mph</>
        case "W/m^2":
            return <>W/m^2</>
        default:
            return <>Units</>
    }
}

function isAlertDataValid(validations, alertData, riskUnits, userUnits) {
    const errors = {}
    const flags = {}
    let dataHasErrors = false

    for (let val in validations) {
        let validationFunction
        if (val === "threshold" || val === "gddBase") {
            validationFunction = validations[val](riskUnits, userUnits)
        } else if (val === "gddSum") {
            validationFunction = validations[val](userUnits)
        } else {
            validationFunction = validations[val]()
        }

        let err
        if (alertData !== undefined) {
            err = validationFunction(alertData[val])
        } else {
            err = "A value must be entered"
        }

        errors[val] = err
        if (err !== null) {
            flags[val] = true
            dataHasErrors = true
        }
    }
    let result = { errors, flags, dataHasErrors }
    return result
}

function cleanValidations(isGDD, vals) {
    if (!isGDD) {
        delete vals.gddBase
        delete vals.gddSum
        delete vals.gddLifeCycle
    } else {
        delete vals.threshold
        delete vals.recurrent_days
    }
    return vals
}

export {
    getCorrectUnitValue,
    cleanAlertData,
    cleanStackedAlertData,
    getUnitsNotation,
    isAlertDataValid,
    cleanValidations,
}

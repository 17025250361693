import MenuIcon from "../../ui/Icons/newIcons/MenuIcon"

const SideBarMobileToggler = ({ isOpen = false, toggleFunction = () => {} }) => {
    return (
        <div
            className={
                "md:hidden fixed top-3 left-5 w-8 fill-gray-60 opacity-0 transition-all" +
                (isOpen ? " invisible" : " opacity-100")
            }
            onClick={toggleFunction}
        >
            <MenuIcon />
        </div>
    )
}

export default SideBarMobileToggler

import { useContext, useMemo, useState } from "react"
import AnalogsChart from "./components/AnalogsChart"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import useAnalogsData from "../../hooks/useAnalogsData"
import Section from "../../ui/Section"
import AnalogsForm, { EXCLUSION_RADIUS_OPTIONS } from "./components/AnalogsForm"
import { SettingsContext } from "../../Util/SettingsContext"
import { MONTH_NAMES } from "../../Util/constants"

function Analogs() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})

    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const {
        ready,
        allLocations: locations, // ------
        selectedLocation,
        setSelectedLocation,
        scenarios, // --------------------
        selectedScenario,
        setSelectedScenario,
        decades, // ----------------------
        setSelectedDecade,
    } = useAdaptationValues()

    const [formNumberOfAnalogs, setFormNumberOfAnalogs] = useState(3)
    const [formExclusionRadius, setFormExclusionRadius] = useState(2)
    const [formDecadeRange, setFormDecadeRange] = useState([1, 2])
    const decadeOptions = useMemo(() => Object.keys(decades), [decades])
    const formDecadesLabel = useMemo(() => {
        return decadeOptions[formDecadeRange[0]] + " - " + (+decadeOptions[formDecadeRange[1]] - 1)
    }, [decadeOptions, formDecadeRange])
    const [formMonthRanges, setFormMonthRanges] = useState([null, null])
    const formMonthsLabel = useMemo(() => {
        const start = formMonthRanges[0]
        const end = formMonthRanges[1]
        if (start === null && end === null) return "Full Year"
        if (start === null) return MONTH_NAMES[end]
        if (end === null) return MONTH_NAMES[start]
        if (start === end) return MONTH_NAMES[start]
        if (start === 0 && end === 11) return "Full Year"
        return MONTH_NAMES[start].slice(0, 3) + " - " + MONTH_NAMES[end].slice(0, 3)
    }, [formMonthRanges])

    const { mapHeatmap, mapPins } = useAnalogsData(data, {
        currentLocation: locations[selectedLocation],
        numberOfAnalogs: formNumberOfAnalogs,
        exclusionRadius: EXCLUSION_RADIUS_OPTIONS(units)[formExclusionRadius],
        units,
        years: formDecadesLabel,
        months: formMonthsLabel,
    })

    return (
        <div className="flex flex-row justify-end gap-[14px] h-full flex-wrap" id="analogs-section">
            <AnalogsChart loading={loading} mapHeatmap={mapHeatmap} mapPins={mapPins} />

            <Section title="Search Parameters" extraClasses="w-[320px] grow-0 shrink-0" headerTextClass="pl-0">
                <AnalogsForm
                    ready={ready}
                    locations={locations}
                    scenarios={scenarios}
                    decadeOptions={decadeOptions}
                    formDecadeRange={formDecadeRange}
                    setFormDecadeRange={setFormDecadeRange}
                    formDecadesLabel={formDecadesLabel}
                    formMonthRanges={formMonthRanges}
                    setFormMonthRanges={setFormMonthRanges}
                    formMonthsLabel={formMonthsLabel}
                    selectedLocation={selectedLocation}
                    selectedScenario={selectedScenario}
                    formNumberOfAnalogs={formNumberOfAnalogs}
                    setFormNumberOfAnalogs={setFormNumberOfAnalogs}
                    formExclusionRadius={formExclusionRadius}
                    setFormExclusionRadius={setFormExclusionRadius}
                    mapHeatmap={mapHeatmap}
                    mapPins={mapPins}
                    setLoading={setLoading}
                    callback={(location, scenario, decade, data) => {
                        setSelectedLocation(location)
                        setSelectedScenario(scenario)
                        setSelectedDecade(decade)
                        setData(data)
                    }}
                />
            </Section>
        </div>
    )
}

export default Analogs

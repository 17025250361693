const InsufficientHighTempRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 23H12.9999V19H11V23ZM11 16.8999C11.3231 16.9655 11.6575 17 11.9999 17C14.7599 17 16.9999 14.76 16.9999 12C16.9999 9.24 14.7599 7 11.9999 7C11.6575 7 11.3231 7.03448 11 7.10015V16.8999ZM11 5H12.9999V1H11V5ZM18.9999 11H22.9999V13H18.9999V11ZM18.7099 20.13L16.2399 17.66L17.6499 16.25L20.1199 18.72L18.7099 20.13ZM16.2499 6.34L18.7199 3.87L20.1299 5.28L17.6599 7.75L16.2499 6.34Z"
                fill={props.fill}
            />
            <rect x="5" y="6.7738" width="2" height="6" fill={props.fill} />
            <rect x="5" y="14.7738" width="2" height="2" fill={props.fill} />
        </svg>
    )
}

export default InsufficientHighTempRiskIcon

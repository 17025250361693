import React, { useState } from "react"
import "./TogglerButton.css"

function TogglerButton(props) {
    const { icon: IconComponent, children, fill, className = "" } = props
    const [open, toggle] = useState(false)

    return (
        <>
            <div className={`toggler-button ${className}`} onClick={() => toggle(!open)}>
                <button className="toggler-button__button">
                    <IconComponent fill={fill} width="100%" height="100%" />
                </button>
                {open && children}
            </div>
        </>
    )
}

export default TogglerButton

import "react-datepicker/dist/react-datepicker.css"

import Select from "../../../ui/Select/Select"
import Section from "../../../ui/Section"
import { HeatmapGridChartComponent } from "../../../components/Charts/HeatmapGrid/HeatmapGrid.component"
import useContainerSize from "../../../hooks/useContainerSize"
import CalendarIcon from "../../../ui/Icons/CalendarIcon"
import LoadingBar from "../../../components/LoadingBar"

function DecadalChart(props) {
    const {
        loading,
        data,
        scenarios,
        variables,
        decades,
        selectedScenario,
        selectedVariable,
        selectedDecade,
        setSelectedScenario,
        setSelectedVariable,
        setSelectedDecade,
    } = props

    const [containerRef, { height }] = useContainerSize(1000)

    return (
        <>
            <Section
                title="Co-Variant Climate Risk"
                description="This page displays the overall correlation on climate change between your locations. It gives you a sense of how likely two locations are to experience climate changes at the same time, creating an additional risk to the portfolio."
                header={() => (
                    <>
                        <Select
                            icon={CalendarIcon}
                            value={selectedDecade || ""}
                            onChange={(e) => setSelectedDecade(e.target.value)}
                        >
                            {decades.map((decade) => (
                                <option value={decade.value} key={`portfolio_decade_${decade.value}`}>
                                    {decade.label}
                                </option>
                            ))}
                        </Select>
                        <Select value={selectedVariable || ""} onChange={(e) => setSelectedVariable(e.target.value)}>
                            {variables &&
                                variables.map((v) => (
                                    <option value={v.value} key={`portfolio_var_${v.value}`}>
                                        {v.label}
                                    </option>
                                ))}
                        </Select>
                        <Select value={selectedScenario || ""} onChange={(e) => setSelectedScenario(e.target.value)}>
                            {scenarios.map((scenario) => (
                                <option value={scenario.value} key={`portfolio_scenario_${scenario.value}`}>
                                    {scenario.label}
                                </option>
                            ))}
                        </Select>
                    </>
                )}
            >
                <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
                    {loading && <LoadingBar loading />}
                    {data && data.values && data.values.length > 0 && (
                        <HeatmapGridChartComponent
                            colors={["white", "teal"]}
                            marginBottom={25}
                            marginTop={100}
                            marginLeft={25}
                            marginRight={25}
                            data={data}
                            svgHeight={height}
                        ></HeatmapGridChartComponent>
                    )}
                </div>
            </Section>
        </>
    )
}

export default DecadalChart

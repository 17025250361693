import React, { useEffect, useState } from "react"
import "./TableContent.css"
import TableHeader from "./TableHeader"
import TableItem from "./TableItem"
import { applyFieldTransformation } from "../../helpers/table"

function TableContent(props) {
    const {
        displayData = [],
        columns = [],
        sortBy = [],
        groupBy = [],
        sortFunctionName,
        onHeaderClick,
        path = "",
        selected = [],
        summaries = {},
        filters = [],
        availableFilterOptions = {},
        noSummary = false,
    } = props

    let currentColumns = [...columns]
    let nextFields = []

    const [styleFlag, setStyleFlag] = useState(false)

    useEffect(() => {
        setStyleFlag(!styleFlag)
    }, [summaries])

    // deprecated logic
    // if (groupBy && groupBy.length) {
    //     for (let field of columns) {
    //         // check if field is the selected one
    //         // check if current columns === 0 because you can only group data by one field at a time
    //         let currentlyGroupedBy = field.propName === groupBy[0].propName ? field : null

    //         if (currentlyGroupedBy && !currentlyGroupedBy.groupFields) {
    //             // if currently grouped by has special columns
    //             currentColumns = [currentlyGroupedBy]
    //         } else if (currentlyGroupedBy && currentlyGroupedBy.groupFields) {
    //             // if currently grouped by doesn't have special filds
    //             currentColumns = [...currentlyGroupedBy.groupFields]
    //         } else {
    //             // if it's any other field
    //             nextFields.push(field)
    //         }
    //     }
    // }
    // let transformedData = applyFieldTransformation(displayData, currentColumns) // deprecated

    let transformedData = displayData
    const _selected = [...selected] // deprecated

    const tableItems = (transformedData &&
        transformedData.length > 0 &&
        transformedData.map((rowData, index) => {
            // if tableitem has data, arrange it and render it

            let checked = false
            let checkedStatus = "empty"
            let checkedData = []

            // deprecated
            // for (let i = 0; i < _selected.length; i++) {
            //     if (groupBy[0] && rowData.__group) {
            //         const { propName } = groupBy[0]
            //         if (_selected[i][propName] === rowData[propName]) {
            //             checked = true
            //             checkedStatus =
            //                 _selected[i].__count === rowData.__count
            //                     ? "full"
            //                     : _selected[i].__count > 0
            //                     ? "half"
            //                     : "empty"
            //             checkedData = [..._selected[i].data]
            //             // remove from selected array
            //             _selected.splice(i, 1)
            //             break
            //         }
            //     } else if (_selected[i].uuid === rowData.uuid) {
            //         checked = true
            //         checkedStatus = "full"
            //         // remove from selected array
            //         _selected.splice(i, 1)
            //         break
            //     }
            // }

            return (
                <TableItem
                    key={`${rowData.uuid}_${index}`}
                    index={index}
                    rowData={rowData}
                    columns={currentColumns}
                    previousPath={path}
                    checked={checked}
                    checkedStatus={checkedStatus}
                    path={groupBy.length > 0 ? path + "/" + rowData[groupBy[0].propName] : path + "/" + rowData.uuid}
                >
                    {groupBy.length > 0 ? (
                        <TableContent
                            displayData={rowData.data}
                            columns={nextFields}
                            groupBy={groupBy.slice(1, groupBy.length)} // next group
                            selected={checkedData}
                            path={path + "/" + rowData[groupBy[0].propName]}
                            sortFunctionName={sortFunctionName}
                            onHeaderClick={onHeaderClick}
                        />
                    ) : null}
                </TableItem>
            )
        })) || (
        <tr className="flex-1 w-full h-full">
            <td className="w-full h-full flex-1" colSpan={currentColumns.length}>
                No records to display
            </td>
        </tr>
    )

    return (
        <>
            <thead>
                <tr>
                    {currentColumns &&
                        currentColumns.map((column, i) => {
                            return (
                                <TableHeader
                                    key={`field_${column.propName}_${i}`}
                                    active={column.propName === sortBy}
                                    sortFunctionName={sortFunctionName}
                                    onClick={onHeaderClick}
                                    column={column}
                                    selectedFilters={
                                        new Set(
                                            filters.filter((f) => f.propName === column.propName).map((f) => f.value)
                                        )
                                    }
                                    filterOptions={availableFilterOptions[column.propName]}
                                />
                            )
                        })}
                </tr>
            </thead>
            <tbody>{tableItems}</tbody>
            {!noSummary && (
                <tfoot className="sticky bottom-0 z-full">
                    {/* TODO */}
                    <tr>
                        {columns.map((col, index) => {
                            return (
                                <td
                                    key={`table_summary_${col.propName || index}`}
                                    className={`${col.headerClassName} ${(col.sticky && "stick") || ""}`}
                                    style={{ ...col.style, opacity: styleFlag ? ".99" : "1" }}
                                >
                                    {!col.summarize && col.renderSummary && col.renderSummary()}
                                    {col.summarize && !col.renderSummary && summaries[col.propName]}
                                    {col.summarize && col.renderSummary && col.renderSummary(summaries[col.propName])}
                                </td>
                            )
                        })}
                    </tr>
                </tfoot>
            )}
        </>
    )
}

export default React.memo(TableContent)

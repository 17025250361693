import { useEffect, useRef, useState } from "react"
import useOutsideComponentClickHandler from "../../hooks/ClickOutsideHook"
import { getAllCrops } from "../../services/cropVariety.service"
import { Button } from "../../components/Button"

const EMPTY_FIELD = {
    name: "",
    crop: "",
    variety: "",
    cropId: -1,
    varietyId: -1,
    plantingDateP: "",
    region: "",
    shapefile: "",
    uuid: "",
    wheather_variables: {},
    datasources: {},
    labels: [],
    triggered_alerts: {},
}
const REQUIRED_KEYS = [
    "name",
    "crop",
    "variety",
    "region",
    // "shapefile",
    // "datasources",
]

const SHAPEFILE_EXTENSIONS = [
    ".geojson",
    ".kml",
    ".zip"
]
const ALLOWED_SHAPEFILE_GEOMETRY_TYPES = [
    "Point",
    "Polygon",
    "MultiPolygon"
]

const AddEditField = (props) => {
    const { doShow, field, onCancel = () => {}, onUpdate = () => {}, onSave = () => {}, canEditDatasource } = props

    const [oldNewField, setOldNewField] = useState(EMPTY_FIELD)
    const [shapefileError, setShapefileError] = useState("")
    const [showErrors, setShowErrors] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [crops, setCrops] = useState({})

    const shapefileInputRef = useRef(null)

    const addEditFieldComponentRef = useOutsideComponentClickHandler(() => {
        setShowErrors(false)
        onCancel()
    })

    useEffect(() => {
        async function fetchData() {
            await getAllCrops().then(async (response) => {
                setCrops(response)
            })
        }
        fetchData()
    }, [])

    const setupFieldData = () => {
        if (field && field.uuid) {
            setOldNewField({
                name: field.name,
                ...getCropVarietyInfo(field),
                plantingDateP: field.plantingDateP,
                region: field.region,
                shapefile: field.shapefile,
                uuid: field.uuid,
                wheather_variables: { ...field.wheather_variables },
                datasources: { ...field.datasources },
                labels: [...field.labels],
                triggered_alerts: { ...field.triggered_alerts },
            })
            setIsEditing(true)
        } else {
            setOldNewField({ ...EMPTY_FIELD })
            setIsEditing(false)
        }
    }

    useEffect(() => {
        if (!doShow) {
            setOldNewField({ ...EMPTY_FIELD })
            setShowErrors(false)
        } else {
            setupFieldData()
        }
    }, [doShow])

    const isValidId = (id) => {
        return id !== "" && id !== -1 && id !== "-1" && id !== null && id !== undefined
    }

    const isValidCropId = (id) => {
        return isValidId(id) && crops[id]
    }

    const isValidVarietyId = (var_id, crop_id) => {
        return isValidCropId(crop_id) && isValidId(var_id) && crops[crop_id].varieties[var_id]
    }

    const getCropVarietyInfo = (field) => {
        return {
            crop: isValidCropId(field.crop_id) ? crops[+field.crop_id].name : "",
            variety: isValidVarietyId(field.variety_id, field.crop_id) ? crops[+field.crop_id].varieties[+field.variety_id].name : "",
            cropId: isValidId(field.crop_id) ? +field.crop_id : -1,
            varietyId: isValidId(field.variety_id) ? +field.variety_id : -1,
        }
    }

    useEffect(() => {
        setupFieldData()
    }, [field])

    // Params:
    // shapefile -> File object
    // successAction -> function to execute if the shapefile passes all verifications
    function verifyShapefile(shapefile, successAction) {
        // Checks that an uploaded shapefile has
        // 1. Correct file extension
        // 2. Correct Feature geometry types
        // If it passes both tasks, executes successAction

        let fileExtension = shapefile.name.match(/\.([^\.]+)$/)[1];
        
        if (SHAPEFILE_EXTENSIONS.includes('.' + fileExtension)){
            if (fileExtension === 'geojson'){
                // Since its a geojson, check for correct geometry types
                let reader = new FileReader()
                
                reader.readAsText(shapefile)

                reader.onload = function (e) {
                    let iterator = 0
                    let fileContents = JSON.parse(reader.result)
                    for (const feature of fileContents["features"]){
                        let geoType = feature["geometry"]["type"]
                        if (!ALLOWED_SHAPEFILE_GEOMETRY_TYPES.includes(geoType)){
                            setShapefileError(`Unsuported Shapefile Geometry format (${geoType}) in Feature ${iterator}`)
                            return
                        }
                        iterator++
                    }
                    successAction()
                    setShapefileError("")
                }
            }else{
                // Not a geojson, geometry types will get checked at the backend
                successAction()
                setShapefileError("")
            }
        } else {
            setShapefileError(`Unsuported Shapefile format (.${fileExtension})`)
        }
    }

    const handleFileDrop = (event) => {
        const newShapefile = event.dataTransfer.files[0]
        verifyShapefile(newShapefile, () => setOldNewField({ ...oldNewField, shapefile: newShapefile }))
        event.preventDefault()
    }

    const handleFileChange = (event) => {
        const newShapefile = event.target.files[0]
        verifyShapefile(newShapefile, () => setOldNewField({ ...oldNewField, shapefile: newShapefile }))
        event.preventDefault()
    }

    const handleDragOver = (event) => {
        event.preventDefault()
    }

    const handlePropInputChange = (value, prop) => {
        if (prop === "cropId") {
            setOldNewField({
                ...oldNewField,
                [prop]: value,
                crop: crops[value].name,
                variety: "",
                varietyId: -1,
            })
        } else if (prop === "varietyId") {
            setOldNewField({
                ...oldNewField,
                [prop]: value,
                variety: crops[oldNewField.cropId].varieties[value].name,
            })
        } else {
            setOldNewField({
                ...oldNewField,
                [prop]: value,
            })
        }
    }

    const handleDatasourceChange = (event) => {
        setOldNewField({
            ...oldNewField,
            datasources: {
                ...oldNewField.datasources,
                selected: event.target.value,
            },
        })
    }

    const isReqPropValid = (prop) => {
        // For the cropId and varietyId cases
        if (oldNewField[prop + "Id"] === -1) {
            return false
        } else if (oldNewField[prop] === "" || oldNewField[prop] === null || oldNewField[prop] === undefined) {
            return false
        }
        return true
    }

    const isFormValid = () => {
        const result = { isValid: true, error: "" }

        for (const reqKey of REQUIRED_KEYS) {
            if (!isReqPropValid(reqKey)) {
                result.isValid = false
                result.error = result.error !== "" ? result.error + ", " + reqKey : reqKey
            }
        }

        if (isEditing && !oldNewField.datasources.selected) {
            result.isValid = false
            result.error = result.error !== "" ? result.error + ", datasource" : "datasource"
        }

        if (!isEditing && oldNewField.shapefile === "") {
            result.isValid = false
            result.error = result.error !== "" ? result.error + ", shapefile" : "shapefile"
            setShapefileError("Shapefile is required")
        }

        return result
    }

    const handleSubmit = () => {
        const { isValid, error } = isFormValid()
        if (!isValid) {
            setShowErrors(true)
            return
        }
        if (isEditing) {
            onUpdate({ ...oldNewField }, field)
        } else {
            onSave({ ...oldNewField })
        }
        setShowErrors(false)
        onCancel()
    }

    const showErrorForProp = (prop) => {
        if (prop === "datasources") return showErrors && !oldNewField.datasources.selected
        if (prop === "cropId" || prop === "varietyId") return showErrors && oldNewField[prop] === -1
        if (prop === "shapefile") return showErrors || shapefileError !== ""
        return showErrors && !oldNewField[prop]
    }

    const formatDistance = (distance) => {
        if (!distance) return "NA"
        return distance.toFixed().toString() + "km"
    }

    return (
        <div
            ref={addEditFieldComponentRef}
            className={
                "fixed inset-y-0 right-0 w-0 bg-white z-full shadow-md transition-all duration-150 ease-out overflow-hidden overflow-y-auto" +
                (doShow ? " w-[300px]" : "")
            }
        >
            <div className="w-[252px] m-[24px] mb-[38px]">
                <h3 className="pt-[30px] text-[16px] montserrat text-gray-90 font-semibold m-0">
                    {isEditing ? "Edit" : "Add New"} Location
                </h3>

                <div className="pt-[42px]">
                    <div>
                        <label className="text-[14px] text-gray-60 roboto">Enter location name</label>
                        <input
                            onChange={(e) => handlePropInputChange(e.target.value, "name")}
                            value={oldNewField.name}
                            type="text"
                            placeholder="Write the name"
                            className={
                                "w-[240px] h-[36px] mt-[8px] border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-60 placeholder:text-gray-30" +
                                (showErrorForProp("name") ? " border-red-500" : "")
                            }
                        ></input>
                        {showErrorForProp("name") && (
                            <span className="block text-red-500 text-[12px] pt-[1px]">Name is required</span>
                        )}
                    </div>

                    <div className="pt-[26px]">
                        <label className="text-[14px] text-gray-60 roboto">Select category</label>
                        <select
                            onChange={(e) => handlePropInputChange(e.target.value, "cropId")}
                            value={oldNewField.cropId}
                            className={
                                "w-[240px] mt-[8px] h-[36px] climateai-select" +
                                (showErrorForProp("cropId") ? " border-red-500" : "")
                            }
                            disabled={Object.keys(crops).length === 0}
                        >
                            {Object.keys(crops).length > 0 ? (
                                <>
                                    <option disabled value={-1}>
                                        Select a category from your category list
                                    </option>
                                    {Object.keys(crops).map((id) => (
                                        <option value={id} key={id}>
                                            {crops[id].name}
                                        </option>
                                    ))}
                                </>
                            ) : (
                                <option disabled value={-1}>
                                    Add a category in Risk Categories
                                </option>
                            )}
                        </select>
                        {showErrorForProp("cropId") && (
                            <span className="block text-red-500 text-[12px] pt-[1px]">Category is required</span>
                        )}
                    </div>

                    <div className="pt-[26px]">
                        <label className="text-[14px] text-gray-60 roboto">Select subcategory</label>
                        <select
                            onChange={(e) => handlePropInputChange(e.target.value, "varietyId")}
                            value={oldNewField.varietyId}
                            className={
                                "w-[240px] mt-[8px] h-[36px] climateai-select" +
                                (showErrorForProp("varietyId") ? " border-red-500" : "")
                            }
                            disabled={
                                !isValidCropId(oldNewField.cropId) ||
                                Object.keys(crops[oldNewField.cropId].varieties).length === 0
                            }
                        >
                            {isValidCropId(oldNewField.cropId) ? (
                                Object.keys(crops[oldNewField.cropId].varieties).length > 0 ? (
                                    <>
                                        <option disabled value={-1}>
                                            Select a subcategory from your subcategory list
                                        </option>
                                        {Object.keys(crops[oldNewField.cropId].varieties).map((id) => (
                                            <option value={id} key={id}>
                                                {crops[oldNewField.cropId].varieties[id].name}
                                            </option>
                                        ))}
                                    </>
                                ) : (
                                    <option disabled value={-1}>
                                        Add a subcategory in Risk Categories
                                    </option>
                                )
                            ) : (
                                <option disabled value={-1}>
                                    Select a category
                                </option>
                            )}
                        </select>
                        {showErrorForProp("varietyId") && (
                            <span className="block text-red-500 text-[12px] pt-[1px]">Subcategory is required</span>
                        )}
                    </div>

                    <div className="pt-[26px]">
                        <label className="text-[14px] text-gray-60 roboto">Enter region</label>
                        <input
                            onChange={(e) => handlePropInputChange(e.target.value, "region")}
                            value={oldNewField.region}
                            type="text"
                            placeholder="Write the region name"
                            className={
                                "w-[240px] h-[36px] mt-[8px] border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-60 placeholder:text-gray-30 bg-white disabled:bg-gray-3 disabled:cursor-not-allowed cursor-pointer" +
                                (showErrorForProp("region") ? " border-red-500" : "")
                            }
                        ></input>
                        {showErrorForProp("region") && (
                            <span className="block text-red-500 text-[12px] pt-[1px]">Region is required</span>
                        )}
                    </div>

                    <div className="pt-[26px]">
                        <label className="text-[14px] text-gray-60 roboto">Enter starting date</label>
                        <input
                            onChange={(e) => handlePropInputChange(e.target.value, "plantingDateP")}
                            value={oldNewField.plantingDateP}
                            type="date"
                            className={
                                "w-[240px] h-[36px] mt-[8px] climateai-date-input" +
                                (showErrorForProp("plantingDateP") ? " border-red-500" : "")
                            }
                        ></input>
                    </div>

                    {isEditing && canEditDatasource && (
                        <div className="pt-[26px]">
                            <label className="text-[14px] text-gray-60 roboto">Data Source</label>
                            <select
                                className={
                                    "w-[240px] h-[36px] mt-[8px] border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-60 placeholder:text-gray-30 bg-white disabled:bg-gray-3 disabled:cursor-not-allowed cursor-pointer" +
                                    (showErrorForProp("datasources") ? " border-red-500" : "")
                                }
                                value={oldNewField.datasources.selected}
                                onChange={handleDatasourceChange}
                            >
                                <option disabled>Select an option</option>
                                {Object.keys(oldNewField.datasources)
                                    .filter((key) => key !== "suggested" && key !== "selected")
                                    .map((key) => {
                                        if (
                                            oldNewField.datasources[key] &&
                                            Object.keys(oldNewField.datasources[key]).length === 0 &&
                                            oldNewField.datasources[key].constructor === Object
                                        )
                                            return null

                                        return (
                                            <option key={key} value={key}>
                                                {key.toUpperCase()} -{" "}
                                                {formatDistance(oldNewField.datasources[key]?.distance)}
                                            </option>
                                        )
                                    })}
                            </select>
                            {showErrorForProp("datasources") && (
                                <span className="block text-red-500 text-[12px] pt-[1px]">Datasource is required</span>
                            )}
                        </div>
                    )}
                </div>

                <div className="w-[240px] pt-[46px] flex flex-row items-center self-stretch space-x-3">
                    <Button
                        label="Cancel"
                        type="secondary"
                        onClick={() => {
                            setShowErrors(false)
                            onCancel()
                        }}
                        extraClasses="flex-1"
                    />
                    <Button label="Save" type="primary" onClick={handleSubmit} extraClasses="flex-1" />
                </div>
            </div>
        </div>
    )
}

export default AddEditField

import React from "react"

import "./ChangeEmailModal.css"

const ChangeEmailModal = (props) => {
    const { onCancel, onConfirm } = props

    return (
        <div className="change-email-modal">
            <form className="change-email-modal__container">
                <div className="change-email-modal__content-white">
                    <div className="change-email-modal__title">Current Email</div>
                    <input className="change-email-modal__input" placeholder="Your email address" />
                </div>

                <div className="change-email-modal__content-gray">
                    <div className="change-email-modal__title">Set New Email</div>
                    <input className="change-email-modal__input" placeholder="New email" />

                    <div className="change-email-modal__button-container">
                        <button onClick={onCancel} className="change-email-modal__cancel-button">
                            Cancel
                        </button>
                        <button onClick={onConfirm} className="change-email-modal__accept-button">
                            Confirm
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ChangeEmailModal

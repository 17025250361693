const HighSoilTempRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <rect x="3" y="19" width="18" height="2" fill={props.fill} />
            <path d="M21 16V14.3334L17.7273 14.3334V16H21Z" fill={props.fill} />
            <path
                d="M16.009 16C16.0627 15.7307 16.0909 15.4521 16.0909 15.1667C16.0909 12.8667 14.2582 11 12 11C9.74182 11 7.90909 12.8667 7.90909 15.1667C7.90909 15.4521 7.9373 15.7307 7.99103 16H16.009Z"
                fill={props.fill}
            />
            <path d="M6.27273 16V14.3334H3L3 16H6.27273Z" fill={props.fill} />
            <path d="M11.1818 9.33335V6L12.8182 6V9.33335H11.1818Z" fill={props.fill} />
            <path
                d="M18.6518 9.57502L16.6309 11.6334L15.4773 10.4584L17.4982 8.40001L18.6518 9.57502Z"
                fill={props.fill}
            />
            <path d="M7.36909 11.625L5.34818 9.56669L6.50182 8.39168L8.52273 10.45L7.36909 11.625Z" fill={props.fill} />
        </svg>
    )
}

export default HighSoilTempRiskIcon

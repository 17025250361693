import { useMemo } from "react"

// www.geodatasource.com/developers/javascript
const distanceBetween = (lat1, lon1, lat2, lon2, unit) => {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0
    } else {
        var radlat1 = (Math.PI * lat1) / 180
        var radlat2 = (Math.PI * lat2) / 180
        var theta = lon1 - lon2
        var radtheta = (Math.PI * theta) / 180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
            dist = 1
        }
        dist = Math.acos(dist)
        dist = (dist * 180) / Math.PI
        dist = dist * 60 * 1.1515
        if (unit === "K") {
            dist = dist * 1.609344
        }
        if (unit === "N") {
            dist = dist * 0.8684
        }
        return dist
    }
}
const isValidLocation = ({ location, otherLocations, currentLocation, exclusionRadius, units }) => {
    if (exclusionRadius === 0) return true
    const cc = currentLocation.coords
    const distanceBetweenUnits = units === "metric" ? "K" : "M"
    if (distanceBetween(location.lat, location.lon, cc.lat, cc.lon, distanceBetweenUnits) < exclusionRadius)
        return false
    if (otherLocations.length === 0) return true
    for (const loc of otherLocations) {
        if (distanceBetween(location.lat, location.lon, loc.lat, loc.lon, distanceBetweenUnits) < exclusionRadius)
            return false
    }
    return true
}

const variableNamesAnalogMapPinPopover = {
    t2m_max: "Max temp",
    t2m_min: "Min temp",
    tp: "Precipitation",
}

export default function useAnalogsData(data, extras) {
    return useMemo(() => {
        if (!data) return

        const mapPins = []
        if (data && Object.keys(data).length > 0 && extras.currentLocation) {
            mapPins.push({
                analogMarker: "reference",
                lat: extras.currentLocation.coords.lat,
                lon:
                    extras.currentLocation.coords.lon > 180
                        ? extras.currentLocation.coords.lon - 360
                        : extras.currentLocation.coords.lon,
                value: extras.currentLocation.location,
                months: extras.months,
                // years: extras.years,
                years: "2010 - 2019",
            })
        }

        const sorted = Object.keys(data)
            .map((key) => {
                let months = data[key]?.analog_month[0] || "N/A"
                if (data[key]?.analog_month?.length > 1) {
                    months =
                        data[key].analog_month[0] + " - " + data[key].analog_month[data[key].analog_month.length - 1]
                }
                const variables = {}
                Object.keys(variableNamesAnalogMapPinPopover).forEach((variable) => {
                    if (data[key][variable])
                        variables[variable] = {
                            name: variableNamesAnalogMapPinPopover[variable],
                            value: data[key][variable],
                        }
                })
                return {
                    key,
                    value: data[key].value,
                    variables,
                    months,
                    years: extras.years,
                }
            })
            .sort((a, b) => (a.value > b.value ? -1 : 1))

        let mapHeatmap = []
        sorted.forEach((el) => {
            const strCoords = el.key.replaceAll("(", "").replaceAll(")", "").split(",")
            const lat = parseFloat(strCoords[0])
            const lng = parseFloat(strCoords[1])
            const value = el.value
            const opacity = (Math.sqrt(value) / Math.sqrt(10)) * 10

            mapHeatmap.push({
                type: "Feature",
                properties: {
                    type: "rectangularHeatmap",
                    longitudeWidth: 1,
                    latitudeHeight: 1,
                    color: ["#00ceb4", "#0c7667"],
                    satelliteColors: ["#0098B3", "#04FFE1"],
                    opacity,
                    value,
                    months: el.months,
                    variables: el.variables,
                },
                geometry: {
                    type: "Point",
                    coordinates: [lat, lng > 180 ? lng - 360 : lng],
                },
            })

            if (extras && extras.numberOfAnalogs >= mapPins.length) {
                if (
                    isValidLocation({
                        location: { lat, lon: lng },
                        otherLocations: mapPins,
                        currentLocation: extras.currentLocation,
                        exclusionRadius: extras.exclusionRadius || 0,
                        units: extras.units,
                    })
                ) {
                    mapPins.push({
                        count: mapPins.length,
                        analogMarker: "analog",
                        lat,
                        lon: lng,
                        value,
                        months: el.months,
                        years: el.years,
                        variables: el.variables,
                    })
                }
            }
        })

        return { mapHeatmap, mapPins }
    }, [data])
}

import { useMemo, useContext } from "react"
import { SettingsContext } from "../Util/SettingsContext"

export default function useHistoricalData(data, convertToUnits = (fromUnit, toUnit, val) => val) {
    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const convert = (val) => +convertToUnits("metric", units, val)

    return useMemo(() => {
        const candlesticks = {
            type: "candlestick",
            color: (pointIndex, groupIndex, candleIndex) => {
                return ["#1C9690", "#EE6900"][groupIndex]
            },
            points: [],
        }

        const line = {
            type: "line",
            color: "#8F0F9E",
            points: [],
        }

        data?.historical?.observation &&
            Object.keys(data.historical.observation).map((key) => {
                line.points.push({
                    x: new Date(key, 0, 1),
                    y: convert(data.historical.observation[key]),
                })
            })

        const years = {}

        let maxValue = Number.MIN_SAFE_INTEGER
        let minValue = Number.MAX_SAFE_INTEGER

        data?.historical &&
            data?.historical["post-processed"] &&
            Object.keys(data?.historical["post-processed"]).map((key) => {
                if (!years[key]) years[key] = []
                const values = data?.historical["post-processed"][key]

                const item95 = convert(values["0.95"])
                const item05 = convert(values["0.05"])

                if (maxValue < item95) maxValue = item95
                if (minValue > item05) minValue = item05

                years[key].push({
                    x: new Date(`Jan 1, ${key}`),
                    y0: item05,
                    y1: item95,
                    yMax: convert(values["0.75"]),
                    yMid: convert(values["0.5"]),
                    yMin: convert(values["0.25"]),
                })
            })

        data?.historical &&
            data?.historical["raw"] &&
            Object.keys(data?.historical["raw"]).map((key) => {
                if (!years[key]) years[key] = []
                const values = data?.historical["raw"][key]

                const item95 = convert(values["0.95"])
                const item05 = convert(values["0.05"])

                if (maxValue < item95) maxValue = item95
                if (minValue > item05) minValue = item05

                years[key].push({
                    x: new Date(`Jan 1, ${key}`),
                    y0: item05,
                    y1: item95,
                    yMax: convert(values["0.75"]),
                    yMid: convert(values["0.5"]),
                    yMin: convert(values["0.25"]),
                })
            })

        Object.keys(years).map((key) => {
            candlesticks.points.push({
                x: new Date(key, 0, 1),
                values: years[key],
            })
        })

        const chartData = [candlesticks, line]
        let tableData = (data?.evaluation && Object.values(data.evaluation).reverse()) || []

        return { chartData, tableData, maxValue, minValue }
    }, [data])
}

const ExcessiveSolarRadiationRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7Z"
                fill={props.fill}
            />
            <path d="M11 1V5L13 5L13 1L11 1Z" fill={props.fill} />
            <path d="M11 23L13 23V19H11L11 23Z" fill={props.fill} />
            <path d="M19 11V13H23V11L19 11Z" fill={props.fill} />
            <path d="M5 11L1 11L1 13H5V11Z" fill={props.fill} />
            <path d="M6.34 16.24L3.87 18.71L5.28 20.12L7.75 17.65L6.34 16.24Z" fill={props.fill} />
            <path d="M18.72 3.87L16.25 6.34L17.66 7.75L20.13 5.28L18.72 3.87Z" fill={props.fill} />
            <path
                d="M22.9999 22.9999V16.9999L20.7071 19.2928L17.6568 16.2426L16.2426 17.6568L19.2928 20.707L16.9999 22.9999H22.9999Z"
                fill={props.fill}
            />
            <path d="M5 1H3V3H1V5H3V7H5V5H7V3H5V1Z" fill={props.fill} />
        </svg>
    )
}

export default ExcessiveSolarRadiationRiskIcon

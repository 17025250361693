export const companyLogosObject = {
    ibm: {
        image: "/images/ibm_logo.png",
        url: "https://www.ibm.com",
    },
    meteoblue: {
        image: "/images/meteoblue_logo.png",
        url: "https://www.meteoblue.com",
    },
    openWeather: {
        image: "/images/open-weather_logo.png",
        url: "https://openweathermap.org/",
    },
    spire: {
        image: "/images/spire_logo.svg",
        url: "https://spire.com/",
    },
    clearAg: {
        image: "/images/clearag_logo.jpg",
        url: "https://www.dtn.com/agriculture/agribusiness/clearag/",
    },
    ecmwf: {
        image: "/images/ecmwf_logo.png",
        url: "https://www.ecmwf.int/",
    },
    noaa: {
        image: "/images/noaa_logo.jpg",
        url: "https://www.noaa.gov/",
    },
}

import { useMemo, useRef, useState, useEffect, useContext } from "react"
import { CircularProgress } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"
import { formatLineChartData } from "./helper"
import "./index.css"

import {
    addDays,
    addMonths,
    convertToShadedRangesFormat,
    getMonthsBetween,
    getLastDayOfMonth,
    getLastDayOfWeek,
    getFirstDayOfMonth,
    getFirstDayOfWeek,
} from "../../../../helpers/chartHelpers"

import ChartSpecs from "../ChartSpecs"
import networking from "../../../../Util/Networking"
import http from "../../../../Util/http"
import { AuthContext } from "../../../../Auth/Auth"
import { SettingsContext } from "../../../../Util/SettingsContext"
import useLastUpdated from "../../../../hooks/useLastUpdated"
import { ToastContext } from "../../../../components/Toast/ToastContextProvider"
import { convertFromCelsiusNum, convertTemperatureValueOnly } from "../../../../Util/UnitConversion"

const ChillHoursChart = ({ actionsState }) => {
    const chartRef = useRef(null)
    const { currentUser } = useContext(AuthContext)
    const { currentSettings } = useContext(SettingsContext)
    const { enqueueError, enqueueNetworkError } = useContext(ToastContext)

    const [confidenceVisible, setConfidenceBarsVisibility] = useState(false)

    const { id } = useParams()
    const [fieldId, setFieldId] = useState()
    const [userToken, setUserToken] = useState()
    const [chSettings, setChSettings] = useState({
        startDate: null,
        endDate: null,
        lowTemp: null,
        highTemp: null,
    })

    const [chSettingsTempError, setChSettingsTempError] = useState({
        flag: false,
        error: "",
    })

    const [csvMonthlyData, setCsvMonthlyData] = useState()

    const [initialView, setInitialView] = useState(false)

    const [data, setData] = useState({
        data: null,
        pending: true,
    })

    // Alerts Data
    const [alertsData, setAlertsData] = useState({
        ch_sum: {},
    })

    // Prepare initial data
    const weatherVariable = "chill_hours"

    const getXDateMax = (data) => {
        if (!data?.data?.time) return null
        let result = new Date(data.data.time[data.data.time.length - 1])
        const firstDate = new Date(data.data.time[0])
        const months = getMonthsBetween(firstDate, result)
        if (months <= 2) {
            // End of week
            result = addDays(getLastDayOfWeek(result), 0)
        } else {
            // End of day
            result = addDays(getLastDayOfMonth(result), 1)
        }
        return result
    }

    const getXDateMin = (data) => {
        if (!data?.data?.time) return null
        const lastDate = new Date(data.data.time[data.data.time.length - 1])
        let result = new Date(data.data.time[0])
        const months = getMonthsBetween(result, lastDate)
        if (months <= 2) {
            // End of week
            result = addDays(getFirstDayOfWeek(result), 0)
        } else {
            // End of day
            result = addDays(getFirstDayOfMonth(result), 0)
        }
        return result
    }

    const getXTicksCount = (data) => {
        if (!data?.data?.time) return null
        const lastDate = new Date(data.data.time[data.data.time.length - 1])
        let result = new Date(data.data.time[0])
        const months = getMonthsBetween(result, lastDate)
        if (months > 6) {
            return 12
        }
        return 6
    }

    const isTickCentered = (data) => {
        if (!data?.data?.time) return true
        const lastDate = new Date(data.data.time[data.data.time.length - 1])
        let result = new Date(data.data.time[0])
        const months = getMonthsBetween(result, lastDate, true)
        if (months <= 2) {
            return false
        }
        return true
    }

    const getChillHoursData = (settings, userToken) => {
        const start_month = settings.startDate || 10
        const end_month = settings.endDate || 3
        const lowTemp = convertTemperatureValueOnly(currentSettings.units, "metric", settings.lowTemp || 0)
        const highTemp = convertTemperatureValueOnly(currentSettings.units, "metric", settings.highTemp || 7)
        networking
            .get(
                `/api/v1/weather/${weatherVariable}/daily/${id}?end_month=${end_month}&start_month=${start_month}&low_temp=${lowTemp}&high_temp=${highTemp}&quantiles=vigintiles&datasets=forecast`,
                {
                    extraHeaders: { "User-Token": userToken },
                }
            )
            .then((res) => {
                setData({ data: res.data.ds_fc, pending: false })
                setCsvMonthlyData(
                    res.data.ds_fc.time.map((time, i) => {
                        return [
                            [
                                new Date(time).toLocaleDateString(undefined, {
                                    month: "short",
                                    year: "numeric",
                                    day: "numeric",
                                }),
                            ],
                            [
                                Array.isArray(res.data.ds_fc.ch_sum)
                                    ? res.data.ds_fc.ch_sum[i]
                                    : res.data.ds_fc.ch_sum[0.5][i],
                            ],
                        ]
                    })
                )
            })
            .catch(() => {
                enqueueNetworkError()
            })
    }

    const postChillHourSettings = () => {
        let settings = { ...chSettings }
        if (!validateRange(settings.lowTemp, settings.highTemp)) {
            return
        }
        setInitialView(false)
        setData({ data: null, pending: true })
        http.post(`/api/v1/settings/${weatherVariable}/${id}`, {
            extraHeaders: { "User-Token": userToken },
            start_month: settings.startDate,
            end_month: settings.endDate,
            low_temp: parseFloat(convertTemperatureValueOnly(currentSettings.units, "metric", settings.lowTemp)),
            high_temp: parseFloat(convertTemperatureValueOnly(currentSettings.units, "metric", settings.highTemp)),
        }).then((res) => {
            setChSettings(settings)
            getChillHoursData(settings, userToken)
        })
    }

    const validateRange = (low, high) => {
        if (low >= high) {
            setChSettingsTempError({ flag: true, error: "Low temperature should be lower than High temperature" })
            return false
        }
        return true
    }

    const updateChillHoursSettings = (settings) => {
        setChSettings({ ...settings })
        let validRange = validateRange(settings.lowTemp, settings.highTemp)
        if (validRange) {
            setChSettingsTempError({ flag: false, error: "" })
        }
    }

    // Load data
    useEffect(() => {
        if (!currentSettings.loading) {
            if (fieldId !== id) {
                setData({ data: null, pending: true })

                currentUser.getIdToken().then((userToken) => {
                    setUserToken(userToken)

                    networking
                        .get(`/api/v1/settings/${weatherVariable}/${id}`, {
                            extraHeaders: { "User-Token": userToken },
                        })
                        .then(({ data }) => {
                            const settings = {
                                startDate: data.start_month || 10,
                                endDate: data.end_month || 3,
                                lowTemp: convertTemperatureValueOnly(
                                    "metric",
                                    currentSettings.units,
                                    data.low_temp || 0
                                ),
                                highTemp: convertTemperatureValueOnly(
                                    "metric",
                                    currentSettings.units,
                                    data.high_temp || 7
                                ),
                            }
                            // Set last day of the month
                            setChSettings(settings)
                            getChillHoursData(settings, userToken)
                        })

                    // Alerts Data
                    networking
                        .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
                            extraHeaders: { "User-Token": userToken },
                        })
                        .then((res) => {
                            setAlertsData(res.data)
                        })
                })
                setFieldId(id)
            }
        }
    }, [currentUser, id, currentSettings.loading])

    const lastUpdated = useLastUpdated(data.data)

    return (
        <div className="weather-chart">
            <div className="weather-chart__chart-container">
                <div style={{ display: initialView ? "flex" : "none" }} className="weather-chart__preload-container">
                    <div className="chill-hours-initial-view">
                        <div>
                            <svg
                                width="96"
                                height="96"
                                viewBox="0 0 96 96"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M24.68 52L8 52L8 44L24.68 44L11.72 31.04L17.36 25.36L36 44L44 44L44 36L25.36 17.36L31.04 11.72L44 24.68L44 8L52 8L52 24.68L52 36L52 44L52 52L52 60L52 71.32L52 88L44 88L44 71.32L31.04 84.28L25.36 78.64L44 60L44 52L36 52L17.36 70.64L11.72 64.96L24.68 52Z"
                                    fill="#B3B6BA"
                                />
                                <path
                                    d="M60 86.1687C76.2284 81.0716 88 65.9105 88 47.9999C88 30.0893 76.2284 14.9282 60 9.83111L60 18.326C62.7748 19.4492 65.3562 20.9503 67.6804 22.7655L61.9661 28.4798C64.1472 30.0431 66.0555 31.9629 67.6057 34.154L73.3244 28.4353C76.7401 32.8501 79.027 38.1826 79.7524 44L71.6682 44C71.8864 45.3008 72 46.6371 72 48C72 49.3628 71.8864 50.6992 71.6682 52L79.7524 52C79.0238 57.8428 76.72 63.1965 73.2794 67.6225L67.563 61.9061C66.0062 64.0922 64.0922 66.0062 61.9061 67.563L67.6225 73.2794C65.3134 75.0744 62.7518 76.5599 60 77.6738L60 86.1687Z"
                                    fill="#B3B6BA"
                                />
                            </svg>
                        </div>
                        <h4 className="chill-hours-initial-view__header">
                            Please set a timeframe to calculate chill hours
                        </h4>
                        <div>
                            <span className="chill-hours-initial-view__body ">
                                Specify the start and end month of the chill season you want to forecast
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    style={{ display: data.pending && !initialView ? "flex" : "none" }}
                    className="weather-chart__preload-container"
                >
                    <CircularProgress />
                </div>
                <LineAreaChartComponent
                    // Pass height externally
                    svgHeight={650}
                    // Title text
                    title="Chill Hours"
                    // Set title hover text
                    titleHover={
                        {
                            monthly: `This graph shows information about chill hours. `,
                        }[actionsState.selectedGranularity]
                    }
                    // Y label text
                    labelY="Chill Hours"
                    //Convert received data to shaded ranges format
                    shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData))}
                    // Pass unique resize event key
                    resizeEventListenerId="chill_hours-chart"
                    // Add chart data id to filter out some update requests
                    chartDataId={
                        {
                            monthly: `day`,
                        }[actionsState.selectedGranularity] +
                        `${weatherVariable}-chart_` +
                        data.length
                    }
                    // Center Ticks

                    centerTicks={
                        {
                            monthly: isTickCentered(data),
                        }[actionsState.selectedGranularity]
                    }
                    // Make chart to have dynamic y basis
                    zeroBasis={true}
                    // Bottom margin will be 0.2 times of data diff
                    yBottomOffset={0.2}
                    // Top margin will be 0.3 times of data diff
                    yTopOffset={0.3}
                    // Provide custom date max axis extent for monthly view charts
                    xDateMax={
                        {
                            monthly: getXDateMax(data),
                        }[actionsState.selectedGranularity]
                    }
                    // Provide custom date min axis extent for monthly view charts
                    xDateMin={
                        {
                            monthly: getXDateMin(data),
                        }[actionsState.selectedGranularity]
                    }
                    // How x ticks will be formatted in chart
                    xTickFormat={
                        {
                            monthly: (d, i, arr) => {
                                const min = arr[0].__data__
                                const max = arr[arr.length - 1].__data__
                                const months = getMonthsBetween(min, max)
                                // Remove last, overflowing tick item
                                if (i === arr.length - 1) return ""
                                if (months > 2) {
                                    return d.toLocaleString(undefined, { month: "short" })
                                }
                                return d.toLocaleString(undefined, { month: "short", day: "2-digit" })
                            },
                        }[actionsState.selectedGranularity]
                    }
                    xTicksCount={
                        {
                            monthly: getXTicksCount(data),
                        }[actionsState.selectedGranularity]
                    }
                    // Hide chart if data is pending
                    hide={data.pending}
                    // Tooltip content on line points mouse over
                    tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                        let prec = 100
                        let hour = undefined
                        let day = "numeric"
                        let month = "short"
                        let h = ""
                        let titleWidth = 40
                        let titleFontSize = 18

                        let dateStr = key.toLocaleString(undefined, { day, month, hour })

                        if (actionsState.selectedGranularity === "monthly") {
                            dateStr = key.toLocaleString(undefined, { month: "short", day: "numeric", year: "numeric" })
                        }

                        return `
                        <div style="padding:10px;">
                        <div style="width:100%;color:black;font-size:${titleFontSize}px;margin-bottom:10px;">
                           <div style="padding-right: 12px; margin-right:14px;width:100%;line-height:1.1">${dateStr}</div>
                           <div style="font-size:14px;color:#7B8399">
                            ${points[0].dashed ? "Forecast" : "Observed"}
                           </div>
                           
                        </div> 
                        <table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-bottom:none">
                 <tr>
                     <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                         points[0].dashed ? "dashed" : "solid"
                     } ${
                            colors[0]
                        };margin-top:-10px;border-radius:5px;"></div>Total Chill Hours  <div style="margin-left:10px;display:inline-block;color:black;"> ${
                            Math.round(values[0] * 100) / 100
                        }</div>
                     </td>
                 </tr>
             </table>
             </div>`
                    }}
                    // Chart data content
                    data={[
                        // Confidence Bands
                        ["monthly"].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: formatLineChartData({
                                      data: data.data,
                                      today: new Date(),
                                  })
                                      .filter((d) => d.dashed)
                                      .map((d) => ({
                                          x: d.x,
                                          min: d.y05,
                                          max: d.y95,
                                      })),
                                  color: "#8AB8DC",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,
                        ["monthly"].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: formatLineChartData({
                                      data: data.data,
                                      today: new Date(),
                                  })
                                      .filter((d) => d.dashed)
                                      .map((d) => ({
                                          x: d.x,
                                          min: d.y75,
                                          max: d.y25,
                                      })),
                                  color: "#498CC3",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,
                        {
                            type: "line",
                            color: "#2288C1",
                            "stroke-width": 2.5,
                            points: formatLineChartData({
                                data: data.data,
                                today: new Date(),
                            }),
                        },
                    ]}
                ></LineAreaChartComponent>
            </div>
            <div className="weather-chart__specs-container">
                <ChartSpecs
                    type="chill_hours"
                    data={{
                        csv: csvMonthlyData,
                        // hourlyCsv: mergeHistoricalAndForecastData({
                        //     forecast: hourlyData.ds_fc,
                        //     historical: hourlyData.ds_hist,
                        // }),
                    }}
                    selectedGranularity={actionsState.selectedGranularity}
                    chSettings={chSettings}
                    chSettingsErrors={chSettingsTempError}
                    onDateRangesSet={(startDate, endDate) => {
                        let newSettings = chSettings
                        newSettings.startDate = parseFloat(startDate)
                        newSettings.endDate = parseFloat(endDate)
                        setChSettings({ ...newSettings })
                        postChillHourSettings()
                    }}
                    onLimitChange={(lowTemp, highTemp) => {
                        let newSettings = chSettings
                        newSettings.lowTemp = parseFloat(lowTemp) || 0
                        newSettings.highTemp = parseFloat(highTemp) || 0
                        updateChillHoursSettings({ ...newSettings })
                    }}
                    onSubmitChillHoursSettings={postChillHourSettings}
                    chartRef={chartRef}
                    lastUpdated={lastUpdated}
                    actionsState={actionsState}
                    confidenceVisible={confidenceVisible}
                    handleAreasVisibilityChange={({ conf, clim }) => {
                        setConfidenceBarsVisibility(conf)
                    }}
                />
            </div>
        </div>
    )
}

export default ChillHoursChart

import HighWindDayRiskIcon from "../ui/Icons/newIcons/riskIcons/HighWindDayRiskIcon"
import {
    ChillHoursIcon,
    GrowingDegreeDaysIcon,
    PrecipitationIcon,
    RelativeHumidityIcon,
    TemperatureIcon,
    MinTemperatureIcon,
    MaxTemperatureIcon,
    WindSpeedIcon,
    SoilTemperatureIcon,
    SolarRadiationIcon,
    EvapotranspirationIcon,
} from "../ui/Icons/newIcons/weatherVariableIcons"

export default function getVariableIcon(variable) {
    switch (variable) {
        case "t2m": {
            return TemperatureIcon
        }
        case "t2m_min": {
            return MinTemperatureIcon
        }
        case "t2m_max": {
            return MaxTemperatureIcon
        }
        case "tp": {
            return PrecipitationIcon
        }
        case "ws": {
            return WindSpeedIcon
        }
        case "rh": {
            return RelativeHumidityIcon
        }
        case "gdd": {
            return GrowingDegreeDaysIcon
        }
        case "ch": {
            return ChillHoursIcon
        }
        case "ws_max": {
            return HighWindDayRiskIcon
        }
        case "soil_temperature": {
            return SoilTemperatureIcon
        }
        case "solar_radiation": {
            return SolarRadiationIcon
        }
        case "evapotranspiration": {
            return EvapotranspirationIcon
        }
        default: {
            return null
        }
    }
}

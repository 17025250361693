// Import React
import { useContext, useState } from "react"
// Import Components
import { AuthContext } from "../../Auth/Auth"
import AddLocationsForm from "./components/AddLocationsForm"
import AddLocationsMap from "./components/AddLocationsMap"
import BulkUploadCSVParser from "./components/BulkUploadCSVParser"
import Papa from "papaparse"
import { isEmptyString, generateLabelStructure, TEMPLATE_CSV_DATA } from "./AddLocationsUtil"
import { addTableRow } from "../../services/table.service"

const AddLocations = () => {
    const { featurePermissions, currentUser } = useContext(AuthContext)

    const [mapPinCoords, setMapPinCoords] = useState({ lat: 19.2719, lon: -99.0954 })
    const [parsedBulkUploadData, setParsedBulkUploadData] = useState(undefined)

    const { skin } = window.dashboard

    if (skin === "skip") {
        // Toggle skip payment flow if a skip user navigates to /weather/add-locations
        window.open("https://skip.payment.climate.ai/input_field/" + currentUser.uid, "_self")
    }

    const handleManualCoordChange = (coords) => {
        setMapPinCoords({ lat: Number(coords.lat), lon: Number(coords.lon) })
    }

    function setNewBulkUploadFile(results, successAction) {
        // First, lets get rid of empty rows (empty lines on CSV file)
        for (let i = 0; i < results.data.length; i++) {
            let rowData = results.data[i].join("")
            if (isEmptyString(rowData) || rowData === TEMPLATE_CSV_DATA[0].join("")) {
                // The entire row is empty, or is the first row of the template (header row). We can ignore it
                // Remove it and backtrack iterator
                results.data.splice(i, 1)
                i--
            }
        }

        setParsedBulkUploadData(results)

        // File read successfully!
        successAction()
    }

    const handleBulkUploadFileChange = (newFile, successAction) => {
        // Parses CSV and calls the complete arrow function when done, passing in the results
        let parseConfig = { complete: (results, _) => setNewBulkUploadFile(results, successAction) }
        Papa.parse(newFile, parseConfig)
    }

    const handleImportLocations = async (data, uploadStatusCallback, doneUploadingCallback) => {
        // Generate loc array with correct structure so that it fits the endpoint's standards
        let locationsToAdd = []
        let status = { rows: [], current: 0 }
        await Promise.all(data.map(async (rowData) => {
            let newLocation = {}
            newLocation["name"] = rowData[0].value
            newLocation["field_center"] = { lat: Number(rowData[1].value), lon: Number(rowData[2].value) }
            newLocation["region"] = rowData[3].value
            newLocation["crop"] = rowData[4].value
            newLocation["crop_id"] = rowData[4].id
            newLocation["variety"] = rowData[5].value
            newLocation["variety_id"] = rowData[5].id
            newLocation["plantingDateP"] = rowData[6].value
                ? new Date(rowData[6].value).toISOString().split("T")[0]
                : "" // Set data format correctly
            newLocation["labels"] = await generateLabelStructure(rowData[7].value.split(", "))
            locationsToAdd.push(newLocation)
            status.rows.push("on queue") // So that these rows don't show as loading yet
        }))

        // Update status
        uploadStatusCallback(status)

        // Start the upload sequence
        uploadNextLocation(status, locationsToAdd, 0, uploadStatusCallback, doneUploadingCallback)
    }

    async function uploadNextLocation(status, locations, index, uploadStatusCallback, doneUploadingCallback) {
        // Check for end of upload sequence
        if (index === status.rows.length) {
            // We're done, hit the end of sequence callback and return early
            doneUploadingCallback(status)
            return
        }

        // Grab current loc
        let location = locations[index]

        // Update current index
        status.current = index
        // Set loc status to waiting, so that the loading circle starts spinning on the table
        status.rows[index] = "waiting"
        // Update status
        uploadStatusCallback(status)

        // Hit the endpoint to add the loc
        let res = await addTableRow(location).catch((_) => {
            // There was a backend problem; Code from the bottom of the function will not be hit
            // Continue process from the catch statement
            // Set the status text
            status.rows[index] = "error"

            // Update status
            uploadStatusCallback(status)

            // Upload next location
            uploadNextLocation(status, locations, index + 1, uploadStatusCallback, doneUploadingCallback)
        })

        // Get out if we didnt get a response
        if (res === undefined) return

        // Set the status text
        if (res.status < 200 && res.status > 299) status.rows[index] = "error"
        else status.rows[index] = res.status

        // Update status
        uploadStatusCallback(status)

        // Upload next location
        uploadNextLocation(status, locations, index + 1, uploadStatusCallback, doneUploadingCallback)
    }

    const handleAddSingleLocation = async (location, uploadStatusCallback, doneUploadingCallback, errorCallback) => {
        // Prepare loc object so that it fits the endpoint's standards
        location.crop_id = Number(location.cropId)
        location.variety_id = Number(location.varietyId)
        location.field_center = { lat: Number(location.field_center.lat), lon: Number(location.field_center.lon) }

        delete location.cropId
        delete location.varietyId

        // Update status
        uploadStatusCallback("waiting")

        // Hit endpoint to add location
        let res = await addTableRow(location)

        // On error, hit the error callback
        if (res.status < 200 && res.status > 299) errorCallback()
        // On success, hit the done callback
        else doneUploadingCallback()
    }

    const handleCancel = () => {
        setParsedBulkUploadData(undefined)
    }

    return (
        <div className="flex flex-col justify-items-center mx-0 h-full z-0">
            <AddLocationsForm
                doShow={true}
                onCancel={() => {
                    return
                }}
                onSave={handleAddSingleLocation}
                onUpdate={() => {
                    return
                }}
                pinCoords={mapPinCoords}
                onManualCoordChange={handleManualCoordChange}
                onBulkUploadFileChange={handleBulkUploadFileChange}
                canEditDatasource={featurePermissions?.datasource?.edit || false}
                refreshBulkUploadFile={parsedBulkUploadData === undefined}
            />
            <div className="ml-[340px] h-full">
                <AddLocationsMap setCoords={setMapPinCoords} coords={mapPinCoords} isAddingEditing={true} />
            </div>
            <BulkUploadCSVParser
                data={parsedBulkUploadData ? parsedBulkUploadData.data : undefined}
                handleImportLocations={handleImportLocations}
                handleCancel={handleCancel}
            />
        </div>
    )
}

export default AddLocations

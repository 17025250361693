import { useState, useEffect, useContext } from "react"
import DepartureAside from "./components/DepartureAside"
import DepartureChart from "./components/DepartureChart"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import useDepartureData from "../../hooks/useDepartureData"
import { ToastContext } from "../../components/Toast/ToastContextProvider"

function Departure() {
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState({})
    const [pastq, setPastq] = useState(0.1)
    const [futureq, setFutureq] = useState(0.5)

    const { enqueueNetworkError } = useContext(ToastContext)

    const {
        ready,
        //
        allLocations: locations,
        selectedLocation,
        setSelectedLocation,
        //
        scenarios,
        selectedScenario,
        setSelectedScenario,
        //
        variables,
        selectedVariable,
        setSelectedVariable,
    } = useAdaptationValues()

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?scenario=${selectedScenario}&location=${selectedLocation}&var=${selectedVariable}`

        const request = http.get(`/api/v1/adapt/v2/departure${query}`, { timeout: 180000 })
        request
            .then((response) => {
                // thresholds fix
                const result = {}
                for (let key in response.data.threshold) {
                    const value = response.data.threshold[key]
                    const [pq, fq] = JSON.parse(key)
                    result[JSON.stringify([Math.round(pq * 100) / 100, Math.round(fq * 100) / 100])] = value
                }

                setData({ x: response.data.data.x, y: response.data.data.y, threshold: result })
            })
            .catch((err) => {
                console.log(err)
                enqueueNetworkError()
            })
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedLocation, selectedVariable, selectedScenario])

    // in case tp was previously selected
    useEffect(() => {
        if (selectedVariable === "tp") setSelectedVariable("t2m")
    }, [selectedVariable])

    const { chartData, threshold } = useDepartureData(
        data,
        pastq,
        futureq,
        ready ? variables[selectedVariable].convertToUnits : null
    )

    return (
        <>
            {/* <ReportDownloadButton
                text={`Climate Tipping Point / ${selectedLocation} / ${selectedVariableObj.label} / ${selectedScenarioObj.label}`}
            /> */}
            <div className="overview" id="departure-section">
                <div className="overview__main">
                    <DepartureChart
                        data={chartData}
                        threshold={threshold}
                        futureq={futureq}
                        pastq={pastq}
                        loading={loading}
                        // locations dropdown
                        locations={locations}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        // scenarios dropdown
                        scenarios={scenarios}
                        selectedScenario={selectedScenario}
                        setSelectedScenario={setSelectedScenario}
                        // variables dropdown
                        variables={variables}
                        selectedVariable={selectedVariable}
                        setSelectedVariable={setSelectedVariable}
                    />
                </div>
                <div className="overview__aside">
                    <DepartureAside
                        loading={loading}
                        threshold={threshold}
                        futureq={futureq}
                        pastq={pastq}
                        setFutureq={setFutureq}
                        setPastq={setPastq}
                    />
                </div>
            </div>
        </>
    )
}

export default Departure

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

const getHistoricalMinTemp = (historical) =>
    historical.time.map((item, index) => ({
        x: new Date(item).getTime(),
        y: historical["t2m_min"][index],
    }))

const getHistoricalMaxTemp = (historical) =>
    historical.time.map((item, index) => ({
        x: new Date(item).getTime(),
        y: historical["t2m_max"][index],
    }))

const getHistoricalMeanTemp = (historical) =>
    historical.time.map((item, index) => ({
        x: new Date(item).getTime(),
        y: historical["t2m_mean"][index],
    }))

const getMinY = (historicalMinTemp) => Math.min(...historicalMinTemp.map((d) => d.y))
const getMaxY = (historicalMaxTemp) => Math.max(...historicalMaxTemp.map((d) => d.y))

const getForecastMinArr = (forecast) =>
    "-1.0" in forecast["t2m_min"] ? forecast["t2m_min"]["-1.0"] : forecast["t2m_min"]["0.5"]
const getForecastMaxArr = (forecast) =>
    "-1.0" in forecast["t2m_max"] ? forecast["t2m_max"]["-1.0"] : forecast["t2m_max"]["0.5"]
const getForecastMeanArr = (forecast) => {
    return "-1.0" in forecast["t2m_mean"] ? forecast["t2m_mean"]["-1.0"] : forecast["t2m_mean"]["0.5"]
}

const getForecastMinTemp = (forecast, lastHistoricalPoint, forecastMinArr) =>
    forecast.time.map((item, index) => {
        if (!index) {
            return {
                x: new Date(item).getTime(),
                y: lastHistoricalPoint.y,
            }
        } else {
            return {
                x: new Date(item).getTime(),
                y: forecastMinArr[index],
            }
        }
    })

const getForecastMaxTemp = (forecast, lastHistoricalPoint, forecastMaxArr) =>
    forecast.time.map((item, index) => {
        if (!index) {
            return {
                x: new Date(item).getTime(),
                y: lastHistoricalPoint.y,
            }
        } else {
            return {
                x: new Date(item).getTime(),
                y: forecastMaxArr[index],
            }
        }
    })

const getForecastMeanTemp = (forecast, lastHistoricalPoint, forecastMeanArr) =>
    forecast.time.map((item, index) => {
        if (!index) {
            return {
                x: new Date(item).getTime(),
                y: lastHistoricalPoint.y,
            }
        } else {
            return {
                x: new Date(item).getTime(),
                y: forecastMeanArr[index],
            }
        }
    })

const getClimMin = (clim) => {
    const climMinLightenY0Arr = []
    const climMinLightenY1Arr = []
    const climMinDarkenY0Arr = []
    const climMinDarkenY1Arr = []

    for (let key in clim["t2m_min"]) {
        if (+key === 0.05) {
            climMinLightenY0Arr.push(...clim["t2m_min"][key])
        } else if (+key === 0.95) {
            climMinLightenY1Arr.push(...clim["t2m_min"][key])
        } else if (+key === 0.25) {
            climMinDarkenY0Arr.push(...clim["t2m_min"][key])
        } else if (+key === 0.75) {
            climMinDarkenY1Arr.push(...clim["t2m_min"][key])
        }
    }

    const climMinLighten = clim.time.map((item, index) => ({
        x: new Date(item).getTime(),
        y0: climMinLightenY0Arr[index],
        y: climMinLightenY1Arr[index],
    }))

    const climMinDarken = clim.time.map((item, index) => ({
        x: new Date(item).getTime(),
        y0: climMinDarkenY0Arr[index],
        y: climMinDarkenY1Arr[index],
    }))

    return {
        climMinLighten,
        climMinDarken,
    }
}

const getClimMax = (clim) => {
    const climMaxLightenY0Arr = []
    const climMaxLightenY1Arr = []
    const climMaxDarkenY0Arr = []
    const climMaxDarkenY1Arr = []

    for (let key in clim["t2m_max"]) {
        if (+key === 0.05) {
            climMaxLightenY0Arr.push(...clim["t2m_max"][key])
        } else if (+key === 0.95) {
            climMaxLightenY1Arr.push(...clim["t2m_max"][key])
        } else if (+key === 0.25) {
            climMaxDarkenY0Arr.push(...clim["t2m_max"][key])
        } else if (+key === 0.75) {
            climMaxDarkenY1Arr.push(...clim["t2m_max"][key])
        }
    }

    const climMaxLighten = clim.time.map((item, index) => {
        return {
            x: new Date(item).getTime(),
            y0: climMaxLightenY0Arr[index],
            y: climMaxLightenY1Arr[index],
        }
    })

    const climMaxDarken = clim.time.map((item, index) => {
        return {
            x: new Date(item).getTime(),
            y0: climMaxDarkenY0Arr[index],
            y: climMaxDarkenY1Arr[index],
        }
    })

    return {
        climMaxLighten,
        climMaxDarken,
    }
}

const getClimMean = (clim) => {
    const climMeanLightenY0Arr = []
    const climMeanLightenY1Arr = []
    const climMeanDarkenY0Arr = []
    const climMeanDarkenY1Arr = []
    if (clim["t2m_mean"] === undefined) {
        // NO MEAN TEMP INFO
        return { climMeanLighten: [], climMeanDarken: [] }
    }
    for (let key in clim["t2m_mean"]) {
        if (+key === 0.05) {
            climMeanLightenY0Arr.push(...clim["t2m_mean"][key])
        } else if (+key === 0.95) {
            climMeanLightenY1Arr.push(...clim["t2m_mean"][key])
        } else if (+key === 0.25) {
            climMeanDarkenY0Arr.push(...clim["t2m_mean"][key])
        } else if (+key === 0.75) {
            climMeanDarkenY1Arr.push(...clim["t2m_mean"][key])
        }
    }

    const climMeanLighten = clim.time.map((item, index) => {
        return {
            x: new Date(item).getTime(),
            y0: climMeanLightenY0Arr[index],
            y: climMeanLightenY1Arr[index],
        }
    })

    const climMeanDarken = clim.time.map((item, index) => {
        return {
            x: new Date(item).getTime(),
            y0: climMeanDarkenY0Arr[index],
            y: climMeanDarkenY1Arr[index],
        }
    })

    return {
        climMeanLighten,
        climMeanDarken,
    }
}

const trimmData = (data) =>
    data.filter((item) => {
        const minX = new Date().getTime() - 12 * 24 * 60 * 60 * 1000
        const maxX = new Date().getTime() + 14 * 24 * 60 * 60 * 1000
        return minX <= item.x && item.x <= maxX
    })

const minX = new Date().getTime() - 12 * 24 * 60 * 60 * 1000
const maxX = new Date().getTime() + 14 * 24 * 60 * 60 * 1000

export {
    monthNames,
    getHistoricalMinTemp,
    getHistoricalMaxTemp,
    getHistoricalMeanTemp,
    getMinY,
    getMaxY,
    getForecastMinTemp,
    getForecastMaxTemp,
    getForecastMeanTemp,
    getClimMin,
    getClimMax,
    getClimMean,
    getForecastMinArr,
    getForecastMaxArr,
    getForecastMeanArr,
    trimmData,
    minX,
    maxX,
}

import React from "react"

function PopoverTarget(props) {
    const { targetId = "", children } = props

    return (
        <>
            <div id={`${targetId}__popover-target`} className="popover__target">
                {children}
            </div>
        </>
    )
}

export default PopoverTarget

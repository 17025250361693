import { useState, useEffect, useContext } from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import SidebarItem from "./SidebarItem"
import SidebarOptions from "./SidebarOptions"
import SidebarFieldList from "./SidebarFieldList"
import SideBarMobileToggler from "../../components/TopBar/SideBarMobileToggler"
import InfoIcon from "../Icons/newIcons/InfoIcon"
import UserManualIcon from "../Icons/Sidebar/UserManualIcon"
import { getTableData } from "../../services/table.service"
import { AuthContext } from "../../Auth/Auth"
import { DataContext } from "../../Util/Data"
import { ModalContext } from "../../components/Modal/ModalContextProvider"
import { CLIMATE_NAV, SEASONAL_NAV } from "./sidebarUtils"
import useField from "../../hooks/useField"
import useWindowDimensions from "../../hooks/useDimensions"
import "./Sidebar.css"
import Toggler from "../../components/Toggler"

const FIELD_LIST_CLOSE_SECTIONS = ["table-settings", "risk-profiles", "risk-categories", "user-settings"]

function Sidebar({ settings, climate, seasonal }) {
    const openFlag = JSON.parse(sessionStorage.getItem("@sidebar/open"))
    const [open, _toggle] = useState((openFlag === null && true) || openFlag)
    const [showToggle, setShowToggle] = useState(true)
    const [isFieldListOpen, setIsFieldListOpen] = useState(false)
    const { pathname } = useLocation()
    const history = useHistory()

    const { aboutModal } = useContext(ModalContext)
    const { permissions } = useContext(AuthContext)
    const {
        state: { fields },
        dispatch,
    } = useContext(DataContext)

    const field = useField()
    const { width } = useWindowDimensions()

    useEffect(() => {
        if (!fields || fields.length === 0) {
            getTableData()
                .then((fields) => {
                    dispatch({ type: "GET_FIELDS", payload: { fields } })
                    const polys = {}
                    fields.forEach((field) => (polys[field.uuid] = field.polygon))
                    dispatch({ type: "GET_POLYGONS", payload: { polygons: polys } })
                })
                .catch(console.log)
        }
    }, [])

    useEffect(() => {
        // force resize
        setTimeout(() => {
            let _event = new CustomEvent("force-resize", { bubbles: true })
            window.dispatchEvent(_event)
        }, 200)
    }, [open])

    // get active tab
    let separator = "/"
    if (settings) separator += (seasonal && "weather") || (climate && "climate")
    else {
        if (climate) separator += "climate"
        else if (seasonal) separator += "weather/" + field?.uuid
    }
    let view = pathname.split(separator)[1]
    view = (seasonal && !view && "/seasonal") || view

    const toggle = (value) => {
        if (isFieldListOpen) setIsFieldListOpen(false)
        _toggle(value)
        sessionStorage.setItem("@sidebar/open", JSON.stringify(value))
    }

    const toggleFieldsList = (e) => {
        if (!e) e = window.event
        e.cancelBubble = true
        if (e.stopPropagation) e.stopPropagation()
        setIsFieldListOpen((prevIsFieldListOpen) => !prevIsFieldListOpen)
        _toggle(true)
    }

    const OPTIONS =
        (climate && CLIMATE_NAV(settings)) ||
        (seasonal &&
            SEASONAL_NAV({ fieldId: field?.uuid || fields[0]?.uuid || "dashboard", openFieldList: toggleFieldsList }))
    // This will ensure that if the user clicks the Seasonal Model SideNav button from any settings page,
    // (and thus has no selected field), it will grab the first field on the list to display
    // If the user has no fields, then it will send the user to the overview page (dashboard)
    const { sidebarManual } = window.dashboard

    const isActive = (item, view) => {
        return (
            (item.view && item.view === view) ||
            (item.children && Array.isArray(item.children) && item.children.find((c) => c.view === view))
        )
    }

    useEffect(() => {
        if (isFieldListOpen && FIELD_LIST_CLOSE_SECTIONS.includes(pathname.split("/").pop())) setIsFieldListOpen(false)
    }, [pathname])

    useEffect(() => {
        if (width <= 1280) toggle(false)
    }, [])

    return (
        <div
            className={
                "absolute inset-y-0 left-0 z-full md:z-50 md:relative shrink-0 grow-0 bg-white elevation-1 border-r border-gray-10 transition-all" +
                (open || isFieldListOpen ? " w-[240px]" : " w-[0px] md:w-[68px]")
            }
            onMouseEnter={() => setShowToggle(true)}
            onMouseLeave={() => setShowToggle(false)}
        >
            {/* SIDENAV MOBILE BUTTON */}
            <SideBarMobileToggler isOpen={open} toggleFunction={() => toggle(!open)} />

            {/* SIDENAV TOGGLE BUTTON */}
            <div className="relative w-full overflow-visible z-full">
                <div
                    className={
                        "absolute -right-3 top-[24px]" +
                        (open ? (showToggle ? " block" : " md:hidden") : " hidden md:block")
                    }
                >
                    <Toggler open={open} toggle={toggle} openDirection="right" />
                </div>
            </div>

            <div className="flex flex-row h-full">
                <div
                    className={
                        "absolute left-0 shrink-0 h-full w-full " +
                        "transition-all duration-200 " +
                        (isFieldListOpen ? "w-0" : "")
                    }
                >
                    <div
                        className={
                            "relative h-full  pt-[12px] flex flex-col grow shrink-0" + (open ? " min-w-[240px]" : "")
                        }
                    >
                        {OPTIONS.map((item) => {
                            return (
                                <SidebarItem
                                    key={item.name}
                                    sidebarOpen={open}
                                    active={isActive(item, view)}
                                    icon={item.icon}
                                    nextIcon={item.nextIcon}
                                    nextIconClick={toggleFieldsList}
                                    name={item.name}
                                    onClick={(goToFirst) => {
                                        if (!open) toggle(true)
                                        if (item.to) history.push(item.to)
                                        else if (goToFirst && item.children && Array.isArray(item.children))
                                            history.push(item.children[0].to)
                                    }}
                                    hasChildren={item.children !== null && item.children !== undefined}
                                    disabled={item.disabled}
                                >
                                    {(item.children && Array.isArray(item.children) && (
                                        <SidebarOptions
                                            view={view}
                                            sidebarOpen={open}
                                            linkComponent={Link}
                                            items={
                                                (climate && item.children) ||
                                                (seasonal && item.settings && item.children) ||
                                                (seasonal &&
                                                    !item.settings &&
                                                    item.children.filter(
                                                        (i) => i.permission && permissions[i.permission]
                                                    ))
                                            }
                                        />
                                    )) ||
                                        (item.children && typeof item.children === "function" && item.children()) ||
                                        null}
                                </SidebarItem>
                            )
                        })}
                        <div className="navbar__bottom">
                            {sidebarManual && (
                                <SidebarItem
                                    sidebarOpen={open}
                                    active={false}
                                    icon={UserManualIcon}
                                    name="Manual"
                                    onClick={() => window.open(sidebarManual)}
                                />
                            )}
                            <SidebarItem
                                sidebarOpen={open}
                                active={false}
                                icon={InfoIcon}
                                name="About"
                                onClick={() => aboutModal(seasonal ? "seasonal" : climate ? "climate" : null)}
                            />
                        </div>
                    </div>
                </div>
                {seasonal && (
                    <div
                        className={
                            "absolute right-0 shrink-0 h-full w-full " +
                            "bg-white " +
                            "overflow-hidden " +
                            "transition-all duration-200 " +
                            (isFieldListOpen ? "" : "w-0")
                        }
                    >
                        <div className="min-w-[240px] relative h-full">
                            <SidebarFieldList fields={fields} closeFieldList={toggleFieldsList} view={view} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Sidebar

import { useState, useEffect, useContext } from "react"

import HistoricalChart from "./components/HistoricalChart"
import TableColumn from "../../ui/Table/TableColumn"
import SimpleTable from "../../ui/Table/SimpleTable"
import useHistoricalData from "../../hooks/useHistoricalData"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import ChartLabels from "../../components/LongTerm/ChartLabels/ChartLabels"
import ChartLabelItem from "../../components/LongTerm/ChartLabels/ChartLabelItem"
import { LineIcon } from "../Departure/components/DepartureChart"
import { ToastContext } from "../../components/Toast/ToastContextProvider"

function Historical() {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const { enqueueNetworkError } = useContext(ToastContext)

    const {
        ready,
        //
        allLocations: locations,
        selectedLocation,
        setSelectedLocation,
        //
        variables,
        selectedVariable,
        setSelectedVariable,
    } = useAdaptationValues()

    // Fetch info
    useEffect(() => {
        if (!ready) return
        if (!loading) setLoading(true)

        let query = `?scenario=usual&location=${selectedLocation}&var=${selectedVariable}`

        const request = http.get(`/api/v1/adapt/v2/validation${query}`, { timeout: 180000 })
        request
            .then((response) => {
                console.log(response.data)
                setData(response.data)
            })
            .catch((err) => {
                console.log(err)
                enqueueNetworkError()
            })
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedLocation, selectedVariable])

    const { chartData, tableData, maxValue, minValue } = useHistoricalData(
        data,
        variables[selectedVariable].convertToUnits
    )

    return (
        <div className="overview historical">
            <div className="overview__main">
                <HistoricalChart
                    maxValue={maxValue}
                    minValue={minValue}
                    loading={loading}
                    data={chartData}
                    // locations dropdown
                    locations={locations}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    // variables dropdown
                    variables={variables}
                    selectedVariable={selectedVariable}
                    setSelectedVariable={setSelectedVariable}
                >
                    <div>
                        <SimpleTable data={tableData} className="full" noHeaders noSummary>
                            <TableColumn
                                propName="desc"
                                displayName="Data Summary"
                                style={{ flex: 1, maxWidth: "initial" }}
                            />
                            <TableColumn
                                propName="raw"
                                displayName="Public Data Providers"
                                style={{ flex: 2 }}
                                render={(v) => v && v.toFixed(2)}
                            />
                            <TableColumn
                                propName="climateai"
                                displayName="ClimateAi"
                                style={{ flex: 2 }}
                                render={(v) => v && v.toFixed(2)}
                            />
                            <TableColumn
                                propName="improvement"
                                displayName="Improvement (%)"
                                style={{ flex: 2 }}
                                render={(v) => v + "%"}
                            />
                        </SimpleTable>
                    </div>
                    <ChartLabels>
                        <ChartLabelItem
                            renderIcon={() => (
                                <svg>
                                    <rect
                                        x="2"
                                        y="3"
                                        rx="2"
                                        width="15"
                                        height="15"
                                        strokeWidth="2"
                                        stroke={"#1C9690"}
                                        fill={"#E8F5F4"}
                                    ></rect>
                                </svg>
                            )}
                        >
                            ClimateAi
                        </ChartLabelItem>
                        <ChartLabelItem
                            renderIcon={() => (
                                <svg>
                                    <rect
                                        x="2"
                                        y="3"
                                        rx="2"
                                        width="15"
                                        height="15"
                                        strokeWidth="2"
                                        stroke={"#EE6900"}
                                        fill={"#FDF0E6"}
                                    ></rect>
                                </svg>
                            )}
                        >
                            Public Data Providers
                        </ChartLabelItem>
                        <ChartLabelItem renderIcon={() => <LineIcon fill="#9F24AA" />}>Observation</ChartLabelItem>
                    </ChartLabels>

                    {!tableData || (tableData.length === 0 && <div style={{ height: 144 }} />)}
                </HistoricalChart>
            </div>
        </div>
    )
}

export default Historical

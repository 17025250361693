import { withRouter } from "react-router"
import { Link as RouterLink } from "react-router-dom"
import BackgroundAndCardLayout from "./BackgroundAndCardLayout"
import { Button } from "./Button"

const SignUpConfirmation = () => {
    return (
        <BackgroundAndCardLayout>
            <div className="montserrat text-[16px] text-gray-90 font-semibold text-center leading-tight py-4">
                Thank you for contacting us. One of our team members will get in touch with you shortly.
            </div>
            <div className="self-stretch flex flex-row space-x-2 py-2">
                <RouterLink className="w-full" to="/">
                    <Button label="Continue" type="primary" extend={true} />
                </RouterLink>
            </div>
        </BackgroundAndCardLayout>
    )
}

export default withRouter(SignUpConfirmation)

import "react-datepicker/dist/react-datepicker.css"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"
import Section from "../../../ui/Section"
import useContainerSize from "../../../hooks/useContainerSize"
import LoadingBar from "../../../components/LoadingBar"
import ScenarioDropdownSelection from "../../../ui/Controls/ScenarioDropdownSelection"
import LocationDropdownSelection from "../../../ui/Controls/LocationDropdownSelection"
import VariableDropdownSelection from "../../../ui/Controls/VariableDropdownSelection"
import { getMaxYValue } from "../../../Util/candleGraphUtils"
import { useContext } from "react"
import { SettingsContext } from "../../../Util/SettingsContext"
import ExportButton from "../../../components/ExportButton"
import { CLIMATE_CSV_HEADERS, formatClimateCSVData } from "../../../Util/CSVParser"
import useCSVData from "../../../hooks/useCSVData"

function DecadalTrendChart(props) {
    const {
        maxValue,
        minValue,
        loading,
        data,
        //
        locations,
        selectedLocation,
        setSelectedLocation,
        //
        scenarios,
        selectedScenario,
        setSelectedScenario,
        //
        variables,
        selectedVariable,
        setSelectedVariable,
    } = props

    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const [containerRef, { height }] = useContainerSize(1000, [selectedLocation, selectedScenario, selectedVariable])

    let labelY = null
    let tickFormat = null
    let yTickFormat = null

    if (variables && selectedVariable) {
        const selectedVariableObj = variables[selectedVariable]

        labelY =
            selectedVariableObj.label +
            " " +
            ((selectedVariableObj.units && `(${selectedVariableObj.getUnits(units)})`) || "")

        tickFormat = selectedVariableObj.units ? selectedVariableObj.getUnits(units) : ""

        yTickFormat = (d) => (["gdd", "rh", "ch"].includes(selectedVariable) && d < 0 ? "" : d)
    }

    function addYears(date, years) {
        const _date = new Date(date.getTime())
        _date.setFullYear(_date.getFullYear() + years)
        return _date
    }

    const chartXMin = data && data.points && data.points.length > 0 && addYears(data.points[0].x, -10)
    const chartXMax =
        data && data.points && data.points.length > 0 && addYears(data.points[data.points.length - 1].x, +10)

    const { csvHeaders, csvData, csvString } = useCSVData(
        formatClimateCSVData(data),
        CLIMATE_CSV_HEADERS,
        `Decadal Trend Data
        Location:,${locations[selectedLocation]?.location}
        Scenario:,${scenarios[selectedScenario]?.label}
        Variable:,${variables[selectedVariable]?.label}
        \n`,
        !loading
    )

    return (
        <>
            <Section
                title="Decadal Trend"
                id="decadal-trend-section"
                description="This page displays the decadal trend of the selected climate variable at a given location."
                header={() => (
                    <>
                        <LocationDropdownSelection
                            selectedLocation={selectedLocation}
                            locations={locations}
                            setSelectedLocation={setSelectedLocation}
                            keyPrefix={"decadal_location"}
                        />
                        <VariableDropdownSelection
                            selectedVariable={selectedVariable}
                            variables={variables}
                            setSelectedVariable={setSelectedVariable}
                            keyPrefix={"decadal_variable"}
                        />
                        <ScenarioDropdownSelection
                            selectedScenario={selectedScenario}
                            scenarios={scenarios}
                            setSelectedScenario={setSelectedScenario}
                            keyPrefix={"decadal_scenario"}
                        />
                        <ExportButton
                            cols={{ csv: csvHeaders }}
                            data={{ csv: csvData }}
                            csvString={csvString}
                            wrapperClasses="absolute top-3 right-2"
                            fileName={"Decadal_trend-" + locations[selectedLocation]?.location}
                            idToExport="decadal-trend-section"
                        />
                    </>
                )}
            >
                {loading && <LoadingBar loading />}
                <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
                    <LineAreaChartComponent
                        labelY={labelY}
                        yValueMax={getMaxYValue(maxValue, minValue, 8)}
                        zeroBasis={["tp"].includes(selectedVariable) || minValue === 0}
                        marginLeft={80}
                        marginRight={80}
                        xDateMin={chartXMin}
                        xDateMax={chartXMax}
                        xTicksCount={6}
                        labelYOffsetX={-20}
                        candlePadding={0}
                        candleTopBottomLines={true}
                        centerTicks={true}
                        xTickFormat={(d) => {
                            let y = d.getFullYear()
                            return (y % 10 === 0 && y <= 2060 && y) || ""
                        }}
                        yTickFormat={yTickFormat}
                        yBottomOffset={0.3}
                        yTopOffset={0.3}
                        svgHeight={(height && height - 10) || 0}
                        svgWidth="100%"
                        shapeTip={(e, d, arr) => {
                            return `
                      <table>
                        <tr><th style="text-align:right">95th</th><td>${
                            d.y1 != undefined && d.y1.toFixed(2) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">75th</th><td>${
                            d.yMax != undefined && d.yMax.toFixed(2) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">50th</th><td>${
                            d.yMid != undefined && d.yMid.toFixed(2) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">25th</th><td>${
                            d.yMin != undefined && d.yMin.toFixed(2) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">5th</th><td>${
                            d.y0 != undefined && d.y0.toFixed(2) + " " + tickFormat
                        }</td></tr>
                      </table>
                    `
                        }}
                        data={data}
                    ></LineAreaChartComponent>
                </div>
            </Section>
        </>
    )
}

export default DecadalTrendChart

import { capitalizeFirstLetter } from "../helpers/plantingTool"
import { getCorrectUnitValue } from "./Alerts"
import defaultRisks from "./default-risks.json"

export default function createMetadataForRisk(risk, unitSystem) {
    const defaultRisk = defaultRisks[risk.risk_alert]

    let units = defaultRisk["units"][unitSystem]
    let { threshold_value: threshold, recurrence_days } = risk

    if (unitSystem === "imperial") {
        threshold = getCorrectUnitValue(defaultRisk.units.metric, "metric", "imperial", threshold)
    }
    return (
        `${defaultRisk["readable_name"]}: ` +
        `${capitalizeFirstLetter(defaultRisk["modifier"])} ` +
        `${capitalizeFirstLetter(defaultRisk["readable_variable"])} ` +
        `${defaultRisk["conditional"].replace("_", " ")} ` +
        `${threshold}${units}`
    )
}

import React from "react"

// Modules
import { Link } from "react-router-dom"

// Components
import Tooltip from "../../../ui/Tooltip"

function CropTableName(props) {
    const { value, rowData = {}, permissions } = props
    return (
        <Tooltip content={"Go to " + rowData.name} position="right">
            <Link
                to={"/weather/" + (rowData.__group ? rowData.data[0] && rowData.data[0].uuid : rowData.uuid)}
                className="underline"
            >
                {value}
            </Link>
        </Tooltip>
    )
}

export default CropTableName

import { useMemo, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { DataContext } from "../Util/Data"

export default function useField() {
    let { id } = useParams()
    const [lastFieldId, setLastFieldId] = useState()

    useEffect(() => {
        if (id) {
            setLastFieldId(id)
        }
    }, [id])

    const {
        state: { fields },
    } = useContext(DataContext)

    return useMemo(() => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].uuid === lastFieldId) {
                return fields[i]
            }
        }
    }, [fields, lastFieldId])
}

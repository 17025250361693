import React, { Children, useRef } from "react"
import ArrowLeft from "../Icons/ArrowLeft"

import "./Select.css"

function Select(props) {
    const { children, value, onChange, icon: IconComponent } = props

    const childrenArray = Children.toArray(children)

    let displayValue = childrenArray[0]?.props?.children || "---"

    for (let i = 0; i < childrenArray.length; i++) {
        if (childrenArray[i]?.props?.value === value) {
            displayValue = childrenArray[i].props.children
            break
        }
    }

    return (
        <>
            <div className="select">
                {IconComponent && (
                    <div className="select__icon">
                        <IconComponent fill="#666d74" />
                    </div>
                )}
                <div className="select__value">{displayValue}</div>
                <div className="select__arrow">
                    <ArrowLeft />
                </div>
                <select className="select__input" value={value} onChange={onChange}>
                    {children}
                </select>
            </div>
        </>
    )
}

export default Select

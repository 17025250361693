import * as React from "react"

function DownloadIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14 11.574v3H2v-3H0v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4l-1.41-1.41L9 8.744V.574H7v8.17l-2.59-2.58L3 7.574l5 5 5-5z"
                fill={props.fill}
            />
        </svg>
    )
}

export default DownloadIcon

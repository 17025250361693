import React, { useRef, useState } from "react"
import DebounceSearchInput from "../../components/DebounceSearchInput/DebounceSearchInput"
import Checkbox from "../Checkbox/Checkbox"

function TableFilter(props) {
    const {
        options = new Set(),
        selected = new Set(),
        onChange = () => null,
        keyPrefix = "",
        onSelectChange = () => null,
    } = props
    const [searchText, setSearchText] = useState("")
    const scrollRef = useRef(null)

    const _options = Array.from(options)
        .filter((item = "") => item.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
        .sort((a, b) => {
            // put the selected ones first
            if (!selected.has(a) && selected.has(b)) return 1
            else if (selected.has(a) && !selected.has(b)) return -1
            else return 0
        })
        .map((item, index) => {
            return (
                <button
                    className="btn w-full flex items-center px-3 pb-4"
                    key={`${keyPrefix}_${index}`}
                    onClick={(e) => {
                        if (scrollRef.current) scrollRef.current.scrollTo(0, 0)
                        onSelectChange(!selected.has(item), item)
                    }}
                >
                    <Checkbox checked={selected.has(item)} />
                    <p className="pl-[5px] font-normal text-left truncate">{item}</p>
                </button>
            )
        })

    return (
        <>
            <div
                className="w-full h-full bg-white top-[100%] rounded-[8px] pb-[4px] elevation-2 border-[1px] border-gray-10 max-h-[200px] overflow-y-scroll overflow-x-hidden"
                ref={scrollRef}
            >
                <div className="sticky top-0 bg-white z-10 p-3">
                    <DebounceSearchInput onSearch={(text) => setSearchText(text)} autoFocus ms={200} />
                </div>
                {(_options.length > 0 && _options) || (
                    <p className="w-full font-normal text-left color-gray-60 px-3">No results found.</p>
                )}
            </div>
        </>
    )
}

export default TableFilter

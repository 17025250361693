import axios from "axios"
import config from "../Util/config"

import app from "../Util/Fire"

const hostDomain = config.backend_server

export async function getAllCrops() {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const {
            data: { data },
        } = await axios.get(hostDomain + "/api/v1/crops/", { headers: { "User-Token": userToken } })
        return data
    } catch (e) {
        console.log(e)
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function getDefaultCrops() {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const {
            data: { data },
        } = await axios.get(hostDomain + "/api/v1/crops/default", { headers: { "User-Token": userToken } })
        return data
    } catch (e) {
        console.log(e)
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function saveCrop(crop) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return axios.post(hostDomain + "/api/v1/crops/", crop, { headers: { "User-Token": userToken } })
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}
export async function updateCrop(crop) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return axios.put(hostDomain + "/api/v1/crops/", crop, { headers: { "User-Token": userToken } })
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function deleteCrop(crop) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return axios.delete(hostDomain + "/api/v1/crops/", { headers: { "User-Token": userToken }, data: crop })
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function saveVariety({ id: cropId }, variety) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return axios.post(hostDomain + "/api/v1/crops/" + cropId + "/varieties", variety, {
            headers: { "User-Token": userToken },
        })
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function saveStage({ id: cropId }, { id: varietyId }, stage) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return axios.post(hostDomain + "/api/v1/crops/" + cropId + "/varieties/" + varietyId + "/stages", stage, {
            headers: { "User-Token": userToken },
        })
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function updateStage({ id: cropId }, { id: varietyId }, stage) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return axios.put(
            hostDomain + "/api/v1/crops/" + cropId + "/varieties/" + varietyId + "/stages/" + stage.id,
            stage,
            {
                headers: { "User-Token": userToken },
            }
        )
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function updateVariety({ id: cropId }, variety) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return axios.put(hostDomain + "/api/v1/crops/" + cropId + "/varieties/" + variety.id, variety, {
            headers: { "User-Token": userToken },
        })
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function deleteVariety({ id: cropId }, variety) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return axios.delete(hostDomain + "/api/v1/crops/" + cropId + "/varieties/" + variety.id, {
            headers: { "User-Token": userToken },
            data: variety,
        })
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}
export async function deleteStage({ id: cropId }, { id: varietyId }, { id: stageId }) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return axios.delete(
            hostDomain + "/api/v1/crops/" + cropId + "/varieties/" + varietyId + "/stages/" + stageId,

            { headers: { "User-Token": userToken } }
        )
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function removeLabel(label) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return await axios.delete(hostDomain + "/api/v1/labels/", {
            headers: { "User-Token": userToken },
            data: { label },
        })
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function removeLabelFromField(label, field) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        return axios.delete(hostDomain + "/api/v1/labels/field", {
            headers: { "User-Token": userToken },
            data: { label, field },
        })
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

import * as React from "react"

function PlantingToolLabelIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4 4h24" stroke={props.stroke || "#9F24AA"} strokeWidth={4} />
            <rect width={8} height={8} rx={2} fill={props.stroke || "#9F24AA"} />
            <rect x={23} width={8} height={8} fill={props.stroke || "#9F24AA"} />
        </svg>
    )
}

export default PlantingToolLabelIcon

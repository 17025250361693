import React, { Component } from "react"

class UnitSystem extends Component {
    constructor(props) {
        super()
        this.state = {
            unitSystem: props.currentSettings.units,
        }
    }

    setUnitSystem = (us) => {
        this.setState({
            unitSystem: us,
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentSettings.units !== this.props.currentSettings.units) {
            this.setUnitSystem(this.props.currentSettings.units)
        }
    }

    componentWillUnmount() {
        if (this.state.unitSystem !== this.props.currentSettings.units) {
            this.props.onUserSettingsChange({ units: this.state.unitSystem })
        }
    }

    render() {
        return (
            <div id="unit-system-container__user-settings" className="user-settings__unit-column">
                <div className="user-settings__unit-column__title">Unit System</div>
                <div className="user-settings__unit-column__text">Select the unit system you prefer</div>
                <div className="user-settings__button-container">
                    <button
                        id="imperial-unit-selection-button__user-settings"
                        onClick={() => {
                            this.setUnitSystem("imperial")
                        }}
                        className={
                            this.state.unitSystem === "imperial"
                                ? "user-settings__button selected"
                                : "user-settings__button"
                        }
                    >
                        <div className="user-settings__button-text">Imperial</div>
                        <div className="user-settings__button-icon"></div>
                    </button>

                    <button
                        id="metric-unit-selection-button__user-settings"
                        onClick={() => {
                            this.setUnitSystem("metric")
                        }}
                        className={
                            this.state.unitSystem === "metric"
                                ? "user-settings__button selected"
                                : "user-settings__button"
                        }
                    >
                        <div className="user-settings__button-text">Metric</div>
                        <div className="user-settings__button-icon"></div>
                    </button>
                </div>
            </div>
        )
    }
}

export default UnitSystem

import { useMemo, useState, useRef, useEffect, useContext } from "react"
import { CircularProgress } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"
import { convertTemperatureValueOnly } from "../../../../Util/UnitConversion"

import { AuthContext } from "../../../../Auth/Auth"
import networking from "../../../../Util/Networking"
import { SettingsContext } from "../../../../Util/SettingsContext"
import roundNumber from "../../../../helpers/roundNumber"

import {
    getHistoricalMaxTemp,
    getForecastMinTemp,
    getForecastMaxTemp,
    getClimMin,
    getClimMax,
    getForecastMinArr,
    getForecastMaxArr,
} from "./helper"
import {
    getUnit,
    processUnitSystem,
    getForecastConfidenceData,
    addMonths,
    validateData,
    convertToShadedRangesFormat,
    addDays,
    assembleAreaData,
    assembleLineData,
    getSevenMonthsMarginClimData,
    convertAPIDataToSeasonalBarStructure,
    convertDataToBarStructure,
    convertToLineFromBar,
    convertToDoubleCandlestick,
    renderArrows,
    trimmData,
} from "../../../../helpers/chartHelpers"

import ChartSpecs from "../ChartSpecs"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useLastUpdated from "../../../../hooks/useLastUpdated"
import { ToastContext } from "../../../../components/Toast/ToastContextProvider"

const SollTempChart = ({ actionsState }) => {
    const chartRef = useRef(null)
    const { currentUser } = useContext(AuthContext)
    const { currentSettings } = useContext(SettingsContext)
    const { enqueueNetworkError } = useContext(ToastContext)
    const { id } = useParams()
    const [fieldId, setFieldId] = useState()

    const [confidenceVisible, setConfidenceBarsVisibility] = useState(false)
    const [climatologyVisible, setClimatologyVisible] = useState(true)

    const [historicalPending, setHistoricalPending] = useState(false)

    // Media Queries for Tablet View
    const [tabletMedia, setTabletMedia] = useState(true)
    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)", { noSsr: true })
    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    // ******************************* BAR DATA PART ****************************

    //---------------------------  MONTHLY SEASONAL  ------------------------
    // BarData - Forecast
    const [monthlyBarForecastData, setMonthlyBarForecastData] = useState({
        time: [],
    })

    // BarData - Climatology
    const [monthlyBarClimatologyData, setMonthlyBarClimatologyData] = useState({
        time: [],
    })

    // BarData - Forecast - Vigintiles
    const [monthlyBarForecastDataVigintiles, setMonthlyBarForecastDataVigintiles] = useState({ time: [] })

    // BarData - Climatology - Vigintiles
    const [monthlyBarClimatologyDataVigintiles, setMonthlyBarClimatologyDataVigintiles] = useState({ time: [] })

    // Bar Data - Insights
    const barDataInsights = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "stl1_mean",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                property: "stl1_mean",
            }),
        [monthlyBarForecastDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarClimatologyDataVigintiles,
                property: "stl1_mean",
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
            }),
        [monthlyBarClimatologyDataVigintiles, monthlyBarForecastDataVigintiles]
    )

    const [barType, setBarType] = useState("candl")

    //******************************* END OF BAR DATA PART ****************************

    // Prepare initial data
    const weatherVariable = "soil_temperature"
    const [data, setData] = useState({
        ds_hist: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        ds_fc: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        ds_clim: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        pending: true,
    })

    // Prepare initial data
    const [monthlyData, setMonthlyData] = useState({
        ds_hist: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        ds_fc: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        ds_clim: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        pending: true,
    })

    // Alerts Data
    const [alertsData, setAlertsData] = useState({
        stl1_mean: {},
    })

    // Load data
    useEffect(() => {
        console.log("fires 1")
        if (fieldId !== id || (fieldId === id && climatologyVisible && data.ds_clim.time.length === 0)) {
            console.log("fires 2")
            // Set data status pending
            setData({
                ...data,
                pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
            })

            let localData = data

            if (climatologyVisible) {
                setHistoricalPending(true)
            }
            // let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
            // if (climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
            let datasets = "history%2Cforecast%2Cclimatology"
            if (!climatologyVisible) {
                if (fieldId !== id) {
                    localData.pending = true
                }
                localData = Object.assign({}, localData, {
                    ds_clim: {
                        time: [],
                        stl1_min: [],
                        stl1_mean: [],
                    },
                })
                setData(localData)
            }

            currentUser.getIdToken().then((userToken) => {
                networking
                    .get(`/api/v1/weather/${weatherVariable}/daily/${id}?datasets=${datasets}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (datasets.indexOf("climatology") !== -1) {
                            setHistoricalPending(false)
                        }
                        setData({
                            ...Object.assign({}, localData, res.data),
                            pending: false,
                        })
                    })
                    .catch((_) => {
                        setData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        enqueueNetworkError()
                    })

                // Break network call if data already loaded
                if (fieldId === id && monthlyData.ds_fc.time.length) {
                    return
                }

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setMonthlyBarForecastData(res.data.ds_fc)
                        setMonthlyBarClimatologyData(res.data.ds_clim)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setMonthlyBarForecastDataVigintiles(res.data.ds_fc)
                        setMonthlyBarClimatologyDataVigintiles(res.data.ds_clim)
                    })
                    .catch(() => {
                        //         toast.warn(
                        //             `Alerts not displayed on dashboard due to internet
                        // connectivity issues. All other functions working.`
                        //         )
                    })

                networking
                    .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        setAlertsData(res.data)
                    })
                    .catch(() => {
                        //         toast.warn(
                        //             `Alerts not displayed on dashboard due to internet
                        // connectivity issues. All other functions working.`
                        //         )
                    })
            })
            setFieldId(id)
        }
    }, [
        actionsState.selectedGranularity,
        actionsState.isMonthly,
        actionsState.selectedVariable,
        currentUser,
        id,
        climatologyVisible,
    ])

    const lastUpdated = useLastUpdated(data, monthlyData)

    // Prepare min historical  data
    // const historicalMinTemp = useMemo(() => getHistoricalMinTemp(data["ds_hist"]), [data])
    const historicalMaxTemp = useMemo(() => getHistoricalMaxTemp(data["ds_hist"]), [data])
    const forecastMinArr = useMemo(() => getForecastMinArr(data["ds_fc"]), [data])
    const forecastMaxArr = useMemo(() => getForecastMaxArr(data["ds_fc"]), [data])

    // Prepare max historical data
    const forecastMinTemp = useMemo(() => getForecastMinTemp(data["ds_fc"], forecastMinArr), [data, forecastMinArr])
    const forecastMaxTemp = useMemo(() => getForecastMaxTemp(data["ds_fc"], forecastMaxArr), [data, forecastMaxArr])
    const { climMinLighten } = useMemo(() => getClimMin(data["ds_clim"]), [data])
    const { climMaxLighten, climMaxDarken } = useMemo(() => getClimMax(data["ds_clim"]), [data])

    // Look at the changes for historical and forecast data and display warning messages if invalid
    useMemo(() => {
        validateData({
            diffToAlert: 12,
            historic: climMinLighten,
            forecast: forecastMinTemp,
            accessorKey: "y0",
            message: "Tmin Forecast Anomaly Detected",
        })
        validateData({
            diffToAlert: 12,
            historic: climMaxLighten,
            forecast: forecastMaxTemp,
            accessorKey: "y",
            message: "TMax Forecast Anomaly Detected",
        })
    }, [forecastMinTemp, forecastMaxTemp, climMinLighten, climMaxLighten])

    // Prepare Confidence Data
    // const forecastMinConfidence75 = useMemo(() => {
    //     return getForecastConfidenceData(
    //         data["ds_fc"],
    //         historicalMinTemp[historicalMinTemp.length - 1],
    //         data["ds_fc"]["stl1_min"],
    //         "0.75"
    //     )
    // }, [data, historicalMinTemp])
    // const forecastMinConfidence95 = useMemo(() => {
    //     return getForecastConfidenceData(
    //         data["ds_fc"],
    //         historicalMinTemp[historicalMinTemp.length - 1],
    //         data["ds_fc"]["stl1_min"],
    //         "0.95"
    //     )
    // }, [data, historicalMinTemp])
    const forecastMaxConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMaxTemp[historicalMaxTemp.length - 1],
            data["ds_fc"]["stl1_mean"],
            "0.75"
        )
    }, [data, historicalMaxTemp])
    const forecastMaxConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMaxTemp[historicalMaxTemp.length - 1],
            data["ds_fc"]["stl1_mean"],
            "0.95"
        )
    }, [data, historicalMaxTemp])

    const lineData = assembleLineData({
        isMonthly: actionsState.isMonthly,
        historical: historicalMaxTemp,
        forecast: forecastMaxTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.stl1_mean["0.5"][i],
            }
        }),
    })

    const dailyLineDaysFromBeginning =
        (lineData.length > 0 && Math.floor((new Date().getTime() - lineData[0].x) / (24 * 60 * 60 * 1000))) + 1 || 0

    const csvData = lineData.map((d) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })],
        [currentSettings.units === "metric" ? d.y : convertTemperatureValueOnly("metric", "imperial", d.y)],
    ])

    return (
        <div className="weather-chart">
            <div className="weather-chart__chart-container">
                <div style={{ display: data.pending ? "flex" : "none" }} className="weather-chart__preload-container">
                    <CircularProgress />
                </div>
                <LineAreaChartComponent
                    // Pass height externally
                    svgHeight={650}
                    marginRight={30}
                    xAxisOffsetY={5}
                    // Title text
                    title="Soil Temperature (0-7cm)"
                    // Y label text
                    labelY={`Soil Temperature in ${getUnit({ system: currentSettings.units }).tempUnit}`}
                    // Pass unique resize event key
                    resizeEventListenerId="soil-temperature-chart"
                    // Add chart data id to filter out some update requests
                    chartDataId={
                        {
                            hourly: `hour`,
                            daily: `month`,
                            monthly: `day`,
                        }[actionsState.selectedGranularity] +
                        `${weatherVariable}-chart_` +
                        climMaxLighten.length
                    }
                    // Center Tick
                    centerTicks={
                        {
                            daily: false,
                            monthly: true,
                            hourly: false,
                        }[actionsState.selectedGranularity]
                    }
                    // Convert received data to shaded ranges format
                    shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData))}
                    // Make chart to have dynamic y basis
                    zeroBasis={false}
                    marginTop={tabletMedia ? 65 : 65}
                    // Bottom margin will be 0.3 times of data diff
                    yBottomOffset={0.3}
                    // Top margin will be 0.3 times of data diff
                    yTopOffset={0.3}
                    // Provide custom date max axis extent for monthly view charts
                    xDateMax={
                        {
                            daily: lineData.length
                                ? addDays(new Date(lineData[lineData.length - 1].x), 0)
                                : addDays(new Date(), 7),
                            //  monthly: addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), 2), 7),
                            monthly: addMonths(
                                new Date(
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getFullYear(),
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getMonth() + 1,
                                    1
                                ),
                                0
                            ),
                            hourly: null,
                        }[actionsState.selectedGranularity]
                    }
                    // Provide custom date min axis extent for monthly view charts
                    xDateMin={
                        {
                            daily: tabletMedia ? addDays(new Date(), -2) : null,
                            // monthly: addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), 28), -7),
                            monthly: (() => {
                                const today = new Date()
                                const year = today.getFullYear()
                                const month = today.getMonth()
                                const result = new Date(year, month, 1)
                                return result
                            })(),
                            hourly: null,
                        }[actionsState.selectedGranularity]
                    }
                    // How x ticks will be formatted in chart
                    xTickFormat={
                        {
                            daily: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getDate()
                                if (i === 0) return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                            },
                            // monthly: (d, i, arr) => {
                            //     // Remove last, overflowing tick item
                            //     if (i === arr.length - 1) return ""
                            //     return d.toLocaleString(undefined, { month: "short" })
                            // },
                            monthly: (d, i, arr) => {
                                // Remove last, overflowing tick item
                                if (i === arr.length - 1) return ""
                                return d.toLocaleString(undefined, { month: "short" })
                            },
                            hourly: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getHours() + "h"
                                if (i === 0) return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                                return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Give chart tips count tip
                    xTicksCount={
                        {
                            daily: tabletMedia ? 14 : 20,
                            // monthly: 12,
                            monthly: 5,
                            hourly: 16,
                        }[actionsState.selectedGranularity]
                    }
                    // Hide chart if data is pending
                    hide={data.pending}
                    // Tooltip content on line points mouse over
                    tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                        let hour = undefined
                        let day = "numeric"
                        let month = "short"
                        let titleWidth = 40
                        let titleFontSize = 20

                        if (actionsState.selectedGranularity === "hourly") {
                            hour = "numeric"
                            day = undefined
                            month = undefined
                        }

                        let dateStr = key.toLocaleString(undefined, { day, month, hour })

                        if (actionsState.selectedGranularity === "monthly") {
                            dateStr = key.toLocaleString(undefined, { month: "short" })
                        }

                        if (barType === "candl") {
                            day = undefined
                        }

                        return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                 <tr><td style="font-weight:bold;font-size:${titleFontSize}px" rowspan="${
                            values.length
                        }"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:${titleWidth}px;line-height:1.1">${dateStr}</div></td>
                     <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                         points[0].dashed ? "dashed" : "solid"
                     } ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${roundNumber(values[0], 1)} ${
                            getUnit({ system: currentSettings.units }).tempUnit
                        }</td>
                 </tr>
                 ${values
                     .filter((_, i) => i > 0)
                     .map((value, i) => {
                         return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                             points[i + 1].dashed ? "dashed" : "solid"
                         } ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${roundNumber(value, 1)} ${
                             getUnit({ system: currentSettings.units }).tempUnit
                         }</td></tr>`
                     })
                     .join("")}
             </table>`
                    }}
                    shapeTip={
                        {
                            hourly: null,
                            daily: null,
                            monthly: (EVENT, boundObj, state, point) => {
                                let resultObj = point ?? boundObj
                                let month = resultObj.key
                                    .toLocaleString("en-US", { month: "short", year: "numeric" }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                    .split("")
                                    .join("")
                                const prec = 100
                                const warmer = Math.round(prec * barDataInsights.normalMax[month]) / prec
                                let clim67 = Math.round(prec * barDataInsights.clim67[month]) / prec
                                const normal = Math.round(prec * barDataInsights.normal[month]) / prec
                                let clim33 = Math.round(prec * barDataInsights.clim33[month]) / prec
                                const colder = Math.round(prec * barDataInsights.normalMin[month]) / prec

                                let unit = "C"
                                if (currentSettings.units === "imperial") {
                                    clim33 = +convertTemperatureValueOnly("metric", "imperial", clim33)
                                    clim67 = +convertTemperatureValueOnly("metric", "imperial", clim67)
                                    unit = "F"
                                }

                                return `<div style="max-width:250px;">There is a ${warmer}% chance that soil temperature will be higher than usual (above ${clim67} ${unit} ). </br>  </br> There is a ${normal}% chance that soil temperature will be within the normal range (${clim33} to ${clim67}  ${unit}). </br>  </br>There is a ${colder}% chance that soil temperature will be lower than normal (below ${clim33}  ${unit}).</div>`
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Chart data content
                    data={[
                        //======================= PLOT MEDIAN AS LINE - MONTHLY ===================
                        ["monthly"].includes(actionsState.selectedGranularity)
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBar({
                                      obj: forecastQuantilesBarData[0.5],
                                      props: {
                                          color: "#EE6900",
                                          barType: barType,
                                          visible: true,
                                          unit: currentSettings.units,
                                          unitType: "temp",
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsights)
                                                  return {
                                                      color: "",
                                                      symbol: `
                           <style>
                           .point-loader {
                             border: 3px solid #f3f3f3;
                             border-radius: 50%;
                             border-top: 3px solid #3498db;
                             width: 10px;
                             height: 10px;
                             -webkit-animation: spin 2s linear infinite; /* Safari */
                             animation: spin 2s linear infinite;
                           }

                           /* Safari */
                           @-webkit-keyframes spin {
                             0% { -webkit-transform: rotate(0deg); }
                             100% { -webkit-transform: rotate(360deg); }
                           }

                           @keyframes spin {
                             0% { transform: rotate(0deg); }
                             100% { transform: rotate(360deg); }
                           }
                           </style>

                           <div class="point-loader"></div>
                           `,
                                                  }
                                              const month = d.key
                                                  .toLocaleString("en-US", {
                                                      month: "short",
                                                      year: "numeric",
                                                  }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                                  .split("")
                                                  .join("")
                                              const normalMax = barDataInsights.normalMax[month]
                                              const normalMin = barDataInsights.normalMin[month]
                                              const normal = barDataInsights.normal[month]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        //=================== DOUBLE  CANDLESTICK VERSION  =========================

                        [""].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToLineFromBar({
                                  obj: forecastQuantilesBarData[0.5],
                                  props: {
                                      color: "#EE6900",
                                      barType: barType,
                                      visible: false,
                                      unit: currentSettings.units,
                                      unitType: "temp",
                                      date: 15,
                                  },
                              })
                            : null,

                        ["monthly"].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToDoubleCandlestick({
                                  obj: [
                                      {
                                          values: [forecastQuantilesBarData, climatologyQuantilesBarsData],
                                      },
                                  ],
                                  unit: currentSettings.units,
                                  unitType: "temp",
                                  colors: ["#EE6900", "#EE6900"],
                              })
                            : null,

                        // ===================== End Of Horizontal and vertical bars versions  ====================

                        ["daily"].includes(actionsState.selectedGranularity) && climatologyVisible
                            ? {
                                  type: "area",
                                  points: (!actionsState.isMonthly
                                      ? trimmData(
                                            getSevenMonthsMarginClimData(climMaxLighten, dailyLineDaysFromBeginning)
                                        )
                                      : getSevenMonthsMarginClimData(climMaxLighten)
                                  ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: "#F8D6C5",
                                  opacity: 0.6,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) && climatologyVisible
                            ? {
                                  type: "area",
                                  points: (!actionsState.isMonthly
                                      ? trimmData(
                                            getSevenMonthsMarginClimData(climMaxDarken, dailyLineDaysFromBeginning)
                                        )
                                      : getSevenMonthsMarginClimData(climMaxDarken)
                                  ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: "#FDBE9D",
                                  opacity: 0.6,
                              }
                            : null,
                        // climatologyVisible ?{
                        //     type: "area",
                        //     points: (!actionsState.isMonthly ? trimmData(climMinLighten) : climMinLighten).map((d) =>
                        //         processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                        //     ),
                        //     color: "#DBEBF5",
                        //     opacity: 0.6,
                        // }:null,
                        // climatologyVisible ?{
                        //     type: "area",
                        //     points: (!actionsState.isMonthly ? trimmData(climMinDarken) : climMinDarken).map((d) =>
                        //         processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                        //     ),
                        //     color: "#C6E2F1",
                        //     opacity: 0.6,
                        // }:null,
                        // Confidence Bands
                        // confidenceVisible?{
                        //     type: "area",
                        //     points: (!actionsState.isMonthly
                        //         ? trimmData(forecastMinConfidence95)
                        //         : forecastMinConfidence95
                        //     ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                        //     color: "#0089C6",
                        //     opacity: 0.4,
                        // }:null,
                        // confidenceVisible?{
                        //     type: "area",
                        //     points: (!actionsState.isMonthly
                        //         ? trimmData(forecastMinConfidence75)
                        //         : forecastMinConfidence75
                        //     ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                        //     color: "#0089C6",
                        //     opacity: 0.4,
                        // }:null,
                        ["daily"].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastMaxConfidence95,
                                      isMonthly: actionsState.isMonthly,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y1: monthlyData.ds_fc.stl1_mean["0.95"][i],
                                              y0: monthlyData.ds_fc.stl1_mean["0.05"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: actionsState.isMonthly ? "#FFBB97" : "#d05e0a",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastMaxConfidence75,
                                      isMonthly: actionsState.isMonthly,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y1: monthlyData.ds_fc.stl1_mean["0.75"][i],
                                              y0: monthlyData.ds_fc.stl1_mean["0.25"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: actionsState.isMonthly ? "#FB9769" : "#db630a",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) && {
                            type: "line",
                            id: "line-max",
                            alwaysOnTop: true,
                            points: lineData.map((d) =>
                                processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                            ),
                            color: "#EE6900",
                            "stroke-width": 2,
                        },
                        // {
                        //     type: "line",
                        //     id:"line-min",
                        //     alwaysOnTop:true,
                        //     points: (!actionsState.isMonthly
                        //         ? trimmData(historicalMinTemp).concat(
                        //               trimmData(forecastMinTemp).map((d) => Object.assign(d, { dashed: true }))
                        //           )
                        //         : historicalMinTemp.concat(
                        //               forecastMinTemp.map((d) => Object.assign(d, { dashed: true }))
                        //           )
                        //     ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                        //     color: "#0089C6",
                        //     "stroke-width": 2,
                        // },
                    ]}
                ></LineAreaChartComponent>
            </div>
            <div className="weather-chart__specs-container">
                <ChartSpecs
                    type="temp-soil"
                    barType={barType}
                    historicalPending={historicalPending}
                    selectedGranularity={actionsState.selectedGranularity}
                    chartRef={chartRef}
                    climatologyVisible={climatologyVisible}
                    lastUpdated={lastUpdated}
                    confidenceVisible={confidenceVisible}
                    handleBarTypeChange={(candlChecked) => {
                        if (candlChecked) setBarType("candl")
                        else setBarType("med")
                    }}
                    handleAreasVisibilityChange={({ conf, clim }) => {
                        setConfidenceBarsVisibility(conf)
                        setClimatologyVisible(clim)
                    }}
                    colsArr={["Date", `Value (${getUnit({ system: currentSettings.units }).tempUnit})`]}
                    data={{
                        csv: csvData,
                        // hourlyCsv: mergeHistoricalAndForecastData({
                        //     forecast: hourlyData.ds_fc,
                        //     historical: hourlyData.ds_hist,
                        //     prop: "stl1",
                        // }),
                    }}
                    // onHourlyCsvDataTrigger={() => {
                    //     return new Promise((resolve, reject) => {
                    //         currentUser.getIdToken().then((userToken) => {
                    //             networking
                    //                 .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                    //                     extraHeaders: { "User-Token": userToken },
                    //                 })
                    //                 .then((res) => {
                    //                     setHourlyData({
                    //                         ...res.data,
                    //                     })
                    //                     resolve(res.data)
                    //                 })
                    //                 .catch(() => {
                    //                     reject()
                    //                 })
                    //         })
                    //     })
                    // }}
                    actionsState={actionsState}
                />
            </div>
        </div>
    )
}

export default SollTempChart

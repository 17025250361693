import React, { useLayoutEffect, useRef } from "react";
import { HeatmapGrid } from "./HeatmapGrid.d3";
import "./HeatmapGrid.css"

// export const HeatmapGridChartComponent = (
//     props,
//     ref
// ) => {
//     const d3Container = useRef(null);
//     let chart = null;

//     // We need to manipulate DOM
//     useLayoutEffect(() => {
//         if (props.data && d3Container.current) {
//             if (!chart) {
//                 /* eslint-disable */
//                 chart = new HeatmapGrid();
//             }

//             chart
//                 .container(d3Container.current)
//                 .data(props.data)
//                 .svgHeight(window.innerHeight - 20)



//                 .render();
//         }
//     }, [props.data, d3Container.current]);

//     return (
//         <div>
//             <div ref={d3Container} />
//         </div>
//     );
// };



export class HeatmapGridChartComponent extends React.Component {

    // Handle component mounting event
    componentDidMount() {
        this.createDiagram = this.createDiagram.bind(this)
        this.createDiagram()
    }

    // Handle component update event
    componentDidUpdate(prevProps, prevState) {
        if (this.props.disableUpdate) {
            return
        }
        this.createDiagram()
    }

    // Render chart
    render() {
        return (
            <div
                style={{ display: this.props.hide ? "none" : "inline-block" }}
                className="d3-heatmap-grid d3-chart-container"
            >
                <div ref={(node) => (this.node = node)} />
            </div>
        )
    }

    // Reusable, create diagram function
    createDiagram() {
        // Retrieve node dom element
        const node = this.node

        // If there is no data, exit from component
        if (!this.props.data) {
            return
        }

        // Retrieve props
        const props = this.props

        // Create chart reference

        if (!this.chart) {
            /* eslint-disable */
            this.chart = new HeatmapGrid()
        }

        // Pass chart some parameters and (re)render it
        this.chart
            .container(node)
            .data(props.data)

        if (props.svgHeight !== undefined) {
            this.chart.svgHeight(props.svgHeight)
        }

        if (props.marginTop !== undefined) {
            this.chart.marginTop(props.marginTop)
        }

        if (props.colors !== undefined) {
            this.chart.colors(props.colors)
        }




        if (props.resizeEventListenerId !== undefined) {
            this.chart.resizeEventListenerId(props.resizeEventListenerId)
        }

        if (props.marginBottom !== undefined) {
            this.chart.marginBottom(props.marginBottom)
        }

        if (props.marginRight !== undefined) {
            this.chart.marginRight(props.marginRight)
        }

        if (props.marginLeft !== undefined) {
            this.chart.marginLeft(props.marginLeft)
        }

        this.chart
            .render();

    }
}

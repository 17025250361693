import React from "react"

function WindDirectionArrowIcon(props) {
    return (
        <>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 14 14"
                fill="none"
                className="fill-inherit"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.923 13.4V3.664l4.472 4.472L13.523 7 7.123.6.723 7 1.85 8.128l4.472-4.464V13.4h1.6Z"
                    fill={props.fill}
                    transform={props.transform}
                />
            </svg>
        </>
    )
}

export default WindDirectionArrowIcon

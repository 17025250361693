import { withRouter } from "react-router"
import BackgroundAndCardLayout from "./BackgroundAndCardLayout"
import { Button } from "./Button"

const RestorePasswordConfirmation = ({ history }) => {
    return (
        <BackgroundAndCardLayout>
            <div className="montserrat text-[16px] text-gray-90 font-semibold text-center leading-tight pb-2">
                Your password has been changed successfully!
            </div>
            <Button
                label="Log in"
                type="primary"
                extend={true}
                onClick={() => {
                    history.push("/")
                }}
            />
        </BackgroundAndCardLayout>
    )
}

export default withRouter(RestorePasswordConfirmation)

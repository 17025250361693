import * as React from "react"

function WeatherEqualsIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 13 8"
            fill="none"
            className="fill-inherit"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12.706 2.53H.024V.72h12.682V2.53ZM12.706 7.966H.024V6.154h12.682v1.812Z" fill={props.fill} />
        </svg>
    )
}

export default WeatherEqualsIcon

import React from "react"

function ClimateLogoWhite(props) {
    return (
        <>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 36 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M20.24 8.03C18.742 4.003 17.326.2 13.947.2c-3.38 0-4.796 3.803-6.294 7.83C5.786 13.04 3.345 20.905-4.8 20.905v.891c10.38 0 13.144-9.17 14.503-12.824 1.453-3.91 2.437-6.305 4.24-6.305 1.802 0 2.786 2.396 4.24 6.305C19.582 12.732 22.35 21.8 30 21.8c-5.82-1.712-7.893-8.76-9.76-13.77z"
                    fill="#fff"
                />
                <path
                    d="M15.944 8.03C17.471 4.003 18.914.2 22.357.2s4.886 3.803 6.412 7.83c1.902 5.01 4.389 12.879 12.688 12.879l.543.551-.543.34c-10.576 0-13.392-9.174-14.777-12.828-1.48-3.91-2.483-6.305-4.32-6.305-1.836 0-2.838 2.396-4.32 6.305C16.615 12.732 13.795 21.8 6 21.8c5.93-1.712 8.043-8.76 9.944-13.77z"
                    fill="#fff"
                />
            </svg>
        </>
    )
}

export default ClimateLogoWhite

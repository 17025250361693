import Menu from "./Menu"
import MenuItem from "./MenuItem"
import CalendarIcon from "../Icons/CalendarIcon"

export default function DecadeDropdownSelection(props) {
    const { selectedDecade, decades, setSelectedDecade, keyPrefix } = props
    return (
        <Menu className="left" icon={CalendarIcon} text={decades[selectedDecade]?.label || ""}>
            {Object.values(decades).map((decade) => (
                <MenuItem
                    active={selectedDecade === decade.value}
                    label={decade.label}
                    key={`${keyPrefix}_${decade.value}`}
                    onClick={() => setSelectedDecade(decade.value)}
                />
            ))}
        </Menu>
    )
}

import React, { useContext, useEffect } from "react"
import { ToastContext } from "../../components/Toast/ToastContextProvider"
import http from "../../Util/http"

import { useAdaptationValues, VARIABLES } from "./ClimateProvider"

function ClimateWrapper(props) {
    const { children } = props
    const { setInitialState } = useAdaptationValues()
    const { enqueueNetworkError } = useContext(ToastContext)

    async function getReportValues() {
        // first get locations and crops
        const results = await Promise.allSettled([
            http.get("/api/v1/adapt/v2/locations"),
            http.get("/api/v1/adapt/v2/plots"),
            http.get("/api/v1/adapt/v2/risks"),
            http.get("/api/v1/adapt/var"),
        ])

        // TODO handle error inside the view
        if (
            results[0].status !== "fulfilled" ||
            results[1].status !== "fulfilled" ||
            results[2].status !== "fulfilled" ||
            results[3].status !== "fulfilled"
        ) {
            enqueueNetworkError()
            throw new Error("There was a problem fetching the report information. Try again later.")
        }

        const locations = results[0].value?.data
        const plots = results[1].value?.data
        const riskList = results[2].value?.risk_list
        let availableVariables = Object.keys(results[3].value?.data)
        if (availableVariables.length === 0) {
            availableVariables = Object.keys(VARIABLES)
        }

        let _locations = {}
        Object.values(locations)
            .filter((l) => Object.values(plots).find((p) => p.location === l.location))
            .forEach((l) => (_locations[l.id] = l))

        setInitialState(_locations, plots, riskList, availableVariables)
    }

    useEffect(() => {
        getReportValues().catch((err) => console.log(err))
    }, [])

    return <>{children}</>
}

export default React.memo(ClimateWrapper)

import { useState } from "react"
import useOutsideComponentClickHandler from "../hooks/ClickOutsideHook"
import ArrowBottom from "../ui/Icons/newIcons/ArrowBottom"

const Select = ({
    selected,
    options = {},
    setSelected = () => {},
    valueKey = "",
    textAlignClass = "text-left",
    loading = false,
    placeholder = "",
}) => {
    const [open, toggle] = useState(false)

    const dropdownRef = useOutsideComponentClickHandler(() => toggle(false))

    const getDisplayValue = (key) => {
        if (loading) return "Loading..."
        if (!key) return placeholder || "Please select a value"
        if (valueKey !== "") return options[key][valueKey].replaceAll("_", " ")
        return options[key]
    }

    return (
        <div className="relative min-w-0 select-none" ref={dropdownRef}>
            <button
                className={[
                    "h-[36px] min-w-[60px] pl-2 w-full",
                    "border-[1px] border-gray-10 rounded-lg",
                    "flex flex-row items-center",
                    "transition-all duration-75",
                    "hover:text-gray-90 hover:elevation-1 cursor-pointer",
                    "focus:outline-accent",
                    textAlignClass,
                ].join(" ")}
                onClick={() => toggle(!open)}
            >
                <div className="truncate pointer-events-none grow whitespace-nowrap">{getDisplayValue(selected)}</div>
                <span className="w-[22px] mt-[2px] shrink-0 grow-0">
                    <ArrowBottom />
                </span>
            </button>
            {open && options && (
                <div
                    className={[
                        "absolute top-full right-0",
                        "bg-white z-30 rounded-lg elevation-2",
                        "h-fit max-h-[400px] overflow-y-auto",
                        "border-[1px] border-gray-10",
                    ].join(" ")}
                >
                    {Object.keys(options).map((key, index) => (
                        <button
                            key={key + "-" + index}
                            className={[
                                "flex flex-row items-center",
                                "h-[32px] px-2 max-w-[200px] min-w-[40px] w-full",
                                "hover:bg-gray-5 cursor-pointer",
                                key == selected ? "bg-gray-5" : "", // == instead of === to compare integers with strings
                                textAlignClass,
                            ].join(" ")}
                            onClick={() => {
                                toggle(false)
                                setSelected(key)
                            }}
                        >
                            <p className="w-full truncate pointer-events-none whitespace-nowrap">
                                {getDisplayValue(key)}
                            </p>
                        </button>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Select

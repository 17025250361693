import * as React from "react"

const DotsIcon = (props) => (
    <svg
        width={"100%"}
        height={"100%"}
        viewBox="0 0 20 20"
        className="fill-inherit"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.3334 9.99998C13.3334 10.9166 14.0834 11.6666 15.0001 11.6666C15.9167 11.6666 16.6667 10.9166 16.6667 9.99998C16.6667 9.08331 15.9167 8.33331 15.0001 8.33331C14.0834 8.33331 13.3334 9.08331 13.3334 9.99998ZM11.6667 9.99998C11.6667 9.08331 10.9167 8.33331 10.0001 8.33331C9.08341 8.33331 8.33341 9.08331 8.33341 9.99998C8.33341 10.9166 9.08341 11.6666 10.0001 11.6666C10.9167 11.6666 11.6667 10.9166 11.6667 9.99998ZM6.66675 9.99998C6.66675 9.08331 5.91675 8.33331 5.00008 8.33331C4.08341 8.33331 3.33341 9.08331 3.33341 9.99998C3.33341 10.9166 4.08341 11.6666 5.00008 11.6666C5.91675 11.6666 6.66675 10.9166 6.66675 9.99998Z"
            fill={props.fill}
        />
    </svg>
)

export default DotsIcon

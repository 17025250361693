/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from "!mapbox-gl"
import * as d3 from "d3"
import { token } from "./accessToken"
import { MAP_DEFAULT_LAYER } from "./MapboxGenericMap"
import ReactDOMServer from "react-dom/server"
import getRiskIcon from "../../../Util/getRiskIcon"
import CancelIcon from "../../../ui/Icons/newIcons/CancelIcon"

mapboxgl.accessToken = token

const AlertsDesc = ({ icon: Icon, title, crop, alert_types_count, extraClasses = "" }) => {
    if (alert_types_count > 0) {
        return (
            <>
                <div className={"flex flex-row items-center h-[36px] " + extraClasses}>
                    <span className="pl-1 grow-0 shrink-0 w-[20px] flex items-center justify-center fill-inherit">
                        {Icon && <Icon />}
                    </span>
                    <div className="pl-2 text-gray-60 text-[14px] whitespace-nowrap">{title}</div>
                    <div className="shrink-0 text-[14px] text-gray-90 pl-1 font-semibold">{crop}</div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className={"flex flex-row items-center h-[36px] " + extraClasses}>
                    <div className="shrink-0 text-[14px] text-gray-90 font-semibold">{crop}</div>
                </div>
            </>
        )
    }
}

const RedDot = () => {
    return <div className="w-[6px] h-[6px] rounded-full bg-red"></div>
}
const AlertListOption = ({ title, icon: Icon, fieldId, ...rest }) => {
    return (
        <button
            className="py-[3px] w-full flex flex-row items-center rounded-sm outline-none space-x-2 text-gray-90 hover:bg-gray-5 hover:elevation-1 transition-all duration-200"
            {...rest}
        >
            <span className="w-[20px] h-[20px] fill-gray-60 grow-0 shrink-0 pointer-events-none">
                {Icon && <Icon />}
            </span>
            <div className="text-[16px] underline truncate pointer-events-none">{title}</div>
        </button>
    )
}
const SeasonalMapMarkerCard = ({ name, region, alerts, alert_types_count, isOpen, fieldId, crop }) => {
    const formatAlert = (count) => count + " alert " + (count > 1 ? "types" : "type")
    const risksSeen = []
    let alertsToShow = []
    alerts?.variables?.forEach((risk) => {
        if (!risksSeen.includes(risk.risk_alert)) {
            risksSeen.push(risk.risk_alert)
            alertsToShow.push(risk)
        }
    })
    alertsToShow = alertsToShow.length > 5 ? alertsToShow.slice(0, 5) : alertsToShow
    const alertTitle = "Top " + formatAlert(alertsToShow.length) + " for "

    return (
        <div
            className={
                "overflow-hidden flex flex-col items-stretch max-w-[280px] " + (isOpen ? "" : "pointer-events-none")
            }
            id={fieldId + "-popup"}
        >
            <div className="flex flex-row items-center justify-between pb-[4px] space-x-2 h-[20px]">
                <h6 className="text-[16px] text-gray-90 shrink grow whitespace-nowrap truncate">
                    {name}, {region}
                </h6>
                {isOpen ? (
                    <span
                        className="w-[20px] h-[20px] cursor-pointer shrink-0 fill-gray-30 hover:fill-red"
                        id={fieldId + "-closeBtn"}
                    >
                        <CancelIcon />
                    </span>
                ) : (
                    alert_types_count > 0 && (
                        <div className="text-[12px] text-accent shrink-0 font-bold">(Click to interact)</div>
                    )
                )}
            </div>
            <AlertsDesc icon={RedDot} title={alertTitle} value="" crop={crop} alert_types_count={alert_types_count} />
            {alert_types_count > 0 && (
                <>
                    <div className="h-fit min-h-[0px]">
                        {alertsToShow.map((alert, index) => (
                            <AlertListOption
                                icon={getRiskIcon(alert.risk_alert)}
                                title={alert.risk_readable}
                                key={"popup-alert-" + index}
                                id={fieldId + "/" + alert.risk_alert}
                                fieldId={fieldId}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export const getSeasonalMarkerPopup = (
    { id, name, region, maxTemp, minTemp, precipitation, alerts, fixedMarker, alert_types_count, crop },
    units
) => {
    return new mapboxgl.Popup({
        className: "roboto z-full",
        maxWidth: "360px",
        offset: 0,
    }).setHTML(
        ReactDOMServer.renderToStaticMarkup(
            <SeasonalMapMarkerCard
                name={name}
                region={region}
                maxTemp={maxTemp}
                minTemp={minTemp}
                precipitation={precipitation}
                alerts={alerts}
                alert_types_count={alert_types_count}
                isOpen={id === fixedMarker}
                fieldId={id}
                units={units}
                crop={crop}
            />
        )
    )
}

const GridOption = ({ year, yield_impact }) => {
    return (
        <div className="flex flex-row justify-between items-center h-[36px] pt-1 px-1 space-x-[18px] border-b-[1px] border-gray-10">
            <div className="grow text-gray-60 text-[14px] whitespace-nowrap">
                {year} - {+year + 9}
            </div>
            <div className="grow-0 shrink-0 text-[16px] text-gray-90">{yield_impact}%</div>
        </div>
    )
}
const ClimateMapMarkerCard = ({ location, country, decades }) => {
    return (
        <>
            <div className="flex flex-col items-stretch space-y-[4px]">
                <h6 className="text-[16px] text-gray-90">
                    {location}, {country}
                </h6>
                <p className="text-[14px] text-gray-30">Yield average across all crops.</p>
            </div>
            <div className="grid grid-rows-3 grid-flow-col gap-x-[28px]">
                {Object.keys(decades).map((key) => (
                    <GridOption key={key} year={key} yield_impact={decades[key].yield_impact.toFixed(1)} />
                ))}
            </div>
        </>
    )
}

const formatCoords = (lat, lon) => {
    const ns = lat > 0 ? "N" : "S"
    const ew = lon > 0 ? "E" : "W"
    return Math.abs(lat.toFixed(1)) + "\xB0 " + ns + ",\xa0\xa0" + Math.abs(lon.toFixed(1)) + "\xB0 " + ew
}
const AnalogVariable = ({ variable }) => {
    return (
        <div className="flex flex-row items-center gap-2">
            <div className="w-36 truncate font-normal text-gray-60 tracking-[0.6] text-[12px]">{variable.name}</div>
            <div className="relative w-full h-1 grow bg-gray-10">
                <div
                    className="absolute inset-y-0 left-0 bg-accent"
                    style={{ width: (variable.value / 10) * 100 + "%" }}
                ></div>
            </div>
            <div className="font-normal text-[14px] text-gray-90 tracking-[0.25]">{variable.value.toFixed(1)}</div>
        </div>
    )
}
const AnalogMapMarkerCard = ({ value, months, lat, lon, count, isReference, variables, years }) => {
    let variableKeys = []
    if (variables) {
        variableKeys = Object.keys(variables)
    }

    return (
        <div className="pb-1 roboto">
            <h3 className="font-medium text-[14px] text-gray-90 tracking-[0.4]">
                {isReference ? value.replaceAll("_", " ") : "Analog " + count}
            </h3>
            <div
                className={
                    "flex flex-row items-center justify-between gap-6 " +
                    (!isReference && variableKeys?.length > 0 ? "pb-3 mb-3 border-b border-gray-10 " : "")
                }
            >
                <div className="font-normal text-[12px] text-gray-60 tracking-[0.4] pt-2">
                    <div className="">{isReference ? "Reference" : formatCoords(lat, lon)}</div>
                    <div className="pt-1">
                        {years} ({months})
                    </div>
                </div>
                {!isReference && (
                    <div className="font-normal tracking-[0.4] pt-1 text-right">
                        <div className="text-[16px] text-gray-90 tracking-[0.15]">{value.toFixed(1)}</div>
                        <div className="text-[12px] text-gray-60 pt-1">Similarity</div>
                    </div>
                )}
            </div>
            {!isReference && variableKeys?.length > 0 && (
                <div className="flex flex-col gap-2">
                    <h6 className="font-medium text-gray-60 tracking-[0.6] text-[12px] pb-1">Similarity by variable</h6>
                    {variableKeys.map((key) => (
                        <AnalogVariable variable={variables[key]} />
                    ))}
                </div>
            )}
        </div>
    )
}

export const getClimateMarkerPopup = ({ location, country, decades }) => {
    return new mapboxgl.Popup({
        className: "roboto z-full",
        maxWidth: "360px",
        offset: 0,
    }).setHTML(
        ReactDOMServer.renderToStaticMarkup(
            <ClimateMapMarkerCard location={location} country={country} decades={decades} />
        )
    )
}

export const getAnalogMarkerPopup = ({ value, lat, lon, analogMarker, count, months, years, variables }) => {
    return new mapboxgl.Popup({
        className: "roboto z-full",
        maxWidth: "460px",
        offset: 0,
    }).setHTML(
        ReactDOMServer.renderToStaticMarkup(
            <AnalogMapMarkerCard
                value={value}
                months={months}
                lat={lat}
                lon={lon}
                count={count}
                years={years}
                variables={variables}
                isReference={analogMarker === "reference"}
            />
        )
    )
}

const getMarkerElement = ({ alert_types_count, showAlertCount = true, analogMarker = "" }) => {
    const alertsCount = alert_types_count || 0
    const el = document.createElement("div")
    el.className =
        "flex items-top justify-center cursor-pointer p-0 border-0 w-[30px] h-[36px] bg-no-repeat bg-bottom bg-contain"
    el.style.backgroundImage = "url(/map-generic-pin.png)"

    if (alertsCount > 0) {
        el.style.backgroundImage = "url(/map-alert-pin.png)"
        el.innerHTML = `<div class="roboto text-[12px] font-medium text-white mt-2 pointer-events-none">${showAlertCount ? alertsCount : ""
            }</div>`
    }
    if (analogMarker !== "") {
        el.style.backgroundImage = "url(/map-" + analogMarker + "-pin.png)"
    }
    return el
}

export const getMarker = ({ data, map, markerPopup, fixedMarker }) => {
    try {
        return new mapboxgl.Marker({
            element: getMarkerElement({ ...data }),
            anchor: "bottom",
        })
            .setPopup(markerPopup({ ...data, fixedMarker }))
            .setLngLat([data.lon, data.lat])
            .addTo(map)
    } catch (e) {
        console.log(e)
        return null
    }
}

export const setupElementEvents = (element, eventsObj) => {
    Object.keys(eventsObj).forEach((key) => {
        element.addEventListener(key, eventsObj[key])
    })
}

export const getPolygonCenter = (polygon) => {
    let [lng, lat] = [NaN, NaN]
    // Check for point type polygons
    // If it is a single point, return it as the center
    // This is necessary snce d3.polygonCentroid always returns [NaN, NaN] when it gets a single point
    if (polygon.length === 1) [lng, lat] = polygon[0]
    else[lng, lat] = d3.polygonCentroid(polygon)
    if (typeof lng === "number" && typeof lat === "number" && !Number.isNaN(lng) && !Number.isNaN(lat))
        return [lng, lat]
    else return null
}

export const setupPolygonMapLayer = (map, polygons, onClick = () => { }) => {
    const features = polygons.map((polygon) => {
        return {
            type: "Feature",
            properties: polygon,
            geometry: {
                type: "Polygon",
                coordinates: [polygon.polygon],
            },
        }
    })

    if (!map.getSource("polygon-shape") && map.loaded()) {
        map.addSource("polygon-shape", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: features,
            },
        })
        map.addLayer({
            id: "polygon-layer",
            type: "fill",
            source: "polygon-shape",
            layout: {},
            paint: {
                "fill-color": getComputedStyle(document.documentElement).getPropertyValue("--color-primary"),
                "fill-opacity": 0.4,
            },
        })
    }

    setupMapEvents(map, {
        mouseenter: {
            layer: "polygon-layer",
            callback: (e) => {
                if (e.originalEvent.srcElement.tagName !== "CANVAS") return
                map.getCanvas().style.cursor = "pointer"
            },
        },
        mouseleave: {
            layer: "polygon-layer",
            callback: () => {
                map.getCanvas().style.cursor = ""
            },
        },
        click: {
            layer: "polygon-layer",
            callback: (e) => {
                let feature = e.features[0]
                if (feature) {
                    onClick({ id: feature.properties.id })
                }
            },
        },
    })
}

export const setupHeatmapMapLayer = (map, heatmap, isSatellite = false) => {
    if (!heatmap) return
    const rectHeatmapFeatures = heatmap
    const featuresCopy = JSON.parse(JSON.stringify(rectHeatmapFeatures))
    // const maxOpacity = Math.max(...featuresCopy.map((d) => d.properties.opacity))
    // const minOpacity = Math.min(...featuresCopy.map((d) => d.properties.opacity))
    const maxOpacity = 10
    const minOpacity = 0
    const scale = d3
        .scalePow()
        .exponent(heatmap.exponent || 3)
        .domain([minOpacity, maxOpacity])
        .range([0, 1])
    const polygonFeatures = featuresCopy.map((fc) => {
        const lat = fc.geometry.coordinates[0]
        const lng = fc.geometry.coordinates[1]
        const longitudeWidth = fc.properties.longitudeWidth
        const latitudeHeight = fc.properties.latitudeHeight
        const leftTop = [lng - longitudeWidth / 2, lat + latitudeHeight / 2]
        const rightTop = [lng + longitudeWidth / 2, lat + latitudeHeight / 2]
        const rightBot = [lng + longitudeWidth / 2, lat - latitudeHeight / 2]
        const leftBottom = [lng - longitudeWidth / 2, lat - latitudeHeight / 2]
        const scaledOpacity = scale(fc.properties.opacity)
        let colors = Array.isArray(fc.properties.color) ? fc.properties.color : [fc.properties.color]
        if (isSatellite) {
            colors = Array.isArray(fc.properties.satelliteColors)
                ? fc.properties.satelliteColors
                : [fc.properties.satelliteColors]
        }
        const colorScale = d3.interpolateRgbBasis(colors)
        const scaledColor = colorScale(scaledOpacity)
        return {
            type: "Feature",
            properties: Object.assign({}, fc.properties, {
                opacity: scaledOpacity,
                scaledColor: scaledColor,
            }),
            geometry: {
                type: "Polygon",
                coordinates: [[leftTop, rightTop, rightBot, leftBottom, leftTop]],
            },
        }
    })

    const data = {
        type: "FeatureCollection",
        features: polygonFeatures,
    }

    map.addSource("rectangular-heatmap-points-source", {
        type: "geojson",
        data: data,
    })

    map.addLayer({
        id: "rectangular-heatmap-points-layer",
        type: "fill",
        source: "rectangular-heatmap-points-source",
        layout: {},
        paint: {
            "fill-color": ["get", "scaledColor"],
            "fill-opacity": ["get", "opacity"],
            "fill-outline-color": "rgba(0,0,0,0)",
        },
    })
}

const setupMapEvents = (map, eventsObj) => {
    Object.keys(eventsObj).forEach((key) => {
        map.on(key, eventsObj[key].layer, eventsObj[key].callback)
    })
}

// Calculate right top end coordinates (lat,lon) of passed polygon
export const maxRightTop = (polygonCoords) => {
    const maxX = Math.max(...polygonCoords.map((d) => d[0]))
    const maxY = Math.max(...polygonCoords.map((d) => d[1]))
    return [maxX, maxY]
}

// Calculate left bottom end coordinates (lat,lon) of passed polygon
export const minLeftBottom = (polygonCoords) => {
    const minX = Math.min(...polygonCoords.map((d) => d[0]))
    const minY = Math.min(...polygonCoords.map((d) => d[1]))
    return [minX, minY]
}

// Calculate right top end coordinates (lat,lon) of passed multi polygon figures
export const maxRightTopMulti = (polygonCoords) => {
    const maxes = polygonCoords.map((d) => maxRightTop(d))
    const maxX = Math.max(...maxes.map((d) => d[0]))
    const maxY = Math.max(...maxes.map((d) => d[1]))
    return [maxX, maxY]
}

// Calculate left bottom end coordinates (lat,lon) of passed multi polygon figures
export const minLeftBottomMulti = (polygonCoords) => {
    const mins = polygonCoords.map((d) => minLeftBottom(d))
    const minX = Math.min(...mins.map((d) => d[0]))
    const minY = Math.min(...mins.map((d) => d[1]))
    return [minX, minY]
}

export const initializeMap = ({
    mapContainerRefCurrent,
    layer = "",
    center = [0, 25],
    zoom = 1.4,
    minZoom,
    renderWorldCopies = true,
    maxZoom = 10,
}) => {
    const map = new mapboxgl.Map({
        container: mapContainerRefCurrent,
        style: layer !== "" ? layer : MAP_DEFAULT_LAYER,
        center,
        zoom,
        dragRotate: false,
        minZoom,
        maxZoom,
        renderWorldCopies,
    })
    map.dragRotate.disable()
    map.touchZoomRotate.disableRotation()

    map.on("load", () => {
        map.resize()
    })

    return map
}

const removePolygonLayers = (map) => {
    removeMapLayerAndSource(map, { layer: "polygon-layer", source: "polygon-shape" })
}
const removeHeatmapLayers = (map) => {
    removeMapLayerAndSource(map, {
        layer: "rectangular-heatmap-points-layer",
        source: "rectangular-heatmap-points-source",
    })
}

export const removeLayers = (map) => {
    removePolygonLayers(map)
    removeHeatmapLayers(map)
}

export const removeMapLayerAndSource = (map, names) => {
    if (names.layer && map && map.getLayer(names.layer)) map.removeLayer(names.layer)
    if (names.source && map && map.getSource(names.source)) map.removeSource(names.source)
}

export const removeMarkers = (markers) => {
    if (!markers) return
    markers.forEach((marker) => marker.remove())
}

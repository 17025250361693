import * as React from "react"

const FunnelAltIcon = (props) => (
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M16.492 4.675a.832.832 0 0 0-.659-1.342H5.692l6.641 6.642 4.159-5.3ZM2.342 2.342 1.158 3.517l7.175 7.316v5c0 .459.375.834.834.834h1.666a.836.836 0 0 0 .834-.834v-1.808l4.816 4.817 1.175-1.175L2.342 2.342Z"
            fill="#666D74"
        />
    </svg>
)

export default FunnelAltIcon

import { useState, useEffect, useMemo, useContext } from "react"
import "./Overview.css"
import "./components/OverviewRisks.css"
import TableColumn from "../../ui/Table/TableColumn"
import SimpleTable from "../../ui/Table/SimpleTable"
import useOverviewData from "../../hooks/useOverviewData"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"

import ScenarioDropdownSelection from "../../ui/Controls/ScenarioDropdownSelection"
import DebounceSearchInput from "../../components/DebounceSearchInput/DebounceSearchInput"
import ExpandIcon from "../../ui/Icons/ExpandIcon"
import CollapseIcon from "../../ui/Icons/CollapseIcon"
import Tooltip from "../../ui/Tooltip"
import GranularityButton from "../Seasonal/components/SeasonalVariableTabs/components/GranularityButton"
import { capitalizeFirstCharacter } from "../../helpers/wordHelper"
import DecadeDropdownSelection from "../../ui/Controls/DecadeDropdownSelection"
import getRiskIcon from "../../Util/getRiskIcon"
import LoadingBar from "../../components/LoadingBar"
import { getClimateMarkerPopup } from "../../components/Charts/MapboxMaps/mapboxUtils"
import DotsIcon from "../../ui/Icons/DotsIcon"
import { REMEMBERED_CLIMATE_TABLE_FILTERS } from "../../Util/localStorageVariables"
import { formatOverviewCSVData, OVERVIEW_CSV_HEADERS } from "../../Util/CSVParser"
import ExportButton from "../../components/ExportButton"
import { ToastContext } from "../../components/Toast/ToastContextProvider"
import MapboxGenericMap from "../../components/Charts/MapboxMaps/MapboxGenericMap"
import { SettingsContext } from "../../Util/SettingsContext"
import { convertDistance, convertSpeed, convertTemperature, convertWaterLength } from "../../Util/UnitConversion"

const formatFilters = (filters, auxSearchInput) => {
    if (!filters) return "None"
    let formatedFilters = filters
        .map((filter) => capitalizeFirstCharacter(filter.propName) + ": " + filter.value)
        .join(" / ")

    if (auxSearchInput !== "")
        formatedFilters =
            formatedFilters + (formatedFilters !== "" ? " / " : "") + 'Search input: "' + auxSearchInput + '"'

    if (formatedFilters === "") return "No filters"

    return formatedFilters
}

export const formatRiskDescription = (riskDescription, units) => {
    switch (units) {
        case "metric":
            // Default for risk descriptions IS metric, we are good to go!
            // TODO: This might change later on?
            return capitalizeFirstCharacter(riskDescription)

        case "imperial":
            // get values with units
            let valueToConvert = riskDescription.split(/((-?[.\d]+)\s*(?:mm|°C|k\/h|km|hours))/)[1]
            if (!valueToConvert) return capitalizeFirstCharacter(riskDescription)

            // remove spaces
            valueToConvert = valueToConvert.replaceAll(" ", "")
            let convertedValue = undefined
            if (valueToConvert !== undefined) {
                if (valueToConvert.includes("mm"))
                    convertedValue = convertWaterLength(
                        "metric",
                        "imperial",
                        Number(valueToConvert.slice(0, valueToConvert.indexOf("mm")))
                    )
                else if (valueToConvert.includes("°C"))
                    convertedValue = convertTemperature(
                        "metric",
                        "imperial",
                        Number(valueToConvert.slice(0, valueToConvert.indexOf("°C")))
                    )
                else if (valueToConvert.includes("km"))
                    convertedValue = convertDistance(
                        "metric",
                        "imperial",
                        Number(valueToConvert.slice(0, valueToConvert.indexOf("km")))
                    )
                else if (valueToConvert.includes("k/h"))
                    convertedValue = convertSpeed(
                        "metric",
                        "imperial",
                        Number(valueToConvert.slice(0, valueToConvert.indexOf("k/h")))
                    )
            }
            if (convertedValue !== undefined)
                return capitalizeFirstCharacter(riskDescription.replace(valueToConvert, convertedValue))
            return capitalizeFirstCharacter(riskDescription)
    }
}

const Overview = ({ history }) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [expanded, toggleExpanded] = useState(false)
    const [selectedTableType, setSelectedTableType] = useState("decadal")

    const [selectedPlots, setSelectedPlots] = useState([])
    const [filters, setFilters] = useState([])
    const [auxSearchInput, setAuxSearchInput] = useState("")

    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const { enqueueNetworkError } = useContext(ToastContext)

    const {
        ready,
        allLocations: locations = {},
        plots,
        scenarios,
        selectedScenario,
        setSelectedScenario,
        setSelectedLocation,
        setSelectedPlot,
        risks,
        riskList,
        decades,
        selectedDecade,
        setSelectedDecade,
    } = useAdaptationValues()

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        const request = http.get(`/api/v1/adapt/v2/overview?scenario=${selectedScenario}&decade=2010`, {
            timeout: 180000,
        })
        request
            .then((response) => {
                setData(response.data)
            })
            .catch((err) => {
                console.log(err)
                enqueueNetworkError()
            })
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedScenario])

    const _plots = useMemo(() => Object.values(plots), [plots])

    // Transform Info
    const { tableData } = useOverviewData(_plots, data)

    // create risks array (iteration per location)
    const tableColumns = useMemo(() => {
        const cols = []

        new Array(6).fill(null).forEach((_, index) => {
            const decade = 2000 + 10 * (index + 1)

            cols.push({
                propName: `overview_${decade}`,
                displayName: `${decade} - ${decade + 9}`,
                meta: { decade },
            })
        })
        return cols
    }, [risks])

    const mapData = useMemo(() => {
        return selectedPlots
            .filter((p) => data[p.id])
            .map((p) => {
                let key = p.id
                const coords = JSON.parse(data[key]?.coords)

                return {
                    ...data[key],
                    lat: coords[0],
                    lon: coords[1],
                }
            })
    }, [data, selectedPlots])

    return (
        <>
            <div className="overview">
                <div className="overview__main">
                    <div className="w-full h-full xl:border-x border-x-gray-10">
                        {loading && <LoadingBar loading />}
                        <div className="w-full h-full xl:border-x border-x-gray-10">
                            <div
                                className={
                                    "w-full transition-all duration-200 h-[0%] overflow-hidden opacity-0 " +
                                    (expanded ? "" : "h-[80%] lg:h-[50%] overflow-visible opacity-100")
                                }
                            >
                                <MapboxGenericMap
                                    data={mapData}
                                    markerPopup={getClimateMarkerPopup}
                                    mapLocationOnClick={({ location }) => {
                                        setSelectedLocation(
                                            Object.keys(locations).filter(
                                                (key) => locations[key].location === location
                                            )[0]
                                        )
                                        history.push("/climate/decadal-risk")
                                    }}
                                />
                            </div>
                            <div
                                className={
                                    "w-full transition-all duration-200 h-full bg-gray-5 z-full " +
                                    (expanded ? "" : "h-[20%] lg:h-[50%]")
                                }
                            >
                                <SimpleTable
                                    data={tableData}
                                    units={units}
                                    onSelectionChanged={(selected, options) => {
                                        setFilters(options.filters)
                                        setSelectedPlots(selected)
                                    }}
                                    exportFormats={{
                                        CSV: {
                                            func: formatOverviewCSVData,
                                            headers: OVERVIEW_CSV_HEADERS,
                                            headerSection: `Overview of yield impact per location
                                            Scenario:,${!loading && scenarios[selectedScenario].label}
                                            Filters:,${!loading && formatFilters(filters, auxSearchInput)}
                                            \n`,
                                        },
                                    }}
                                    exportFilename="climate_overview"
                                    expanded={expanded}
                                    filterStorage={REMEMBERED_CLIMATE_TABLE_FILTERS}
                                    loading={loading}
                                    renderTableTopBar={(handleSearchBarInputChange, _, renderExportButton) => {
                                        return (
                                            <div
                                                className={
                                                    "flex w-full flex-col lg:flex-row justify-center gap-4 lg:justify-between " +
                                                    "items-center px-5 py-3 lg:py-0 h-full"
                                                }
                                            >
                                                <h4 className="text-[16px] montserrat font-semibold">
                                                    Yield Risk Index Overview
                                                </h4>
                                                {ready && !loading && (
                                                    <div className="flex flex-row flex-wrap items-center justify-center gap-2">
                                                        {selectedTableType === "risk" && (
                                                            <DecadeDropdownSelection
                                                                selectedDecade={selectedDecade}
                                                                decades={decades}
                                                                setSelectedDecade={setSelectedDecade}
                                                                keyPrefix={"decadal_scenario"}
                                                            />
                                                        )}
                                                        <ScenarioDropdownSelection
                                                            selectedScenario={selectedScenario}
                                                            scenarios={scenarios}
                                                            setSelectedScenario={setSelectedScenario}
                                                            keyPrefix={"overview_scenario"}
                                                        />
                                                        <div className="grow-0">
                                                            {["decadal", "risk"].map((type, index) => {
                                                                return (
                                                                    <GranularityButton
                                                                        key={`table_type_${type}`}
                                                                        onClick={() => setSelectedTableType(type)}
                                                                        isSelected={selectedTableType === type}
                                                                        label={capitalizeFirstCharacter(type) + " View"}
                                                                        isLeft={index === 0}
                                                                        isRight={index === 1}
                                                                        isPossible={true}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                        {selectedTableType === "decadal" && renderExportButton()}
                                                        <div className="w-48">
                                                            <DebounceSearchInput
                                                                onSearch={(text) => {
                                                                    setAuxSearchInput(text)
                                                                    handleSearchBarInputChange(text)
                                                                }}
                                                            />
                                                        </div>
                                                        <Tooltip
                                                            position="bottom"
                                                            content={(expanded && "Collapse Table") || "Expand Table"}
                                                            contentClass="mr-16"
                                                        >
                                                            <button
                                                                className="btn w-[24px] h-[24px]"
                                                                onClick={() => toggleExpanded(!expanded)}
                                                            >
                                                                {(!expanded && (
                                                                    <ExpandIcon fill="var(--color-gray-60)" />
                                                                )) || <CollapseIcon fill="var(--color-gray-60)" />}
                                                            </button>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }}
                                >
                                    <TableColumn
                                        propName="location"
                                        displayName="Location"
                                        style={{ minWidth: 160, flex: 1.5 }}
                                        headerStyle={{ minWidth: 160, flex: 1.5 }}
                                        render={(value, rowData) => {
                                            return (
                                                <Tooltip content={"Go to " + value} position="right">
                                                    <button
                                                        className="underline btn text-accent"
                                                        onClick={() => {
                                                            // setSelectedLocation(value)
                                                            setSelectedPlot(rowData.id)
                                                            history.push("/climate/decadal-risk")
                                                        }}
                                                    >
                                                        {value}
                                                    </button>
                                                </Tooltip>
                                            )
                                        }}
                                        renderSummary={() => {
                                            return "Summary"
                                        }}
                                        sortable
                                        filter
                                        sticky
                                    />
                                    <TableColumn
                                        propName="region"
                                        displayName="Region"
                                        style={{ minWidth: 150, flex: 1 }}
                                        headerStyle={{ minWidth: 150, flex: 1 }}
                                        filter
                                        sortable
                                    />
                                    <TableColumn
                                        propName="crop"
                                        displayName="Category"
                                        style={{ minWidth: 150, flex: 1 }}
                                        headerStyle={{ minWidth: 150, flex: 1 }}
                                        filter
                                        sortable
                                    />
                                    <TableColumn
                                        propName="variety"
                                        displayName="Subcategory"
                                        style={{ minWidth: 150, flex: 1 }}
                                        headerStyle={{ minWidth: 150, flex: 1 }}
                                        filter
                                        sortable
                                    />
                                    {ready &&
                                        selectedTableType === "decadal" &&
                                        tableColumns &&
                                        tableColumns.map((col) => {
                                            return (
                                                <TableColumn
                                                    key={`overview_tablecol_${col.propName}`}
                                                    propName={col.propName}
                                                    headerRender={() => {
                                                        return (
                                                            <button
                                                                onClick={() => {
                                                                    setSelectedDecade(col?.meta?.decade)
                                                                    setSelectedTableType("risk")
                                                                }}
                                                                className="flex items-center justify-center w-full h-full font-bold underline btn hover:bg-gray-10"
                                                            >
                                                                {col.displayName}
                                                            </button>
                                                        )
                                                    }}
                                                    style={{ minWidth: 150, flex: 1 }}
                                                    headerStyle={{ minWidth: 150, flex: 1 }}
                                                    render={(v) =>
                                                        (typeof v === "number" && v.toFixed(2) + "%") || "N/A"
                                                    }
                                                    renderSummary={(d) => {
                                                        return `Avg ${
                                                            (typeof d === "number" &&
                                                                !Number.isNaN(d) &&
                                                                d.toFixed(2)) ||
                                                            "0.00"
                                                        }%`
                                                    }}
                                                    summarize
                                                    sortable
                                                />
                                            )
                                        })}
                                    {ready && selectedTableType === "risk" && (
                                        <>
                                            <TableColumn
                                                key={`overview_tablecol_${selectedDecade}`}
                                                propName={`overview_${selectedDecade}`}
                                                displayName={`${selectedDecade} - ${parseInt(selectedDecade) + 9}`}
                                                style={{ minWidth: 150, flex: 1 }}
                                                headerStyle={{ minWidth: 150, flex: 1 }}
                                                render={(v) => (typeof v === "number" && v.toFixed(2) + "%") || "N/A"}
                                                renderSummary={(d) => {
                                                    return `Avg ${
                                                        (typeof d === "number" && !Number.isNaN(d) && d.toFixed(2)) ||
                                                        "0.00"
                                                    }%`
                                                }}
                                                summarize
                                                sortable
                                            />
                                            {/* TODO Risk table column */}
                                            <TableColumn
                                                propName={`risks`}
                                                displayName={"Top Risks"}
                                                style={{ minWidth: 600, flex: 1 }}
                                                headerStyle={{ minWidth: 600, flex: 1 }}
                                                render={(v, rowData) => {
                                                    const { id: plotId } = rowData

                                                    if (!rowData.decades) return null
                                                    const riskValues = rowData.decades[selectedDecade]?.risk_values

                                                    const sortedRisks = Object.keys(riskValues)
                                                        .sort((a, b) => {
                                                            if (Math.abs(riskValues[b]) > Math.abs(riskValues[a]))
                                                                return 1
                                                            else if (Math.abs(riskValues[b]) < Math.abs(riskValues[a]))
                                                                return -1
                                                            else return 0
                                                        })
                                                        .map((id) => ({ id, value: riskValues[id] }))

                                                    // make average when items exeed 4 length
                                                    const MAX_INDIVIDUAL_RISK_NUMBER = 4
                                                    let result = []
                                                    let otherRisks = []
                                                    let sum = 0
                                                    for (let i = 0; i < sortedRisks.length; i++)
                                                        if (i < MAX_INDIVIDUAL_RISK_NUMBER) result.push(sortedRisks[i])
                                                        else {
                                                            otherRisks.push(sortedRisks[i])
                                                            let v = parseFloat(sortedRisks[i].value)
                                                            sum += !Number.isNaN(v) ? v : 0
                                                        }

                                                    return (
                                                        <>
                                                            <div className="flex items-center justify-center w-full h-full gap-x-7">
                                                                {result.map(({ id, value }) => {
                                                                    const _risk = plots[plotId].risks[id]
                                                                    const IconComponent = getRiskIcon(_risk?.icon)

                                                                    return (
                                                                        <div
                                                                            key={`table_risk_${id}`}
                                                                            className="flex items-center w-auto h-full gap-x-2"
                                                                        >
                                                                            <Tooltip
                                                                                white
                                                                                size="medium"
                                                                                content={
                                                                                    <div className="w-full h-full p-2 flex items-start text-left text-[14px] ">
                                                                                        <div className="w-[24px] fill-gray-60">
                                                                                            {IconComponent && (
                                                                                                <IconComponent />
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="flex-1 pl-3 pt-.5">
                                                                                            <h6 className="text-body">
                                                                                                {_risk?.title}
                                                                                            </h6>
                                                                                            <p className="pt-1 text-gray-30 roboto">
                                                                                                {_risk?.stage}
                                                                                            </p>
                                                                                            <p className="pt-1">
                                                                                                {(riskList[id]
                                                                                                    .description &&
                                                                                                    formatRiskDescription(
                                                                                                        riskList[id]
                                                                                                            ?.description,
                                                                                                        units
                                                                                                    )) ||
                                                                                                    "No description available."}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                position="left"
                                                                            >
                                                                                <div className="w-[20px] h-[20px] cursor-pointer fill-gray-60">
                                                                                    {IconComponent && <IconComponent />}
                                                                                </div>
                                                                            </Tooltip>
                                                                            {(typeof value === "number" &&
                                                                                !Number.isNaN(value) &&
                                                                                value.toFixed(2)) ||
                                                                                "0.00"}
                                                                            %
                                                                        </div>
                                                                    )
                                                                })}
                                                                {otherRisks.length > 0 && (
                                                                    <div className="flex items-center w-auto h-full gap-x-2">
                                                                        <Tooltip
                                                                            white
                                                                            size="medium"
                                                                            content={
                                                                                <div className="w-full h-full p-2 items-start flex text-left text-[14px] ">
                                                                                    <div className="w-[24px] h-[24px] fill-gray-60">
                                                                                        <DotsIcon />
                                                                                    </div>
                                                                                    <div className="flex-1 pl-3 pt-.5">
                                                                                        <h6 className="text-body">
                                                                                            Other Risks
                                                                                        </h6>
                                                                                        <p className="pt-1 text-gray-30 roboto">
                                                                                            ({otherRisks.length})
                                                                                        </p>
                                                                                        <p className="pt-1">
                                                                                            The rest of the risks
                                                                                            account for {sum.toFixed(2)}
                                                                                            % of the total yield impact
                                                                                            for this decade.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            position="bottom"
                                                                        >
                                                                            <div className="w-[20px] h-[20px] cursor-pointer">
                                                                                <DotsIcon />
                                                                            </div>
                                                                        </Tooltip>
                                                                        {sum.toFixed(2)}%
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* TODO add average item */}
                                                        </>
                                                    )
                                                }}
                                            />
                                        </>
                                    )}
                                </SimpleTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Overview

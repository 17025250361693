import { Switch, Redirect, useHistory } from "react-router-dom"

// Views
import Overview from "../views/Overview/Overview"
import DecadalRisk from "../views/DecadalRisk/DecadalRisk"
import SeasonalRisk from "../views/SeasonalRisk/SeasonalRisk"
import DecadalTrend from "../views/DecadalTrend/DecadalTrend"
import SeasonalTrend from "../views/SeasonalTrend/SeasonalTrend"
import Departure from "../views/Departure/Departure"
import Analogs from "../views/Analogs/Analogs"
import Portfolio from "../views/Portfolio/Portfolio"
import Historical from "../views/Historical/Historical"
import { RouteWithLayout, TopBar } from "../components"
import { ClimateLayout } from "../layouts"
import { ProfileSettings } from "../views"
import { AuthContext } from "../Auth/Auth"
import { useContext, useEffect, useState } from "react"
import { useMediaQuery } from "@material-ui/core"
import NotAllowedDevice from "../views/NotAllowedDevice"

function ClimateRouter(props) {
    const PROFILE_OPTIONS = [
        {
            id: "user-settings__user-profile",
            label: "User Settings",
            to: "/climate/user-settings",
        },
    ]

    const history = useHistory()
    const tabletMedia = useMediaQuery("(max-width: 768px)")

    const [currentPageURL, setCurrentPageURL] = useState(window.location.href)

    useEffect(() => {
        history.listen((location) => {
            setCurrentPageURL(location.pathname)
        })
    }, [history])

    const { currentUser } = useContext(AuthContext)
    if (!currentUser) return <Redirect to="/" />

    if (tabletMedia) return <NotAllowedDevice />

    return (
        <div className="climate flex flex-col h-full">
            <TopBar profileOptions={PROFILE_OPTIONS} url={currentPageURL} />
            <div className="grow overflow-auto">
                <Switch>
                    {/* <PrivateRoute exact path="/climate/reports" component={LongTerm} /> */}
                    <RouteWithLayout
                        requiresPermission={false}
                        component={ProfileSettings}
                        exact
                        layout={ClimateLayout}
                        path="/climate/user-settings"
                        title="User Settings"
                        settings
                    />
                    <RouteWithLayout
                        component={Overview}
                        exact
                        layout={ClimateLayout}
                        path="/climate/overview"
                        header="Climate Risk Overview"
                    />
                    <RouteWithLayout
                        component={DecadalRisk}
                        exact
                        layout={ClimateLayout}
                        path="/climate/decadal-risk"
                        header="Climate Risk by Decade"
                    />
                    <RouteWithLayout
                        component={SeasonalRisk}
                        exact
                        layout={ClimateLayout}
                        path="/climate/seasonal-risk"
                        header="Climate Risk by Month"
                    />
                    <RouteWithLayout
                        component={DecadalTrend}
                        exact
                        layout={ClimateLayout}
                        path="/climate/decadal-trend"
                        header="Climate Risk by Decade"
                    />
                    <RouteWithLayout
                        component={SeasonalTrend}
                        exact
                        layout={ClimateLayout}
                        path="/climate/seasonal-trend"
                        header="Climate Risk by Month"
                    />
                    <RouteWithLayout
                        component={Departure}
                        exact
                        layout={ClimateLayout}
                        path="/climate/departure"
                        header="Year of Departure"
                    />
                    <RouteWithLayout
                        component={Analogs}
                        exact
                        layout={ClimateLayout}
                        path="/climate/analogs"
                        header="Climate Analogs"
                    />
                    <RouteWithLayout
                        component={Portfolio}
                        exact
                        layout={ClimateLayout}
                        path="/climate/portfolio"
                        header="Portfolio Analysis"
                    />
                    <RouteWithLayout
                        component={Historical}
                        exact
                        layout={ClimateLayout}
                        path="/climate/historical"
                        header="Historical Validation"
                    />
                    {/* <RouteWithLayout
                        component={ReportBuilder}
                        exact
                        layout={ClimateLayout}
                        path="/climate/:reportId/builder"
                        header="Report Builder"
                    /> */}
                    <Redirect to="/climate/overview" />
                </Switch>
            </div>
        </div>
    )
}

export default ClimateRouter

import ToolsIcon from "../Icons/Sidebar/ToolsIcon"
import SettingsIcon from "../Icons/Sidebar/SettingsIcon"
import MapWorldIcon from "../Icons/Sidebar/MapWorldIcon"
import ClimateRiskIcon from "../Icons/Sidebar/ClimateRiskIcon"
import AnalogsIcon from "../Icons/Sidebar/AnalogsIcon"
import HistoricalIcon from "../Icons/Sidebar/HistoricalIcon"
import SeasonalIcon from "../Icons/Sidebar/SeasonalIcon"
import ClimateTrendsIcon from "../Icons/Sidebar/ClimateTrendsIcon"
import AltArrowRight from "../Icons/newIcons/AltArrowRight"

const SETTINGS_NAV_ITEM = {
    name: "Settings",
    icon: SettingsIcon,
    children: [
        {
            view: "/risk-profiles",
            to: "/weather/risk-profiles",
            name: "Risk Profiles",
        },
        {
            view: "/risk-categories",
            to: "/weather/risk-categories",
            name: "Risk Categories",
        },
    ],
    settings: true,
}

export const SEASONAL_NAV = ({ fieldId, openFieldList }) => [
    {
        view: "/overview",
        to: "/weather",
        name: "Overview",
        permission: "general",
        icon: MapWorldIcon,
    },
    {
        view: "/seasonal",
        to: `/weather/${fieldId}`,
        name: "Seasonal Model",
        permission: "weather",
        icon: SeasonalIcon,
        nextIcon: ({ active }) => {
            return (
                <button
                    className={[
                        "h-5 w-5",
                        active ? "fill-accent" : "fill-gray-60",
                        "transition-all duration-200",
                        "hover:scale-[1.2]",
                        "focus:outline-accent",
                    ].join(" ")}
                    onClick={openFieldList}
                >
                    <AltArrowRight />
                </button>
            )
        },
    },
    {
        name: "Optimization Tools",
        icon: ToolsIcon,
        nextIcon: ({ active }) => {
            return (
                <div
                    className={[
                        "h-5 w-5",
                        active ? "fill-accent" : "fill-gray-60",
                        "transition-all duration-200",
                        "hover:scale-[1.2]",
                    ].join(" ")}
                    onClick={openFieldList}
                >
                    <AltArrowRight />
                </div>
            )
        },
        children: [
            {
                view: "/planting_tool",
                to: `/weather/${fieldId}/planting_tool`,
                name: "Planting Tool",
                permission: "planting_tool",
            },
            {
                view: "/harvesting_tool",
                to: `/weather/${fieldId}/harvesting_tool`,
                name: "Harvesting Tool",
                permission: "harvesting_tool",
            },
            {
                view: "/spraying_tool",
                to: `/weather/${fieldId}/spraying_tool`,
                name: "Spraying Tool",
                permission: "spraying_tool",
            },
            {
                view: "/nitrogen_application_tool",
                to: `/weather/${fieldId}/nitrogen_application_tool`,
                name: "Nitrogen Application Tool",
                permission: "nitrogen_application_tool",
            },
        ],
    },
    SETTINGS_NAV_ITEM,
]

export const CLIMATE_NAV = (settings) => [
    {
        view: "/overview",
        to: (settings && `/climate/reports`) || `/climate/overview`,
        name: (settings && "Home") || "Overview",
        icon: MapWorldIcon,
    },
    {
        name: "Climate Risk",
        children: [
            { view: "/decadal-risk", to: `/climate/decadal-risk`, name: "Decadal Risk" },
            { view: "/seasonal-risk", to: `/climate/seasonal-risk`, name: "Monthly Risk" },
        ],
        icon: ClimateRiskIcon,
    },
    {
        name: "Climate Trends",
        children: [
            { view: "/decadal-trend", to: `/climate/decadal-trend`, name: "Decadal Trend" },
            { view: "/seasonal-trend", to: `/climate/seasonal-trend`, name: "Monthly Trend" },
            { view: "/departure", to: `/climate/departure`, name: "Climate Tipping Point" },
        ],
        icon: ClimateTrendsIcon,
    },
    {
        view: "/analogs",
        to: `/climate/analogs`,
        name: "Climate Analogs",
        icon: AnalogsIcon,
    },
    {
        view: "/historical",
        to: `/climate/historical`,
        name: "Historical Validation",
        icon: HistoricalIcon,
    },
]

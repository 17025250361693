export function isValidValue(type, value) {
    // validations like this would need a lot more lol but it's fine for now
    switch (type) {
        case "string": {
            return typeof value === "string" && value.length > 0
        }
        case "file": {
            return value instanceof File && typeof value.name === "string" && value.name.length > 0
        }
        default:
            return false
    }
}

export function getRowsFromGroups(data = []) {
    let rows = []
    data.forEach((item) => {
        if (item.__group) rows = rows.concat(getRowsFromGroups(item.data))
        else rows.push(item)
    })
    return rows
}

// export function getGroupPathsFromUUIDS(data = [], uuids = []) {}

export function transformData(
    data,
    // groupBy,
    sortBy,
    sortFunctionName,
    inputValue,
    // filters,
    // rows,
    // currentPage,
    columns
    // selectedLabels
) {
    let displayData = [...data]

    // filter by selected labels (deprecated)
    // displayData = filterBySelectedLabels(data, selectedLabels)
    // groupBy transform (deprecated)
    // displayData = groupData(displayData, groupBy, columns)
    // apply transform from columns (deprecated)
    displayData = applyFieldTransformation(displayData, columns)
    // inputValue transform
    displayData = filterDataByInput(displayData, inputValue)
    // sortBy/asc trasnform
    displayData = sortData(displayData, sortBy, sortFunctionName, columns)

    return displayData
}

function filterBySelectedLabels(data, selectedLabels) {
    const hashLabels = {}
    for (let _label of selectedLabels) hashLabels[_label.name] = true

    if (selectedLabels.length === 0) return data

    return data.filter((rowData) => {
        return (rowData.labels && rowData.labels.find((_label) => hashLabels[_label.name])) || false
    })
}

export function summarizeRows(data = [], columns = []) {
    const summaries = {}

    columns
        .filter((col) => col.summarize)
        .forEach((col) => {
            if (col.summarizeFunction && typeof col.summarizeFunction === "function") {
                summaries[col.propName] = col.summarizeFunction(data)
                return
            }

            let colSum = 0
            data.forEach((rowData) => (colSum += rowData[col.propName]))
            summaries[col.propName] = (colSum !== 0 && colSum / data.length) || 0
        })

    return summaries
}

/**
 *
 * @param {[Object]} data
 * @param {[Object]} filters
 */
export function applyFilters(data = [], filters = [], columns = []) {
    const availableFilterOptions = {}
    const hash = {}

    let filteringPropNames = columns.filter((c) => c.filter).map((c) => c.propName)
    filteringPropNames.forEach((propName) => (availableFilterOptions[propName] = new Set()))

    filters.forEach((f) => {
        if (!hash[f.propName]) hash[f.propName] = []
        hash[f.propName].push(f.value)
    })

    let _result = [...data]
    Object.keys(hash).forEach((_propName) => {
        const column = columns.find((col) => col.propName === _propName)

        // fill columns as we iterate filters
        _result.forEach((rowData) => {
            // fill
            if (column.columnFilterOptions && typeof column.columnFilterOptions === "function") {
                availableFilterOptions[_propName] = column.columnFilterOptions(
                    rowData,
                    availableFilterOptions[_propName]
                )
            } else {
                availableFilterOptions[_propName].add(rowData[_propName])
            }
        })

        _result = _result.filter((rowData) => {
            if (column.filterFunction && typeof column.filterFunction === "function")
                return column.filterFunction(rowData, hash[_propName])
            else return hash[_propName].includes(rowData[_propName])
        })
        filteringPropNames = filteringPropNames.filter((p) => p !== _propName)
    })

    // once column filters are done, we fill all the left columns
    _result.forEach((rowData) => {
        filteringPropNames.forEach((propName) => {
            const column = columns.find((col) => col.propName === propName)
            if (column.columnFilterOptions && typeof column.columnFilterOptions === "function") {
                availableFilterOptions[propName] = column.columnFilterOptions(rowData, availableFilterOptions[propName])
            } else {
                availableFilterOptions[propName].add(rowData[propName])
            }
        })
    })

    return { data: _result, availableFilterOptions }
}

/**
 *
 * @param {[Object]} data
 * @param {[Object]} columns
 */
export function applyFieldTransformation(data, columns) {
    // save fields to transform
    const hash = {}
    // get transform fields function
    for (let _column of columns) {
        const { transform } = _column
        // transform function exists, add it
        if (transform && transform instanceof Function) {
            hash[_column.propName] = transform
        }
    }

    // if there's no transformations, return
    if (Object.keys(hash).length === 0) return data

    return data.map((rowData) => {
        const _rowData = { ...rowData }

        for (let _propName in hash) {
            _rowData[_propName] = hash[_propName](rowData[_propName], rowData)
        }

        return _rowData
    })
}

/**
 *
 * @param {[Object]} data
 * @param {String} inputValue
 */
export function filterDataByInput(data, inputValue) {
    return data.filter((item, index) => {
        // map throught each field
        for (let _field in item) {
            // stringify field value
            // check for substring
            // if exists, add object to new array
            if (item[_field] && JSON.stringify(item[_field]).toLowerCase().includes(inputValue.toLowerCase()))
                return true
        }
        return false
    })
}

/**
 *
 * @param {[Object]} data
 * @param {[String]} groupBy
 * @param {[Object]} fields
 */
export function groupData(data, groupBy) {
    // don't mutate groupBy array
    const _groupBy = [...groupBy]

    // first group
    let group = _groupBy[0]
    if (!group) return data

    const { propName } = group
    const hashValues = {}

    data.forEach((curr) => {
        // extract current value
        const currentValue = curr[propName]
        // current value has key on hash map?
        if (!hashValues[currentValue])
            // if it doesn't, create new object with the data that we need
            // initialize data array []
            // hashValues[currentValue] = { propName, displayName, value: currentValue, data: [] } // <<< later maybe
            hashValues[currentValue] = {
                [propName]: currentValue,
                data: [],
                __group: true,
                __propName: propName,
                __count: 0,
            }

        // since hashValues[currentValue] now exists, push object to data array
        hashValues[currentValue].data.push(curr)
        hashValues[currentValue].__count++
    })

    const arrayValues = Object.values(hashValues)

    // repeat for each data array inside arrayValues
    if (_groupBy.length > 0)
        arrayValues.forEach((item) => {
            item.data = groupData(item.data, _groupBy.slice(1))
        })

    return arrayValues
}

/*

function groupBy(arr, fields) {
  let field = fields[0]               
  if (!field) return arr              
  let retArr = Object.values(
     arr.reduce((obj, current) => {
        if (!obj[current[field]]) obj[current[field]] = {field: field, value: current[field],rows: []}
        obj[current[field]].rows.push(current)
        return obj
     }, {}))
  
  // recurse for each child's rows if there are remaining fields
  if (fields.length){
      retArr.forEach(obj => {
          obj.count = obj.rows.length
          obj.rows = groupBy(obj.rows, fields.slice(1))
      })
  }
  return retArr
}

let result = groupBy(stock, ["order", "item"]);
console.log(result)
 */

/**
 *
 * @param {[Object]} data
 * @param {String} sortBy
 * @param {String} sortFunctionName
 * @param {[Object]} columns
 */
function sortData(data, sortBy, sortFunctionName, columns) {
    if (!sortBy) return data
    // Get the sortedColumn
    const sortedColumn = columns.filter((column) => column.propName === sortBy)[0]
    // Get the specific sortingFunction of the sortFunctionName if any
    let sortingFunction = sortedColumn?.sortingFunctions?.sortFunctionName
    if (sortingFunction === undefined) {
        if (sortFunctionName === "ASC" || sortFunctionName === "DES") {
            const ascDes = sortFunctionName === "ASC"
            sortingFunction = (a, b) => {
                // asc will be a boolean
                if (a[sortBy] > b[sortBy]) {
                    return (ascDes && 1) || -1
                } else if (a[sortBy] < b[sortBy]) {
                    return (ascDes && -1) || 1
                } else {
                    return 0
                }
            }
        }
        // else sortingFunction will be undefined
    }
    return sortDataRecursive(data, sortingFunction)
}

/**
 *
 * @param {[Object]} data
 * @param {Function} sortingFunction
 */
export function sortDataRecursive(data, sortingFunction) {
    // If data is grouped && groupData.length > 1 : sort each groupData
    if (data[0] && data[0]["data"] && data[0]["data"].length > 1) {
        for (const group of data) {
            group["data"] = sortDataRecursive(group["data"], sortingFunction)
        }
        return data
    }
    return data.sort(sortingFunction)
}

import { useState } from "react"
import { withRouter } from "react-router"
import { Link as RouterLink } from "react-router-dom"
import networking from "../Util/Networking"
import BackgroundAndCardLayout from "./BackgroundAndCardLayout"
import { Button } from "./Button"

export const GenericFromInputAndLabel = ({
    label,
    handleChange,
    placeholder,
    value,
    type = "text",
    inputRef,
    id,
    name,
    noValidate,
    error = "",
}) => {
    return (
        <div className="roboto self-stretch flex flex-col space-y-[6px]" noValidate={noValidate}>
            <label className="text-[14px] text-gray-60 pl-1">{label}</label>
            <input
                className={
                    "text-[16px] text-gray-90 border-[1px] rounded-lg w-full p-2 focus:outline-none" +
                    (error !== "" ? " border-red" : " border-gray-10 focus:border-gray-30")
                }
                type={type}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                id={id}
                name={name}
                ref={inputRef}
            />
            {error !== "" && <div className="text-[12px] text-red">{error}</div>}
        </div>
    )
}

const Sign = function SignUp({ history }) {
    const [state, setState] = useState({
        name: "",
        email: "",
        company: "",
    })

    const [submitEnabled, setSubmitEnabled] = useState("")
    const [loading, setLoading] = useState(false)

    const handleSignUp = (event) => {
        event.preventDefault()
        setLoading(true)
        try {
            let payload = { ...state }
            networking
                .post("/api/v1/contact/contact_form", payload)
                .then((res) => res.data)
                .then(() => {
                    setLoading(false)
                    resetForm()
                    history.push("/signup/confirmation")
                })
                .catch((error) => {
                    setLoading(false)
                    console.log("There was a problem with consulting endpoint")
                    console.log(error)
                })
        } catch (error) {
            setLoading(false)
            alert(error)
        }
    }

    function areFieldsFilled(state) {
        let status = "enabled"
        Object.values(state).forEach((prop) => {
            if (prop === "") {
                status = ""
            }
        })
        return status
    }

    function onChangeState(propName, value) {
        let newState = { ...state, [propName]: value }
        setState(newState)
        setSubmitEnabled(areFieldsFilled(newState))
    }

    function resetForm() {
        setSubmitEnabled("")
        setState({
            name: "",
            email: "",
            company: "",
        })
    }

    return (
        <BackgroundAndCardLayout>
            <div className="montserrat text-[16px] text-gray-90 font-semibold text-center leading-tight pb-4">
                Contact ClimateAi's sales team, by filling out the below form.
            </div>
            <GenericFromInputAndLabel
                label="First and Last Name"
                handleChange={(e) => {
                    onChangeState("name", e.target.value)
                }}
                value={state.name}
                placeholder="Name"
            />
            <GenericFromInputAndLabel
                label="Email Address"
                handleChange={(e) => {
                    onChangeState("email", e.target.value)
                }}
                value={state.email}
                placeholder="example@mail.com"
            />
            <GenericFromInputAndLabel
                label="Company"
                handleChange={(e) => {
                    onChangeState("company", e.target.value)
                }}
                value={state.company}
                placeholder="Company"
            />
            <div className="flex flex-row self-stretch pt-4 pb-2 space-x-2">
                <RouterLink className="w-1/2" to="/">
                    <Button label="Cancel" type="secondary" extend={true} onClick={() => {}} />
                </RouterLink>
                <Button
                    extraClasses={"w-1/2" + (loading ? " cursor-wait" : "")}
                    label="Continue"
                    type="primary"
                    extend={true}
                    onClick={handleSignUp}
                    disabled={submitEnabled === "" || loading}
                />
            </div>
        </BackgroundAndCardLayout>
    )
}

export default withRouter(Sign)

import { createContext, useContext, useReducer } from "react"
import {
    convertSpeedWindValueOnly,
    convertTemperatureValueOnly,
    convertWaterLengthValueOnly,
} from "../../Util/UnitConversion"

export const AdaptationContext = createContext({})

const temp_variables = {
    label: "Mean Temperature",
    value: "t2m",
    units: "°C",
    getUnits: (units) => {
        if (units === "metric") return "°C"
        if (units === "imperial") return "°F"
        return "mm"
    },
    convertToUnits: convertTemperatureValueOnly,
}

const wind_variables = {
    label: "Wind Speed",
    value: "ws",
    units: "km/h",
    getUnits: (units) => {
        if (units === "metric") return "km/h"
        if (units === "imperial") return "MPH"
        return "km/h"
    },
    convertToUnits: convertSpeedWindValueOnly,
}

export const VARIABLES = {
    t2m: {
        ...temp_variables,
        label: "Mean Temperature",
        value: "t2m",
    },
    t2m_max: {
        ...temp_variables,
        label: "Maximum Temperature",
        value: "t2m_max",
    },
    t2m_min: {
        ...temp_variables,
        label: "Minimum Temperature",
        value: "t2m_min",
    },
    tp: {
        label: "Precipitation",
        value: "tp",
        units: "mm",
        getUnits: (units) => {
            if (units === "metric") return "mm"
            if (units === "imperial") return "in"
            return "mm"
        },
        convertToUnits: convertWaterLengthValueOnly,
    },
    ws: {
        ...wind_variables,
        label: "Wind Speed",
        value: "ws",
    },
    ws_max: {
        ...wind_variables,
        label: "Maximum Wind Speed",
        value: "ws_max",
    },
    rh: { label: "Relative Humidity", value: "rh", units: "%", getUnits: (_) => "%", convertToUnits: undefined },
    gdd: {
        label: "Growing Degree Days",
        value: "gdd",
        units: "gdd",
        getUnits: (_) => "gdd",
        convertToUnits: undefined,
    },
    ch: { label: "Chill Hours", value: "ch", units: "h", getUnits: (_) => "h", convertToUnits: undefined },
    soil_temperature: {
        ...temp_variables,
        label: "Soil Temperature",
        value: "soil_temperature",
    },
    solar_radiation: {
        label: "Solar Radiation",
        value: "solar_radiation", // ssrd
        units: "W/m^2",
        getUnits: (_) => "W/m^2",
        // convertToUnits: convertWaterLengthValueOnly,
    },
    evapotranspiration: {
        label: "Evapotranspiration",
        value: "evapotranspiration",
        units: "mm",
        getUnits: (units) => {
            if (units === "metric") return "mm"
            if (units === "imperial") return "in"
            return "mm"
        },
        convertToUnits: convertWaterLengthValueOnly,
    },
}

export const DECADES = {
    2010: { label: "2010 - 2019", value: "2010" },
    2020: { label: "2020 - 2029", value: "2020" },
    2030: { label: "2030 - 2039", value: "2030" },
    2040: { label: "2040 - 2049", value: "2040" },
    2050: { label: "2050 - 2059", value: "2050" },
    2060: { label: "2060 - 2069", value: "2060" },
}

export const SCENARIOS = {
    worst: { label: "High emission scenario", value: "worst" },
    usual: { label: "Middle of the road", value: "usual" },
    best: { label: "Low emission scenario", value: "best" },
}

const initialState = {
    // from server
    allLocations: {},
    locations: {},
    plots: {},
    riskList: {},
    crops: {},
    // local
    variables: VARIABLES,
    decades: DECADES,
    scenarios: SCENARIOS,
    // user selections
    selectedLocation: null,
    selectedCrop: null,
    selectedVariety: null,
    selectedRisk: null,
    selectedVariable: null,
    selectedDecade: "2060",
    selectedScenario: Object.keys(SCENARIOS)[1],
    // toggle variables and risks (for certain screens)
    // this is easier than erasing one of the latter each time
    // they toggle
    // 'true' for showing variables
    // 'false' for showing risks
    variablesByDefault: true,
}

function AdaptationReducer(state = {}, action) {
    const { type, payload } = action

    switch (type) {
        case "SET_INITIAL_STATE": {
            const {
                allLocations,
                locations,
                plots,
                riskList,
                crops,
                variables,
                selectedLocation,
                selectedPlot,
                selectedCrop,
                selectedVariety,
                selectedRisk,
                selectedVariable,
            } = payload

            return {
                ...state,
                allLocations,
                locations,
                plots,
                riskList,
                crops,
                variables,
                selectedLocation,
                selectedPlot,
                selectedCrop,
                selectedVariety,
                selectedRisk,
                selectedVariable,
            }
        }
        case "SET_SELECTED_PLOT": {
            const { selectedPlot, locations, selectedLocation, selectedCrop, selectedVariety, selectedRisk } = payload
            return { ...state, locations, selectedPlot, selectedLocation, selectedCrop, selectedVariety, selectedRisk }
        }
        case "SET_SELECTED_LOCATION": {
            const { locations, selectedLocation, selectedPlot, selectedCrop, selectedVariety, selectedRisk } = payload
            return { ...state, locations, selectedLocation, selectedPlot, selectedCrop, selectedVariety, selectedRisk }
        }
        case "SET_SELECTED_CROP": {
            const { selectedCrop } = payload
            // set 1st risk
            return { ...state, selectedCrop }
        }
        case "SET_SELECTED_CROP_VARIETY": {
            const { locations, selectedCrop, selectedLocation, selectedVariety, selectedPlot, selectedRisk } = payload
            return { ...state, locations, selectedLocation, selectedCrop, selectedVariety, selectedPlot, selectedRisk }
        }
        case "SET_SELECTED_RISK": {
            const { selectedRisk } = payload
            return { ...state, selectedRisk }
        }
        case "SET_SELECTED_VARIABLE": {
            const { selectedVariable } = payload
            return { ...state, selectedVariable }
        }
        case "SET_SELECTED_DECADE": {
            const { selectedDecade } = payload
            return { ...state, selectedDecade }
        }
        case "SET_SELECTED_SCENARIO": {
            const { selectedScenario } = payload
            return { ...state, selectedScenario }
        }
        default: {
            return state
        }
    }
}

function ClimateProvider(props) {
    const { children } = props
    const [state, dispatch] = useReducer(AdaptationReducer, initialState)

    return <AdaptationContext.Provider value={{ state, dispatch }}>{children}</AdaptationContext.Provider>
}

export function useAdaptationValues() {
    const {
        state: { allLocations, locations, plots, riskList, crops, availableVariables, ...state },
        dispatch,
    } = useContext(AdaptationContext)

    function setInitialState(_locations, _plots, _riskList, _availableVariables) {
        // get crop/variety list
        const crops = {}
        // set first crop/variety
        let selectedCrop = null
        let selectedVariety = null
        for (let key in _plots) {
            const plot = _plots[key]

            if (!selectedCrop && !selectedVariety) {
                selectedCrop = plot.crop
                selectedVariety = plot.variety
            }

            if (!crops[`${plot.crop}/${plot.variety}`])
                crops[`${plot.crop}/${plot.variety}`] = {
                    crop: plot.crop,
                    variety: plot.variety,
                    plots: [plot.id],
                }
            else crops[`${plot.crop}/${plot.variety}`].plots.push(plot.id)
        }

        // set first location
        let selectedLocation = null
        // set first plot
        let selectedPlot = null
        // get available locations by crop/variety
        const locations = crops[`${selectedCrop}/${selectedVariety}`].plots.reduce((obj, p) => {
            for (let l in _locations) {
                if (_locations[l]?.location === _plots[p]?.location) {
                    if (!selectedLocation) selectedLocation = _locations[l]?.id
                    if (!selectedPlot) selectedPlot = _plots[p]?.id

                    obj[_locations[l]?.id] = _locations[l]
                }
            }

            return obj
        }, {})

        const selectedPlotObj = _plots[selectedPlot]
        // set risks
        const selectedRisk = Object.keys(selectedPlotObj?.risks)[0]

        // filter available variables
        let selectedVariable = null

        const variables = Object.keys(VARIABLES)
            .filter((key) => _availableVariables.includes(VARIABLES[key].value))
            .reduce((cur, key) => {
                return Object.assign(cur, { [key]: VARIABLES[key] })
            }, {})

        selectedVariable = Object.keys(variables)[0]

        dispatch({
            type: "SET_INITIAL_STATE",
            payload: {
                allLocations: _locations,
                locations: locations,
                plots: _plots,
                riskList: _riskList,
                crops: crops,
                variables: variables,
                selectedLocation,
                selectedPlot,
                selectedCrop,
                selectedVariety,
                selectedRisk,
                selectedVariable,
            },
        })
    }

    function setSelectedLocation(_selectedLocation) {
        // set first key as current location value (id)
        const selectedLocationObj = allLocations[_selectedLocation]

        // check if location matches with selected crop/variety
        const plotsByLocation = Object.values(plots).filter((p) => p.location === selectedLocationObj?.location)
        const currentPlot = plotsByLocation.find(
            (p) => p.crop === state.selectedCrop && p.variety === state.selectedVariety
        )

        let { selectedPlot, selectedCrop, selectedVariety } = state

        let _locations = locations

        if (!currentPlot) {
            // plot is different from current, we need to pull a new one

            // get new plot, crop and variety
            selectedPlot = Object.keys(plots).find((key) => plots[key].location === selectedLocationObj.location)
            // select crop from plot
            selectedCrop = plots[selectedPlot]?.crop
            // select variety from plot
            selectedVariety = plots[selectedPlot]?.variety
            // get available locations by crop/variety
            _locations = crops[`${selectedCrop}/${selectedVariety}`].plots.reduce((obj, p) => {
                for (let l in allLocations)
                    if (allLocations[l]?.location === plots[p]?.location) obj[allLocations[l]?.id] = allLocations[l]

                return obj
            }, {})
        }

        // select risk from plot
        const selectedRisk = Object.keys(plots[(currentPlot && currentPlot.id) || selectedPlot]?.risks)[0]

        dispatch({
            type: "SET_SELECTED_LOCATION",
            payload: {
                locations: _locations,
                selectedLocation: _selectedLocation,
                selectedPlot: (currentPlot && currentPlot.id) || selectedPlot,
                selectedCrop,
                selectedVariety,
                selectedRisk,
            },
        })
    }

    function setSelectedPlot(_selectedPlot) {
        // get plot
        const selectedPlotObj = plots[_selectedPlot]
        // set location
        // select crop from plot
        const _selectedCrop = plots[_selectedPlot]?.crop
        // select variety from plot
        const _selectedVariety = plots[_selectedPlot]?.variety
        // get available locations by crop/variety
        const _locations = crops[`${_selectedCrop}/${_selectedVariety}`].plots.reduce((obj, p) => {
            for (let l in allLocations)
                if (allLocations[l]?.location === plots[p]?.location) obj[allLocations[l]?.id] = allLocations[l]

            return obj
        }, {})

        const _selectedLocation = Object.values(allLocations).find((l) => l.location === selectedPlotObj.location).id

        // select risk from plot
        const _selectedRisk = Object.keys(selectedPlotObj?.risks)[0]

        dispatch({
            type: "SET_SELECTED_PLOT",
            payload: {
                locations: _locations,
                selectedLocation: _selectedLocation,
                selectedPlot: _selectedPlot,
                selectedCrop: _selectedCrop,
                selectedVariety: _selectedVariety,
                selectedRisk: _selectedRisk,
            },
        })
    }

    function setSelectedCrop(selectedCrop) {
        dispatch({ type: "SET_SELECTED_CROP", payload: { selectedCrop } })
    }
    function setSelectedCropVariety(_selectedCrop, _selectedVariety) {
        // set first location
        let _selectedLocation = null
        // set first plot
        let _selectedPlot = null
        // get available locations by crop/variety
        const locations = crops[`${_selectedCrop}/${_selectedVariety}`].plots.reduce((obj, p) => {
            for (let l in allLocations) {
                if (allLocations[l]?.location === plots[p]?.location) {
                    if (!_selectedLocation) _selectedLocation = allLocations[l]?.id
                    if (!_selectedPlot) _selectedPlot = plots[p]?.id

                    obj[allLocations[l]?.id] = allLocations[l]
                }
            }

            return obj
        }, {})

        const selectedPlotObj = plots[_selectedPlot]
        // set risks
        const _selectedRisk = Object.keys(selectedPlotObj?.risks)[0]

        dispatch({
            type: "SET_SELECTED_CROP_VARIETY",
            payload: {
                ...state,
                locations,
                selectedCrop: _selectedCrop,
                selectedVariety: _selectedVariety,
                selectedLocation: _selectedLocation,
                selectedPlot: _selectedPlot,
                selectedRisk: _selectedRisk,
            },
        })
    }
    function setSelectedRisk(selectedRisk) {
        dispatch({ type: "SET_SELECTED_RISK", payload: { selectedRisk } })
    }
    function setSelectedVariable(selectedVariable) {
        dispatch({ type: "SET_SELECTED_VARIABLE", payload: { selectedVariable } })
    }
    function setSelectedDecade(selectedDecade) {
        dispatch({ type: "SET_SELECTED_DECADE", payload: { selectedDecade } })
    }
    function setSelectedScenario(selectedScenario) {
        dispatch({ type: "SET_SELECTED_SCENARIO", payload: { selectedScenario } })
    }

    // filter risks by current plot
    // changing the risk doesn't change the plot, therefore, we can get current risks
    // from current plot id
    const risks = {}
    const selectedPlotObj = plots[state.selectedPlot]

    selectedPlotObj &&
        Object.keys(selectedPlotObj.risks).forEach((key) => {
            if (riskList[key].variable === "chill hours") riskList[key].variable = "ch"
            if (riskList[key].unit === "hours") riskList[key].unit = "h"
            risks[key] = {
                id: key && typeof key === "string" && key.replaceAll(" ", ""),
                ...selectedPlotObj.risks[key],
                ...riskList[key],
            }
        })

    return {
        ready:
            allLocations &&
            Object.keys(allLocations).length > 0 &&
            plots &&
            Object.keys(plots).length > 0 &&
            riskList &&
            Object.keys(riskList).length > 0,
        allLocations,
        locations,
        plots,
        riskList,
        ...state,
        crops, // by location
        risks, // by plot
        setInitialState,
        setSelectedLocation,
        setSelectedCrop,
        setSelectedCropVariety,
        setSelectedRisk,
        setSelectedVariable,
        setSelectedDecade,
        setSelectedScenario,
        setSelectedPlot,
    }
}

export default ClimateProvider

import { useMemo, useRef, useState, useEffect, useContext } from "react"
import { CircularProgress } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"
import { SettingsContext } from "../../../../Util/SettingsContext"
import { getHistoricalTemp, getForecastArr, getForecastTemp, getClim } from "./helper"
import {
    convertToShadedRangesFormat,
    assembleAreaData,
    assembleLineData,
    getForecastConfidenceData,
    addMonths,
    validateData,
    addDays,
    getSevenMonthsMarginClimData,
    convertDataToBarStructure,
    convertAPIDataToSeasonalBarStructure,
    convertToLineFromBar,
    convertToDoubleCandlestick,
    convertAPIDataToSeasonalBarStructureWeekly,
    convertToDoubleCandlestickWeekly,
    convertToLineFromBarWeekly,
    convertDataToBarStructureWeekly,
    renderArrows,
    trimmData,
} from "../../../../helpers/chartHelpers"

import ChartSpecs from "../ChartSpecs"
import networking from "../../../../Util/Networking"
import { AuthContext } from "../../../../Auth/Auth"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useLastUpdated from "../../../../hooks/useLastUpdated"
import { ToastContext } from "../../../../components/Toast/ToastContextProvider"

const SolarRadiationChart = ({ actionsState }) => {
    const chartRef = useRef(null)
    const { currentUser } = useContext(AuthContext)
    const { currentSettings } = useContext(SettingsContext)
    const { enqueueNetworkError } = useContext(ToastContext)
    const { id } = useParams()
    const [fieldId, setFieldId] = useState()
    const [confidenceVisible, setConfidenceBarsVisibility] = useState(false)
    const [climatologyVisible, setClimatologyVisible] = useState(true)

    const [historicalPending, setHistoricalPending] = useState(false)

    // Media Queries for Tablet View
    const [tabletMedia, setTabletMedia] = useState(true)
    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)", { noSsr: true })
    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    // ******************************* BAR DATA PART ****************************

    //---------------------------  MONTHLY SEASONAL  ------------------------
    // BarData - Forecast
    const [monthlyBarForecastData, setMonthlyBarForecastData] = useState({
        time: [],
    })

    // BarData - Climatology
    const [monthlyBarClimatologyData, setMonthlyBarClimatologyData] = useState({
        time: [],
    })

    // BarData - Forecast - Vigintiles
    const [monthlyBarForecastDataVigintiles, setMonthlyBarForecastDataVigintiles] = useState({ time: [] })

    // BarData - Climatology - Vigintiles
    const [monthlyBarClimatologyDataVigintiles, setMonthlyBarClimatologyDataVigintiles] = useState({ time: [] })

    // Bar Data - Insights
    const barDataInsights = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "ssrd_mean",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                property: "ssrd_mean",
            }),
        [monthlyBarForecastDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarClimatologyDataVigintiles,
                property: "ssrd_mean",
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
            }),
        [monthlyBarClimatologyDataVigintiles, monthlyBarForecastDataVigintiles]
    )

    const [barType, setBarType] = useState("candl")

    //---------------------------  WEEKLY SEASONAL (SUBSEASONAL)  ------------------------

    // BarData - Forecast
    const [weeklyBarForecastData, setWeeklyBarForecastData] = useState({
        time: [],
    })

    // BarData - Climatology
    const [weeklyBarClimatologyData, setWeeklyBarClimatologyData] = useState({
        time: [],
    })

    // BarData - Forecast - Vigintiles
    const [weeklyBarForecastDataVigintiles, setWeeklyBarForecastDataVigintiles] = useState({ time: [] })

    // BarData - Climatology - Vigintiles
    const [weeklyBarClimatologyDataVigintiles, setWeeklyBarClimatologyDataVigintiles] = useState({ time: [] })

    // Bar Data - Insights
    const barDataInsightsWeekly = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructureWeekly({
                climatology: weeklyBarClimatologyData,
                forecast: weeklyBarForecastData,
                property: "ssrd_mean",
            }),
        [weeklyBarForecastData, weeklyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarDataWeekly = useMemo(
        () =>
            convertDataToBarStructureWeekly({
                data: weeklyBarForecastDataVigintiles,
                property: "ssrd_mean",
            }),
        [weeklyBarForecastDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsDataWeekly = useMemo(
        () =>
            convertDataToBarStructureWeekly({
                data: weeklyBarClimatologyDataVigintiles,
                property: "ssrd_mean",
            }),
        [weeklyBarClimatologyDataVigintiles]
    )

    // ******************************* END OF BAR DATA PART ****************************

    // Prepare initial data
    const weatherVariable = "solar_radiation"
    const [data, setData] = useState({
        ds_hist: {
            time: [],
            ssrd_mean: [],
        },
        ds_fc: {
            time: [],
            ssrd_mean: [],
        },
        ds_clim: {
            time: [],
            ssrd_mean: [],
        },
        pending: true,
    })

    // Prepare initial data
    const [monthlyData, setMonthlyData] = useState({
        ds_hist: {
            time: [],
            ssrd_mean: [],
        },
        ds_fc: {
            time: [],
            ssrd_mean: [],
        },
        ds_clim: {
            time: [],
            ssrd_mean: [],
        },
        pending: true,
    })

    // Alerts Data
    const [alertsData, setAlertsData] = useState({
        ssrd_mean: {},
    })

    // Load data
    useEffect(() => {
        if (fieldId !== id || (fieldId === id && climatologyVisible && data.ds_clim.time.length === 0)) {
            setData((prevData) => ({
                ...prevData,
                pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
            }))

            let localData = data

            if (climatologyVisible) {
                setHistoricalPending(true)
            }
            // let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
            // if (climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
            let datasets = "history%2Cforecast%2Cclimatology"
            if (!climatologyVisible) {
                if (fieldId !== id) {
                    localData.pending = true
                }
                localData = Object.assign({}, localData, {
                    ds_clim: {
                        time: [],
                        ssrd_mean: [],
                    },
                })
                setData(localData)
            }

            currentUser.getIdToken().then((userToken) => {
                networking
                    .get(`/api/v1/weather/${weatherVariable}/daily/${id}?datasets=${datasets}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (datasets.indexOf("climatology") !== -1) {
                            setHistoricalPending(false)
                        }
                        setData({
                            ...Object.assign({}, localData, res.data),
                            pending: false,
                        })
                    })
                    .catch(() => {
                        setData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        enqueueNetworkError()
                    })

                //Break network call if data already loaded
                if (fieldId === id && monthlyData.ds_fc.time.length) {
                    return
                }

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        console.log("monthly terciles data loaded", res.data)
                        setMonthlyBarForecastData(res.data.ds_fc)
                        setMonthlyBarClimatologyData(res.data.ds_clim)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        console.log("monthly vigintiles data loaded", res.data)
                        setMonthlyBarForecastDataVigintiles(res.data.ds_fc)
                        setMonthlyBarClimatologyDataVigintiles(res.data.ds_clim)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                /*************** WEEKLY API   ********************/

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/weekly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setWeeklyBarForecastDataVigintiles(res.data.ds_fc)
                        setWeeklyBarClimatologyDataVigintiles(res.data.ds_clim)
                        console.log("weekly vigintiles data loaded", res)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/weekly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setWeeklyBarForecastData(res.data.ds_fc)
                        setWeeklyBarClimatologyData(res.data.ds_clim)
                        console.log("weekly terciles data loaded", res)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                // networking
                //     .get(`/api/v1/weather/${weatherVariable}/monthly/${id}?datasets=history%2Cforecast`, {
                //         extraHeaders: { "User-Token": userToken },
                //     })
                //     .then((res) => {
                //         setMonthlyData({
                //             ...res.data,
                //             pending: false,
                //         })
                //     })
                //     .catch(() => {
                //         setMonthlyData((prevData) => ({
                //             ...prevData,
                //             pending: false,
                //         }))
                //         enqueueNetworkError()
                //     })

                networking
                    .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        setAlertsData(res.data)
                    })
                    .catch(() => {
                        //             toast.warn(
                        //                 `Alerts not displayed on dashboard due to internet
                        //   connectivity issues. All other functions working.`
                        //             )
                    })
            })
            setFieldId(id)
        }
    }, [currentUser, id, climatologyVisible])

    const lastUpdated = useLastUpdated(data, monthlyData)

    // Prepare historical data
    const historicalTemp = useMemo(() => getHistoricalTemp(data["ds_hist"]), [data])

    // Prepare forecast data
    const forecastArr = useMemo(() => getForecastArr(data["ds_fc"]), [data])
    const forecastTemp = useMemo(() => {
        return getForecastTemp(data["ds_fc"], forecastArr)
    }, [data, forecastArr])

    // Prepare areas data
    const { climLighten, climDarken } = useMemo(() => getClim(data["ds_clim"]), [data])

    // Prepare Confidence Data
    const forecastConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalTemp[historicalTemp.length - 1],
            data["ds_fc"]["ssrd_mean"],
            "0.75"
        )
    }, [data, historicalTemp])
    const forecastConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalTemp[historicalTemp.length - 1],
            data["ds_fc"]["ssrd_mean"],
            "0.95"
        )
    }, [data, historicalTemp])

    // Look at the changes for historical and forecast data and display warning messages if invalid
    useMemo(() => {
        validateData({
            diffToAlert: 25,
            historic: climLighten,
            forecast: forecastTemp,
            accessorKey: "y",
            message: "Forecast Anomaly Detected",
        })
    }, [forecastTemp, climLighten])

    const lineDataDaily = assembleLineData({
        isMonthly: actionsState.isMonthly,
        historical: historicalTemp,
        forecast: forecastTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.ssrd_mean["0.5"][i],
            }
        }),
    })

    // get ammount of days from the beginning of the line, when it's trimmed inside assembleLineData function
    // sometimes we endup with less than 12 days because of the line being icomplete from historical (see SE-1919)
    const dailyLineDaysFromBeginning =
        (lineDataDaily.length > 0 && Math.floor((new Date().getTime() - lineDataDaily[0].x) / (24 * 60 * 60 * 1000))) +
            1 || 0

    const lineDataWeekly = weeklyBarForecastDataVigintiles.time.map((d, i) => {
        return {
            x: new Date(d),
            y: weeklyBarForecastDataVigintiles.ssrd_mean["0.5"][i],
        }
    })

    const lineDataMonthly = monthlyBarForecastDataVigintiles.time.map((d, i) => {
        return {
            x: new Date(d),
            y: monthlyBarForecastDataVigintiles.ssrd_mean["0.5"][i],
        }
    })

    const csvDailyData = lineDataDaily.map((d) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })],
        [d.y],
    ])

    const csvWeeklyData = lineDataWeekly.map((d) => [
        [
            new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric" }) +
                " - " +
                new Date(addDays(d.x, 6)).toLocaleDateString(undefined, { month: "short", day: "numeric" }),
        ],
        [d.y],
    ])

    const csvMonthlyData = lineDataMonthly.map((d) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", year: "numeric" })],
        [d.y],
    ])

    return (
        <div className="weather-chart">
            <div className="weather-chart__chart-container">
                <div style={{ display: data.pending ? "flex" : "none" }} className="weather-chart__preload-container">
                    <CircularProgress />
                </div>

                <LineAreaChartComponent
                    // Pass height externally
                    svgHeight={650}
                    // Title text
                    title="Solar Radiation"
                    // Y label text
                    labelY="Solar Radiation [W/m^2]"
                    // Add chart data id to filter out some update requests
                    chartDataId={
                        {
                            hourly: `hour`,
                            weekly: `weekly`,
                            daily: `month`,
                            monthly: `day`,
                        }[actionsState.selectedGranularity] +
                        `${weatherVariable}-chart_` +
                        climLighten.length
                    }
                    // Pass unique resize event key
                    resizeEventListenerId="solar-radiation-chart"
                    // Convert received data to shaded ranges format
                    shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData))}
                    centerTicks={
                        {
                            daily: false,
                            monthly: true,
                            hourly: false,
                            weekly: true,
                        }[actionsState.selectedGranularity]
                    }
                    // Make chart to have zero y basis
                    zeroBasis={true}
                    // Bottom margin will be 0.2 times of data diff
                    yBottomOffset={0.2}
                    // Top margin will be 0.3 times of data diff
                    yTopOffset={0.3}
                    marginTop={55}
                    // Provide custom date max axis extent for monthly view charts
                    xDateMax={
                        {
                            daily: tabletMedia ? addDays(new Date(), 14) : null,
                            // monthly: addMonths(
                            //     new Date(new Date().getFullYear(), new Date().getMonth(), 2),7
                            // ),
                            monthly: addMonths(
                                new Date(
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getFullYear(),
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getMonth() + 1,
                                    1
                                ),
                                0
                            ),
                            hourly: null,
                            weekly: addDays(
                                new Date(
                                    weeklyBarForecastDataVigintiles.time[
                                        weeklyBarForecastDataVigintiles.time.length - 1
                                    ]
                                ),
                                8
                            ),
                        }[actionsState.selectedGranularity]
                    }
                    // Provide custom date min axis extent for monthly view charts
                    xDateMin={
                        {
                            daily: tabletMedia ? addDays(new Date(), -2) : null,
                            // monthly:addMonths(
                            //     new Date(new Date().getFullYear(), new Date().getMonth(), 28),-7
                            // ),
                            monthly: (() => {
                                const today = new Date()
                                const year = today.getFullYear()
                                const month = today.getMonth()
                                const result = new Date(year, month, 1)
                                return result
                            })(),
                            hourly: null,
                            weekly: addDays(new Date(weeklyBarForecastDataVigintiles.time[0]), -1),
                        }[actionsState.selectedGranularity]
                    }
                    // How x ticks will be formatted in chart
                    xTickFormat={
                        {
                            weekly: (d, i, arr) => {
                                const nextDate = addDays(d, 6)
                                const dStr = d.toLocaleString(undefined, { month: "short", day: "numeric" })
                                const nextDateStr = nextDate.toLocaleString(undefined, {
                                    month: "short",
                                    day: "numeric",
                                })
                                return dStr + " - " + nextDateStr
                            },
                            daily: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getDate()
                                if (i === 0) return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                            },
                            // monthly:(d, i, arr) => {
                            //     // Remove last, overflowing tick item
                            //     if (i === arr.length - 1) return ""
                            //     return d.toLocaleString(undefined, { month: "short" })
                            // },
                            monthly: (d, i, arr) => {
                                // Remove last, overflowing tick item
                                if (i === arr.length - 1) return ""
                                return d.toLocaleString(undefined, { month: "short" })
                            },
                            hourly: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getHours() + "h"
                                if (i === 0) return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                                return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Give chart tips count tip
                    xTicksCount={
                        {
                            daily: tabletMedia ? 14 : 30,
                            //monthly: 12,
                            monthly: 5,
                            hourly: 16,
                            weekly: weeklyBarForecastDataVigintiles.time.length,
                        }[actionsState.selectedGranularity]
                        //    actionsState.isMonthly ? 12 : tabletMedia ? 14 : 30
                    }
                    // Hide chart if data is pending
                    hide={data.pending}
                    // Tooltip content on line points mouse over
                    tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                        let hour = undefined
                        let day = "numeric"
                        let month = "short"
                        let h = ""
                        let titleWidth = 40
                        let titleFontSize = 20

                        if (actionsState.selectedGranularity === "hourly") {
                            hour = "numeric"
                            day = undefined
                            month = undefined
                            h = "h"
                        }

                        let dateStr = key.toLocaleString(undefined, { day, month, hour })

                        if (actionsState.selectedGranularity === "monthly") {
                            dateStr = key.toLocaleString(undefined, { month: "short" })
                        }

                        if (actionsState.selectedGranularity === "weekly") {
                            titleWidth = 140
                            titleFontSize = 15

                            const dateMin = addDays(key, -3)
                            const dateMax = addDays(key, 4)
                            const dateMinStr = dateMin.toLocaleString(undefined, { day, month })
                            const dateMaxStr = dateMax.toLocaleString(undefined, { day, month })
                            dateStr = dateMinStr + " - " + dateMaxStr
                        }

                        if (barType === "candl") {
                            day = undefined
                        }

                        return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
    <tr><td style="font-weight:bold;font-size:${titleFontSize}px" rowspan="${
                            values.length
                        }"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:${titleWidth}px;line-height:1.1">${dateStr}${h}</div></td> 
        <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
            points[0].dashed ? "dashed" : "solid"
        } ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${Math.round(values[0])} W/m^2</td>
    </tr>
    ${values
        .filter((_, i) => i > 0)
        .map((value, i) => {
            return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                points[i + 1].dashed ? "dashed" : "solid"
            } ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${Math.round(value)} W/m^2 </td></tr>`
        })
        .join("")}
</table>`
                    }}
                    shapeTip={
                        {
                            hourly: null,
                            daily: null,
                            weekly: (EVENT, boundObj, state, point) => {
                                let resultObj = point ?? boundObj
                                let week = resultObj.key.toISOString()
                                let insights = barDataInsightsWeekly
                                if (!insights) {
                                    return ""
                                }
                                let warmer = Math.round(insights.normalMax[week])
                                let clim67 = Math.round(insights.clim67[week])
                                let normal = Math.round(insights.normal[week])
                                let clim33 = Math.round(insights.clim33[week])
                                let colder = Math.round(insights.normalMin[week])

                                let unit = "W/m^2"
                                // if (currentSettings.units === 'imperial') {
                                //     clim33 = +convertWaterLengthValueOnly("metric", "imperial", clim33)
                                //     clim67 = +convertWaterLengthValueOnly("metric", "imperial", clim67)
                                //     unit = 'in'
                                // }

                                return `<div style="max-width:250px;">
                                    
                                    There is a ${warmer}% chance that solar radiation will be less than usual (above ${clim67} ${unit}).
                                    
                                    </br>  </br> There is a ${normal}% chance that solar radiation will be within the normal range (${clim33} to ${clim67} ${unit}). 
                                    
                                    </br>  </br>There is a ${colder}% chance that solar radiation will be more than normal (below ${clim33} ${unit}).</div>`
                            },
                            monthly: (EVENT, boundObj, state, point) => {
                                let resultObj = point ?? boundObj
                                let month = resultObj.key
                                    .toLocaleString("en-US", { month: "short", year: "numeric" }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                    .split("")
                                    .join("")
                                const prec = 100

                                if (!barDataInsights) {
                                    return ""
                                }

                                const warmer = Math.round(prec * barDataInsights.normalMax[month]) / prec
                                let clim67 = Math.round(prec * barDataInsights.clim67[month]) / prec
                                const normal = Math.round(prec * barDataInsights.normal[month]) / prec
                                let clim33 = Math.round(prec * barDataInsights.clim33[month]) / prec
                                const colder = Math.round(prec * barDataInsights.normalMin[month]) / prec

                                let unit = "W/m^2"
                                // if (currentSettings.units === 'imperial') {
                                //     clim33 = +convertWaterLengthValueOnly("metric", "imperial", clim33)
                                //     clim67 = +convertWaterLengthValueOnly("metric", "imperial", clim67)
                                //     console.log('clim 33 after', clim33)
                                //     unit = 'in'
                                // }

                                return `<div style="max-width:250px;">There is a ${warmer}% chance that solar radiation will be higher than usual (above ${clim67} ${unit} ). </br>  </br> There is a ${normal}% chance that solar radiation will be within the normal range (${clim33} to ${clim67}  ${unit}). </br>  </br>There is a ${colder}% chance that solar radiation will be lower than normal (below ${clim33}  ${unit}).</div>`
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Chart data content
                    data={[
                        // =====================  WEEKLY   =====================
                        ["weekly"].includes(actionsState.selectedGranularity)
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBarWeekly({
                                      obj: forecastQuantilesBarDataWeekly[0.5],

                                      props: {
                                          color: "#EE6900",
                                          barType: barType,
                                          // date:10,
                                          visible: true,
                                          isMax: false,
                                          unit: currentSettings.units,
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsightsWeekly)
                                                  return {
                                                      color: "",
                                                      symbol: `
                                                        <style>
                                                        .point-loader {
                                                        border: 3px solid #f3f3f3;
                                                        border-radius: 50%;
                                                        border-top: 3px solid #3498db;
                                                        width: 10px;
                                                        height: 10px;
                                                        -webkit-animation: spin 2s linear infinite; /* Safari */
                                                        animation: spin 2s linear infinite;
                                                        }
                                                        
                                                        /* Safari */
                                                        @-webkit-keyframes spin {
                                                        0% { -webkit-transform: rotate(0deg); }
                                                        100% { -webkit-transform: rotate(360deg); }
                                                        }
                                                        
                                                        @keyframes spin {
                                                        0% { transform: rotate(0deg); }
                                                        100% { transform: rotate(360deg); }
                                                        }
                                                        </style>
                                                        
                                                        <div class="point-loader"></div>
                                                        `,
                                                  }

                                              const weekly = d.key.toISOString()
                                              const normalMax = barDataInsightsWeekly.normalMax[weekly]
                                              const normalMin = barDataInsightsWeekly.normalMin[weekly]
                                              const normal = barDataInsightsWeekly.normal[weekly]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        //=================== DOUBLE  CANDLESTICK VERSION  =========================

                        [""].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToLineFromBarWeekly({
                                  obj: forecastQuantilesBarDataWeekly[0.5],
                                  props: {
                                      color: "#EE6900",
                                      barType: barType,
                                      visible: false,
                                      unit: currentSettings.units,
                                      // unitType: "precip",
                                      hours: 24,
                                      // date: 19,
                                  },
                              })
                            : null,

                        ["weekly"].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToDoubleCandlestickWeekly({
                                  obj: [
                                      {
                                          isMax: true,
                                          values: [forecastQuantilesBarDataWeekly, climatologyQuantilesBarsDataWeekly],
                                      },
                                  ],
                                  unit: currentSettings.units,
                                  // unitType: "temp",
                                  colors: ["#EE6900", "#039BE5"],
                              })
                            : null,

                        // //======================= PLOT MEDIAN AS LINE - MONTHLY===================
                        ["monthly"].includes(actionsState.selectedGranularity)
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBar({
                                      obj: forecastQuantilesBarData[0.5],
                                      props: {
                                          color: "#EE6900",
                                          barType: barType,
                                          visible: true,
                                          unit: currentSettings.units,
                                          unitType: "ssrd_mean",
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsights)
                                                  return {
                                                      color: "",
                                                      symbol: `
                                        <style>
                                        .point-loader {
                                        border: 3px solid #f3f3f3;
                                        border-radius: 50%;
                                        border-top: 3px solid #3498db;
                                        width: 10px;
                                        height: 10px;
                                        -webkit-animation: spin 2s linear infinite; /* Safari */
                                        animation: spin 2s linear infinite;
                                        }
                                        
                                        /* Safari */
                                        @-webkit-keyframes spin {
                                        0% { -webkit-transform: rotate(0deg); }
                                        100% { -webkit-transform: rotate(360deg); }
                                        }
                                        
                                        @keyframes spin {
                                        0% { transform: rotate(0deg); }
                                        100% { transform: rotate(360deg); }
                                        }
                                        </style>
                                        
                                        <div class="point-loader"></div>
                                        `,
                                                  }
                                              const month = d.key
                                                  .toLocaleString("en-US", {
                                                      month: "short",
                                                      year: "numeric",
                                                  }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                                  .split("")
                                                  .join("")
                                              const normalMax = barDataInsights.normalMax[month]
                                              const normalMin = barDataInsights.normalMin[month]
                                              const normal = barDataInsights.normal[month]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        // //=================== DOUBLE  CANDLESTICK VERSION  =========================

                        [""].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToLineFromBar({
                                  obj: forecastQuantilesBarData[0.5],
                                  props: {
                                      color: "#EE6900",
                                      barType: barType,
                                      visible: false,
                                      unit: currentSettings.units,
                                      unitType: "ssrd_mean",
                                      date: 15,
                                  },
                              })
                            : null,

                        ["monthly"].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToDoubleCandlestick({
                                  obj: [
                                      {
                                          values: [forecastQuantilesBarData, climatologyQuantilesBarsData],
                                      },
                                  ],
                                  unit: currentSettings.units,
                                  unitType: "ssrd_mean",
                                  colors: ["#EE6900", "#EE6900"],
                              })
                            : null,

                        // ===================== End Of Horizontal and vertical bars versions  ====================

                        ["daily"].includes(actionsState.selectedGranularity) && climatologyVisible
                            ? {
                                  type: "area",
                                  points: !actionsState.isMonthly
                                      ? trimmData(getSevenMonthsMarginClimData(climLighten), dailyLineDaysFromBeginning)
                                      : getSevenMonthsMarginClimData(climLighten),
                                  color: "#F8D6C5",
                                  opacity: 0.6,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) && climatologyVisible
                            ? {
                                  type: "area",
                                  points: !actionsState.isMonthly
                                      ? trimmData(getSevenMonthsMarginClimData(climDarken), dailyLineDaysFromBeginning)
                                      : getSevenMonthsMarginClimData(climDarken),
                                  color: "#FDBE9D",
                                  opacity: 0.6,
                              }
                            : null,
                        // Confidence Bands
                        ["daily"].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastConfidence95,
                                      isMonthly: actionsState.isMonthly,
                                      //   climatology: duplicateMonthlyHistoricalDataForFutureSevenMonths(climMaxLighten),
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              key: +new Date(d),
                                              y1: monthlyData.ds_fc.ssrd_mean["0.95"][i],
                                              y0: monthlyData.ds_fc.ssrd_mean["0.05"][i],
                                              max: monthlyData.ds_fc.ssrd_mean["0.95"][i],
                                              min: monthlyData.ds_fc.ssrd_mean["0.05"][i],
                                          }
                                      }),
                                  }),
                                  color: actionsState.isMonthly ? "#FFBB97" : "#EE6900",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastConfidence75,
                                      isMonthly: actionsState.isMonthly,
                                      //climatology: climMaxDarken,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              key: +new Date(d),
                                              y1: monthlyData.ds_fc.ssrd_mean["0.75"][i],
                                              y0: monthlyData.ds_fc.ssrd_mean["0.25"][i],
                                              max: monthlyData.ds_fc.ssrd_mean["0.75"][i],
                                              min: monthlyData.ds_fc.ssrd_mean["0.25"][i],
                                          }
                                      }),
                                  }),
                                  color: actionsState.isMonthly ? "#FB9769" : "#EE6900",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,

                        // {
                        //   type: 'area',
                        //   points: !actionsState.isMonthly ? trimmData(forecastConfidence95) : forecastConfidence95,
                        //   color: '#237CB5',
                        //   'opacity': 0.4,
                        // },
                        // {
                        //   type: 'area',
                        //   points: !actionsState.isMonthly ? trimmData(forecastConfidence75) : forecastConfidence75,
                        //   color: '#237CB5',
                        //   'opacity': 0.4,
                        // },
                        // {
                        //   type: 'line',
                        //   points: !actionsState.isMonthly ?
                        //     trimmData(historicalTemp).concat(trimmData(forecastTemp).map(d => Object.assign(d, { dashed: true }))) :
                        //     historicalTemp.concat(forecastTemp.map(d => Object.assign(d, { dashed: true }))),
                        //   color: '#237CB5',
                        //   'stroke-width': 2
                        // },
                        ["daily"].includes(actionsState.selectedGranularity)
                            ? {
                                  type: "line",
                                  id: "line-mid",
                                  alwaysOnTop: true,
                                  points: assembleLineData({
                                      isMonthly: actionsState.isMonthly,
                                      historical: historicalTemp,
                                      forecast: forecastTemp,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: new Date(d),
                                              y: monthlyData.ds_fc.ssrd_mean["0.5"][i],
                                          }
                                      }),
                                  }),
                                  color: "#EE6900",
                                  "stroke-width": 2,
                              }
                            : null,
                    ]}
                ></LineAreaChartComponent>
            </div>
            <div className="weather-chart__specs-container">
                <ChartSpecs
                    type="solar-radiation"
                    barType={barType}
                    historicalPending={historicalPending}
                    chartRef={chartRef}
                    climatologyVisible={climatologyVisible}
                    confidenceVisible={confidenceVisible}
                    handleBarTypeChange={(candlChecked) => {
                        if (candlChecked) setBarType("candl")
                        else setBarType("med")
                    }}
                    selectedGranularity={actionsState.selectedGranularity}
                    lastUpdated={lastUpdated}
                    handleAreasVisibilityChange={({ conf, clim }) => {
                        setConfidenceBarsVisibility(conf)
                        setClimatologyVisible(clim)
                    }}
                    colsArr={["Date", "Value (W/m^2)"]}
                    data={{
                        csv:
                            actionsState.selectedGranularity === "daily"
                                ? csvDailyData
                                : actionsState.selectedGranularity === "weekly"
                                ? csvWeeklyData
                                : csvMonthlyData,
                        // hourlyCsv: mergeHistoricalAndForecastData({
                        //     forecast: hourlyData.ds_fc,
                        //     historical: hourlyData.ds_hist,
                        //     prop: "ssrd",
                        // }),
                    }}
                    // onHourlyCsvDataTrigger={() => {
                    //     return new Promise((resolve, reject) => {
                    //         currentUser.getIdToken().then((userToken) => {
                    //             networking
                    //                 .get(`/api/v1/weather/solar_radiation/hourly/${id}`, {
                    //                     extraHeaders: { "User-Token": userToken },
                    //                 })
                    //                 .then((res) => {
                    //                     setHourlyData({
                    //                         ...res.data,
                    //                     })
                    //                     resolve(res.data)
                    //                 })
                    //                 .catch(() => {
                    //                     reject()
                    //                 })
                    //         })
                    //     })
                    // }}
                    actionsState={actionsState}
                />
            </div>
        </div>
    )
}

export default SolarRadiationChart

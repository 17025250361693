import React, { useMemo, useRef, useState, useEffect, useContext } from "react"
import ReactDOMServer from "react-dom/server"
import { CircularProgress, useMediaQuery } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { SettingsContext } from "../../../../Util/SettingsContext"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"

import { getHistoricalTemp, getForecastArr, getForecastTemp, getClim, trimmData, getWindDirectionText } from "./helper"

import {
    processUnitSystem,
    convertToShadedRangesFormat,
    getForecastConfidenceData,
    addMonths,
    validateData,
    addDays,
    getUnit,
    assembleAreaData,
    assembleLineData,
    getSevenMonthsMarginClimData,
    convertAPIDataToSeasonalBarStructure,
    convertDataToBarStructure,
    convertToLineFromBar,
    convertToDoubleCandlestick,
    renderArrows,
    hourlyDataTrim,
} from "../../../../helpers/chartHelpers"

import { convertWindSpeedKmhToMph } from "../../../../Util/UnitConversion"

import ChartSpecs from "../ChartSpecs"
import networking from "../../../../Util/Networking"
import { AuthContext } from "../../../../Auth/Auth"
import useLastUpdated from "../../../../hooks/useLastUpdated"
import { ToastContext } from "../../../../components/Toast/ToastContextProvider"
import ArrowRight from "../../../../ui/Icons/ArrowRight"
import WeatherArrowIcon from "../../../../ui/Icons/WeatherArrowIcon"
import WeatherEqualsIcon from "../../../../ui/Icons/WeatherEqualsIcon"
import WindDirectionArrowIcon from "../../../../ui/Icons/WindDirectionArrowIcon"
import { WindSpeedIcon } from "../../../../ui/Icons/newIcons/weatherVariableIcons"

const WindSpeedChart = ({ actionsState }) => {
    const chartRef = useRef(null)
    const { currentUser } = useContext(AuthContext)
    const { currentSettings } = useContext(SettingsContext)
    const { enqueueError, enqueueNetworkError } = useContext(ToastContext)
    const { id } = useParams()
    const [fieldId, setFieldId] = useState()

    const [confidenceVisible, setConfidenceBarsVisibility] = useState(false)
    const [climatologyVisible, setClimatologyVisible] = useState(true)

    const [historicalPending, setHistoricalPending] = useState(false)

    // Prepare initial data
    const weatherVariable = "wind_speed"

    // Media query for ticks and hourly viz
    // large breakpoint for ticks count
    const mediaBreakpointLarge = useMediaQuery("(max-width: 1200px)", { noSsr: true })
    // medium breakpoint for 8h display (mobile devices)
    const mediaBreakpointMedium = useMediaQuery("(max-width: 600px)", { noSsr: true })

    // ******************************* BAR DATA PART ****************************

    //---------------------------  MONTHLY SEASONAL  ------------------------
    // BarData - Forecast
    const [monthlyBarForecastData, setMonthlyBarForecastData] = useState({
        time: [],
    })

    // BarData - Climatology
    const [monthlyBarClimatologyData, setMonthlyBarClimatologyData] = useState({
        time: [],
    })

    // BarData - Forecast - Vigintiles
    const [monthlyBarForecastDataVigintiles, setMonthlyBarForecastDataVigintiles] = useState({ time: [] })

    // BarData - Climatology - Vigintiles
    const [monthlyBarClimatologyDataVigintiles, setMonthlyBarClimatologyDataVigintiles] = useState({ time: [] })

    // Bar Data - Insights
    const barDataInsights = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "ws_mean",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                property: "ws_mean",
            }),
        [monthlyBarForecastDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarClimatologyDataVigintiles,
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
                property: "ws_mean",
            }),
        [monthlyBarClimatologyDataVigintiles, monthlyBarForecastDataVigintiles]
    )

    const [barType, setBarType] = useState("candl")
    const [windDirectionVisible, setWindDirectionVisible] = useState(true)

    // ******************************* END OF BAR DATA PART ****************************

    const [data, setData] = useState({
        ds_hist: {
            time: [],
            ws_mean: [],
        },
        ds_fc: {
            time: [],
            ws_mean: [],
        },
        ds_clim: {
            time: [],
            ws_mean: [],
        },
        pending: true,
    })

    const [hourlyData, setHourlyData] = useState({
        // hourly data only shows forecast
        // ds_hist: {
        //     time: [],
        //     ws: [],
        // },
        ds_fc: {
            time: [],
            ws: [],
        },
    })

    const [wdHourlyData, setWDHourlyData] = useState({
        ds_fc: {
            time: [],
            wd: [],
        },
    })

    const [monthlyData, setMonthlyData] = useState({
        ds_hist: {
            time: [],
            ws_mean: [],
        },
        ds_fc: {
            time: [],
            ws_mean: [],
        },
        ds_clim: {
            time: [],
            ws_mean: [],
        },
        pending: true,
    })

    // Alerts Data
    const [alertsData, setAlertsData] = useState({
        ws_mean: {},
    })

    // Load data
    useEffect(() => {
        if (fieldId !== id || (fieldId === id && climatologyVisible && data.ds_clim.time.length === 0)) {
            setData((prevData) => ({
                ...prevData,
                pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
            }))

            let localData = data

            if (climatologyVisible) {
                setHistoricalPending(true)
            }
            // let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
            // if (climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
            let datasets = "history%2Cforecast%2Cclimatology"
            if (!climatologyVisible) {
                if (fieldId !== id) {
                    localData.pending = true
                }
                localData = Object.assign({}, localData, {
                    ds_clim: {
                        time: [],
                        ws_mean: [],
                    },
                })
                setData(localData)
            }

            currentUser.getIdToken().then((userToken) => {
                // get hourly data
                networking
                    .get(`/api/v1/weather/${weatherVariable}/hourly/${id}?datasets=forecast`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (datasets.indexOf("climatology") !== -1) {
                            setHistoricalPending(false)
                        }

                        setHourlyData({
                            ...Object.assign({}, res.data),
                            pending: false,
                        })
                    })
                    .catch(() => {
                        setHourlyData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        enqueueNetworkError()
                    })

                // get hourly wind direction
                networking
                    .get(`/api/v1/weather/wind_direction/hourly/${id}?datasets=forecast`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (datasets.indexOf("climatology") !== -1) {
                            setHistoricalPending(false)
                        }
                        console.log(res.data)
                        setWDHourlyData({
                            ...Object.assign({}, localData, res.data),
                            pending: false,
                        })
                    })
                    .catch(() => {
                        setWDHourlyData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        enqueueNetworkError()
                    })

                networking
                    .get(`/api/v1/weather/${weatherVariable}/daily/${id}?datasets=${datasets}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (datasets.indexOf("climatology") !== -1) {
                            setHistoricalPending(false)
                        }
                        setData({
                            ...Object.assign({}, localData, res.data),
                            pending: false,
                        })
                    })
                    .catch(() => {
                        setData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        enqueueNetworkError()
                    })

                // Break network call if data already loaded
                if (fieldId === id && localData.ds_fc.time.length) {
                    return
                }

                // networking
                //     .get(
                //         `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                //         {
                //             extraHeaders: { "User-Token": userToken },
                //         }
                //     )
                //     .then((res) => {
                //         console.log(res.data)
                //         setMonthlyBarForecastData(res.data.ds_fc)
                //         setMonthlyBarClimatologyData(res.data.ds_clim)
                //     })
                //     .catch(() => {
                //         enqueueNetworkError()
                //     })

                // networking
                //     .get(
                //         `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                //         {
                //             extraHeaders: { "User-Token": userToken },
                //         }
                //     )
                //     .then((res) => {
                //         console.log(res.data)
                //         setMonthlyBarForecastDataVigintiles(res.data.ds_fc)
                //         setMonthlyBarClimatologyDataVigintiles(res.data.ds_clim)
                //     })
                //     .catch(() => {
                //         enqueueNetworkError()
                //     })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        console.log("seasonal terciles", res)
                        setMonthlyBarForecastData(res.data.ds_fc)
                        setMonthlyBarClimatologyData(res.data.ds_clim)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setMonthlyBarForecastDataVigintiles(res.data.ds_fc)
                        setMonthlyBarClimatologyDataVigintiles(res.data.ds_clim)
                    })
                    .catch(() => {
                        //         toast.warn(
                        //             `Alerts not displayed on dashboard due to internet
                        // connectivity issues. All other functions working.`
                        //         )
                    })

                networking
                    .get(`/api/v1/alertsettings/${weatherVariable}/${id}?datasets=history%2Cforecast`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        setAlertsData(res.data)
                    })
                    .catch(() => {
                        //             toast.warn(
                        //                 `Alerts not displayed on dashboard due to internet
                        //   connectivity issues. All other functions working.`
                        //             )
                    })
            })
            setFieldId(id)
        }
    }, [currentUser, id, climatologyVisible, actionsState.selectedGranularity])

    const lastUpdated = useLastUpdated(data, monthlyData)

    // Prepare historical data
    const historicalTemp = useMemo(() => {
        try {
            return getHistoricalTemp(data["ds_hist"])
        } catch (e) {
            enqueueError("Problem ocurred processsing information")
            throw e
        }
    }, [data])

    // Prepare forecast data
    const forecastArr = useMemo(() => {
        try {
            return getForecastArr(data["ds_fc"])
        } catch (e) {
            return []
        }
    }, [data])
    const forecastTemp = useMemo(() => {
        try {
            return getForecastTemp(data["ds_fc"], forecastArr)
        } catch (e) {
            return []
        }
    }, [data, forecastArr])

    // Prepare Confidence Data
    const forecastConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalTemp[historicalTemp.length - 1],
            data["ds_fc"]["ws_mean"],
            "0.75"
        )
    }, [data, historicalTemp])
    const forecastConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalTemp[historicalTemp.length - 1],
            data["ds_fc"]["ws_mean"],
            "0.95"
        )
    }, [data, historicalTemp])

    // Prepare clim data
    const { climLighten, climDarken } = useMemo(() => {
        try {
            return getClim(data["ds_clim"])
        } catch (e) {
            return []
        }
    }, [data])

    // Look at the changes for historical and forecast data and display warning messages if invalid
    useMemo(() => {
        validateData({
            diffToAlert: 15,
            historic: climLighten,
            forecast: forecastTemp,
            accessorKey: "y",
            message: "Forecast Anomaly Detected",
        })
    }, [forecastTemp, climLighten])

    const lineData = assembleLineData({
        isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
        historical: historicalTemp,
        forecast: forecastTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.ws_mean["0.5"][i],
            }
        }),
    })

    // get ammount of days from the beginning of the line, when it's trimmed inside assembleLineData function
    // sometimes we endup with less than 12 days because of the line being icomplete from historical (see SE-1919)
    const dailyLineDaysFromBeginning =
        (lineData.length > 0 && Math.floor((new Date().getTime() - lineData[0].x) / (24 * 60 * 60 * 1000))) + 1 || 0

    const lineDataMonthly = monthlyBarForecastDataVigintiles.time.map((d, i) => {
        return {
            x: new Date(d),
            y: monthlyBarForecastDataVigintiles.ws_mean[0.5][i],
        }
    })

    // windspeed data
    let lineDataHourly =
        (actionsState.selectedGranularity === "hourly" &&
            assembleLineData({
                selectedGranularity: actionsState.selectedGranularity,
                hourlyData: hourlyData,
                // wdHourlyData: wdHourlyData,
                propVar: "ws",
                ...(windDirectionVisible && {
                    color: "#fff",
                    stroke: "#db620a",
                    fill: "#fff",
                    strokeWidth: 1,
                    radius: 12,
                }),
            }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "ws" }))) ||
        []

    if (actionsState.selectedGranularity === "hourly" && lineDataHourly.length > 0) {
        // slice wind speed data depending on the view breakpoint
        // TODO change this later, está bien erizo
        if (mediaBreakpointMedium) lineDataHourly = lineDataHourly.slice(0, 8)
        else if (mediaBreakpointLarge) lineDataHourly = lineDataHourly.slice(0, 18)

        // merge windspeed data and winddirection data
        // find first hour in common
        let firstHourIndex = -1
        for (let i = 0; i < wdHourlyData.ds_fc.time.length; i++) {
            let t = wdHourlyData.ds_fc.time[i]
            if (new Date(t) >= lineDataHourly[0].x) {
                firstHourIndex = i
                break
            }
        }

        // iterate over lineDataHourly and start adding
        // wd values from firstHourIndex + i
        lineDataHourly.forEach((_, i) => {
            if (lineDataHourly[i]) {
                lineDataHourly[i]["wd"] = wdHourlyData.ds_fc.wd[firstHourIndex + i]
            }
        })
    }

    const csvData = lineData.map((d) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })],
        [currentSettings.units === "metric" ? d.y : convertWindSpeedKmhToMph(d.y)],
    ])

    const csvMonthlyData = lineDataMonthly.map((d) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", year: "numeric" })],
        [d.y],
    ])

    const csvHourlyData = lineDataHourly.map((d) => [
        [
            new Date(d.x).toLocaleDateString(undefined, {
                month: "short",
                year: "numeric",
                hour: "numeric",
                day: "numeric",
            }),
        ],
        [d.y],
        [d.wd],
    ])

    const colsArr = ["Date", `Value (${getUnit({ system: currentSettings.units }).windSpeedUnit})`]
    if (actionsState.selectedGranularity === "hourly") colsArr.push("Wind Direction °")

    return (
        <div className="weather-chart">
            <div className="weather-chart__chart-container">
                <div style={{ display: data.pending ? "flex" : "none" }} className="weather-chart__preload-container">
                    <CircularProgress />
                </div>

                <LineAreaChartComponent
                    // space between yaxis and ytick
                    yTickMarginLeft={(actionsState.selectedGranularity === "hourly" && 20) || 10}
                    // Pass height externally
                    svgHeight={650}
                    // Title text
                    title="Wind Speed"
                    // Y label text
                    labelY={`Wind Speed in ${getUnit({ system: currentSettings.units }).windSpeedUnit}`}
                    // Convert received data to shaded ranges format
                    shadedRanges={
                        (actionsState.selectedGranularity !== "hourly" &&
                            convertToShadedRangesFormat(alertsData, Object.keys(alertsData))) ||
                        null
                    }
                    // Pass unique resize event key
                    resizeEventListenerId="wind_speed-chart"
                    // Add chart data id to filter out some update requests
                    chartDataId={
                        {
                            daily: `month`,
                            monthly: `day`,
                            hourly: `hour`,
                        }[actionsState.selectedGranularity] +
                        "_wind_speed-chart_" +
                        climLighten.length
                    }
                    // Center Ticks
                    centerTicks={
                        {
                            daily: false,
                            monthly: true,
                            hourly: false,
                        }[actionsState.selectedGranularity]
                    }
                    // Make chart to have dynamic y basis
                    zeroBasis={true}
                    // Bottom margin will be 0.2 times of data diff
                    yBottomOffset={0.2}
                    // Top margin will be 0.3 times of data diff
                    yTopOffset={0.3}
                    marginTop={65}
                    // Provide custom date max axis extent for monthly view charts
                    xDateMax={
                        {
                            daily: addDays(new Date(), 7),
                            // monthly: addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), 2), 7),
                            monthly: addMonths(
                                new Date(
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getFullYear(),
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getMonth() + 1,
                                    1
                                ),
                                0
                            ),
                            hourly: null,
                        }[actionsState.selectedGranularity]
                    }
                    // Provide custom date min axis extent for monthly view charts
                    xDateMin={
                        {
                            daily: addDays(new Date(), -6),
                            //monthly: addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), 28), -7),
                            monthly: (() => {
                                const today = new Date()
                                const year = today.getFullYear()
                                const month = today.getMonth()
                                const result = new Date(year, month, 1)
                                return result
                            })(),
                            hourly: null,
                        }[actionsState.selectedGranularity]
                    }
                    // How x ticks will be formatted in chart
                    xTickFormat={
                        {
                            daily: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getDate()
                                if (i === 0) return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                            },
                            monthly: (d, i, arr) => {
                                // Remove last, overflowing tick item
                                if (i === arr.length - 1) return ""
                                return d.toLocaleString(undefined, { month: "short" })
                            },
                            hourly: (d, i) => {
                                return (
                                    d
                                        .toLocaleString("en-US", { hour: "numeric", hour12: true })
                                        .toLowerCase()
                                        .replace(" ", "") +
                                    ((d &&
                                        (d.getHours() === 0 || i === 0) &&
                                        "\n" + d.toLocaleString("en-US", { month: "short", day: "numeric" })) ||
                                        "")
                                )
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Give chart tips count tip
                    xTicksCount={
                        {
                            daily: 14,
                            monthly: 5,
                            hourly: (lineDataHourly && lineDataHourly.length) || 0,
                        }[actionsState.selectedGranularity]
                    }
                    // Hide chart if data is pending
                    hide={data.pending}
                    // Tooltip content on line points mouse over
                    tooltip={(EVENT, d, state) => {
                        const { key, values, colors, lines, points } = d
                        let prec = 10
                        let hour = undefined
                        let day = "numeric"
                        let month = "short"

                        let titleWidth = 40
                        let titleFontSize = 20

                        if (actionsState.selectedGranularity === "hourly") {
                            prec = 100
                            return ReactDOMServer.renderToStaticMarkup(
                                <div className="w-[240px] p-2 text-[14px]">
                                    <div className="text-gray-60 pb-1">
                                        {key.toLocaleString("en-US", {
                                            day: "numeric",
                                            month: "short",
                                            year: "numeric",
                                            hour: "numeric",
                                            hour12: true,
                                        })}{" "}
                                    </div>
                                    <div className="flex justify-between border-b border-b-gray-20 pb-1">
                                        <div className="flex items-center text-gray-60">
                                            <div className="w-[18px] h-[18px]">
                                                <WindSpeedIcon fill="var(--color-gray-60)" />
                                            </div>
                                            <label className="">Wind Speed</label>
                                        </div>
                                        <p>
                                            {Math.round(values[0] * prec) / prec}
                                            {getUnit({ system: currentSettings.units }).windSpeedUnit}
                                        </p>
                                    </div>
                                    <div className="flex justify-between pt-1">
                                        <div className="flex items-center text-gray-60">
                                            <div className="w-[18px] h-[18px] flex justify-center items-center">
                                                <div
                                                    className="w-[15px] h-[15px]"
                                                    style={{ transform: `rotate(${(points[0].wd + 180) % 360}deg)` }}
                                                >
                                                    <WindDirectionArrowIcon fill="var(--color-gray-60)" />
                                                </div>
                                            </div>
                                            <label className="">Wind Direction</label>
                                        </div>
                                        <p>{getWindDirectionText(points[0].wd)}</p>
                                    </div>
                                </div>
                            )
                        }

                        let dateStr = key.toLocaleString(undefined, { day, month, hour })

                        if (actionsState.selectedGranularity === "monthly") {
                            dateStr = key.toLocaleString(undefined, { month: "short" })
                        }

                        if (barType === "candl") {
                            day = undefined
                        }

                        return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                            <tr><td style="font-weight:bold;font-size:${titleFontSize}px" rowspan="${
                            values.length
                        }"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:${titleWidth}px;line-height:1.1">${dateStr}</div></td> 
                                <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                                    points[0].dashed ? "dashed" : "solid"
                                } ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${
                            Math.round(values[0] * prec) / prec
                        }${getUnit({ system: currentSettings.units }).windSpeedUnit}</td>
                            </tr>
                            ${values
                                .filter((_, i) => i > 0)
                                .map((value, i) => {
                                    return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                                        points[i + 1].dashed ? "dashed" : "solid"
                                    } ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${
                                        Math.round(value * prec) / prec
                                    } ${getUnit({ system: currentSettings.units }).windSpeedUnit}</td></tr>`
                                })
                                .join("")}
                        </table>`
                    }}
                    shapeTip={
                        {
                            hourly: null,
                            daily: null,
                            monthly: (EVENT, boundObj, state, point) => {
                                let resultObj = point ?? boundObj
                                let month = resultObj.key
                                    .toLocaleString("en-US", { month: "short", year: "numeric" }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                    .split("")
                                    .join("")
                                const prec = 100
                                const warmer = Math.round(prec * barDataInsights.normalMax[month]) / prec
                                let clim67 = Math.round(prec * barDataInsights.clim67[month]) / prec
                                const normal = Math.round(prec * barDataInsights.normal[month]) / prec
                                let clim33 = Math.round(prec * barDataInsights.clim33[month]) / prec
                                const colder = Math.round(prec * barDataInsights.normalMin[month]) / prec

                                let unit = getUnit({ system: "metric" }).windSpeedUnit
                                if (currentSettings.units === "imperial") {
                                    clim33 = Math.round(+convertWindSpeedKmhToMph(clim33) * 100) / 100
                                    clim67 = Math.round(+convertWindSpeedKmhToMph(clim67) * 100) / 100
                                    unit = getUnit({ system: "imperial" }).windSpeedUnit
                                }

                                return `<div style="max-width:250px;">There is a ${warmer}% chance that wind speed will be higher than usual (above ${clim67} ${unit} ). </br>  </br> There is a ${normal}% chance that wind speed will be within the normal range (${clim33} to ${clim67}  ${unit}). </br>  </br>There is a ${colder}% chance that wind speed will be lower than normal (below ${clim33}  ${unit}).</div>`
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Chart data content
                    data={[
                        //======================= PLOT MEDIAN AS LINE - monthly ===================
                        ["monthly"].includes(actionsState.selectedGranularity)
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBar({
                                      obj: forecastQuantilesBarData[0.5],
                                      props: {
                                          color: "#EE6900",
                                          barType: barType,
                                          visible: true,
                                          unit: currentSettings.units,
                                          unitType: "ws_custom",
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsights)
                                                  return {
                                                      color: "",
                                                      symbol: `
                                <style>
                                .point-loader {
                                border: 3px solid #f3f3f3;
                                border-radius: 50%;
                                border-top: 3px solid #3498db;
                                width: 10px;
                                height: 10px;
                                -webkit-animation: spin 2s linear infinite; /* Safari */
                                animation: spin 2s linear infinite;
                                }

                                /* Safari */
                                @-webkit-keyframes spin {
                                0% { -webkit-transform: rotate(0deg); }
                                100% { -webkit-transform: rotate(360deg); }
                                }

                                @keyframes spin {
                                0% { transform: rotate(0deg); }
                                100% { transform: rotate(360deg); }
                                }
                                </style>

                                <div class="point-loader"></div>
                                `,
                                                  }
                                              const month = d.key
                                                  .toLocaleString("en-US", {
                                                      month: "short",
                                                      year: "numeric",
                                                  }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                                  .split("")
                                                  .join("")
                                              const normalMax = barDataInsights.normalMax[month]
                                              const normalMin = barDataInsights.normalMin[month]
                                              const normal = barDataInsights.normal[month]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])

                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        //=================== DOUBLE  CANDLESTICK VERSION  =========================

                        [""].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToLineFromBar({
                                  obj: forecastQuantilesBarData[0.5],
                                  props: {
                                      color: "#EE6900",
                                      barType: barType,
                                      visible: false,
                                      unit: currentSettings.units,
                                      unitType: "ws_custom",
                                      date: 15,
                                  },
                              })
                            : null,

                        ["monthly"].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToDoubleCandlestick({
                                  obj: [
                                      {
                                          values: [forecastQuantilesBarData, climatologyQuantilesBarsData],
                                      },
                                  ],
                                  unit: currentSettings.units,
                                  unitType: "ws_custom",
                                  colors: ["#EE6900", "#EE6900"],
                              })
                            : null,

                        // ===================== End Of Horizontal and vertical bars versions  ====================

                        ["daily", ""].includes(actionsState.selectedGranularity) && climatologyVisible
                            ? {
                                  type: "area",
                                  points: (!["monthly"].includes(actionsState.selectedGranularity)
                                      ? trimmData(getSevenMonthsMarginClimData(climLighten), dailyLineDaysFromBeginning)
                                      : getSevenMonthsMarginClimData(climLighten)
                                  ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "ws" })),
                                  color: "#E2E2CA",
                                  opacity: 0.6,
                              }
                            : null,
                        ["daily", ""].includes(actionsState.selectedGranularity) && climatologyVisible
                            ? {
                                  type: "area",
                                  points: (!["monthly"].includes(actionsState.selectedGranularity)
                                      ? trimmData(getSevenMonthsMarginClimData(climDarken), dailyLineDaysFromBeginning)
                                      : getSevenMonthsMarginClimData(climDarken)
                                  ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "ws" })),
                                  color: "#D3D292",
                                  opacity: 0.6,
                              }
                            : null,
                        // Confidence Bands

                        ["daily", ""].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastConfidence95,
                                      isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y1: monthlyData.ds_fc.ws_mean["0.95"][i],
                                              y0: monthlyData.ds_fc.ws_mean["0.05"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "ws" })),
                                  color: ["monthly"].includes(actionsState.selectedGranularity) ? "#ffa47d" : "#d05e0a",
                                  opacity: ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.4,
                              }
                            : null,

                        ["daily", ""].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastConfidence75,
                                      isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y1: monthlyData.ds_fc.ws_mean["0.75"][i],
                                              y0: monthlyData.ds_fc.ws_mean["0.25"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "ws" })),
                                  color: ["monthly"].includes(actionsState.selectedGranularity) ? "#ff7a50" : "#db630a",
                                  opacity: ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.4,
                              }
                            : null,

                        ["daily", ""].includes(actionsState.selectedGranularity)
                            ? {
                                  type: "line",
                                  id: "line-mid",
                                  alwaysOnTop: true,
                                  points: lineData.map((d) =>
                                      processUnitSystem(d, { system: currentSettings.units, type: "ws" })
                                  ),
                                  color: "#db620a",
                                  "stroke-width": 2,
                              }
                            : null,

                        ["hourly"].includes(actionsState.selectedGranularity)
                            ? {
                                  type: "line",
                                  id: "line-mid",
                                  alwaysOnTop: true,
                                  color: "#db620a",
                                  "stroke-width": 2,
                                  points: lineDataHourly,
                                  renderCustomPoint:
                                      (windDirectionVisible &&
                                          ((d) => {
                                              let wd = (d.wd + 180) % 360
                                              return ReactDOMServer.renderToStaticMarkup(
                                                  <div
                                                      style={{
                                                          width: "100%",
                                                          height: "100%",
                                                          top: 0,
                                                          left: 0,
                                                          display: "flex",
                                                          justifyContent: "center",
                                                          alignItems: "center",
                                                      }}
                                                  >
                                                      {Number.isSafeInteger(wd) && (
                                                          <div
                                                              style={{
                                                                  width: "100%",
                                                                  height: "100%",
                                                                  padding: 5,
                                                                  display: "flex",
                                                                  justifyContent: "center",
                                                                  alignItems: "center",
                                                                  fill: "#EE6900",
                                                              }}
                                                          >
                                                              <WindDirectionArrowIcon
                                                                  transform={`rotate(${wd}, 7, 7)`}
                                                              />
                                                          </div>
                                                      )}
                                                  </div>
                                              )
                                          })) ||
                                      undefined,
                              }
                            : null,
                    ]}
                ></LineAreaChartComponent>
            </div>
            <div className="weather-chart__specs-container">
                <ChartSpecs
                    type="wind_speed"
                    selectedGranularity={actionsState.selectedGranularity}
                    barType={barType}
                    handleBarTypeChange={(candlChecked) => {
                        if (candlChecked) setBarType("candl")
                        else setBarType("med")
                    }}
                    historicalPending={historicalPending}
                    chartRef={chartRef}
                    climatologyVisible={climatologyVisible}
                    confidenceVisible={confidenceVisible}
                    windDirectionVisible={windDirectionVisible}
                    toggleWindDirection={setWindDirectionVisible}
                    lastUpdated={lastUpdated}
                    handleAreasVisibilityChange={({ conf, clim, wd }) => {
                        setConfidenceBarsVisibility(conf)
                        setClimatologyVisible(clim)
                    }}
                    colsArr={colsArr}
                    data={{
                        // csv: actionsState.selectedGranularity === "daily" ? csvData : csvMonthlyData,
                        csv: { daily: csvData, monthly: csvMonthlyData, hourly: csvHourlyData }[
                            actionsState.selectedGranularity
                        ],
                    }}
                    // onHourlyCsvDataTrigger={() => {
                    //     return new Promise((resolve, reject) => {
                    //         currentUser.getIdToken().then((userToken) => {
                    //             if (hourlyData.ds_fc.time.length > 0) {
                    //                 setTimeout((d) => {
                    //                     const mergedData = mergeHistoricalAndForecastData({
                    //                         forecast: hourlyData.ds_fc,
                    //                         historical: hourlyData.ds_hist,
                    //                         prop: "ws",
                    //                     })
                    //                     resolve(mergedData)
                    //                 }, 1000)
                    //             } else {
                    //                 networking
                    //                     .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                    //                         extraHeaders: { "User-Token": userToken },
                    //                     })
                    //                     .then((res) => {
                    //                         setHourlyData({
                    //                             ...res.data,
                    //                         })
                    //                         resolve(res.data)
                    //                     })
                    //                     .catch(() => {
                    //                         reject()
                    //                     })
                    //             }
                    //         })
                    //     })
                    // }}
                    actionsState={actionsState}
                />
            </div>
        </div>
    )
}

export default WindSpeedChart

import React, { useState, useEffect, useContext } from "react"
import { MapboxPolygonViewerComponent } from "../Charts/MapboxMaps/MapboxPolygonViewer/MapboxPolygonViewer"
import { AuthContext } from "../../Auth/Auth"
import networking from "../../Util/Networking"
import { useParams } from "react-router-dom"

import { ToastContext } from "../Toast/ToastContextProvider"

export const FieldPropertiesContext = React.createContext(null)

function FieldProperties() {
    const [polygonData, setPolygonData] = useState({})
    const [fieldData, setFieldData] = useState({})
    let { id } = useParams()
    const { currentUser } = useContext(AuthContext)
    const { enqueueError } = useContext(ToastContext)

    useEffect(() => {
        currentUser.getIdToken().then((userToken) => {
            // Load polygon data
            networking
                .get(`/api/v1/maps/ndvimap?uuid=${id}`, {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    setPolygonData(res.data)
                })
                .catch((err) => {
                    enqueueError("Could not load polygon data.")
                })

            // Load fields data
            networking
                .get(`/api/v1/fields/${id}`, {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    setFieldData(res.data.data)
                })
                .catch((err) => {
                    enqueueError("Could not load field data.")
                })
        })
    }, [currentUser, id])

    // Render field property lists and their values
    return (
        <FieldPropertiesContext.Provider value={(fieldData, polygonData)}>
            <div className="h-full p-[14px] md:p-[18px] flex flex-row items-center justify-between space-x-2 bg-white rounded-lg border-[1px] border-gray-10">
                <div className="roboto flex flex-col justify-between w-full h-full py-1 shrink-0 grow-0 text-[14px] text-gray-60 max-w-fit space-y-2">
                    <div>
                        <b className="font-bold">Category:</b> {fieldData.crop}
                    </div>
                    <div>
                        <b className="font-bold">Subcategory:</b> {fieldData.variety}
                    </div>
                    <div>
                        <b className="font-bold">Region:</b> {fieldData.region}
                    </div>
                    <div style={{ display: "none" }}>
                        <b>lat lon:</b>{" "}
                        {polygonData.center ? `${polygonData.center.lat},${polygonData.center.lon}` : ""}
                    </div>
                </div>

                <div className="overflow-hidden w-full h-full rounded-sm">
                    <MapboxPolygonViewerComponent
                        polygonData={polygonData}
                        height="100%"
                        width="100%"
                    ></MapboxPolygonViewerComponent>
                </div>
            </div>
        </FieldPropertiesContext.Provider>
    )
}

export default FieldProperties

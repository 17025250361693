import { useContext } from "react"
import { DataContext } from "../../Util/Data"
import ReportsView from "./ReportsView"

const RegulatoryReports = ({}) => {
    const {
        state: { regulatoryReports },
    } = useContext(DataContext)

    return <ReportsView reports={regulatoryReports} />
}

export default RegulatoryReports

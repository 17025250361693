import React, { Children } from "react"

function Dropdown(props) {
    const { title, children, __onClose__, className = "" } = props

    const childrenArray = Children.toArray(children)

    if (childrenArray.length === 0) {
        throw new TypeError("You must provide children to this component.")
    }

    return (
        <>
            <div className={`small-controls ${className}`}>
                <div className="control-item">
                    {title && (
                        <div className="control-item__header">
                            <button className="control-item__title">{title}</button>
                        </div>
                    )}
                    <div className="control-item__content">
                        <div className="control-item__content__default-options">
                            {childrenArray.map((child) => {
                                return React.cloneElement(child, { actions: { close: __onClose__ } })
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dropdown

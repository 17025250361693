import * as React from "react"

const ToolsIcon = (props) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)">
            <path
                d="M21.575 17.457 17 12.883a2.9 2.9 0 0 0-3.335-.543L9.5 8.176V5.75L4.5 2 2 4.5l3.75 5h2.426l4.164 4.164A2.907 2.907 0 0 0 12.883 17l4.575 4.574c.57.57 1.492.57 2.058 0l2.059-2.058a1.46 1.46 0 0 0 0-2.059Zm-6.617-6.668A4.11 4.11 0 0 1 17.883 12l.758.758a5.53 5.53 0 0 0 1.711-1.152 5.618 5.618 0 0 0 1.48-5.34.468.468 0 0 0-.785-.215l-2.906 2.906-2.652-.441-.442-2.653 2.907-2.906a.472.472 0 0 0-.223-.789 5.627 5.627 0 0 0-5.336 1.48c-1.113 1.114-1.637 2.582-1.61 4.047l3.208 3.207c.316-.074.644-.113.964-.113Zm-4.06 3.203-2.214-2.215-5.953 5.957a2.499 2.499 0 1 0 3.535 3.535l4.828-4.828c-.297-.777-.386-1.625-.195-2.449ZM4.5 20.437a.94.94 0 0 1-.937-.937c0-.52.418-.938.937-.938a.938.938 0 0 1 0 1.875Z"
                fill={props.fill}
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default ToolsIcon

import React from "react"

import "./ConfirmationModal.css"

const ConfirmationModal = (props) => {
    const { title, text, onContinue, continueDisabled = true } = props

    return (
        <div className="confirmation-modal">
            <div className="confirmation-modal__container">
                <div className="confirmation-modal__title">{title}</div>
                <div className="confirmation-modal__text">{text}</div>
                <button className="confirmation-modal__continue-button" onClick={onContinue} disabled={continueDisabled}>
                    Continue
                </button>
            </div>
        </div>
    )
}

export default ConfirmationModal

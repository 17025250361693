import { createContext, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Toast from "./Toast"

const GENERIC_ERROR_MESSAGE = "There was an error. Please try again."
const PERMISSIONS_ERROR_MESSAGE = "Your account does not have permission to do this action."
const NETWORK_ERROR_MESSAGE = "There is a connectivity issue. Please try again."

export const ToastContext = createContext({})

function ToastWrapper({ children }) {
    const [activeAlert, setActiveAlert] = useState(null)
    const [alertsQueue, setAlertsQueue] = useState([])

    const history = useHistory()

    useEffect(() => {
        return history.listen(() => {
            setAlertsQueue([])
            setActiveAlert(null)
        })
    }, [history])

    useEffect(() => {
        if (alertsQueue.length > 0) {
            setActiveAlert(alertsQueue[0])
        } else {
            setActiveAlert(null)
        }
    }, [alertsQueue])

    const dequeueAlert = () => {
        setActiveAlert(null)
        const newAlerts = [...alertsQueue]
        newAlerts.shift()
        setAlertsQueue(newAlerts)
    }

    const enqueueAlert = (alert) => {
        if (typeof alert === "string") {
            setAlertsQueue((prevAlertsQueue) => {
                prevAlertsQueue.push({
                    content: alert,
                })
                return [...prevAlertsQueue]
            })
            return
        }

        setAlertsQueue((prevAlertsQueue) => {
            prevAlertsQueue.push(alert)
            return [...prevAlertsQueue]
        })
    }

    const enqueueMsgOrDefault = (msg, def) => {
        if (msg && typeof msg === "string" && msg.length > 0) {
            enqueueAlert(msg)
            return
        }
        enqueueAlert(def)
    }

    const enqueueError = (msg) => {
        enqueueMsgOrDefault(msg, GENERIC_ERROR_MESSAGE)
    }

    const enqueuePermissionError = (msg) => {
        enqueueMsgOrDefault(msg, PERMISSIONS_ERROR_MESSAGE)
    }

    const enqueueNetworkError = (msg) => {
        enqueueMsgOrDefault(msg, NETWORK_ERROR_MESSAGE)
    }

    const enqueueDevAlert = (msg, log) => {
        if (process.env.REACT_APP_STAGE !== "production") {
            enqueueAlert(msg)
            console.log(msg, log)
        }
    }

    return (
        <ToastContext.Provider
            value={{ enqueueAlert, enqueueError, enqueuePermissionError, enqueueNetworkError, enqueueDevAlert }}
        >
            {children}
            {activeAlert !== null && (
                <Toast
                    content={activeAlert.content}
                    onCloseAlert={() => dequeueAlert()}
                    duration={activeAlert?.duration}
                    remaining={alertsQueue.length}
                />
            )}
        </ToastContext.Provider>
    )
}

export default ToastWrapper

import { useEffect, useState, useContext } from "react"
// Modules

// Components
import AlertInput from "./AlertInput"
import AlertDateInput from "./AlertDateInput"
import AlertActiveToggle from "./AlertActiveToggle"
import { Button } from "../../../../components/Button"

// Icons
import EditIcon from "../../../../ui/Icons/EditIcon"
import TrashIcon from "../../../../ui/Icons/TrashIcon"
import ChevronArrow from "../../../../ui/Icons/newIcons/ChevronArrow"

// Views

// Context providers / Utils
import AlertsContext from "./AlertsContext"
import {
    getUnitsNotation,
    getCorrectUnitValue,
    cleanAlertData,
    cleanStackedAlertData,
    isAlertDataValid,
    cleanValidations,
} from "../../../../Util/Alerts"
import validations from "./ValidateAlertInput"
import { RISKS } from "./RisksList"
import { ToastContext } from "../../../../components/Toast/ToastContextProvider"
import { ModalContext } from "../../../../components/Modal/ModalContextProvider"

// Hooks

// Material-UI *

// Styles

const Alert = (props) => {
    const { riskData, alertData, onDelete, idAlert, path, units, currentUser, featurePermissions, id } = props

    const { onUpdateAlert, onUpdateStackedAlert, onDeleteAlert, toggleLoading } = useContext(AlertsContext)
    const { enqueueAlert, enqueueError } = useContext(ToastContext)
    const { confirmationModal } = useContext(ModalContext)

    const cleanState = !alertData.stacked
        ? {
              probability: (alertData.probability * 100).toFixed(1),
              errors: {},
              flags: {},
          }
        : {
              active: true,
              risks: [],
              errors: { risks: [] },
              flags: { risks: [] },
          }

    const [isDisabled, setIsDisabled] = useState(true)
    const [dataBeforeEdit, setDataBeforeEdit] = useState({})
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [state, setState] = useState(cleanState)

    const handleOpenAlertToggle = () => {
        setIsAlertOpen(!isAlertOpen)
    }

    function handleInputChange(propName, indexOfRisk) {
        if (riskData.stacked && indexOfRisk >= 0) {
            return (value, error, flag) => {
                let updatedRisks = [...state.risks]
                updatedRisks[indexOfRisk][propName] = value
                let updatedErrors = { ...state.errors }
                let updatedFlags = { ...state.flags }

                if ((propName !== "title", "recurrent_days")) {
                    updatedErrors.risks[indexOfRisk][propName] = error
                    updatedFlags.risks[indexOfRisk][propName] = flag
                } else {
                    updatedErrors[propName] = error
                    updatedFlags[propName] = flag
                }

                setState({
                    ...state,
                    risks: updatedRisks,
                    errors: updatedErrors,
                    flags: updatedFlags,
                })
            }
        }

        return (value, error, flag) => {
            setState({
                ...state,
                [propName]: value,
                errors: { ...state.errors, [propName]: error },
                flags: { ...state.flags, [propName]: flag },
            })
        }
    }

    function onEdit() {
        if (riskData.stacked) {
            setDataBeforeEdit({ ...state, risks: JSON.parse(JSON.stringify(state.risks)) })
        } else {
            setDataBeforeEdit({ ...state })
        }
        setIsDisabled(false)
    }

    function onCancelEdit() {
        setState({ ...dataBeforeEdit })
        setIsDisabled(true)
    }

    function handleAlertTitleBlur(e) {
        let onChange = handleInputChange("title")
        const error = validations.title()(e.target.value)
        onChange(e.target.value, error, true)
    }

    function handleAlertTitleChange(e) {
        let onChange = handleInputChange("title")
        const error = validations.title()(e.target.value)
        onChange(e.target.value, error, false)
    }

    function checkStackedAlertData() {
        const errors = { risks: generateEmptyArray(alertData.risks.length) }
        const flags = { risks: generateEmptyArray(alertData.risks.length) }
        let dataHasErrors = false

        let vals = Object.assign({}, validations)

        let general_vals = ["title", "recurrent_days"]
        general_vals.forEach((val) => {
            let validationFunction = vals[val]()
            let err = validationFunction(state[val])
            errors[val] = err
            if (err !== null) {
                flags[val] = true
                dataHasErrors = true
            }
            delete vals[val]
        })

        state.risks.forEach((risk, index) => {
            let individualRiskData = RISKS[risk.risk]
            let data = risk
            let riskVals = cleanValidations(individualRiskData.isGDD, Object.assign({}, vals))

            const {
                errors: riskErrors,
                flags: riskFlags,
                dataHasErrors: riskDataHasErrors,
            } = isAlertDataValid(riskVals, data, individualRiskData.units, units)
            dataHasErrors = dataHasErrors || riskDataHasErrors
            errors.risks[index] = riskErrors
            flags.risks[index] = riskFlags
        })

        setState({ ...state, errors, flags })
        if (dataHasErrors) {
            console.log("Data has errors = ", errors)
            return false
        }
        updateAlert()
        setIsDisabled(true)
        return true
    }

    function checkAlertData(isGDD) {
        let vals = cleanValidations(isGDD, Object.assign({}, validations))

        const { errors, flags, dataHasErrors } = isAlertDataValid(vals, state, riskData.units, units)

        setState({ ...state, errors, flags })
        if (dataHasErrors) {
            console.log("Data has errors = ", errors)
            return false
        }
        updateAlert()
        setIsDisabled(true)
        return true
    }

    function updateAlert() {
        toggleLoading(true)
        let alertData
        let updateAlertFunction
        if (riskData.stacked) {
            alertData = cleanStackedAlertData(
                {
                    ...state,
                    risks: JSON.parse(JSON.stringify(state.risks)),
                    errors: { ...state.errors, risks: JSON.parse(JSON.stringify(state.errors)) },
                    flags: { ...state.flags, risks: JSON.parse(JSON.stringify(state.flags)) },
                },
                RISKS,
                units
            )
            updateAlertFunction = onUpdateStackedAlert
        } else {
            alertData = cleanAlertData(state, riskData.units, units, riskData.isGDD)
            updateAlertFunction = onUpdateAlert
        }
        updateAlertFunction(alertData, path)
            .then(() => {
                toggleLoading(false)
                enqueueAlert(`${state?.title} has been updated`)
            })
            .catch((err) => {
                console.log("Error updating user alert ", err)
                toggleLoading(false)
                enqueueError(`Error saving ${state?.title}, please try again later`)
            })
    }

    function deleteAlert() {
        toggleLoading(true)
        onDeleteAlert(idAlert, path)
            .then(() => {
                onDelete(idAlert)
            })
            .catch((err) => {
                enqueueError(`Error: Error deleting user alert. ${err}`)
                console.log("Error deleting user alert ", err)
            })
    }

    function generateEmptyArray(length) {
        return Array.apply(null, Array(length)).map(() => {
            return {}
        })
    }

    useEffect(() => {
        if (riskData.stacked) {
            alertData.risks.forEach((risk, index) => {
                alertData.risks[index].probability = (alertData.risks[index].probability * 100).toFixed(1)
            })

            let newState = {
                ...alertData,
                errors: { risks: generateEmptyArray(alertData.risks.length) },
                flags: { risks: generateEmptyArray(alertData.risks.length) },
            }
            setState(newState)
        } else {
            let newState = { ...alertData }
            if (riskData.isGDD) {
                newState.gddSum = getCorrectUnitValue(riskData.units, "metric", units, alertData.threshold)
                newState.gddBase = getCorrectUnitValue(riskData.units, "metric", units, alertData.gddBase)
            } else {
                newState.threshold = getCorrectUnitValue(riskData.units, "metric", units, alertData.threshold)
            }
            setState({
                ...newState,
                probability: (alertData.probability * 100).toFixed(1),
                errors: {},
                flags: {},
            })
        }
    }, [units])

    return (
        <div id={id} className="pl-10">
            <div className="flex flex-row items-stretch justify-between space-x-8 pt-5 pb-3">
                <div className="grow">
                    <label className="block text-[14px] text-gray-60">Alert title</label>
                    <input
                        type="text"
                        value={state?.title}
                        placeholder="The alert title"
                        className={[
                            "border-[1px] border-gray-10 text-gray-90 placeholder:text-gray-30 text-[16px] px-3 py-2 rounded-lg w-full mt-1",
                            isDisabled ? "border-transparent" : "",
                        ].join(" ")}
                        onChange={handleAlertTitleChange}
                        onBlur={handleAlertTitleBlur}
                        disabled={isDisabled}
                    />
                    {state.flags.title && <div className="text-[12px] text-red-500">{state.errors.title}</div>}
                </div>
                <div className="grow-0">
                    <label className="block text-[14px] text-gray-60 mb-2">{"Active"}</label>
                    <AlertActiveToggle
                        isActive={alertData?.active}
                        path={path}
                        currentUser={currentUser}
                        featurePermissions={featurePermissions}
                    />
                </div>
                <div
                    onClick={handleOpenAlertToggle}
                    className={[
                        "transform h-6 w-6 fill-gray-60 cursor-pointer self-center",
                        isAlertOpen ? "rotate-180" : "",
                    ].join(" ")}
                >
                    <ChevronArrow />
                </div>
            </div>

            {isAlertOpen && (
                <div>
                    <div className="alert__content__conditions">
                        {riskData.stacked ? (
                            state.risks.map((risk, index) => {
                                let individualRiskData = RISKS[risk.risk]
                                return (
                                    <div key={risk + index}>
                                        <div className="alert__content__conditions__options-s-margin">
                                            <div>
                                                <strong>If: &nbsp;</strong> Predicted {individualRiskData.modifier}{" "}
                                                {individualRiskData.variable} is {individualRiskData.conditional}
                                            </div>
                                            {!individualRiskData.isGDD && (
                                                <AlertInput
                                                    placeholder={30}
                                                    value={state.threshold === null ? "" : state.risks[index].threshold}
                                                    onChange={handleInputChange("threshold", index)}
                                                    match={validations.threshold(individualRiskData.units, units)}
                                                    error={state.errors.risks[index]?.threshold}
                                                    flag={state.flags.risks[index]?.threshold}
                                                    disabled={isDisabled}
                                                    min={-30}
                                                    max={2000}
                                                    elementId={`${id}__if-input__risk-profiles`}
                                                />
                                            )}
                                            {individualRiskData.isGDD && (
                                                <AlertInput
                                                    placeholder={300}
                                                    value={state.gddSum === null ? "" : state.risks[index].gddSum}
                                                    onChange={handleInputChange("gddSum", index)}
                                                    match={validations.gddSum(units)}
                                                    error={state.errors.risks[index]?.gddSum}
                                                    flag={state.flags.risks[index]?.gddSum}
                                                    disabled={isDisabled}
                                                    min={100}
                                                    max={10000}
                                                    elementId={`${id}__if-input__risk-profiles`}
                                                />
                                            )}
                                            <div>{getUnitsNotation(individualRiskData.units, units)}</div>
                                        </div>

                                        <div className="alert__content__conditions__options-m-margin">
                                            <strong>With: &nbsp;</strong> Probability of at least
                                            <AlertInput
                                                placeholder={80}
                                                value={state.risks[index].probability}
                                                onChange={handleInputChange("probability", index)}
                                                disabled={isDisabled}
                                                match={validations.probability()}
                                                error={state.errors.risks[index]?.probability}
                                                flag={state.flags.risks[index]?.probability}
                                                min={1}
                                                max={100}
                                                elementId={`${id}__with-input__risk-profiles`}
                                            />
                                            %
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <>
                                <div className="alert__content__conditions__options-s-margin">
                                    <div>
                                        <strong>If: &nbsp;</strong> Predicted {riskData.modifier} {riskData.variable} is{" "}
                                        {riskData.conditional}
                                    </div>
                                    {!riskData.isGDD && (
                                        <AlertInput
                                            placeholder={30}
                                            value={state.threshold === null ? "" : state.threshold}
                                            onChange={handleInputChange("threshold")}
                                            match={validations.threshold(riskData.units, units)}
                                            error={state.errors.threshold}
                                            flag={state.flags.threshold}
                                            disabled={isDisabled}
                                            min={-30}
                                            max={2000}
                                            elementId={`${id}__if-input__risk-profiles`}
                                        />
                                    )}
                                    {riskData.isGDD && (
                                        <AlertInput
                                            placeholder={300}
                                            value={state.gddSum === null ? "" : state.gddSum}
                                            onChange={handleInputChange("gddSum")}
                                            match={validations.gddSum(units)}
                                            error={state.errors.gddSum}
                                            flag={state.flags.gddSum}
                                            disabled={isDisabled}
                                            min={100}
                                            max={10000}
                                            elementId={`${id}__if-input__risk-profiles`}
                                        />
                                    )}
                                    <div>{getUnitsNotation(riskData.units, units)}</div>
                                </div>

                                <div className="alert__content__conditions__options-m-margin">
                                    <strong>With: &nbsp;</strong> Probability of at least
                                    <AlertInput
                                        placeholder={80}
                                        value={state.probability}
                                        onChange={handleInputChange("probability")}
                                        disabled={isDisabled}
                                        match={validations.probability()}
                                        error={state.errors.probability}
                                        flag={state.flags.probability}
                                        min={1}
                                        max={100}
                                        elementId={`${id}__with-conditional-input__risk-profiles`}
                                    />
                                    %
                                </div>

                                {riskData.isGDD && (
                                    <>
                                        <div className="alert__content__conditions__options-m-margin">
                                            <strong>And: &nbsp;</strong> {riskData.variable} Base is:
                                            <AlertInput
                                                placeholder={30}
                                                value={state.gddBase === null ? "" : state.gddBase}
                                                onChange={handleInputChange("gddBase")}
                                                match={validations.gddBase(riskData.units, units)}
                                                error={state.errors.gddBase}
                                                flag={state.flags.gddBase}
                                                disabled={isDisabled}
                                                min={-30}
                                                max={2000}
                                                elementId={`${id}__and-conditional-input__risk-profiles`}
                                            />
                                            <div>{getUnitsNotation(riskData.units, units)}</div>
                                        </div>
                                        <div className="alert__content__conditions__options-m-margin">
                                            <strong>And: &nbsp;</strong> Total Plant Life Cycle:
                                            <AlertInput
                                                placeholder={90}
                                                value={state.gddLifeCycle === null ? "" : state.gddLifeCycle}
                                                onChange={handleInputChange("gddLifeCycle")}
                                                match={validations.gddLifeCycle()}
                                                error={state.errors.gddLifeCycle}
                                                flag={state.flags.gddLifeCycle}
                                                disabled={isDisabled}
                                                min={0}
                                                max={360}
                                                elementId={`${id}__and-conditional-input__risk-profiles`}
                                            />
                                            <div>days </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {!riskData.isGDD && (
                            <div className="alert__content__conditions__options-s-margin">
                                <strong>And: &nbsp;</strong> {riskData.aggregated ? "Aggregated" : "Recurrent"} for
                                <AlertInput
                                    placeholder={3}
                                    value={state.recurrent_days === null ? "" : state.recurrent_days}
                                    onChange={handleInputChange("recurrent_days")}
                                    match={validations.recurrent_days()}
                                    error={state.errors.recurrent_days}
                                    flag={state.flags.recurrent_days}
                                    disabled={isDisabled}
                                    min={1}
                                    max={180}
                                    elementId={`${id}__and-conditional-input__risk-profiles`}
                                />{" "}
                                consecutive days
                            </div>
                        )}

                        <div className="alert__content__conditions__options-s-margin">
                            <strong>During: &nbsp;</strong>
                            <AlertDateInput
                                value={state?.start_date}
                                disabled={isDisabled}
                                onChange={handleInputChange("start_date")}
                                elementId="start-date"
                            />
                            to
                            <AlertDateInput
                                value={state?.end_date}
                                disabled={isDisabled}
                                onChange={handleInputChange("end_date")}
                                elementId="end-date"
                            />
                        </div>
                    </div>
                    {isDisabled && (
                        <div className="alert__content__edit-button-container">
                            {featurePermissions.alerts.edit && (
                                <div
                                    id={`${id}__edit-icon__risk-profiles`}
                                    className="alert__content__edit-icon"
                                    onClick={onEdit}
                                >
                                    <EditIcon />
                                </div>
                            )}

                            {featurePermissions.alerts.delete && (
                                <div
                                    id={`${id}__trash-icon__risk-profiles`}
                                    className="alert__content__trash-icon"
                                    onClick={() => {
                                        confirmationModal({
                                            title: `Delete alert "${state.title}".`,
                                            text: "This action cannot be undone.",
                                            onContinueLabel: "Delete",
                                            onContinue: () => {
                                                deleteAlert()
                                            },
                                        })
                                    }}
                                >
                                    <TrashIcon />
                                </div>
                            )}
                        </div>
                    )}
                    {!isDisabled && (
                        <div className="flex flex-row items-center justify-end space-x-2">
                            <Button type="secondary" onClick={onCancelEdit} label="Cancel" />
                            <Button
                                onClick={() => {
                                    !riskData.stacked ? checkAlertData(riskData.isGDD) : checkStackedAlertData()
                                }}
                                label="Save"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Alert

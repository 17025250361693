import { useMemo, useContext } from "react"
import { SettingsContext } from "../Util/SettingsContext"

export default function useDepartureData(data, pastq, futureq, convertToUnits = (fromUnit, toUnit, val) => val) {
    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const convert = (val) => +convertToUnits("metric", units, val)

    return useMemo(() => {
        const { threshold = {}, x, y } = data

        let chartData = {
            type: "line",
            color: "teal",
            "stroke-width": 2,
            points: [],
        }

        x &&
            x.forEach((d, i) => {
                if (d && y[i]) {
                    chartData.points.push({
                        x: new Date(`Jan 1, ${d}`).getTime(),
                        y: convert(y[i]),
                    })
                }
            })

        return { chartData, threshold: threshold[`[${pastq},${futureq}]`] || null }
    }, [data, pastq, futureq])
}

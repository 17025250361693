// Modules
import PropTypes from "prop-types"

// Styles
import "../../styles/chart.css"
import "./ClimateLayout.css"

import ClimateWrapper from "./ClimateWrapper"
import ClimateProvider from "./ClimateProvider"
import { Sidebar } from "../../ui/Sidebar"
import GenericPageHeader from "../GenericPageHeader"
import PoweredBy from "../../components/PoweredBy"

function ClimateLayout(props) {
    const { children, title, settings, path } = props
    const { skin } = window.dashboard

    return (
        <ClimateProvider>
            {/* VIEW CONTAINER  */}
            <div className="flex flex-row justify-center w-full h-full grow text-gray-90 roboto">
                {/* SIDENAV  */}
                {path !== "/climate/overview" && <Sidebar settings={settings} climate />}

                {/* REPORT */}
                <div
                    className={
                        "z-30 flex flex-col h-full overflow-x-hidden overflow-y-visible bg-gray-5 grow max-w-8xl " +
                        (path !== "/climate/overview" ? "p-[14px]" : "")
                    }
                >
                    <ClimateWrapper>
                        {title && title !== "" && <GenericPageHeader pageTitle={title} />}
                        {children}
                    </ClimateWrapper>
                    {skin && (
                        <div className="flex flex-col items-center justify-end grow">
                            <PoweredBy />
                        </div>
                    )}
                </div>
            </div>
        </ClimateProvider>
    )
}

ClimateLayout.propTypes = {
    children: PropTypes.node,
}

export default ClimateLayout

import React, { useState, useEffect } from "react"

import "./AlertSelect.css"

import ArrowRight from "../../../../ui/Icons/ArrowRight"
import useOutsideComponentClickHandler from "../../../../hooks/ClickOutsideHook"

const AlertSelect = (props) => {
    const { options, selectedIndex = 1, maxNumber = Infinity, disabled, onChange = () => null, elementId } = props

    const [selected, setSelected] = useState()
    const [open, toggle] = useState(false)

    const wrapperRef = useOutsideComponentClickHandler(() => toggle(false))

    const selectOption = (index) => {
        setSelected(index)
        onChange(index + 1)
        toggle(false)
    }

    const openDropdown = () => {
        if (!disabled) {
            toggle(!open)
        }
    }

    useEffect(() => {
        if (selectedIndex > maxNumber) {
            let index = maxNumber - 1
            setSelected(index)
            selectOption(index)
        }
    }, [maxNumber])

    useEffect(() => {
        setSelected(selectedIndex - 1)
    }, [selectedIndex])

    useEffect(() => {
        toggle(false)
    }, [disabled])

    return (
        <div id={elementId} className="alert-select" ref={wrapperRef}>
            <div
                className={
                    !disabled ? "alert-select__control-container" : "alert-select__control-container select-disabled"
                }
                onClick={openDropdown}
            >
                <div className="alert-select__control-selected">{options[selected]}</div>
                <ArrowRight
                    className={open ? "alert-select__control-arrow rotate" : "alert-select__control-arrow"}
                    fill={disabled ? "#8a9198" : null}
                />
            </div>
            <div className={open && !disabled ? "alert-select__options-dropdown" : "hidden"}>
                {options.map((option, i) => (
                    <div
                        key={i}
                        onClick={() => {
                            selectOption(i)
                        }}
                        className="alert-select__option"
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AlertSelect

import { useMemo, useContext } from "react"
import { SettingsContext } from "../Util/SettingsContext"

export default function useSeasonalData(data, selected, chartColors, convertToUnits = (fromUnit, toUnit, val) => val) {
    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const convert = (val) => +convertToUnits("metric", units, val)

    return useMemo(() => {
        let chartData = {}
        let maxValue = Number.MIN_SAFE_INTEGER
        let minValue = Number.MAX_SAFE_INTEGER

        let selectedKeys = Object.keys(selected)

        if (data && Object.keys(data).length > 0) {
            const availableColors = selectedKeys.reduce((prev, key, index) => {
                selected[key] && prev.push(chartColors[index])
                return prev
            }, [])

            chartData = {
                type: "candlestick",
                // TODO modify with available colors
                color: (pointIndex, groupIndex, candleIndex) => {
                    return availableColors[groupIndex]
                },
                points: [],
            }

            const months = {}

            Object.keys(data)
                .filter((key) => selected[key])
                .forEach((key, index) => {
                    // month
                    Object.keys(data[key]).forEach((month, index) => {
                        if (!months[month]) months[month] = []
                        const values = data[key][month]

                        const item95 = convert(values["0.95"])
                        const item05 = convert(values["0.05"])

                        if (maxValue < item95) maxValue = item95
                        if (minValue > item05) minValue = item05

                        months[month].push({
                            x: new Date(`Jan 1, ${key}`),
                            y0: item05,
                            y1: item95,
                            yMax: convert(values["0.75"]),
                            yMid: convert(values["0.5"]),
                            yMin: convert(values["0.25"]),
                        })
                    })
                })

            Object.keys(months).forEach((key) => {
                chartData.points.push({
                    x: new Date(`${key} 16 2022`),
                    values: months[key],
                })
            })
        }

        return { chartData, maxValue, minValue }
    }, [data, selected])
}

import React, { useState, useRef, useEffect } from "react"
import mapboxgl from "!mapbox-gl" // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css"
import { token } from "../../components/Charts/MapboxMaps/accessToken"
import "./LongTermMap.css"

import { useHistory } from "react-router-dom"

mapboxgl.accessToken = token

function LongTermMap({ reports }) {
    const [lng, setLng] = useState(78.0081)
    const [lat, setLat] = useState(27.1767)
    const [zoom, setZoom] = useState(5)
    const [markers, setMarkers] = useState([])
    const mapContainer = useRef(null)
    const map = useRef(null)

    const history = useHistory()

    function addMarker() {
        const markerArray = []

        reports &&
            reports.map((report) => {
                console.log(report)
                var marker = new mapboxgl.Marker()
                marker.className = "marker"
                marker.getElement().addEventListener("click", () => {
                    history.push(`/climate/${report.reportId}/overview`)
                })
                marker.setLngLat(report.coordinates).addTo(map.current)
            })

        setMarkers(markerArray)
    }

    function removeMarkers() {
        markers.forEach((marker) => marker.remove())
    }

    useEffect(() => {
        if (map.current) return
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/satellite-v9?optimize=true",
            center: [lng, lat],
            zoom,
            optimizeForTerrain: true,
            minZoom: 1,
        })
        map.current.addControl(new mapboxgl.NavigationControl())
    }, [])

    useEffect(() => {
        removeMarkers()
        addMarker()
    }, [reports])

    return (
        <>
            <div ref={mapContainer} className="map-container" />
        </>
    )
}

export default LongTermMap

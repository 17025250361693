import * as React from "react"

function ArrowLeft(props) {
    return (
        <svg width={5} height={9} viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5 0L0 4.5 5 9V0z" fill="#4E4E4E" />
        </svg>
    )
}

export default ArrowLeft

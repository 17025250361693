const PrecipitationRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M17.39 8.43C16.88 5.86 14.62 4 12 4C9.94 4 8.08 5.14 7.13 6.97L6.63 7.92L5.56 8.03C3.53 8.24 2 9.95 2 12C2 13.6177 2.95904 15.0103 4.33985 15.6406L3.84948 17.6021C1.59955 16.7361 0 14.5524 0 12C0 8.91 2.34 6.36 5.35 6.04C6.6 3.64 9.11 2 12 2C15.64 2 18.67 4.59 19.35 8.04C21.95 8.22 24 10.36 24 13C24 15.76 21.76 18 19 18H17.75L18.25 16H19C20.65 16 22 14.65 22 13C22 11.45 20.78 10.14 19.22 10.04L17.69 9.93L17.39 8.43Z"
                fill={props.fill}
            />
            <path d="M15 21H13L15 13H17L15 21Z" fill={props.fill} />
            <path d="M13 13H11L9 21H11L13 13Z" fill={props.fill} />
            <path d="M7 21H5L7 13H9L7 21Z" fill={props.fill} />
        </svg>
    )
}

export default PrecipitationRiskIcon

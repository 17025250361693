import * as React from "react"

function CropIcon(props) {
    return (
        <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15 13a5 5 0 0 0 5-5h-1a5 5 0 0 0-5 5h1ZM21 18H3v2h18v-2ZM9 8a5 5 0 0 1-5-5h1a5 5 0 0 1 5 5H9Z"
                fill="#666D74"
            />
            <path fill="#666D74" d="M11 3.001h2v15h-2z" />
        </svg>
    )
}

export default CropIcon

export const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

export const titleCase = (str) => {
    str = str.toString()
    if (!str) {
        return ""
    }
    var splitStr = str.toLowerCase().split(/[\s_]+/)
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
}

// Modules
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"

// Context providers / Utils
import { SettingsProvider } from "./Util/SettingsContext"
import { AuthProvider } from "./Auth/Auth" // TODO move Auth to Util collection
import DataProvider from "./Util/Data"
import SkinProvider from "./Util/SkinProvider"

// styles
import "./App.css"
import "./styles/chart.css"

import SignIn from "./components/SignIn"
import SignUpConfirmation from "./components/SignUpConfirmation"
import RestorePasswordEmail from "./components/RestorePasswordEmail"
import SignUp from "./components/SignUp"
import SeasonalRouter from "./routers/SeasonalRouter"
import ClimateRouter from "./routers/ClimateRouter"
import CustomRouter from "./routers/CustomRouter"
import ToastWrapper from "./components/Toast/ToastContextProvider"
import ModalWrapper from "./components/Modal/ModalContextProvider"
import RestorePasswordNewPassword from "./components/RestorePasswordNewPassword"
import RestorePasswordConfirmation from "./components/RestorePasswordConfirmation"

function App() {
    return (
        <SkinProvider>
            <Router>
                <AuthProvider>
                    <DataProvider>
                        <SettingsProvider>
                            <ToastWrapper>
                                <ModalWrapper>
                                    <Switch>
                                        <Route exact path="/">
                                            <SignIn />
                                        </Route>
                                        <Route exact path="/signup">
                                            <SignUp />
                                        </Route>
                                        <Route exact path="/signup/confirmation">
                                            <SignUpConfirmation />
                                        </Route>
                                        <Route exact path="/restore-password">
                                            <RestorePasswordEmail />
                                        </Route>
                                        <Route exact path="/restore-password/code">
                                            <RestorePasswordNewPassword />
                                        </Route>
                                        <Route exact path="/restore-password/confirmation">
                                            <RestorePasswordConfirmation />
                                        </Route>
                                        {/* COD */}
                                        <Route path="/weather">
                                            <SeasonalRouter />
                                        </Route>
                                        {/* CAD */}
                                        <Route path="/climate">
                                            <ClimateRouter />
                                        </Route>
                                        {/* Custom */}
                                        <Route path="/custom">
                                            <CustomRouter reportTab="custom" />
                                        </Route>
                                        {/* Exposure */}
                                        <Route path="/exposure">
                                            <CustomRouter reportTab="regulatory" />
                                        </Route>

                                        <Redirect to="/" />
                                    </Switch>
                                </ModalWrapper>
                            </ToastWrapper>
                        </SettingsProvider>
                    </DataProvider>
                </AuthProvider>
            </Router>
        </SkinProvider>
    )
}

export default App

import { useEffect, useRef, useState } from "react"
import DebounceSearchInput from "../../components/DebounceSearchInput/DebounceSearchInput"
import useField from "../../hooks/useField"
import AltArrowLeft from "../Icons/newIcons/AltArrowLeft"
import { useHistory } from "react-router-dom"

const RegionItem = ({ region, children, currentRegion }) => {
    return (
        <div className="roboto pr-[14px]">
            <div className="text-[14px] font-normal text-gray-60 sticky top-0 bg-white z-10 pb-[4px]">
                {region}
                {region === currentRegion ? " (Current region)" : ""}
            </div>
            <div className="border-b-[1px] border-gray-10 pt-[4px] pb-[10px] flex flex-col z-0">{children}</div>
        </div>
    )
}

const FieldItem = ({ isActive = false, name, crop, variety, alertsCount = 0, onClick, showAlertsCount = true }) => {
    return (
        <button
            className={[
                "roboto flex flex-row justify-between items-center",
                "py-[6px] px-2 rounded-sm",
                "transition-all duration-200",
                "hover:bg-gray-5/70 hover:translate-x-[2px]",
                "focus:bg-gray-5/70 focus:translate-x-[2px] focus:outline-accent",
            ].join(" ")}
            onClick={() => {
                if (!isActive) onClick()
            }}
        >
            <div className="flex flex-col space-y-[3px] text-left">
                <div className={"text-[16px] " + (isActive ? "font-bold text-accent" : "font-normal text-gray-90")}>
                    {name}
                </div>
                <div className={"text-[14px] font-normal " + (isActive ? "text-accent" : "text-gray-30")}>
                    {crop} ({variety})
                </div>
            </div>
            {alertsCount > 0 && (
                <div className="rounded-full bg-red h-[16px] w-[16px] text-white text-[11px] flex flex-row justify-center items-center roboto font-semibold">
                    {showAlertsCount ? alertsCount : ""}
                </div>
            )}
        </button>
    )
}

const SidebarFieldList = ({ fields, closeFieldList, view }) => {
    const [regions, setRegions] = useState({})
    const [searchInput, setSearchInput] = useState("")
    const listRef = useRef(null)
    const history = useHistory()

    useEffect(() => {
        const newRegions = {}
        fields
            .filter((field) => {
                if (searchInput === "") return true

                const toSearch = searchInput.toLowerCase()
                const name = field.name?.toLowerCase() || ""
                const regi = field.region?.toLowerCase() || ""
                const crop = field.crop?.toLowerCase() || ""
                const vari = field.variety?.toLowerCase() || ""
                const aler = field.alert_types_count?.toString().toLowerCase() || ""
                return (
                    name.includes(toSearch) ||
                    regi.includes(toSearch) ||
                    crop.includes(toSearch) ||
                    vari.includes(toSearch) ||
                    aler.includes(toSearch)
                )
            })
            .forEach((field) => {
                const reg = field.region?.trim() || null
                if (reg) {
                    if (newRegions[reg]) newRegions[reg].push(field)
                    else newRegions[reg] = [field]
                } else {
                    // TODO: Is this a data error?
                    // newRegions["No Region"] = [field]
                }
            })
        setRegions(newRegions)
    }, [fields, searchInput])

    const currentField = useField()

    const handleItemClick = (fieldId) => {
        if (view === "/seasonal") history.push("/weather/" + fieldId)
        else history.push("/weather/" + fieldId + view)
    }

    return (
        <div className="pl-[14px] pt-[24px] pb-[14px] flex flex-col space-y-[20px] min-h-[0px] h-full">
            <div className="flex flex-row items-center space-x-[10px]">
                <button
                    className={[
                        "h-[24px] w-[24px]",
                        "fill-gray-60",
                        "transition-all duration-200",
                        "hover:opacity-70",
                    ].join(" ")}
                    onClick={closeFieldList}
                >
                    <AltArrowLeft />
                </button>
                <div className="montserrat text-[16px] font-semibold">My locations</div>
            </div>
            <div className="pr-[14px]">
                <DebounceSearchInput
                    onSearch={(val) => {
                        setSearchInput(val)
                    }}
                    ms={300}
                />
            </div>
            {currentField && (
                <div className="flex-1 flex flex-col space-y-[12px] overflow-y-auto" ref={listRef}>
                    {searchInput === "" && (
                        <RegionItem region={currentField.region} currentRegion={currentField.region.trim()}>
                            <FieldItem
                                name={currentField.name}
                                crop={currentField.crop}
                                variety={currentField.variety}
                                isActive={true}
                                alertsCount={currentField.alert_types_count}
                            />
                            {regions[currentField.region.trim()] &&
                                regions[currentField.region.trim()]
                                    .filter((field) => field.uuid !== currentField.uuid)
                                    .sort()
                                    .map((field) => (
                                        <FieldItem
                                            key={field.uuid}
                                            name={field.name}
                                            crop={field.crop}
                                            variety={field.variety}
                                            isActive={false}
                                            alertsCount={field.alert_types_count}
                                            onClick={() => handleItemClick(field.uuid)}
                                        />
                                    ))}
                        </RegionItem>
                    )}
                    {Object.keys(regions).length > 0 ? (
                        Object.keys(regions)
                            .filter((regKey) => (searchInput === "" ? regKey !== currentField.region.trim() : true))
                            .sort()
                            .map((regKey) => (
                                <RegionItem region={regKey} key={regKey} currentRegion={currentField.region.trim()}>
                                    {regions[regKey].sort().map((field) => {
                                        return (
                                            <FieldItem
                                                key={field.uuid}
                                                name={field.name}
                                                crop={field.crop}
                                                variety={field.variety}
                                                isActive={field.uuid === currentField.uuid}
                                                alertsCount={field.alert_types_count}
                                                onClick={() => handleItemClick(field.uuid)}
                                            />
                                        )
                                    })}
                                </RegionItem>
                            ))
                    ) : (
                        <div className="text-[14px] font-normal text-gray-60 text-center py-2 mr-[14px] bg-gray-5 rounded-sm">
                            {searchInput === "" ? "No fields" : "All locations filtered"}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default SidebarFieldList

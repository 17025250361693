import { createContext, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Modal from "./Modal"

export const ModalContext = createContext({})

function ModalWrapper({ children }) {
    const [activeModal, setActiveModal] = useState(null)
    const [modalsQueue, setModalsQueue] = useState([])

    const history = useHistory()

    useEffect(() => {
        return history.listen(() => {
            setModalsQueue([])
            setActiveModal(null)
        })
    }, [history])

    useEffect(() => {
        if (modalsQueue.length > 0) {
            setActiveModal(modalsQueue[0])
        } else {
            setActiveModal(null)
        }
    }, [modalsQueue])

    const dequeueModal = () => {
        setActiveModal(null)
        const newModals = [...modalsQueue]
        newModals.shift()
        setModalsQueue(newModals)
    }

    const enqueueModal = (modal) => {
        setModalsQueue((prevModalsQueue) => {
            prevModalsQueue.push(modal)
            return [...prevModalsQueue]
        })
    }

    const confirmationModal = ({ title, text, onCancel, onContinue, onContinueLabel }) => {
        enqueueModal({
            title,
            text,
            onCancel,
            onContinue,
            onContinueLabel,
            type: "confirmation",
            closeModal: dequeueModal,
            doGrayBG: true,
        })
    }

    const aboutModal = (tab) => {
        if (!tab) return
        enqueueModal({
            type: "about-" + tab,
            onContinueLabel: "OK",
            closeModal: dequeueModal,
            doGrayBG: true,
        })
    }

    const infoModal = ({ title, text, onContinueLabel }) => {
        enqueueModal({
            title,
            text,
            onContinueLabel,
            type: "info",
            closeModal: dequeueModal,
            doGrayBG: true,
        })
    }

    return (
        <ModalContext.Provider value={{ confirmationModal, aboutModal, infoModal }}>
            {children}
            {activeModal !== null && <Modal {...activeModal} />}
        </ModalContext.Provider>
    )
}

export default ModalWrapper

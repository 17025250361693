import "./SidebarOptions.css"

function SidebarOptions(props) {
    const { linkComponent: LinkComponent, items = [], fieldId = "", sidebarOpen, view } = props

    if (items.length === 0) return null

    return (
        <div>
            {items.map((item, index) => {
                const { view: _view, to, name, disabled } = item
                return (
                    <LinkComponent
                        key={"navbar-option-" + index}
                        to={to}
                        id={`${String(name).toLocaleLowerCase()}__nav-option__navbar`}
                    >
                        <div
                            className={
                                "transition-all duration-200 flex flex-row items-center pr-[14px] pl-[56px] w-full hover:bg-gray-5" +
                                (sidebarOpen ? "" : " pl-[68px]") +
                                (disabled ? " pointer-events-none opacity-40" : "")
                            }
                        >
                            <div
                                className={
                                    "grow shrink-0 flex flex-row items-center h-full py-[12px] border-b border-transparent" +
                                    ((index === items.length - 1 && "border-b-gray-10") || "")
                                }
                            >
                                <h3 className={"text-[14px]" + ((_view === view && " text-accent") || " text-gray-60")}>
                                    {name}
                                </h3>
                            </div>
                        </div>
                    </LinkComponent>
                )
            })}
        </div>
    )
}

export default SidebarOptions

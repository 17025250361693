import { useRef, useState } from "react"
import _ from "lodash"
import useOutsideComponentClickHandler from "../../../hooks/ClickOutsideHook"
import Checkbox from "../../../ui/Checkbox/Checkbox"
import Chip from "../../../components/Chip"
import SearchIcon from "../../../ui/Icons/SearchIcon"

export const toggleObjKey = (obj, key, value = "") => {
    const copy = { ...obj }
    if (copy[key]) {
        delete copy[key]
    } else {
        copy[key] = value !== "" ? value : key
    }
    return copy
}

const PlacesInput = ({ options, selected, select, placeholder }) => {
    const [open, toggle] = useState(false)
    const [searchText, setSearchText] = useState("")

    const optionsToShow = options.filter((item = "") =>
        item.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    ) // TODO: Ask what product likes more: Filter or sort
    // .sort((a, b) => {
    //     if (searchText === "") return 0
    //     const includesA = a.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    //     const includesB = b.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    //     if (!includesA && includesB) return 1
    //     else if (includesA && !includesB) return -1
    //     else return 0
    // })

    const handleButtonClick = (option) => {
        select(toggleObjKey(selected, option, ""))
        placesInputRef?.current?.focus()
    }

    const handleEnter = () => {
        if (optionsToShow.length === 0 || searchText === "") {
            toggle(false)
            return
        }
        select(toggleObjKey(selected, optionsToShow[0], ""))
        setSearchText("")
        if (placesInputRef?.current) placesInputRef.current.value = ""
    }

    const containerRef = useOutsideComponentClickHandler(() => open && toggle(false))
    const scrollRef = useRef(null)

    const onSearch = (text) => {
        scrollRef?.current?.scrollTo(0, 0)
        setSearchText(text)
    }
    const debounceSearch = _.debounce(onSearch, 100)

    const onFocusOrTypeOrClick = () => {
        if (!open) toggle(true)
    }
    const placesInputRef = useRef(null)

    return (
        <button
            className={
                "relative w-full min-h-[36px]" +
                " border-[1px] border-gray-10 rounded-lg" +
                " p-2 cursor-text" +
                " flex flex-row items-center gap-[6px] flex-wrap" +
                " focus:outline-accent" +
                " text-gray-90" +
                (open ? " border-accent" : "")
            }
            onClick={() => {
                placesInputRef?.current?.focus()
            }}
            ref={containerRef}
        >
            {Object.keys(selected).length > 0 ? (
                Object.keys(selected).map((key) => (
                    <Chip
                        key={"analogs-place-chip-" + key.replaceAll(" ", "_")}
                        id={"analogs-place-chip-" + key.replaceAll(" ", "_")}
                        value={key}
                        onRemove={() => select(toggleObjKey(selected, key, ""))}
                    />
                ))
            ) : (
                <span className="w-6 h-6 ml-1">
                    <SearchIcon />
                </span>
            )}
            <input
                type="text"
                placeholder={Object.keys(selected).length === 0 ? placeholder : ""}
                className={
                    "min-w-0 grow shrink focus:outline-none placeholder:text-gray-30 " +
                    (open || Object.keys(selected).length === 0 ? "h-6" : "h-0 absolute")
                }
                onKeyUp={(e) => {
                    let sanitizedInput = e.target.value
                    e.target.value = sanitizedInput
                    if (e.key === "Enter") {
                        onSearch(sanitizedInput)
                        handleEnter(sanitizedInput)
                    } else debounceSearch(sanitizedInput)
                    onFocusOrTypeOrClick()
                }}
                onFocus={onFocusOrTypeOrClick}
                onClick={onFocusOrTypeOrClick}
                ref={placesInputRef}
            />
            {open && optionsToShow.length > 0 && (
                <div
                    className={[
                        "absolute top-full inset-x-0",
                        "bg-white z-30 rounded-lg elevation-2",
                        "h-fit max-h-[200px] min-w-full",
                        "border-[1px] border-gray-10",
                        "overflow-y-auto",
                    ].join(" ")}
                    ref={scrollRef}
                >
                    {optionsToShow.map((item, index) => {
                        return (
                            <button
                                key={"places-option-" + index}
                                className={[
                                    "flex flex-row items-center",
                                    "w-full p-2",
                                    "hover:bg-gray-5",
                                    "cursor-pointer",
                                    "transition-all duration-75",
                                    index === 0 && searchText !== "" ? "bg-gray-5" : "",
                                ].join(" ")}
                                onClick={() => {
                                    handleButtonClick(item)
                                }}
                            >
                                <Checkbox
                                    checked={selected[item] ? true : false}
                                    status={selected[item] ? "full" : "empty"}
                                    onChange={(e) => e.preventDefault()}
                                    disabled={true}
                                    extraWrapperClasses="pointer-events-none"
                                />
                                <p className="pl-[5px] font-normal text-left truncate">{item}</p>
                            </button>
                        )
                    })}
                </div>
            )}
        </button>
    )
}

export default PlacesInput

import { useContext, useState } from "react"
import { withRouter } from "react-router"
import { Link as RouterLink } from "react-router-dom"
import app from "../Util/Fire"
import validateEmail from "../Util/Validate"
import BackgroundAndCardLayout from "./BackgroundAndCardLayout"
import { Button } from "./Button"
import { GenericFromInputAndLabel } from "./SignUp"
import { ToastContext } from "./Toast/ToastContextProvider"
import { LOCAL_STORAGE_VARIABLES } from "./UserProfile/UserProfile"

const RestorePasswordEmail = ({ history }) => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const { enqueueError, enqueueAlert } = useContext(ToastContext)

    const handlePassword = async (event) => {
        event.preventDefault()
        setLoading(true)
        try {
            /* TODO Add better styles to handle successful email sent and error handling, maybe a message before redirecting*/
            await app.auth().sendPasswordResetEmail(email)
            setLoading(false)
            enqueueAlert("We have sent you an email to reset your password.")
            setTimeout(() => {
                app.auth().signOut()
                LOCAL_STORAGE_VARIABLES.forEach((value) => {
                    localStorage.removeItem(value)
                })
                history.push("/")
            }, 5000)
        } catch (error) {
            setLoading(false)
            if (error.toString().includes("There is no user"))
                enqueueError("That email is not registered on ClimateAi.")
            else enqueueError(error)
        }
    }

    const returnPath = history.location.state ? history.location.state.prevPath : "/"

    return (
        <BackgroundAndCardLayout>
            <div className="montserrat text-[16px] text-gray-90 font-semibold text-center leading-tight pb-0">
                Password Recovery
            </div>
            <div className="roboto text-[16px] text-gray-90 leading-relaxed text-center pb-2">
                Enter the email address associated with your account.
            </div>
            <GenericFromInputAndLabel
                label="Email Address"
                handleChange={(e) => {
                    setEmail(e.target.value)
                }}
                value={email}
                placeholder="example@email.com"
                type="email"
            />
            <div className="flex flex-row self-stretch pt-4 pb-2 space-x-2">
                <RouterLink className="w-1/2" to={returnPath}>
                    <Button label="Cancel" type="secondary" extend={true} onClick={() => {}} />
                </RouterLink>
                <Button
                    extraClasses={"w-1/2" + (loading ? " cursor-wait" : "")}
                    label="Continue"
                    type="primary"
                    extend={true}
                    onClick={handlePassword}
                    disabled={!validateEmail(email) || loading}
                />
            </div>
        </BackgroundAndCardLayout>
    )
}

export default withRouter(RestorePasswordEmail)

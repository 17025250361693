import { useContext, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { UserProfile } from "../"
import { AuthContext } from "../../Auth/Auth"
import AddLocationsButton from "../../ui/Controls/AddLocationsButton"

const TopBarOption = ({ option }) => {
    return (
        <NavLink
            style={{ width: option.label.length * 12 + "px" }}
            className={(isActive) =>
                "flex-1 md:flex-none border-y-4 border-transparent flex flex-row items-center justify-center hover:font-bold transition-all max-w-[200px]" +
                (isActive ? " border-b-accent text-accent font-bold cursor-default" : " text-gray-90 cursor-pointer")
            }
            to={option.to}
        >
            <div>{option.label}</div>
        </NavLink>
    )
}

const TopBar = ({ navOptions = [], profileOptions = [], url }) => {
    const NAVBAR_OPTIONS = [
        {
            label: "Monitor (Seasonal)",
            to: "/weather",
            permission: "weather",
        },
        {
            label: "Adapt (Climate)",
            to: "/climate",
            permission: "climate",
        },
        { label: "Assess (Exposure)", to: "/exposure", permission: "exposure" },
        { label: "Custom", to: "/custom", permission: "custom" },
    ]

    const [options, setOptions] = useState(NAVBAR_OPTIONS)

    const { permissions, currentUser, userInfo, featurePermissions } = useContext(AuthContext)

    const { skin } = window.dashboard

    useEffect(() => {
        let newOptions = [...NAVBAR_OPTIONS]
        if (navOptions.length !== 0) newOptions = [...navOptions]

        setOptions(newOptions)
    }, [])

    const { Logo } = window.dashboard

    function togglePaymentFlow() {
        window.open("https://skip.payment.climate.ai/input_field/" + currentUser.uid)
    }

    function shouldShowAddLocationsButton() {
        return url.includes("weather") && !url.includes("add-locations") && featurePermissions?.field_tools?.add
    }

    return (
        <div className="bg-white grow-0 shrink-0 elevation-1 md:pr-3 z-full">
            {/* DESKTOP VIEW */}
            <div className="hidden md:flex flex-row items-center justify-between h-[56px]">
                <div className="flex flex-row items-center justify-start h-full">
                    <span className="grow-0 shrink-0 h-full pl-16 md:px-5 py-[5px] flex flex-col justify-center items-center">
                        {Logo && <Logo title={userInfo?.company || "ClimateAI"} />}
                    </span>
                    <div className="h-full flex flex-row items-stretch space-x-4 uppercase text-[14px]">
                        {options.map((option, index) => {
                            if (!permissions[option.permission]) return null
                            return <TopBarOption option={option} key={index} />
                        })}
                    </div>
                </div>
                <div className="flex flex-row items-center justify-center space-x-4">
                    {shouldShowAddLocationsButton() && (
                        <AddLocationsButton
                            togglePaymentFlow={togglePaymentFlow}
                            skin={skin}
                            email={currentUser.email}
                        />
                    )}
                    {currentUser.email.includes("demo") && (
                        <div className="text-[14px] text-gray-30 font-bold">Demo account</div>
                    )}
                    <UserProfile options={profileOptions} />
                </div>
            </div>

            {/* MOBILE VIEW */}
            <div
                className={
                    "flex md:hidden flex-row justify-start space-x-4 uppercase h-[56px] text-[14px]" +
                    (!["/weather/dashboard"].includes(url) && " pl-[70px]")
                }
            >
                {options
                    .filter((_, i) => i === 0)
                    .map((option, index) => {
                        if (!permissions[option.permission]) return null
                        return <TopBarOption option={option} key={index} />
                    })}
                <div className="flex flex-row items-center justify-end flex-1 pr-2">
                    <UserProfile options={profileOptions} />
                </div>
            </div>
        </div>
    )
}

export default TopBar

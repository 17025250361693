export function getYLabel(cas, fun, cons, va, un) {
    switch (cas) {
        case 1: {
            if (fun === "average") return `Average ${va} ${un || ""}`
            else return `Total ${va} ${(un && "(" + un + ")") || ""}`
        }
        case 2: {
            if (cons) return `Average Number of events`
            else return `Average Number of days`
        }
        case 3: {
            return `Average Number of days`
        }
    }
}

export function getYFormat(cas, fun, cons, va, un) {
    switch (cas) {
        case 1: {
            return un
        }
        case 2:
        case 3: {
            return ""
        }
    }
}

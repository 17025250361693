import React, { useContext, useEffect, useMemo, useState } from "react"

// Components
import TableColumn from "../../ui/Table/TableColumn"

import CropTableCheck from "./CropTableColumns/CropTableCheck"
import CropTableAlert from "./CropTableColumns/CropTableAlert"
import CropTableName from "./CropTableColumns/CropTableName"
import CropTableTomorrowTemp from "./CropTableColumns/CropTableTomorrowTemp"
import CropTableTomorrowPrecipt from "./CropTableColumns/CropTableTomorrowPrecipt"
import CropTableActions from "./CropTableColumns/CropTableActions"

import { processPolygonData } from "../../helpers/chartHelpers"
import { normalizeString } from "../../helpers/wordHelper"
import AddEditField from "../../ui/Table/AddEditField"

// Views
// Context providers / Utils
import {
    getTableData,
    addTableRow,
    updateTableRow,
    deleteTableRow,
    uploadFile,
    getTableColumnSettings,
    formatDate,
} from "../../services/table.service"
import { addLabelToFields, getAllLabels, removeLabel, removeLabelFromField } from "../../services/label.service"
import networking from "../../Util/Networking"
import { SettingsContext } from "../../Util/SettingsContext"

// Hooks
import { useHistory, Link } from "react-router-dom"
// Styles
import "./CropTable.css"

// Table fields
import {
    alertColumn,
    checkColumn,
    nameColumn,
    cropColumn,
    varietyColumn,
    regionColumn,
    actionsColumn,
    labelsColumn,
} from "./tableColumns"

// Table meta fields
import Checkbox from "../../ui/Checkbox/Checkbox"

import { AuthContext } from "../../Auth/Auth"
import { DataContext } from "../../Util/Data"
import { ToastContext } from "../Toast/ToastContextProvider"
import SimpleTable from "../../ui/Table/SimpleTable"
import LoadingBar from "../LoadingBar"
import MapboxGenericMap from "../Charts/MapboxMaps/MapboxGenericMap"
import { getPolygonCenter, getSeasonalMarkerPopup } from "../Charts/MapboxMaps/mapboxUtils"
import DebounceSearchInput from "../DebounceSearchInput/DebounceSearchInput"
import ExpandIcon from "../../ui/Icons/ExpandIcon"
import CollapseIcon from "../../ui/Icons/CollapseIcon"
import { convertTemperatureValueOnly, convertWaterLengthValueOnly } from "../../Util/UnitConversion"
import Tooltip from "../../ui/Tooltip"
import CropTableLabels from "./CropTableColumns/CropTableLabels"
import { REMEMBERED_SEASONAL_TABLE_FILTERS } from "../../Util/localStorageVariables"
import { formatSeasonalOverviewCSVData, CROP_TABLE_CSV_HEADERS } from "../../Util/CSVParser"
import getRiskIcon from "../../Util/getRiskIcon"
import DotsIcon from "../../ui/Icons/DotsIcon"
import createMetadataForRisk from "../../Util/createMetaDataForRisk"

function CropTable() {
    // field data
    const {
        state: { fields, polygons },
        dispatch,
    } = useContext(DataContext)

    const [loading, setLoading] = useState(false)
    const [expanded, toggleExpanded] = useState(false)
    const [selectedMapColumnVariable, setSelectedMapColumnVariable] = useState("temp")
    const [tableColumnSettings, setTableColumnSettings] = useState({})

    const { currentUser, permissions, featurePermissions } = useContext(AuthContext)
    const {
        currentSettings: { units, regionalViewMapVisible, loading: unitsLoading },
        handleUpdateDBUserSettings,
    } = useContext(SettingsContext)
    const { enqueueAlert, enqueueError, enqueuePermissionError } = useContext(ToastContext)

    const [labels, setLabels] = useState([])
    const [selectedFieldIds, setSelectedFieldIds] = useState([])

    const [activeField, setActiveField] = useState(null)
    const [addEditFieldOpen, setAddEditFieldOpen] = useState(false)

    const history = useHistory()

    // get table data
    useEffect(() => {
        let navData = history.location.navigationData
        if (navData !== undefined) if (navData.pendingToast !== undefined) enqueueAlert(navData.pendingToast)
        if ((navData !== undefined && navData.forceReload) || !fields || fields.length === 0) {
            setLoading(true)
            getTableData()
                .then((fields) => {
                    // save fields to upper state
                    dispatch({ type: "GET_FIELDS", payload: { fields } })
                    const polys = {}
                    fields.forEach((field) => (polys[field.uuid] = field.polygon))
                    dispatch({ type: "GET_POLYGONS", payload: { polygons: polys } })
                })
                .catch(console.log)
                .finally(() => setLoading(false))
        }

        getAllLabels()
            .then((labelsData) => {
                setLabels(labelsData)
            })
            .catch(console.log)

        getTableColumnSettings().then((data) => {
            setTableColumnSettings(data)
        })
    }, [])

    const _checkColumn = {
        ...checkColumn,
        component: CropTableCheck,
        headerRender: (_, { checked, handleCheckAllRows, checkedStatus }) => (
            <div className="crop-table-check">
                <Checkbox checked={checked} onChange={handleCheckAllRows} status={checkedStatus} />
            </div>
        ),
    }

    const _alertColumn = {
        ...alertColumn,
        component: CropTableAlert,
    }

    // this just saves the selected fields here in the screen
    function handleSelectionChange(selectedFields) {
        setSelectedFieldIds(selectedFields.map((field) => field.uuid))
    }

    async function handleRowAdd(rowData) {
        if (featurePermissions && featurePermissions.field_tools && featurePermissions.field_tools.add) {
            try {
                setLoading(true)
                // upload file to firebase
                if (rowData.shapefile instanceof File) rowData.shapefile = await uploadFile("Shape", rowData.shapefile)

                rowData["crop_id"] = +rowData["cropId"]
                delete rowData["cropId"]
                rowData["variety_id"] = +rowData["varietyId"]
                delete rowData["varietyId"]

                await addTableRow(rowData)
                const _data = await getTableData()
                // re - poblate table
                dispatch({ type: "GET_FIELDS", payload: { fields: _data } })
                setLoading(false)
                enqueueAlert("Field added successfully!")
            } catch (e) {
                setLoading(false)
                enqueueError()
            }
        } else {
            enqueuePermissionError()
        }
    }

    async function handleRowUpdate(rowData, past) {
        if (featurePermissions && featurePermissions.field_tools && featurePermissions.field_tools.edit) {
            try {
                setLoading(true)

                // upload file to firebase
                if (rowData.shapefile instanceof File) rowData.shapefile = await uploadFile("Shape", rowData.shapefile)

                rowData["crop_id"] = +rowData["cropId"]
                delete rowData["cropId"]
                rowData["variety_id"] = +rowData["varietyId"]
                delete rowData["varietyId"]

                await updateTableRow(past.uuid, { ...past, ...rowData, labels: undefined })
                const _data = await getTableData()

                dispatch({ type: "GET_FIELDS", payload: { fields: _data } })
                setLoading(false)
                enqueueAlert("Field updated successfully!")
            } catch (e) {
                console.log(e)
                setLoading(false)
                enqueueError()
            }
        } else {
            enqueuePermissionError()
        }
    }

    async function handleRowDelete(rowData) {
        if (featurePermissions && featurePermissions.field_tools && featurePermissions.field_tools.delete) {
            try {
                setLoading(true)
                await deleteTableRow(rowData.uuid)

                const _data = await getTableData()
                dispatch({ type: "GET_FIELDS", payload: { fields: _data } })

                setLoading(false)
                enqueueAlert("Field deleted successfully!")
                return true
            } catch (e) {
                setLoading(false)
                enqueueError()
            }
        } else {
            enqueuePermissionError()
        }
        return false
    }

    function handleToggleAddEditField(field) {
        setActiveField(field && field.uuid ? field : null)
        setAddEditFieldOpen(!addEditFieldOpen)
    }

    async function handleDataSourceChange(fieldId, selectedObj) {
        if (featurePermissions?.datasources?.edit === false) {
            return
        }

        try {
            let userToken = await currentUser.getIdToken()
            return networking.put("/api/v1/fields/datasource/" + fieldId, selectedObj, {
                extraHeaders: { "User-Token": userToken },
            })
        } catch (err) {
            console.log("Error getting User token = ", err)
        }
    }

    async function handleDeleteLabel(label) {
        setLoading(true)

        const { name: labelName } = label
        const pastFields = [...fields]
        const pastLabels = [...labels]

        removeLabel(label)
            .catch(() => {
                setLabels(pastLabels)
                dispatch({ type: "GET_FIELDS", payload: { fields: pastFields } })
            })
            .finally(() => {
                setLoading(false)
            })

        setLabels(labels.filter((label) => label.name !== labelName))

        const _fields = fields.map((field) => {
            if (field.labels) {
                const _field = { ...field, labels: field.labels.filter((label) => label.name !== labelName) }
                return _field
            } else return field
        })

        dispatch({ type: "GET_FIELDS", payload: { fields: _fields } })
    }

    function handleLabelFields(label, selectedFieldIds) {
        const { name: labelName } = label
        const normLabelName = normalizeString(labelName)
        const pastFields = [...fields]
        const pastLabels = [...labels]
        let hasMatch = false
        labels.forEach((_label) => {
            if (normalizeString(_label.name) !== normLabelName) return
            label.name = _label.name
            label.color = _label.color
            hasMatch = true
        })
        // Send new label to the backend
        setLoading(true)
        addLabelToFields(label, selectedFieldIds)
            .then(() => {
                if (hasMatch) setLabels(pastLabels)
                else setLabels([...labels, label])
            })
            .catch(() => {
                setLabels(pastLabels)
                dispatch({ type: "GET_FIELDS", payload: { fields: pastFields } })
            })
            .finally(() => {
                setLoading(false)
            })

        // insert all fields by uuid into an object
        const hash = {}
        for (let selected of selectedFieldIds) hash[selected] = selected

        // Update the app state to add the new label to the field
        const _fields = fields.map((field) => {
            if (hash[field.uuid]) {
                // delete hash[item.uuid]
                const _field = { ...field }
                if (_field.labels && !_field.labels.find((label) => normalizeString(label.name) === normLabelName))
                    _field.labels = [..._field.labels, label]
                else if (!_field.labels) _field.labels = [label]

                return _field
            } else return field
        })

        dispatch({ type: "GET_FIELDS", payload: { fields: _fields } })
    }

    async function handleRemoveLabelFromField(label, field) {
        setLoading(true)

        const { name: labelName } = label
        const pastFields = [...fields]
        const pastLabels = [...labels]

        removeLabelFromField(label, field)
            .catch(() => {
                setLabels(pastLabels)
                dispatch({ type: "GET_FIELDS", payload: { fields: pastFields } })
            })
            .finally(() => {
                setLoading(false)
            })

        const _fields = fields.map((item) => {
            if (item.uuid === field.uuid) {
                return { ...item, labels: item?.labels?.filter((label) => label.name !== labelName) }
            } else return item
        })
        // setCropTableFields(_cropTableFields)
        dispatch({ type: "GET_FIELDS", payload: { fields: _fields } })
    }

    // Group fields
    const regionGroupColumns = [
        // Alert Field (render changes)
        {
            ..._alertColumn,
            render(_, rowData) {
                let count = 0
                for (let field of rowData.data) if (field.alert_types_count) count += field.alert_types_count
                return <CropTableAlert value={{ count }} />
            },
        },
        _checkColumn,
        // Region Field (render changes)
        {
            ...regionColumn,
            displayName: "",
            toggler: true,
            draggable: false,
            style: { flex: 4 },
            headerStyle: { flex: 4 },
            render(value, rowData) {
                return (
                    <>
                        <strong>Region:</strong>
                        {value}
                    </>
                )
            },
        },
    ]

    const fieldsPolygonData = processPolygonData({ selectedFieldIds, polygons, fields }, units)

    const mapPinsData = useMemo(
        () =>
            fieldsPolygonData.data.sort((a, b) => {
                // Sort the locations with alerts at the end
                // to see them at the bottom in the map pins
                if (a.alert_types_count && b.alert_types_count) {
                    if (a.alert_types_count > b.alert_types_count) {
                        return 1
                    }
                    if (b.alert_types_count > a.alert_types_count) {
                        return -1
                    }
                    return 0
                }
                if (a.alert_types_count) return 1
                if (b.alert_types_count) return -1
                return 0
            }),
        [fieldsPolygonData]
    )
    const mapPolygonsData = useMemo(
        () =>
            fieldsPolygonData.data.map((fieldData) => ({
                id: fieldData.id,
                polygon: fieldData.polygon,
            })),
        [fieldsPolygonData]
    )

    return (
        <div className="mb-6 overview">
            <div className="overview__main">
                {loading && <LoadingBar loading />}
                <div className="w-full h-full xl:border-x border-x-gray-10">
                    <div
                        className={
                            "w-full transition-all duration-200 h-[90%]" + (expanded ? " h-[0%]" : " lg:h-[50%]")
                        }
                    >
                        <MapboxGenericMap
                            data={mapPinsData}
                            polygons={mapPolygonsData}
                            markerPopup={(data) => getSeasonalMarkerPopup(data, units)}
                            mapLocationOnClick={({ id }) => history.push("/weather/" + id)}
                            markerPopupLock
                        />
                    </div>
                    <div
                        className={
                            "w-full transition-all duration-200 h-full " + (expanded ? "" : "h-[10%] lg:h-[50%]")
                        }
                    >
                        <SimpleTable
                            title="Field List"
                            // data
                            data={fields}
                            // metaColumns={cropTableMetaFields}
                            // Pass processed polygon data (Needed for the map display)
                            // fieldsPolygonData={processPolygonData({ selectedFieldIds, polygons, fields }, units)}
                            // handlers
                            loading={loading}
                            onSelectedMapColumnVariable={setSelectedMapColumnVariable}
                            onSelectionChanged={handleSelectionChange} // Handling table row selection changes
                            // onNewReport={handleNewReport}
                            onRowAdd={handleRowAdd}
                            onRowUpdate={handleRowUpdate}
                            onRowDelete={handleRowDelete}
                            onDataSourceChange={handleDataSourceChange}
                            onToggleAddEditField={handleToggleAddEditField}
                            // tableColumnSettings={tableColumnSettings}
                            // highlight functionallity
                            highlightByColumn={selectedMapColumnVariable}
                            // label functionallity
                            labels={labels}
                            onDeleteLabel={handleDeleteLabel}
                            onLabelFields={handleLabelFields}
                            onRemoveLabelFromField={handleRemoveLabelFromField}
                            // permissions
                            reportTypes={featurePermissions["report_generation"]}
                            showAddButton={featurePermissions?.field_tools?.add}
                            showDataSourceDropdown={featurePermissions?.datasource?.edit}
                            regionalViewMapVisibleSetting={regionalViewMapVisible}
                            onUserSettingsChange={handleUpdateDBUserSettings}
                            units={units}
                            // navigation
                            history={history}
                            expanded={expanded}
                            filterStorage={REMEMBERED_SEASONAL_TABLE_FILTERS}
                            exportFormats={{
                                CSV: {
                                    func: formatSeasonalOverviewCSVData,
                                    headers: CROP_TABLE_CSV_HEADERS,
                                    headerSection: `Overview Locations${",".repeat(
                                        CROP_TABLE_CSV_HEADERS.length - 1
                                    )}\n`,
                                },
                            }}
                            exportFilename="overview_locations"
                            renderTableTopBar={(handleSearchBarInputChange, _, renderExportButton) => {
                                return (
                                    <div
                                        className={
                                            "flex items-center justify-between w-full h-full px-5 " +
                                            (expanded && "pt-2")
                                        }
                                    >
                                        <div className="flex items-center">
                                            <h4 className="text-[16px] montserrat font-semibold">Overview</h4>
                                        </div>
                                        <div className="flex items-center">
                                            {renderExportButton()}
                                            <div className="w-48 pl-2">
                                                <DebounceSearchInput
                                                    onSearch={(text) => handleSearchBarInputChange(text)}
                                                />
                                            </div>
                                            <Tooltip
                                                position="left"
                                                content={(expanded && "Collapse Table") || "Expand Table"}
                                            >
                                                <div className="pl-2">
                                                    <button
                                                        className="btn w-[24px] h-[24px]"
                                                        onClick={() => toggleExpanded(!expanded)}
                                                    >
                                                        {(!expanded && <ExpandIcon fill="var(--color-gray-60)" />) || (
                                                            <CollapseIcon fill="var(--color-gray-60)" />
                                                        )}
                                                    </button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )
                            }}
                        >
                            {/* Alert Column */}
                            <TableColumn {..._alertColumn} />
                            {/* DEPRECATED Check Column */}
                            {/* <TableColumn {..._checkColumn} /> */}
                            {/* Name Column */}
                            <TableColumn
                                {...nameColumn}
                                groupColumns={(self) => [_checkColumn, self]}
                                filter
                                renderSummary={() => "Summary"}
                                render={(value, rowData, rowOptions) => {
                                    return (
                                        <CropTableName
                                            value={value}
                                            rowData={rowData}
                                            rowOptions={rowOptions}
                                            permissions={permissions}
                                        />
                                    )
                                }}
                            />
                            {/* Region Column */}
                            <TableColumn {...regionColumn} filter groupColumns={() => regionGroupColumns} />
                            {/* Crop Column */}
                            <TableColumn {...cropColumn} filter groupColumns={(self) => [_checkColumn, self]} />
                            {/* Variety Column */}
                            <TableColumn {...varietyColumn} filter groupColumns={(self) => [_checkColumn, self]} />

                            {/* <TableColumn
                                propName="risk_probability"
                                displayName="Weighted Risk Probability"
                                headerStyle={{
                                    minWidth: 180,
                                    flex: 1,
                                }}
                                style={{
                                    minWidth: 180,
                                    flex: 1,
                                }}
                                transform={(_, rowData) => {
                                    const risks = rowData?.climate_risk

                                    if (risks.length > 0 && Array.isArray(risks)) {
                                        return risks.reduce((prev, curr) => prev + curr.climate_risk_weighted, 0)
                                    }

                                    return 0
                                }}
                                summarize
                                summarizeFunction={(rows) => {
                                    return rows.reduce((prev, curr) => prev + curr.risk_probability, 0) / rows.length
                                }}
                                renderSummary={(v) => {
                                    if (!v || Number.isNaN(v)) return "Avg 0.00%"
                                    else return "Avg " + (v * 100).toFixed(2) + "%"
                                }}
                                render={(v, rowData) => {
                                    return (
                                        <Link
                                            to={`/weather/${rowData.uuid}/planting_tool`}
                                            className="text-black underline visited:text-black"
                                        >
                                            {(v * 100).toFixed(2) + "%"}
                                        </Link>
                                    )
                                }}
                                sortable
                            /> */}

                            {!unitsLoading && units && (
                                <TableColumn
                                    propName={`risks`}
                                    displayName={"Top Risk Probability (next 6 weeks)"}
                                    style={{ minWidth: 350, flex: 1 }}
                                    headerStyle={{ minWidth: 350, flex: 1 }}
                                    transform={(_, rowData) => {
                                        const risks = rowData?.climate_risk

                                        if (!risks.length > 0) return null

                                        const sortedRisks = risks
                                            .sort((a, b) => {
                                                if (b.climate_risk_raw > a.climate_risk_raw) return 1
                                                else if (b.climate_risk_raw < a.climate_risk_raw) return -1
                                                else return 0
                                            })
                                            .filter((a) => a.climate_risk_raw !== 0)

                                        // make average when items exeed 4 length
                                        const MAX_INDIVIDUAL_RISK_NUMBER = 3
                                        let result = []
                                        for (let i = 0; i < sortedRisks.length; i++)
                                            if (i < MAX_INDIVIDUAL_RISK_NUMBER) result.push(sortedRisks[i])
                                            else break

                                        return { result }
                                    }}
                                    render={(risksObj) => {
                                        if (!risksObj) return null

                                        const { result } = risksObj

                                        return (
                                            <>
                                                <div className="flex items-center justify-center w-full h-full gap-x-7">
                                                    {result.map((risk) => {
                                                        const IconComponent = getRiskIcon(risk.risk_alert)
                                                        const label = createMetadataForRisk(risk, units)

                                                        let [riskName, riskTitle] = risk.risk_alert_name.split(" - ")

                                                        return (
                                                            <div className="flex items-center w-auto h-full gap-x-2">
                                                                <Tooltip
                                                                    white
                                                                    size="medium"
                                                                    content={
                                                                        <div className="w-full h-full p-2 flex items-start text-left text-[14px] ">
                                                                            <div className="w-[24px] fill-gray-60">
                                                                                {IconComponent && <IconComponent />}
                                                                            </div>
                                                                            <div className="flex-1 pl-3 pt-.5">
                                                                                <h6 className="text-body">
                                                                                    {riskTitle || riskName}
                                                                                </h6>
                                                                                {riskTitle && riskName && (
                                                                                    <p className="pt-1 text-gray-30 roboto">
                                                                                        {riskName}
                                                                                    </p>
                                                                                )}
                                                                                <p className="pt-1">{label}</p>
                                                                                <p className="pt-1">
                                                                                    <label className="font-bold">
                                                                                        Start:{" "}
                                                                                    </label>
                                                                                    {risk.start_date &&
                                                                                        new Date(
                                                                                            risk.start_date
                                                                                        ).toLocaleDateString("en-US", {
                                                                                            month: "long",
                                                                                            day: "numeric",
                                                                                        })}
                                                                                </p>
                                                                                <p className="">
                                                                                    <label className="font-bold">
                                                                                        End:{" "}
                                                                                    </label>
                                                                                    {risk.end_date &&
                                                                                        new Date(
                                                                                            risk.end_date
                                                                                        ).toLocaleDateString("en-US", {
                                                                                            month: "long",
                                                                                            day: "numeric",
                                                                                        })}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    position="left"
                                                                >
                                                                    <div className="w-[20px] h-[20px] cursor-pointer fill-gray-60">
                                                                        {IconComponent && <IconComponent />}
                                                                    </div>
                                                                </Tooltip>
                                                                {risk.climate_risk_raw &&
                                                                    (risk.climate_risk_raw * 100).toFixed(2)}
                                                                %
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                {/* TODO add average item */}
                                            </>
                                        )
                                    }}
                                />
                            )}
                            {/* Labels Column */}
                            <TableColumn
                                propName="labels"
                                {...labelsColumn}
                                filter
                                // Function that specifies which options should get added to the filters
                                // depending on the value of each row. It's intended to be used with values
                                // that are not linear such as arrays and objects.
                                columnFilterOptions={(rowData, availableFilterOptionsCol = new Set()) => {
                                    if (!rowData?.labels || rowData.labels.length === 0)
                                        return availableFilterOptionsCol

                                    // return value
                                    const values = rowData?.labels.map((label) => label.name)
                                    values.forEach((val) => availableFilterOptionsCol.add(val))

                                    // returns a set of values
                                    return availableFilterOptionsCol
                                }}
                                //
                                filterFunction={(rowData, columnFilterValues = []) => {
                                    if (!rowData.labels || rowData.labels.length === 0) return false

                                    for (let columnFilterValue of columnFilterValues)
                                        if (
                                            rowData?.labels &&
                                            rowData.labels.map((label) => label.name).includes(columnFilterValue)
                                        )
                                            return true

                                    return false
                                }}
                                style={{ minWidth: 200, flex: 1 }}
                                headerStyle={{ minWidth: 200, flex: 1 }}
                                component={CropTableLabels}
                            />

                            {/* Actions */}
                            {(featurePermissions?.field_tools?.edit || featurePermissions?.field_tools?.delete) && (
                                <TableColumn
                                    {...actionsColumn}
                                    render={(value, rowData, rowOptions) => {
                                        return (
                                            <CropTableActions
                                                value={value}
                                                rowData={rowData}
                                                rowOptions={rowOptions}
                                                onToggleAddEditField={handleToggleAddEditField}
                                                featurePermissions={featurePermissions}
                                            />
                                        )
                                    }}
                                />
                            )}
                        </SimpleTable>
                    </div>
                </div>
                <AddEditField
                    doShow={addEditFieldOpen}
                    onCancel={() => setAddEditFieldOpen(false)}
                    field={activeField}
                    onSave={handleRowAdd}
                    onUpdate={handleRowUpdate}
                    canEditDatasource={featurePermissions?.datasource?.edit || false}
                />
            </div>
        </div>
    )
}

export default React.memo(CropTable)

import { useState, useEffect } from "react"

import PortfolioChart from "./components/PortfolioChart"

import { useLocation, useParams } from "react-router-dom"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import usePortfolioData from "../../hooks/usePortfolioData"
import ReportDownloadButton from "../LongTerm/components/ReportDownloadButton"

function Portfolio(props) {
    const { search } = useLocation()
    const query = new URLSearchParams(search)

    const { reportId } = useParams()

    const [mapLoading, setMapLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const {
        ready,
        scenarios,
        variables,
        decades,
        selectedScenario,
        selectedVariable,
        selectedDecade,
        setSelectedScenario,
        setSelectedVariable,
        setSelectedDecade,
    } = useAdaptationValues()

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?scenario=${selectedScenario}&var=${selectedVariable}&decade=${selectedDecade}`

        const request = http.get(`/api/v1/adapt/${reportId}/portfolio${query}`, { timeout: 180000 })
        request
            .then((response) => {
                setData(response.data)
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedVariable, selectedScenario, selectedDecade])

    const { chartData } = usePortfolioData(data)

    const selectedVariableObj = variables.find(({ value }) => value === selectedVariable)
    const selectedDecadeObj = decades.find((d) => d.value === selectedDecade)
    const selectedScenarioObj = scenarios.find((s) => s.value === selectedScenario)

    return (
        <>
            <ReportDownloadButton
                text={`Portfolio Analysis / ${selectedDecadeObj.label} / ${selectedVariableObj.label} / ${selectedScenarioObj.label}`}
            />
            <div className="overview">
                <div className="overview__main">
                    <PortfolioChart
                        loading={loading}
                        data={chartData}
                        scenarios={scenarios}
                        variables={variables}
                        decades={decades}
                        selectedScenario={selectedScenario}
                        selectedVariable={selectedVariable}
                        selectedDecade={selectedDecade}
                        setSelectedScenario={setSelectedScenario}
                        setSelectedVariable={setSelectedVariable}
                        setSelectedDecade={setSelectedDecade}
                    />
                </div>
            </div>
        </>
    )
}

export default Portfolio

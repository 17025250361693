import * as React from "react"

const TableArrowIcon = (props) => {
    const { sort } = props

    return (
        <svg width="100%" height="100%" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6 16 .804 10h10.392L6 16Z"
                fill={(sort === "DES" && "var(--color-gray-60)") || "var(--color-gray-10)"}
            />
            <path
                d="m6 0 5.196 6H.804L6 0Z"
                fill={(sort === "ASC" && "var(--color-gray-60)") || "var(--color-gray-10)"}
            />
        </svg>
    )
}

export default TableArrowIcon

import * as React from "react"

const AnalogsIcon = (props) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M18 10.2C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14ZM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2Z"
            fill={props.fill}
        />
        <path fill={props.fill} d="M9 8.006h6v1.665H9zM9 11.335h6V13H9z" />
    </svg>
)

export default AnalogsIcon

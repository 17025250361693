import { useEffect, useState } from "react"
import TimesIcon from "../../../ui/Icons/TimesIcon"
import { SimpleModal } from "../../../ui/Modal"
import { Button } from "../../../components/Button"
import SimplePopover from "../../../ui/Popover/SimplePopover"
import "./BulkUploadCSVParser.css"
import {
    getUserCrops,
    isDate,
    isEmptyString,
    MAX_LAT,
    MAX_LON,
    MIN_LAT,
    MIN_LON,
    TABLE_HEADER_COLUMN_DATA,
} from "../AddLocationsUtil"
import LoadingCircleIcon from "../../../ui/Icons/LoadingCircleIcon"
import CheckmarkIcon from "../../../ui/Icons/CheckmarkIcon"
import { Link as RouterLink } from "react-router-dom"

const BulkUploadCSVParser = (props) => {
    const { data, handleImportLocations, handleCancel } = props

    const [modalTitle, setModalTitle] = useState("Import Locations")
    const [modalSubtitle, setModalSubtitle] = useState("Reading file...")
    const [userCrops, setUserCrops] = useState([])
    const [readyToUpload, setReadyToUpload] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [uploadStatus, setUploadStatus] = useState({ rows: [], current: 0 })

    useEffect(() => {
        // Get user crops
        getUserCrops((crops) => setUserCrops(crops))
        // Monitor the data being recieved; once we get a file, validate it
        if (data !== undefined) validateCSVData(data)
    }, [data])

    const getCropNameList = () => {
        return Object.values(userCrops).map((crop) => {
            return crop.name
        })
    }

    const getCropIDByName = (cropName) => {
        let cropId = undefined
        Object.values(userCrops).map((crop) => {
            if (crop.name === cropName) cropId = crop.id
        })
        return cropId
    }

    const getVarietyIDByName = (cropName, varName) => {
        let varId = undefined
        Object.values(userCrops).map((crop) => {
            if (crop.name === cropName) {
                Object.values(crop.varieties).map((variety) => {
                    if (variety.name === varName) varId = variety.id
                })
            }
        })
        return varId
    }

    const getVarietyNameList = (cropName) => {
        let varietyNameList = []
        Object.values(userCrops).forEach((crop) => {
            if (crop.name === cropName) {
                varietyNameList = Object.values(crop.varieties).map((variety) => {
                    return variety.name
                })
            }
        })
        return varietyNameList
    }

    let errorCount = 0
    const setCellError = (cell, error) => {
        cell.error = error
        errorCount++
    }

    const validateCSVData = (data) => {
        errorCount = 0

        data.map((rowData, rowIndex) => {
            let rowCategory = undefined
            rowData.map((dataCellValue, colIndex) => {
                // Prevent extra data from the CSV from messing up parsing
                // -2 is because the first column of the table is the enumerating column, no data is displayed there
                if (colIndex > TABLE_HEADER_COLUMN_DATA.length - 2) return

                // Restructure data so that it can hold error info, if needed
                if (typeof data[rowIndex][colIndex] !== "object")
                    data[rowIndex][colIndex] = { value: dataCellValue, error: undefined }

                // Grab a copy of that new dataCell instance
                const dataCell = data[rowIndex][colIndex]

                dataCell.value = dataCell.value.trim()

                // Data type validations //
                let cellIsEmpty = isEmptyString(dataCell.value)

                // Empty //
                if (cellIsEmpty && TABLE_HEADER_COLUMN_DATA[colIndex + 1].required) {
                    // This cell is empty && its a required cell, add the error!
                    setCellError(dataCell, "This cell is empty, and is required")
                } else if (cellIsEmpty && !TABLE_HEADER_COLUMN_DATA[colIndex + 1].required) {
                    // This cell is empty, but it is not required; no further validation needed
                    // return early
                    return
                }

                // Numerical //
                else if (TABLE_HEADER_COLUMN_DATA[colIndex + 1].type === "number") {
                    // This is a numerical cell, try to parse it
                    const parsedNumber = Number(dataCell.value)
                    if (isNaN(parsedNumber)) {
                        // Add error to this cell
                        setCellError(dataCell, "This cell accepts numerical values only")
                    }
                    // Now that we know we have a number on this cell, lets validate its value
                    else if (TABLE_HEADER_COLUMN_DATA[colIndex + 1].label === "Latitude") {
                        if (parsedNumber > MAX_LAT || parsedNumber < MIN_LAT) {
                            // This value is out of bounds for latitude, add error
                            setCellError(
                                dataCell,
                                `Out of bounds value for latitude; values must be between ${MIN_LAT} and ${MAX_LAT}`
                            )
                        }
                    } else if (TABLE_HEADER_COLUMN_DATA[colIndex + 1].label === "Longitude") {
                        if (parsedNumber > MAX_LON || parsedNumber < MIN_LON) {
                            // This value is out of bounds for longitude, add error
                            setCellError(
                                dataCell,
                                `Out of bounds value for longitude; values must be between ${MIN_LON} and ${MAX_LON}`
                            )
                        }
                    }
                }

                // String //
                else if (TABLE_HEADER_COLUMN_DATA[colIndex + 1].type === "string") {
                    let labelSplit = []
                    let currentLabel = TABLE_HEADER_COLUMN_DATA[colIndex + 1].label
                    // The labels cell needs to be split using the '-' character
                    // Split it before checking for special chars
                    if (currentLabel === "Labels") {
                        labelSplit = dataCell.value.split("-")
                        // Join with emptystring initially, so it can be properly tested for special chars
                        dataCell.value = labelSplit.join("")
                    }

                    // This is a string cell, check for special characters
                    // Except for loc name and category, we accept special characters for those columns!
                    if (currentLabel !== "Location Name" && currentLabel !== "Category") {
                        if (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(dataCell.value)) {
                            // String has special characters, add error to this cell
                            setCellError(dataCell, "This cell contains disallowed special characters")
                        }
                    }

                    // Now we know we have a valid string, check if it has a valid Category / Subcategory value
                    if (currentLabel === "Category") {
                        // Set this row's category
                        rowCategory = dataCell.value
                        if (!getCropNameList().includes(dataCell.value)) {
                            // The specified category does not exist for this user! Set according error
                            setCellError(dataCell, "This Category does not exist on your Category list")
                        } else dataCell.id = getCropIDByName(rowCategory) // Set crop id for later use
                    } else if (currentLabel === "Subcategory") {
                        if (!getVarietyNameList(rowCategory).includes(dataCell.value)) {
                            // The specified subcategory does not exist for the specified category! Set according error
                            setCellError(
                                dataCell,
                                `This Subcategory does not exist for the specified Category '${
                                    rowCategory !== undefined ? rowCategory : data[rowIndex][colIndex - 1].value
                                }'`
                            )
                        } else dataCell.id = getVarietyIDByName(rowCategory, dataCell.value) // Set variety id for later use
                    }

                    if (currentLabel === "Labels") {
                        // Now join the label values properly with a comma, since it already passed special chars verification
                        dataCell.value = labelSplit.join(", ")
                    }
                }

                // Date //
                else if (TABLE_HEADER_COLUMN_DATA[colIndex + 1].type === "date") {
                    // This is a date cell, check for formatting
                    if (!isDate(dataCell.value)) {
                        // String has special characters, add error to this cell
                        setCellError(dataCell, "This cell accepts date values only; format is mm/dd/yy")
                    }
                }

                // Update the cell instance
                data[rowIndex][colIndex] = dataCell
            })
        })

        if (!data.length) {
            // Empty CSV!
            setModalTitle(`No locations detected on this file`)
            setModalSubtitle("Please add at least one location, then re-upload the CSV file")
            setReadyToUpload(false)
        } else if (errorCount) {
            // There are errors on the file
            setModalTitle(`${errorCount} Errors detected on your file`)
            setModalSubtitle("Please verify and correct each error, then re-upload the CSV file")
            setReadyToUpload(false)
        } else {
            // There are NO errors on the file
            setModalTitle(`Import ${data.length} location${data.length > 1 ? "s" : ""}`)
            setModalSubtitle("This file has no errors")
            setReadyToUpload(true)
        }
    }

    const checkUploadResults = (status) => {
        let errors = 0
        status.rows.forEach((rowStat) => {
            if (rowStat === "error") errors++
        })
        return errors
    }

    const doneUploading = (status) => {
        let totalErrors = checkUploadResults(status)
        if (!totalErrors) {
            setModalTitle(`Done importing ${data.length} location${data.length > 1 ? "s" : ""}!`)
            setModalSubtitle(
                `You can close this window and review the new location${data.length - totalErrors > 1 ? "s" : ""}`
            )
        } else {
            setModalTitle(
                `Done importing ${data.length - totalErrors} location${data.length - totalErrors > 1 ? "s" : ""}!`
            )
            setModalSubtitle(
                `There was a problem with ${totalErrors} location${
                    totalErrors > 1 ? "s" : ""
                }. You can close this window and review the new location${data.length - totalErrors > 1 ? "s" : ""}`
            )
        }
        setUploading("done uploading")
    }

    const importLocations = () => {
        setUploading(true)
        setModalTitle(`Importing location ${uploadStatus.current + 1} of ${data.length}...`)
        setModalSubtitle("Please do not close this window!")
        handleImportLocations(
            data,
            (status) => {
                setUploadStatus({ ...status })
                setModalTitle(`Importing location ${status.current + 1} of ${data.length}...`)
            },
            doneUploading
        )
    }

    const resetStates = () => {
        // Reset state so that this component is guaranteed to re-render,
        // even if the exact same CSV file gets re-uploaded
        setModalTitle("Import Locations")
        setModalSubtitle("Reading file...")
        setReadyToUpload(false)
        setUploading(false)
        setUploadStatus({ rows: [], current: 0 })
        handleCancel()
    }

    const toOverview = () => {
        const routerElement = document.getElementById("overviewForceReloadLink")
        routerElement.click()
    }

    return (
        data !== undefined && (
            <SimpleModal
                title={modalTitle}
                customStyle={{
                    topLevel:
                        "fixed flex justify-center items-center bg-black/75 w-[100%] h-[100%] top-0 left-0 z-full",
                    contentLevel: "relative rounded-lg bg-white w-[873px] h-[534px]",
                    title: "pt-[14px] pl-[20px] text-[16px] montserrat text-gray-90 font-semibold",
                }}
            >
                <button
                    className="absolute top-[11px] right-[16px] w-[20px] h-[20px]"
                    onClick={() => {
                        // Reset state so that this component is guaranteed to re-render,
                        // even if the exact same CSV file gets re-uploaded
                        resetStates()
                    }}
                >
                    <TimesIcon />
                </button>
                <div className="flex flex-col justify-start max-w-8xl mx-auto">
                    <span className="pl-[20px] mt-[7px] text-[14px] text-gray-60 roboto">{modalSubtitle}</span>
                    <div className="w-full h-[424px] mt-[10px] overflow-y-scroll">
                        <table className=" csv_table__content table-fixed border-colapse border-b border-gray-10 relative">
                            <thead className="h-[36px] bg-gray-5 text-[14px] roboto font-bold">
                                <tr>
                                    {TABLE_HEADER_COLUMN_DATA.map((element, index) => {
                                        return (
                                            <th
                                                key={`th${index}`}
                                                id={`th${index}`}
                                                className={"top-0 bg-gray-5 border border-gray-10 sticky z-[9999]"}
                                            >
                                                <div
                                                    className={
                                                        "text-[14px] overflow-hidden whitespace-nowrap " +
                                                        element.width +
                                                        element.extraStyle
                                                    }
                                                >
                                                    {element.label}
                                                </div>
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((rowData, rowIndex) => {
                                    return (
                                        <tr key={rowIndex}>
                                            <td
                                                key={`#${rowIndex}`}
                                                headers={"th0"}
                                                className={"border-r border-gray-10"}
                                            >
                                                {(!uploading ||
                                                    (uploading && uploadStatus.rows[rowIndex] === "on queue")) && (
                                                    <div
                                                        className={
                                                            "flex items-center justify-center text-[14px] text-gray-30 h-[31px] overflow-hidden whitespace-nowrap " +
                                                            TABLE_HEADER_COLUMN_DATA[0].width
                                                        }
                                                    >
                                                        {rowIndex + 1}
                                                    </div>
                                                )}
                                                {uploading && uploadStatus.rows[rowIndex] !== "on queue" && (
                                                    <div
                                                        className={
                                                            "flex items-center justify-center text-[14px] text-gray-30 h-[31px] overflow-hidden whitespace-nowrap " +
                                                            TABLE_HEADER_COLUMN_DATA[0].width
                                                        }
                                                    >
                                                        {uploadStatus.rows[rowIndex] === "waiting" && (
                                                            <span className="w-5 h-5">
                                                                <LoadingCircleIcon />
                                                            </span>
                                                        )}
                                                        {uploadStatus.rows[rowIndex] === 201 && (
                                                            <span className="w-14 h-14 ml-[8px] mt-[8px]">
                                                                <CheckmarkIcon />
                                                            </span>
                                                        )}
                                                        {uploadStatus.rows[rowIndex] === "error" && (
                                                            <span className="w-5 h-5">
                                                                <TimesIcon />
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                            {rowData.map((dataCell, colIndex) => {
                                                // Prevent extra data on the CSV from messing up rendering
                                                // -2 is because the first column of the table is the enumerating column, no data is displayed there
                                                if (colIndex > TABLE_HEADER_COLUMN_DATA.length - 2) return
                                                return dataCell.error === undefined ? (
                                                    <td
                                                        key={colIndex}
                                                        headers={`th${colIndex}`}
                                                        className={"border-r border-gray-10"}
                                                    >
                                                        <div
                                                            className={
                                                                "flex items-center text-[14px] text-gray-90 h-[31px] pl-[5px] overflow-hidden whitespace-nowrap " +
                                                                TABLE_HEADER_COLUMN_DATA[colIndex + 1].width
                                                            }
                                                        >
                                                            {dataCell.value}
                                                        </div>
                                                    </td>
                                                ) : (
                                                    <td
                                                        key={colIndex}
                                                        headers={`th${colIndex}`}
                                                        className={"border-r border-gray-10 bg-[#FCE6EA]"}
                                                    >
                                                        <SimplePopover
                                                            className={"bg-gray-90 border-none "}
                                                            tipStyle={"left-[25px] bg-black border-none "}
                                                            labelStyle="bg-black border-none max-w-[140px]"
                                                            secondaryTextStyle="text-[14px] text-white font-normal roboto"
                                                            text2={dataCell.error}
                                                        >
                                                            <div
                                                                className={
                                                                    "flex items-center text-[14px] text-[#E00028] h-[31px] pl-[5px] overflow-hidden whitespace-nowrap " +
                                                                    TABLE_HEADER_COLUMN_DATA[colIndex + 1].width
                                                                }
                                                            >
                                                                {dataCell.value}
                                                            </div>
                                                        </SimplePopover>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-[147px] h-[36px] mt-[8px] ml-[705px] mr-[13px]">
                    {!uploading && (
                        <Button
                            label="Import Locations"
                            type="primary"
                            onClick={importLocations}
                            disabled={!readyToUpload}
                        />
                    )}
                    {uploading === "done uploading" && (
                        <Button onClick={toOverview} label="Back to Overview" type="primary" />
                    )}
                </div>
                <RouterLink
                    id="overviewForceReloadLink"
                    className="hidden"
                    to={{
                        pathname: "/weather/dashboard",
                        navigationData: {
                            forceReload: true,
                            pendingToast: `${data.length} Location${data.length > 1 ? "s" : ""} imported successfully`,
                        },
                    }}
                />
            </SimpleModal>
        )
    )
}

export default BulkUploadCSVParser

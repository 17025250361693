import { useMemo, useState, useRef, useEffect, useContext } from "react"
import { CircularProgress } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"
import { convertTemperatureValueOnly } from "../../../../Util/UnitConversion"

import { AuthContext } from "../../../../Auth/Auth"
import { SettingsContext } from "../../../../Util/SettingsContext"
import networking from "../../../../Util/Networking"
import roundNumber from "../../../../helpers/roundNumber"

import {
    getHistoricalMinTemp,
    getHistoricalMaxTemp,
    getForecastMinTemp,
    getForecastMaxTemp,
    getClimMin,
    getClimMax,
    getForecastMinArr,
    getForecastMaxArr,
    getHistoricalMeanTemp,
    getForecastMeanArr,
    getForecastMeanTemp,
    getClimMean,
} from "./helper"
import {
    processUnitSystem,
    convertToLineFromBar,
    getUnit,
    trimmData,
    hourlyDataTrim,
    assembleAreaData,
    assembleLineData,
    getForecastConfidenceData,
    addMonths,
    validateData,
    convertToShadedRangesFormat,
    addDays,
    convertDataToBarStructure,
    convertAPIDataToSeasonalBarStructure,
    convertAPIDataToSeasonalBarStructureWeekly,
    convertDataToBarStructureWeekly,
    convertToDoubleCandlestick,
    convertToDoubleCandlestickWeekly,
    convertToLineFromBarWeekly,
    renderArrows,
} from "../../../../helpers/chartHelpers"
import ChartSpecs from "../ChartSpecs"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useLastUpdated from "../../../../hooks/useLastUpdated"
import { ToastContext } from "../../../../components/Toast/ToastContextProvider"

const TempChart = ({ actionsState }) => {
    let chartRef = useRef(null)
    const { currentUser } = useContext(AuthContext)
    const { currentSettings } = useContext(SettingsContext)
    const { enqueueNetworkError } = useContext(ToastContext)
    const { id } = useParams()

    const [fieldId, setFieldId] = useState()
    const [confidenceVisible, setConfidenceBarsVisibility] = useState(false)
    const [climatologyVisible, setClimatologyVisible] = useState(true)

    const [historicalPending, setHistoricalPending] = useState(false)

    const [selectedTempValue, setSelectedTempValue] = useState("maxandmin")

    // Media Queries for Tablet View
    const [tabletMedia, setTabletMedia] = useState(true)
    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)", { noSsr: true })
    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    // ******************************* BAR DATA PART ****************************

    //---------------------------  MONTHLY SEASONAL  ------------------------

    // BarData - Forecast
    const [monthlyBarForecastData, setMonthlyBarForecastData] = useState({
        time: [],
    })

    // BarData - Climatology
    const [monthlyBarClimatologyData, setMonthlyBarClimatologyData] = useState({
        time: [],
    })

    // BarData - Forecast - Vigintiles
    const [monthlyBarForecastDataVigintiles, setMonthlyBarForecastDataVigintiles] = useState({ time: [] })

    // BarData - Climatology - Vigintiles
    const [monthlyBarClimatologyDataVigintiles, setMonthlyBarClimatologyDataVigintiles] = useState({ time: [] })

    // Bar Data - Insights
    const barDataInsightsMin = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "t2m_min",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarDataMin = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
                property: "t2m_min",
            }),
        [monthlyBarForecastDataVigintiles, monthlyBarClimatologyDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsDataMin = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarClimatologyDataVigintiles,
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
                property: "t2m_min",
            }),
        [monthlyBarForecastDataVigintiles, monthlyBarClimatologyDataVigintiles]
    )

    // Bar Data - Insights
    const barDataInsightsMax = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "t2m_max",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarDataMax = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
                property: "t2m_max",
            }),
        [monthlyBarForecastDataVigintiles, monthlyBarClimatologyDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsDataMax = useMemo(
        () =>
            convertDataToBarStructure({
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
                data: monthlyBarClimatologyDataVigintiles,
                property: "t2m_max",
            }),
        [monthlyBarForecastDataVigintiles, monthlyBarClimatologyDataVigintiles]
    )

    // Bar Data - Insights - Mean
    const barDataInsightsMean = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "t2m_mean",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    const climatologyQuantilesBarsDataMean = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarClimatologyDataVigintiles,
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
                property: "t2m_mean",
            }),
        [monthlyBarForecastDataVigintiles, monthlyBarClimatologyDataVigintiles]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarDataMean = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
                property: "t2m_mean",
            }),
        [monthlyBarForecastDataVigintiles, monthlyBarClimatologyDataVigintiles]
    )

    // ---------------------- WEEKLY (SUBSEASONAL DATA PART)  ---------------------- //
    // BarData - Forecast
    const [weeklyBarForecastData, setWeeklyBarForecastData] = useState({
        time: [],
    })

    // BarData - Climatology
    const [weeklyBarClimatologyData, setWeeklyBarClimatologyData] = useState({
        time: [],
    })

    // BarData - Forecast - Vigintiles
    const [weeklyBarForecastDataVigintiles, setWeeklyBarForecastDataVigintiles] = useState({ time: [] })

    // BarData - Climatology - Vigintiles
    const [weeklyBarClimatologyDataVigintiles, setWeeklyBarClimatologyDataVigintiles] = useState({ time: [] })

    // Bar Data - Insights
    const barDataInsightsMinWeekly = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructureWeekly({
                climatology: weeklyBarClimatologyData,
                forecast: weeklyBarForecastData,
                property: "t2m_min",
            }),
        [weeklyBarForecastData, weeklyBarClimatologyData]
    )

    const barDataInsightsMeanWeekly = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructureWeekly({
                climatology: weeklyBarClimatologyData,
                forecast: weeklyBarForecastData,
                property: "t2m_mean",
            }),
        [weeklyBarForecastData, weeklyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarDataMinWeekly = useMemo(
        () =>
            convertDataToBarStructureWeekly({
                data: weeklyBarForecastDataVigintiles,
                property: "t2m_min",
            }),
        [weeklyBarForecastDataVigintiles]
    )

    const forecastQuantilesBarDataMeanWeekly = useMemo(
        () =>
            convertDataToBarStructureWeekly({
                data: weeklyBarForecastDataVigintiles,
                property: "t2m_mean",
            }),
        [weeklyBarForecastDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsDataMinWeekly = useMemo(
        () =>
            convertDataToBarStructureWeekly({
                data: weeklyBarClimatologyDataVigintiles,
                property: "t2m_min",
            }),
        [weeklyBarClimatologyDataVigintiles]
    )

    const climatologyQuantilesBarsDataMeanWeekly = useMemo(
        () =>
            convertDataToBarStructureWeekly({
                data: weeklyBarClimatologyDataVigintiles,
                property: "t2m_mean",
            }),
        [weeklyBarClimatologyDataVigintiles]
    )

    // Bar Data - Insights
    const barDataInsightsMaxWeekly = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructureWeekly({
                climatology: weeklyBarClimatologyData,
                forecast: weeklyBarForecastData,
                property: "t2m_max",
            }),
        [weeklyBarForecastData, weeklyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarDataMaxWeekly = useMemo(
        () =>
            convertDataToBarStructureWeekly({
                data: weeklyBarForecastDataVigintiles,
                property: "t2m_max",
            }),
        [weeklyBarForecastDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsDataMaxWeekly = useMemo(
        () =>
            convertDataToBarStructureWeekly({
                data: weeklyBarClimatologyDataVigintiles,
                property: "t2m_max",
            }),
        [weeklyBarClimatologyDataVigintiles]
    )

    // ******************************* END OF BAR DATA PART ****************************

    // Prepare initial data
    const weatherVariable = "temperature"
    const [data, setData] = useState({
        ds_hist: {
            time: [],
            t2m_min: [],
            t2m_max: [],
            t2m_mean: [],
        },
        ds_fc: {
            time: [],
            t2m_min: [],
            t2m_max: [],
            t2m_mean: [],
        },
        ds_clim: {
            time: [],
            t2m_min: [],
            t2m_max: [],
            t2m_mean: [],
        },
        pending: true,
    })

    // Alerts Data
    const [alertsData, setAlertsData] = useState({
        t2m_max: {},
        t2m_min: {},
    })

    const [hourlyData, setHourlyData] = useState({
        ds_hist: {
            time: [],
            t2m: [],
        },
        ds_fc: {
            time: [],
            t2m: [],
        },
    })

    // Prepare initial data
    const [monthlyData, setMonthlyData] = useState({
        ds_hist: {
            time: [],
            t2m_min: [],
            t2m_max: [],
            t2m_mean: [],
        },
        ds_fc: {
            time: [],
            t2m_min: [],
            t2m_max: [],
            t2m_mean: [],
        },
        ds_clim: {
            time: [],
            t2m_min: [],
            t2m_max: [],
            t2m_mean: [],
        },
        pending: true,
    })

    const [weeklyData, setWeeklyData] = useState({
        ds_hist: {
            time: [],
            t2m_min: [],
            t2m_max: [],
        },
        ds_fc: {
            time: [],
            t2m_min: [],
            t2m_max: [],
        },
        ds_clim: {
            time: [],
            t2m_min: [],
            t2m_max: [],
        },
        pending: true,
    })

    // Load data
    useEffect(() => {
        if (fieldId !== id || (fieldId === id && climatologyVisible && data.ds_clim.time.length === 0)) {
            // Set data status pending
            setData({
                ...data,
                pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
            })
            setMonthlyData({
                ...monthlyData,
                pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
            })

            // Load data
            currentUser.getIdToken().then((userToken) => {
                let localData = data

                if (climatologyVisible) {
                    setHistoricalPending(true)
                }
                // let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
                // if (fieldId !== id && climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
                let datasets = "history%2Cforecast%2Cclimatology"
                if (fieldId !== id && !climatologyVisible) {
                    localData = Object.assign({}, localData, {
                        ds_clim: {
                            time: [],
                            t2m_min: [],
                            t2m_max: [],
                        },
                        pending: true,
                    })
                    setData(localData)
                }

                networking
                    .get(`/api/v1/weather/${weatherVariable}/daily/${id}?datasets=${datasets}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (datasets.indexOf("climatology") !== -1) {
                            setHistoricalPending(false)
                        }
                        setData({
                            ...Object.assign({}, localData, res.data),
                            pending: false,
                        })
                    })
                    .catch(() => {
                        setData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        enqueueNetworkError()
                    })

                // Break network call if data already loaded
                if (fieldId === id && monthlyData.ds_fc.time.length) {
                    return
                }

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setMonthlyBarForecastData(res.data.ds_fc)
                        setMonthlyBarClimatologyData(res.data.ds_clim)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setMonthlyBarForecastDataVigintiles(res.data.ds_fc)
                        setMonthlyBarClimatologyDataVigintiles(res.data.ds_clim)
                        setMonthlyData(res.data)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                /*************** WEEKLY API   ********************/

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/weekly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setWeeklyBarForecastDataVigintiles(res.data.ds_fc)
                        setWeeklyBarClimatologyDataVigintiles(res.data.ds_clim)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/weekly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setWeeklyBarForecastData(res.data.ds_fc)
                        setWeeklyBarClimatologyData(res.data.ds_clim)
                        setWeeklyData(res.data)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                /*************** END OF WEEKLY NETWORK TEST   ********************/

                networking
                    .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        setAlertsData(res.data)
                    })
                    .catch(() => {
                        //         toast.warn(
                        //             `Alerts not displayed on dashboard due to internet
                        // connectivity issues. All other functions working.`
                        //         )
                    })
            })
        }

        setFieldId(id)
    }, [actionsState.selectedGranularity, actionsState.selectedVariable, currentUser, id, climatologyVisible])

    const lastUpdated = useLastUpdated(data, monthlyData, weeklyData)

    // Prepare min  temp data
    const historicalMinTemp = useMemo(() => getHistoricalMinTemp(data["ds_hist"]), [data])
    const historicalMaxTemp = useMemo(() => getHistoricalMaxTemp(data["ds_hist"]), [data])
    const historicalMeanTemp = useMemo(() => getHistoricalMeanTemp(data["ds_hist"]), [data])
    const forecastMinArr = useMemo(() => getForecastMinArr(data["ds_fc"]), [data])
    const forecastMaxArr = useMemo(() => getForecastMaxArr(data["ds_fc"]), [data])
    const forecastMeanArr = useMemo(() => getForecastMeanArr(data["ds_fc"]), [data])

    // Prepare max temp data
    const forecastMinTemp = useMemo(
        () => getForecastMinTemp(data["ds_fc"], historicalMinTemp[historicalMinTemp.length - 1], forecastMinArr),
        [data, forecastMinArr, historicalMinTemp]
    )
    const forecastMaxTemp = useMemo(
        () => getForecastMaxTemp(data["ds_fc"], historicalMaxTemp[historicalMaxTemp.length - 1], forecastMaxArr),
        [data, forecastMaxArr, historicalMaxTemp]
    )
    const forecastMeanTemp = useMemo(
        () => getForecastMeanTemp(data["ds_fc"], historicalMeanTemp[historicalMeanTemp.length - 1], forecastMeanArr),
        [data, forecastMeanArr, historicalMeanTemp]
    )
    const { climMinLighten, climMinDarken } = useMemo(() => getClimMin(data["ds_clim"]), [data])
    const { climMaxLighten, climMaxDarken } = useMemo(() => getClimMax(data["ds_clim"]), [data])
    const { climMeanLighten, climMeanDarken } = useMemo(() => getClimMean(data["ds_clim"]), [data])

    // Look at the changes for historical and forecast data and display warning messages if invalid
    useMemo(() => {
        validateData({
            diffToAlert: 15,
            historic: climMinLighten,
            forecast: forecastMinTemp,
            message: "Tmin Forecast Anomaly Detected",
        })
        validateData({
            diffToAlert: 15,
            historic: climMaxLighten,
            forecast: forecastMaxTemp,
            accessorKey: "y",
            message: "TMax Forecast Anomaly Detected",
        })
    }, [forecastMinTemp, forecastMaxTemp, climMinLighten, climMaxLighten])

    // Prepare Confidence Data
    const forecastMinConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMinTemp[historicalMinTemp.length - 1],
            data["ds_fc"]["t2m_min"],
            "0.75",
            historicalMinTemp
        )
    }, [data, historicalMinTemp])
    const forecastMinConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMinTemp[historicalMinTemp.length - 1],
            data["ds_fc"]["t2m_min"],
            "0.95",
            historicalMinTemp
        )
    }, [data, historicalMinTemp])
    const forecastMaxConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMaxTemp[historicalMaxTemp.length - 1],
            data["ds_fc"]["t2m_max"],
            "0.75",
            historicalMaxTemp
        )
    }, [data, historicalMaxTemp])
    const forecastMaxConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMaxTemp[historicalMaxTemp.length - 1],
            data["ds_fc"]["t2m_max"],
            "0.95",
            historicalMaxTemp
        )
    }, [data, historicalMaxTemp])

    const forecastMeanConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMeanTemp[historicalMeanTemp.length - 1],
            data["ds_fc"]["t2m_mean"],
            "0.75",
            historicalMeanTemp
        )
    }, [data, historicalMeanTemp])
    const forecastMeanConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMeanTemp[historicalMeanTemp.length - 1],
            data["ds_fc"]["t2m_mean"],
            "0.95",
            historicalMeanTemp
        )
    }, [data, historicalMeanTemp])

    const [barType, setBarType] = useState("candl")

    // Set chart title according to daily/monthly selection
    var chartTitle = "Min & Max Temperature"
    if (actionsState.selectedGranularity === "daily") {
        chartTitle = "Daily Surface Min & Max Temperature"
    } else if (actionsState.selectedGranularity === "monthly") {
        chartTitle = "Monthly Surface Min & Max Temperature"
    }
    const dailyMaxData = assembleLineData({
        isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
        historical: historicalMaxTemp,
        forecast: forecastMaxTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.t2m_max["0.5"][i],
            }
        }),
    })

    const dailyMaxLineDaysFromBeginning =
        (dailyMaxData.length > 0 && Math.floor((new Date().getTime() - dailyMaxData[0].x) / (24 * 60 * 60 * 1000))) +
            1 || 0

    const dailyMinData = assembleLineData({
        selectedGranularity: actionsState.selectedGranularity,
        isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
        historical: historicalMinTemp,
        forecast: forecastMinTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.t2m_min["0.5"][i],
            }
        }),
    })

    const dailyMinLineDaysFromBeginning =
        (dailyMinData.length > 0 && Math.floor((new Date().getTime() - dailyMinData[0].x) / (24 * 60 * 60 * 1000))) +
            1 || 0

    const dailyMeanData = assembleLineData({
        selectedGranularity: actionsState.selectedGranularity,
        isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
        historical: historicalMeanTemp,
        forecast: forecastMeanTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.t2m_mean["0.5"][i],
            }
        }),
    })

    const dailyMeanLineDaysFromBeginning =
        (dailyMeanData.length > 0 && Math.floor((new Date().getTime() - dailyMeanData[0].x) / (24 * 60 * 60 * 1000))) +
            1 || 0

    const dailyLineDaysFromBeginning = Math.min(
        dailyMaxLineDaysFromBeginning,
        dailyMinLineDaysFromBeginning,
        dailyMeanLineDaysFromBeginning
    )

    const monthlyMaxData = convertToLineFromBar({
        obj: forecastQuantilesBarDataMax[0.5],
        props: {
            color: "#EE6900",
            barType: barType,
            visible: false,
            date: 9,
            unit: currentSettings.units,
            unitType: "temp",
        },
    })

    const monthlyMinData = convertToLineFromBar({
        obj: forecastQuantilesBarDataMin[0.5],
        props: {
            color: "#039BE5",
            barType: barType,
            visible: false,
            unit: currentSettings.units,
            unitType: "temp",
            date: 19,
        },
    })

    const monthlyMeanData = convertToLineFromBar({
        obj: forecastQuantilesBarDataMean[0.5],
        props: {
            color: "#EE6900",
            barType: barType,
            visible: false,
            date: 9,
            unit: currentSettings.units,
            unitType: "temp",
        },
    })

    const weeklyMaxData = convertToLineFromBar({
        obj: forecastQuantilesBarDataMaxWeekly[0.5],
        props: {
            color: "#EE6900",
            barType: barType,
            visible: false,
            date: 9,
            unit: currentSettings.units,
            unitType: "temp",
        },
    })

    const weeklyMinData = convertToLineFromBar({
        obj: forecastQuantilesBarDataMinWeekly[0.5],
        props: {
            color: "#EE6900",
            barType: barType,
            visible: false,
            date: 9,
            unit: currentSettings.units,
            unitType: "temp",
        },
    })

    const weeklyMeanData = convertToLineFromBar({
        obj: forecastQuantilesBarDataMeanWeekly[0.5],
        props: {
            color: "#EE6900",
            barType: barType,
            visible: false,
            date: 9,
            unit: currentSettings.units,
            unitType: "temp",
        },
    })

    const csvDailyData = dailyMaxData.map((d, i) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })],
        [currentSettings.units === "metric" ? d.y : convertTemperatureValueOnly("metric", "imperial", d.y)],
        [
            currentSettings.units === "metric"
                ? dailyMinData[i].y
                : convertTemperatureValueOnly("metric", "imperial", dailyMinData[i].y),
        ],
    ])

    const csvMonthlyData = monthlyMaxData.points.map((d, i) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", year: "numeric" })],
        [d.y],
        [monthlyMinData.points[i].y],
    ])

    const csvWeeklyData = weeklyMaxData.points.map((d, i) => [
        [
            new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric" }) +
                " - " +
                new Date(addDays(d.x, 6)).toLocaleDateString(undefined, { month: "short", day: "numeric" }),
        ],
        [d.y],
        [weeklyMinData.points[i].y],
    ])

    const csvWeeklyMeanData = weeklyMeanData.points.map((d, i) => [
        [
            new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric" }) +
                " - " +
                new Date(addDays(d.x, 6)).toLocaleDateString(undefined, { month: "short", day: "numeric" }),
        ],
        [d.y],
    ])

    const csvDailyMeanData = dailyMeanData.map((d, i) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })],
        [currentSettings.units === "metric" ? d.y : convertTemperatureValueOnly("metric", "imperial", d.y)],
    ])

    const csvMonthlyMeanData = monthlyMeanData.points.map((d, i) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", year: "numeric" })],
        [d.y],
    ])

    return (
        <div className="weather-chart">
            <div className="weather-chart__chart-container">
                <div style={{ display: data.pending ? "flex" : "none" }} className="weather-chart__preload-container">
                    <CircularProgress />
                </div>
                <LineAreaChartComponent
                    svgHeight={650}
                    // Title text
                    title={chartTitle}
                    // Y label text
                    labelY={`Temperature in ${getUnit({ system: currentSettings.units }).tempUnit}`}
                    // Add top margin
                    marginTop={tabletMedia ? 75 : 65}
                    // Title label offset
                    titleLabelOffsetY={0}
                    // Pass unique resize event key
                    resizeEventListenerId={`${weatherVariable}-chart`}
                    // Add chart data id to filter out some update requests
                    chartDataId={
                        {
                            hourly: `hour`,
                            weekly: `weekly`,
                            daily: `month`,
                            monthly: `day`,
                        }[actionsState.selectedGranularity] +
                        "_temp-chart_" +
                        climMaxLighten.length
                    }
                    // Center Ticks
                    centerTicks={
                        {
                            hourly: false,
                            daily: false,
                            weekly: true,
                            monthly: true,
                        }[actionsState.selectedGranularity]
                    }
                    // Make chart to have dynamic y basis
                    zeroBasis={false}
                    // Convert received data to shaded ranges format
                    shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData))}
                    // Bottom margin will be 0.3 times of data diff
                    yBottomOffset={0.3}
                    // Top margin will be 0.3 times of data diff
                    yTopOffset={0.3}
                    // Hides y axis numbers
                    hideYAxisNumbers={false}
                    // Provide custom date max axis extent for monthly view charts
                    xDateMax={
                        {
                            hourly: null,
                            daily: tabletMedia ? addDays(new Date(), 14) : null,
                            weekly: addDays(
                                new Date(
                                    weeklyBarForecastDataVigintiles.time[
                                        weeklyBarForecastDataVigintiles.time.length - 1
                                    ]
                                ),
                                8
                            ),
                            monthly: addMonths(
                                new Date(
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getFullYear(),
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getMonth() + 1,
                                    1
                                ),
                                0
                            ),
                        }[actionsState.selectedGranularity]
                    }
                    // Provide custom date min axis extent for monthly view charts
                    xDateMin={
                        {
                            hourly: null,
                            weekly: addDays(new Date(weeklyBarForecastDataVigintiles.time[0]), -1),
                            daily: tabletMedia ? addDays(new Date(), -2) : null,
                            monthly: (() => {
                                const today = new Date()
                                const year = today.getFullYear()
                                const month = today.getMonth()
                                const result = new Date(year, month, 1)
                                return result
                            })(),
                        }[actionsState.selectedGranularity]
                    }
                    // How x ticks will be formatted in chart
                    xTickFormat={
                        {
                            hourly: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getHours() + "h"
                                if (i === 0) return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                                return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                            },
                            weekly: (d) => {
                                const nextDate = addDays(d, 6)
                                const dStr = d.toLocaleString(undefined, { month: "short", day: "numeric" })
                                const nextDateStr = nextDate.toLocaleString(undefined, {
                                    month: "short",
                                    day: "numeric",
                                })
                                return dStr + " - " + nextDateStr
                            },
                            daily: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getDate()
                                if (i === 0) return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                            },
                            monthly: (d, i, arr) => {
                                // Remove last, overflowing tick item
                                if (i === arr.length - 1) return ""
                                return d.toLocaleString(undefined, { month: "short" })
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Give chart tips count tip
                    xTicksCount={
                        {
                            hourly: 16,
                            daily: tabletMedia ? 10 : 30,
                            weekly: weeklyBarForecastDataVigintiles.time.length,
                            monthly: 6,
                        }[actionsState.selectedGranularity]
                    }
                    // Hide chart if data is pending
                    hide={data.pending}
                    // Tooltip content on line points mouse over
                    tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                        let hour = undefined
                        let day = "numeric"
                        let month = "short"
                        let h = ""

                        if (actionsState.selectedGranularity === "hourly") {
                            hour = "numeric"
                            day = undefined
                            month = undefined
                            h = "h"
                        }

                        if (barType === "candl" && actionsState.selectedGranularity !== "daily") {
                            day = undefined
                        }

                        return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                <tr><td style="font-weight:bold;font-size:20px" rowspan="${
                    values.length
                }"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(
                            undefined,
                            { day, month, hour }
                        )}${h}</div></td> 
                    <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                        points[0].dashed ? "dashed" : "solid"
                    } ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${roundNumber(values[0], 1)} ${
                            getUnit({ system: currentSettings.units }).tempUnit
                        }
                </td></tr>
                ${values
                    .filter((_, i) => i > 0)
                    .map((value, i) => {
                        return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                            points[i + 1].dashed ? "dashed" : "solid"
                        } ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${roundNumber(value, 1)} ${
                            getUnit({ system: currentSettings.units }).tempUnit
                        }
                </td></tr>`
                    })
                    .join("")}
            </table>`
                    }}
                    shapeTip={
                        {
                            hourly: null,
                            daily: null,
                            weekly: (EVENT, boundObj, state, point) => {
                                let resultObj = point ?? boundObj
                                let week = resultObj.key.toISOString()
                                let insights = barDataInsightsMinWeekly
                                if ((resultObj.line || boundObj).isMax) {
                                    insights = barDataInsightsMaxWeekly
                                }
                                if (selectedTempValue === "mean") insights = barDataInsightsMeanWeekly
                                if (!insights) {
                                    return ""
                                }
                                let warmer = Math.round(insights.normalMax[week])
                                let clim67 = Math.round(insights.clim67[week])
                                let normal = Math.round(insights.normal[week])
                                let clim33 = Math.round(insights.clim33[week])
                                let colder = Math.round(insights.normalMin[week])

                                if (isNaN(warmer)) {
                                    return "Still loading relevant data..."
                                }

                                let unit = "C"
                                if (currentSettings.units === "imperial") {
                                    clim33 = +convertTemperatureValueOnly("metric", "imperial", clim33)
                                    clim67 = +convertTemperatureValueOnly("metric", "imperial", clim67)
                                    unit = "F"
                                }

                                return `<div style="max-width:250px;">
                                
                                There is a ${warmer}% chance that temperatures will be warmer than usual (above ${clim67} ${unit}).
                                
                                </br>  </br> There is a ${normal}% chance that temperatures will be within the normal range (${clim33} to ${clim67} ${unit}). 
                                
                                </br>  </br>There is a ${colder}% chance that temperatures will be colder than normal (below ${clim33} ${unit}).</div>`
                            },
                            monthly: (EVENT, boundObj, state, point) => {
                                let resultObj = point ?? boundObj
                                let month = resultObj.key
                                    .toLocaleString("en-US", { month: "short", year: "numeric" }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                    .split("")
                                    .join("")
                                let insights = barDataInsightsMin
                                if ((resultObj.line || boundObj).isMax) {
                                    insights = barDataInsightsMax
                                }
                                if (selectedTempValue === "mean") insights = barDataInsightsMean
                                if (!insights) {
                                    return ""
                                }
                                let warmer = Math.round(insights.normalMax[month])
                                let clim67 = Math.round(insights.clim67[month])
                                let normal = Math.round(insights.normal[month])
                                let clim33 = Math.round(insights.clim33[month])
                                let colder = Math.round(insights.normalMin[month])

                                let unit = "C"
                                if (currentSettings.units === "imperial") {
                                    clim33 = +convertTemperatureValueOnly("metric", "imperial", clim33)
                                    clim67 = +convertTemperatureValueOnly("metric", "imperial", clim67)
                                    unit = "F"
                                }

                                return `<div style="max-width:250px;">
                                
                                There is a ${warmer}% chance that temperatures will be warmer than usual (above ${clim67} ${unit}).
                                
                                </br>  </br> There is a ${normal}% chance that temperatures will be within the normal range (${clim33} to ${clim67} ${unit}). 
                                
                                </br>  </br>There is a ${colder}% chance that temperatures will be colder than normal (below ${clim33} ${unit}).</div>`
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Chart data content
                    data={[
                        // =====================  WEEKLY   =====================
                        ["weekly"].includes(actionsState.selectedGranularity) && selectedTempValue === "maxandmin"
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBarWeekly({
                                      obj: forecastQuantilesBarDataMinWeekly[0.5],

                                      props: {
                                          color: "#039BE5",
                                          barType: barType,
                                          // date:10,
                                          visible: true,
                                          isMax: false,
                                          unit: currentSettings.units,
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsightsMinWeekly)
                                                  return {
                                                      color: "",
                                                      symbol: `
                                                        <style>
                                                        .point-loader {
                                                        border: 3px solid #f3f3f3;
                                                        border-radius: 50%;
                                                        border-top: 3px solid #3498db;
                                                        width: 10px;
                                                        height: 10px;
                                                        -webkit-animation: spin 2s linear infinite; /* Safari */
                                                        animation: spin 2s linear infinite;
                                                        }
                                                        
                                                        /* Safari */
                                                        @-webkit-keyframes spin {
                                                        0% { -webkit-transform: rotate(0deg); }
                                                        100% { -webkit-transform: rotate(360deg); }
                                                        }
                                                        
                                                        @keyframes spin {
                                                        0% { transform: rotate(0deg); }
                                                        100% { transform: rotate(360deg); }
                                                        }
                                                        </style>
                                                        
                                                        <div class="point-loader"></div>
                                                        `,
                                                  }

                                              const weekly = d.key.toISOString()
                                              const normalMax = barDataInsightsMinWeekly.normalMax[weekly]
                                              const normalMin = barDataInsightsMinWeekly.normalMin[weekly]
                                              const normal = barDataInsightsMinWeekly.normal[weekly]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        ["weekly"].includes(actionsState.selectedGranularity) && selectedTempValue === "maxandmin"
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBarWeekly({
                                      obj: forecastQuantilesBarDataMaxWeekly[0.5],
                                      props: {
                                          color: "#EE6900",
                                          barType: barType,
                                          visible: true,
                                          isMax: true,
                                          unit: currentSettings.units,
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsightsMaxWeekly)
                                                  return {
                                                      color: "",
                                                      symbol: `
                                                        <style>
                                                        .point-loader {
                                                        border: 3px solid #f3f3f3;
                                                        border-radius: 50%;
                                                        border-top: 3px solid #3498db;
                                                        width: 10px;
                                                        height: 10px;
                                                        -webkit-animation: spin 2s linear infinite; /* Safari */
                                                        animation: spin 2s linear infinite;
                                                        }
                                                        
                                                        /* Safari */
                                                        @-webkit-keyframes spin {
                                                        0% { -webkit-transform: rotate(0deg); }
                                                        100% { -webkit-transform: rotate(360deg); }
                                                        }
                                                        
                                                        @keyframes spin {
                                                        0% { transform: rotate(0deg); }
                                                        100% { transform: rotate(360deg); }
                                                        }
                                                        </style>
                                                        
                                                        <div class="point-loader"></div>
                                                        `,
                                                  }
                                              const weekly = d.key.toISOString()
                                              const normalMax = barDataInsightsMaxWeekly.normalMax[weekly]
                                              const normalMin = barDataInsightsMaxWeekly.normalMin[weekly]
                                              const normal = barDataInsightsMaxWeekly.normal[weekly]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        ["weekly"].includes(actionsState.selectedGranularity) && selectedTempValue === "mean"
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBarWeekly({
                                      obj: forecastQuantilesBarDataMeanWeekly[0.5],

                                      props: {
                                          color: "#A220B7",
                                          barType: barType,
                                          // date:10,
                                          visible: true,
                                          isMax: false,
                                          unit: currentSettings.units,
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsightsMeanWeekly)
                                                  return {
                                                      color: "",
                                                      symbol: `
                                                        <style>
                                                        .point-loader {
                                                        border: 3px solid #f3f3f3;
                                                        border-radius: 50%;
                                                        border-top: 3px solid #3498db;
                                                        width: 10px;
                                                        height: 10px;
                                                        -webkit-animation: spin 2s linear infinite; /* Safari */
                                                        animation: spin 2s linear infinite;
                                                        }
                                                        
                                                        /* Safari */
                                                        @-webkit-keyframes spin {
                                                        0% { -webkit-transform: rotate(0deg); }
                                                        100% { -webkit-transform: rotate(360deg); }
                                                        }
                                                        
                                                        @keyframes spin {
                                                        0% { transform: rotate(0deg); }
                                                        100% { transform: rotate(360deg); }
                                                        }
                                                        </style>
                                                        
                                                        <div class="point-loader"></div>
                                                        `,
                                                  }

                                              const weekly = d.key.toISOString()
                                              const normalMax = barDataInsightsMeanWeekly.normalMax[weekly]
                                              const normalMin = barDataInsightsMeanWeekly.normalMin[weekly]
                                              const normal = barDataInsightsMeanWeekly.normal[weekly]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        //=================== DOUBLE  CANDLESTICK VERSION  =========================

                        [""].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToLineFromBarWeekly({
                                  obj: forecastQuantilesBarDataMaxWeekly[0.5],
                                  props: {
                                      color: "#EE6900",
                                      barType: barType,
                                      visible: false,
                                      hours: -30,
                                      unit: currentSettings.units,
                                      unitType: "temp",
                                  },
                              })
                            : null,

                        [""].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToLineFromBarWeekly({
                                  obj: forecastQuantilesBarDataMinWeekly[0.5],
                                  props: {
                                      color: "#039BE5",
                                      barType: barType,
                                      visible: false,
                                      unit: currentSettings.units,
                                      unitType: "temp",
                                      hours: 34,
                                      // date: 19,
                                  },
                              })
                            : null,

                        ["weekly"].includes(actionsState.selectedGranularity) &&
                        barType === "candl" &&
                        selectedTempValue === "maxandmin"
                            ? convertToDoubleCandlestickWeekly({
                                  obj: [
                                      {
                                          isMax: true,
                                          values: [
                                              forecastQuantilesBarDataMaxWeekly,
                                              climatologyQuantilesBarsDataMaxWeekly,
                                          ],
                                      },
                                      {
                                          isMax: false,
                                          values: [
                                              forecastQuantilesBarDataMinWeekly,
                                              climatologyQuantilesBarsDataMinWeekly,
                                          ],
                                      },
                                  ],
                                  unit: currentSettings.units,
                                  unitType: "temp",
                                  colors: ["#EE6900", "#039BE5"],
                              })
                            : null,

                        ["weekly"].includes(actionsState.selectedGranularity) &&
                        barType === "candl" &&
                        selectedTempValue === "mean"
                            ? convertToDoubleCandlestickWeekly({
                                  obj: [
                                      {
                                          isMax: false,
                                          values: [
                                              forecastQuantilesBarDataMeanWeekly,
                                              climatologyQuantilesBarsDataMeanWeekly,
                                          ],
                                      },
                                  ],
                                  unit: currentSettings.units,
                                  unitType: "temp",
                                  colors: ["#A220B7", "#F2F3F3"],
                              })
                            : null,

                        //======================= PLOT MEDIAN AS LINE - MONTHLY ===================
                        ["monthly"].includes(actionsState.selectedGranularity) && selectedTempValue === "maxandmin"
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBar({
                                      obj: forecastQuantilesBarDataMin[0.5],

                                      props: {
                                          color: "#039BE5",
                                          barType: barType,
                                          visible: true,
                                          isMax: false,
                                          unit: currentSettings.units,
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsightsMin)
                                                  return {
                                                      color: "",
                                                      symbol: `
                                        <style>
                                        .point-loader {
                                            border: 3px solid #f3f3f3;
                                            border-radius: 50%;
                                            border-top: 3px solid #3498db;
                                            width: 10px;
                                            height: 10px;
                                            -webkit-animation: spin 2s linear infinite; /* Safari */
                                            animation: spin 2s linear infinite;
                                        }
                                        
                                        /* Safari */
                                        @-webkit-keyframes spin {
                                            0% { -webkit-transform: rotate(0deg); }
                                            100% { -webkit-transform: rotate(360deg); }
                                        }
                                        
                                        @keyframes spin {
                                            0% { transform: rotate(0deg); }
                                            100% { transform: rotate(360deg); }
                                        }
                                        </style>
                                        
                                        <div class="point-loader"></div>
                                        `,
                                                  }
                                              const month = d.key
                                                  .toLocaleString("en-US", { month: "short", year: "numeric" }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                                  .split("")
                                                  .join("")
                                              const normalMax = barDataInsightsMin.normalMax[month]
                                              const normalMin = barDataInsightsMin.normalMin[month]
                                              const normal = barDataInsightsMin.normal[month]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        ["monthly"].includes(actionsState.selectedGranularity) && selectedTempValue === "maxandmin"
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBar({
                                      obj: forecastQuantilesBarDataMax[0.5],
                                      props: {
                                          color: "#EE6900",
                                          barType: barType,
                                          visible: true,
                                          isMax: true,
                                          unit: currentSettings.units,
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsightsMax)
                                                  return {
                                                      color: "",
                                                      symbol: `
                                        <style>
                                        .point-loader {
                                            border: 3px solid #f3f3f3;
                                            border-radius: 50%;
                                            border-top: 3px solid #3498db;
                                            width: 10px;
                                            height: 10px;
                                            -webkit-animation: spin 2s linear infinite; /* Safari */
                                            animation: spin 2s linear infinite;
                                        }
                                        
                                        /* Safari */
                                        @-webkit-keyframes spin {
                                            0% { -webkit-transform: rotate(0deg); }
                                            100% { -webkit-transform: rotate(360deg); }
                                        }
                                        
                                        @keyframes spin {
                                            0% { transform: rotate(0deg); }
                                            100% { transform: rotate(360deg); }
                                        }
                                        </style>
                                        
                                        <div class="point-loader"></div>
                                        `,
                                                  }
                                              const month = d.key
                                                  .toLocaleString("en-US", { month: "short", year: "numeric" }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                                  .split("")
                                                  .join("")
                                              const normalMax = barDataInsightsMax.normalMax[month]
                                              const normalMin = barDataInsightsMax.normalMin[month]
                                              const normal = barDataInsightsMax.normal[month]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        ["monthly"].includes(actionsState.selectedGranularity) && selectedTempValue === "mean"
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBar({
                                      obj: forecastQuantilesBarDataMean[0.5],
                                      props: {
                                          color: "#A220B7",
                                          barType: barType,
                                          visible: true,
                                          isMax: false,
                                          unit: currentSettings.units,
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsightsMean)
                                                  return {
                                                      color: "",
                                                      symbol: `
                                        <style>
                                        .point-loader {
                                            border: 2px solid #A220B7;
                                            border-radius: 50%;
                                            width: 10px;
                                            height: 10px;
                                            -webkit-animation: spin 2s linear infinite; /* Safari */
                                            animation: spin 2s linear infinite;
                                        }
                                        
                                        /* Safari */
                                        @-webkit-keyframes spin {
                                            0% { -webkit-transform: rotate(0deg); }
                                            100% { -webkit-transform: rotate(360deg); }
                                        }
                                        
                                        @keyframes spin {
                                            0% { transform: rotate(0deg); }
                                            100% { transform: rotate(360deg); }
                                        }
                                        </style>
                                        
                                        <div class="point-loader"></div>
                                        `,
                                                  }
                                              const month = d.key
                                                  .toLocaleString("en-US", { month: "short", year: "numeric" }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                                  .split("")
                                                  .join("")
                                              const normalMax = barDataInsightsMean.normalMax[month]
                                              const normalMin = barDataInsightsMean.normalMin[month]
                                              const normal = barDataInsightsMean.normal[month]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        //=================== DOUBLE  CANDLESTICK VERSION  =========================

                        [""].includes(actionsState.selectedGranularity) && barType === "candl" ? monthlyMaxData : null,

                        [""].includes(actionsState.selectedGranularity) && barType === "candl" ? monthlyMinData : null,

                        ["monthly"].includes(actionsState.selectedGranularity) &&
                        barType === "candl" &&
                        selectedTempValue === "maxandmin"
                            ? convertToDoubleCandlestick({
                                  obj: [
                                      {
                                          isMax: true,
                                          values: [forecastQuantilesBarDataMax, climatologyQuantilesBarsDataMax],
                                      },
                                      {
                                          isMax: false,
                                          values: [forecastQuantilesBarDataMin, climatologyQuantilesBarsDataMin],
                                      },
                                  ],
                                  unit: currentSettings.units,
                                  unitType: "temp",
                                  colors: ["#EE6900", "#039BE5"],
                              })
                            : null,

                        ["monthly"].includes(actionsState.selectedGranularity) &&
                        barType === "candl" &&
                        selectedTempValue === "mean"
                            ? convertToDoubleCandlestick({
                                  obj: [
                                      {
                                          isMax: false,
                                          values: [forecastQuantilesBarDataMean, climatologyQuantilesBarsDataMean],
                                      },
                                  ],
                                  unit: currentSettings.units,
                                  unitType: "temp",
                                  colors: ["#A220B7", "#F2F3F3"],
                              })
                            : null,

                        // ===================== End Of Horizontal and vertical bars versions  ====================

                        ["daily"].includes(actionsState.selectedGranularity) &&
                        climatologyVisible &&
                        selectedTempValue === "maxandmin"
                            ? {
                                  type: "area",
                                  points: {
                                      daily: trimmData(climMaxLighten, dailyLineDaysFromBeginning),
                                      monthly: climMaxLighten,
                                      hourly: hourlyDataTrim(climMaxLighten),
                                  }[actionsState.selectedGranularity].map((d) =>
                                      processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                  ),
                                  color: "#F8D6C5",
                                  opacity: 0.6,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        climatologyVisible &&
                        selectedTempValue === "maxandmin"
                            ? {
                                  type: "area",
                                  points: {
                                      daily: trimmData(climMaxDarken, dailyLineDaysFromBeginning),
                                      monthly: climMaxDarken,
                                      hourly: hourlyDataTrim(climMaxDarken),
                                  }[actionsState.selectedGranularity].map((d) =>
                                      processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                  ),
                                  color: "#FDBE9D",
                                  opacity: 0.6,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        climatologyVisible &&
                        selectedTempValue === "maxandmin"
                            ? {
                                  type: "area",
                                  points: {
                                      daily: trimmData(climMinLighten, dailyLineDaysFromBeginning),
                                      monthly: climMinLighten,
                                      hourly: hourlyDataTrim(climMinLighten),
                                  }[actionsState.selectedGranularity].map((d) =>
                                      processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                  ),
                                  color: "#DBEBF5",
                                  opacity: 0.6,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        climatologyVisible &&
                        selectedTempValue === "maxandmin"
                            ? {
                                  type: "area",
                                  points: {
                                      daily: trimmData(climMinDarken, dailyLineDaysFromBeginning),
                                      monthly: climMinDarken,
                                      hourly: hourlyDataTrim(climMinDarken),
                                  }[actionsState.selectedGranularity].map((d) =>
                                      processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                  ),
                                  color: "#C6E2F1",
                                  opacity: 0.6,
                              }
                            : null,
                        // Confidence Bands
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        confidenceVisible &&
                        selectedTempValue === "maxandmin"
                            ? {
                                  type: "area",
                                  color: ["monthly"].includes(actionsState.selectedGranularity) ? "#9BC5E3" : "#039BE5",
                                  opacity: ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.4,
                                  points: assembleAreaData({
                                      areaData: forecastMinConfidence95,
                                      isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                      //climatology: duplicateMonthlyHistoricalDataForFutureSevenMonths(climMinLighten),
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y: monthlyData.ds_fc.t2m_min["0.95"][i],
                                              y0: monthlyData.ds_fc.t2m_min["0.05"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        confidenceVisible &&
                        selectedTempValue === "maxandmin"
                            ? {
                                  type: "area",
                                  color: ["monthly"].includes(actionsState.selectedGranularity) ? "#5AA0D0" : "#039BE5",
                                  points: assembleAreaData({
                                      areaData: forecastMinConfidence75,
                                      isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                      // climatology: climMinDarken,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y: monthlyData.ds_fc.t2m_min["0.75"][i],
                                              y0: monthlyData.ds_fc.t2m_min["0.25"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  opacity: ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.4,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        confidenceVisible &&
                        selectedTempValue === "maxandmin"
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastMaxConfidence95,
                                      isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                      //   climatology: duplicateMonthlyHistoricalDataForFutureSevenMonths(climMaxLighten),
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y: monthlyData.ds_fc.t2m_max["0.95"][i],
                                              y0: monthlyData.ds_fc.t2m_max["0.05"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: ["monthly"].includes(actionsState.selectedGranularity) ? "#FFBB97" : "#FF7100",
                                  opacity: ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.4,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        confidenceVisible &&
                        selectedTempValue === "maxandmin"
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastMaxConfidence75,
                                      isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                      //climatology: climMaxDarken,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y: monthlyData.ds_fc.t2m_max["0.75"][i],
                                              y0: monthlyData.ds_fc.t2m_max["0.25"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: ["monthly"].includes(actionsState.selectedGranularity) ? "#FB9769" : "#FF7100",
                                  opacity: ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.4,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) && selectedTempValue === "maxandmin"
                            ? {
                                  type: "line",
                                  id: "line-max",
                                  alwaysOnTop: true,
                                  points: dailyMaxData.map((d) =>
                                      processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                  ),
                                  color: "#FF7100",
                                  "stroke-width": 2,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) && selectedTempValue === "maxandmin"
                            ? {
                                  type: "line",
                                  id: "line-min",
                                  alwaysOnTop: true,
                                  points: dailyMinData.map((d) =>
                                      processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                  ),
                                  color: "#039BE5",
                                  "stroke-width": 2,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        climatologyVisible &&
                        selectedTempValue === "mean"
                            ? {
                                  type: "area",
                                  points: {
                                      daily: trimmData(climMeanLighten, dailyLineDaysFromBeginning),
                                      monthly: climMeanLighten,
                                      hourly: hourlyDataTrim(climMeanLighten),
                                  }[actionsState.selectedGranularity].map((d) =>
                                      processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                  ),
                                  color: "#F8F0FA",
                                  opacity: 0.7,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        climatologyVisible &&
                        selectedTempValue === "mean"
                            ? {
                                  type: "area",
                                  points: {
                                      daily: trimmData(climMeanDarken, dailyLineDaysFromBeginning),
                                      monthly: climMeanDarken,
                                      hourly: hourlyDataTrim(climMeanDarken),
                                  }[actionsState.selectedGranularity].map((d) =>
                                      processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                  ),
                                  color: "#F6E9F8",
                                  opacity: 0.7,
                              }
                            : null,
                        // Confidence Bands
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        confidenceVisible &&
                        selectedTempValue === "mean"
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastMeanConfidence95,
                                      isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                      //   climatology: duplicateMonthlyHistoricalDataForFutureSevenMonths(climMaxLighten),
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y: monthlyData.ds_fc.t2m_mean["0.95"][i],
                                              y0: monthlyData.ds_fc.t2m_mean["0.05"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: "#A220B7",
                                  opacity: 0.4,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) &&
                        confidenceVisible &&
                        selectedTempValue === "mean"
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastMeanConfidence75,
                                      isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                      //climatology: climMaxDarken,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y: monthlyData.ds_fc.t2m_mean["0.75"][i],
                                              y0: monthlyData.ds_fc.t2m_mean["0.25"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: "#A220B7",
                                  opacity: 0.4,
                              }
                            : null,
                        ["daily"].includes(actionsState.selectedGranularity) && selectedTempValue === "mean"
                            ? {
                                  type: "line",
                                  id: "line-mean",
                                  alwaysOnTop: true,
                                  points: dailyMeanData.map((d) =>
                                      processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                  ),
                                  color: "#A220B7",
                                  "stroke-width": 2,
                              }
                            : null,

                        ["hourly"].includes(actionsState.selectedGranularity)
                            ? {
                                  type: "line",
                                  id: "line-mid",
                                  alwaysOnTop: true,
                                  points: assembleLineData({
                                      selectedGranularity: actionsState.selectedGranularity,
                                      hourlyData: hourlyData,
                                      propVar: "t2m",
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: "#039BE5",
                                  "stroke-width": 2,
                              }
                            : null,
                    ]}
                ></LineAreaChartComponent>
            </div>
            <div className="weather-chart__specs-container">
                <ChartSpecs
                    type="temp"
                    barType={barType}
                    historicalPending={historicalPending}
                    chartRef={chartRef}
                    climatologyVisible={climatologyVisible}
                    confidenceVisible={confidenceVisible}
                    lastUpdated={lastUpdated}
                    selectedGranularity={actionsState.selectedGranularity}
                    handleAreasVisibilityChange={({ conf, clim }) => {
                        setConfidenceBarsVisibility(conf)
                        setClimatologyVisible(clim)
                    }}
                    handleBarTypeChange={(candlChecked) => {
                        if (candlChecked) setBarType("candl")
                        else setBarType("med")
                    }}
                    handleTempValueChange={(newTempValue) => {
                        setSelectedTempValue(newTempValue)
                    }}
                    colsArr={{
                        maxandmin: [
                            "Date",
                            `Temp. Max (${getUnit({ system: currentSettings.units }).tempUnit})`,
                            `Temp. Min (${getUnit({ system: currentSettings.units }).tempUnit})`,
                        ],
                        mean: ["Date", `Temp. Mean (${getUnit({ system: currentSettings.units }).tempUnit})`],
                    }}
                    data={{
                        csv:
                            actionsState.selectedGranularity === "daily"
                                ? { maxandmin: csvDailyData, mean: csvDailyMeanData }[selectedTempValue]
                                : actionsState.selectedGranularity === "weekly"
                                ? { maxandmin: csvWeeklyData, mean: csvWeeklyMeanData }[selectedTempValue]
                                : { maxandmin: csvMonthlyData, mean: csvMonthlyMeanData }[selectedTempValue],
                        // hourlyCsv: mergeHistoricalAndForecastData({
                        //     forecast: hourlyData.ds_fc,
                        //     historical: hourlyData.ds_hist,
                        // }),
                    }}
                    // onHourlyCsvDataTrigger={() => {
                    //     return new Promise((resolve, reject) => {
                    //         if (hourlyData.ds_fc.time.length > 0) {
                    //             setTimeout((d) => {
                    //                 const mergedData = mergeHistoricalAndForecastData({
                    //                     forecast: hourlyData.ds_fc,
                    //                     historical: hourlyData.ds_hist,
                    //                 })
                    //                 resolve(mergedData)
                    //             }, 1000)
                    //         } else {
                    //             currentUser.getIdToken().then((userToken) => {
                    //                 networking
                    //                     .get(
                    //                         `/api/v1/weather/${weatherVariable}/hourly/${id}?datasets=history%2Cforecast%2Cclimatology`,
                    //                         {
                    //                             extraHeaders: { "User-Token": userToken },
                    //                         }
                    //                     )
                    //                     .then((res) => {
                    //                         setHourlyData({
                    //                             ...res.data,
                    //                         })
                    //                         resolve(res.data)
                    //                     })
                    //                     .catch(() => {
                    //                         reject()
                    //                     })
                    //             })
                    //         }
                    //     })
                    // }}
                    actionsState={actionsState}
                />
            </div>
        </div>
    )
}

export default TempChart

import { useContext, useEffect, useRef, useState } from "react"
import useOutsideComponentClickHandler from "../../../hooks/ClickOutsideHook"
import { ToastContext } from "../../../components/Toast/ToastContextProvider"
import { Button } from "../../../components/Button"
import HorizontalSeparator from "../../../ui/HorizontalSeparator/HorizontalSeparator"
import "./AddLocationsForm.css"
import Menu from "../../../ui/Controls/Menu"
import MenuItem from "../../../ui/Controls/MenuItem"
import TimesIcon from "../../../ui/Icons/TimesIcon"
import Papa from "papaparse"
import { getUserCrops, TEMPLATE_CSV_DATA } from "../AddLocationsUtil"
import LoadingCircleIcon from "../../../ui/Icons/LoadingCircleIcon"
import { Link as RouterLink } from "react-router-dom"

const EMPTY_LOCATION = {
    name: "",
    crop: "",
    variety: "",
    cropId: -1,
    varietyId: -1,
    plantingDateP: "",
    region: "",
    shapefile: "",
    bulkUploadFile: "",
    field_center: {lat: -1, lon: -1,},
    uuid: "",
    wheather_variables: {},
    datasources: {},
    labels: [],
    triggered_alerts: {},
}
const REQUIRED_KEYS = [
    "name",
    "crop",
    "variety",
    "region",
    "lat",
    "lon"
]
const BULK_UPLOAD_FILE_EXTENSIONS = [
    ".csv",
]

const AddLocationsForm = (props) => {
    const { doShow, field, onCancel = () => {}, onUpdate = () => {},
            onSave = () => {}, pinCoords, onManualCoordChange,
            onBulkUploadFileChange, refreshBulkUploadFile } = props
    const [newLocation, setNewLocation] = useState(EMPTY_LOCATION)
    const [bulkUploadFileError, setBulkUploadFileError] = useState("")
    const [showErrors, setShowErrors] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [crops, setCrops] = useState({})
    const [uploadLocationStatus, setUploadLocationStatus] = useState("not uploading")

    const bulkUploadFileInputRef = useRef(null)

    const addEditFieldComponentRef = useOutsideComponentClickHandler(() => {
        setShowErrors(false)
        onCancel()
    })

    const { enqueueAlert } = useContext(ToastContext)

    if (pinCoords.lat !== undefined && newLocation["field_center"]["lat"] !== pinCoords.lat){
        // Map has loaded for the first time!
        // Set location's lat and lon to the pin position
        setNewLocation({
            ...newLocation,
            ["field_center"]: {lat: pinCoords.lat, lon: pinCoords.lon}
        })
    }

    useEffect(() => {
        // Gets the user crop list
        async function fetchData() {
            getUserCrops((crops) => setCrops(crops))
        }
        fetchData()
    }, [])

    useEffect(() => {
        // This gets rid of the previously uploaded CSV file when the user cancels the bulk upload operation
        if (refreshBulkUploadFile) setNewLocation({ ...newLocation, bulkUploadFile: "" })
    }, [refreshBulkUploadFile])

    const downloadTemplateCSV = () => {
        // First, get a reference to the <a> element that is being clicked
        let downloadLink = document.getElementById("csv_template_download_link")

        // Set its path to a blob that downloads the template data
        downloadLink.href = window.URL.createObjectURL(
                                new Blob( [Papa.unparse(TEMPLATE_CSV_DATA)],
                                          {type: "text/csv;charset=utf-8;"}
                                )
                            )
        
        // Set the file name by using the download attribute
        downloadLink.setAttribute("download", "Location Bulk Upload Template.csv")

        // The file will be downloaded by the browser's default <a> onclick action right after this method executes
    }

    const setupFieldData = () => {
        if (field && field.uuid) {
            setNewLocation({
                name: field.name,
                ...getCropVarietyInfo(field),
                plantingDateP: field.plantingDateP,
                region: field.region,
                shapefile: field.shapefile,
                uuid: field.uuid,
                wheather_variables: { ...field.wheather_variables },
                datasources: { ...field.datasources },
                labels: [...field.labels],
                triggered_alerts: { ...field.triggered_alerts },
            })
            setIsEditing(true)
        } else {
            setNewLocation({ ...EMPTY_LOCATION })
            setIsEditing(false)
        }
    }

    useEffect(() => {
        if (!doShow) {
            setNewLocation({ ...EMPTY_LOCATION })
            setShowErrors(false)
        } else {
            setupFieldData()
        }
    }, [doShow])

    const isValidId = (id) => {
        return id !== "" && id !== -1 && id !== "-1" && id !== null && id !== undefined
    }

    const isValidCropId = (id) => {
        return isValidId(id) && crops[id]
    }

    const isValidVarietyId = (var_id, crop_id) => {
        return isValidCropId(crop_id) && isValidId(var_id) && crops[crop_id].varieties[var_id]
    }

    const getCropVarietyInfo = (field) => {
        return {
            crop: isValidCropId(field.crop_id) ? crops[+field.crop_id].name : "",
            variety: isValidVarietyId(field.variety_id, field.crop_id) ? crops[+field.variety_id].varieties[+field.variety_id].name : "",
            cropId: isValidId(field.crop_id) ? +field.crop_id : -1,
            varietyId: isValidId(field.variety_id) ? +field.variety_id : -1,
        }
    }

    useEffect(() => {
        setupFieldData()
    }, [field])

    // Params:
    // bulkUploadFile -> File object
    // successAction -> function to execute if the bulkUploadFile passes all verifications
    function verifyBulkUploadFile(bulkUploadFile, successAction){
        // Checks that an uploaded bulkUploadFile has
        // 1. Correct file extension
        // If it passes all checks, executes successAction (this is handled by the onBulkUploadFileChange method, retrieved from props)
        setBulkUploadFileError("")

        let fileExtension = bulkUploadFile.name.match(/\.([^\.]+)$/)[1];
        
        if (BULK_UPLOAD_FILE_EXTENSIONS.includes('.' + fileExtension)){
            onBulkUploadFileChange(bulkUploadFile, successAction)
        }
        else {
            setBulkUploadFileError(`Unsuported Bulk Upload file format (.${fileExtension})`)
        }
    }

    const handleFileDrop = (event) => {
        const newBulkUploadFile = event.dataTransfer.files[0]
        verifyBulkUploadFile(newBulkUploadFile, () => setNewLocation({ ...newLocation, bulkUploadFile: newBulkUploadFile }))
        event.preventDefault()
    }

    const handleFileChange = (event) => {        
        const newBulkUploadFile = event.target.files[0]
        verifyBulkUploadFile(newBulkUploadFile, () => setNewLocation({ ...newLocation, bulkUploadFile: newBulkUploadFile }))
        event.preventDefault()
    }

    const handleDragOver = (event) => {
        event.preventDefault()
    }

    const handlePropInputChange = (value, prop) => {
        if (prop === "cropId") {
            setNewLocation({
                ...newLocation,
                [prop]: value,
                crop: crops[value].name,
                variety: "",
                varietyId: -1,
            })
        } else if (prop === "varietyId") {
            setNewLocation({
                ...newLocation,
                [prop]: value,
                variety: crops[newLocation.cropId].varieties[value].name,
            })
        } else {
            if (!["lat", "lon"].includes(prop)){
                setNewLocation({
                    ...newLocation,
                    [prop]: value,
                    })
                    return
            }
            // We are dealing with field_center prop
            let newFieldCenter = {lat: -1, lon: -1}
            if (prop === "lat"){
                // Constrain latitude to values between -90 and 90
                value = Math.min(Math.max(value, -90), 90)
                newFieldCenter = {lat: value, lon: Number(newLocation["field_center"]["lon"])}
                onManualCoordChange(newFieldCenter)
            }
            if (prop === "lon"){
                // Constrain longitude to values between -180 and 180
                value = Math.min(Math.max(value, -180), 180)
                newFieldCenter = {lat: Number(newLocation["field_center"]["lat"]), lon: value}
                onManualCoordChange(newFieldCenter)
            }
            setNewLocation({
            ...newLocation,
            ['field_center']: newFieldCenter,
            })
        }
    }

    const isReqPropValid = (prop) => {
        // For the cropId and varietyId cases
        if (newLocation[prop + "Id"] === -1) {
            return false
        }
        // For lat/lon cases 
        else if (["lat", "lon"].includes(prop)){
            if(newLocation["field_center"][prop] === "" || newLocation["field_center"][prop] === null || newLocation["field_center"][prop] === undefined || newLocation["field_center"][prop] === -1){
                return false
            }
        }
        else if (newLocation[prop] === "" || newLocation[prop] === null || newLocation[prop] === undefined || newLocation[prop] === -1) {
            return false
        }
        return true
    }

    const isFormValid = (booleanOnly = false) => {
        const result = { isValid: true, error: "" }

        for (const reqKey of REQUIRED_KEYS) {
            if (!isReqPropValid(reqKey)) {
                result.isValid = false
                result.error = result.error !== "" ? result.error + ", " + reqKey : reqKey
            }
        }

        if (isEditing && !newLocation.datasources.selected) {
            result.isValid = false
            result.error = result.error !== "" ? result.error + ", datasource" : "datasource"
        }

        return booleanOnly ? result.isValid : result
    }

    const errorAddingLocation = () => {
        setUploadLocationStatus("error")
    }

    const handleSubmit = () => {
        const { isValid, error } = isFormValid()
        if (!isValid) {
            enqueueAlert(`There is an error with: ${error}`)
            setShowErrors(true)
            return
        }
        if (isEditing) {
            onUpdate({ ...newLocation }, field)
        } else {
            onSave({ ...newLocation }, (status) => (setUploadLocationStatus(status)), doneAddingLocation, errorAddingLocation)
        }
        setShowErrors(false)
        onCancel()
    }

    const showErrorForProp = (prop) => {
        if (prop === "bulkUploadFile") return showErrors || bulkUploadFileError !== ""
        return showErrors && !newLocation[prop]
    }

    const doneAddingLocation = () => {
        // Once the single location upload is done, redirect users to the overview page
        const routerElement = document.getElementById("overviewForceReloadLink_singleUpload")
        routerElement.click()
    }

    return (
        <div
            ref={addEditFieldComponentRef}
            className={
                "fixed bottom-0 top-14 left-0 w-0 bg-white z-10 shadow-md transition-all duration-150 ease-out overflow-hidden overflow-y-auto w-[340px]"
            }
        >
            <div className="w-[302px] ml-[19px] mr-[19px] mb-[10px]">
                <h3 className="pt-[14px] text-[16px] montserrat text-gray-90 font-semibold">
                   Upload Locations
                </h3>
                <label className="block pt-[7px] text-[14px] text-gray-60 roboto">Import all locations using our <a id="csv_template_download_link" href="#" onClick={downloadTemplateCSV} className="csv_template_link">template</a></label>
                <div className="pt-[8px] pb-[27px]">
                    {newLocation.bulkUploadFile && (
                        <div
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                        className={
                            "grid place-items-center w-[302px] h-[64px] border-dashed border-2 border-gray-10 rounded-sm p-[8px] text-gray-60 text-[16px] cursor-pointer bg-gray-50 hover:bg-gray-5" +
                            (showErrorForProp("bulkUploadFile") ? " border-red-500" : "")
                        }
                        onClick={() => setNewLocation({ ...newLocation, bulkUploadFile: "" })}
                    >
                            {newLocation.bulkUploadFile.name}
                            <TimesIcon />
                        </div>
                    )}
                    {!newLocation.bulkUploadFile && (
                        <div
                            onDrop={handleFileDrop}
                            onDragOver={handleDragOver}
                            className={
                                "grid place-items-center w-[302px] h-[64px] border-dashed border-2 border-gray-10 rounded-lg p-[8px] text-gray-30 text-[16px] cursor-pointer bg-gray-50 hover:bg-gray-5" +
                                (showErrorForProp("bulkUploadFile") ? " border-red-500" : "")
                            }
                            onClick={() => bulkUploadFileInputRef.current.click()}
                        >
                            Click here or drop CSV file to upload
                        </div>
                    )}
                    <input
                        onClick={() => {
                            bulkUploadFileInputRef.current.value = null
                        }}
                        onChange={handleFileChange}
                        ref={bulkUploadFileInputRef}
                        type="file"
                        accept={BULK_UPLOAD_FILE_EXTENSIONS.join(", ")}
                        className="hidden"
                        disabled={uploadLocationStatus === "waiting"}
                    ></input>
                    {showErrorForProp("bulkUploadFile") && (
                        <span className="block text-red-500 text-[12px] pt-[1px] w-[292px] text-center">
                            {bulkUploadFileError}
                        </span>
                    )}
                </div>
                <HorizontalSeparator label={"Or"} />
                <h3 className="pt-[22px] text-[16px] montserrat text-gray-90 font-semibold">
                   Add New Location
                </h3>
                <label className="block pt-[3px] text-[14px] text-gray-30 roboto">Click on the map to drop pin</label>
                <div className="flex-col space-y-[16px] pt-[23px]">
                    <div className="flex space-x-2 max-w-full">
                        <div className="max-w-[148px]">
                            <label className="text-[14px] text-gray-60 roboto">Latitude</label>
                            <input
                                onChange={(e) => handlePropInputChange(e.target.value, "lat")}
                                value={newLocation.field_center.lat !== -1 ? newLocation.field_center.lat : ""}
                                type="number"
                                placeholder="12.34567"
                                className={"w-[149px] h-[36px] mt-[5px] border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-90 placeholder:text-gray-30"}
                                disabled={uploadLocationStatus === "waiting"}
                            ></input>
                        </div>
                        <div className="max-w-[148px]">
                            <label className="text-[14px] text-gray-60 roboto">Longitude</label>
                            <input
                                onChange={(e) => handlePropInputChange(e.target.value, "lon")}
                                value={newLocation.field_center.lon !== -1 ? newLocation.field_center.lon : ""}
                                type="number"
                                placeholder="-87.65432"
                                className={"w-[149px] h-[36px] mt-[5px] border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-90 placeholder:text-gray-30"}
                                disabled={uploadLocationStatus === "waiting"}
                            ></input>
                        </div>
                    </div>
                    <div className="">
                        <label className="text-[14px] text-gray-60 roboto">Location's Name</label>
                        <input
                            onChange={(e) => handlePropInputChange(e.target.value, "name")}
                            value={newLocation.name}
                            type="text"
                            className={"w-[305px] h-[36px] mt-[5px] border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-90 placeholder:text-gray-30"}
                            disabled={uploadLocationStatus === "waiting"}
                        ></input>
                    </div>

                    <div className="">
                        <label className="text-[14px] text-gray-60 roboto">Region</label>
                        <input
                            onChange={(e) => handlePropInputChange(e.target.value, "region")}
                            value={newLocation.region}
                            type="text"
                            className={"w-[305px] h-[36px] mt-[5px] border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-90 placeholder:text-gray-30 bg-white disabled:bg-gray-3 disabled:cursor-not-allowed cursor-pointer"}
                            disabled={uploadLocationStatus === "waiting"}
                        ></input>
                    </div>

                    <div className="">
                        <label className="text-[14px] text-gray-60 roboto">Category</label>
                        <Menu
                            text={newLocation.cropId !== -1 ? crops[newLocation.cropId].name : "Select Category"}
                            className={"left w-[305px] mt-[5px] h-[36px] text-[16px]"}
                            disabled={Object.keys(crops).length === 0 || uploadLocationStatus === "waiting"}
                            styleToApply={"text-[16px] " + ((newLocation.cropId !== -1 && uploadLocationStatus !== "waiting") ? "text-gray-90" : "text-gray-30")}
                            childrenStyle={"w-full"}
                        >
                            {Object.keys(crops).length > 0 ? (
                                <>
                                    {Object.keys(crops).map((id) => (
                                        <MenuItem label={crops[id].name}
                                                  key={id}
                                                  onClick={() => handlePropInputChange(id, "cropId")}/>
                                    ))}
                                </>
                            ) : (
                                <MenuItem label={"Select Category"} value={-1} />
                            )}
                        </Menu>
                    </div>

                    <div className="">
                        <label className="text-[14px] text-gray-60 roboto">Subcategory</label>
                        <Menu
                            text={newLocation.varietyId !== -1 ? crops[newLocation.cropId].varieties[newLocation.varietyId].name : "Select Subcategory"}
                            className={"left w-[305px] mt-[5px] h-[36px] text-[16px]"}
                            disabled={
                                !isValidCropId(newLocation.cropId) ||
                                Object.keys(crops[newLocation.cropId].varieties).length === 0 ||
                                uploadLocationStatus === "waiting"
                            }
                            styleToApply={"text-[16px] " + ((newLocation.varietyId !== -1 && uploadLocationStatus !== "waiting") ? "text-gray-90" : "text-gray-30")}
                            childrenStyle={"w-full"}
                        >
                            {isValidCropId(newLocation.cropId) ? (
                                Object.keys(crops[newLocation.cropId].varieties).length > 0 ? (
                                    <>
                                        {Object.keys(crops[newLocation.cropId].varieties).map((id) => (
                                            <MenuItem label={crops[newLocation.cropId].varieties[id].name}
                                                      key={id}
                                                      onClick={(e) => handlePropInputChange(id, "varietyId")} />
                                        ))}
                                    </>
                                ) : (
                                    <MenuItem label={"No available Subcategory!"} value={-1} />
                                )
                            ) : (
                                <MenuItem label={"Select Subcategory"} value={-1} />
                            )}
                        </Menu>
                    </div>

                    <div className="">
                        <label className="text-[14px] text-gray-60 roboto">Starting Date</label>
                        <input
                            onChange={(e) => handlePropInputChange(e.target.value, "plantingDateP")}
                            value={newLocation.plantingDateP}
                            type="date"
                            className={"w-[305px] h-[36px] mt-[5px] climateai-date-input text-[16px]"}
                            disabled={uploadLocationStatus === "waiting"}
                        ></input>
                    </div>
                </div>

                <div className="w-[305px] pt-[40px] flex flex-row items-center self-stretch space-x-3">
                    {uploadLocationStatus === "not uploading" && (
                        <Button label="Add Location" type="primary" onClick={handleSubmit} disabled={!isFormValid(true)} extraClasses="flex-1" />
                    )}
                    {uploadLocationStatus === "waiting" && (
                        <Button label={<LoadingCircleIcon />} type="primary" disabled={true} labelStyle="w-[14px] h-[14px]" extraClasses="flex-1" />  
                    )}
                    {uploadLocationStatus === "error" && (
                        <div>
                            <span>There was a problem uploading this location. Please try again</span>
                            <Button label="Add Location" type="primary" onClick={handleSubmit} disabled={!isFormValid(true)} extraClasses="flex-1" />
                        </div>
                    )}
                </div>
            </div>
            <RouterLink id="overviewForceReloadLink_singleUpload" className="hidden" to={ { pathname: "/weather/dashboard", navigationData: { forceReload: true, pendingToast: "Location added successfully"} } } />
        </div>
    )
}

export default AddLocationsForm

import { useState } from "react"
import useOutsideComponentClickHandler from "../hooks/ClickOutsideHook"
import CalendarIcon from "../ui/Icons/newIcons/CalendarIcon"
import { MONTH_NAMES, MONTH_INDEXES } from "../Util/constants"

export const shadedMonthIndexes = (startMonthIndex, endMonthIndex) => {
    if (!MONTH_INDEXES.includes(endMonthIndex)) return [startMonthIndex]
    if (!MONTH_INDEXES.includes(startMonthIndex)) return []
    if (startMonthIndex <= endMonthIndex) return MONTH_INDEXES.slice(startMonthIndex, endMonthIndex + 1)
    return [...MONTH_INDEXES.slice(0, endMonthIndex + 1), ...MONTH_INDEXES.slice(startMonthIndex, 12)]
}

const MonthPickerButton = ({ id, open, editing, placeholder, onClick }) => {
    return (
        <button
            className={[
                "border-[1px] border-gray-10 rounded-lg",
                "px-2 flex-1 h-full focus:outline-accent",
                "flex flex-row items-center justify-between",
                "hover:elevation-1 cursor-pointer",
                open && editing === id ? "border-accent" : "",
            ].join(" ")}
            onClick={() => onClick(id)}
        >
            {placeholder}
            <span className="w-5 h-5 fill-gray-60">
                <CalendarIcon />
            </span>
        </button>
    )
}

const MonthRangePicker = ({
    id = "",
    startMonthIndex,
    endMonthIndex,
    startMonthPlaceholder = "",
    endMonthPlaceholder = "",
    popupPositionClass = "right-0",
    onChange = () => {},
}) => {
    const [open, toggle] = useState(false)
    const [editing, setEditing] = useState("start")
    const [hoverTill, setHoverTill] = useState(null)
    const dropdownRef = useOutsideComponentClickHandler(() => open && toggle(false))

    const edit = (startOrEnd) => {
        setHoverTill(null)
        if (editing === startOrEnd) {
            toggle(!open)
            return
        }
        setEditing(startOrEnd)
        toggle(true)
    }

    const isHoveredRange = (index) => {
        if (hoverTill === null) return false
        if (editing === "end") {
            if (startMonthIndex === null) return false
            return shadedMonthIndexes(startMonthIndex, hoverTill).includes(index)
        }
        if (editing === "start") {
            if (endMonthIndex === null) return false
            return shadedMonthIndexes(hoverTill, endMonthIndex).includes(index)
        }
    }

    const getBGClasses = (index) => {
        const isActive =
            shadedMonthIndexes(startMonthIndex, endMonthIndex).includes(index) &&
            index !== startMonthIndex &&
            index !== endMonthIndex
        const isPossible = isHoveredRange(index) && index !== startMonthIndex && index !== endMonthIndex

        if (hoverTill === null) {
            if (index === startMonthIndex || index === endMonthIndex) return "bg-accent text-white"
            if (isActive) return "bg-accent-light text-gray-60"
            return "hover:bg-gray-5 text-gray-60"
        }
        if ((editing === "start" && index === endMonthIndex) || (editing === "end" && index === startMonthIndex)) {
            return "bg-accent text-white hover:bg-accent-dark"
        }
        if (index === startMonthIndex || index === endMonthIndex) {
            if (editing === "start") {
                if (startMonthIndex < endMonthIndex) {
                    if (hoverTill <= startMonthIndex) return "bg-accent-light text-gray-60 hover:bg-accent-medium"
                    return "bg-gray-5 hover:bg-gray-10 text-gray-30"
                }
                if (hoverTill <= startMonthIndex && hoverTill > endMonthIndex)
                    return "bg-accent-light text-gray-60 hover:bg-accent-medium"
                return "bg-gray-5 hover:bg-gray-10 text-gray-30"
            }
            if (editing === "end") {
                if (startMonthIndex < endMonthIndex) {
                    if (hoverTill >= endMonthIndex) return "bg-accent-light text-gray-60 hover:bg-accent-medium"
                    return "bg-gray-5 hover:bg-gray-10 text-gray-30"
                }
                if (hoverTill >= endMonthIndex && hoverTill < startMonthIndex)
                    return "bg-accent-light text-gray-60 hover:bg-accent-medium"
                return "bg-gray-5 hover:bg-gray-10 text-gray-30"
            }
        }
        if (isActive && isPossible) return "bg-accent-light text-gray-60 hover:bg-accent-medium"
        if (isActive) return "bg-gray-5 text-gray-30"
        if (isPossible) return "bg-gray-5 hover:bg-gray-10 text-gray-60"
        return "hover:bg-gray-5 text-gray-60"
    }

    return (
        <div className="relative min-w-0 select-none" ref={dropdownRef}>
            <div className="h-[36px] min-w-[60px] w-full flex flex-row items-center gap-2">
                <MonthPickerButton
                    id="start"
                    placeholder={startMonthPlaceholder}
                    onClick={edit}
                    open={open}
                    editing={editing}
                />
                <MonthPickerButton
                    id="end"
                    placeholder={endMonthPlaceholder}
                    onClick={edit}
                    open={open}
                    editing={editing}
                />
            </div>
            {open && (
                <div
                    className={[
                        "absolute top-full",
                        popupPositionClass,
                        "bg-white z-30 rounded-lg elevation-2",
                        "h-fit",
                        "border-[1px] border-gray-10",
                        "p-[10px]",
                    ].join(" ")}
                    onMouseLeave={() => setHoverTill(null)}
                >
                    <div className="w-full h-fit grid gap-2 grid-cols-4 grid-rows-3 col-auto">
                        {MONTH_NAMES.map((month, index) => {
                            return (
                                <button
                                    key={id + "-months-" + index}
                                    className={[
                                        "h-[32px] w-full whitespace-nowrap text-[14px] roboto",
                                        "flex flex-row items-center justify-center relative",
                                        "h-[32px] z-20 focus:outline-accent",
                                        "cursor-pointer rounded-sm",
                                        getBGClasses(index),
                                        editing === "start" && index === startMonthIndex ? "hover:text-red" : "",
                                        editing === "end" && index === endMonthIndex ? "hover:text-red" : "",
                                    ].join(" ")}
                                    onClick={() => {
                                        let indexes = [null, null]
                                        if (editing === "start") {
                                            if (index === startMonthIndex) {
                                                indexes = [null, endMonthIndex]
                                            } else {
                                                indexes = [index, endMonthIndex]
                                            }
                                        } else {
                                            if (index === endMonthIndex) {
                                                indexes = [startMonthIndex, null]
                                            } else {
                                                indexes = [startMonthIndex, index]
                                            }
                                        }
                                        onChange(indexes)
                                        if (editing === "end") toggle(false)
                                        else setEditing("end")
                                    }}
                                    onFocus={() => setHoverTill(index)}
                                    onMouseEnter={() => setHoverTill(index)}
                                >
                                    {month.slice(0, 3)}
                                </button>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default MonthRangePicker

import React from "react"

import Tooltip from "../../../ui/Tooltip"
import TimesIcon from "../../../ui/Icons/TimesIcon"

function CropTableLabelItem(props) {
    const {
        label,
        onDelete = () => null,
        compact = true,
        tooltip = true,
        deleteButton = true,
        select = false,
        onClick = () => null,
    } = props
    const { name, color } = label

    const child = (
        <div className="relative">
            <div
                className={
                    "relative flex content-start items-center w-max px-2 py-1 border rounded-sm overflow-hidden" +
                    ((compact && " max-w-[100px]") || "  max-w-[150px]") +
                    ((select && " cursor-pointer") || "")
                }
                style={{
                    borderColor: color,
                    color,
                }}
                onClick={onClick}
            >
                <div
                    className="absolute top-0 left-0 w-full h-full pointer-events-none"
                    style={{ backgroundColor: color, opacity: 0.1 }}
                />
                <div className="flex-1 overflow-hidden whitespace-nowrap truncate">{name}</div>
                {deleteButton && (
                    <button className="w-4 h-4" onClick={() => onDelete(label)}>
                        <TimesIcon fill={color} />
                    </button>
                )}
            </div>
        </div>
    )

    return (
        <>
            {tooltip && name.length > 8 ? (
                <Tooltip content={name} position="top" contentClass="mr-12">
                    {child}
                </Tooltip>
            ) : (
                child
            )}
        </>
    )
}

export default CropTableLabelItem

import { ChevronLeft } from "@material-ui/icons"
import PlusIconAlt from "../../../../ui/Icons/PlusIconAlt"

import ProtectorGlass from "./ProtectorGlass"

const AddNewItem = ({ doShow, doShowTwo = true, addItem, itemName, prevItemName, blockAddButton }) => {
    return doShow ? (
        doShowTwo ? (
            <div className="relative">
                {blockAddButton && <ProtectorGlass />}
                <div
                    className="group pl-5 pr-4 hover:bg-gray-50 flex flex-row justify-start items-center cursor-pointer"
                    onClick={addItem}
                >
                    <div className="h-16 w-[28px] ml-1 flex flex-row items-center border-b-2 border-transparent">
                        <span className="h-7 fill-accent">
                            <PlusIconAlt />
                        </span>
                    </div>
                    <div className="h-16 ml-5 flex-grow flex flex-row items-center border-b-2 border-gray-100">
                        <span className="text-md font-normal text-accent">Add new {itemName}</span>
                    </div>
                </div>
            </div>
        ) : null
    ) : (
        <div className="h-16 px-3 flex flex-row space-x-1 items-center text-gray-300">
            <ChevronLeft />
            <span className="text-sm font-normal">
                Select a {prevItemName} to add a {itemName}
            </span>
        </div>
    )
}

export default AddNewItem

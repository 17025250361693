import { useState } from "react"
import Tooltip from "../Tooltip"

function SidebarItem(props) {
    const {
        icon: IconComponent,
        nextIcon: NextIcon,
        nextIconClick = () => null,
        name,
        active,
        children,
        onClick = () => null,
        sidebarOpen,
        hasChildren,
        disabled,
    } = props

    const [open, toggle] = useState(true)

    if (hasChildren && (children.length === 0 || (children.props.items && children.props.items.length === 0)))
        return null

    return (
        <>
            <Tooltip
                content={name}
                position="right"
                doShow={!sidebarOpen}
                wrapperClass={name === "Overview" ? "ml-4" : ""}
            >
                <div
                    className={
                        "flex flex-row items-center h-[48px] pr-[14px] w-full cursor-pointer min-h-[48px]" +
                        " hover:bg-gray-5" +
                        " overflow-hidden" +
                        (active ? " text-accent font-bold fill-accent" : " fill-gray-60") +
                        (disabled && " opacity-50 pointer-events-none")
                    }
                    onClick={() => {
                        if (!sidebarOpen) {
                            // force open
                            toggle(true)
                            onClick(!active)
                            return
                        }

                        if (children) {
                            if (active) {
                                if (NextIcon) nextIconClick()
                            } else {
                                toggle(!open)
                            }
                        } else {
                            if (active) {
                                if (NextIcon) nextIconClick()
                            } else {
                                onClick(false)
                                if (NextIcon) nextIconClick()
                            }
                        }
                    }}
                >
                    <div
                        className={
                            "grow-0 shrink-0 transition-all duration-200 ease-in-out pr-[12px] pl-[20px]" +
                            (sidebarOpen ? "" : " md:px-[22px]")
                        }
                    >
                        <span
                            className={
                                "block w-[24px] h-[24px]" +
                                (active ? " text-accent font-bold fill-accent" : " fill-gray-60")
                            }
                        >
                            {IconComponent && (
                                <IconComponent
                                    fill={sidebarOpen ? " text-accent font-bold fill-accent" : " fill-gray-60"}
                                />
                            )}
                        </span>
                    </div>

                    <div className="flex flex-row items-center justify-between h-full border-transparent grow shrink-0 border-y border-b-gray-10">
                        <h2 className="text-[16px] leading-none -mb-[1px]">{name}</h2>
                        {NextIcon && active && <NextIcon active={active} />}
                    </div>
                </div>
            </Tooltip>
            {sidebarOpen && open && children}
        </>
    )
}

export default SidebarItem

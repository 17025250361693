import CropTable from "../../components/CropTable"
import PoweredBy from "../../components/PoweredBy"
import "./Dashboard.css"

export default function Dashboard() {
    const { skin } = window.dashboard
    return (
        <main className="flex flex-row w-full h-full bg-white box-border xl:justify-center">
            <div className="w-full max-w-8xl">
                <CropTable />
                {skin && <PoweredBy />}
            </div>
        </main>
    )
}

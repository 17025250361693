export default function DoubleCandleStick(optionsAvailable = {}) {
    const getOptionBoxes = () => {
        return (
            <>
                {Object.keys(optionsAvailable).map((opt, i) => {
                    let obj = optionsAvailable[opt]
                    return (
                        // The "g" tag serves the same purpose as a div,
                        // but for grouping elements inside of an <svg> tag;
                        // Using a <div> will prevent the elements inside of it of being rendered
                        <g key={"legend-opt" + i}>
                            <rect
                                x="10"
                                rx="2"
                                width="15"
                                height="15"
                                strokeWidth="2"
                                stroke={obj.color}
                                y={10 + i * 30}
                                fill={obj.fill}
                            ></rect>

                            <text y={21 + i * 30} x="36" fill="#696969">
                                {obj.title}
                            </text>
                        </g>
                    )
                })}

                <rect
                    x="10"
                    rx="2"
                    width="15"
                    height="15"
                    strokeWidth="2"
                    stroke="rgb(102, 109, 116)"
                    y={10 + Object.keys(optionsAvailable).length * 30}
                    fill="rgb(242, 243, 243)"
                ></rect>

                <text y={21 + Object.keys(optionsAvailable).length * 30} x="36" fill="#696969">
                    Historical
                </text>
            </>
        )
    }

    return (
        <div className="flex flex-row items-center">
            <div className="grow h-24">
                <svg width="100%" height="100%" style={{ fontSize: 12, fontFamily: "sans-serif" }}>
                    {getOptionBoxes()}
                </svg>
            </div>
            <div className="grow-0 h-24 w-fit -mr-4">
                <svg width="100%" height="100%" style={{ fontSize: 12, fontFamily: "sans-serif" }}>
                    <g transform="translate(0,9) ">
                        <g transform="translate(5,0) ">
                            <rect
                                rx="3"
                                width="20"
                                strokeWidth="2"
                                stroke="#B3B6BA"
                                height="40"
                                y="20"
                                fill="rgb(225,225,225)"
                            ></rect>
                            <rect width="2" x="9" height="20" fill="#B3B6BA"></rect>
                            <rect width="2" x="9" y="60" height="20" fill="#B3B6BA"></rect>
                            <rect width="20" x="0" y="39" height="2" fill="#B3B6BA"></rect>
                        </g>
                        <line x1="20" x2="50" y1="0" y2="0" stroke="#B3B6BA" strokeDasharray="1 3"></line>
                        <line x1="30" x2="50" y1="20" y2="20" stroke="#B3B6BA" strokeDasharray="1 3"></line>
                        <line x1="30" x2="50" y1="40" y2="40" stroke="#B3B6BA" strokeDasharray="1 3"></line>
                        <line x1="30" x2="50" y1="60" y2="60" stroke="#B3B6BA" strokeDasharray="1 3"></line>
                        <line x1="20" x2="50" y1="80" y2="80" stroke="#B3B6BA" strokeDasharray="1 3"></line>

                        <text y="5" x="50" fill="#696969">
                            95th
                        </text>
                        <text y="25" x="50" fill="#696969">
                            75th
                        </text>
                        <text y="45" x="50" fill="#696969">
                            50th
                        </text>
                        <text y="65" x="50" fill="#696969">
                            25th
                        </text>
                        <text y="83" x="50" fill="#696969">
                            5th
                        </text>
                    </g>
                </svg>
            </div>
        </div>
    )
}

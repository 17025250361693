import * as React from "react"

const ExpandIcon = (props) => (
    <svg
        width="100%"
        height="100%"
        className="fill-inherit"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            d="M10 21v-2H6.41l4.5-4.5-1.41-1.41-4.5 4.5V14H3v7h7Zm4.5-10.09 4.5-4.5V10h2V3h-7v2h3.59l-4.5 4.5 1.41 1.41Z"
            fill={props.fill}
        />
    </svg>
)

export default ExpandIcon

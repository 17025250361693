import { useMemo, useRef, useState, useEffect, useContext } from "react"
import { CircularProgress } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"

import { getHistoricalTemp, getForecastArr, getForecastTemp, getClim } from "./helper"
import {
    convertToShadedRangesFormat,
    getForecastConfidenceData,
    addMonths,
    validateData,
    addDays,
    assembleAreaData,
    assembleLineData,
    getSevenMonthsMarginClimData,
    convertAPIDataToSeasonalBarStructure,
    convertDataToBarStructure,
    convertToLineFromBar,
    convertToDoubleCandlestick,
    renderArrows,
    trimmData,
} from "../../../../helpers/chartHelpers"

import ChartSpecs from "../ChartSpecs"
import networking from "../../../../Util/Networking"
import { AuthContext } from "../../../../Auth/Auth"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useLastUpdated from "../../../../hooks/useLastUpdated"

const RelativeHumidityChart = ({ actionsState }) => {
    const chartRef = useRef(null)
    const { currentUser } = useContext(AuthContext)
    const { enqueueNetworkError } = useContext(AuthContext)
    const { id } = useParams()
    const [fieldId, setFieldId] = useState()

    const [confidenceVisible, setConfidenceBarsVisibility] = useState(false)
    const [climatologyVisible, setClimatologyVisible] = useState(true)

    const [historicalPending, setHistoricalPending] = useState(false)

    // Media Queries for Tablet View
    const [tabletMedia, setTabletMedia] = useState(true)
    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)", { noSsr: true })
    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    // Prepare initial data
    const weatherVariable = "relative_humidity"

    // ******************************* BAR DATA PART ****************************

    //---------------------------  MONTHLY SEASONAL - MONTHLY  ------------------------
    // BarData - Forecast
    const [monthlyBarForecastData, setMonthlyBarForecastData] = useState({
        time: [],
    })

    // BarData - Climatology
    const [monthlyBarClimatologyData, setMonthlyBarClimatologyData] = useState({
        time: [],
    })

    // BarData - Forecast - Vigintiles
    const [monthlyBarForecastDataVigintiles, setMonthlyBarForecastDataVigintiles] = useState({ time: [] })

    // BarData - Climatology - Vigintiles
    const [monthlyBarClimatologyDataVigintiles, setMonthlyBarClimatologyDataVigintiles] = useState({ time: [] })

    // Bar Data - Insights
    const barDataInsights = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "rh_mean",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
                property: "rh_mean",
            }),
        [monthlyBarForecastDataVigintiles, monthlyBarClimatologyDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarClimatologyDataVigintiles,
                timeSyncData: {
                    clim: monthlyBarClimatologyDataVigintiles,
                    for: monthlyBarForecastDataVigintiles,
                },
                property: "rh_mean",
            }),
        [monthlyBarClimatologyDataVigintiles, monthlyBarForecastDataVigintiles]
    )

    const [barType, setBarType] = useState("candl")

    // ******************************* END OF BAR DATA PART ****************************

    const [data, setData] = useState({
        ds_hist: {
            time: [],
            rh_mean: [],
        },
        ds_fc: {
            time: [],
            rh_mean: [],
        },
        ds_clim: {
            time: [],
            rh_mean: [],
        },
        pending: true,
    })

    const [hourlyData, setHourlyData] = useState({
        ds_hist: {
            time: [],
            t2m: [],
        },
        ds_fc: {
            time: [],
            t2m: [],
        },
    })

    const [monthlyData, setMonthlyData] = useState({
        ds_hist: {
            time: [],
            rh_mean: [],
        },
        ds_fc: {
            time: [],
            rh_mean: [],
        },
        ds_clim: {
            time: [],
            rh_mean: [],
        },
        pending: true,
    })
    // Alerts Data
    const [alertsData, setAlertsData] = useState({
        rh_mean: {},
    })

    // Load data
    useEffect(() => {
        if (fieldId !== id || (fieldId === id && climatologyVisible && data.ds_clim.time.length === 0)) {
            console.log("data loading set")
            setData((prevData) => ({
                ...prevData,
                pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
            }))

            let localData = data

            if (climatologyVisible) {
                setHistoricalPending(true)
            }
            // let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
            // if (climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
            let datasets = "history%2Cforecast%2Cclimatology"
            if (!climatologyVisible) {
                if (fieldId !== id) {
                    localData.pending = true
                }
                localData = Object.assign({}, localData, {
                    ds_clim: {
                        time: [],
                        rh_mean: [],
                    },
                })
                setData(localData)
            }

            currentUser.getIdToken().then((userToken) => {
                networking
                    .get(`/api/v1/weather/${weatherVariable}/daily/${id}?datasets=${datasets}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (datasets.indexOf("climatology") !== -1) {
                            setHistoricalPending(false)
                        }
                        setData({
                            ...Object.assign({}, localData, res.data),
                            pending: false,
                        })
                    })
                    .catch(() => {
                        setData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        enqueueNetworkError()
                    })

                // Break network call if data already loaded
                if (fieldId === id && localData.ds_fc.time.length) {
                    return
                }

                // networking
                //     .get(`/api/v1/weather/${weatherVariable}/monthly/${id}?datasets=history%2Cforecast`, {
                //         extraHeaders: { "User-Token": userToken },
                //     })
                //     .then((res) => {
                //         setMonthlyData({
                //             ...res.data,
                //             pending: false,
                //         })
                //     })
                //     .catch(() => {
                //         setMonthlyData((prevData) => ({
                //             ...prevData,
                //             pending: false,
                //         }))
                //         toast.warn("Could not load monthly data.")
                //     })

                networking
                    .get(`/api/v1/alertsettings/${weatherVariable}/${id}?datasets=history%2Cforecast`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        setAlertsData(res.data)
                    })
                    .catch(() => {
                        //             toast.warn(
                        //                 `Alerts not displayed on dashboard due to internet
                        //   connectivity issues. All other functions working.`
                        //             )
                    })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        console.log("seasonal terciles", res)
                        setMonthlyBarForecastData(res.data.ds_fc)
                        setMonthlyBarClimatologyData(res.data.ds_clim)
                    })
                    .catch(() => {
                        enqueueNetworkError()
                    })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setMonthlyBarForecastDataVigintiles(res.data.ds_fc)
                        setMonthlyBarClimatologyDataVigintiles(res.data.ds_clim)
                    })
                    .catch(() => {
                        //         toast.warn(
                        //             `Alerts not displayed on dashboard due to internet
                        // connectivity issues. All other functions working.`
                        //         )
                    })

                setFieldId(id)
            })
        }
    }, [currentUser, id, climatologyVisible])

    const lastUpdated = useLastUpdated(data, monthlyData)

    // Prepare historical data
    const historicalTemp = useMemo(() => getHistoricalTemp(data["ds_hist"]), [data])

    // Prepare forecast data
    const forecastArr = useMemo(() => getForecastArr(data["ds_fc"]), [data])
    const forecastTemp = useMemo(() => {
        return getForecastTemp(data["ds_fc"], forecastArr)
    }, [data, forecastArr])

    // Prepare areas data
    const { climLighten, climDarken } = useMemo(() => getClim(data["ds_clim"]), [data])

    // Prepare Confidence Data
    const forecastConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalTemp[historicalTemp.length - 1],
            data["ds_fc"]["rh_mean"],
            "0.75"
        )
    }, [data, historicalTemp])
    const forecastConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalTemp[historicalTemp.length - 1],
            data["ds_fc"]["rh_mean"],
            "0.95"
        )
    }, [data, historicalTemp])

    // Look at the changes for historical and forecast data and display warning messages if invalid
    useMemo(() => {
        validateData({
            diffToAlert: 25,
            historic: climLighten,
            forecast: forecastTemp,
            accessorKey: "y",
            message: "Forecast Anomaly Detected",
        })
    }, [forecastTemp, climLighten])

    // Prepare csv data
    const histCsvData = data["ds_hist"].time.map((item, index) => {
        return [item, data["ds_hist"]["rh_mean"][index]]
    })
    const forcCsvData = data["ds_fc"].time.map((item, index) => {
        return [item, forecastArr[index]]
    })
    const climArr = [].concat.apply([], Object.values(data["ds_clim"]["rh_mean"]))
    const climCsvData = data["ds_clim"].time.map((item, index) => {
        return [item, climArr[index]]
    })
    const combinedCsvData = (clim, forecast, historical) => {
        const csvArr = []
        let j = 0
        for (let i = 0; i < clim.length; i++) {
            if (historical[i]) {
                csvArr.push([...clim[i], [""], ...historical[i]])
            } else if (clim[i] && forecast[j]) {
                csvArr.push([...clim[i], ...forecast[j], [""]])
                j += 1
            } else if (clim[i]) {
                csvArr.push([...clim[i], [""]])
            }
        }
        return csvArr
    }

    const lineData = assembleLineData({
        isMonthly: actionsState.isMonthly,
        historical: historicalTemp,
        forecast: forecastTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.rh_mean["0.5"][i],
            }
        }),
    })

    // get ammount of days from the beginning of the line, when it's trimmed inside assembleLineData function
    // sometimes we endup with less than 12 days because of the line being icomplete from historical (see SE-1919)
    const dailyLineDaysFromBeginning =
        (lineData.length > 0 && Math.floor((new Date().getTime() - lineData[0].x) / (24 * 60 * 60 * 1000))) + 1 || 0

    const csvData = lineData.map((d) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })],
        [d.y],
    ])

    return (
        <div className="weather-chart">
            <div className="weather-chart__chart-container">
                <div style={{ display: data.pending ? "flex" : "none" }} className="weather-chart__preload-container">
                    <CircularProgress />
                </div>

                <LineAreaChartComponent
                    // Pass height externally
                    svgHeight={650}
                    // Title text
                    title="Relative Humidity"
                    yValueMax={100}
                    // Y label text
                    labelY="RH [%]"
                    // Add chart data id to filter out some update requests
                    chartDataId={
                        (actionsState.isMonthly ? "month" : "day") + "_relative-humidity_" + climLighten.length
                    }
                    // Pass unique resize event key
                    resizeEventListenerId="relative-humidity-chart"
                    // Center Ticks
                    centerTicks={
                        {
                            daily: false,
                            monthly: true,
                            hourly: false,
                        }[actionsState.selectedGranularity]
                    }
                    marginTop={76}
                    // Convert received data to shaded ranges format
                    shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData))}
                    // Make chart to have zero y basis
                    zeroBasis={true}
                    // Bottom margin will be 0.2 times of data diff
                    yBottomOffset={0.2}
                    // Top margin will be 0.3 times of data diff
                    yTopOffset={0.3}
                    // Provide custom date max axis extent for monthly view chart
                    xDateMax={
                        {
                            daily: addDays(new Date(), 14),
                            // monthly: addMonths(new Date(), 6),
                            monthly: addMonths(
                                new Date(
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getFullYear(),
                                    new Date(
                                        monthlyBarForecastDataVigintiles.time[
                                            monthlyBarForecastDataVigintiles.time.length - 1
                                        ]
                                    ).getMonth() + 1,
                                    1
                                ),
                                0
                            ),
                            hourly: null,
                        }[actionsState.selectedGranularity]
                    }
                    // Provide custom date min axis extent for monthly view charts
                    xDateMin={
                        {
                            daily: tabletMedia ? addDays(new Date(), -15) : null,
                            // monthly: addMonths(new Date(), -7),
                            monthly: (() => {
                                const today = new Date()
                                const year = today.getFullYear()
                                const month = today.getMonth()
                                const result = new Date(year, month, 1)
                                return result
                            })(),
                            hourly: null,
                        }[actionsState.selectedGranularity]
                    }
                    // How x ticks will be formatted in chart
                    xTickFormat={
                        {
                            daily: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getDate()
                                if (i === 0) return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                            },
                            // monthly: (d, i, arr) => {
                            //     // Remove last, overflowing tick item
                            //     if (i === arr.length - 1) return ""
                            //     return d.toLocaleString(undefined, { month: "short" })
                            // },
                            monthly: (d, i, arr) => {
                                // Remove last, overflowing tick item
                                if (i === arr.length - 1) return ""
                                return d.toLocaleString(undefined, { month: "short" })
                            },
                            hourly: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0) return d.getHours() + "h"
                                if (i === 0) return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                                return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Give chart tips count tip
                    xTicksCount={
                        {
                            daily: 24,
                            //monthly: 12,
                            monthly: 5,
                            hourly: 16,
                        }[actionsState.selectedGranularity]
                    }
                    // Hide chart if data is pending
                    hide={data.pending}
                    // Tooltip content on line points mouse over
                    tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                        return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
     <tr><td style="font-weight:bold;font-size:20px" rowspan="${
         values.length
     }"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(
                            undefined,
                            {
                                day: "numeric",
                                month: "short",
                            }
                        )}</div></td> 
         <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
             points[0].dashed ? "dashed" : "solid"
         } ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${Math.round(values[0])} %</td>
     </tr>
     ${values
         .filter((d, i) => i > 0)
         .map((value, i) => {
             return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                 points[i + 1].dashed ? "dashed" : "solid"
             } ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${Math.round(value)} %</td></tr>`
         })
         .join("")}
 </table>`
                    }}
                    shapeTip={
                        {
                            hourly: null,
                            daily: null,
                            monthly: (EVENT, boundObj, state, point) => {
                                let resultObj = point ?? boundObj
                                let month = resultObj.key
                                    .toLocaleString("en-US", { month: "short", year: "numeric" }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                    .split("")
                                    .join("")
                                console.log({ barDataInsights, month })
                                const prec = 100
                                const warmer = Math.round(prec * barDataInsights.normalMax[month]) / prec
                                let clim67 = Math.round(prec * barDataInsights.clim67[month]) / prec
                                const normal = Math.round(prec * barDataInsights.normal[month]) / prec
                                let clim33 = Math.round(prec * barDataInsights.clim33[month]) / prec
                                const colder = Math.round(prec * barDataInsights.normalMin[month]) / prec

                                let unit = "%"

                                // if (currentSettings.units === 'imperial') {
                                //     clim33 = +convertTemperatureValueOnly("metric", "imperial", clim33)
                                //     clim67 = +convertTemperatureValueOnly("metric", "imperial", clim67)
                                //     unit = 'F'
                                // }

                                return `<div style="max-width:250px;">There is a ${warmer}% chance that relative humidity will be higher than usual (above ${clim67} ${unit} ). </br>  </br> There is a ${normal}% chance that relative humidity will be within the normal range (${clim33} to ${clim67}  ${unit}). </br>  </br>There is a ${colder}% chance that relative humidity will be lower than normal (below ${clim33}  ${unit}).</div>`
                            },
                        }[actionsState.selectedGranularity]
                    }
                    // Chart data content
                    data={[
                        // //======================= PLOT MEDIAN AS LINE - MONTHLY===================
                        ["monthly"].includes(actionsState.selectedGranularity)
                            ? barType !== "med"
                                ? null
                                : convertToLineFromBar({
                                      obj: forecastQuantilesBarData[0.5],
                                      props: {
                                          color: "#EE6900",
                                          barType: barType,
                                          visible: true,
                                          convert: false,
                                          unit: "evapotranspiration",
                                          renderCustomPoint: (d) => {
                                              if (!barDataInsights)
                                                  return {
                                                      color: "",
                                                      symbol: `
                            <style>
                            .point-loader {
                            border: 3px solid #f3f3f3;
                            border-radius: 50%;
                            border-top: 3px solid #3498db;
                            width: 10px;
                            height: 10px;
                            -webkit-animation: spin 2s linear infinite; /* Safari */
                            animation: spin 2s linear infinite;
                            }

                            /* Safari */
                            @-webkit-keyframes spin {
                            0% { -webkit-transform: rotate(0deg); }
                            100% { -webkit-transform: rotate(360deg); }
                            }

                            @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                            }
                            </style>

                            <div class="point-loader"></div>
                            `,
                                                  }
                                              const month = d.key
                                                  .toLocaleString("en-US", {
                                                      month: "short",
                                                      year: "numeric",
                                                  }) // Gotta set it to en-US, or else this breaks when the browser is set to other languages
                                                  .split("")
                                                  .join("")
                                              const normalMax = barDataInsights.normalMax[month]
                                              const normalMin = barDataInsights.normalMin[month]
                                              const normal = barDataInsights.normal[month]
                                              const maxValue = Math.max(...[normalMax, normalMin, normal])
                                              return renderArrows(maxValue, normalMin, normal, normalMax)
                                          },
                                      },
                                  })
                            : null,

                        //=================== DOUBLE  CANDLESTICK VERSION  =========================

                        [""].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToLineFromBar({
                                  obj: forecastQuantilesBarData[0.5],
                                  props: {
                                      color: "#EE6900",
                                      barType: barType,
                                      visible: false,
                                      convert: false,
                                      unit: "evapotranspiration",
                                      //   unitType: "temp",
                                      date: 15,
                                  },
                              })
                            : null,

                        ["monthly"].includes(actionsState.selectedGranularity) && barType === "candl"
                            ? convertToDoubleCandlestick({
                                  obj: [
                                      {
                                          values: [forecastQuantilesBarData, climatologyQuantilesBarsData],
                                      },
                                  ],
                                  unit: "evapotranspiration",
                                  //   unitType: "temp",
                                  convert: false,
                                  colors: ["#EE6900", "#EE6900"],
                              })
                            : null,

                        // ===================== End Of Horizontal and vertical bars versions  ====================

                        ["daily", ""].includes(actionsState.selectedGranularity) && climatologyVisible
                            ? {
                                  type: "area",
                                  points: !actionsState.isMonthly
                                      ? trimmData(getSevenMonthsMarginClimData(climLighten), dailyLineDaysFromBeginning)
                                      : getSevenMonthsMarginClimData(climLighten),
                                  color: "#BEE2EB",
                                  opacity: 0.6,
                              }
                            : null,
                        ["daily", ""].includes(actionsState.selectedGranularity) && climatologyVisible
                            ? {
                                  type: "area",
                                  points: !actionsState.isMonthly
                                      ? trimmData(getSevenMonthsMarginClimData(climDarken), dailyLineDaysFromBeginning)
                                      : getSevenMonthsMarginClimData(climDarken),
                                  color: "#89D4E1",
                                  opacity: 0.6,
                              }
                            : null,
                        // Confidence Bands
                        ["daily", ""].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastConfidence95,
                                      isMonthly: actionsState.isMonthly,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              key: +new Date(d),
                                              y1: monthlyData.ds_fc.rh_mean["0.95"][i],
                                              y0: monthlyData.ds_fc.rh_mean["0.05"][i],
                                              max: monthlyData.ds_fc.rh_mean["0.95"][i],
                                              min: monthlyData.ds_fc.rh_mean["0.05"][i],
                                          }
                                      }),
                                  }),
                                  color: actionsState.isMonthly ? "#7fb7e6" : "#237CB5",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,

                        ["daily", ""].includes(actionsState.selectedGranularity) && confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastConfidence75,
                                      isMonthly: actionsState.isMonthly,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              key: +new Date(d),
                                              y1: monthlyData.ds_fc.rh_mean["0.75"][i],
                                              y0: monthlyData.ds_fc.rh_mean["0.25"][i],
                                              max: monthlyData.ds_fc.rh_mean["0.75"][i],
                                              min: monthlyData.ds_fc.rh_mean["0.25"][i],
                                          }
                                      }),
                                  }),
                                  color: actionsState.isMonthly ? "#4591e1" : "#237CB5",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,

                        ["daily", ""].includes(actionsState.selectedGranularity)
                            ? {
                                  type: "line",
                                  id: "line-mid",
                                  alwaysOnTop: true,
                                  points: lineData,
                                  color: "#3B77B5",
                                  "stroke-width": 2,
                              }
                            : null,
                    ]}
                ></LineAreaChartComponent>
            </div>
            <div className="weather-chart__specs-container">
                <ChartSpecs
                    type="relative-humidity"
                    barType={barType}
                    selectedGranularity={actionsState.selectedGranularity}
                    historicalPending={historicalPending}
                    chartRef={chartRef}
                    climatologyVisible={climatologyVisible}
                    confidenceVisible={confidenceVisible}
                    handleBarTypeChange={(candlChecked) => {
                        if (candlChecked) setBarType("candl")
                        else setBarType("med")
                    }}
                    lastUpdated={lastUpdated}
                    handleAreasVisibilityChange={({ conf, clim }) => {
                        setConfidenceBarsVisibility(conf)
                        setClimatologyVisible(clim)
                    }}
                    colsArr={["Date", "Value (RH)"]}
                    data={{
                        csv: csvData,
                        // hourlyCsv: mergeHistoricalAndForecastData({
                        //     forecast: hourlyData.ds_fc,
                        //     historical: hourlyData.ds_hist,
                        //     prop: "rh",
                        // }),
                    }}
                    // onHourlyCsvDataTrigger={() => {
                    //     return new Promise((resolve, reject) => {
                    //         currentUser.getIdToken().then((userToken) => {
                    //             networking
                    //                 .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                    //                     extraHeaders: { "User-Token": userToken },
                    //                 })
                    //                 .then((res) => {
                    //                     setHourlyData({
                    //                         ...res.data,
                    //                     })
                    //                     resolve(res.data)
                    //                 })
                    //                 .catch(() => {
                    //                     reject()
                    //                 })
                    //         })
                    //     })
                    // }}
                    actionsState={actionsState}
                />
            </div>
        </div>
    )
}

export default RelativeHumidityChart

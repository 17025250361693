const NotLocationPinIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M11.9228 4C14.6615 4 16.8842 6.24 16.8842 9C16.8842 10.06 16.4972 11.32 15.892 12.62L17.3705 14.11C18.2437 12.36 18.8688 10.57 18.8688 9C18.8688 5.13 15.763 2 11.9228 2C10.097 2 8.44983 2.71 7.20948 3.86L8.62845 5.29C9.50165 4.5 10.6527 4 11.9228 4ZM11.9228 6.5C11.3374 6.5 10.8015 6.71 10.3749 7.06L13.8478 10.56C14.1951 10.13 14.4035 9.59 14.4035 9C14.4035 7.62 13.2922 6.5 11.9228 6.5ZM3.39912 2.86L2 4.27L5.15546 7.45C5.04631 7.95 4.97685 8.47 4.97685 9C4.97685 14.25 11.9228 22 11.9228 22C11.9228 22 13.5799 20.15 15.2767 17.65L18.6009 21L20 19.59L3.39912 2.86ZM11.9228 18.88C9.92834 16.3 7.15987 12.14 6.98126 9.29L13.8478 16.21C13.2029 17.19 12.5281 18.1 11.9228 18.88Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default NotLocationPinIcon

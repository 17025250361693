export const convertTemperature = (originalUnits, wantedUnits, value) => {
    let baseValue = value
    if (originalUnits !== "metric") {
        baseValue = ((value - 32) * 5) / 9
    }
    if (wantedUnits === "imperial") {
        let converted = (baseValue * 9) / 5 + 32
        let formatted = converted.toFixed(1)
        return formatted + "°F"
    }
    return baseValue.toFixed(1) + "°C"
}

export const convertTemperatureValueOnly = (originalUnits, wantedUnits, value) => {
    let baseValue = parseFloat(value)
    if (originalUnits !== "metric") {
        baseValue = (value - 32) * (5 / 9)
    }
    if (wantedUnits === "imperial") {
        let converted = baseValue * (9 / 5) + 32
        let formatted = converted.toFixed(1)
        return formatted
    }
    return baseValue.toFixed(1)
}

export const convertFromCelsius = (wantedUnits, value) => {
    return convertTemperature("metric", wantedUnits, value)
}

export const convertFromFahren = (wantedUnits, value) => {
    return convertTemperature("imperial", wantedUnits, value)
}

export const convertSpeed = (originalUnits, wantedUnits, value) => {
    let baseValue = value
    if (originalUnits !== "metric") {
        baseValue = value * 1.609
    }
    if (wantedUnits === "imperial") {
        let converted = baseValue / 1.609
        let formatted = converted.toFixed(2)
        return formatted + " mi/h"
    }
    return baseValue.toFixed(2) + " k/h"
}

export const convertSpeedValueOnly = (originalUnits, wantedUnits, value) => {
    let baseValue = value
    if (originalUnits !== "metric") {
        baseValue = value * 1.609
    }
    if (wantedUnits === "imperial") {
        let converted = baseValue / 1.609
        let formatted = converted.toFixed(2)
        return formatted
    }
    return baseValue.toFixed(2)
}

export const convertSpeedWindValueOnly = (originalUnits, wantedUnits, value) => {
    let baseValue = parseFloat(value)
    if (originalUnits !== "metric") {
        baseValue = value * 1.609
    }
    if (wantedUnits === "imperial") {
        let converted = baseValue / 1.609
        let formatted = converted.toFixed(2)
        return formatted
    } else if (wantedUnits === "metric") {
        let formatted = baseValue.toFixed(2)
        return formatted
    }
    return baseValue.toFixed(1)
}

export const convertWindSpeedKmhToMph = (value) => {
    return value * 0.621371
}

export const convertFromKmH = (wantedUnits, value) => {
    return convertSpeed("metric", wantedUnits, value)
}

export const meterPerSecondToKilometerPerHour = (value) => {
    return value * 3.6
}

export const convertFromMps2MPH = (wantedUnits, value) => {
    return convertSpeedWindValueOnly("metric", wantedUnits, value)
}

export const convertFromCelsiusNum = (wantedUnits, value) => {
    let convertedTemp = convertTemperature("metric", wantedUnits, value)
    return parseInt(convertedTemp.slice(0, -2))
}

export const convertFromMPH = (wantedUnits, value) => {
    return convertSpeed("imperial", wantedUnits, value)
}

export const convertDistance = (originalUnits, wantedUnits, value, roundDecimals) => {
    let baseValue = value
    if (originalUnits !== "metric") {
        baseValue = value * 1.609
    }
    if (wantedUnits === "imperial") {
        let converted = baseValue / 1.609
        let formatted = converted.toFixed(roundDecimals) + "mi"
        return formatted
    }
    return baseValue.toFixed(roundDecimals) + "km"
}

export const convertWaterLength = (originalUnits, wantedUnits, value) => {
    let baseValue = value
    if (originalUnits !== "metric") {
        baseValue = value * 25.4
    }
    if (wantedUnits === "imperial") {
        let converted = baseValue / 25.4
        let formatted = converted.toFixed(2)
        return formatted + " in"
    }
    return baseValue.toFixed(1) + " mm"
}

export const convertWaterLengthValueOnly = (originalUnits, wantedUnits, value) => {
    let baseValue = parseFloat(value)
    if (originalUnits !== "metric") {
        baseValue = value * 25.4
    }
    if (wantedUnits === "imperial") {
        let converted = baseValue / 25.4
        let formatted = converted.toFixed(2)
        return formatted
    }
    return baseValue.toFixed(2)
}

export const convertFromMM = (wantedUnits, value) => {
    return convertWaterLength("metric", wantedUnits, value)
}

export const convertFromM = (wantedUnits, value) => {
    value = value * 1000
    let convertedRain = convertWaterLength("metric", wantedUnits, value)
    return parseFloat(convertedRain.slice(0, -2))
}

export const convertGDD = (originalUnits, wantedUnits, value) => {
    let baseValue = value
    if (wantedUnits === "imperial") {
        // Max said that the constant +32 wasn't neccesary
        let converted = (baseValue * 9) / 5 + 32
        let formatted = converted.toFixed(2)
        converted = Number(formatted)
        return converted
    }
    return Number(baseValue.toFixed(1))
}

export const convertGDDFromMetric = (wantedUnits, value) => {
    return convertGDD("metric", wantedUnits, value)
}

export const convertVolumeValueOnly = (originalUnits, wantedUnits, value) => {
    let baseValue = parseFloat(value)
    if (originalUnits !== "metric") {
        baseValue = value * 25.4 * 35.315
    }

    if (wantedUnits === "imperial") {
        let converted = baseValue / 25.4 / 35.315
        let formatted = converted.toFixed(5)
        return formatted
    }
    return baseValue.toFixed(2)
}

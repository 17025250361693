import { useState } from "react"
import Toggler from "../../../components/Toggler"

const AnalogsMapLegend = () => {
    const [open, toggle] = useState(false)

    return (
        <div
            className={[
                "absolute z-20 bottom-2 left-2",
                "roboto text-[14px] text-gray-60 leading-tight",
                "transition-all duration-200 w-[180px]",
                open ? "md:w-[400px]" : "",
            ].join(" ")}
        >
            <div
                className={[
                    "bg-white rounded-lg border-[1px] border-gray-10",
                    "flex flex-row items-center",
                    "overflow-hidden",
                    "h-full w-full py-2",
                ].join(" ")}
            >
                <div className="flex flex-col items-stretch grow-0 shrink-0 max-w-[180px] space-y-2 px-3 py-2">
                    <div className="flex flex-row items-center space-x-1">
                        <span className="shrink-0 grow-0 w-fit">
                            <img src="/map-reference-pin.png" className="w-[24px]" alt="reference pin" />
                        </span>
                        <label className="">
                            <span className="whitespace-nowrap">Reference Location</span>{" "}
                            <span className="whitespace-nowrap">(2010-2019)</span>
                        </label>
                    </div>
                    <div className="flex flex-row items-center space-x-1">
                        <span className="shrink-0 grow-0 w-fit">
                            <img src="/map-analog-pin.png" className="w-[24px]" alt="analog pin" />
                        </span>
                        <label className="">Top Analogs</label>
                    </div>
                </div>
                <div className="max-w-[210px] px-4 py-2 border-l-[1px] border-gray-10 shrink-0 hidden md:block">
                    For a detailed soil report of the found analog locations please reach out to your ClimateAi agent.
                </div>
            </div>
            <div className="absolute right-[-12px] top-1/2 -translate-y-1/2 hidden md:block">
                <Toggler open={open} toggle={toggle} openDirection="right" />
            </div>
        </div>
    )
}

export default AnalogsMapLegend

import Menu from "./Menu"
import MenuItem from "./MenuItem"
import MapPinIcon from "../Icons/MapPinIcon"

export default function LocationDropdownSelection(props) {
    const { selectedLocation, locations, setSelectedLocation, keyPrefix } = props
    return (
        <Menu
            className="left"
            icon={MapPinIcon}
            text={locations[selectedLocation]?.location?.replaceAll("_", " ") || ""}
        >
            {locations &&
                Object.values(locations)
                    .sort((a, b) => {return a.location > b.location ? 1 : -1})
                    .map((location) => (
                        <MenuItem
                            active={selectedLocation === location.id}
                            label={location.location.replaceAll("_", " ")}
                            key={`${keyPrefix}_${location.id}`}
                            onClick={() => selectedLocation !== location.id && setSelectedLocation(location.id)}
                        />
                    ))}
        </Menu>
    )
}

import { useRef, useState } from "react"
import Checkbox from "../ui/Checkbox/Checkbox"
import downloadSVG from "export-svg-with-styles"
import DownloadIcon from "../ui/Icons/newIcons/DownloadIcon"
import Tooltip from "../ui/Tooltip"
import { CSVLink } from "react-csv"
import useOutsideComponentClickHandler from "../hooks/ClickOutsideHook"
import { Button } from "./Button"
import html2canvas from "html2canvas"

export const formatFilenameFieldAndVariable = (fieldName, variableName) => {
    // RegExp to remove consecutive hypens (-) and illegal filename characters
    let textRegex = new RegExp(/\B-+|\/|\*|#|%|&|{|}|\\|'|"|<|>|\?|\$|!|:|@|\||`|\+|\||=/g)
    let formatedFilename = `${String(fieldName).replaceAll(" ", "-").replaceAll(textRegex, "")}_${String(variableName)
        .toLowerCase()
        .replaceAll("_", "")
        .replace("t2m", "")}`
    return formatedFilename
}

const downloadBlob = (blob, fileName) => {
    const fakeLink = document.createElement("a")
    fakeLink.style = "display:none;"
    fakeLink.download = fileName
    fakeLink.href = blob
    document.body.appendChild(fakeLink)
    fakeLink.click()
    document.body.removeChild(fakeLink)
    fakeLink.remove()
}

const ExportAsOption = ({ onToggle, checked, id, name }) => {
    return (
        <div
            className="flex flex-row items-center space-x-[10px] hover:translate-x-1 transition-all duration-200 cursor-pointer select-none"
            onClick={onToggle}
        >
            <Checkbox id={id} checked={checked} onChange={onToggle} status={(checked && "full") || "empty"} />
            <label className="cursor-pointer pointer-events-none whitespace-nowrap text-gray-90" htmlFor={id}>
                Export as{"\u00a0"}
                <span className="dropdown-item-text-bold">{name}</span>
            </label>
        </div>
    )
}

const initialState = {
    exportToPng: false,
    exportToCsv: false,
    visible: false,
}

const ExportButton = ({
    cols,
    data,
    isDisabled = false,
    fileName,
    wrapperClasses = "relative",
    buttonSizeClasses = "w-[36px] h-[36px]",
    csvString,
    isPNGEnabled = true,
    isCSVEnabled = true,
    idToExport = "",
}) => {
    const csvLinkRef = useRef(null)
    const [state, setState] = useState({ ...initialState })

    let width = 0
    let height = 0
    const chart = document.getElementsByClassName("svg-chart-container")[0]
    if (chart) {
        const rect = chart.getBoundingClientRect()
        width = rect.width
        height = rect.height
    }

    const handleSubmitExport = () => {
        onVisibleChange()

        if (state.exportToPng) {
            if (idToExport !== "") {
                // Wait for the export popover to hide
                setTimeout(async () => {
                    const element = document.getElementById(idToExport)
                    const canvas = await html2canvas(element, {
                        backgroundColor: "lightgray",
                    })
                    const image = canvas.toDataURL("image/png", 1.0)
                    downloadBlob(image, fileName + ".png")
                }, 200)
            } else {
                downloadSVG({
                    width: width * 3,
                    height: height * 3,
                    svg: chart,
                    filename: fileName,
                })
            }
        }
        if (state.exportToCsv) {
            if (csvString) {
                const dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(csvString)
                downloadBlob(dataStr, fileName + ".csv")
            } else {
                csvLinkRef.current.link.click()
            }
        }

        setState({ ...initialState })
    }

    function onVisibleChange() {
        setState({
            ...state,
            visible: !state.visible,
        })
    }

    const wrapperRef = useOutsideComponentClickHandler(() => {
        if (state.visible) setState({ ...state, visible: false })
    })

    return (
        <div className={"hidden lg:block " + wrapperClasses} ref={wrapperRef}>
            {data.csv && (
                <button
                    className={[
                        "flex items-center justify-center relative group shrink-0",
                        "roboto font-normal text-[16px]",
                        buttonSizeClasses,
                    ].join(" ")}
                    onClick={onVisibleChange}
                >
                    <div className="h-[24px] w-[24px] group-hover:scale-[108%] fill-gray-60">
                        <DownloadIcon />
                    </div>
                    {!state.visible && <Tooltip content="Export" wrapperClass="mt-4 mr-5" contentClass="mr-6" />}
                </button>
            )}
            {state.visible && (
                <div className="absolute right-0 bg-white border-[1px] border-gray-10 w-fit p-4 rounded-lg z-full flex flex-col space-y-[12px] roboto text-[16px] font-normal elevation-2 overflow-hidden">
                    {isPNGEnabled && (
                        <ExportAsOption
                            onToggle={() => setState({ ...state, exportToPng: !state.exportToPng })}
                            checked={state.exportToPng}
                            id="exportToPng"
                            name="PNG"
                        />
                    )}
                    {isCSVEnabled && (
                        <ExportAsOption
                            onToggle={() => setState({ ...state, exportToCsv: !state.exportToCsv })}
                            checked={state.exportToCsv}
                            id="exportToCsv"
                            name="CSV"
                        />
                    )}
                    <CSVLink
                        ref={csvLinkRef}
                        data={data.csv}
                        headers={cols.csv}
                        className="hidden-link"
                        filename={`${fileName}.csv`}
                    />

                    <Button
                        extend={true}
                        label="Export Now"
                        onClick={handleSubmitExport}
                        disabled={(!state.exportToCsv && !state.exportToPng) || isDisabled}
                    />
                </div>
            )}
        </div>
    )
}

export default ExportButton

import React, { useState, useMemo, useEffect } from "react"
import useOutsideComponentClickHandler from "../../../hooks/ClickOutsideHook"
import FixedLayer from "../../../ui/FixedLayer/FixedLayer"
import TrashAltIcon from "../../../ui/Icons/TrashAltIcon"
import ColorPicker from "../../../ui/Table/ColorPicker"
import DebounceSearchInput from "../../DebounceSearchInput/DebounceSearchInput"
import CropTableLabelItem from "./CropTableLabelItem"
import { normalizeString } from "../../../helpers/wordHelper"

function CropTableLabelsForm(props) {
    const { labels = [], onDeleteLabel = () => null, onLabelFields = () => null, rowData = {} } = props

    const [showColorPicker, toggleColorPicker] = useState(false)

    const [inputValue, setInputValue] = useState("")
    const DEFAULT_COLOR = "var(--color-primary)"
    const [selectedColor, setSelectedColor] = useState(DEFAULT_COLOR)

    const ref = useOutsideComponentClickHandler(() => showColorPicker && toggleColorPicker(false))

    const isExactMatch = useMemo(() => {
        const normInput = normalizeString(inputValue)
        return labels.find(({ name }) => normalizeString(name) === normInput)
    }, [labels, inputValue])

    useEffect(() => {
        if (isExactMatch) {
            setSelectedColor(isExactMatch.color)
        } else {
            setSelectedColor(DEFAULT_COLOR)
        }
    }, [isExactMatch])

    const _labels =
        labels.length > 0 &&
        labels
            .filter((label) => label?.name?.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))
            .map((label) => {
                return (
                    <div
                        key={`label_${label?.name}_${label.color}`}
                        className="flex justify-between p-1 flex-start hover:bg-gray-5 px-3 last-of-type:pb-3 group"
                    >
                        <CropTableLabelItem
                            label={label}
                            compact={false}
                            tooltip={false}
                            deleteButton={false}
                            select
                            onClick={() => {
                                onLabelFields(label, [rowData.uuid])
                            }}
                        />
                        <button
                            className="btn w-[20px] h-[20px] hidden group-hover:block"
                            onClick={() => onDeleteLabel(label)}
                        >
                            <TrashAltIcon />
                        </button>
                    </div>
                )
            })

    return (
        <>
            <div className="w-[220px] border border-gray-10 shadow-md rounded-sm bg-white max-h-[200px] overflow-y-scroll">
                <div className="sticky top-0 bg-white z-10 p-3">
                    <DebounceSearchInput
                        onSearch={(text) => setInputValue(text)}
                        onSubmit={(text) => text && onLabelFields({ name: text, color: selectedColor }, [rowData.uuid])}
                        autoFocus
                        ms={200}
                        placeholder="Search label"
                        disallowedCharacters={[".", ",", "$", "#", "[", "]", "/", "{", "}"]}
                        renderIcon={() => {
                            return (
                                <>
                                    <button
                                        className="outline-none border-none p-0 cursor-pointer relative w-4 h-4"
                                        onClick={() => toggleColorPicker(!showColorPicker)}
                                        ref={ref}
                                    >
                                        <div
                                            className="w-4 h-4 rounded-sm"
                                            style={{ backgroundColor: selectedColor }}
                                        />
                                        {showColorPicker && !isExactMatch && (
                                            <FixedLayer xPosition="right" yPosition="top">
                                                <div className="border border-gray-10 shadow-md rounded-sm bg-white max-h-[200px] flex w-[100px] h-auto p-3">
                                                    <ColorPicker
                                                        value={selectedColor}
                                                        colors={[
                                                            ["var(--color-primary)", "#155ECC", "#E13B9F"],
                                                            ["#A220B7", "#1C9690", "#E8A500"],
                                                            ["#77BC40", "#E00028", "#039BE5"],
                                                        ]}
                                                        onColorSelect={(value) => {
                                                            setSelectedColor(value)
                                                        }}
                                                    />
                                                </div>
                                            </FixedLayer>
                                        )}
                                    </button>
                                </>
                            )
                        }}
                    />
                    <label className="ml-[-8px] text-[11px] roboto italic text-gray-30">
                        Some special chars are not allowed
                    </label>
                </div>

                {(_labels.length > 0 && _labels) || (
                    <p className="px-3 pb-3 text-left">
                        No labels found. Press <i>Enter</i> to create a new one
                    </p>
                )}
            </div>
        </>
    )
}

export default CropTableLabelsForm

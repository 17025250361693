import "./PoweredBy.css"

export default function PoweredBy() {
    return (
        <div className="powered-by">
            <div className="powered-by__tag">
                <div>Powered by</div>
                <a href="https://www.climate.ai" target="_blank" className="ml-1">
                    <img alt="ClimateAI logo" src="/Climate-AI-Color-Logo.png" />
                </a>
            </div>
        </div>
    )
}


import networking from "./Networking"

function getUserAlerts(userToken) {
    return networking.get("/api/v1/alertsettings/", {
        extraHeaders: { "User-Token": userToken },
    })
}

function deleteAlert(userToken, path) {
    return networking.delete("/api/v1/alertsettings/", path, {
        extraHeaders: { "User-Token": userToken },
    })
}

function updateAlert(userToken, path, alert_data) {
    let alertObj = { path, alert_data }

    return networking.put("/api/v1/alertsettings/", alertObj, {
        extraHeaders: { "User-Token": userToken },
    })
}

function updateStackedAlert(userToken, path, alert_data) {
    let alertObj = { path, alert_data }
    console.log("AlertObj = ", alertObj)
    return networking.put("/api/v1/alertsettings/stacked", alertObj, {
        extraHeaders: { "User-Token": userToken },
    })
}

function setNewAlert(userToken, path, alert_data) {
    let alertObj = { path, alert_data }

    return networking.post("/api/v1/alertsettings/", alertObj, {
        extraHeaders: { "User-Token": userToken },
    })
}

function setNewStackedAlert(userToken, path, alert_data) {
    let alertObj = { path, alert_data }

    return networking.post("/api/v1/alertsettings/stacked", alertObj, {
        extraHeaders: { "User-Token": userToken },
    })
}

function updateNotifyVia(userToken, notifyViaBooleans) {
    return networking.post("/api/v1/alertsettings/notify_via", notifyViaBooleans, {
        extraHeaders: { "User-Token": userToken },
    })
}

function getCropVarietyConfigs(userToken, crop, variety) {
    return networking.get(`/api/v1/alertsettings/file/${crop}/${variety}`, {
        extraHeaders: { "User-Token": userToken },
    })
}

function updateCropVarietyConfigs(userToken, crop, variety, configs) {
    return networking.put(
        `/api/v1/alertsettings/file/${crop}/${variety}`,
        { configs },
        {
            extraHeaders: { "User-Token": userToken },
        }
    )
}

export {
    getUserAlerts,
    deleteAlert,
    updateAlert,
    updateStackedAlert,
    setNewAlert,
    setNewStackedAlert,
    updateNotifyVia,
    getCropVarietyConfigs,
    updateCropVarietyConfigs,
}

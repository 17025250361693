import _ from "lodash"
import SearchIcon from "../../ui/Icons/SearchIcon"

const DebounceSearchInput = ({
    onSearch = () => {},
    onSubmit = () => null,
    ms = 500,
    renderIcon = () => null,
    autoFocus = false,
    placeholder = "Search",
    onFocusOrTypeOrClick = () => {},
    inputRef = null,
    canAutocomplete = true,
    disallowedCharacters = [],
}) => {
    const debounceSearch = _.debounce(onSearch, ms)

    return (
        <div className="min-w-[100px] flex flex-row items-center border border-gray-10 rounded-lg h-[36px] w-full px-[6px] relative overflow-hidden">
            <span
                className={
                    "h-[20px] w-[20px] z-10 grow-0 shrink-0 " + (renderIcon() !== null ? "" : "pointer-events-none")
                }
            >
                {(renderIcon && renderIcon()) || <SearchIcon />}
            </span>
            <input
                type="text"
                placeholder={placeholder}
                autoFocus={autoFocus}
                className="absolute inset-0 pl-8 pr-2 rounded-lg focus:outline-none placeholder:text-gray-30"
                onKeyUp={(e) => {
                    let sanitizedInput = e.target.value
                    disallowedCharacters.forEach((char) => {
                        sanitizedInput = sanitizedInput.replaceAll(char, "")
                    })
                    e.target.value = sanitizedInput
                    if (e.key === "Enter") {
                        onSearch(sanitizedInput)
                        onSubmit(sanitizedInput)
                    } else debounceSearch(sanitizedInput)
                    onFocusOrTypeOrClick()
                }}
                onFocus={onFocusOrTypeOrClick}
                onClick={onFocusOrTypeOrClick}
                ref={inputRef}
                autoComplete={canAutocomplete ? "on" : "off"}
            />
        </div>
    )
}

export default DebounceSearchInput

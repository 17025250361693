import { useMemo } from "react"

function getTableData(plots = [], data = {}) {
    return plots.map((plot) => {
        const result = {
            id: plot.id,
            decades: data[plot.id]?.decades || {},
            location: plot.location.replaceAll("_", " "),
            region: plot.country,
            crop: plot.crop,
            variety: plot.variety,
        }

        // add decades
        if (data[plot.id]) {
            data[plot.id].decades &&
                Object.keys(data[plot.id].decades).forEach((key) => {
                    const yield_impact = data[plot.id].decades[key].yield_impact
                    result[`overview_${key}`] = yield_impact
                })
        }

        return result
    })
}

export default function useOverviewData(plots, data) {
    return useMemo(() => {
        let tableData = {}

        // Table data
        tableData = getTableData(plots, data)

        return {
            tableData,
        }
    }, [plots, data])
}

const GranularityButton = ({ isSelected, onClick, hasAlert, label, isLeft, isRight, isPossible, hasActiveAlert }) => {
    return (
        <button
            className={[
                "p-2 px-3 border-[1px] transition-all duration-200 relative -ml-[1px]",
                "roboto text-[16px] text-center",
                isSelected
                    ? "border-accent-medium text-accent bg-accent-light z-50"
                    : isPossible
                    ? "border-gray-10 text-gray-60 bg-white z-40 hover:text-gray-90 hover:elevation-1"
                    : "border-gray-10 text-gray-30 bg-gray-5/50 z-40 cursor-not-allowed",
                isLeft ? "rounded-l-lg" : "",
                isRight ? "rounded-r-lg" : "",
            ].join(" ")}
            onClick={() => (isPossible ? onClick() : null)}
        >
            {label}
            {hasAlert && isPossible && (
                <div
                    className={
                        "absolute top-1 right-1 w-[6px] h-[6px] rounded-full " +
                        (hasActiveAlert ? "bg-red" : "bg-gray-30/50")
                    }
                ></div>
            )}
        </button>
    )
}

export default GranularityButton

import ChevronArrow from "../ui/Icons/newIcons/ChevronArrow"

const GenericPageHeader = ({ pageTitle, pageSubtitle }) => {
    return (
        <div className="px-4 py-4 pt-6 font-semibold montserrat text-[16px] text-gray-90 flex flex-row items-center justify-start space-x-1">
            <div className="h-6 flex items-center">{pageTitle}</div>
            {pageSubtitle && pageSubtitle !== "" && (
                <>
                    <span className="w-6 transform -rotate-90 fill-gray-60">
                        <ChevronArrow />
                    </span>
                    <div className="roboto font-normal text-gray-60">{pageSubtitle}</div>
                </>
            )}
        </div>
    )
}

export default GenericPageHeader

import MapLayersIcon from "../../../ui/Icons/newIcons/MapLayersIcon"
import PlusIcon from "../../../ui/Icons/newIcons/PlusIcon"
import MinusIcon from "../../../ui/Icons/newIcons/MinusIcon"

const CustomMapControls = ({ map, toggleLayer, canToggleMapStyle = true }) => {
    return (
        <div className="absolute z-20 bottom-2 right-2">
            <div className="cursor-pointer rounded-lg w-[36px] flex flex-col justify-center items-center overflow-hidden border-[1px] border-gray-10 divide-y-[1px] divide-gray-10">
                <div
                    className="w-full h-[36px] bg-white hover:bg-gray-10 flex justify-center items-center"
                    onClick={() => {
                        map.zoomIn()
                    }}
                >
                    <span className="w-[24px] h-[24px] fill-gray-60">
                        <PlusIcon />
                    </span>
                </div>
                <div
                    className="w-full h-[36px] bg-white hover:bg-gray-10 flex justify-center items-center"
                    onClick={() => {
                        map.zoomOut()
                    }}
                >
                    <span className="w-[24px] h-[24px] fill-gray-60">
                        <MinusIcon />
                    </span>
                </div>
            </div>
            {canToggleMapStyle && (
                <div
                    className="cursor-pointer rounded-lg bg-white mt-2 h-[36px] w-[36px] flex justify-center items-center hover:bg-gray-10 border-[1px] border-gray-10"
                    onClick={toggleLayer}
                >
                    <span className="w-[24px] h-[24px] fill-gray-60">
                        <MapLayersIcon />
                    </span>
                </div>
            )}
        </div>
    )
}

export default CustomMapControls

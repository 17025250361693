import { useEffect, useState } from "react"
import { MONTH_NAMES } from "../../../../Util/constants"

import AlertSelect from "./AlertSelect"

function AlertDateInput(props) {
    const { onChange = () => null, value: dateObj, disabled, elementId } = props
    const [state, setState] = useState({ ...dateObj })

    const daysOfMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    const [selectedMonthIndex, setSelectedMonth] = useState(state.month - 1)

    function handleDateChange(propName) {
        return (value) => {
            let newDate = { ...state, [propName]: parseInt(value) }
            onChange(newDate, null, false)
            if (propName === "month") setSelectedMonth(value - 1)

            setState(newDate)
        }
    }

    useEffect(() => {
        setState({ ...dateObj })
    }, [dateObj])

    return (
        <>
            <AlertSelect
                options={MONTH_NAMES}
                selectedIndex={state.month}
                disabled={disabled}
                onChange={handleDateChange("month")}
                elementId={`${elementId}__month-input__risk-profiles`}
            />
            <AlertSelect
                options={[...Array((daysOfMonths[selectedMonthIndex] || 30) + 1).keys()].slice(1)}
                maxNumber={[...Array((daysOfMonths[selectedMonthIndex] || 30) + 1).keys()].slice(1).length}
                selectedIndex={state.day}
                disabled={disabled}
                onChange={handleDateChange("day")}
                elementId={`${elementId}__day-input__risk-profiles`}
            />
        </>
    )
}

export default AlertDateInput

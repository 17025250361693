import React, { useEffect, useState } from "react"
// Modules
import PropTypes from "prop-types"

// Components
import SavedAlerts from "./SavedAlerts"
import NewAlert from "./NewAlert"
import LoadingBar from "../../../../components/LoadingBar"
import Tooltip from "../../../../ui/Tooltip"
import PlusIconAlt from "../../../../ui/Icons/PlusIconAlt"

// Views

// Context providers / Utils
import AlertsContext from "./AlertsContext"

// Hooks

// Material-UI *

// Styles
import "../AlertSettings.css"

const RiskAlert = (props) => {
    const {
        riskData,
        icon: IconComponent,
        alertsObj = {},
        path,
        units,
        currentUser,
        featurePermissions,
        onAlertAdd = () => null,
        onStackedAlertAdd = () => null,
        onAlertActiveChange = () => null,
        onUpdateAlert = () => null,
        onUpdateStackedAlert = () => null,
        onDeleteAlert = () => null,
        reloadAlertSettings = () => null,
    } = props

    const [openNewAlert, toggleNewAlert] = useState(false)
    const [alerts, setAlerts] = useState({ ...alertsObj })
    const [alertCount, setAlertCount] = useState(Object.keys(alerts).length)
    const [loading, toggleLoading] = useState(false)

    // control is back
    useEffect(() => {
        setAlerts({ ...alertsObj })
    }, [alertsObj])

    const handleToggleNewAlert = () => {
        if (featurePermissions.alerts.add) toggleNewAlert(!openNewAlert)
    }

    function handleAlertCountUpdate(unit) {
        let newCount = alertCount + unit
        if (newCount >= 0) {
            setAlertCount(newCount)
        }
    }

    function handleNewAlertSaved(alertId, newAlert) {
        reloadAlertSettings()
        handleAlertCountUpdate(1)
        let newAlertsObj = { ...alerts }
        newAlertsObj[alertId] = newAlert
        setAlerts(newAlertsObj)
    }

    function handleAlertDeleted(idAlert) {
        reloadAlertSettings() // Temporary
        handleAlertCountUpdate(-1)
        let newAlertsObj = { ...alerts }
        delete newAlertsObj[idAlert]
        setAlerts(newAlertsObj)
    }

    return (
        <>
            <AlertsContext.Provider
                value={{
                    onAlertAdd,
                    onStackedAlertAdd,
                    onAlertActiveChange,
                    onUpdateAlert,
                    onUpdateStackedAlert,
                    onDeleteAlert,
                    toggleLoading,
                }}
            >
                <div id={`${riskData.title}__risk-profiles`} className="py-[8px] w-full h-full box-border montserrat">
                    <div className="py-[16px] px-5 w-full h-full border-[1px] border-gray-10 rounded-lg relative">
                        <LoadingBar loading={loading} />
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-row items-center grow-0 space-x-5">
                                <div className="w-6 opacity-50 -mb-[3px]">{IconComponent && <IconComponent />}</div>
                                <div className="text-[14px] font-semibold text-gray-90">{riskData.title}</div>
                                {alertCount > 0 && <div className="">[{alertCount}]</div>}
                            </div>

                            <div
                                onClick={handleToggleNewAlert}
                                className={
                                    "cursor-pointer w-[24px] h-[24px] fill-gray-60" +
                                    (openNewAlert ? " opacity-50" : "") +
                                    (featurePermissions.alerts.add ? "" : " fill-gray-30 cursor-not-allowed")
                                }
                            >
                                <Tooltip content="Add new alert">
                                    <PlusIconAlt />
                                </Tooltip>
                            </div>
                        </div>

                        {openNewAlert && (
                            <NewAlert
                                riskData={riskData}
                                path={path}
                                onSave={handleNewAlertSaved}
                                onCancel={() => {
                                    toggleNewAlert(false)
                                }}
                                units={units}
                                currentUser={currentUser}
                                featurePermissions={featurePermissions}
                                toggleNewAlert={toggleNewAlert}
                            />
                        )}

                        {openNewAlert && Object.keys(alerts).length > 0 && (
                            <div className="w-full h-[1px] border-b-[1px] border-gray-10 my-2"></div>
                        )}

                        <SavedAlerts
                            riskData={riskData}
                            alerts={alerts}
                            path={path}
                            onDeleteAlert={handleAlertDeleted}
                            units={units}
                            currentUser={currentUser}
                            featurePermissions={featurePermissions}
                        />
                    </div>
                </div>
            </AlertsContext.Provider>
        </>
    )
}

RiskAlert.propTypes = {
    children: PropTypes.node,
}

export default RiskAlert

import { useState, useContext } from "react"
// Modules
import { useHistory } from "react-router-dom"

// Components
import ArrowRight from "../../ui/Icons/ArrowRight"
import ExitApp from "../../ui/Icons/ExitApp"
import Dropdown from "../../ui/Controls/Dropdown"
import DropdownItem from "../../ui/Controls/DropdownItem"

// Views
// Context providers / Utils
import { AuthContext } from "../../Auth/Auth"
import app from "../../Util/Fire"

// Hooks
import useOutsideComponentClickHandler from "../../hooks/ClickOutsideHook"

// Styles
import "./UserProfile.css"
import {
    REMEMBERED_PLANTING_DATE,
    REMEMBERED_THRESHOLD,
    REMEMBERED_GROUPING,
    REMEMBERED_COEFFICIENT,
    REMEMBERED_VARIABLE_PERIOD,
    REMEMBERED_WEATHER_VARIABLE_KEY,
    REMEMBERED_SEASONAL_TABLE_FILTERS,
    REMEMBERED_CLIMATE_TABLE_FILTERS,
} from "../../Util/localStorageVariables"

export const LOCAL_STORAGE_VARIABLES = [
    REMEMBERED_THRESHOLD,
    REMEMBERED_GROUPING,
    REMEMBERED_COEFFICIENT,
    REMEMBERED_PLANTING_DATE,
    REMEMBERED_VARIABLE_PERIOD,
    REMEMBERED_WEATHER_VARIABLE_KEY,
    REMEMBERED_SEASONAL_TABLE_FILTERS,
    REMEMBERED_CLIMATE_TABLE_FILTERS,
]

const UserProfile = ({ options = [] }) => {
    const DEFAULT_OPTIONS = []

    if (options.length === 0) {
        options = DEFAULT_OPTIONS
    }

    const [open, toggle] = useState(false)
    const profileRef = useOutsideComponentClickHandler(() => toggle(false))
    const history = useHistory()

    const {
        currentUser: { displayName, email },
    } = useContext(AuthContext)

    function handleLogoutButton(e) {
        if (e) e.preventDefault()
        app.auth().signOut()
        LOCAL_STORAGE_VARIABLES.forEach((value) => {
            localStorage.removeItem(value)
        })
        history.push("/")
    }

    return (
        <div className="user-profile" ref={profileRef}>
            <div className="user-profile__visible" onClick={() => toggle(!open)}>
                <div className={"user-profile__icon orange"}>
                    <div>{displayName ? displayName[0] : email[0]}</div>
                </div>
                <ArrowRight
                    id="arrow-dropdown__user-profile"
                    className={open ? "user-profile__deploy-icon--rotate" : "user-profile__deploy-icon"}
                />
            </div>
            {open && (
                <Dropdown title={displayName} className="down left" __onClose__={() => toggle(false)}>
                    {options.map((option, index) => {
                        return (
                            <DropdownItem
                                title={option.label}
                                id={option.id}
                                key={index}
                                onClick={() => history.push(option.to)}
                            />
                        )
                    })}
                    <DropdownItem
                        title="Contact Us"
                        id="contact-us__user-profile"
                        onClick={() => window.open("https://climate.ai", "_blank")}
                    />
                    <DropdownItem
                        title={
                            <div className="user-profile__dropdown__logout">
                                Logout
                                <ExitApp
                                    fill="var(--color-gray-80)"
                                    width="9px"
                                    height="9px"
                                    className="user-profile__logout-icon"
                                />
                            </div>
                        }
                        id="logout__user-profile"
                        onClick={(e) => {
                            handleLogoutButton(e)
                        }}
                    />
                </Dropdown>
            )}
        </div>
    )
}

export default UserProfile

import "react-datepicker/dist/react-datepicker.css"
import Section from "../../../ui/Section"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"
import useContainerSize from "../../../hooks/useContainerSize"
import LocationDropdownSelection from "../../../ui/Controls/LocationDropdownSelection"
import VariableDropdownSelection from "../../../ui/Controls/VariableDropdownSelection"
import ScenarioDropdownSelection from "../../../ui/Controls/ScenarioDropdownSelection"
import LoadingBar from "../../../components/LoadingBar"
import { getMaxYValue, getMinYValue } from "../../../Util/candleGraphUtils"
import { useContext } from "react"
import { SettingsContext } from "../../../Util/SettingsContext"

function HistoricalChart(props) {
    const {
        maxValue,
        minValue,
        loading,
        data,
        children,
        //
        locations,
        selectedLocation,
        setSelectedLocation,
        //
        variables,
        selectedVariable,
        setSelectedVariable,
    } = props

    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const [containerRef, { height }] = useContainerSize(1000)

    const selectedVariableObj = variables[selectedVariable]

    let labelY =
        selectedVariableObj.label +
        " " +
        ((selectedVariableObj.units && `(${selectedVariableObj.getUnits(units)})`) || "")

    let tickFormat = selectedVariableObj.units ? selectedVariableObj.getUnits(units) : ""

    const yTickFormat = (d) => {
        // return selectedVariableObj.units || ""
        return ["gdd", "rh", "ch", "tp"].includes(selectedVariable) && d < 0 ? "" : d
    }

    const getVariableYTick = (labY) => {
        if (labY.includes("Relative") || labY.includes("Growing") || labY.includes("Chill")) return 3
        return 5
    }

    return (
        <>
            <Section
                title="ClimateAi Projections vs Public Data Providers"
                description="This page displays ClimateAi's forecast performance against the raw IPCC climate model output for a single location. Accuracy is measured using the Continuous Ranked Probability Score (CRPS) which can be interpreted as the average error of our forecasted probabilities. Uncertainty is measured using the standard deviation. In both cases lower values are better."
                header={() => (
                    <>
                        <LocationDropdownSelection
                            selectedLocation={selectedLocation}
                            locations={locations}
                            setSelectedLocation={setSelectedLocation}
                            keyPrefix={"decadal_location"}
                        />
                        <VariableDropdownSelection
                            selectedVariable={selectedVariable}
                            variables={variables}
                            setSelectedVariable={setSelectedVariable}
                            keyPrefix={"decadal_variable"}
                        />
                    </>
                )}
            >
                {loading && <LoadingBar loading />}
                {children}
                <div style={{ width: "100%", height: "100%", overflowY: "auto" }} ref={containerRef}>
                    <LineAreaChartComponent
                        labelY={labelY}
                        xTicksCount={12}
                        yTicksCount={5}
                        yValueMax={getMaxYValue(maxValue, minValue, getVariableYTick(labelY))}
                        yValueMin={getMinYValue(maxValue, minValue, getVariableYTick(labelY))}
                        xDateMin={new Date(1975, 0, 1)}
                        xDateMax={new Date(2020, 0, 1)}
                        yTickFormat={yTickFormat}
                        zeroBasis={minValue === 0}
                        // centerTicks={true}
                        candlePadding={0}
                        labelYOffsetX={-10}
                        candlestickGroupMargin={30}
                        candleTopBottomLines={true}
                        // maxCandlestickWidth={50}
                        yBottomOffset={0.2}
                        yTopOffset={0.2}
                        marginLeft={100}
                        svgHeight={(height && height - 10) || 0}
                        tooltip={(EVENT, { key, values, colors }, state) => {
                            return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                    <tr><td style="font-weight:bold;font-size:20px" rowspan="${
                        values.length
                    }"><div style="text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(
                                undefined,
                                {
                                    year: "numeric",
                                }
                            )}</div></td>
                        <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:3px;background-color:${
                            colors[0]
                        };margin-top:-10px;border-radius:5px;"></div>${
                                Math.round(values[0] * 1000) / 1000
                            }${tickFormat}</td>
                    </tr>
                    ${values
                        .filter((_, i) => i > 0)
                        .map((value, i) => {
                            return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:3px;background-color:${
                                colors[i + 1]
                            };margin-top:-10px;border-radius:5px;"></div>${Math.round(value * 1000) / 1000}%</td></tr>`
                        })
                        .join("")}

                    </table>`
                        }}
                        shapeTip={(e, d, arr) => {
                            return `
                            <table>
                            <tr><th style="text-align:right">95th</th><td>${
                                d.y1 !== undefined && d.y1.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">75th</th><td>${
                                d.yMax !== undefined && d.yMax.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">50th</th><td>${
                                d.yMid !== undefined && d.yMid.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">25th</th><td>${
                                d.yMin !== undefined && d.yMin.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">5th</th><td>${
                                d.y0 !== undefined && d.y0.toFixed(2) + " " + tickFormat
                            }</td></tr>
                          </table>
                    `
                        }}
                        data={data}
                    ></LineAreaChartComponent>
                </div>
            </Section>
        </>
    )
}

export default HistoricalChart

import Menu from "./Menu"
import MenuItem from "./MenuItem"
import getVariableIcon from "../../Util/getVariableIcon"

export default function VariableDropdownSelection(props) {
    const { selectedVariable, variables, setSelectedVariable, keyPrefix, filter = (v) => v } = props

    return (
        <Menu
            className="left"
            icon={getVariableIcon(variables[selectedVariable]?.value)}
            text={variables[selectedVariable]?.label}
        >
            {variables &&
                Object.values(variables)
                    .filter(filter)
                    .map((v) => (
                        <MenuItem
                            active={selectedVariable === v.value}
                            icon={getVariableIcon(v.value)}
                            label={v.label}
                            key={`${keyPrefix}_${v.value}`}
                            onClick={() => setSelectedVariable(v.value)}
                        />
                    ))}
        </Menu>
    )
}

import { useState, useRef, useEffect, useCallback } from "react"
import "mapbox-gl/dist/mapbox-gl.css"
import "./mapbox.css"
import {
    getMarker,
    initializeMap,
    setupElementEvents,
    setupPolygonMapLayer,
    setupHeatmapMapLayer,
    maxRightTop,
    minLeftBottom,
    removeLayers,
    removeMarkers,
} from "./mapboxUtils"
import { withRouter } from "react-router"
import CustomMapControls from "./CustomMapControls"

export const MAP_DEFAULT_LAYER = "mapbox://styles/geramv/cl1mqypw5001714lqqms67v5f"
export const MAP_LAYER_TOGGLER = (mapDefaultLayer) => {
    return {
        "mapbox://styles/mapbox/satellite-v9": mapDefaultLayer || MAP_DEFAULT_LAYER,
        [mapDefaultLayer || MAP_DEFAULT_LAYER]: "mapbox://styles/mapbox/satellite-v9",
    }
}

const MapboxGenericMap = ({
    data,
    polygons = [],
    heatmap = [],
    markerPopup = () => {},
    mapLocationOnClick = () => {},
    heightClasses = "",
    minZoom = 1.1,
    history,
    markerPopupLock = false,
    mapDefaultLayer = "",
    renderWorldCopies = true,
    children,
    customFitTo,
    canToggleMapStyle = true,
}) => {
    const [map, setMap] = useState(null)
    const [mapStyle, setMapStyle] = useState(mapDefaultLayer || MAP_DEFAULT_LAYER)
    const [markers, setMarkers] = useState([])
    const [myData, setMyData] = useState([]) // Useful just to update the view
    const [fixedMarker, setFixedMarker] = useState("")
    const mapContainer = useRef(null)

    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time))

    useEffect(() => {
        const myMap = initializeMap({
            layer: mapStyle,
            mapContainerRefCurrent: mapContainer.current,
            minZoom,
            renderWorldCopies,
        })
        setMap(myMap)
        const handleResize = () => myMap?.resize()
        window.addEventListener("force-resize", handleResize)
        mapContainer.current.classList.remove("mapboxgl-map")
        return () => {
            myMap?.remove()
            window.removeEventListener("force-resize", handleResize)
        }
    }, [])

    useEffect(() => {
        if (!map) {
            delay(200).then(() => setMyData([...data]))
            return
        }
        removeMarkersAndLayers()
        if (data && data.length === 0) return
        setupMarkersAndLayersAndFitMap()
    }, [data, myData])

    useEffect(() => {
        if (!map) return
        removeLayers(map)
        map.setStyle(mapStyle)
        setupPolygonsLayer(500)
        setupHeatmapLayer(500)
    }, [mapStyle])

    useEffect(() => {
        if (!map || !markerPopupLock) return
        setupMarkers()
    }, [fixedMarker])

    const setupPolygonsLayer = (waitTime) => {
        if (polygons && polygons.length > 0)
            delay(waitTime).then(() => setupPolygonMapLayer(map, polygons, mapLocationOnClick))
    }

    const setupHeatmapLayer = (waitTime) => {
        if (heatmap && heatmap.length > 0)
            delay(waitTime).then(() => setupHeatmapMapLayer(map, heatmap, mapStyle.includes("satellite")))
    }

    const removeMarkersAndLayers = () => {
        removeMarkers(markers)
        setMarkers([])
        removeLayers(map)
    }

    const clearFixedMarker = useCallback(() => {
        setFixedMarker("")
    }, [setFixedMarker])
    const mouseDownHandler = useCallback(
        (event) => {
            let toast_container = event?.target?.classList?.contains("Toastify__toast-container")
            if (
                document.openPopupEl &&
                !document.openPopupEl.contains(event.target) &&
                !toast_container &&
                !event.srcElement.className.includes("items-top") // When clicking another pin
            ) {
                clearFixedMarker()
            }
        },
        [clearFixedMarker]
    )
    const escapeKeyHandler = useCallback(
        (event) => {
            if (event.key === "Escape") {
                clearFixedMarker()
            }
        },
        [clearFixedMarker]
    )
    const buttonElClickHandler = useCallback((event) => {
        const btnLinksList = document.btnLinksList
        const riskId = event.target.id.split("/")[1]
        if (riskId && btnLinksList && btnLinksList.length > 0) {
            for (const btnLink of btnLinksList) {
                if (btnLink.includes(riskId)) history.push(btnLink)
            }
        }
    }, [])

    const setupOrCleanupEventListeners = (d, eventListenerFunctionName) => {
        const closeButtonEl = document.getElementById(d.id + "-closeBtn")
        if (closeButtonEl) {
            closeButtonEl[eventListenerFunctionName]("click", clearFixedMarker)
        }

        const openPopupEl = document.getElementById(d.id + "-popup")
        document.openPopupEl = openPopupEl
        document[eventListenerFunctionName]("mousedown", mouseDownHandler)
        document[eventListenerFunctionName]("keyup", escapeKeyHandler)

        if (!d?.alerts?.variables) return
        document.btnLinksList = []
        for (const alert of d.alerts.variables) {
            const buttonEl = document.getElementById(d.id + "/" + alert.risk_alert)
            if (!buttonEl) continue
            const fieldNextURL =
                d.id +
                "?variable=" +
                alert.variable_name +
                "&granularity=" +
                alert.granularity[0] +
                "&risk=" +
                alert.risk_alert
            document.btnLinksList.push(fieldNextURL)

            buttonEl[eventListenerFunctionName]("click", buttonElClickHandler)
        }
    }

    const setupMarkers = () => {
        for (let d of data) {
            setupOrCleanupEventListeners(d, "removeEventListener")
        }
        removeMarkers(markers)
        const _markers = []
        for (let d of data) {
            const marker = getMarker({ data: d, map, markerPopup, fixedMarker, closePopup: clearFixedMarker })
            if (!marker) continue

            const toggle = () => {
                if (markerPopupLock) {
                    if (!fixedMarker) marker.togglePopup()
                } else marker.togglePopup()
            }
            const fixMarkerOrJumpToLocation = () => {
                if (markerPopupLock) {
                    if ((!(d?.alerts?.variables?.length > 0) && fixedMarker === "") || fixedMarker === d.id)
                        mapLocationOnClick({ id: d.id, ...d })
                    setFixedMarker(d.id)
                } else mapLocationOnClick({ id: d.id, ...d })
            }
            setupElementEvents(marker.getElement(), {
                mouseenter: toggle,
                mouseleave: toggle,
                click: () => fixMarkerOrJumpToLocation(),
            })
            _markers.push(marker)

            if (d.id !== fixedMarker) continue
            marker.togglePopup()
            if (!(d?.alerts?.variables?.length > 0)) {
                clearFixedMarker()
                continue
            }
            setupOrCleanupEventListeners(d, "addEventListener")
        }
        setMarkers(_markers)
    }

    const setupMarkersAndLayersAndFitMap = () => {
        setupPolygonsLayer(200)
        setupHeatmapLayer(200)
        setupMarkers()

        const centers = data.map((d) => [d.lon, d.lat]).filter((d) => d)
        map.fitBounds(customFitTo ? customFitTo : [minLeftBottom(centers), maxRightTop(centers)], {
            padding: customFitTo ? 180 : 80,
        })
    }

    return (
        <div className="relative w-full h-full">
            <CustomMapControls
                map={map}
                toggleLayer={() => setMapStyle(MAP_LAYER_TOGGLER(mapDefaultLayer)[mapStyle])}
                canToggleMapStyle={canToggleMapStyle}
            />
            {children}
            <div className={"w-full h-full " + heightClasses} ref={(el) => (mapContainer.current = el)} />
        </div>
    )
}

export default withRouter(MapboxGenericMap)

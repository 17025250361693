const CumulativePrecipitationRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M0 12C0 9.39076 1.6685 7.16656 4 6.34494V8.53087C2.79535 9.22066 2 10.522 2 12C2 13.6177 2.95904 15.0103 4.33985 15.6406L3.84948 17.6021C1.59955 16.7361 0 14.5524 0 12Z"
                fill={props.fill}
            />
            <path
                d="M12 2V4C14.62 4 16.88 5.86 17.39 8.43L17.69 9.93L19.22 10.04C20.78 10.14 22 11.45 22 13C22 14.65 20.65 16 19 16H18.25L17.75 18H19C21.76 18 24 15.76 24 13C24 10.36 21.95 8.22 19.35 8.04C18.67 4.59 15.64 2 12 2Z"
                fill={props.fill}
            />
            <path d="M15 21H13L15 13H17L15 21Z" fill={props.fill} />
            <path d="M9 21L11 13H13L11 21H9Z" fill={props.fill} />
            <path d="M7 21H5L7 13H9L7 21Z" fill={props.fill} />
            <path d="M10 6H12V8H10V10H8V8H6V6H8V4H10V6Z" fill={props.fill} />
        </svg>
    )
}

export default CumulativePrecipitationRiskIcon

export const TemperatureIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M15 13V5C15 4.20435 14.6839 3.44129 14.1213 2.87868C13.5587 2.31607 12.7956 2 12 2C11.2044 2 10.4413 2.31607 9.87868 2.87868C9.31607 3.44129 9 4.20435 9 5V13C8.16047 13.6296 7.54033 14.5075 7.22743 15.5092C6.91453 16.5108 6.92473 17.5856 7.25658 18.5811C7.58844 19.5767 8.22512 20.4426 9.07645 21.0562C9.92778 21.6698 10.9506 22 12 22C13.0494 22 14.0722 21.6698 14.9236 21.0562C15.7749 20.4426 16.4116 19.5767 16.7434 18.5811C17.0753 17.5856 17.0855 16.5108 16.7726 15.5092C16.4597 14.5075 15.8395 13.6296 15 13ZM12 4C12.2652 4 12.5196 4.10536 12.7071 4.29289C12.8946 4.48043 13 4.73478 13 5V9H11V5C11 4.73478 11.1054 4.48043 11.2929 4.29289C11.4804 4.10536 11.7348 4 12 4Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const MinTemperatureIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 13V5C18 4.20435 17.6839 3.44129 17.1213 2.87868C16.5587 2.31607 15.7956 2 15 2C14.2044 2 13.4413 2.31607 12.8787 2.87868C12.3161 3.44129 12 4.20435 12 5V13C11.1605 13.6296 10.5403 14.5075 10.2274 15.5092C9.91453 16.5108 9.92473 17.5856 10.2566 18.5811C10.5884 19.5767 11.2251 20.4426 12.0764 21.0562C12.9278 21.6698 13.9506 22 15 22C16.0494 22 17.0722 21.6698 17.9236 21.0562C18.7749 20.4426 19.4116 19.5767 19.7434 18.5811C20.0753 17.5856 20.0855 16.5108 19.7726 15.5092C19.4597 14.5075 18.8395 13.6296 18 13ZM15 4C14.4477 4 14 4.44771 14 5V14.1707C12.8348 14.5825 12 15.6938 12 17H18C18 15.6938 17.1652 14.5825 16 14.1707V5C16 4.44772 15.5523 4 15 4Z"
                fill={props.fill}
            />
            <path d="M10 9H4V11H10V9Z" fill={props.fill} />
        </svg>
    )
}

export const MaxTemperatureIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M18 13V5C18 4.20435 17.6839 3.44129 17.1213 2.87868C16.5587 2.31607 15.7956 2 15 2C14.2044 2 13.4413 2.31607 12.8787 2.87868C12.3161 3.44129 12 4.20435 12 5V13C11.1605 13.6296 10.5403 14.5075 10.2274 15.5092C9.91453 16.5108 9.92473 17.5856 10.2566 18.5811C10.5884 19.5767 11.2251 20.4426 12.0764 21.0562C12.9278 21.6698 13.9506 22 15 22C16.0494 22 17.0722 21.6698 17.9236 21.0562C18.7749 20.4426 19.4116 19.5767 19.7434 18.5811C20.0753 17.5856 20.0855 16.5108 19.7726 15.5092C19.4597 14.5075 18.8395 13.6296 18 13ZM15 4C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5V7H14V5C14 4.73478 14.1054 4.48043 14.2929 4.29289C14.4804 4.10536 14.7348 4 15 4Z"
                fill={props.fill}
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M8 11V13H6L6 11H4V9H6L6 7H8V9H10V11H8Z" fill={props.fill} />
        </svg>
    )
}

export const SoilTemperatureIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.67637 20H2.5V22H8.26935C7.616 21.4466 7.07255 20.7675 6.67637 20ZM16.7306 22H22.5V20H18.3236C17.9274 20.7675 17.384 21.4466 16.7306 22Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0495 17H22.5V15H18.7385C18.9405 15.6305 19.0495 16.3025 19.0495 17ZM6.26142 15C6.05947 15.6305 5.95044 16.3025 5.95044 17H2.5V15H6.26142Z"
                fill={props.fill}
            />
            <path
                d="M15.5 13V5C15.5 4.20435 15.1839 3.44129 14.6213 2.87868C14.0587 2.31607 13.2956 2 12.5 2C11.7044 2 10.9413 2.31607 10.3787 2.87868C9.81607 3.44129 9.5 4.20435 9.5 5V13C8.66047 13.6296 8.04033 14.5075 7.72743 15.5092C7.41453 16.5108 7.42473 17.5856 7.75658 18.5811C8.08844 19.5767 8.72512 20.4426 9.57645 21.0562C10.4278 21.6698 11.4506 22 12.5 22C13.5494 22 14.5722 21.6698 15.4236 21.0562C16.2749 20.4426 16.9116 19.5767 17.2434 18.5811C17.5753 17.5856 17.5855 16.5108 17.2726 15.5092C16.9597 14.5075 16.3395 13.6296 15.5 13ZM12.5 4C12.7652 4 13.0196 4.10536 13.2071 4.29289C13.3946 4.48043 13.5 4.73478 13.5 5V9H11.5V5C11.5 4.73478 11.6054 4.48043 11.7929 4.29289C11.9804 4.10536 12.2348 4 12.5 4Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const PrecipitationIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M17.89 8.43C17.38 5.86 15.12 4 12.5 4C10.44 4 8.58 5.14 7.63 6.97L7.13 7.92L6.06 8.03C4.03 8.24 2.5 9.95 2.5 12C2.5 13.6177 3.45904 15.0103 4.83985 15.6406L4.34948 17.6021C2.09955 16.7361 0.5 14.5524 0.5 12C0.5 8.91 2.84 6.36 5.85 6.04C7.1 3.64 9.61 2 12.5 2C16.14 2 19.17 4.59 19.85 8.04C22.45 8.22 24.5 10.36 24.5 13C24.5 15.76 22.26 18 19.5 18H18.25L18.75 16H19.5C21.15 16 22.5 14.65 22.5 13C22.5 11.45 21.28 10.14 19.72 10.04L18.19 9.93L17.89 8.43Z"
                fill={props.fill}
            />
            <path d="M15.5 21H13.5L15.5 13H17.5L15.5 21Z" fill={props.fill} />
            <path d="M13.5 13H11.5L9.5 21H11.5L13.5 13Z" fill={props.fill} />
            <path d="M7.5 21H5.5L7.5 13H9.5L7.5 21Z" fill={props.fill} />
        </svg>
    )
}

export const RelativeHumidityIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 13.8C4.5 10.48 7.17 6.55 12.5 2C17.83 6.55 20.5 10.48 20.5 13.8C20.5 18.78 16.7 22 12.5 22C8.3 22 4.5 18.78 4.5 13.8ZM18.4973 14H6.5027C6.5009 13.9337 6.5 13.867 6.5 13.8C6.5 11.46 8.45 8.36 12.5 4.66C16.55 8.36 18.5 11.45 18.5 13.8C18.5 13.867 18.4991 13.9337 18.4973 14Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const WaterBudgetIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <rect x="12" y="13" width="7" height="2" transform="rotate(90 12 13)" fill={props.fill} />
            <rect x="8" y="16" width="4" height="2" transform="rotate(90 8 16)" fill={props.fill} />
            <rect x="16" y="10" width="10" height="2" transform="rotate(90 16 10)" fill={props.fill} />
            <path
                d="M7 11.4C8.66 11.4 10 10.06 10 8.4C10 6.4 7 3 7 3C7 3 4 6.4 4 8.4C4 10.06 5.34 11.4 7 11.4Z"
                fill={props.fill}
            />
            <rect x="20" y="7" width="13" height="2" transform="rotate(90 20 7)" fill={props.fill} />
        </svg>
    )
}

export const EvapotranspirationIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M3.60677 20.4157L3.69209 20.3713L3.6995 20.3674L3.71375 20.36C4.13 20.13 4.38876 20 5.0075 20C5.62627 20 5.88499 20.13 6.30124 20.36C6.8075 20.63 7.505 21 8.75377 21C9.9911 21 10.6872 20.6367 11.2033 20.3674L11.2175 20.36C11.6338 20.13 11.8813 20 12.5113 20C13.124 20 13.3837 20.1373 13.7929 20.3536L13.805 20.36C14.3112 20.63 15.0088 21 16.2575 21C17.4843 21 18.1682 20.6429 18.6825 20.3743L18.71 20.36C19.1263 20.13 19.3737 20 20.0038 20C20.6165 20 20.8761 20.1373 21.2854 20.3536L21.2975 20.36C21.3617 20.3942 21.4289 20.4301 21.5 20.4666V18.2161C21.1078 18.0912 20.6237 18 20.0038 18C18.755 18 18.0575 18.37 17.5512 18.64C17.135 18.87 16.8763 19 16.2575 19C15.6448 19 15.3851 18.8627 14.9759 18.6464L14.9638 18.64C14.4575 18.37 13.76 18 12.5113 18C11.2739 18 10.5778 18.3633 10.0617 18.6326L10.0475 18.64C9.63126 18.87 9.38376 19 8.75377 19C8.12373 19 7.87627 18.87 7.45999 18.64C6.95376 18.37 6.25626 18 5.0075 18C4.38237 18 3.8954 18.0927 3.5 18.2192V20.4703C3.5365 20.4519 3.57206 20.4337 3.60677 20.4157Z"
                fill={props.fill}
            />
            <path
                d="M3.60677 15.4157L3.69209 15.3713L3.6995 15.3674L3.71375 15.36C4.13 15.13 4.38876 15 5.0075 15C5.62627 15 5.88499 15.13 6.30124 15.36C6.8075 15.63 7.505 16 8.75377 16C9.9911 16 10.6872 15.6367 11.2033 15.3674L11.2175 15.36C11.6338 15.13 11.8813 15 12.5113 15C13.124 15 13.3837 15.1373 13.7929 15.3536L13.805 15.36C14.3112 15.63 15.0088 16 16.2575 16C17.4843 16 18.1682 15.6429 18.6825 15.3743L18.71 15.36C19.1263 15.13 19.3737 15 20.0038 15C20.6165 15 20.8761 15.1373 21.2854 15.3536L21.2975 15.36C21.3617 15.3942 21.4289 15.4301 21.5 15.4666V13.2161C21.1078 13.0912 20.6237 13 20.0038 13C18.755 13 18.0575 13.37 17.5512 13.64C17.135 13.87 16.8763 14 16.2575 14C15.6448 14 15.3851 13.8627 14.9759 13.6464L14.9638 13.64C14.4575 13.37 13.76 13 12.5113 13C11.2739 13 10.5778 13.3633 10.0617 13.6326L10.0475 13.64C9.63126 13.87 9.38376 14 8.75377 14C8.12373 14 7.87627 13.87 7.45999 13.64C6.95376 13.37 6.25626 13 5.0075 13C4.38237 13 3.8954 13.0927 3.5 13.2192V15.4703C3.5365 15.4519 3.57206 15.4337 3.60677 15.4157Z"
                fill={props.fill}
            />
            <path
                d="M12.5 3.00007L8.25738 7.24272L11.5 7.24272L11.5 11.5564L13.5 11.5564L13.5 7.24272L16.7427 7.24272L12.5 3.00007Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const SolarRadiationIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M12.5 7C9.74 7 7.5 9.24 7.5 12C7.5 14.76 9.74 17 12.5 17C15.26 17 17.5 14.76 17.5 12C17.5 9.24 15.26 7 12.5 7Z"
                fill={props.fill}
            />
            <path d="M11.5 1V5L13.5 5L13.5 1L11.5 1Z" fill={props.fill} />
            <path d="M11.5 23L13.5 23V19H11.5L11.5 23Z" fill={props.fill} />
            <path d="M19.5 11V13H23.5V11L19.5 11Z" fill={props.fill} />
            <path d="M5.5 11L1.5 11L1.5 13H5.5V11Z" fill={props.fill} />
            <path d="M4.37 5.28L6.84 7.75L8.25 6.34L5.78 3.87L4.37 5.28Z" fill={props.fill} />
            <path d="M6.84 16.24L4.37 18.71L5.78 20.12L8.25 17.65L6.84 16.24Z" fill={props.fill} />
            <path d="M19.22 3.87L16.75 6.34L18.16 7.75L20.63 5.28L19.22 3.87Z" fill={props.fill} />
            <path
                d="M23.4999 22.9999V16.9999L21.2071 19.2928L18.1568 16.2426L16.7426 17.6568L19.7928 20.707L17.4999 22.9999H23.4999Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const GrowingDegreeDaysIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <rect x="11.5" y="5" width="2" height="13" fill={props.fill} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.25739 7.24268L12.5 3L16.7427 7.24268H13.5V11.5563H11.5V7.24268H8.25739ZM4.5 9C4.5 11.7614 6.73859 14 9.5 14H10.5C10.5 11.2386 8.26141 9 5.5 9H4.5ZM15.5 16C18.2614 16 20.5 13.7614 20.5 11H19.5C16.7386 11 14.5 13.2386 14.5 16H15.5ZM21.5 18H3.5V20H21.5V18Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const SoilMoistureIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <rect x="3.5" y="18" width="18" height="2" fill={props.fill} />
            <rect x="3.5" y="13" width="18" height="2" fill={props.fill} />
            <path
                d="M12.5 10.4C14.16 10.4 15.5 9.06 15.5 7.4C15.5 5.4 12.5 2 12.5 2C12.5 2 9.5 5.4 9.5 7.4C9.5 9.06 10.84 10.4 12.5 10.4Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const WindSpeedIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M15 18C15 19.65 13.65 21 12 21C10.35 21 9 19.65 9 18H11C11 18.55 11.45 19 12 19C12.55 19 13 18.55 13 18C13 17.45 12.55 17 12 17H2.5V15H12C13.65 15 15 16.35 15 18ZM19.5 5.5C19.5 3.57 17.93 2 16 2C14.07 2 12.5 3.57 12.5 5.5H14.5C14.5 4.67 15.17 4 16 4C16.83 4 17.5 4.67 17.5 5.5C17.5 6.33 16.83 7 16 7H2.5V9H16C17.93 9 19.5 7.43 19.5 5.5ZM19 11H2.5V13H19C19.83 13 20.5 13.67 20.5 14.5C20.5 15.33 19.83 16 19 16V18C20.93 18 22.5 16.43 22.5 14.5C22.5 12.57 20.93 11 19 11Z"
                fill={props.fill}
            />
        </svg>
    )
}

export const ChillHoursIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.17 13L2 13L2 11L6.17 11L2.93 7.76L4.34 6.34L9 11L11 11L11 9L6.34 4.34L7.76 2.93L11 6.17L11 2L13 2L13 6.17L13 9L13 11L13 13L13 15L13 17.83L13 22L11 22L11 17.83L7.76 21.07L6.34 19.66L11 15L11 13L9 13L4.34 17.66L2.93 16.24L6.17 13Z"
                fill={props.fill}
            />
            <path
                d="M15 21.542C19.0571 20.2677 22 16.4774 22 11.9998C22 7.52215 19.0571 3.73186 15 2.4576L15 4.58131C15.6937 4.86211 16.3391 5.2374 16.9201 5.6912L15.4915 7.11978C16.0368 7.5106 16.5139 7.99054 16.9014 8.53831L18.3311 7.10864C19.185 8.21235 19.7568 9.54547 19.9381 10.9998L17.917 10.9998C17.9716 11.325 18 11.6591 18 11.9998C18 12.3405 17.9716 12.6746 17.917 12.9998L19.9381 12.9998C19.756 14.4605 19.18 15.7989 18.3198 16.9055L16.8907 15.4764C16.5016 16.0229 16.0231 16.5014 15.4765 16.8906L16.9056 18.3197C16.3283 18.7684 15.688 19.1398 15 19.4183L15 21.542Z"
                fill={props.fill}
            />
        </svg>
    )
}

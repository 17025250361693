const LowSoilTempRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M11.1 9.39925V5.75998L12.9 5.75998V9.39925L15.816 6.57162L17.094 7.80216L12.9 11.8691V13.6145H14.7L18.894 9.54762L20.163 10.7869L17.247 13.6145H21V15.36H3L3 13.6145L6.753 13.6145L3.837 10.7869L5.106 9.54762L9.3 13.6145H11.1V11.8691L6.906 7.80216L8.184 6.57162L11.1 9.39925Z"
                fill={props.fill}
            />
            <rect x="3" y="18.24" width="18" height="1.92" fill={props.fill} />
        </svg>
    )
}

export default LowSoilTempRiskIcon

const ArrowBottom = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path d="M7 10L12 15L17 10H7Z" fill={props.fill || "#4E4E4E"} />
        </svg>
    )
}

export default ArrowBottom

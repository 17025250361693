import { useContext } from "react"
import Switch from "../../../../components/Switch"
import AlertsContext from "./AlertsContext"

const AlertActiveToggle = ({ isActive, path = null, featurePermissions, isPossible = true }) => {
    const { onAlertActiveChange } = useContext(AlertsContext)

    function handleActiveChange(value) {
        if (!path || !featurePermissions.alerts.edit) {
            return
        }
        onAlertActiveChange(value, path)
    }

    return (
        <Switch
            checked={isActive}
            onChange={handleActiveChange}
            disabled={!featurePermissions.alerts.edit || !isPossible}
            size="lg"
            className="flex flex-row items-center justify-center"
        />
    )
}

export default AlertActiveToggle

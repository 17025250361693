import "./ChartLabelItem.css"

function ChartLabelItem(props) {
    const { icon: IconComponent, renderIcon = () => null, children, disabled } = props

    return (
        <>
            <div className={"chart-label-item__button" + (disabled ? " non-visible" : "")}>
                {IconComponent || renderIcon() && (
                    <div style={{ width: 22, height: 22 }}>{(IconComponent && <IconComponent />) || renderIcon()}</div>
                )}
                {" "}
                {children}
            </div>
        </>
    )
}

export default ChartLabelItem

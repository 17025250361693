import { useState, useEffect, useContext } from "react"
import { AuthContext } from "../../../../Auth/Auth"
import GranularityButton from "./components/GranularityButton"
import VariableButton from "./components/VariableButton"
import useField from "../../../../hooks/useField"
import Tooltip from "../../../../ui/Tooltip"
import InfoIcon from "../../../../ui/Icons/newIcons/InfoIcon"

import { GRANULARITY_META, VARIABLES_META } from "./variablesGranularitiesMetadata"
import { REMEMBERED_VARIABLE_PERIOD, REMEMBERED_WEATHER_VARIABLE_KEY } from "../../../../Util/localStorageVariables"
import { DataContext } from "../../../../Util/Data"

const ALLOWED_GRANS = ["hourly", "daily", "weekly", "monthly"]
//TODO: Is there a better place to have a global Allowed Vars array that also feeds the Switch on the renderComponent() on this file?
const ALLOWED_VARS = [
    "temperature_t2m",
    "precipitation",
    "soil_temperature",
    "relative_humidity",
    "evapotranspiration",
    "solar_radiation",
    "gdd",
    "wind_speed",
    "soil_moisture",
    "chill_hours",
]

const SeasonalVariableTabs = ({ onStateChange, allowedSeasonalVariables, selectedTab = "", selectedGran = "" }) => {
    const [shownVariables, setShownVariables] = useState([])
    const [selectedVariable, setSelectedVariable] = useState("temperature_t2m")
    const [shownGranularities, setShownGranularities] = useState([])
    const [selectedGranularity, setSelectedGranularity] = useState("daily")
    const [variableAlerts, setVariableAlerts] = useState({})
    const [granularityAlerts, setGranularityAlerts] = useState({ daily: false, weekly: false, monthly: false })

    const { forecastTypesEnabled } = useContext(AuthContext)

    const field = useField()

    useEffect(() => {
        fillAllowedVariablesState()
    }, [])

    useEffect(() => {
        fillAllowedGranularityState()
        setupGranularityAlerts()
        setupVariableAlerts()
    }, [field, selectedVariable])

    const fillAllowedVariablesState = () => {
        let vars = []
        // Don't add the variable if no permission or if we don't have metadata for it.
        for (const [key, value] of Object.entries(allowedSeasonalVariables)) {
            if (key in VARIABLES_META && value === true) {
                vars.push(key)
            }
        }
        setShownVariables([...vars])

        let selectedVar =
            (selectedTab === "temp" && "temperature_t2m") ||
            selectedTab ||
            localStorage.getItem(REMEMBERED_WEATHER_VARIABLE_KEY) ||
            vars[0] ||
            "temperature_t2m"
        if (!ALLOWED_VARS.includes(selectedVar)) selectedVar = "temperature_t2m"
        setSelectedVariable(selectedVar)
        let selectedPer =
            getSelectedOrAvailableGranularity(selectedVar) ||
            selectedGran ||
            localStorage.getItem(REMEMBERED_VARIABLE_PERIOD) ||
            "daily"
        if (!ALLOWED_GRANS.includes(selectedPer)) selectedPer = "daily"

        setSelectedGranularity(selectedPer)
        onStateChange({
            selectedVariable: selectedVar,
            selectedGranularity: selectedPer,
        })
    }

    const getSelectedOrAvailableGranularity = (variable) => {
        let granularity = ""
        granularity = "daily"
        if (localStorage.getItem(REMEMBERED_VARIABLE_PERIOD) === "monthly") {
            granularity = "monthly"
        } else if (localStorage.getItem(REMEMBERED_VARIABLE_PERIOD) === "hourly") {
            granularity = "hourly"
        } else if (localStorage.getItem(REMEMBERED_VARIABLE_PERIOD) === "weekly") {
            granularity = "weekly"
        }

        if (
            (granularity === "monthly" && !VARIABLES_META[variable].monthly) ||
            (granularity === "hourly" && !VARIABLES_META[variable].hourly) ||
            (granularity === "weekly" && !VARIABLES_META[variable].weekly)
        ) {
            granularity = "daily"
        }
        if (granularity === "daily" && !VARIABLES_META[variable].daily) {
            granularity = "monthly"
        }
        return granularity
    }

    const onSelect = (variable) => {
        localStorage.setItem(REMEMBERED_WEATHER_VARIABLE_KEY, variable)
        setSelectedVariable(variable)
        const granularity = getSelectedOrAvailableGranularity(variable)
        setSelectedGranularity(granularity)
        onStateChange({
            selectedVariable: variable,
            selectedGranularity: granularity,
        })
    }

    const handleGranularityChange = (granularityPeriod) => {
        localStorage.setItem(REMEMBERED_VARIABLE_PERIOD, granularityPeriod)
        setSelectedGranularity(granularityPeriod)
        onStateChange({
            selectedVariable: selectedVariable,
            selectedGranularity: granularityPeriod,
        })
    }

    const fillAllowedGranularityState = () => {
        const granularities = []
        Object.keys(GRANULARITY_META).forEach((granularity) => {
            const newGranularity = GRANULARITY_META[granularity]
            newGranularity["isPossible"] =
                selectedVariable !== "" &&
                VARIABLES_META[selectedVariable] &&
                VARIABLES_META[selectedVariable][granularity] === true &&
                forecastTypesEnabled[GRANULARITY_META[granularity].enableValidatorKey]
            granularities.push(newGranularity)
        })
        setShownGranularities(granularities)
    }

    const hasActiveAlert = (granularity, variable) => {
        if (field?.triggered_alerts?.variables) {
            for (const alert of field.triggered_alerts.variables) {
                if (alert.granularity.includes(granularity) && alert.variable_name === variable) return true
            }
        }
        return false
    }

    const setupGranularityAlerts = () => {
        const newGranularityAlerts = { daily: false, weekly: false, monthly: false }
        if (field?.triggered_alerts?.variables) {
            for (const alert of field.triggered_alerts.variables) {
                for (const granularity of alert.granularity) {
                    newGranularityAlerts[granularity] = true
                }
            }
        }
        setGranularityAlerts(newGranularityAlerts)
    }

    const setupVariableAlerts = () => {
        const newVariableAlerts = {}
        if (field?.triggered_alerts?.variables) {
            for (const alert of field.triggered_alerts.variables) {
                newVariableAlerts[alert.variable_name] = true
            }
        }
        setVariableAlerts(newVariableAlerts)
    }

    return (
        <div className="flex flex-col md:flex-col-reverse justify-start space-y-[10px] p-5 pb-0 md:border-b-[1px] border-gray-10">
            <div className="flex flex-col flex-wrap md:flex-row md:items-center">
                <div className="z-40 flex flex-row items-center justify-center pt-2 md:justify-start">
                    {shownGranularities.map((granularity, index) => (
                        <GranularityButton
                            key={granularity.key}
                            onClick={() => handleGranularityChange(granularity.key)}
                            isSelected={granularity.key === selectedGranularity}
                            hasAlert={granularityAlerts[granularity.key]}
                            // hasActiveAlert={hasActiveAlert(granularity.key, selectedVariable)} // TODO: Think about two way indicators
                            hasActiveAlert={true}
                            label={granularity.displayName}
                            isLeft={index === 0}
                            isRight={index === shownGranularities.length - 1}
                            isPossible={granularity.isPossible}
                        />
                    ))}
                </div>
                <div className="hidden md:block border-l-[1px] mt-2 border-gray-10 h-8 mx-4" />
                <div className="flex flex-row items-center justify-center space-x-2 pt-2 z-50 -mb-[1px] overflow-x-visible grow sm:overflow-x-visible border-b-[1px] md:justify-start">
                    {shownVariables.map((variable) => (
                        <VariableButton
                            key={variable}
                            onClick={() => onSelect(variable)}
                            isSelected={variable === selectedVariable}
                            hasAlert={variableAlerts[variable]}
                            // hasActiveAlert={true}
                            hasActiveAlert={hasActiveAlert(selectedGranularity, variable)}
                            title={VARIABLES_META[variable]?.title}
                            icon={VARIABLES_META[variable]?.icon}
                        />
                    ))}
                </div>
            </div>
            <div className="flex flex-row items-center justify-center md:justify-start">
                {field ? (
                    <div className="flex flex-row items-center space-x-[8px]">
                        <Tooltip
                            size="medium"
                            pointerEvents={true}
                            contentClass="ml-48"
                            content={VARIABLES_META[selectedVariable].tooltip(selectedGranularity)}
                        >
                            <span className="flex shrink-0 roboto font-normal text-[16px]">
                                <span className="w-[18px] h-[18px]">
                                    <InfoIcon className="fill-gray-30" />
                                </span>
                            </span>
                        </Tooltip>
                        <div className="montserrat font-semibold text-[16px] text-gray-90">
                            {VARIABLES_META[selectedVariable].title} in {field.name}, {field.region} for {field.crop} (
                            {field.variety})
                        </div>
                        <div className="hidden LAT_LON">
                            {field.polygon.center.lat}, {field.polygon.center.lon}
                        </div>
                    </div>
                ) : (
                    <div className="w-full max-w-[500px] h-[22px] text-gray-60 bg-gray-5 animate-pulse-fast mr-8 flex items-center">
                        Loading...
                    </div>
                )}
            </div>
        </div>
    )
}

export default SeasonalVariableTabs

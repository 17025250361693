import InfoIcon from "../Icons/newIcons/InfoIcon"

import Tooltip from "../Tooltip"

import "./Section.css"

function Section(props) {
    const {
        title,
        description,
        header = () => null,
        children,
        flat,
        id,
        extraClasses = "",
        headerTextClass = "",
    } = props
    return (
        <div className={"section grow " + extraClasses} id={id}>
            {!flat && (
                <div className="relative section__header">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {description && (
                            <div className="section__header__info-icon">
                                <Tooltip content={description} size="large" contentClass="ml-72">
                                    <div style={{ width: 20, height: 20 }}>
                                        <InfoIcon fill="#B3B6BA" />
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                        <div className={"section__header__container " + headerTextClass}>
                            <div className="section__header__title">{title}</div>
                        </div>
                    </div>
                    <div className="section__header__controls">{header()}</div>
                </div>
            )}
            <div className="overflow-hidden section__content">{children}</div>
        </div>
    )
}

export default Section

import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp"
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

import { useEffect, useRef, useState } from "react"
import { token } from "./accessToken"

// Styles
import "./AddLocationsMap.css"
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import CustomMapControls from "../../../components/Charts/MapboxMaps/CustomMapControls"
import { MAP_DEFAULT_LAYER, MAP_LAYER_TOGGLER } from "../../../components/Charts/MapboxMaps/MapboxGenericMap"
import { initializeMap, setupPolygonMapLayer } from "../../../components/Charts/MapboxMaps/mapboxUtils"

mapboxgl.workerClass = MapboxWorker
mapboxgl.accessToken = token
const AddLocationsMap = function MapInput({ setCoords, coords, isAddingEditing }) {
    const [map, setMap] = useState(null)
    const [layer, setLayer] = useState(MAP_DEFAULT_LAYER)

    const mapContainer = useRef()
    const currentMarker = useRef(null)

    const getLocationOrDefaultCoords = () => {
        return new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        lat: position.coords.latitude,
                        lon: position.coords.longitude,
                    })
                },
                () => {
                    resolve({
                        lat: Number(coords.lat),
                        lon: Number(coords.lon),
                    })
                }
            )
        })
    }

    const getMarkerElement = (imageName, id) => {
        const el = document.createElement("div")
        el.id = id
        el.style.backgroundImage = "url(/" + imageName + ")"
        el.className =
            "flex items-top justify-center cursor-pointer p-0 border-0 w-[30px] h-[36px] bg-no-repeat bg-bottom bg-contain"
        if (id === "new-location-marker") el.style.zIndex = "9"
        return el
    }

    useEffect(async () => {
        if (map === null) return

        const location = await getLocationOrDefaultCoords()
        const lat = location.lat.toFixed(4)
        const lon = location.lon.toFixed(4)

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false,
            placeholder: "Map Search",
        })

        geocoder.on("result", (ev) => {
            setCoords({
                lat: ev.result.center[1],
                lon: ev.result.center[0],
            })
        })

        map.addControl(geocoder, "top-left")

        currentMarker.current = new mapboxgl.Marker({
            draggable: true,
            element: getMarkerElement("map-new-location-marker.png", "new-location-marker"),
        })
        currentMarker.current.setLngLat([lon, lat])
        currentMarker.current.addTo(map)
        currentMarker.current.on("dragend", function (e) {
            setCoords({
                lat: e.target._lngLat.lat.toFixed(4),
                lon: e.target._lngLat.lng.toFixed(4),
            })
        })

        map.on("click", function (e) {
            setCoords({
                lat: e.lngLat.lat.toFixed(4),
                lon: e.lngLat.lng.toFixed(4),
            })
        })

        setTimeout(() => {
            map.resize()
            map.flyTo({
                zoom: 6,
                speed: 1,
                curve: 1,
            })
        }, 400)
    }, [map])

    useEffect(async () => {
        const location = await getLocationOrDefaultCoords()
        const lat = location.lat.toFixed(4)
        const lon = location.lon.toFixed(4)
        setCoords({ lat, lon })
        setMap(
            initializeMap({
                mapContainerRefCurrent: mapContainer.current,
                layer,
                center: [lon, lat],
                zoom: 3,
                minZoom: 1.4,
                maxZoom: 20,
            })
        )
    }, [])

    useEffect(() => {
        if (currentMarker.current && coords && coords.lon && coords.lat) {
            currentMarker.current.setLngLat([coords.lon, coords.lat])
            map.flyTo({ center: [coords.lon, coords.lat] })
        }
    }, [coords])

    useEffect(() => {
        if (isAddingEditing) {
            if (document.getElementById("new-location-marker")) {
                document.getElementById("new-location-marker").style.display = "block"
            }
            if (document.getElementById("marker-text-id"))
                document.getElementById("marker-text-id").innerHTML = "New Location"
        } else {
            if (document.getElementById("new-location-marker")) {
                document.getElementById("new-location-marker").style.display = "none"
            }
        }
    }, [isAddingEditing])

    useEffect(() => {
        if (!map) return
        removeLayers()
        map.setStyle(layer)
        delay(200).then(() => setupPolygonMapLayer(map, []))
    }, [layer])

    const removeLayers = () => {
        if (map.getLayer("polygon-layer")) map.removeLayer("polygon-layer")
        if (map.getSource("polygon-shape")) map.removeSource("polygon-shape")
    }

    const delay = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time))
    }

    return (
        <div className="h-full">
            <CustomMapControls map={map} toggleLayer={() => setLayer(MAP_LAYER_TOGGLER()[layer])} />
            <div className="map-container" ref={(el) => (mapContainer.current = el)} />
        </div>
    )
}

export default AddLocationsMap

import Tooltip from "../../../ui/Tooltip"
import "../../../ui/Icons/Styles/Icons.css"

function CropTableAlert(props) {
    const { value = 0, showCount = true } = props
    let count = value
    if (count > 0) {
        return (
            <Tooltip
                content={
                    showCount ? count + " alert " + (count > 1 ? "types" : "type") + " triggered" : "alerts triggered"
                }
                position="right"
            >
                {count && <label className="alert-icon__indicator">{showCount ? count : ""}</label>}
            </Tooltip>
        )
    } else {
        return <div></div>
    }
}

export default CropTableAlert

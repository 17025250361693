import * as React from "react"

function SvgComponent(props, ref = null) {
    const { fill = "#1D2934" } = props
    return (
        <svg width={5} height={10} viewBox="0 0 5 10" {...props} ref={ref}>
            <path d="M4.5 5L0 9.763V.237L4.5 5z" fill={fill} />
        </svg>
    )
}

export default React.forwardRef(SvgComponent)

import ArrowRight from "../Icons/ArrowRight"

import "./MenuItem.css"

function MenuItem(props) {
    const {
        label,
        icon: IconComponent,
        onClick = () => null,
        children,
        styleToApply = undefined,
        active = false,
    } = props
    return (
        <>
            <div className={"menu-item" + ((active && " bg-gray-5") || "")} onClick={onClick}>
                {IconComponent && (
                    <div className="select__icon">
                        <IconComponent fill="#666d74" />
                    </div>
                )}
                <div className="select__value menu-item__value">
                    {/*/ This inner span is needed since text-overflow: ellipsis; does not work on a flex-aligned container/*/}
                    <span className={"menu-item__value_label"}>
                        <div className={styleToApply ? styleToApply : ""}>{label}</div>
                    </span>
                    {children && (
                        <div className="menu-item__value__arrow">
                            <ArrowRight fill="#666D74" />
                        </div>
                    )}
                </div>
                {children && <div className="menu__children menu-item__children left">{children}</div>}
            </div>
        </>
    )
}

export default MenuItem

import { useMemo } from "react"

export default function useLastUpdated(data, monthlyData, weeklyData) {
    return useMemo(() => {
        let result = {
            hourly: {
                ds_hist: 0,
                ds_fc: 0,
                ds_clim: 0,
                pending: true,
            },
            daily: {
                ds_hist: 0,
                ds_fc: 0,
                ds_clim: 0,
                pending: true,
            },
            monthly: {
                ds_hist: 0,
                ds_fc: 0,
                ds_clim: 0,
                pending: true,
            },
            weekly: {
                ds_hist: null,
                ds_fc: null,
                ds_clim: null,
                pending: true,
            },
        }

        if (data) {
            let lastUpdatedDaily = {}

            for (const [key, value] of Object.entries(data)) {
                if (key === "pending") continue
                lastUpdatedDaily[key] = value.last_updated
            }

            result = { ...result, daily: lastUpdatedDaily }
        }

        if (monthlyData) {
            let lastUpdatedMonthly = {}
            for (const [key, value] of Object.entries(monthlyData)) {
                if (key === "pending") continue
                lastUpdatedMonthly[key] = value.last_updated
            }

            result = { ...result, monthly: lastUpdatedMonthly }
        }

        if (weeklyData) {
            let lastUpdatedWeekly = {}
            for (const [key, value] of Object.entries(weeklyData)) {
                if (key === "pending") continue
                lastUpdatedWeekly[key] = value.last_updated
            }

            result = { ...result, weekly: lastUpdatedWeekly }
        }

        return result
    }, [data, monthlyData, weeklyData])
}

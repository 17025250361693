import React from "react"

// Components
import Tooltip from "../../../ui/Tooltip"
import TrashIcon from "../../../ui/Icons/TrashIcon"
import EditIcon from "../../../ui/Icons/EditIcon"

function CropTableActions(props) {
    const {
        rowData,
        rowOptions: { toggleDelete },
        onToggleAddEditField,
        featurePermissions,
    } = props

    return (
        <>
            <div className="relative flex">
                {featurePermissions?.field_tools?.edit && (
                    <Tooltip content="Edit field" position="left">
                        <button
                            className="w-6 h-6 crop-table__icon-button"
                            onClick={() => {
                                onToggleAddEditField(rowData)
                            }}
                        >
                            <div className="crop-table__edit-icon">
                                <EditIcon fill="var(--color-gray-60)" />
                            </div>
                        </button>
                    </Tooltip>
                )}
                {featurePermissions?.field_tools?.delete && (
                    <Tooltip content="Delete field" position="left">
                        <button className="w-6 h-6 crop-table__icon-button" onClick={() => toggleDelete()}>
                            <TrashIcon size="100%" className="crop-table__trash-icon" fill="var(--color-gray-60)" />
                        </button>
                    </Tooltip>
                )}
            </div>
        </>
    )
}

export default CropTableActions

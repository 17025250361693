const DrySoilRiskIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path
                d="M16.0851 11.3257C16.2351 10.4229 15.9851 9.52 15.4151 8.81143L15.3451 8.74286C14.3151 7.58857 13.8451 6.04571 14.0451 4.49143L14.1051 4H15.9951L15.9051 4.67429C15.7551 5.57714 16.0051 6.48 16.5751 7.18857L16.6451 7.26857C17.6751 8.41143 18.1551 9.95429 17.9451 11.5086L17.8851 12H15.9751L16.0851 11.3257Z"
                fill={props.fill}
            />
            <path
                d="M12.0851 11.3257C12.2351 10.4229 11.9851 9.52 11.4151 8.81143L11.3451 8.74286C10.3151 7.58857 9.84515 6.04571 10.0451 4.49143L10.1051 4H11.9951L11.9051 4.67429C11.7551 5.57714 12.0051 6.48 12.5751 7.18857L12.6451 7.26857C13.6751 8.41143 14.1551 9.95429 13.9451 11.5086L13.8851 12H11.9751L12.0851 11.3257Z"
                fill={props.fill}
            />
            <path
                d="M8.08515 11.3257C8.23515 10.4229 7.98515 9.52 7.41515 8.81143L7.34515 8.74286C6.31515 7.58857 5.84515 6.04571 6.04515 4.49143L6.10515 4H7.99515L7.90515 4.67429C7.75515 5.57714 8.00515 6.48 8.57515 7.18857L8.64515 7.26857C9.67515 8.41143 10.1551 9.95429 9.94515 11.5086L9.88515 12H7.97515L8.08515 11.3257Z"
                fill={props.fill}
            />
            <rect x="3" y="14" width="18" height="2" fill={props.fill} />
            <rect x="3" y="19" width="18" height="2" fill={props.fill} />
        </svg>
    )
}

export default DrySoilRiskIcon

import { useContext, useState, useRef, useEffect } from "react"
import app from "../Util/Fire"
import { Link as RouterLink } from "react-router-dom"
import { withRouter } from "react-router"
import { useTranslation } from "react-i18next"

import firebase from "firebase/compat/app"
import { capitalizeFirstCharacter, capitalizeFirstCharacterOfEachWord } from "../helpers/wordHelper"

import { demo_password, demo_user } from "../Util/config"
import validateEmail from "../Util/Validate"
import BackgroundAndCardLayout from "./BackgroundAndCardLayout"
import { Button } from "./Button"
import { GenericFromInputAndLabel } from "./SignUp"
import "./SignIn.css"
import { ModalContext } from "./Modal/ModalContextProvider"

const SignIn = () => {
    const { t } = useTranslation()

    // TODO: Set default to user's check
    const [rememberUser, setRememberUser] = useState(!!localStorage.getItem("@flag/remember-me") || true)

    const { infoModal } = useContext(ModalContext)

    const loginClick = useRef(null)
    const emailInput = useRef(null)
    const passwordInput = useRef(null)
    const demoEmailInput = useRef(null)

    const handleLogin = async (event) => {
        event.preventDefault()
        const { email, password } = event.target.elements
        try {
            if (rememberUser === null) setRememberUser(false)
            await app
                .auth()
                .setPersistence(
                    rememberUser ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION
                )
            await app.auth().signInWithEmailAndPassword(email.value, password.value)
        } catch (error) {
            // alert(error)
            infoModal({
                title: "Error",
                text: error.message,
                onContinueLabel: "OK",
            })
        }
    }

    useEffect(() => {
        if (rememberUser === null) {
            // User just clicked the demo login button, we should disable "remember me" just for this login
            loginClick.current.click()
        }
    }, [rememberUser])

    function handleRememberUser(e) {
        console.log(e.target.checked)
        if (e.target.checked) localStorage.setItem("@flag/remember-me", "true")
        else localStorage.removeItem("@flag/remember-me")

        setRememberUser(e.target.checked)
    }

    const { skin, signInTitle, trackDemoEmail, demoCredentials } = window.dashboard

    function demoLogin() {
        if (trackDemoEmail) {
            let demoEmail = demoEmailInput.current.value.trim()
            if (!validateEmail(demoEmail)) {
                //TODO: Once the localization PR gets merged update this to use the translation var
                infoModal({
                    title: "Error",
                    text: "Please enter a valid email address",
                    onContinueLabel: "OK",
                })
                return
            }

            // Initialize and Track the event
            let pendo_object = {
                visitor_id: "demo@climate.ai",
                account_id: "demo@climate.ai",
            }

            //Note: This pendo.init is necessary so that the track event gets attributed to the `demo@climate.ai`
            window["wrapper_pendo"](pendo_object)
            window["wrapper_pendo_tracking"]("demo_skip", { email: demoEmail })
        }

        emailInput.current.value = demoCredentials?.user || demo_user
        passwordInput.current.value = demoCredentials?.password || demo_password

        // Disable "remember me" for demo logins
        // This will also automatically trigger the login once the state is changed
        if (rememberUser) setRememberUser(null)
        else loginClick.current.click()
    }

    return (
        <BackgroundAndCardLayout>
            <div className="montserrat text-[16px] text-gray-90 font-semibold text-center leading-tight">
                {capitalizeFirstCharacterOfEachWord(signInTitle || t("operational_dashboard"))}
            </div>

            {trackDemoEmail ? (
                <GenericFromInputAndLabel
                    label="Email Address"
                    placeholder="Required for Demo"
                    type="email"
                    noValidate
                    id="demo_email"
                    name="demo_email"
                    inputRef={demoEmailInput}
                />
            ) : null}
            {(process.env.REACT_APP_STAGE !== "production" || skin === "skip") && (
                <Button label={capitalizeFirstCharacter(t("demo"))} extend={true} onClick={demoLogin} />
            )}

            <div className="h-[1px] w-full border-b-[1px] border-gray-10"></div>

            <form className="self-stretch flex flex-col space-y-4" noValidate onSubmit={handleLogin}>
                <GenericFromInputAndLabel
                    label={capitalizeFirstCharacterOfEachWord(t("email_address"))}
                    placeholder={t("email_placeholder")}
                    type="email"
                    id="email"
                    name="email"
                    inputRef={emailInput}
                />
                <GenericFromInputAndLabel
                    label={capitalizeFirstCharacterOfEachWord(t("password"))}
                    placeholder="********"
                    type="password"
                    id="password"
                    name="password"
                    inputRef={passwordInput}
                />

                <RouterLink
                    className="roboto text-[14px] text-accent hover:underline self-start"
                    to="/restore-password"
                >
                    {capitalizeFirstCharacter(t("forgot_password"))}
                </RouterLink>

                <div className="sign-in-side__checkbox-container">
                    <span className="sign-in-side__checkbox">
                        <input
                            type="checkbox"
                            checked={rememberUser}
                            onChange={handleRememberUser}
                            id="remember-me-checkbox__log-in"
                            className="sign-in-side__checkbox-input"
                        />
                        <label className="sign-in-side__checkbox-label">
                            {rememberUser && (
                                <svg
                                    width="90%"
                                    height="90%"
                                    viewBox="0 0 8 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.74982 4.73743L1.01232 2.99993L0.420654 3.58743L2.74982 5.9166L7.74982 0.916601L7.16232 0.329102L2.74982 4.73743Z"
                                        fill="white"
                                    />
                                </svg>
                            )}
                        </label>
                    </span>
                    <div className="sign-in-side__checkbox-text">{capitalizeFirstCharacter(t("remember_me"))}</div>
                </div>

                <Button
                    forwardedRef={loginClick}
                    label={capitalizeFirstCharacterOfEachWord(t("log_in"))}
                    type="primary"
                    extend={true}
                    submit
                />
            </form>

            <div className="h-[1px] w-full border-b-[1px] border-gray-10"></div>

            <div className="roboto text-[16px] text-gray-90">{capitalizeFirstCharacter(t("no_account"))}</div>
            <RouterLink className="self-stretch pb-8" to="/signup">
                <Button label={capitalizeFirstCharacterOfEachWord(t("sign_up"))} extend={true} type="secondary" />
            </RouterLink>
        </BackgroundAndCardLayout>
    )
}

export default withRouter(SignIn)

import React from "react"

import Checkbox from "../../../ui/Checkbox/Checkbox"
import ArrowRight from "../../../ui/Icons/ArrowRight"

function CropTableCheck(props) {
    const {
        rowOptions: {
            canToggleContent,
            currentPath,
            isEditable,
            toggleContent,
            handleCheckChange,
            checked,
            checkedStatus,
        },
    } = props
    return (
        <>
            <div className="crop-table-check">
                <Checkbox checked={checked} status={checkedStatus} onChange={handleCheckChange} />
                {!isEditable && canToggleContent && (
                    <button className="crop-table__toggler-button" onClick={toggleContent}>
                        {!currentPath && (
                            <ArrowRight
                                style={{ width: "100%", height: "100%" }}
                                className="crop-table__toggler-icon"
                            />
                        )}
                        {currentPath && (
                            <ArrowRight
                                style={{ width: "100%", height: "100%" }}
                                className="crop-table__toggler-icon--open"
                            />
                        )}
                    </button>
                )}
            </div>
        </>
    )
}

export default React.memo(CropTableCheck)

import Menu from "./Menu"
import MenuItem from "./MenuItem"

export default function ScenarioDropdownSelection(props) {
    const { selectedScenario, scenarios, setSelectedScenario, keyPrefix } = props
    return (
        <Menu className="left" text={scenarios[selectedScenario]?.label}>
            {scenarios &&
                Object.values(scenarios).map((scenario) => (
                    <MenuItem
                        active={selectedScenario === scenario.value}
                        label={scenario.label}
                        key={`${keyPrefix}_${scenario.value}`}
                        onClick={() => setSelectedScenario(scenario.value)}
                    />
                ))}
        </Menu>
    )
}

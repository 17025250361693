export const Button = ({
    icon = null,
    type = "primary",
    extend = false,
    disabled = false,
    label = "",
    extraClasses = "",
    onClick = () => {},
    forwardedRef = null,
    submit = false,
    labelStyle = undefined,
}) => {
    return (
        <button
            ref={forwardedRef}
            type={submit ? "submit" : "button"}
            disabled={disabled}
            className={[
                "box-border border font-medium rounded-lg roboto text-[16px] px-3 h-[36px] leading-4 flex flex-row justify-center items-center space-x-2 whitespace-nowrap",
                type === "primary"
                    ? "bg-accent border-transparent text-white fill-white hover:bg-accent-dark disabled:bg-gray-10 disabled:text-gray-30 disabled:fill-gray-30"
                    : "bg-transparent border-accent-medium fill-accent text-accent hover:border-accent hover:text-accent-dark hover:fill-accent-dark disabled:border-gray-10 disabled:text-gray-30 disabled:fill-gray-30",
                extend ? "w-full" : "",
                disabled ? "cursor-not-allowed" : "cursor-pointer",
                extraClasses,
            ].join(" ")}
            onClick={onClick}
        >
            {icon !== null && <span className="w-[20px] h-[20px]">{icon}</span>}
            <span className={labelStyle !== undefined ? labelStyle : ""}>{label}</span>
        </button>
    )
}

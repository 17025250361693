import "react-datepicker/dist/react-datepicker.css"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"
import Section from "../../../ui/Section"
import useContainerSize from "../../../hooks/useContainerSize"
import LoadingBar from "../../../components/LoadingBar"
import ScenarioDropdownSelection from "../../../ui/Controls/ScenarioDropdownSelection"
import LocationDropdownSelection from "../../../ui/Controls/LocationDropdownSelection"
import CropDropdownSelection from "../../../ui/Controls/CropDropdownSelection"
import RiskDropdownSelection from "../../../ui/Controls/RiskDropdownSelection"
import { getMaxYValue } from "../../../Util/candleGraphUtils"
import ExportButton from "../../../components/ExportButton"
import { CLIMATE_CSV_HEADERS, formatClimateCSVData } from "../../../Util/CSVParser"
import useCSVData from "../../../hooks/useCSVData"
import { getYLabel } from "../../../Util/CADChartLabel"
import { useEffect } from "react"

function DecadalRiskChart(props) {
    const {
        units,
        maxValue,
        minValue,
        loading,
        data,
        // locations dropdown
        locations,
        selectedLocation,
        setSelectedLocation = () => null,
        // crop dropdown
        crops,
        selectedCrop,
        selectedVariety,
        setSelectedCropVariety = () => null,
        // scenario dropdown
        scenarios,
        selectedScenario,
        setSelectedScenario = () => null,
        // risk dropdown
        risks,
        selectedRisk,
        setSelectedRisk = () => null,
        riskVariable,
    } = props

    const [containerRef, { height }] = useContainerSize(1000, [
        selectedLocation,
        selectedCrop,
        selectedScenario,
        selectedRisk,
    ])

    const _risk = risks[selectedRisk]
    let labelY = "Probability (%)"

    // if case !== 1, there is no units (days or events)
    let tickFormat = "%"
    const yTickFormat = (d) => (d < 0 || d > 100 ? "" : d)

    function addYears(date, years) {
        const _date = new Date(date.getTime())
        _date.setFullYear(_date.getFullYear() + years)
        return _date
    }

    const chartXMin = data && data.points && data.points.length > 0 && addYears(data.points[0].x, -10)
    const chartXMax =
        data && data.points && data.points.length > 0 && addYears(data.points[data.points.length - 1].x, +10)

    const { csvHeaders, csvData, csvString } = useCSVData(
        formatClimateCSVData(data),
        CLIMATE_CSV_HEADERS,
        `Decadal Risk Data
        Category:,${selectedCrop + "/" + selectedVariety}
        Location:,${locations[selectedLocation]?.location}
        Scenario:,${scenarios[selectedScenario]?.label}
        Risk:,${risks[selectedRisk]?.title + " (" + risks[selectedRisk]?.stage + ")"}
        Description:,${risks[selectedRisk]?.description?.replaceAll(",", "/")}
        \n`,
        !loading
    )

    return (
        <Section
            title="Decadal Risk"
            id="decadal-risk-section"
            description="This page displays the decadal probability of the chosen climate risk (defined for a crop variety) at a given location."
            header={() => (
                <>
                    <CropDropdownSelection
                        selectedCrop={selectedCrop}
                        selectedVariety={selectedVariety}
                        crops={crops}
                        selectCropVariety={setSelectedCropVariety}
                        keyPrefix={"decadal_crops"}
                    />
                    <LocationDropdownSelection
                        selectedLocation={selectedLocation}
                        locations={locations}
                        setSelectedLocation={setSelectedLocation}
                        keyPrefix={"decadal_location"}
                    />
                    <RiskDropdownSelection
                        selectedRisk={selectedRisk}
                        risks={risks}
                        setSelectedRisk={setSelectedRisk}
                        keyPrefix={"decadal_risks"}
                    />

                    <ScenarioDropdownSelection
                        selectedScenario={selectedScenario}
                        scenarios={scenarios}
                        setSelectedScenario={setSelectedScenario}
                        keyPrefix={"decadal_scenario"}
                    />
                    <ExportButton
                        cols={{ csv: csvHeaders }}
                        data={{ csv: csvData }}
                        csvString={csvString}
                        wrapperClasses="absolute top-3 right-2"
                        fileName={"Decadal_risk-" + locations[selectedLocation]?.location + "-" + selectedCrop}
                        idToExport="decadal-risk-section"
                    />
                </>
            )}
        >
            {loading && <LoadingBar loading />}
            <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
                <LineAreaChartComponent
                    labelY={labelY}
                    xTicksCount={data?.points?.length - 1}
                    yValueMax={Math.min(100, getMaxYValue(maxValue, minValue, 10))}
                    zeroBasis={minValue === 0}
                    marginLeft={80}
                    marginRight={80}
                    xDateMin={chartXMin}
                    xDateMax={chartXMax}
                    labelYOffsetX={-20}
                    candlePadding={0}
                    candleTopBottomLines={true}
                    centerTicks={true}
                    yTopOffset={0.3}
                    yBottomOffset={0.3}
                    yTickFormat={yTickFormat}
                    svgHeight={(height && height - 10) || 0}
                    svgWidth="100%"
                    xTickFormat={(d) => {
                        let y = d.getFullYear()
                        return (y % 10 === 0 && y <= 2060 && y) || ""
                    }}
                    shapeTip={(e, d, arr) => {
                        return `
                      <table>
                        <tr><th style="text-align:right">95th</th><td>${
                            d.y1 != undefined && d.y1.toFixed(0) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">75th</th><td>${
                            d.yMax != undefined && d.yMax.toFixed(0) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">50th</th><td>${
                            d.yMid != undefined && d.yMid.toFixed(0) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">25th</th><td>${
                            d.yMin != undefined && d.yMin.toFixed(0) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">5th</th><td>${
                            d.y0 != undefined && d.y0.toFixed(0) + " " + tickFormat
                        }</td></tr>
                      </table>
                    `
                    }}
                    data={data}
                ></LineAreaChartComponent>
            </div>
        </Section>
    )
}

export default DecadalRiskChart

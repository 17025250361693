import CancelIcon from "../ui/Icons/newIcons/CancelIcon"

const Chip = ({ value, onRemove, id }) => {
    return (
        <div
            key={id}
            className="flex items-center p-1 border rounded border-accent-medium bg-accent-light text-accent"
            onClick={(e) => e.stopPropagation()}
        >
            <div className="cursor-default">{value}</div>
            {onRemove && (
                <button
                    className="w-4 h-4 ml-1 fill-accent-medium hover:fill-accent"
                    onClick={(e) => {
                        e.stopPropagation()
                        onRemove()
                    }}
                >
                    <CancelIcon />
                </button>
            )}
        </div>
    )
}

export default Chip

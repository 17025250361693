import React, { useContext, useRef } from "react"
import AlertContainer from "./AlertContainer"
import { RISKS } from "./RisksList"
import { STACKED_RISKS } from "./RisksList"
import { AuthContext } from "../../../../Auth/Auth"
import { SettingsContext } from "../../../../Util/SettingsContext"
import { ToastContext } from "../../../../components/Toast/ToastContextProvider"
import DownloadIcon from "../../../../ui/Icons/newIcons/DownloadIcon"
import UploadIcon from "../../../../ui/Icons/newIcons/UploadIcon"
import { Button } from "../../../../components/Button"
import getRiskIcon from "../../../../Util/getRiskIcon"

function VarietyAlert(props) {
    const {
        varietyObj,
        path,
        onVarietyFileDownload = () => null,
        onVarietyFileUpload = () => null,
        isDownloadEnabled,
        isUploadEnabled,
        stackedAlertsView,
        onAlertStatusChange = () => null,
        reloadAlertSettings = () => null,
    } = props
    const {
        currentSettings: { units },
    } = useContext(SettingsContext)
    const { currentUser, featurePermissions } = useContext(AuthContext)
    const { enqueueError } = useContext(ToastContext)
    const RISK_LIST = !stackedAlertsView ? RISKS : STACKED_RISKS

    function handleDownload() {
        onVarietyFileDownload(path.crop, path.variety)
    }

    function handleCSVUpload(e) {
        e.persist()
        const fileExt = e.target.value.split(".").pop()
        if (fileExt !== "csv") {
            enqueueError("Only .csv files are accepted.")
            return
        }
        onVarietyFileUpload(path.crop, path.variety, e.target.files[0])
    }

    const fileInputRef = useRef(null)

    return (
        <div>
            <div className="flex flex-row max-w-md pt-3 space-x-2">
                {isDownloadEnabled && !stackedAlertsView && (
                    <Button icon={<DownloadIcon />} onClick={handleDownload} label="Download Alerts" />
                )}
                {isUploadEnabled && !stackedAlertsView && (
                    <>
                        <Button
                            icon={<UploadIcon />}
                            onClick={() => fileInputRef.current.click()}
                            label="Upload Alerts"
                        />
                        <input
                            ref={fileInputRef}
                            type="file"
                            className="invisible"
                            onChange={handleCSVUpload}
                            accept=".csv"
                        />
                    </>
                )}
            </div>

            {Object.keys(RISK_LIST).map((mRisk) => {
                let risk = !stackedAlertsView ? RISKS[mRisk] : STACKED_RISKS[mRisk]
                let alertsObj = varietyObj[mRisk]
                return (
                    <AlertContainer
                        key={"risk-" + mRisk}
                        riskData={risk}
                        icon={getRiskIcon(mRisk)}
                        alertsObj={alertsObj}
                        path={{ ...path, risk: mRisk }}
                        units={units}
                        currentUser={currentUser}
                        featurePermissions={featurePermissions}
                        onAlertStatusChange={onAlertStatusChange}
                        reloadAlertSettings={reloadAlertSettings}
                    />
                )
            })}
        </div>
    )
}

export default React.memo(VarietyAlert)

import Menu from "./Menu"
import MenuItem from "./MenuItem"
import getRiskIcon from "../../Util/getRiskIcon"
import Tooltip from "../Tooltip"
import FixedLayer from "../FixedLayer/FixedLayer"
import { useContext, useState } from "react"
import { formatRiskDescription } from "../../views/Overview/Overview"
import { SettingsContext } from "../../Util/SettingsContext"

export default function RiskDropdownSelection(props) {
    const { selectedRisk, risks, setSelectedRisk, keyPrefix } = props

    const selectedRiskObj = risks[selectedRisk]

    const [hover, toggleHover] = useState(false)

    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    const getRiskIconComponent = (icon) => {
        const IconComponent = getRiskIcon(icon)
        return <IconComponent fill="gray" />
    }
    return (
        <div className="relative">
            <Menu
                className="left"
                icon={getRiskIcon(selectedRiskObj?.icon)}
                text={`${selectedRiskObj?.title} (${selectedRiskObj?.stage})`}
                toggleHover={toggleHover}
            >
                {risks &&
                    Object.values(risks).map((risk) => (
                        <Tooltip
                            white
                            key={risk.id}
                            customStyle={"relative group flex items-center transition-all duration-100"}
                            size="medium"
                            content={
                                <FixedLayer xPosition="right" yPosition="bottom">
                                    <div className="w-[250px] h-full p-2 flex text-left text-[14px] bg-white border rounded-lg mt-[-25px] elevation-1">
                                        <div className="w-[24px] self-start">{getRiskIconComponent(risk?.icon)}</div>
                                        <div className="flex-1 pl-3 pt-.5">
                                            <h6 className="text-body">{risk?.title}</h6>
                                            <p className="pt-1 text-gray-30 roboto">{risk?.stage}</p>
                                            <p className="pt-1 text-gray-60">
                                                {(risk?.description &&
                                                    formatRiskDescription(risk?.description, units)) ||
                                                    "No description available."}
                                            </p>
                                        </div>
                                    </div>
                                </FixedLayer>
                            }
                            position="left"
                        >
                            <MenuItem
                                active={selectedRisk === risk.id}
                                label={`${risk.title} (${risk.stage})`}
                                key={`${keyPrefix}_${risk.id}`}
                                icon={getRiskIcon(risk.icon)}
                                onClick={() => selectedRisk !== risk.id && setSelectedRisk(risk.id)}
                            />
                        </Tooltip>
                    ))}
            </Menu>
            {hover && (
                <FixedLayer xPosition="right" yPosition="bottom" inheritWidth>
                    <div className="w-full h-full p-2 flex text-left text-[14px] bg-white border rounded-lg mt-[3px] elevation-1">
                        <div className="w-[24px] self-start">{getRiskIconComponent(selectedRiskObj?.icon)}</div>
                        <div className="flex-1 pl-3 pt-.5">
                            <h6 className="text-gray-90">{selectedRiskObj?.title}</h6>
                            <p className="pt-1 text-gray-30 roboto">{selectedRiskObj?.stage}</p>
                            <p className="pt-1 text-gray-60">
                                {(selectedRiskObj?.description &&
                                    formatRiskDescription(selectedRiskObj?.description, units)) ||
                                    "No description available."}
                            </p>
                        </div>
                    </div>
                </FixedLayer>
            )}
        </div>
    )
}

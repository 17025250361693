import React from "react"
import ClimateLogoWhite from "../../ui/Icons/ClimateLogoWhite"
import ComputerIcon from "../../ui/Icons/ComputerIcon"

function NotAllowedDevice(props) {
    const {} = props
    return (
        <div className="w-full h-full flex flex-1 flex-col justify-center items-center">
            <div className="w-[150px] absolute top-[80px]">
                <img src="/ClimateAI_Logo.png" alt="" />
            </div>
            <div className="flex flex-col justify-center items-center">
                <div className="w-[75px] h-[75px] fill-gray-60">
                    <ComputerIcon />
                </div>
                <p className="max-w-[250px] text-center pt-3 leading-6">
                    Please visit ClimateAi’s Platform on a desktop device.
                </p>
            </div>
        </div>
    )
}

export default NotAllowedDevice

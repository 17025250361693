import React, { useRef } from "react"
import TweakIcon from "../../../../ui/Icons/TweakIcon"
import TimesIcon from "../../../../ui/Icons/TimesIcon"
import TrashAltIcon from "../../../../ui/Icons/TrashAltIcon"
import SearchIcon from "../../../../ui/Icons/SearchIcon"
import Checkbox from "../../../../ui/Checkbox/Checkbox"
import AltArrowLeft from "../../../../ui/Icons/newIcons/AltArrowLeft"

const ColumnHeader = ({
    title,
    checkedItems,
    items,
    setCheckedItems,
    isActive = true,
    type = "",
    tweakingType = false,
    setTweakingType = () => null,
    setPosibleType = () => null,
    deleteCheckedItems = () => null,
    debounceSearch = () => null,
    closeSearch = () => null,
    openSearch = () => null,
    searchTarget = "",
    goBack = null,
    prevSelectedItemName = "",
}) => {
    const checkedItemsLength = Object.keys(checkedItems).length
    const itemsLength = Object.keys(items).length

    const searchRef = useRef(null)

    return (
        <div
            className={
                "h-[50px] px-4 flex-none flex flex-row items-center justify-between relative border-b-2 border-gray-100" +
                (searchTarget !== title ? "" : " py-2")
            }
        >
            {checkedItemsLength === 0 ? (
                title !== "Stages" ? (
                    <>
                        {isActive && (
                            <span
                                className={
                                    "absolute" +
                                    (searchTarget !== title
                                        ? " h-6 w-[24px] right-6 cursor-pointer"
                                        : " h-5 w-[20px] left-6")
                                }
                                onClick={() => {
                                    openSearch(title)
                                }}
                            >
                                <SearchIcon fill="#666D74" />
                            </span>
                        )}
                        {searchTarget !== title ? (
                            <div className="flex flex-row items-center justify-center space-x-4">
                                {goBack !== null && (
                                    <span className="w-6 md:hidden" onClick={goBack}>
                                        <AltArrowLeft />
                                    </span>
                                )}
                                <div
                                    className={
                                        "text-md font-semibold montserrat hidden md:block" +
                                        (isActive ? "" : " text-gray-400")
                                    }
                                >
                                    {title}{" "}
                                    {isActive && <span className="text-sm text-gray-300 ml-2">{itemsLength}</span>}
                                </div>
                                <div className="text-md font-semibold montserrat block md:hidden">
                                    {prevSelectedItemName}
                                </div>
                            </div>
                        ) : (
                            <>
                                <input
                                    ref={searchRef}
                                    type="text"
                                    placeholder="Search"
                                    className="border border-gray-300 rounded-md h-9 pl-9 w-full focus:outline-none"
                                    autoFocus
                                    onKeyUp={(event) => {
                                        debounceSearch(event.target.value)
                                    }}
                                />
                                <span className="h-5 w-[20px] absolute right-7 cursor-pointer" onClick={closeSearch}>
                                    <TimesIcon fill="#666D74" />
                                </span>
                            </>
                        )}
                    </>
                ) : isActive ? (
                    type !== "" && !tweakingType ? (
                        <>
                            <div className="flex flex-row items-center justify-center space-x-4">
                                {goBack !== null && (
                                    <span className="w-6 md:hidden" onClick={goBack}>
                                        <AltArrowLeft />
                                    </span>
                                )}
                                <div className="text-md font-semibold montserrat text-gray-900 hidden md:block">
                                    {title}
                                </div>
                                <div className="text-md font-semibold montserrat text-gray-900 block md:hidden">
                                    {prevSelectedItemName}
                                </div>
                            </div>
                            <div
                                className="flex flex-row items-center py-1 px-3 rounded-md cursor-pointer hover:bg-gray-100"
                                onClick={() => {
                                    setTweakingType(true)
                                    setPosibleType(type)
                                }}
                            >
                                <span className="uppercase text-gray-300 text-sm mr-2 mt-1">{type}</span>
                                <span className="h-5 w-[20px]">
                                    <TweakIcon />
                                </span>
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-row items-center justify-center space-x-4">
                            <span
                                className="w-6 md:hidden"
                                onClick={() => {
                                    if (type !== "") {
                                        setTweakingType(false)
                                        setPosibleType("")
                                    } else {
                                        goBack()
                                    }
                                }}
                            >
                                <AltArrowLeft />
                            </span>
                            <div className="text-sm py-1 font-semibold montserrat text-gray-400">
                                This stage will be measured by...
                            </div>
                        </div>
                    )
                ) : (
                    <div className="text-md py-1 font-semibold montserrat text-gray-400">{title}</div>
                )
            ) : (
                <>
                    <div className="flex flex-row items-center">
                        <Checkbox
                            fill="#666D74"
                            checked={true}
                            status={checkedItemsLength === itemsLength ? "full" : "half"}
                            onChange={() => {
                                if (checkedItemsLength < itemsLength) {
                                    const newCheckedCrops = {}
                                    Object.keys(items).forEach((key) => {
                                        newCheckedCrops[key] = true
                                    })
                                    setCheckedItems({ ...newCheckedCrops })
                                } else {
                                    setCheckedItems({})
                                }
                            }}
                        />
                        <span className="ml-3 font-semibold">{checkedItemsLength}</span>
                    </div>
                    <div className="flex flex-row items-center space-x-5">
                        <div className="h-6 cursor-pointer" onClick={deleteCheckedItems}>
                            <TrashAltIcon />
                        </div>
                        <div
                            className="h-6 cursor-pointer"
                            onClick={() => {
                                setCheckedItems({})
                            }}
                        >
                            <TimesIcon fill="#666D74" />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default ColumnHeader

import {
  getMonthsBetween,
} from "../../../../helpers/chartHelpers"

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function formatLineChartData({ data, today}) {
  if (!data) return [];
  let localData = data;
  if (data.fc_first_day && data.fc_first_day == "No forecast.") {
    localData = {
      ch_sum: {
        '0.5': data.ch_sum,
        '0.95': data.ch_sum,
        '0.05': data.ch_sum,
        '0.75': data.ch_sum,
        '0.25': data.ch_sum,
      },
      time: data.time,
    }
  }
  let mappedData = localData.time.map((time, i) => {
    return {
      x: new Date(time),
      y: localData.ch_sum['0.5'][i],
      y95: localData.ch_sum['0.95'][i],
      y05: localData.ch_sum['0.05'][i],
      y75: localData.ch_sum['0.75'][i],
      y25: localData.ch_sum['0.25'][i],
    }
  })

  const startMonth = mappedData[0].x;
  const endMonth = mappedData[mappedData.length - 1].x;
  const startMonthDiff = Math.abs(getMonthsBetween(startMonth, today, true));
  const todayIsSameStartMonth = (startMonth.getTime() == today.getTime());//getMonthsBetween(startMonth, today) === 0;
  const todayIsSameEndMonth = (endMonth.getTime() == today.getTime());//getMonthsBetween(endMonth, today) === 0;

  // console.log("PROPS - ", {
  //   today,
  //   startMonthDiff,
  //   startMonth,
  //   endMonth,
  //   todayIsSameStartMonth,
  //   todayIsSameEndMonth,
  //   mappedData,
  // })

  // Scenarios
  if (today < startMonth && today < endMonth && startMonthDiff >= 5) {
    // None (no forecast available) - if no forecast available always show all the history for the past season.
    // console.log('COND1')
    return mappedData;
  } else if (today < startMonth && today < endMonth && startMonthDiff < 5) {
    // Incomplete forecast (excluding dates from today to start month). Show only the forecast we have from start month and beyond.
    mappedData.forEach((d, i) => {
      d.dashed = true;
    })
    // console.log('COND2')
    return mappedData;
  } else if (todayIsSameStartMonth && today < endMonth) {
    // Forecast (from today to end month or end of forecast)
    // console.log('COND3')
    mappedData.forEach((d, i) => {
      d.dashed = true;
    })
    return mappedData
  } else if (today > startMonth && today < endMonth) {
    // History (from start month to yesterday) + forecast (from today to end month or end of forecast)
    // console.log('COND4')
    mappedData.filter(d => d.x > today)
      .forEach((d, i) => {
        d.dashed = true;
      })
    return mappedData;
  } else if (today > startMonth && todayIsSameEndMonth) {
    mappedData[mappedData.length - 1].dashed = true;
    // History (from start month to yesterday) + forecast (today)
    // console.log('COND5')
    return mappedData;
  } else if (today > startMonth && today > endMonth) {
    // History (from start month to end month) - Same as first scenario. 
    // console.log('COND6')
    return mappedData;
  }

  return mappedData;
}

export {
  monthNames,
  formatLineChartData
}

import { useState } from "react"
import useOutsideComponentClickHandler from "../../hooks/ClickOutsideHook"
import ArrowLeft from "../Icons/ArrowLeft"

import "../Select/Select.css"
import "./Menu.css"

function Menu(props) {
    const {
        children,
        icon: IconComponent,
        text,
        className,
        styleToApply = undefined,
        disabled = false,
        childrenStyle = "w-auto",
        toggleHover = () => null,
    } = props
    const [open, toggle] = useState(false)

    const ref = useOutsideComponentClickHandler(() => open && toggle(false))

    return (
        <>
            <div className={"menu" + ((className && " " + className) || "") + ((open && " open") || "")} ref={ref}>
                <div
                    className="w-full select hover:text-gray-90 hover:elevation-1"
                    onClick={() => {
                        if (!disabled) {
                            toggle(!open)
                            toggleHover(false)
                        }
                    }}
                    onMouseEnter={() => !open && toggleHover(true)}
                    onMouseLeave={() => toggleHover(false)}
                >
                    {IconComponent && (
                        <div className="select__icon">
                            <IconComponent fill="#666d74" />
                        </div>
                    )}
                    <div className="select__value">
                        <div className={styleToApply ? styleToApply : ""}>{text}</div>
                    </div>
                    <div className="select__arrow">
                        <ArrowLeft />
                    </div>
                </div>
                {open && (
                    <div className={"menu__children " + childrenStyle} onClick={() => !disabled && toggle(false)}>
                        {children}
                    </div>
                )}
            </div>
        </>
    )
}

export default Menu

import ChevronIcon from "../ui/Icons/newIcons/ChevronArrow"
import Tooltip from "../ui/Tooltip"

const Toggler = ({ open, toggle, openDirection = "right" }) => {
    let rotationClass = ""

    switch (openDirection) {
        case "left":
            // TODO
            rotationClass = open ? "rotate-90" : "-rotate-90"
            break
        case "top":
            // TODO
            rotationClass = open ? "rotate-90" : "-rotate-90"
            break
        case "right":
            rotationClass = open ? "rotate-90" : "-rotate-90"
            break
        case "bottom":
            // TODO
            rotationClass = open ? "rotate-90" : "-rotate-90"
            break
    }

    return (
        <Tooltip content={open ? "Collapse" : "Expand"} position={openDirection}>
            <button
                className={
                    "cursor-pointer rounded-full bg-white elevation-1 h-[24px] w-[24px] fill-gray-60 border border-gray-10 " +
                    " flex flex-col items-center justify-center transition-all duration-75" +
                    " hover:bg-accent hover:fill-white hover:border-accent" +
                    " focus:outline-accent"
                }
                onClick={() => toggle(!open)}
            >
                <div className={"transform " + rotationClass}>
                    <ChevronIcon />
                </div>
            </button>
        </Tooltip>
    )
}

export default Toggler

import { useMemo, useContext } from "react"
import { SettingsContext } from "../Util/SettingsContext"

export default function useDecadalData(data, convertToUnits = (fromUnit, toUnit, val) => val) {
    const {
        currentSettings: { units },
    } = useContext(SettingsContext)

    return useMemo(() => {
        let chartData = {}
        let maxValue = Number.MIN_SAFE_INTEGER
        let minValue = Number.MAX_SAFE_INTEGER

        if (data && Object.keys(data).length > 0 && convertToUnits) {
            const convert = (val) => +convertToUnits("metric", units, val)
            chartData = {
                type: "candlestick",
                color: () => "#1C9690",
                points: [],
            }

            Object.keys(data).forEach((key) => {
                if (!["2010", "2020", "2030", "2040", "2050", "2060"].includes(key)) return

                const item = data[key]
                const item95 = convert(item["0.95"])
                const item05 = convert(item["0.05"])

                if (maxValue < item95) maxValue = item95
                if (minValue > item05) minValue = item05

                // Month of october gets the closer to the center
                chartData.points.push({
                    x: new Date(`Oct 1, ${parseInt(key) + 3}`),
                    y0: item05,
                    y1: item95,
                    yMax: convert(item["0.75"]),
                    yMid: convert(item["0.5"]),
                    yMin: convert(item["0.25"]),
                })
            })
        }

        return { chartData, maxValue, minValue }
    }, [data])
}

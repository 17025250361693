import React, { Children, useState, useReducer, useRef, useEffect, useCallback, useMemo } from "react"

import "./Table.css"

import _ from "lodash"

import TableContent from "./TableContent"
//
import TableContext from "./TableContext"

// reducer
import TableReducer from "./TableReducer"

//
import {
    transformData,
    getRowsFromGroups,
    groupData,
    summarizeRows,
    applyFilters,
    applyFieldTransformation,
} from "../../helpers/table"
import useTableShadow from "../../hooks/useTableShadow"
import FunnelAltIcon from "../Icons/FunnelAltIcon"
import Tooltip from "../Tooltip"
import Chip from "../../components/Chip"
import ExportButton from "../../components/ExportButton"
import useCSVData from "../../hooks/useCSVData"

const REMEMBERED_GROUPING = "MainTableGrouping"

const getInitialGroupingState = () => {
    const rg = localStorage.getItem(REMEMBERED_GROUPING)
    return rg?.length > 1
        ? rg.split(",").map((gb) => {
              return {
                  propName: gb,
                  displayName: gb.charAt(0).toUpperCase() + gb.slice(1),
              }
          })
        : []
}

const getInitialFilterState = (filterStorage) => {
    return JSON.parse(localStorage.getItem(filterStorage)) || []
}

const getInitialState = (filterStorage) => {
    return {
        // groupBy: [{ propName: "region", displayName: "Region" }],
        groupBy: getInitialGroupingState(),
        sortBy: "",
        sortFunctionName: "",
        inputValue: "",
        rows: 10,
        currentPage: 1,
        openPaths: {},
        selected: [],
        selectedLabels: [],
        filters: getInitialFilterState(filterStorage),
    }
}

// from @gregberge/react-flatten-children
function flattenChildren(children) {
    const childrenArray = React.Children.toArray(children)
    return childrenArray.reduce((flatChildren, child) => {
        if (child.type === React.Fragment) {
            return flatChildren.concat(flattenChildren(child.props.children))
        }
        flatChildren.push(child)
        return flatChildren
    }, [])
}

function Table(props) {
    //
    const {
        children,
        data = [],
        onSelectionChanged = () => null,
        onRowAdd = () => null,
        onRowUpdate = () => null,
        onRowDelete = () => null,
        onDataSourceChange = () => null,
        showDataSourceDropdown,
        onRemoveLabelFromField,
        highlightByColumn,
        units,
        className = "",
        renderTableTopBar = () => null,
        noHeaders = false,
        noSummary = false,
        contentClassName = "",
        expanded,
        filterStorage,
        labels,
        onDeleteLabel = () => null,
        onLabelFields = () => null,
        loading = false,
        /* Object of supported export formats, and their format functions. If
         * the object is null or of length 0, it is assumed that exports are not
         * supported by this table. */
        exportFormats = {},
        /* The filename for the export file. If the prop `exportFormats` is not set,
         * this property will be ignored. */
        exportFilename = "table_export",
    } = props

    // state
    const [state, dispatch] = useReducer(TableReducer, getInitialState(filterStorage))
    const { groupBy, sortBy, sortFunctionName, inputValue, rows, currentPage, openPaths, selected, filters } = state

    // deprecated
    // useMemo(() => {
    //     onSelectionChanged(state.selected)
    // }, [state.selected])

    const childrenArray = flattenChildren(children)
    const columns = useMemo(() => {
        // get columns
        return childrenArray.map((child) => {
            if (child && child.props) {
                // let _groupColumns = []
                // if (child.props.groupColumns && typeof child.props.groupColumns === "function") {
                //     _groupColumns = child.props.groupColumns(child.props)
                // }

                // return { ...child.props, groupFields: _groupColumns }
                return { ...child.props }
            }
        })
    }, [
        // force recalculation of columns only if propnames are different
        childrenArray.map((c) => c.props.propName).join("_"),
    ])

    // Table shadow
    const { shadow, horizontalScrollHandler, shadowWidth } = useTableShadow()
    const scrollContainerRef = useRef()

    const inputRef = useRef(null)

    function handleHeaderClick(propName) {
        dispatch({
            type: "SET_SORT_BY",
            payload: { sortBy: propName },
        })
    }

    function handleRowSelect(rowData) {
        dispatch({
            type: "ADD_SELECTED",
            payload: { items: rowData.__group ? getRowsFromGroups(rowData.data) : [rowData] },
        })
    }

    function handleRowDeselect(rowData) {
        if (rowData.__group) {
            dispatch({
                type: "REMOVE_SELECTED_BY_PROPNAME",
                payload: { propName: rowData.__propName, value: rowData[rowData.__propName] },
            })
        } else {
            dispatch({ type: "REMOVE_SELECTED", payload: { deselected: rowData } })
        }
    }

    function handleRowOpen(rowData, path) {
        dispatch({ type: "ADD_OPEN_PATH", payload: { path, rowData } })
    }

    function handleRowClose(_, path) {
        dispatch({ type: "REMOVE_OPEN_PATH", payload: { path } })
    }

    function handleAddFilter(filter) {
        dispatch({ type: "ADD_FILTER", payload: { filter, filterStorage } })
    }

    function handleRemoveFilter(filter) {
        dispatch({ type: "REMOVE_FILTER", payload: { filter, filterStorage } })
    }

    function handleRemoveAllFilters() {
        dispatch({ type: "REMOVE_ALL_FILTERS", payload: { filterStorage } })
    }

    // input handling
    const debounceInputChange = _.debounce((text) => {
        dispatch({ type: "INPUT_CHANGE", payload: { inputValue: text } })
    }, 500)

    const handleSearchBarInputChange = useCallback(
        (text) => {
            // e.persist()
            debounceInputChange(text)
        },
        [debounceInputChange]
    )

    function handleSearchBarButtonClick(e) {
        dispatch({ type: "INPUT_CHANGE", payload: { inputValue: "" } })
        inputRef.current.value = ""
    }

    const _data = useMemo(() => {
        return applyFieldTransformation(data, columns)
    }, [data, columns])

    let { filteredData, availableFilterOptions } = useMemo(() => {
        let displayData = transformData(_data, sortBy, sortFunctionName, inputValue, columns)
        let { data: filteredData, availableFilterOptions } = applyFilters(displayData, filters, columns)

        return { filteredData, availableFilterOptions }
    }, [data, sortBy, sortFunctionName, inputValue, columns, filters, units])

    // send filtered data to map
    useEffect(() => {
        onSelectionChanged(filteredData, { filters })
    }, [filteredData])

    // summary row functionality
    let summaries = summarizeRows(filteredData, columns)

    // deprecated
    // Define a state variable to check whether checkAll checkbox was checked once
    // const [checkedOnce, setCheckedOnce] = useState(false)

    // DEPRECATED May 10th, 2022. (Since we're gonna reimplement the labels this will revisited at some point)
    // useEffect(() => {
    //     // Listen for data changes and if data found and checkbox is not checked at least once
    //     if (data.length > 0) {
    //         if (!checkedOnce) {
    //             // Set checked flag
    //             setCheckedOnce(true)

    //             // Get currently displayed data
    //             let currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)

    //             // Dispatch check_all event (which checks `check all` checkbox)
    //             dispatch({
    //                 type: "ADD_SELECTED_BY_ROWS",
    //                 payload: { items: currentDisplayedData, data, rows, currentPage, groupBy },
    //             })
    //         }
    //     }
    // }, [data])

    // deprecated logic
    // function handleCheckAllRows(e) {
    //     let currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)

    //     if (e.target.checked) {
    //         dispatch({
    //             type: "ADD_SELECTED_BY_ROWS",
    //             payload: { items: currentDisplayedData, data, rows, currentPage, groupBy },
    //         })
    //     } else {
    //         dispatch({
    //             type: "REMOVE_SELECTED_BY_ROWS",
    //             payload: { items: currentDisplayedData, rows, currentPage, groupBy },
    //         })
    //     }
    // }

    // checkbox logic
    // let checked = false
    // let checkedStatus = "empty"

    // deprecated
    // let _currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)
    // let _currentDisplayedData = displayData
    // const groupedSelected = groupData(selected, groupBy) // deprecated

    // const _propName = (groupBy[0] && groupBy[0].propName) || "uuid"
    // const hashed = {}

    // for (let _currentItem of _currentDisplayedData) hashed[_currentItem[_propName]] = false
    // for (let _selectItem of groupedSelected)
    //     if (hashed[_selectItem[_propName]] === false) hashed[_selectItem[_propName]] = true

    // let c = Object.values(hashed).reduce((prev, curr) => (curr ? prev + 1 : prev), 0)

    // checked = c > 0 && c <= _currentDisplayedData.length
    // checkedStatus = c === 0 ? "empty" : c > 0 && c < _currentDisplayedData.length ? "half" : "full"

    /* Create export information */
    const { csvHeaders, csvData, csvString } = useCSVData(
        // TODO: Change all CSV export functions to accept
        // an object with data and context
        exportFormats.CSV
            ? exportFormats.CSV.func({
                  data: filteredData,
                  context: { units },
              })
            : [],
        exportFormats.CSV ? exportFormats.CSV.headers : "",
        exportFormats.CSV ? exportFormats.CSV.headerSection : "",
        !loading && exportFormats.CSV
    )
    const renderExportButton = () => {
        if (exportFormats && Object.keys(exportFormats).length > 0)
            return (
                <ExportButton
                    cols={{ csv: csvHeaders }}
                    data={{ csv: csvData }}
                    csvString={csvString}
                    isPNGEnabled={false}
                    wrapperClasses="relative border-[1px] border-gray-10 rounded-lg w-[32px] h-[32px]"
                    buttonSizeClasses="h-[32px] w-[32px]"
                    fileName={exportFilename}
                />
            )
    }
    return (
        <>
            <TableContext.Provider
                value={{
                    // TableItem
                    onRowAdd,
                    onRowDelete,
                    onRowUpdate,
                    onDataSourceChange,
                    onRowOpen: handleRowOpen,
                    onRowClose: handleRowClose,
                    onRowSelect: handleRowSelect,
                    onRowDeselect: handleRowDeselect,
                    onRemoveLabelFromField,
                    openPaths,
                    units,
                    showDataSourceDropdown,
                    highlightByColumn,
                    // TableControls
                    selected,
                    labels,
                    onDeleteLabel,
                    onLabelFields,
                    // checked,
                    // handleCheckAllRows,
                    // checkedStatus,
                    handleSearchBarButtonClick,
                    handleSearchBarInputChange,
                    scrollContainerRef,
                    onAddFilter: handleAddFilter,
                    onRemoveFilter: handleRemoveFilter,
                    onRemoveAllFilters: handleRemoveAllFilters,
                }}
            >
                <div className="relative w-full h-full">
                    {!noHeaders && (
                        <>
                            <div
                                className={
                                    "w-full lg:h-[56px] bg-white border-y border-gray-10 " +
                                    ((expanded && "max-h-max min-h-[56px]") || "h-full")
                                }
                            >
                                {/* Search Bar */}
                                {renderTableTopBar(
                                    handleSearchBarInputChange,
                                    handleSearchBarButtonClick,
                                    renderExportButton
                                )}
                            </div>
                            <div
                                className={
                                    "w-full lg:h-[36px] bg-white border-b border-gray-10 flex items-center px-3 " +
                                    ((expanded && "h-[36px]") || "h-0")
                                }
                            >
                                <button
                                    className={"btn w-[20px] h-[20px] mr-3 " + (!expanded && "invisible lg:visible")}
                                    onClick={handleRemoveAllFilters}
                                >
                                    <Tooltip position="right" content="Clear All Filters">
                                        <FunnelAltIcon fill="var(--color-gray-60)" />
                                    </Tooltip>
                                </button>
                                {filters.map((filter) => {
                                    return (
                                        <div className="mx-1">
                                            <Chip
                                                id={`table_filter_${filter.propName}_${filter.value}`}
                                                value={filter.value}
                                                onRemove={() => handleRemoveFilter(filter)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )}
                    <div
                        className={
                            "w-full border-0 border-gray-10 xl:border-x " +
                            ((noHeaders && "h-full") || "h-[calc(100%_-_92px)]")
                        }
                    >
                        <div className="h-full" style={{ width: "100%", position: "relative", overflow: "hidden" }}>
                            {shadow && <div className="table__shadow" style={{ width: shadowWidth }}></div>}
                            <div
                                className="table__scroll-container"
                                onScroll={horizontalScrollHandler}
                                ref={scrollContainerRef}
                            >
                                <table className={`table__content root ${className}`}>
                                    <TableContent
                                        noSummary={noSummary}
                                        displayData={filteredData}
                                        columns={columns}
                                        rows={filteredData.length}
                                        currentPage={currentPage}
                                        sortBy={sortBy}
                                        groupBy={groupBy}
                                        sortFunctionName={sortFunctionName}
                                        onHeaderClick={handleHeaderClick}
                                        // selected={groupedSelected} // deprecated
                                        filters={filters}
                                        summaries={summaries}
                                        //
                                        availableFilterOptions={availableFilterOptions}
                                        root
                                    />
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </TableContext.Provider>
        </>
    )
}

export default React.memo(Table)

import { useState, useEffect, useContext } from "react"
import SeasonalTrendChart from "./components/SeasonalTrendChart"
import ChartLabels from "../../components/LongTerm/ChartLabels/ChartLabels"
import ChartLabelItem from "../../components/LongTerm/ChartLabels/ChartLabelItem"
import Checkbox from "../../ui/Checkbox/Checkbox"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import useSeasonalData from "../../hooks/useSeasonalData"
import { ToastContext } from "../../components/Toast/ToastContextProvider"
import { chartColors, innerChartColors } from "../SeasonalRisk/SeasonalRisk"

const initialSelected = {
    2010: true,
    2020: false,
    2030: false,
    2040: false,
    2050: false,
    2060: true,
    2070: false,
    2080: false,
}

function SeasonalTrend() {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [selected, setSelected] = useState(initialSelected)

    const { enqueueNetworkError } = useContext(ToastContext)

    const {
        ready,
        //
        allLocations: locations,
        selectedLocation,
        setSelectedLocation,
        //
        scenarios,
        selectedScenario,
        setSelectedScenario,
        //
        variables,
        selectedVariable,
        setSelectedVariable,
    } = useAdaptationValues()

    function handleCheckboxChange(key) {
        if (selected[key]) setSelected({ ...selected, [key]: false })
        else setSelected({ ...selected, [key]: true })
    }

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?scenario=${selectedScenario}&location=${selectedLocation}&var=${selectedVariable}`

        const request = http.get(`/api/v1/adapt/v2/seasonal/var${query}`, { timeout: 180000 })
        request
            .then((response) => {
                setData(response.data)
                let newSelected = { ...selected }
                setSelected(newSelected)
            })
            .catch((err) => {
                console.log(err)
                enqueueNetworkError()
            })
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedLocation, selectedVariable, selectedScenario])

    const { chartData, maxValue, minValue } = useSeasonalData(
        data,
        selected,
        chartColors,
        ready ? variables[selectedVariable].convertToUnits : null
    )

    return (
        <>
            {/* <ReportDownloadButton
                text={`Seasonal Risk / ${selectedLocation} / ${
                    variablesByDefault
                        ? selectedVariableObj.label
                        : `${selectedRiskObj.title} (${selectedRiskObj.subtitle}, ${selectedRiskObj.stage})`
                } / ${selectedScenarioObj.label}`}
            /> */}
            <div className="overview">
                <div className="overview__main">
                    <SeasonalTrendChart
                        maxValue={maxValue}
                        minValue={minValue}
                        loading={loading}
                        data={chartData}
                        // locations dropdown
                        locations={locations}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        // scenarios dropdown
                        scenarios={scenarios}
                        selectedScenario={selectedScenario}
                        setSelectedScenario={setSelectedScenario}
                        // variables dropdown
                        variables={variables}
                        selectedVariable={selectedVariable}
                        setSelectedVariable={setSelectedVariable}
                    >
                        <ChartLabels>
                            {new Array(6)
                                .fill(null)
                                .map((_, i) => 2010 + i * 10)
                                .map((d, i) => {
                                    const disabled = !(data && data[d])
                                    return (
                                        <ChartLabelItem
                                            key={`monthly_select_${d.toString()}`}
                                            disabled={disabled}
                                            renderIcon={() => (
                                                <Checkbox
                                                    stroke={chartColors[i]}
                                                    fill={innerChartColors[i]}
                                                    checkmarkIcon={false}
                                                    checked={selected[d.toString()]}
                                                    status={selected[d.toString()] ? "full" : "empty"}
                                                    onChange={() => !disabled && handleCheckboxChange(d.toString())}
                                                />
                                            )}
                                        >
                                            <button
                                                className="btn"
                                                style={{
                                                    opacity: selected[d.toString()] ? 1 : 0.4,
                                                    fontFamily: "Montserrat",
                                                }}
                                                onClick={() => !disabled && handleCheckboxChange(d.toString())}
                                                onMouseEnter={(e) => {
                                                    e.target.style.opacity = 1
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.opacity = selected[d.toString()] ? 1 : 0.4
                                                }}
                                            >
                                                {d} - {d + 9}
                                            </button>
                                        </ChartLabelItem>
                                    )
                                })}
                        </ChartLabels>
                    </SeasonalTrendChart>
                </div>
            </div>
        </>
    )
}

export default SeasonalTrend

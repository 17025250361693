import { Sidebar } from "../../ui/Sidebar"
import GenericPageHeader from "../GenericPageHeader"

import useField from "../../hooks/useField"
import PoweredBy from "../../components/PoweredBy"

const SETTINGS_TITLES = ["Table Settings", "Risk Profiles", "Risk Categories", "User Settings"]

function ConsolidatedLayout(props) {
    const { children, title = "", settings, showSidebar = true, applyStyling = true } = props
    let field = useField()
    let fieldName = field ? field.name : undefined
    if (SETTINGS_TITLES.includes(title)) fieldName = undefined

    const { skin } = window.dashboard

    return (
        <>
            {/* VIEW CONTAINER  */}
            <div className={applyStyling ? "grow flex flex-row w-full h-full text-gray-90 roboto bg-gray-5" : "h-full"}>
                {/* SIDENAV  */}
                {showSidebar && <Sidebar settings={settings} seasonal />}

                {/* REPORT */}
                <div
                    className={
                        applyStyling
                            ? "grow p-0 md:px-[14px] md:pt-6 overflow-x-hidden overflow-y-visible max-w-8xl mx-auto h-full flex flex-col"
                            : "h-full"
                    }
                >
                    <div className={applyStyling ? "grow" : "h-full"}>
                        {title && title !== "" && <GenericPageHeader pageTitle={title} pageSubtitle={fieldName} />}
                        {children}
                    </div>
                    {skin && (
                        <div className="grow-0">
                            <PoweredBy />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ConsolidatedLayout

import "./CardItem.css"

function CardItem(props) {
    const { icon, render, children } = props

    return (
        <>
            <div className={"card-item"}>
                <div className="card-item__main">
                    <div className="card-item__actionable">{icon}</div>
                    <div className="card-item__content">{render && render()}</div>
                </div>
                <div className="card-item__children">{children}</div>
            </div>
        </>
    )
}

export default CardItem

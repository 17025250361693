import React, { useContext } from "react"
import { Redirect, Route } from "react-router-dom"
import PropTypes from "prop-types"
import { AuthContext } from "../../Auth/Auth"

const RouteWithLayout = (props) => {
    const { title, permission = "", layout: Layout, component: Component, requiresPermission, navItem, ...rest } = props
    const { currentUser, permissions } = useContext(AuthContext)

    if (
        currentUser &&
        ((requiresPermission && permission !== "" && permission in permissions && permissions[permission]) ||
            !requiresPermission)
    ) {
        return (
            <Route
                {...rest}
                render={(matchProps) => (
                    <Layout title={title} navItem={navItem} {...rest}>
                        <Component {...matchProps} {...rest} />
                    </Layout>
                )}
            />
        )
    } else {
        console.log("redirect?")
        return <Redirect to="/" />
    }
}

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
}

export default RouteWithLayout

import Menu from "./Menu"
import MenuItem from "./MenuItem"
import CropIcon from "../Icons/CropIcon"

export default function CropDropdownSelection(props) {
    const { selectedCrop, selectedVariety, crops, selectCropVariety, keyPrefix } = props
    return (
        <Menu className="left" icon={CropIcon} text={`${selectedCrop}/${selectedVariety}`}>
            {crops &&
                Object.values(crops).map((crop) => (
                    <MenuItem
                        active={`${crop.crop}/${crop.variety}` === `${selectedCrop}/${selectedVariety}`}
                        label={`${crop.crop}/${crop.variety}`}
                        key={`${keyPrefix}_${crop.crop}/${crop.variety}`}
                        onClick={() =>
                            `${crop.crop}/${crop.variety}` !== `${selectedCrop}/${selectedVariety}` &&
                            selectCropVariety(crop.crop, crop.variety)
                        }
                    />
                ))}
        </Menu>
    )
}
